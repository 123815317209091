/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */
import { isElementNull } from '@fleetmatics/ui.utilities';

import {
  IGetJourneyForVehicleResponse,
  IReplayJourney,
  IReplayJourneySegment,
  ESegmentType,
  EReplaySegmentStatus,
  IReplayPlotResponse,
  IReplayPlotData,
  HarshDrivingEvents,
  ISegmentHarshDrivingResponse,
  EHarshDrivingType,
  EAccelerationSeverity,
  IGetJourneyForVehicleParams,
  Segment,
  IReplayDay,
  EDriverType,
  EPlotDirection,
  DateRange,
  IGetReplayPlotsResponse,
  IReplayGeofence,
  ESegmentPlaceType,
  ISegmentPlace,
  EReplaySegmentStatusIcon
} from '../../models';
import {
  IVehiclePlot,
  ETrackingDeviceEventCode,
  IIndexableObject,
  IReplayPlot,
  EPlotIcon,
  LatLng,
  IMarkerOptions,
  ReplayMarkerInfo,
  EPlotType,
  MarkerInfo,
  IPolylineInfo,
  ICoordinates,
  IVehiclePlotResponse,
  ESegmentSelectionState,
  ETrackableClassification,
  IVideoEvent,
  EVideoEventGenerationType
} from '../../../core/models';
import { ArrayUtilities, EpochTimeUtilities } from '../../../../utils';
import { ISize } from '../../../maps/models';

const replayCompoundMarkerSize: ISize = { height: 32, width: 32 };
const replayPinMarkerSize: ISize = { height: 36, width: 25 };

const invisibleClassName = 'custom-marker--invisible';
const realTimeClassName = 'custom-marker--real-time';
export class ReplayMappingUtilities {
  private static readonly MinZoomLevelToFilter = 9;
  private static readonly MaxZoomLevelToFilter = 15;
  private static readonly ArrowSize = 20;
  private static readonly EarthRadius = 6378137;
  private static readonly PlotIdSpaceForDay = 10000;
  private static readonly ArrowIdSpace = 100000;
  private static readonly plotDirectionMap: EPlotDirection[] = [
    EPlotDirection.North,
    EPlotDirection.NorthEast,
    EPlotDirection.East,
    EPlotDirection.SouthEast,
    EPlotDirection.South,
    EPlotDirection.SouthWest,
    EPlotDirection.West,
    EPlotDirection.NorthWest,
    EPlotDirection.North
  ];

  static mapJourney(
    journeyResponse: IGetJourneyForVehicleResponse,
    plotsResponse: IGetReplayPlotsResponse,
    vehiclePlot: IVehiclePlot,
    params: IGetJourneyForVehicleParams,
    driverNames: IIndexableObject<string>,
    includeRealtimePlots: boolean,
    videoEvents: IVideoEvent[]
  ): IReplayJourney {
    // no data for vehicles
    if (this.journeyHasNoDataForVehicle(journeyResponse)) {
      return null;
    }
    try {
      // convert the API response (external) to model (internal)
      // journey contains the segments for each day, but with no plots attached at this stage
      const journeyModel: IReplayJourney = this.convertToJourneyModel(journeyResponse, vehiclePlot, params, driverNames);

      // convert the plots API response and the latest vehicle update response (different data source)
      const plotsModel = this.convertToPlotsModel(plotsResponse, journeyModel, vehiclePlot, includeRealtimePlots, videoEvents);

      // attach the plots to the segments and set the ids
      this.addPlotsToJourneyModel(journeyModel, plotsModel, includeRealtimePlots);

      return journeyModel;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static journeyHasNoDataForVehicle(journeyResponse: IGetJourneyForVehicleResponse): boolean {
    if (journeyResponse.centricities[0].vehicles.length === 0) {
      return true;
    }

    return false;
  }

  static convertToPlotsModel(
    plotsResponse: IGetReplayPlotsResponse,
    journeyModel: IReplayJourney,
    latestPlot: IVehiclePlot,
    includeRealtimePlots: boolean,
    videoEvents: IVideoEvent[]
  ): IReplayPlotData[] {
    const cleanedUpPlotsResponse: IReplayPlotResponse[] = ReplayMappingUtilities.cleanUpPlotResponse(
      plotsResponse.plots,
      journeyModel.days,
      includeRealtimePlots
    );
    const trackableClassification = this._mapPlotTypeToTrackableClassfication(latestPlot.type);
    const plotsModel = cleanedUpPlotsResponse.map(plotResponse => {
      return ReplayMappingUtilities.convertToPlotModel(plotResponse, trackableClassification);
    });

    const plotsModelWithVideos = videoEvents ? this._addVideosToPlotModels(plotsModel, videoEvents) : plotsModel;

    // TODO: ONCE THE FEATURE IS FULLY INTEGRATED, REMOVE THIS CODE
    if (includeRealtimePlots) {
      this._addLatestPlot(plotsModelWithVideos, latestPlot);
    }
    return plotsModelWithVideos;
  }

  private static _addLatestPlot(plotsModel: IReplayPlotData[], vehiclePlot: IVehiclePlot): IReplayPlotData[] {
    const isLatestPlotIncludedInPlotsFromAPI = plotsModel.some(p => {
      const plotModelTime = p.time;
      const vehiclePlotTime = EpochTimeUtilities.fromDotNetTicksToDate(vehiclePlot.ticks);
      const isSame = plotModelTime.getTime() === vehiclePlotTime.getTime();
      return isSame;
    });

    if (!isLatestPlotIncludedInPlotsFromAPI) {
      const newPlotModel: IReplayPlotData = this._convertVehiclePlotToReplayPlot(vehiclePlot);
      plotsModel.push(newPlotModel);
    }
    return plotsModel;
  }

  private static _mapPlotDirection(directionDegrees: number): EPlotDirection {
    const index = Math.floor((directionDegrees + 22.5) / 45);

    return this.plotDirectionMap[index];
  }

  static addPlotsToJourneyModel(journeyModel: IReplayJourney, plots: IReplayPlotData[], includeRealtimePlots: boolean): void {
    if (plots.length === 0) {
      return;
    }
    journeyModel.days = journeyModel.days.map((day, dayIndex) => {
      if (day.segments.length > 0) {
        // match plots to the correct segments. remaining plots are plots that have not been assigned yet
        const { segmentsWithPlots, remainingPlots } = ReplayMappingUtilities.addPlotsToMatchingSegment(plots, day.segments, dayIndex);

        const segments: IReplayJourneySegment[] = ReplayMappingUtilities.addSyntheticSegments(
          segmentsWithPlots,
          plots[0],
          plots.slice(-1)[0],
          remainingPlots,
          includeRealtimePlots
        );
        // update plots array so processing of the next day will be faster
        plots = remainingPlots;

        const completeJourneyDay: IReplayDay = {
          ...day,
          segments: segments
        };

        return completeJourneyDay;
      }

      // no data for the day:
      return day;
    });
  }

  static _lastSegment(journey: IReplayJourney): IReplayJourneySegment {
    const lastDay = journey.days[journey.days.length - 1];
    const lastSegment = lastDay.segments[lastDay.segments.length - 1];
    return lastSegment;
  }

  static _lastPlotOfJourney(journey: IReplayJourney): IReplayPlotData {
    const lastDay = journey.days[journey.days.length - 1];
    return this._lastPlotOfSegments(lastDay.segments);
  }

  static _lastPlotOfSegments(segments: IReplayJourneySegment[]): IReplayPlotData {
    for (let i = segments.length - 1; i >= 0; i--) {
      const segment = segments[i];
      if (segment.plots.length > 0) {
        return segment.plots.slice(-1)[0];
      }
    }
  }

  static extendJourney(
    journeyResponse: IGetJourneyForVehicleResponse,
    plotsResponse: IGetReplayPlotsResponse,
    vehiclePlot: IVehiclePlot,
    params: IGetJourneyForVehicleParams,
    driverNames: IIndexableObject<string>,
    currentJourney: IReplayJourney,
    includeRealtimePlots: boolean
  ): IReplayJourney {
    // no data for vehicles
    if (journeyResponse.centricities[0].vehicles.length === 0) {
      return currentJourney;
    }
    try {
      const modifiedPlotResponse: IGetReplayPlotsResponse = this._addPrivacyPlotToPlotResponse(
        currentJourney,
        journeyResponse,
        plotsResponse
      );

      const newJourney = ReplayMappingUtilities.mapJourney(
        journeyResponse,
        modifiedPlotResponse,
        vehiclePlot,
        params,
        driverNames,
        includeRealtimePlots,
        []
      );

      /* validate the update:
       journey update contains segments with no plots. This is likely because there's some incosistency between
       the plots response and the segment response. rejecting the update. The next attempt is likely to succeed
      */
      if (newJourney.days.some(day => day.segments.some(s => s.plots.length === 0))) {
        return currentJourney;
      }

      return ReplayMappingUtilities.concatJourneys(currentJourney, newJourney);
    } catch (error) {
      console.error(error);
      return currentJourney;
    }
  }

  static concatJourneys(firstJourney: IReplayJourney, secondJourney: IReplayJourney) {
    // This method takes two journeys assuming the second is a continuation of the first
    // we need to find where the overlap is and remove the tail of the first journey
    // and paste the head of the second journey
    // exceptions: in progress segment of the first journey might contain plots that are not in the second jouney
    // we must merge the in progress segment to keep those
    const secondJourneySegments = secondJourney.days[0].segments;
    const head = secondJourneySegments.filter(s => s.status !== EReplaySegmentStatus.Start && s.status !== EReplaySegmentStatus.End);

    // old segments
    const firstJourneySegments = firstJourney.days.slice(-1)[0].segments;
    const tailIndex = firstJourneySegments.findIndex(
      // Start segment is a synthetic segment and it has the same start time as the next segment
      // this ensures that at the edge case of having only real segment, we will keep the start segment
      s => s.status !== EReplaySegmentStatus.Start && s.dateRange.start.toDate().getTime() === head[0].dateRange.start.toDate().getTime()
    );
    if (tailIndex === -1) {
      console.warn(`concatJourneys did not find a segment with the same timestamp`);
      return firstJourney;
    }

    // keep the first journey in progress segment
    const firstJourneyInProgress = firstJourneySegments.find(s => s.status === EReplaySegmentStatus.InProgress);
    const secondJourneyInProgress = head.find(s => s.status === EReplaySegmentStatus.InProgress);
    if (!isElementNull(firstJourneyInProgress) && !isElementNull(secondJourneyInProgress)) {
      // find the last timestamp
      const timeOfLastPlotSecondJourney = this._lastPlotOfSegments(secondJourneySegments).time;
      // find if there are any plots in the first journey 'in progress' that are later
      const plotsToAdd = firstJourneyInProgress.plots.filter(p => p.time.getTime() > timeOfLastPlotSecondJourney.getTime());
      const mergedPlots = [...secondJourneyInProgress.plots, ...plotsToAdd];

      // set realtime plot to be the last
      secondJourneyInProgress.plots = this._setLatestPlotRealTime(mergedPlots);
    }

    // remove the segments that will be updated
    const concatenatedJourneySegments = firstJourneySegments.slice(0, tailIndex);
    // store the ids of the last plot and last segment before adding the new segments
    const lastPlotId = this._lastPlotOfSegments(concatenatedJourneySegments).id;
    const lastSegmentId = concatenatedJourneySegments.slice(-1)[0].id;
    // push the new segments
    concatenatedJourneySegments.push(...head);

    // update the segments and plots ids
    let newPlotId = lastPlotId + 1;
    let newSegmentId = lastSegmentId + 1;
    head.forEach(segment => {
      segment.id = newSegmentId++;
      segment.plots.forEach(p => {
        p.id = newPlotId++;
        p.segmentIndex = segment.id;
      });
    });

    // create a new reference for change detection
    const concatenatedJourney = Object.assign({}, firstJourney);
    concatenatedJourney.days = concatenatedJourney.days.map(d => ({ ...d }));
    // replace the segments of the current journey with the merged segments
    concatenatedJourney.days[concatenatedJourney.days.length - 1].segments = [...concatenatedJourneySegments];
    return concatenatedJourney;
  }

  private static _setLatestPlotRealTime(mergedPlots: IReplayPlotData[]): IReplayPlotData[] {
    return mergedPlots.map((p, index) => ({
      ...p,
      isRealtimePlot: index === mergedPlots.length - 1
    }));
  }

  private static _createInProgressSegment(lastSegment: IReplayJourneySegment): IReplayJourneySegment {
    return {
      id: lastSegment.id + 1,
      address: null,
      dateRange: new DateRange(lastSegment.dateRange.end.toDate(), null),
      status: EReplaySegmentStatus.InProgress,
      plots: [],
      driverName: lastSegment.driverName,
      isSynthetic: true,
      statusIcon: EReplaySegmentStatusIcon.InProgress,
      durationSeconds: lastSegment.durationSeconds
    };
  }

  private static _createSegmentForEndOfJourney(
    segments: IReplayJourneySegment[],
    plotsOutsideOfJourney: IReplayPlotData[]
  ): IReplayJourneySegment {
    const lastSegment = segments.slice(-1)[0];
    const lastPlotInSegments = this._lastPlotOfSegments(segments);

    // 2) In progress segment with the last plot of the previous segment
    if (plotsOutsideOfJourney.length === 0) {
      return ReplayMappingUtilities._createInProgressWithLastPlotAttached(lastSegment, lastPlotInSegments);
    }

    // 3) Stopped segment:
    // if the last plot is shutdown, create a stopped segment
    const lastPlot = plotsOutsideOfJourney.slice(-1)[0];
    if (plotsOutsideOfJourney.length === 1 && lastPlot.eventCode === ETrackingDeviceEventCode.ShutDown) {
      const stoppedSegment = ReplayMappingUtilities._createSyntheticStoppedSegment(segments, lastPlot);
      return stoppedSegment;
    }

    // 4) In progress segment with one or more "live plots"
    const inProgress = ReplayMappingUtilities._createInProgressSegmentWithPlots(segments, plotsOutsideOfJourney, lastPlotInSegments?.id);

    return inProgress;
  }

  private static _createInProgressWithLastPlotAttached(lastSegment: IReplayJourneySegment, lastPlotInSegments: IReplayPlotData) {
    const inProgress = this._createInProgressSegment(lastSegment);
    // attach the last plot of the journey
    inProgress.plots.push({
      ...lastPlotInSegments,
      id: lastPlotInSegments.id + 1,
      segmentIndex: inProgress.id
    });
    return inProgress;
  }

  private static _createInProgressSegmentWithPlots(
    segments: IReplayJourneySegment[],
    plotsOutsideOfJourney: IReplayPlotData[],
    idOfLastPlot: number = this.PlotIdSpaceForDay
    // asumming realtime plotting is disabled for multiday. if this changes, we'll have to pass in the day marker
  ): IReplayJourneySegment {
    const inProgress = this._createInProgressSegment(segments.slice(-1)[0]);
    const inProgressPlots = plotsOutsideOfJourney.map((p, index) => ({
      ...p,
      id: idOfLastPlot + index + 1,
      segmentIndex: inProgress.id
    }));
    inProgress.plots = inProgressPlots;
    return inProgress;
  }

  private static _createSyntheticStoppedSegment(segments: IReplayJourneySegment[], lastPlot: IReplayPlotData): IReplayJourneySegment {
    const currentTime = new Date();
    const lastSegment = segments.slice(-1)[0];
    return {
      id: lastSegment.id + 1,
      status: EReplaySegmentStatus.Stopped,
      address: lastPlot.address,
      plots: [lastPlot],
      dateRange: new DateRange(lastSegment.dateRange.end.toDate(), currentTime),
      driverName: lastSegment.driverName,
      isSynthetic: true,
      statusIcon:
        lastPlot.trackableClassification === ETrackableClassification.PoweredAsset
          ? EReplaySegmentStatusIcon.StoppedPoweredAsset
          : EReplaySegmentStatusIcon.Stopped
    };
  }

  static convertToJourneyModel(
    journeyResponse: IGetJourneyForVehicleResponse,
    vehiclePlot: IVehiclePlot,
    params: IGetJourneyForVehicleParams,
    driverNames: IIndexableObject<string>
  ): IReplayJourney {
    const cleanedUpSegements = this._getSegmentsFromJourneyResponse(journeyResponse);

    const trackableClassification = this._mapPlotTypeToTrackableClassfication(vehiclePlot.type);
    const segmentsModel: IReplayJourneySegment[] = this._convertToSegmentsModel(cleanedUpSegements, driverNames, trackableClassification);

    const journey = this.getEmptyJourney(vehiclePlot.id, params.dateRange, vehiclePlot.vehicleName);

    journey.days.forEach(day => {
      ReplayMappingUtilities._mapReplayDay(day, segmentsModel);
    });

    return journey;
  }

  private static _getSegmentsFromJourneyResponse(journeyResponse: IGetJourneyForVehicleResponse): Segment[] {
    const segments = journeyResponse.centricities[0].vehicles[0].centricities[0].segments;

    // sort segments by start time
    const sortedSegmentsByStartTime = segments.sort(
      (s1, s2) => new Date(s1.start.time.utc).getTime() - new Date(s2.start.time.utc).getTime()
    );

    const cleanedUpSegements = ReplayMappingUtilities._cleanUpSegments(sortedSegmentsByStartTime);
    return cleanedUpSegements;
  }

  static addSyntheticSegments(
    segments: IReplayJourneySegment[],
    firstPlot: IReplayPlotData,
    lastPlot: IReplayPlotData,
    remainingPlots: IReplayPlotData[],
    includeRealtimePlots: boolean
  ): IReplayJourneySegment[] {
    const start: IReplayJourneySegment = this._createStartSegment(segments, firstPlot);

    // TODO: ONCE THE FEATURE IS FULLY INTEGRATED, REMOVE THIS CODE
    if (!includeRealtimePlots) {
      const end = this._createEndSegment(segments, lastPlot);
      return [start, ...segments, end];
    }

    // real time plots path:
    const lastSegment = this._createSegmentForEndOfJourney(segments, remainingPlots);
    lastSegment.plots = this._setLatestPlotRealTime(lastSegment.plots);

    return [start, ...segments, lastSegment];
  }

  static addPlotsToMatchingSegment(
    plots: IReplayPlotData[],
    segments: IReplayJourneySegment[],
    dayIndex: number
  ): {
    segmentsWithPlots: IReplayJourneySegment[];
    remainingPlots: IReplayPlotData[];
  } {
    let plotIndex = 0;
    /*
    initialize plot id with a separate range for each day, to help with debugging
      plot id for day X, with N plots
      X0000 start
      X0001 ...
      X000N last plot
      X000(N+1) end plot
    */
    let plotId = (dayIndex + 1) * ReplayMappingUtilities.PlotIdSpaceForDay + 1;

    const segmentsWithPlots: IReplayJourneySegment[] = [];
    segments.forEach((currentSegment, segmentIndex) => {
      // assign the plots to this segment where each plot has a reference to the segment that it is part of
      const segmentWithPlots: IReplayJourneySegment = {
        ...currentSegment,
        id: segmentIndex + 1, // 0 is for start segment
        plots: []
      };

      let plot = plots[plotIndex];
      const isPlotAtStartOfSegment = plot?.time.getTime() === currentSegment.dateRange.start.toDate().getTime();
      // add plot to current segment and change to false if it doesn't meet certain conditions
      let shouldAddPlotToSegment = true;
      // if a driver change happesn that creates a new segment, however no plot will match the start time of the segment.
      // if this happens we will create a fake plot
      let driverChange = false;

      // edge case where a plot doesn't fall within a segment but we'll still add it:
      if (!isPlotAtStartOfSegment) {
        // 1) segment is private:
        if (currentSegment.status === EReplaySegmentStatus.Private) {
          // take the previous plot
          plotIndex = Math.max(0, plotIndex - 1);
          plot = plots[plotIndex];
          // but only if it is private
          if (!plot?.enteringPrivate) {
            console.warn(`expected previous plot before a private segment to be entering private. not adding plot to segment.
            segment index: ${segmentIndex}, segment status: ${
              currentSegment.status
            }. ${currentSegment.dateRange.start.toDate()} to ${currentSegment.dateRange.end.toDate()}
            plot index: ${plotIndex}`);
            shouldAddPlotToSegment = false;
          }
        } else if (
          segmentIndex > 0 &&
          currentSegment.status === segments[segmentIndex - 1].status &&
          currentSegment.driverName !== segments[segmentIndex - 1].driverName
        ) {
          // 2) logical segmentation due to driver change:
          // take the previous plot as a base
          plotIndex = Math.max(0, plotIndex - 1);
          plot = plots[plotIndex];
          driverChange = true;
        }
      }

      const isLastSegmentAndMoving = segmentIndex === segments.length - 1 && currentSegment.status === EReplaySegmentStatus.Moving;
      while (
        /*
            add all plots that fall within the segment
            plus edge case:
              if the journey is "open" - a moving segment that is also the last segment,
              then the plot should fall on the end time
        */
        plot?.time < currentSegment.dateRange.end.toDate() ||
        (isLastSegmentAndMoving &&
          plot?.time.getTime() === currentSegment.dateRange.end.toDate().getTime() &&
          plot.eventCode !== ETrackingDeviceEventCode.ShutDown)
      ) {
        // if segment is idle than we only add the first plot
        if (shouldAddPlotToSegment && currentSegment.status === EReplaySegmentStatus.Idle && segmentWithPlots.plots.length > 0) {
          shouldAddPlotToSegment = false;
        }

        if (shouldAddPlotToSegment) {
          let mappedPlot: IReplayPlotData;
          // create a fake plot with the details of the previous plot but with the time of the start segment
          if (driverChange) {
            mappedPlot = {
              ...plots[plotIndex],
              id: plotId++,
              segmentIndex: segmentIndex + 1,
              time: currentSegment.dateRange.start.toDate()
            };
            // Only apply driver swap fake time once
            driverChange = false;
          } else {
            mappedPlot = { ...plots[plotIndex], id: plotId++, segmentIndex: segmentIndex + 1 };
          }
          segmentWithPlots.plots.push(mappedPlot);
        }

        // advance to the next plot
        plot = plots[++plotIndex];
      }

      if (segmentWithPlots.plots.length === 0) {
        console.warn(
          `segment has no plots: index: ${segmentIndex}, ${
            currentSegment.status
          }. ${currentSegment.dateRange.start.toDate()} to ${currentSegment.dateRange.end.toDate()}`
        );
      }

      segmentsWithPlots.push(segmentWithPlots);
    });

    return {
      segmentsWithPlots: segmentsWithPlots,
      remainingPlots: plots.slice(plotIndex)
    };
  }

  static cleanUpPlotResponse(
    plots: IReplayPlotResponse[],
    journeyDays: IReplayDay[],
    includeRealtimePlots: boolean
  ): IReplayPlotResponse[] {
    const plotIdMap = new Map<number, IReplayPlotResponse>();
    const journeyStart = EpochTimeUtilities.fromDateToEpochSeconds(ReplayMappingUtilities.getJourneyFirstPlotTime(journeyDays));

    // TODO: ONCE THE FEATURE IS FULLY INTEGRATED, REMOVE THIS CODE
    const journeyEnd = EpochTimeUtilities.fromDateToEpochSeconds(ReplayMappingUtilities.getJourneyLastPlotTime(journeyDays));

    const plotsSortedByTime = plots.sort((p1, p2) => p1.gpsUpdateUtc - p2.gpsUpdateUtc);
    for (const plot of plotsSortedByTime) {
      // remove plots that startbe fore the journey
      if (plot.gpsUpdateUtc < journeyStart && !plot.enteringPrivate) {
        continue;
      }

      // TODO: ONCE THE FEATURE IS FULLY INTEGRATED, REMOVE THIS CODE
      if (!includeRealtimePlots) {
        if (plot.gpsUpdateUtc > journeyEnd) {
          break;
        }
      }

      // we might have multiple plots with the same time
      // we'll take the last plot the comes along
      const key = plot.gpsUpdateUtc;
      plotIdMap.set(key, plot);
    }

    const cleanedPlots: IReplayPlotResponse[] = [...plotIdMap.values()];

    return cleanedPlots;
  }

  static convertToPlotModel(plotResponse: IReplayPlotResponse, trackableClassification: ETrackableClassification): IReplayPlotData {
    const plotData: IReplayPlotData = {
      id: null,
      segmentIndex: null,
      address: plotResponse.address,
      direction: plotResponse.direction,
      distance: plotResponse.distance,
      eventCode: (<any>ETrackingDeviceEventCode)[plotResponse.eventCode],
      time: EpochTimeUtilities.fromEpochSecondsToDate(plotResponse.gpsUpdateUtc),
      enteringPrivate: plotResponse.enteringPrivate,
      postedSpeedLimit: plotResponse.postedSpeedLimit,
      location: {
        Latitude: plotResponse.location.latitude,
        Longitude: plotResponse.location.longitude
      },
      speed: plotResponse.speed,
      accelerationSeverity: plotResponse.accelerationSeverity,
      trackableClassification: trackableClassification
    };

    return plotData;
  }

  static getHarshDrivingEventTypeFromPlot(plot: IReplayPlotData, userSpeedThreshold: number): EHarshDrivingType {
    if (this.hasSpeedingViolation(plot, userSpeedThreshold)) {
      return EHarshDrivingType.Speeding;
    }
    if (this.hasHarshDrivingViolation(plot)) {
      switch (plot.eventCode) {
        case ETrackingDeviceEventCode.HardAcceleration:
          return plot.accelerationSeverity === EAccelerationSeverity.Moderate
            ? EHarshDrivingType.HardAccelerationModerate
            : plot.accelerationSeverity === EAccelerationSeverity.Severe
            ? EHarshDrivingType.HardAccelerationSevere
            : EHarshDrivingType.None;
        case ETrackingDeviceEventCode.HardBraking:
          return plot.accelerationSeverity === EAccelerationSeverity.Moderate
            ? EHarshDrivingType.HardBrakingModerate
            : plot.accelerationSeverity === EAccelerationSeverity.Severe
            ? EHarshDrivingType.HardBrakingSevere
            : EHarshDrivingType.None;
        case ETrackingDeviceEventCode.HardCornering:
          return plot.accelerationSeverity === EAccelerationSeverity.Moderate
            ? EHarshDrivingType.HardCorneringModerate
            : plot.accelerationSeverity === EAccelerationSeverity.Severe
            ? EHarshDrivingType.HardCorneringSevere
            : EHarshDrivingType.None;
      }
    }
    return EHarshDrivingType.None;
  }

  static hasSpeedingViolation(plot: IReplayPlotData, userSpeedThreshold: number): boolean {
    if (plot.postedSpeedLimit) {
      if (userSpeedThreshold === 0 && plot.speed > plot.postedSpeedLimit) {
        return true;
      }
      if (plot.speed - plot.postedSpeedLimit >= userSpeedThreshold) {
        return true;
      }
    }

    return false;
  }

  static hasHarshDrivingViolation(plot: IReplayPlotData): boolean {
    return plot.accelerationSeverity === EAccelerationSeverity.Moderate || plot.accelerationSeverity === EAccelerationSeverity.Severe;
  }

  static getEmptyJourney(vehicleId: number, dateRange: DateRange, vehicleName: string): IReplayJourney {
    const totalDays = dateRange.differenceDays();
    const days: IReplayDay[] = [...Array(totalDays).keys()].map(dayIndex => this._createEmptyDay(dateRange, dayIndex));

    const journey: IReplayJourney = {
      vehicleId: vehicleId,
      selectedDateRange: dateRange,
      vehicleName: vehicleName,
      days: days
    };

    return journey;
  }

  static getJourneyLastPlotTime(journeyDays: IReplayDay[]): Date {
    for (let i = journeyDays.length - 1; i >= 0; i--) {
      if (journeyDays[i].segments.length > 0) {
        return journeyDays[i].segments.slice(-1)[0].dateRange.end.toDate();
      }
    }
  }

  static getJourneyFirstPlotTime(journeyDays: IReplayDay[]): Date {
    for (let i = 0; i < journeyDays.length; i++) {
      if (journeyDays[i].segments.length > 0) {
        return journeyDays[i].segments[0].dateRange.start.toDate();
      }
    }
  }

  static getDriverIds(journeyResponse: IGetJourneyForVehicleResponse): number[] {
    if (isElementNull(journeyResponse.centricities[0].vehicles[0])) {
      return null;
    }

    const segments = journeyResponse.centricities[0].vehicles[0].centricities[0].segments;

    const driverIds = new Set<number>();
    segments.forEach(segment => {
      if (!isElementNull(segment.driver) && segment.driver.$type === EDriverType.Driver) {
        driverIds.add(segment.driver.id);
      }
    });

    return [...driverIds];
  }

  static getReplayPlot(
    plotData: IReplayPlotData,
    segment: IReplayJourneySegment,
    selectedSegment: IReplayJourneySegment,
    hoveredSegment: IReplayJourneySegment,
    userSpeedingThreshold: number
  ): IReplayPlot {
    const isInASelectedSegment = isElementNull(selectedSegment) ? false : segment.id === selectedSegment.id;
    const isInAHoveredSegment = isElementNull(hoveredSegment) ? false : segment.id === hoveredSegment.id;
    const isAPinPlot = this._isAPinPlot(plotData, segment, isInASelectedSegment, selectedSegment);
    const isPlotSelected: ESegmentSelectionState = this._isPlotSelected(
      plotData,
      segment,
      isInASelectedSegment,
      isInAHoveredSegment,
      isAPinPlot,
      selectedSegment,
      hoveredSegment
    );

    const replayPlot: IReplayPlot = {
      id: plotData.id,
      name: '',
      iconClass: ReplayMappingUtilities._getPlotIcon(plotData, segment, isAPinPlot),
      secondaryIconClass: ReplayMappingUtilities._getSecondaryIcon(plotData, segment.status, userSpeedingThreshold),
      center: plotData.location,
      selected: isPlotSelected,
      invisible: false,
      isAPinPlot: isAPinPlot,
      isRealtimePlot: plotData.isRealtimePlot,
      video: plotData.video
    };
    return replayPlot;
  }

  static getLastKnownReplayPlot(lastKnownPlot: IVehiclePlot): IReplayPlot[] {
    const lastKnownReplayPlot: IReplayPlot = {
      center: {
        Latitude: lastKnownPlot.coordinates.lat,
        Longitude: lastKnownPlot.coordinates.lng
      },
      iconClass: lastKnownPlot.iconClass,
      id: lastKnownPlot.id,
      invisible: false,
      name: lastKnownPlot.vehicleName,
      selected: ESegmentSelectionState.NoSelection,
      isAPinPlot: false,
      isRealtimePlot: false
    };
    return [lastKnownReplayPlot];
  }

  static hideStartEndPlotWhenHoveredOrSelected(hoveredOrSelectedId: number, replayPlots: IReplayPlot[]): void {
    if (!isElementNull(hoveredOrSelectedId) && hoveredOrSelectedId === 1) {
      replayPlots[0].invisible = true;
    }
  }

  static getReplayMarkerInfo(plot: IReplayPlot): ReplayMarkerInfo {
    const latLng = new LatLng(plot.center.Latitude, plot.center.Longitude);
    const size = ReplayMappingUtilities._getReplayMarkerSize(plot);
    const mapOptions = {
      iconName: ReplayMappingUtilities._getIconName(plot),
      className: plot.invisible
        ? invisibleClassName
        : plot.isRealtimePlot
        ? ReplayMappingUtilities._getRealtimeClass(plot.iconClass)
        : null,
      priority: ReplayMappingUtilities._getReplayMarkerPriority(plot),
      size: size
    } as IMarkerOptions;

    return new ReplayMarkerInfo(plot.id, latLng, plot.name, EPlotType.replay, mapOptions);
  }

  private static _getReplayMarkerPriority(plot: IReplayPlot): number {
    if (plot.selected === ESegmentSelectionState.SelectedOrHovered) {
      return 2;
    } else {
      return plot.isRealtimePlot ? 1 : 0;
    }
  }

  private static _getReplayMarkerOfPolylinePriority(polyline: IPolylineInfo): number {
    return polyline.isActive ? 1 : 0;
  }
  private static _convertVehiclePlotToReplayPlot(vehiclePlot: IVehiclePlot): IReplayPlotData {
    return {
      address: vehiclePlot.address,
      distance: 0,
      direction: this._mapPlotDirection(vehiclePlot.direction),
      eventCode: this._mapEventCodeFromVehiclePlot(vehiclePlot.eventCode, vehiclePlot.iconClass),
      location: {
        Latitude: vehiclePlot.coordinates.lat,
        Longitude: vehiclePlot.coordinates.lng
      },
      speed: vehiclePlot.speed,
      postedSpeedLimit: vehiclePlot.vehicleSpeedlimit,
      time: EpochTimeUtilities.fromDotNetTicksToDate(vehiclePlot.ticks),
      isPrivate: vehiclePlot.iconClass === EPlotIcon.Private,
      id: null,
      segmentIndex: null,
      trackableClassification: this._mapPlotTypeToTrackableClassfication(vehiclePlot.type)
    };
  }

  private static _mapEventCodeFromVehiclePlot(eventCode: ETrackingDeviceEventCode, iconClass: string): ETrackingDeviceEventCode {
    const isIconIdling = iconClass === EPlotIcon.Idle;
    const isEventCodeIdling = eventCode === ETrackingDeviceEventCode.IdlingStart || eventCode === ETrackingDeviceEventCode.IdlingEnd;

    if (isIconIdling && !isEventCodeIdling) {
      /*
       Replay plots get their icon from the status of the segment
       In the special case of the realtime plots, we are using the eventcode to get the status
       This is complicated by the behavior of idling - the first plot is idlingStart, and the last plot is IdlingEnd,
       but the plots in between can have a Position eventcode. The iconClass will send 'idle' for all of the plots
       we are changing the eventcode of the in-between plots to idling
       */
      return ETrackingDeviceEventCode.IdlingStart;
    }
    return eventCode;
  }

  private static _convertVehiclePlotResponse(vehiclePlotResponse: IVehiclePlotResponse): IReplayPlotData {
    const vehiclePlot: IVehiclePlot = this._convertVehiclePlotResponseToVehiclePlot(vehiclePlotResponse);
    const replayPlotData = this._convertVehiclePlotToReplayPlot(vehiclePlot);
    return replayPlotData;
  }

  private static _convertVehiclePlotResponseToVehiclePlot(vehiclePlotResponse: IVehiclePlotResponse): IVehiclePlot {
    return {
      address: vehiclePlotResponse.ads,
      direction: vehiclePlotResponse.dir,
      eventCode: vehiclePlotResponse.evcd,
      coordinates: {
        lat: vehiclePlotResponse.Coordinate.Latitude,
        lng: vehiclePlotResponse.Coordinate.Longitude
      },
      speed: vehiclePlotResponse.spd,
      vehicleSpeedlimit: vehiclePlotResponse.vspdlmt,
      ticks: vehiclePlotResponse.ticks,
      iconClass: vehiclePlotResponse.icncls
    } as IVehiclePlot;
  }

  static addLivePlotToJourney(replayJourney: IReplayJourney, vehiclePlotResponse: IVehiclePlotResponse): IReplayJourney {
    // convert the vehicle plot response to vehicle plot:
    const livePlot = this._convertVehiclePlotResponse(vehiclePlotResponse);

    // create a new object that can me modified to add the segment
    const updatedJourney = Object.assign({}, replayJourney);
    updatedJourney.days = updatedJourney.days.map(day => ({
      ...day
    }));
    updatedJourney.days[updatedJourney.days.length - 1].segments = [...updatedJourney.days[updatedJourney.days.length - 1].segments];

    // add or get the in progress segment
    const inProgressSegment: IReplayJourneySegment = this._getOrAddInProgressSegment(updatedJourney);

    // update ids of new plot
    livePlot.id = this._lastPlotOfJourney(updatedJourney).id + 1;
    livePlot.segmentIndex = inProgressSegment.id;
    inProgressSegment.plots.push(livePlot);

    inProgressSegment.plots = this._setLatestPlotRealTime(inProgressSegment.plots);
    return updatedJourney;
  }

  private static _getOrAddInProgressSegment(replayJourney: IReplayJourney): IReplayJourneySegment {
    const lastSegment = this._lastSegment(replayJourney);
    if (lastSegment.status === EReplaySegmentStatus.InProgress) {
      // when the in progress segment is created, if there are no plots outisde the journey, as a default,
      // the last plot of the previous segment is attached. Now that we are adding "real" in progress plots
      // we can remove the duplicate plot
      const previousPlot = replayJourney.days
        .slice(-1)[0]
        .segments[lastSegment.id - 1].plots.find(p => p.id + 1 === lastSegment.plots[0].id);
      if (lastSegment.plots[0].time.getTime() === previousPlot?.time.getTime()) {
        lastSegment.plots = lastSegment.plots.slice(1);
      }
      return lastSegment;
    }

    const inProgressSegment = this._createInProgressSegment(lastSegment);
    // add to the segments array
    replayJourney.days[replayJourney.days.length - 1].segments.push(inProgressSegment);

    return inProgressSegment;
  }

  static _getRealtimeClass(iconClass: EPlotIcon): string {
    let status;
    switch (iconClass) {
      case EPlotIcon.Stopped:
      case EPlotIcon.StoppedPoweredAsset:
      case EPlotIcon.StopPin: {
        status = 'stopped';
        break;
      }
      case EPlotIcon.Idle:
      case EPlotIcon.IdlePoweredAsset:
      case EPlotIcon.IdlePin: {
        status = 'idle';
        break;
      }
      case EPlotIcon.Private: {
        status = 'private';
        break;
      }
      default: {
        status = 'moving';
        break;
      }
    }
    return `${realTimeClassName} ${realTimeClassName}-${status}`;
  }

  static journeyDayToBasePolylineInfo(day: IReplayDay, dayIndex: number, totalDays: number): IPolylineInfo {
    const latLngs = day.segments.reduce(
      (array, segment) => array.concat(segment.plots.map(plot => new LatLng(plot.location.Latitude, plot.location.Longitude))),
      <LatLng[]>[]
    );

    if (latLngs.length === 0) {
      return null;
    }

    // day index is zero based
    const id = dayIndex + 1;
    const polylineInfo: IPolylineInfo = {
      id: id,
      latLng: latLngs,
      isActive: null,
      markers: []
    };

    const dayMarker: MarkerInfo = ReplayMappingUtilities._getDayMarker(dayIndex, totalDays, polylineInfo);
    const arrows = ReplayMappingUtilities._getAllArrows(polylineInfo, day.segments);
    polylineInfo.markers = [dayMarker, ...arrows];

    return polylineInfo;
  }

  static getZoomtoMarkersMap(polyline: IPolylineInfo): Map<number, ReplayMarkerInfo[]> {
    const map = new Map<number, ReplayMarkerInfo[]>();
    const distances: number[] = this._getDistances(polyline);

    for (let i = ReplayMappingUtilities.MinZoomLevelToFilter; i <= ReplayMappingUtilities.MaxZoomLevelToFilter; i++) {
      const markers = ReplayMappingUtilities._filterMarkersbyZoomLevelAndDistance(polyline.markers, i, distances);
      map.set(i, markers);
    }
    return map;
  }

  private static _filterMarkersbyZoomLevelAndDistance(markers: MarkerInfo[], zoomLevel: number, distances: number[]): ReplayMarkerInfo[] {
    const metersPerPx = (156543.03392 * Math.cos((markers[0].latLng.lat * Math.PI) / 180)) / Math.pow(2, zoomLevel);

    const minDist = metersPerPx * ReplayMappingUtilities.ArrowSize * 7;

    let distance = 0;
    return markers.filter((marker, index) => {
      if (marker.options.iconName === EPlotIcon.MultidayPin || marker.options.iconName === EPlotIcon.ReplayEnd) {
        return true;
      }

      distance += distances[index];

      if (distance >= minDist) {
        distance = 0;
        return true;
      }

      return false;
    });
  }

  static getPolylineMarkers(
    polyline: IPolylineInfo,
    zoomLevel: number,
    zoomLevelToMarkersMap: Map<number, Map<number, ReplayMarkerInfo[]>>
  ): ReplayMarkerInfo[] {
    let markers: ReplayMarkerInfo[];
    const shouldReturnDayMarkersOnly =
      zoomLevel < ReplayMappingUtilities.MinZoomLevelToFilter || (polyline.isActive !== null && !polyline.isActive);
    const shouldReturnAllMarkers = zoomLevel > ReplayMappingUtilities.MaxZoomLevelToFilter;
    if (shouldReturnDayMarkersOnly) {
      markers = polyline.markers.slice(0, 1);
    } else if (shouldReturnAllMarkers) {
      markers = polyline.markers;
    } else {
      markers = zoomLevelToMarkersMap.get(polyline.id).get(zoomLevel);
    }

    return markers.map(marker => ReplayMappingUtilities._updateMarkerHighPriorityChanged(marker, polyline.isActive));
  }

  private static _getDistance(latLngA: LatLng, latLngB: LatLng) {
    const c = (latLngA.lat * Math.PI) / 180;
    const a = (latLngA.lng * Math.PI) / 180;
    const d = (latLngB.lat * Math.PI) / 180;
    const b = (latLngB.lng * Math.PI) / 180;
    // equi­rectangular projec­tion(less accurate but more efficient):
    const x = (b - a) * Math.cos((c + d) / 2);
    const y = d - c;
    const distance = Math.sqrt(x * x + y * y) * ReplayMappingUtilities.EarthRadius;
    return distance;

    // haversine formula (more accurate but less efficient)
    //  convert to radians
    // const distance =
    //   2 * Math.asin(Math.sqrt(Math.pow(Math.sin((c - d) / 2), 2) + Math.cos(c) * Math.cos(d) * Math.pow(Math.sin((a - b) / 2), 2)));
    // return distance * ReplayMappingUtilities.R;
  }

  private static _getDistances(polyline: IPolylineInfo): number[] {
    // each cell in the area holds the distance to the next plot
    if (polyline.markers.length === 0 || polyline.markers.length < 3) {
      return [];
    }

    return polyline.markers.map((marker, index) => {
      if (index === polyline.markers.length - 1) {
        // there is no next plot, so no distance is required
        return null;
      }

      const nextMarker = polyline.markers[index + 1];
      const distance = Math.abs(this._getDistance(marker.latLng, nextMarker.latLng));
      return distance;
    });
  }

  private static _convertStatus(segment: Segment): EReplaySegmentStatus {
    if (segment.start.private) {
      return EReplaySegmentStatus.Private;
    }
    switch (segment.$type) {
      case ESegmentType.JourneySegment:
        return EReplaySegmentStatus.Moving;
      case ESegmentType.IdleSegment:
        return EReplaySegmentStatus.Idle;
      case ESegmentType.StopSegment:
        return EReplaySegmentStatus.Stopped;
      default:
        break;
    }
  }

  private static _convertHarshDrivingEvents(harshDrivingEvents: ISegmentHarshDrivingResponse): HarshDrivingEvents {
    if (
      isElementNull(harshDrivingEvents) ||
      (harshDrivingEvents.highSpeedEvents === 0 &&
        harshDrivingEvents.moderateAccelerationEvents === 0 &&
        harshDrivingEvents.moderateBrakingEvents === 0 &&
        harshDrivingEvents.moderateLeftCorneringEvents === 0 &&
        harshDrivingEvents.moderateRightCorneringEvents === 0 &&
        harshDrivingEvents.severeAccelerationEvents === 0 &&
        harshDrivingEvents.severeBrakingEvents === 0 &&
        harshDrivingEvents.severeLeftCorneringEvents === 0 &&
        harshDrivingEvents.severeRightCorneringEvents === 0)
    ) {
      return null;
    }
    return HarshDrivingEvents.fromHarshDrivingEventsResponse(harshDrivingEvents);
  }

  private static _getSegmentGeofences(segment: Segment): IReplayGeofence[] {
    let geofences: IReplayGeofence[] = [];

    if (!isElementNull(segment.start.address.places)) {
      geofences = [...geofences, ...this._mapReplayGeofences(segment.start.address.places)];
    }
    if (!isElementNull(segment.stop.address.places)) {
      geofences = [...geofences, ...this._mapReplayGeofences(segment.stop.address.places)];
    }

    // Return unique values as each segment can have duplicates (same place for start and stop)
    return geofences.filter((geofence, index, array) => array.findIndex(g => g.id === geofence.id) === index);
  }

  private static _mapReplayGeofences(inputPlaces: ISegmentPlace[]): IReplayGeofence[] {
    const geofences = inputPlaces
      .filter(place => place.$type === ESegmentPlaceType.Geofence)
      .map(place => ({ id: place.placeId, name: place.placeName }));
    return geofences;
  }

  private static _createStartSegment(segments: IReplayJourneySegment[], firstPlotOfJourney: IReplayPlotData): IReplayJourneySegment {
    // take the first plot of the first segment. If there was some issue and first segment has no plots, take the first plot of the day
    const firstPlot = segments[0].plots.length > 0 ? segments[0].plots[0] : firstPlotOfJourney;
    const startPlot: IReplayPlotData = { ...firstPlot, id: firstPlot.id - 1, segmentIndex: 0 };

    return {
      id: 0,
      status: EReplaySegmentStatus.Start,
      address: segments[0].address,
      plots: [startPlot],
      dateRange: new DateRange(segments[0].dateRange.start.toDate(), segments[0].dateRange.start.toDate()),
      driverName: segments[0].driverName,
      isSynthetic: true,
      statusIcon: EReplaySegmentStatusIcon.Start
    };
  }

  private static _createEndSegment(segments: IReplayJourneySegment[], lastPlotOfJourney: IReplayPlotData): IReplayJourneySegment {
    const lastSegment = segments[segments.length - 1];

    // take the last plot of the last segment. If there was some issue and last segment has no plots, take the last plot of the day
    const lastPlot = lastSegment.plots.slice(-1).length > 0 ? lastSegment.plots.slice(-1)[0] : lastPlotOfJourney;

    const endPlot: IReplayPlotData = { ...lastPlot, id: lastPlot.id + 1, segmentIndex: segments.length + 1 };

    const endSegmentTime =
      lastSegment.status === EReplaySegmentStatus.Stopped ? lastSegment.dateRange.start.toDate() : lastSegment.dateRange.end.toDate();

    return {
      id: segments.length + 1,
      status: EReplaySegmentStatus.End,
      address: lastPlot.address,
      plots: [endPlot],
      dateRange: new DateRange(endSegmentTime, endSegmentTime),
      driverName: lastSegment.driverName,
      isSynthetic: true,
      statusIcon: EReplaySegmentStatusIcon.End
    };
  }

  private static _createEmptyDay(journeyDateRange: DateRange, dayIndex: number): IReplayDay {
    const isFirstDay = dayIndex === 0;
    const isLastDay = dayIndex === journeyDateRange.differenceDays() - 1;

    const dayStart = isFirstDay ? journeyDateRange.start.clone() : journeyDateRange.start.clone().add(dayIndex, 'days').startOf('day');
    const dayEnd = isLastDay ? journeyDateRange.end.clone() : dayStart.clone().endOf('day');

    return {
      selectedDateRange: new DateRange(dayStart, dayEnd),
      segments: []
    };
  }

  private static _getDriverNameFromSegment(segment: Segment, driverNames: IIndexableObject<string>): string {
    const driverId = segment.driver.$type === EDriverType.Driver ? segment.driver.id : null;
    const driverName = driverNames[driverId] === undefined ? null : driverNames[driverId];
    return driverName;
  }

  private static _mapReplayDay(day: IReplayDay, segmentsModel: IReplayJourneySegment[]) {
    const start = day.selectedDateRange.start.toDate();
    const end = day.selectedDateRange.end.toDate();

    // note that segment that start within a day, but finish in another day, are filtered OUT.
    day.segments = segmentsModel.filter(segment => segment.dateRange.start.toDate() >= start && segment.dateRange.start.toDate() <= end);

    day.segments = this._mergeSequentialSegments(day.segments);

    const driverNamesForDay = new Set<string>();
    day.segments.forEach(segment => {
      if (segment.driverName) {
        driverNamesForDay.add(segment.driverName);
      }
    });
    day.driverNames = driverNamesForDay.size === 0 ? null : [...driverNamesForDay];
  }

  private static _mergeSequentialSegments(segments: IReplayJourneySegment[]): IReplayJourneySegment[] {
    /*
     * Group segments into batches by status and driver name
     * Merge these batches into single segments
     */
    const groupedSegments = this._groupSegments(segments);
    const mergedSegments = groupedSegments.map(segmentGrouping => this._mergeSegments(segmentGrouping));

    return mergedSegments;
  }

  private static _groupSegments(segments: IReplayJourneySegment[]): IReplayJourneySegment[][] {
    const groupedSegments: IReplayJourneySegment[][] = [];

    if (segments.length > 0) {
      segments?.forEach(segment => {
        const shouldAddToCurrentGrouping =
          groupedSegments.length > 0 &&
          groupedSegments[groupedSegments.length - 1][0].status === segment.status &&
          groupedSegments[groupedSegments.length - 1][0].driverName === segment.driverName;

        if (shouldAddToCurrentGrouping) {
          groupedSegments[groupedSegments.length - 1].push(segment);
        } else {
          groupedSegments.push([segment]);
        }
      });
    }

    return groupedSegments;
  }

  private static _mergeSegments(segments: IReplayJourneySegment[]): IReplayJourneySegment {
    const mergedSegment: IReplayJourneySegment = {
      ...segments[0],
      status: segments[0].status,
      address: segments[0].address,
      dateRange: new DateRange(segments[0].dateRange.start.toDate(), segments[segments.length - 1].dateRange.end.toDate()),
      durationSeconds: segments.map(segment => segment.durationSeconds).reduce((a, b) => a + b, 0),
      distanceKms: segments.map(segment => segment.distanceKms).reduce((a, b) => a + b, 0),
      plots: segments.map(segment => segment.plots).reduce((a, b) => a.concat(b), []),
      driverName: segments[0].driverName,
      harshDrivingEvents: HarshDrivingEvents.fromMultipleHarshDrivingEvents(segments.map(segment => segment.harshDrivingEvents)),
      geofences: segments.map(segment => segment.geofences).reduce((a, b) => a.concat(b), []),
      statusIcon: segments[0].statusIcon
    };
    return mergedSegment;
  }

  private static _convertToSegmentsModel(
    sortedSegmentsByStartTime: Segment[],
    driverNames: IIndexableObject<string>,
    trackableClassification: ETrackableClassification
  ): IReplayJourneySegment[] {
    return sortedSegmentsByStartTime.map(
      (segment): IReplayJourneySegment => {
        const driverName = this._getDriverNameFromSegment(segment, driverNames);
        const segmentStatus = this._convertStatus(segment);
        const segmentModel: IReplayJourneySegment = {
          address: segment.start.address.formattedAddress,
          distanceKms: segment.distanceKms,
          driverName: driverName,
          durationSeconds: segment.durationSeconds,
          dateRange: new DateRange(new Date(segment.start.time.utc), new Date(segment.stop.time.utc)),
          status: segmentStatus,
          harshDrivingEvents: this._convertHarshDrivingEvents(segment.harshDrivingEvents),
          plots: [],
          geofences: this._getSegmentGeofences(segment),
          statusIcon: this._mapSegmentStatusToIcon(segmentStatus, trackableClassification)
        };

        return segmentModel;
      }
    );
  }

  private static readonly _getPlotIcon = (plot: IReplayPlotData, segment: IReplayJourneySegment, isPinIcon: boolean): EPlotIcon => {
    if (isPinIcon) {
      switch (segment.status) {
        case EReplaySegmentStatus.Stopped:
          return EPlotIcon.StopPin;
        case EReplaySegmentStatus.Idle:
          return EPlotIcon.IdlePin;
        case EReplaySegmentStatus.Moving:
          return EPlotIcon.MovingPin;
      }
    }
    let icon: EPlotIcon;
    switch (segment.status) {
      case EReplaySegmentStatus.Start:
        icon = EPlotIcon.ReplayStart;
        break;
      case EReplaySegmentStatus.End:
        icon = EPlotIcon.ReplayEnd;
        break;

      case EReplaySegmentStatus.Idle:
        icon = EPlotIcon.Idle;
        break;
      case EReplaySegmentStatus.Stopped:
        icon = EPlotIcon.Stopped;
        break;
      case EReplaySegmentStatus.Private:
        icon = EPlotIcon.Private;
        break;
      case EReplaySegmentStatus.Moving:
        icon = ReplayMappingUtilities._mapPlotDirectionToPlotIcon(plot.direction);
        break;
      case EReplaySegmentStatus.InProgress:
        icon = ReplayMappingUtilities._mapEventCodeToPlotIcon(plot);
        break;
      default:
        break;
    }

    if (plot.trackableClassification === ETrackableClassification.PoweredAsset) {
      icon = ReplayMappingUtilities._mapReplayVehiclePlotIconToPoweredAsset(icon);
    }

    return icon;
  };

  private static readonly _getSecondaryIcon = (
    plot: IReplayPlotData,
    segmentStatus: EReplaySegmentStatus,
    userSpeedThreshold: number
  ): EPlotIcon => {
    if (
      segmentStatus !== EReplaySegmentStatus.Idle &&
      segmentStatus !== EReplaySegmentStatus.Stopped &&
      (ReplayMappingUtilities.hasSpeedingViolation(plot, userSpeedThreshold) || ReplayMappingUtilities.hasHarshDrivingViolation(plot))
    ) {
      return EPlotIcon.Violation;
    }
    return null;
  };

  private static readonly _mapPlotDirectionToPlotIcon = (direction: EPlotDirection): EPlotIcon => {
    switch (direction) {
      case EPlotDirection.North: {
        return EPlotIcon.MovingN;
      }
      case EPlotDirection.NorthEast: {
        return EPlotIcon.MovingNE;
      }
      case EPlotDirection.East: {
        return EPlotIcon.MovingE;
      }
      case EPlotDirection.SouthEast: {
        return EPlotIcon.MovingSE;
      }
      case EPlotDirection.South: {
        return EPlotIcon.MovingS;
      }
      case EPlotDirection.SouthWest: {
        return EPlotIcon.MovingSW;
      }
      case EPlotDirection.West: {
        return EPlotIcon.MovingW;
      }
      case EPlotDirection.NorthWest: {
        return EPlotIcon.MovingNW;
      }

      default: {
        return EPlotIcon.MovingN;
      }
    }
  };

  private static _getIconName(plot: IReplayPlot): string {
    let iconName = plot.selected === ESegmentSelectionState.NotSelectedOrHovered ? `${plot.iconClass}-grey` : plot.iconClass;
    if (!isElementNull(plot.secondaryIconClass)) {
      iconName +=
        plot.selected === ESegmentSelectionState.NotSelectedOrHovered ? `-${plot.secondaryIconClass}-grey` : `-${plot.secondaryIconClass}`;
    }

    return iconName;
  }

  private static _getDayMarker(dayIndex: number, totalDays: number, polylineInfo: IPolylineInfo) {
    let dayMarker: MarkerInfo;
    // last day will be end marker
    if (dayIndex === totalDays - 1) {
      dayMarker = new ReplayMarkerInfo(polylineInfo.id, polylineInfo.latLng[0], `day marker ${polylineInfo.id}`, EPlotType.replay, {
        iconName: EPlotIcon.ReplayEnd
      });
    } else {
      dayMarker = new ReplayMarkerInfo(polylineInfo.id, polylineInfo.latLng[0], `day marker ${polylineInfo.id}`, EPlotType.replay, {
        iconName: EPlotIcon.MultidayPin,
        htmlContent: `<div class="day-marker-number">${polylineInfo.id}</div>`,
        size: { height: 36, width: 24 },
        offset: {
          height: 18,
          width: 12
        },
        addListeners: false,
        priority: this._getReplayMarkerOfPolylinePriority(polylineInfo)
      });
    }
    return dayMarker;
  }

  private static _getAllArrows(polylineInfo: IPolylineInfo, segments: IReplayJourneySegment[]): ReplayMarkerInfo[] {
    const arrows = segments.reduce((tempArrows, segment) => {
      if (segment.status === EReplaySegmentStatus.Start || segment.status === EReplaySegmentStatus.End) {
        return tempArrows;
      }
      const segmentArrows = segment.plots.map((plot, index) => {
        return new ReplayMarkerInfo(
          plot.id + ReplayMappingUtilities.ArrowIdSpace,
          new LatLng(plot.location.Latitude, plot.location.Longitude),
          '',
          EPlotType.replay,
          {
            iconName: EPlotIcon.MultidayArrow,
            priority: this._getReplayMarkerOfPolylinePriority(polylineInfo),
            size: {
              height: 20,
              width: 20
            },
            offset: {
              height: 10,
              width: 10
            },
            rotate: index === segment.plots.length - 1 ? null : this._computeHeading(plot.location, segment.plots[index + 1].location),
            addListeners: false
          }
        );
      });
      return tempArrows.concat(segmentArrows);
    }, <ReplayMarkerInfo[]>[]);

    return arrows;
  }

  private static _computeHeading(p1: ICoordinates, p2: ICoordinates): string {
    const toRad = (degrees: number) => (degrees * Math.PI) / 180;
    const toDeg = (radians: number) => (radians * 180) / Math.PI;
    const lng1 = toRad(p1.Longitude);
    const lng2 = toRad(p2.Longitude);
    const lat1 = toRad(p1.Latitude);
    const lat2 = toRad(p2.Latitude);

    const dLon = lng2 - lng1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const bearing = toDeg(Math.atan2(y, x));

    // const degrees = 360 - ((bearing + 360) % 360); // transform to range [0,360] counter-clockwise
    return `${Math.round(bearing)}deg`;
  }

  private static _updateMarkerHighPriorityChanged(marker: ReplayMarkerInfo, isHighPriority?: boolean): ReplayMarkerInfo {
    let iconName = marker.options.iconName;
    if (iconName === EPlotIcon.MultidayArrow || iconName === EPlotIcon.MultidayArrowActive) {
      iconName = isHighPriority ? EPlotIcon.MultidayArrowActive : EPlotIcon.MultidayArrow;
    }

    return new ReplayMarkerInfo(marker.id, marker.latLng, marker.title, marker.type, {
      ...marker.options,
      priority: isHighPriority === null ? null : 1,
      iconName: iconName
    });
  }

  private static _isAPinPlot(
    plotData: IReplayPlotData,
    currentSegment: IReplayJourneySegment,
    isInASelectedSegment: boolean,
    selectedSegment?: IReplayJourneySegment
  ): boolean {
    if (
      isElementNull(selectedSegment) ||
      selectedSegment.status === EReplaySegmentStatus.Start ||
      selectedSegment.status === EReplaySegmentStatus.End ||
      currentSegment.status === EReplaySegmentStatus.End ||
      currentSegment.status === EReplaySegmentStatus.Private ||
      selectedSegment.status === EReplaySegmentStatus.Private ||
      currentSegment.status === EReplaySegmentStatus.InProgress ||
      selectedSegment.status === EReplaySegmentStatus.InProgress
    ) {
      return false;
    }

    const isFirstPlotOfSelectedSegment = isInASelectedSegment && ReplayMappingUtilities._isFirstPlotOfSegment(plotData, selectedSegment);
    const isFirstPlotOfNextSegment =
      !isInASelectedSegment &&
      currentSegment.id === selectedSegment.id + 1 &&
      ReplayMappingUtilities._isFirstPlotOfSegment(plotData, currentSegment);
    const shouldShowOnePin =
      selectedSegment.status === EReplaySegmentStatus.Idle || selectedSegment.status === EReplaySegmentStatus.Stopped;

    return isFirstPlotOfSelectedSegment || (isFirstPlotOfNextSegment && !shouldShowOnePin);
  }

  private static _isPlotSelected(
    plotData: IReplayPlotData,
    currentSegment: IReplayJourneySegment,
    isInASelectedSegment: boolean,
    isInAHoveredSegment: boolean,
    isAPinPlot: boolean,
    selectedSegment?: IReplayJourneySegment,
    hoveredSegment?: IReplayJourneySegment
  ): ESegmentSelectionState {
    if (isElementNull(selectedSegment) && isElementNull(hoveredSegment)) {
      return ESegmentSelectionState.NoSelection;
    }
    const isSelectedOrHovered =
      isInASelectedSegment ||
      isInAHoveredSegment ||
      isAPinPlot ||
      (currentSegment.status === EReplaySegmentStatus.End &&
        ReplayMappingUtilities._isFirstPlotAfterSelectedSegment(plotData, currentSegment, selectedSegment));

    return isSelectedOrHovered ? ESegmentSelectionState.SelectedOrHovered : ESegmentSelectionState.NotSelectedOrHovered;
  }

  private static _isFirstPlotAfterSelectedSegment(
    plotData: IReplayPlotData,
    currentSegment: IReplayJourneySegment,
    selectedSegment?: IReplayJourneySegment
  ) {
    return (
      !isElementNull(selectedSegment) &&
      currentSegment.id - 1 === selectedSegment.id &&
      ReplayMappingUtilities._isFirstPlotOfSegment(plotData, currentSegment)
    );
  }

  private static _isFirstPlotOfSegment(plotData: IReplayPlotData, segment: IReplayJourneySegment): boolean {
    return segment.plots[0].id === plotData.id;
  }

  private static _getReplayMarkerSize(plot: IReplayPlot) {
    // If this returns a non null value, it will override the predefined map configs in google maps component
    if (plot.isAPinPlot) {
      return replayPinMarkerSize;
    }

    if (!isElementNull(plot.secondaryIconClass)) {
      return replayCompoundMarkerSize;
    }

    return null;
  }

  private static _cleanUpSegments(sortedSegmentsByStartTime: Segment[]): Segment[] {
    if (sortedSegmentsByStartTime.length > 0) {
      /* when there are driver assignments that happen before the journey starts (first ignition on) then
         it is represented by multiple stop segments at the begining of the journey.
         Currently, these segments and are not shown in 'detailed report' and in 'classic replay' and it was decided
         to filter it out
      */

      const journeyStart = sortedSegmentsByStartTime.findIndex(segment => segment.$type !== ESegmentType.StopSegment);
      if (journeyStart !== -1) {
        return sortedSegmentsByStartTime.slice(journeyStart);
      }
    }
    return sortedSegmentsByStartTime;
  }

  static _mapEventCodeToPlotIcon(plot: IReplayPlotData): EPlotIcon {
    if (plot.isPrivate) {
      return EPlotIcon.Private;
    }

    switch (plot.eventCode) {
      case ETrackingDeviceEventCode.IdlingEnd:
      case ETrackingDeviceEventCode.IdlingStart: {
        return EPlotIcon.Idle;
      }
      case ETrackingDeviceEventCode.ShutDown:
        return EPlotIcon.Stopped;
      default:
        return ReplayMappingUtilities._mapPlotDirectionToPlotIcon(plot.direction);
    }
  }

  /*
    if a journey starts with a privacy segment, we don't have a plot to attach to the segment
    because we are extending a current journey, we can use the plot from the current journey
  */
  private static _addPrivacyPlotToPlotResponse(
    currentJourney: IReplayJourney,
    journeyResponse: IGetJourneyForVehicleResponse,
    plotsResponse: IGetReplayPlotsResponse
  ): IGetReplayPlotsResponse {
    if (this.journeyHasNoDataForVehicle(journeyResponse)) {
      return plotsResponse;
    }
    const firstSegmentSecondJourney = this._getSegmentsFromJourneyResponse(journeyResponse)[0];
    // if the first segment is not private, nothing to do
    if (this._convertStatus(firstSegmentSecondJourney) !== EReplaySegmentStatus.Private) {
      return plotsResponse;
    }

    const firstJourneySegments = currentJourney.days.slice(-1)[0].segments;
    const privacySegmentTime = new Date(firstSegmentSecondJourney.start.time.utc);
    const firstJourneyPrivacySegment = ArrayUtilities.findLast(
      firstJourneySegments,
      s => s.dateRange.start.toDate().getTime() === privacySegmentTime.getTime()
    );
    // make sure the current journey's last segment is private and has a plot
    if (firstJourneyPrivacySegment?.status === EReplaySegmentStatus.Private && firstJourneyPrivacySegment?.plots.length > 0) {
      const privacyPlot = firstJourneyPrivacySegment.plots[0];
      const privacyPlotResponse: IReplayPlotResponse = this._mapReplayPlotDataToReplayPlotResponse(privacyPlot);
      plotsResponse.plots.unshift(privacyPlotResponse);
    }
    return plotsResponse;
  }

  private static _mapReplayPlotDataToReplayPlotResponse(plot: IReplayPlotData): IReplayPlotResponse {
    return {
      address: plot.address,
      direction: plot.direction,
      distance: plot.distance,
      eventCode: plot.eventCode.toString(),
      gpsUpdateUtc: EpochTimeUtilities.fromDateToEpochSeconds(plot.time),
      location: {
        latitude: plot.location.Latitude,
        longitude: plot.location.Longitude
      },
      speed: plot.speed,
      enteringPrivate: plot.enteringPrivate,
      accelerationSeverity: plot.accelerationSeverity,
      postedSpeedLimit: plot.postedSpeedLimit
    };
  }

  private static _mapPlotTypeToTrackableClassfication(plotType: EPlotType): ETrackableClassification {
    return plotType === EPlotType.poweredAsset ? ETrackableClassification.PoweredAsset : ETrackableClassification.Vehicle;
  }

  private static _mapReplayVehiclePlotIconToPoweredAsset(vehicleIcon: EPlotIcon): EPlotIcon {
    const vehicleToPoweredAssetMapping = new Map<EPlotIcon, EPlotIcon>([
      [EPlotIcon.Stopped, EPlotIcon.StoppedPoweredAsset],
      [EPlotIcon.Idle, EPlotIcon.IdlePoweredAsset],
      [EPlotIcon.MovingN, EPlotIcon.MovingNPoweredAsset],
      [EPlotIcon.MovingNE, EPlotIcon.MovingNEPoweredAsset],
      [EPlotIcon.MovingE, EPlotIcon.MovingEPoweredAsset],
      [EPlotIcon.MovingSE, EPlotIcon.MovingSEPoweredAsset],
      [EPlotIcon.MovingS, EPlotIcon.MovingSPoweredAsset],
      [EPlotIcon.MovingSW, EPlotIcon.MovingSWPoweredAsset],
      [EPlotIcon.MovingW, EPlotIcon.MovingWPoweredAsset],
      [EPlotIcon.MovingNW, EPlotIcon.MovingNWPoweredAsset]
    ]);
    return vehicleToPoweredAssetMapping.get(vehicleIcon) ?? vehicleIcon;
  }

  private static _mapSegmentStatusToIcon(
    segmentStatus: EReplaySegmentStatus,
    trackableClassisification: ETrackableClassification
  ): EReplaySegmentStatusIcon {
    if (trackableClassisification === ETrackableClassification.PoweredAsset) {
      const poweredAssetMapping = new Map<EReplaySegmentStatus, EReplaySegmentStatusIcon>([
        [EReplaySegmentStatus.End, EReplaySegmentStatusIcon.End],
        [EReplaySegmentStatus.Idle, EReplaySegmentStatusIcon.IdlePoweredAsset],
        [EReplaySegmentStatus.InProgress, EReplaySegmentStatusIcon.InProgress],
        [EReplaySegmentStatus.Moving, EReplaySegmentStatusIcon.MovingPoweredAsset],
        [EReplaySegmentStatus.Private, EReplaySegmentStatusIcon.Private],
        [EReplaySegmentStatus.Start, EReplaySegmentStatusIcon.Start],
        [EReplaySegmentStatus.Stopped, EReplaySegmentStatusIcon.StoppedPoweredAsset]
      ]);
      return poweredAssetMapping.get(segmentStatus);
    }
    const vehicleMapping = new Map<EReplaySegmentStatus, EReplaySegmentStatusIcon>([
      [EReplaySegmentStatus.End, EReplaySegmentStatusIcon.End],
      [EReplaySegmentStatus.Idle, EReplaySegmentStatusIcon.Idle],
      [EReplaySegmentStatus.InProgress, EReplaySegmentStatusIcon.InProgress],
      [EReplaySegmentStatus.Moving, EReplaySegmentStatusIcon.Moving],
      [EReplaySegmentStatus.Private, EReplaySegmentStatusIcon.Private],
      [EReplaySegmentStatus.Start, EReplaySegmentStatusIcon.Start],
      [EReplaySegmentStatus.Stopped, EReplaySegmentStatusIcon.Stopped]
    ]);
    return vehicleMapping.get(segmentStatus);
  }

  private static _addVideosToPlotModels(plotsModel: IReplayPlotData[], videoEvents: IVideoEvent[]): IReplayPlotData[] {
    const plotVideoMap = new Map<IReplayPlotData, IVideoEvent>();
    const plotsWithValidVideoHDE = plotsModel.filter(plot => this._isEventCodeValidVideoEvent(plot.eventCode));
    videoEvents.forEach(video => {
      // If video is generated by VoD, plot doesn't need to have HDEs
      const plotsToSearch = video.eventGenerationType === EVideoEventGenerationType.VoD ? plotsModel : plotsWithValidVideoHDE;
      const closestPlot = this._getClosestPlotToVideo(plotsToSearch, video);
      plotVideoMap.set(closestPlot, video);
    });

    // Add videos to plots
    const plotsWithVideos: IReplayPlotData[] = plotsModel.map(plot =>
      plotVideoMap.has(plot) ? { ...plot, video: plotVideoMap.get(plot) } : plot
    );

    return plotsWithVideos;
  }

  private static _getClosestPlotToVideo(plotsToSearch: IReplayPlotData[], video: IVideoEvent) {
    return plotsToSearch.reduce((a, b) => {
      const aDiff = Math.abs(a.time.getTime() - video.timeUtc.getTime());
      const bDiff = Math.abs(b.time.getTime() - video.timeUtc.getTime());
      return aDiff < bDiff ? a : b;
    });
  }

  private static _isEventCodeValidVideoEvent(eventCode: ETrackingDeviceEventCode): boolean {
    return (
      eventCode === ETrackingDeviceEventCode.HardAcceleration ||
      eventCode === ETrackingDeviceEventCode.HardBraking ||
      eventCode === ETrackingDeviceEventCode.HardCornering
    );
  }
}
