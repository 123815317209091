/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, Store } from '@ngrx/store';

import { AuthService } from '@fleetmatics/ui.authentication';
import { FmUsabillaService } from '@fleetmatics/ui.insights';
import { IconModule } from '@fleetmatics/ui.base-library';
import { FmCookieService } from '@fleetmatics/ui.utilities';

import {
  BalloonHttpInterceptor,
  PlotHttpInterceptor,
  CustomerMetadataHttpInterceptor,
  SearchLightHttpInterceptor,
  GeofenceHttpInterceptor,
  PlacesHttpInterceptor,
  AlertHttpInterceptor,
  LastInTheChainInterceptor,
  UserDataHttpInterceptor,
  CategoriesHttpInterceptor,
  SchedulerHttpInterceptor,
  UsabillaHttpInterceptor
} from './http-interceptors';
import {
  AuthService as AuthServiceMock,
  UsabillaService,
  DemoDtoMappingService,
  DemoGoogleMapsService,
  PlotSimulatorService,
  DemoDomService,
  ModalService,
  PendoService
} from './services';
import { DemoEffects, SetupDemo, demoReducer } from './store';
import { IAppState } from '../core/store/state';
import {
  SplashScreenComponent,
  ModalComponent,
  BuyNowComponent,
  ModalContentComponent,
  ModalContainerComponent,
  PendoGuidesComponent,
  InterstitialScreenComponent,
  CarouselComponent,
  MobileNavTitleComponent,
  MobileNavCardComponent,
  MobileWebSplashScreenComponent,
  FreeTrialModalComponent,
  ShareDemoModalComponent
} from './components';

@NgModule({
  imports: [StoreModule.forFeature('demoFeature', demoReducer), EffectsModule.forFeature([DemoEffects]), CommonModule, IconModule],
  declarations: [
    SplashScreenComponent,
    ModalComponent,
    ModalContentComponent,
    ModalContainerComponent,
    BuyNowComponent,
    PendoGuidesComponent,
    InterstitialScreenComponent,
    CarouselComponent,
    MobileNavTitleComponent,
    MobileNavCardComponent,
    MobileWebSplashScreenComponent,
    FreeTrialModalComponent,
    ShareDemoModalComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BalloonHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CategoriesHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomerMetadataHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SearchLightHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GeofenceHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PlacesHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AlertHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PlotHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserDataHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SchedulerHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UsabillaHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LastInTheChainInterceptor, multi: true },
    { provide: AuthService, useClass: AuthServiceMock },
    { provide: FmUsabillaService, useClass: UsabillaService },
    DemoDtoMappingService,
    DemoGoogleMapsService,
    PlotSimulatorService,
    DemoDomService,
    ModalService,
    PendoService,
    FmCookieService
  ],
  entryComponents: [
    SplashScreenComponent,
    ModalComponent,
    ModalContentComponent,
    BuyNowComponent,
    PendoGuidesComponent,
    InterstitialScreenComponent,
    MobileNavTitleComponent,
    MobileNavCardComponent,
    MobileWebSplashScreenComponent,
    FreeTrialModalComponent,
    ShareDemoModalComponent
  ]
})
export class DemoModule {
  constructor(store: Store<IAppState>) {
    store.dispatch(new SetupDemo());
  }
}
