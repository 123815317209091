/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { XMLSerializer } from 'xmldom';

export class XMLUtilities {
  public static SerializeToString(element: Node): string {
    return new XMLSerializer().serializeToString(element);
  }

  public static ParseFromString(input: string, mimeType: DOMParserSupportedType): Document {
    return new DOMParser().parseFromString(input, mimeType);
  }
}
