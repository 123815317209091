/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IReverseGeocodeState } from '../state';
import { IReverseGeocodeExecutionBatch } from '../../models';

export const getReverseGeocodeState = createFeatureSelector<IReverseGeocodeState>('reverseGeocode');

export const getReverseGeocodeExecutionBatches = createSelector(
  getReverseGeocodeState,
  (state: IReverseGeocodeState): IReverseGeocodeExecutionBatch[] => state.executionBatches
);
