/*
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { IconModule, ButtonModule, TooltipModule, InputModule } from '@fleetmatics/ui.base-library';
import { FmFlyoutModule, FmTriggerModule, FmMenuModule } from '@fleetmatics/ui.navbar';

import { FmSearchLightComponent } from './search-light.component';
import {
  FmSearchLightFabComponent,
  FmSearchLightCategoryMenuComponent,
  FmSearchLightCategoryMenuOptionComponent,
  FmSearchLightCategoryResultsMenuComponent,
  FmSearchLightInputComponent,
  FmSearchLightFabInputComponent,
  FmSearchLightFormComponent,
  FmSearchLightPanelComponent
} from './components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    FmFlyoutModule,
    FmMenuModule,
    FmTriggerModule,
    IconModule,
    ButtonModule,
    TooltipModule,
    InputModule
  ],
  declarations: [
    FmSearchLightComponent,
    FmSearchLightFabComponent,
    FmSearchLightCategoryMenuComponent,
    FmSearchLightCategoryMenuOptionComponent,
    FmSearchLightCategoryResultsMenuComponent,
    FmSearchLightInputComponent,
    FmSearchLightFabInputComponent,
    FmSearchLightPanelComponent,
    FmSearchLightFormComponent
  ],
  exports: [FmSearchLightFormComponent, FmSearchLightFabComponent]
})
export class FmSearchLightModule {}
