/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding
} from '@angular/core';

@Component({
  selector: 'fm-modal-header',
  templateUrl: './modal-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalHeaderComponent {
  @Input()
  headerTitle: string;

  @HostBinding('class.modal-header')
  hostClass = true;
}
