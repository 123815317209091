/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EFuelEfficiencyUnits {
  MilesPerGallonUS = 2,
  LitresPer100km = 3,
  MilesPerGallonUK = 4,
  KilometersPerLitre = 5,
  KilometersPerGallonUS = 6
}
