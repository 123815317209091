/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, forkJoin, of, timer, EMPTY } from 'rxjs';
import { switchMap, map, filter, withLatestFrom, skipWhile, catchError, tap } from 'rxjs/operators';

import { ConfigService, FmWindowRefService, isArrayNullOrEmpty, isElementNull } from '@fleetmatics/ui.utilities';
import { ECheckboxState } from '@fleetmatics/ui.base-library';

import { IAppState } from '../state';
import { AssetsHttpService, PlotHttpService, PlotFormattingService } from '../../services';
import { ENodeType, IAssetPlot, EPlotIcon, IAssetPlotRequest, IMapHierarchyItem, EPlotType, IUserSettings } from '../../models';
import { IAssetPlotResponse, IAssetDetailResponse, IAssetSelectionResponse, IRootGroupResponse } from '../../models/responses';
import {
  GetMapHierarchySuccess,
  UpdateAssetSelection,
  UpdateAssetSelectionSuccess,
  GetSelectedAssets,
  GetSelectedAssetsSuccess,
  GetAssetDetailsSuccess,
  EAssetsActions,
  GetAssetPlotsSuccess,
  GetAssetsHierarchy,
  UpdateAssetAsSelected,
  GetAssetDetails,
  UpdateAssetAsSelectedSuccess,
  GetSearchLightAssetPlot,
  RedirectToLocationHistory
} from '../actions';
import {
  getUserFeatures,
  getAssetsSelection,
  getAssetDetails,
  getMapHierarchyInfo,
  getSelectedAssetsIds,
  getUserSettings
} from '../selectors';
import { FlatDataUtilities } from '../../components';
import { delayedRetry } from '../../operators';
import { IAppConfig } from '../../../config';

export const GET_ASSET_INTERVAL = 60000;

@Injectable()
export class AssetsEffects {
  public getAssetSelection$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSelectedAssets>(EAssetsActions.GetSelectedAssets),
      filter(() => isPlatformBrowser(this._platformId)),
      switchMap(() => this._store.pipe(select(getUserFeatures))),
      skipWhile(userFeatures => isElementNull(userFeatures)),
      filter(userFeatures => userFeatures.assets),
      switchMap(() =>
        this._assetsHttpService.getSelection().pipe(
          delayedRetry(2000, 10),
          catchError(() => of(null))
        )
      ),
      filter(selectedAssetIds => !isElementNull(selectedAssetIds)),
      map(selectedAssetIds => new GetSelectedAssetsSuccess(selectedAssetIds))
    )
  );

  public getAssetsByPolling$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSelectedAssetsSuccess>(EAssetsActions.GetSelectedAssetsSuccess),
      switchMap(() => timer(0, GET_ASSET_INTERVAL)),
      map(() => new GetAssetDetails())
    )
  );

  public getAssetDetails$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAssetDetails | UpdateAssetSelectionSuccess>(EAssetsActions.GetAssetDetails, EAssetsActions.UpdateAssetSelectionSuccess),
      withLatestFrom(this._store.pipe(select(getSelectedAssetsIds))),
      switchMap(([, selectedAssetsIds]) => {
        if (selectedAssetsIds.length === 0) {
          return of([] as IAssetDetailResponse[]);
        }
        const request: Observable<IAssetDetailResponse[] | null> = this._assetsHttpService
          .getAssetsByIds(selectedAssetsIds)
          .pipe(catchError(() => EMPTY));
        return request;
      }),
      map(assetDetails => new GetAssetDetailsSuccess(assetDetails))
    )
  );

  public getAssetPlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAssetDetailsSuccess>(EAssetsActions.GetAssetDetailsSuccess),
      map(action => action.payload),
      map(assetDetails => this._getDistinctDbIdRequests(assetDetails)),
      switchMap((distinctDbIdRequests: IAssetPlotRequest[]) => {
        if (distinctDbIdRequests.length > 0) {
          const requests: Observable<IAssetPlotResponse[]>[] = [];
          distinctDbIdRequests.forEach(plotRequest =>
            requests.push(
              this._assetsHttpService.getAssetPlots(plotRequest.dbId, plotRequest.vehicleTrackingIds).pipe(catchError(() => of([])))
            )
          );
          return forkJoin(requests).pipe(
            withLatestFrom(this._store.pipe(select(getAssetDetails)), this._store.pipe(select(getUserSettings))),
            map(([plotsResponse, detailsResponse, userSettings]) =>
              this._combinePlotWithDetails(plotsResponse, detailsResponse, userSettings)
            )
          );
        }
        return of([]);
      }),
      map((assetPlots: IAssetPlot[]) => new GetAssetPlotsSuccess(assetPlots))
    )
  );

  public updateAssetSelection$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateAssetSelection>(EAssetsActions.UpdateAssetSelection),
      map(action => action.payload.filter(id => id.startsWith('a')).map(id => parseInt(id.substring(1), 10))),
      switchMap((selectedIds: number[]) =>
        this._assetsHttpService.updateSelection(selectedIds).pipe(
          delayedRetry(),
          map(() => selectedIds),
          catchError(() => of(null))
        )
      ),
      filter(selectedIds => !isElementNull(selectedIds)),
      withLatestFrom(this._store.pipe(select(getAssetsSelection))),
      map(([selectedIds, selectedAssets]) => {
        selectedAssets.forEach(asset => {
          asset.selected = selectedIds.includes(asset.id);
        });

        return selectedAssets;
      }),
      map((selectedAssets: IAssetSelectionResponse[]) => new UpdateAssetSelectionSuccess(selectedAssets))
    )
  );

  public getAssetHierarchy$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAssetsHierarchy>(EAssetsActions.GetAssetsHierarchy),
      switchMap(() => this._store.pipe(select(getAssetsSelection))),
      skipWhile(selectedAssets => isArrayNullOrEmpty(selectedAssets)),
      switchMap(selectedAssets => {
        return this._plotHttpService.getRootGroupForHierarchy().pipe(
          delayedRetry(),
          map(rootGroup => [rootGroup, selectedAssets]),
          catchError(() => of(null))
        );
      }),
      filter(result => !isElementNull(result)),
      map(([root, items]: [IRootGroupResponse, IAssetSelectionResponse[]]) => this._mapAssetsReponseToMapHierarchy(root, items)),
      map(mapHierarchy => new GetMapHierarchySuccess({ isNewMapHierarchy: true, mapHierarchy }))
    )
  );

  public updateAssetAsSelected$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateAssetAsSelected>(EAssetsActions.UpdateAssetAsSelected),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getAssetsSelection)), this._store.pipe(select(getMapHierarchyInfo))),
      switchMap(([asset, selectedAssets, mapHierarchy]) => {
        const actions: Action[] = [];
        const assets = selectedAssets.filter(a => a.selected).map(x => 'a' + x.id);

        if (!assets.includes(asset)) {
          assets.push(asset);
        }

        mapHierarchy.isNewMapHierarchy = false;
        mapHierarchy.mapHierarchy = FlatDataUtilities.updateItemSelection(mapHierarchy.mapHierarchy, asset, ECheckboxState.Checked, false);

        actions.push(new UpdateAssetSelection(assets), new UpdateAssetAsSelectedSuccess(mapHierarchy));

        return actions;
      })
    )
  );

  public getSearchLightAssetPlot$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSearchLightAssetPlot>(EAssetsActions.GetSearchLightAssetPlot),
      map(action => action.payload),
      map(asset => new UpdateAssetAsSelected(asset))
    )
  );

  public redirectToLocationHistory$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<RedirectToLocationHistory>(EAssetsActions.RedirectToLocationHistory),
        map(action => action.payload),
        filter(assetId => !isElementNull(assetId)),
        tap((assetId: number) => {
          const rootUrl = this._configService.config.legacyBaseUrl;
          this._windowRefService.nativeWindow.location.href = `${rootUrl}/assets-live/${assetId}/location-history`;
        })
      ),
    { dispatch: false }
  );

  constructor(
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _assetsHttpService: AssetsHttpService,
    private readonly _plotHttpService: PlotHttpService,
    private readonly _configService: ConfigService<IAppConfig>,
    private readonly _windowRefService: FmWindowRefService,
    private readonly _plotFormattingService: PlotFormattingService
  ) {}

  private _mapAssetsReponseToMapHierarchy(rootGroup: IRootGroupResponse, items: IAssetSelectionResponse[]): IMapHierarchyItem[] {
    const rootItem: IMapHierarchyItem = {
      isExpandable: true,
      id: `g${rootGroup.Id.toString()}`,
      level: 0,
      name: rootGroup.Name,
      type: ENodeType.Group
    };

    const result: IMapHierarchyItem[] = [rootItem];

    if (items.length) {
      const children = [] as IMapHierarchyItem[];
      items.forEach(asset => {
        children.push({
          isExpandable: false,
          id: `a${asset.id.toString()}`,
          level: 1,
          name: asset.label,
          state: asset.selected ? ECheckboxState.Checked : ECheckboxState.Unchecked,
          type: ENodeType.Asset
        });
      });

      result.push(...children);

      rootItem.state = items.every(x => x.selected)
        ? ECheckboxState.Checked
        : items.some(x => x.selected)
        ? ECheckboxState.Indeterminate
        : ECheckboxState.Unchecked;
    }

    return result;
  }

  private _getDistinctDbIdRequests(assetDetails: IAssetDetailResponse[]): IAssetPlotRequest[] {
    const distinctDbIdRequests = assetDetails.reduce((previous: IAssetPlotRequest[], current: IAssetDetailResponse) => {
      const existingRequest = previous.find(request => request.dbId === current.dbId);
      if (existingRequest) {
        existingRequest.vehicleTrackingIds.push(current.vehicleTrackingId);
      } else {
        previous.push({ dbId: current.dbId, vehicleTrackingIds: [current.vehicleTrackingId] });
      }
      return previous;
    }, []);

    return distinctDbIdRequests;
  }

  private _combinePlotWithDetails(
    plots: IAssetPlotResponse[][],
    details: IAssetDetailResponse[],
    userSettings: IUserSettings
  ): IAssetPlot[] {
    const flattenedPlotsResponse = plots.reduce((acc, val) => acc.concat(val), []);
    const assetPlots: IAssetPlot[] = flattenedPlotsResponse.map(plot => {
      const assetDetail = details.find(asset => {
        return asset.vehicleTrackingId === plot.vehicleTrackingId;
      });

      return {
        id: assetDetail.id,
        name: assetDetail.label,
        iconClass: EPlotIcon.Asset,
        center: { Latitude: plot.coordinate.latitude, Longitude: plot.coordinate.longitude },
        isFromSearchLight: false,
        address: plot.address.addressLine1,
        lastUpdate: this._plotFormattingService.formatLastUpdateForDisplay(plot.lastUpdateTicks, userSettings),
        ticks: plot.lastUpdateTicks,
        type: EPlotType.asset
      };
    });

    return assetPlots;
  }
}
