/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { IVehicleDriverPanelState } from '../state';
import { EVehicleDriverPanelTabs, Position, IMapContextMenuData, MarkerInfo } from '../../models';

export const getVehicleDriverPanelState = createFeatureSelector<IVehicleDriverPanelState>('vehicleDriverPanel');

export const getVehicleDriverPanel = createSelector(getVehicleDriverPanelState, state => state);

export const getIsVehicleDriverPanelCollapsed = createSelector(
  getVehicleDriverPanel,
  (state: IVehicleDriverPanelState): boolean => state.isCollapsed
);

export const getVehicleDriverPanelActiveTab = createSelector(
  getVehicleDriverPanel,
  (state: IVehicleDriverPanelState): EVehicleDriverPanelTabs => state.activeTab
);

export const getVehicleDriverOverlayMargins = createSelector(
  getVehicleDriverPanel,
  (state: IVehicleDriverPanelState): Position => (!isElementNull(state) ? state.overlayMargins : null)
);

export const getContextMenu = createSelector(
  getVehicleDriverPanel,
  (state: IVehicleDriverPanelState): IMapContextMenuData<MarkerInfo> => (!isElementNull(state) ? state.contextMenu : null)
);
