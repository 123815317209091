/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IUserDataResponse } from '../../core/models';
import { DemoDtoMappingService, DemoDataResolverService } from '../services';

@Injectable()
export class UserDataHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly _demoDtoMappingService: DemoDtoMappingService,
    private readonly _demoDataResolver: DemoDataResolverService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.endsWith('/User/GetUserMapSettings')) {
      return this._demoDataResolver.isReady$.pipe(
        map(() => this._demoDataResolver.initialAppStateData.userMapSettings),
        map((userMapSettings): IUserDataResponse => this._demoDtoMappingService.userMapSettingsToUserDataResponse(userMapSettings)),
        map(
          userDataResponse => new HttpResponse<IUserDataResponse>({ body: userDataResponse, status: 200 })
        )
      );
    }
    return next.handle(req);
  }
}
