/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, iif, timer, throwError, of, BehaviorSubject } from 'rxjs';
import { map, switchMap, retryWhen, concatMap, filter, catchError, withLatestFrom } from 'rxjs/operators';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, ofType, Actions } from '@ngrx/effects';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { UserDataHttpService } from '../../services';
import {
  IUserDataResponse,
  IUserPermissions,
  IUserFeatures,
  IFeaturesResponse,
  IPermissionsResponse,
  INotificationPreferencesResponse,
  IUserSettings,
  EPermissionId,
  EFeatureId
} from '../../models';
import { INotificationPreferences } from '../../components/notifications';
import {
  EUserMapSettingsActions,
  GetUserMapSettingsSuccess,
  GetUserMapSettings,
  GetReplayUserSettings,
  GetReplayUserSettingsSuccess,
  SetUserSettingsTokenValue
} from '../actions';
import { IUserMapSettingsState, IAppState } from '../state';
import { delayedRetry } from '../../operators';
import { getReplayUserSettings } from '../selectors';
import { USER_SETTINGS_TOKEN } from '../../tokens';

@Injectable()
export class UserMapSettingsEffects {

  public getUserMapSettings$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetUserMapSettings>(EUserMapSettingsActions.GetUserMapSettings),
    switchMap(() =>
      this._userDataHttpService
        .getUserPermissions()
        .pipe(
          retryWhen(errors =>
            errors.pipe(concatMap((error, i) => iif(() => isPlatformBrowser(this._platformId), timer(i * 300), throwError(error))))
          )
        )
    ),
    map((response: IUserDataResponse) => this._mapUserDataResponseToStateModel(response)),
    map((userMapSettings: IUserMapSettingsState) => new GetUserMapSettingsSuccess(userMapSettings))
  ));

  public getReplayUserSettings$ = createEffect(() => this._actions$.pipe(
    ofType<GetReplayUserSettings>(EUserMapSettingsActions.GetReplayUserSettings),
    filter(() => isPlatformBrowser(this._platformId)),
    withLatestFrom(this._store.pipe(select(getReplayUserSettings))),
    filter(([, replayUserSettings]) => isElementNull(replayUserSettings)),
    switchMap(() =>
      this._userDataHttpService.getReplaySettings().pipe(
        delayedRetry(),
        catchError(() => {
          return of(null);
        })
      )
    ),
    filter(response => !isElementNull(response)),
    map(replayUserSettings => new GetReplayUserSettingsSuccess(replayUserSettings))
  ));

  public pushUserSettingsValueToToken$ = createEffect(() => this._actions$.pipe(
    ofType<SetUserSettingsTokenValue>(EUserMapSettingsActions.SetUserSettingsTokenValue),
    map(action => action.payload),
    map(userSettings => {
      this._userSettingsToken.next(userSettings);
    })
  ), { dispatch: false });

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _userDataHttpService: UserDataHttpService,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    @Inject(USER_SETTINGS_TOKEN) private readonly _userSettingsToken: BehaviorSubject<IUserSettings>
  ) {}

  private _mapUserDataResponseToStateModel(response: IUserDataResponse): IUserMapSettingsState {
    response.UserSettings.TimeFormat = response.UserSettings.TimeFormat.replace('tt', 'a');
    return {
      accountId: response.AccountId,
      accountSettings: response.AccountSettings,
      features: this._getUserFeatures(response.Features),
      notificationPreferences: this._getNotificationPreferences(response.NotificationPreferences),
      permissions: this._getUserPermissions(response.Permissions),
      universalAccountId: response.UniversalAccountId,
      userRowGuid: response.RowGuid,
      userSettings: response.UserSettings,
      webSocketAccountId: response.WebSocketAccountId,
      webSocketUserId: response.WebSocketUserId,
      featureToggles: response.FeatureToggles
    };
  }

  private _getNotificationPreferences(preferences: INotificationPreferencesResponse[]): INotificationPreferences[] {
    const preferencesRefined: INotificationPreferences[] = [];

    if (!isElementNull(preferences)) {
      preferences.forEach(preference => {
        preferencesRefined.push({
          audioEnabled: preference.AudioEnabled,
          type: preference.Type,
          temporaryPopup: preference.TemporaryPopup,
          stationaryPopup: preference.StationaryPopup,
          numberBadge: preference.NumberBadge
        });
      });
    }

    return preferencesRefined;
  }

  private _getUserFeatures(features: IFeaturesResponse[]): IUserFeatures {
    const mapFeatures: Map<number, string> = new Map([
      [EFeatureId.CustomBalloons, 'customBalloons'],
      [EFeatureId.DrivingStyle, 'drivingStyle'],
      [EFeatureId.DriverApp, 'driverApp'],
      [EFeatureId.MisLive, 'misLive'],
      [EFeatureId.RealTimeTachoDate, 'realTimeTachoData'],
      [EFeatureId.Ecm, 'ecm'],
      [EFeatureId.DeviceStatusChange, 'deviceStatusChange'],
      [EFeatureId.AssetTracking, 'assets'],
      [EFeatureId.VideoExperience, 'videoExperience'],
      [EFeatureId.RoadSideAssistance, 'roadSideAssistance'],
      [EFeatureId.VideoAccess, 'videoAccess']
    ]);

    const resultFeatures = {} as IUserFeatures;

    mapFeatures.forEach((value: string, key: number) => {
      const userFeature = features.find(feature => feature.FeatureId === key);
      resultFeatures[value] = userFeature ? userFeature.EnabledStatus : false;
    });

    return resultFeatures;
  }

  private _getUserPermissions(permissions: IPermissionsResponse[]): IUserPermissions {
    const mapPermissions: Map<number, string> = new Map([
      [EPermissionId.ManageDrivers, 'manageDrivers'],
      [EPermissionId.ManageVehicles, 'manageVehicles'],
      [EPermissionId.ShowSpeedLimits, 'showSpeedLimits'],
      [EPermissionId.Places, 'places'],
      [EPermissionId.Dispatch, 'dispatch'],
      [EPermissionId.Geofences, 'geofences'],
      [EPermissionId.EnableIntegrations, 'enableIntegrations'],
      [EPermissionId.LiveMapFindNearest, 'liveMapFindNearest'],
      [EPermissionId.LiveMapSendStopsToInVehicleNavigationDevices, 'liveMapSendStopsToInVehicleNavigationDevices'],
      [EPermissionId.Replay, 'replay'],
      [EPermissionId.LiveMapWorkOrders, 'liveMapWorkOrders'],
      [EPermissionId.LiveMapWorkOrdersAssigned, 'liveMapWorkOrdersAssigned'],
      [EPermissionId.LiveMapWorkOrdersUnassigned, 'liveMapWorkOrdersUnassigned'],
      [EPermissionId.DispatchWindow, 'dispatchWindow'],
      [EPermissionId.DispatchWindowMessages, 'dispatchWindowMessages'],
      [EPermissionId.DispatchWindowStops, 'dispatchWindowStops'],
      [EPermissionId.Directions, 'directions'],
      [EPermissionId.DirectionsSendToVehicle, 'directionsSendToVehicle'],
      [EPermissionId.Alerts, 'alerts'],
      [EPermissionId.ReportDetailed, 'reportDetailed'],
      [EPermissionId.ReportJourneyAndStops, 'reportJourneyAndStops'],
      [EPermissionId.LiveMapTextMessaging, 'liveMapTextMessaging'],
      [EPermissionId.ShowVehicleSpeed, 'showVehicleSpeed'],
      [EPermissionId.ManagePlaces, 'managePlaces'],
      [EPermissionId.SuggestedGeofences, 'suggestedGeofences'],
      [EPermissionId.CreatePlaceFromMap, 'createPlaceFromMap'],
      [EPermissionId.ManageDriverDispatch, 'manageDriverDispatch'],
      [EPermissionId.RoutingLiveMap, 'routingLiveMap'],
      [EPermissionId.ShowTowingNotifications, 'showTowingNotifications'],
      [EPermissionId.ShowGPSSignalStrength, 'showGPSSignalStrength'],
      [EPermissionId.AssetTracking, 'assetTracking'],
      [EPermissionId.ManageAssets, 'manageAssets'],
      [EPermissionId.DriverPlannerPage, 'driverPlannerPage'],
      [EPermissionId.RoadSideAssistance, 'roadSideAssistance'],
      [EPermissionId.Scheduler, 'scheduler'],
      [EPermissionId.VideoExperience, 'videoExperience'],
      [EPermissionId.VideoAccess, 'videoAccess'],
      [EPermissionId.ECMFuelLevel, 'ecmFuelLevel']
    ]);

    const resultPermissions = permissions.reduce((result, element) => {
      result[mapPermissions.get(element.PermissionId)] = element.Status;
      return result;
    }, {} as IUserPermissions);

    return resultPermissions;
  }
}
