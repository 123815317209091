/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { initialSearchState, ISearchState } from '../state/search.state';
import { SearchActions, ESearchActions } from '../actions';
import { ISearchEntity } from '../../models';

export function searchReducer(state = initialSearchState, action: SearchActions): ISearchState {
  switch (action.type) {
    case ESearchActions.GetSearchEntitiesSuccess: {
      return {
        ...state,
        searchEntities: action.payload,
        filteredEntities: filterEntitiesByName(action.payload, state.searchInput)
      };
    }

    case ESearchActions.SetSearchInput: {
      return {
        ...state,
        searchInput: action.payload,
        filteredEntities: filterEntitiesByName(state.searchEntities, action.payload)
      };
    }

    default:
      return state;
  }
}

function filterEntitiesByName(entities: ISearchEntity[], filterString: string): ISearchEntity[] {
  if (filterString === '') {
    return entities;
  }
  return entities.filter(entity => entity.name.toLowerCase().includes(filterString.toLowerCase()));
}
