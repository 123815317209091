/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { INavbarState } from '@fleetmatics/ui.navbar';

export const initialNavbarStateData = {
  accountMenu: {
    userDetails: {
      imageName: 'assets/images/ic_fab_guide.png',
      fullName: 'Demo User',
      email: null,
      companyName: 'Demo Company',
      isVerizonBrandingEnabled: false,
      brand: 'Reveal Plus (Reveal)',
      firstName: 'Demo',
      lastName: 'User',
      phoneNumber: null,
      universalAccountId: 'DemoAccountId',
      userName: 'demo@account.com',
      userInitials: 'DA',
      isVerizonNewTickBrandingEnabled: true
    },
    bannerTranslations: {
      S_LIVE_MAP: 'Live Map',
      S_REPORTS: 'Reports',
      S_REPLAY: 'Replay',
      S_VIDEO: 'Video',
      S_PLACES: 'Places',
      S_ALERTS: 'Alerts',
      S_DASHBOARD: 'Dashboard',
      S_ROUTIST: 'Routist',
      S_SERVICING: 'Fleet Service',
      S_LOGBOOK: 'LogBook',
      S_JOURNEYCLASSIFICATION_BANNER: 'Trip Logger',
      S_MYFLEETMATICS: 'My Benchmarks',
      S_ADMIN: 'Admin',
      S_REFER_A_FRIEND: 'Refer a Friend',
      S_JOYRIDE: 'Help me with this page',
      S_HELPFILESUSERGUIDE: 'Help Centre',
      S_HELP_MENU_LIVE_TRAINING: 'Live Training',
      S_CONTACTUS: 'Contact Us',
      S_LOGOUT: 'Log Out',
      S_HEADER_ACCOUNTID: 'Account ID:',
      S_HEADER_ACCOUNT_BUTTON: 'MY ACCOUNT',
      S_HELP_MENU_LIVE_TRAINING_ONOFF: '1',
      S_SCHEDULER: 'Scheduler',
      S_TACHO_MENU_ITEM_TEXT: 'Tacho',
      S_FULLNAME_CONTACTUS: 'Full Name',
      S_COMPANY: 'Company',
      S_PHONENUMBER: 'Phone Number',
      S_EMAILADD: 'Email address',
      S_MESSAGE: 'Message',
      S_COMPLETEALLFIELDS: 'Please complete all fields before submitting your feedback. ',
      S_ERR_SEND_FEEDBACK: 'There was a problem with sending your email, please try again.',
      S_CANCEL: 'Cancel',
      S_SUBMIT: 'Submit',
      S_THANKYOUREQUEST: 'Thank you for submitting your request. We strive to respond within 24 hours.',
      S_CLOSE: 'Close',
      V_EMAIL: 'Please enter a valid email address',
      S_MARKETPLACE: 'Marketplace',
      S_RSA_TILE: 'Roadside Assistance',
      S_HELP: 'Help',
      S_HELP_MENU_HELP_AND_TRAINING: 'Help & Training'
    },
    navbarUrls: {
      referFriendUrl: 'https://fleetrefer.fleetmatics.com/micro/microsite?source=reveal_home&f=Delta&e=delta@automation.com',
      liveTrainingUrl: '//dev.development.fleetmatics.com//help/en-ie/index.html#trainingschedule',
      helpGuideUrl: '//dev.development.fleetmatics.com//help/en-ie/index.html',
      orderEquipmentUrl: '//dev.development.fleetmatics.com//myaccount/selfserviceportal/ordertrackingequipment',
      rootUrl: '/map-beta/',
      contactUsUrl: 'http://dev.development.fleetmatics.com/en-IE/myaccount-live/contact-us',
      personalInfoUrl: '//dev.development.fleetmatics.com//pii-requests/en-ie/summary',
      myAccountMicrositeUrl: '//dev.development.fleetmatics.com/en-US/myaccount-live'
    },
    userPermissions: {
      myAccount: false,
      myFleetmatics: false,
      marketplace: false,
      admin: false,
      liveChat: false,
      referAFriend: false,
      liveTraining: false,
      roadsideAssistance: false,
      orderEquipment: false,
      personalInfoRequests: false
    }
  },
  menuItems: {
    items: {
      '0': {
        displayText: 'Live Map',
        displayTextItalian: 'Mappa live',
        displayTextSpanish: 'Mapa en vivo',
        displayTextPortuguese: 'Mapa Interactivo',
        displayTextFrench: 'Carte en direct',
        displayTextGerman: 'Live-Karte',
        displayTextDutch: 'Live kaart',
        displayTextPolish: 'Mapa',
        subtitleText: 'See where vehicles are and what they’re doing.',
        subtitleTextItalian: 'Guarda dove si trovano i veicoli e cosa stanno facendo.',
        subtitleTextSpanish: 'Consulte dónde se encuentran los vehículos y lo que están haciendo.',
        subtitleTextPortuguese: 'Veja onde os seus veículos estão e o que estão a fazer.',
        subtitleTextFrench: 'Découvrez où se trouvent vos véhicules et leurs déplacements.',
        subtitleTextGerman: 'Zeigen Sie an, wo sich Fahrzeuge befinden und was sie tun.',
        subtitleTextDutch: 'Bekijk waar voertuigen zijn en wat ze doen.',
        subtitleTextPolish: 'Zobacz, gdzie są pojazdy i co się z nimi dzieje.',
        unreadBadge: false,
        iconClass: 'ds-header-map',
        href: '/map-beta/',
        isActiveRoute: true,
        notificationEndpoint: null,
        isDisabledInMobile: false
      },
      '1': {
        displayText: 'Reports',
        displayTextItalian: 'Report',
        displayTextSpanish: 'Informes',
        displayTextPortuguese: 'Relatórios',
        displayTextFrench: 'Rapports',
        displayTextGerman: 'Berichte',
        displayTextDutch: 'Rapporten',
        displayTextPolish: 'Raporty',
        subtitleText: 'Get valuable fleet insights with Reports.',
        subtitleTextItalian: 'Ottieni preziose informazioni sulla flotta con Report.',
        subtitleTextSpanish: 'Obtenga información valiosa de la flota con Informes.',
        subtitleTextPortuguese: 'Obtenha informações valiosas sobre a frota com os Relatórios.',
        subtitleTextFrench: `Bénéficiez d'indications très utiles sur votre flotte grâce à la fonction Rapports.`,
        subtitleTextGerman: 'Erhalten Sie wertvolle Erkenntnisse über den Fuhrpark durch Berichte.',
        subtitleTextDutch: 'Krijg waardevolle inzichten in het wagenpark met Rapporten.',
        subtitleTextPolish: 'Ważna możliwość wglądu we flotę dzięki Raportom.',
        unreadBadge: false,
        iconClass: 'ds-header-reports',
        href: '/reports',
        isActiveRoute: false,
        notificationEndpoint: null,
        isDisabledInMobile: true,
        id: 'nav-reports'
      },
      '2': {
        displayText: 'Replay',
        displayTextItalian: 'Percorso',
        displayTextSpanish: 'Reproducir',
        displayTextPortuguese: 'Reprodução de Rota',
        displayTextFrench: 'Replay',
        displayTextGerman: 'Verlauf',
        displayTextDutch: 'Herhalen',
        displayTextPolish: 'Historia',
        subtitleText: 'Re-run your fleet’s journeys with Replay.',
        subtitleTextItalian: 'Percorri nuovamente il tragitto della tua flotta con Percorso.',
        subtitleTextSpanish: 'Vuelva a ejecutar el viaje de su flota con Reproducir.',
        subtitleTextPortuguese: 'Reveja as viagens da sua frota através da Reprodução.',
        subtitleTextFrench: 'Retracez les déplacements de votre flotte grâce à la fonction Replay.',
        subtitleTextGerman: 'Absolvieren Sie die Fahrten Ihres Fuhrparks mit dem Verlauf noch einmal.',
        subtitleTextDutch: 'Speel de reizen van uw wagenpark opnieuw af met Opnieuw afspelen.',
        subtitleTextPolish: 'Powtarzaj podróże floty dzięki funkcji Powtórz.',
        unreadBadge: false,
        iconClass: 'ds-header-replay',
        href: '/replay',
        isActiveRoute: false,
        notificationEndpoint: null,
        isDisabledInMobile: false
      },
      '3': {
        displayText: 'Places',
        displayTextItalian: 'Luoghi',
        displayTextSpanish: 'Puntos de interés',
        displayTextPortuguese: 'Locais',
        displayTextFrench: 'Lieux',
        displayTextGerman: 'Orte',
        displayTextDutch: 'Plaatsen',
        displayTextPolish: 'Miejsca',
        subtitleText: 'Manage and monitor important locations.',
        subtitleTextItalian: 'Gestisci e monitora le posizioni importanti.',
        subtitleTextSpanish: 'Gestione y monitoree ubicaciones importantes.',
        subtitleTextPortuguese: 'Faça a gestão e monitorize localizações importantes.',
        subtitleTextFrench: 'Gérez et surveillez les lieux importants.',
        subtitleTextGerman: 'Verwalten und überwachen Sie wichtige Standorte.',
        subtitleTextDutch: 'Beheer en controleer belangrijke locaties',
        subtitleTextPolish: 'Zarządzaj ważnymi miejscami i monitoruj je.',
        unreadBadge: false,
        iconClass: 'ds-header-places',
        href: '/places-beta/',
        isActiveRoute: false,
        notificationEndpoint: null,
        isDisabledInMobile: false
      },
      '4': {
        displayText: 'Alerts',
        displayTextItalian: 'Allarmi',
        displayTextSpanish: 'Alertas',
        displayTextPortuguese: 'Alertas',
        displayTextFrench: 'Alertes',
        displayTextGerman: 'Warnmeldungen',
        displayTextDutch: 'Alarmen',
        displayTextPolish: 'Alerty',
        subtitleText: 'Get instant event notifications with Alerts.',
        subtitleTextItalian: 'Ottieni notifiche istantanee sugli eventi con Allarmi.',
        subtitleTextSpanish: 'Obtenga notificaciones instantáneas de incidentes con Alertas.',
        subtitleTextPortuguese: 'Obtenha notificações instantâneas de eventos com os Alertas.',
        subtitleTextFrench: `Bénéficiez de notifications d'événements instantanées grâce à la fonction Alertes.`,
        subtitleTextGerman: 'Erhalten Sie sofortige Benachrichtigungen über Ereignisse mit Warnmeldungen.',
        subtitleTextDutch: 'Krijg direct meldingen van gebeurtenissen met Waarschuwingen.',
        subtitleTextPolish: 'Otrzymuj natychmiastowe powiadomienia o zdarzeniach dzięki funkcji Alerty.',
        unreadBadge: false,
        iconClass: 'ds-header-alerts',
        href: '/alerts',
        isActiveRoute: false,
        notificationEndpoint: '/Notification/GetAlertsNotifications',
        isDisabledInMobile: false
      },
      '5': {
        displayText: 'Scheduler',
        displayTextItalian: 'Pianificatore',
        displayTextSpanish: 'Programador',
        displayTextPortuguese: 'Agenda',
        displayTextFrench: 'Dispatch',
        displayTextGerman: 'Zeitplaner',
        displayTextDutch: 'Taakplanner',
        displayTextPolish: 'Planer zleceń',
        subtitleText: 'Organise your team and make scheduling decisions based on your technicians’ near real time updates.',
        // tslint:disable: max-line-length
        subtitleTextItalian:
          'Organizza il tuo team e prendi decisioni più mirate sulla base degli aggiornamenti dei tuoi tecnici in tempo quasi reale.',
        subtitleTextSpanish:
          'Organice su equipo y tome decisiones de programación con base en las actualizaciones de su técnico prácticamente en tiempo real.',
        subtitleTextPortuguese: 'Organize a sua equipa e programe a agenda com base nas atualizações dos técnicos quase em tempo real.',
        subtitleTextFrench:
          'Organisez votre équipe et prenez des décisions concernant le planning en fonction des mises à jour de vos intervenants en temps quasi réel.',
        subtitleTextGerman:
          'Organisieren Sie Ihr Team und treffen Sie Planungsentscheidungen nahezu in Echtzeit auf Grundlage der Updates Ihrer Techniker.',
        subtitleTextDutch: 'Organiseer uw team en maak de planning op basis van de updates van uw monteurs.',
        subtitleTextPolish:
          'Organizuj pracę swojego zespołu i planuj działania w oparciu o informacje dotyczące pracowników aktualizowane w czasie zbliżonym do rzeczywistego.',
        // tslint:enable: max-line-length
        unreadBadge: false,
        iconClass: 'ds-header-scheduler',
        href: '/fsd/',
        isActiveRoute: false,
        notificationEndpoint: null,
        isDisabledInMobile: true,
        id: 'nav-scheduler',
        addOn: true
      }
    },
    selectedItemPattern: false
  },
  plans: [
    {
      type: 60,
      isEnabled: false
    },
    {
      type: 75,
      isEnabled: true
    },
    {
      type: 78,
      isEnabled: true
    },
    {
      type: 95,
      isEnabled: false
    },
    {
      type: 99,
      isEnabled: true
    },
    {
      type: 104,
      isEnabled: true
    }
  ],
  contactUs: {
    contactUsInfo: {
      contactDetails: '',
      description: ''
    },
    wasFeedbackSubmissionSuccessful: false
  },
  helpPanel: {
    isHelpPanelEnabled: true,
    isHelpPanelOpened: false
  }
} as INavbarState;
