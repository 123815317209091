/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom, tap, switchMap, filter, catchError, take, delay } from 'rxjs/operators';

import { isElementNull, FmWindowRefService } from '@fleetmatics/ui.utilities';

import {
  UpdateIsFullscreen,
  ToggleFullscreen,
  ELayoutActions,
  TriggerTextview,
  TriggerGarmin,
  TriggerDriverDispatch,
  TriggerCreateGeofence,
  TriggerFindNearest,
  TriggerGetDirectionsFrom,
  TriggerGetDirectionsTo,
  ZoomToLocation,
  UpdateZoomToLocation,
  TriggerViewReplay,
  TriggerRunReport,
  TriggerEditVehicle,
  TriggerEditDriver,
  TriggerViewGarminWindow,
  TriggerEditPlace,
  TriggerWorkOrderStatusHistory,
  TriggerWorkOrderDetails,
  TriggerViewMetrics,
  TryUpdateZoomToMarker,
  UpdateZoomToMarker,
  TriggerEditAsset,
  TriggerGetDirectionsFromVehicle,
  TriggerGetDirectionsToVehicle,
  TriggerCreateGeofenceForVehicle as TriggerCreateGeofenceOnVehicle,
  OpenReplayOnLiveMap,
  UpdateAnimationState,
  CloseReplayOnLiveMap,
  UpdateIsStreetViewOpen,
  CloseBalloon,
  UpdateIsAppAnimationsEnabled,
  ChangeMapBounds,
  SetPageTitle,
  SetReplayPageTitleFromVehicleId,
  UpdateViewReplayVehicleId,
  EBalloonInfoActions,
  RemoveZoomToMarker
} from '../actions';
import { IAppState, EMapAnimationState } from '../state';
import {
  getIsFullscreen,
  getZoomToLocation,
  getIsStreetViewOpen,
  getDeviceStatusTowedVehicles,
  getVehiclePlots,
  getAnimationState,
  getMapBounds,
  getReplayLayout
} from '../selectors';
import {
  BrowserLocationService,
  ModalWindowService,
  RealTimeDataHttpService,
  GoogleMapsService,
  WindowResizeService
} from '../../services';
import {
  ILatLng,
  IRunReportParams,
  IEditPlaceParams,
  IViewGarminWindowParams,
  IEditVehicleParams,
  IEditDriverParams,
  IFindDirectionParams,
  IFindNearestParams,
  ICreateGeofenceParams,
  IWorkOrderParams,
  IViewMetricsParams,
  IViewReplayParameters,
  IEditAssetParams,
  IVehiclePlot,
  LatLngBounds
} from '../../models';
import { delayedRetry } from '../../operators';
import { StopNonPanicNotifications, StartNonPanicNotifications } from '../../components';

@Injectable()
export class LayoutEffects {
  private readonly _placesPath = '/places/edit/default.aspx';
  private readonly _findNearestPath = '/map/findnearest/default.aspx';

  public zoomToLocation$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<ZoomToLocation>(ELayoutActions.ZoomToLocation),
      withLatestFrom(this._store.pipe(select(getZoomToLocation)), (_action, state) => state),
      map((locationToZoom: ILatLng) => new UpdateZoomToLocation(locationToZoom))
    )
  );

  public tryUpdateZoomToMarker$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<TryUpdateZoomToMarker>(ELayoutActions.TryUpdateZoomToMarker),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getIsStreetViewOpen))),
      switchMap(([params, isStreetViewOpen]) => {
        if (isStreetViewOpen) {
          return this._googleMapsService.isStreetViewAvailable(new google.maps.LatLng(params.latLng.lat, params.latLng.lng)).pipe(
            filter(isStreetViewAvailable => isStreetViewAvailable),
            map(() => new UpdateZoomToMarker(params))
          );
        }
        return of(new UpdateZoomToMarker(params));
      })
    )
  );

  public toggleFullscreen$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<ToggleFullscreen>(ELayoutActions.ToggleFullscreen),
      withLatestFrom(this._store.pipe(select(getIsFullscreen)), (_action, state) => state),
      map((isFullscreen: boolean) => new UpdateIsFullscreen(!isFullscreen))
    )
  );

  public triggerTextview$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerTextview>(ELayoutActions.TriggerTextview),
        withLatestFrom(this._store.pipe(select(getDeviceStatusTowedVehicles))),
        tap(([, dsnTowedVehicles]) => {
          if (dsnTowedVehicles.length > 0) {
            const window = <IFleetmaticsWindow>this._windowRefService.nativeWindow;
            window.fmDeviceStatus = { dsnTowedVehicles: dsnTowedVehicles };
          }
          this._modalWindowService.openWithPostCentered('/map/textview/default.aspx', 'textview');
        })
      ),
    { dispatch: false }
  );

  public triggerGarmin$: Observable<Action> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerGarmin>(ELayoutActions.TriggerGarmin),
        tap(() => {
          this._modalWindowService.openWithPostCentered('/garmin/default.aspx', 'garmin');
        })
      ),
    { dispatch: false }
  );

  public triggerDriverDispatch$: Observable<Action> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerDriverDispatch>(ELayoutActions.TriggerDriverDispatch),
        tap(() => {
          this._modalWindowService.openWithPostCentered('/routing/stops/default.aspx', 'dispatch');
        })
      ),
    { dispatch: false }
  );

  public triggerCreateGeofence$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerCreateGeofence>(ELayoutActions.TriggerCreateGeofence),
        map((action: TriggerCreateGeofence) => action.payload),
        tap((latLng: ILatLng) => {
          const params: ICreateGeofenceParams = this._createCreateGeofenceParameters(latLng);

          this._modalWindowService.openWithPostCentered(this._placesPath, 'places', params);
        })
      ),
    { dispatch: false }
  );

  public triggerCreateVehicleGeofence$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerCreateGeofenceOnVehicle>(ELayoutActions.TriggerCreateGeofenceOnVehicle),
        map((action: TriggerCreateGeofenceOnVehicle) => action.payload),
        withLatestFrom(this._store.pipe(select(getVehiclePlots))),
        tap(([vehicleId, vehiclePlots]: [number, IVehiclePlot[]]) => {
          const vehiclePlot = vehiclePlots.find(plot => plot.id === vehicleId);

          const params: ICreateGeofenceParams = this._createCreateGeofenceParameters(vehiclePlot.coordinates);
          this._modalWindowService.openWithPostCentered(this._placesPath, 'places', params);
        })
      ),
    { dispatch: false }
  );

  public triggerFindNearest$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerFindNearest>(ELayoutActions.TriggerFindNearest),
        map((action: TriggerFindNearest) => action.payload),
        tap((params: IFindNearestParams) => {
          this._modalWindowService.openWithPostCentered(this._findNearestPath, 'findNearest', params);
        })
      ),
    { dispatch: false }
  );

  public triggerViewMetrics$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerViewMetrics>(ELayoutActions.TriggerViewMetrics),
        map((action: TriggerViewMetrics) => action.payload),
        tap((params: IViewMetricsParams) => {
          this._modalWindowService.openWithPostCentered(this._placesPath, 'Places', params);
        })
      ),
    { dispatch: false }
  );

  public triggerGetDirectionsFrom$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerGetDirectionsFrom>(ELayoutActions.TriggerGetDirectionsFrom),
        map(action => action.payload),
        tap((coordinates: ILatLng) => {
          const params: IFindDirectionParams = this._createFindDirectionParameters(coordinates, true);
          this._modalWindowService.openWithPostCentered(this._findNearestPath, 'directions', params);
        })
      ),
    { dispatch: false }
  );

  public triggerGetDirectionsTo$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerGetDirectionsTo>(ELayoutActions.TriggerGetDirectionsTo),
        map(action => action.payload),
        tap((coordinates: ILatLng) => {
          const params: IFindDirectionParams = this._createFindDirectionParameters(coordinates, false);
          this._modalWindowService.openWithPostCentered(this._findNearestPath, 'directions', params);
        })
      ),
    { dispatch: false }
  );

  public triggerGetDirectionsFromVehicle$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerGetDirectionsFromVehicle>(ELayoutActions.TriggerGetDirectionsFromVehicle),
        map((action: TriggerGetDirectionsFromVehicle) => action.payload),
        withLatestFrom(this._store.pipe(select(getVehiclePlots))),
        tap(([vehicleId, vehiclePlots]: [number, IVehiclePlot[]]) => {
          const vehiclePlot = vehiclePlots.find(plot => plot.id === vehicleId);

          const params: IFindDirectionParams = this._createFindDirectionParameters(vehiclePlot.coordinates, true);
          this._modalWindowService.openWithPostCentered(this._findNearestPath, 'directions', params);
        })
      ),
    { dispatch: false }
  );

  public triggerGetDirectionsToVehicle$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerGetDirectionsToVehicle>(ELayoutActions.TriggerGetDirectionsToVehicle),
        map((action: TriggerGetDirectionsToVehicle) => action.payload),
        withLatestFrom(this._store.pipe(select(getVehiclePlots))),
        tap(([vehicleId, vehiclePlots]: [number, IVehiclePlot[]]) => {
          const vehiclePlot = vehiclePlots.find(plot => plot.id === vehicleId);

          const params: IFindDirectionParams = this._createFindDirectionParameters(vehiclePlot.coordinates, false);
          this._modalWindowService.openWithPostCentered(this._findNearestPath, 'directions', params);
        })
      ),
    { dispatch: false }
  );

  public triggerViewReplay$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerViewReplay>(ELayoutActions.TriggerViewReplay),
        map(action => action.payload),
        withLatestFrom(this._store.pipe(select(getMapBounds)), this._store.pipe(select(getIsFullscreen))),
        tap(([viewReplayParameters, mapBounds, isFullscreen]) => {
          const replayOnLiveMapParams: IViewReplayParameters = {
            ...viewReplayParameters,
            isFullscreen: isFullscreen,
            lastBounds: mapBounds
          };

          this._store.dispatch(new OpenReplayOnLiveMap(replayOnLiveMapParams));
        })
      ),
    { dispatch: false }
  );

  public onOpenCloseReplayOnLiveMapDisableAnimations$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CloseReplayOnLiveMap | OpenReplayOnLiveMap>(ELayoutActions.CloseReplayOnLiveMap, ELayoutActions.OpenReplayOnLiveMap),
      withLatestFrom(this._windowResize.isMobileOrTablet$),
      filter(
        ([action, isMobileOrTablet]) =>
          isMobileOrTablet || (action.type === ELayoutActions.OpenReplayOnLiveMap && action.payload?.disableAnimation)
      ),
      map(() => new UpdateIsAppAnimationsEnabled(false))
    )
  );

  public onOpenReplayOnLiveMap$ = createEffect(() =>
    this._actions$.pipe(
      ofType<OpenReplayOnLiveMap>(ELayoutActions.OpenReplayOnLiveMap),
      map(action => action.payload),
      switchMap(params => [
        new UpdateAnimationState(EMapAnimationState.overlayHidden),
        new UpdateIsFullscreen(true),
        new StopNonPanicNotifications(),
        new UpdateIsStreetViewOpen(false),
        new CloseBalloon(),
        new SetReplayPageTitleFromVehicleId(params.id)
      ])
    )
  );

  public onCloseReplayOnLiveMapAnimationTransition$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CloseReplayOnLiveMap>(ELayoutActions.CloseReplayOnLiveMap),
      switchMap(() =>
        this._store.pipe(
          select(getAnimationState),
          filter(state => state === EMapAnimationState.overlayHidden),

          take(1)
        )
      ),
      withLatestFrom(this._store.pipe(select(getReplayLayout))),
      switchMap(([, replayLayout]) => [
        new UpdateAnimationState(EMapAnimationState.default),
        replayLayout.viewReplayParams.isFullscreen ? new UpdateIsFullscreen(true) : new UpdateIsFullscreen(false)
      ])
    )
  );

  public onCloseReplayOnLiveMapRestoreBounds$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CloseReplayOnLiveMap>(ELayoutActions.CloseReplayOnLiveMap),
      switchMap(() =>
        this._store.pipe(
          select(getAnimationState),
          filter(state => state === EMapAnimationState.default),
          take(1)
        )
      ),
      withLatestFrom(this._store.pipe(select(getReplayLayout))),
      delay(1000),
      map(([, replayLayout]) => new ChangeMapBounds(LatLngBounds.toLatLngs(replayLayout.viewReplayParams.lastBounds)))
    )
  );

  public onCloseReplayTryAndCloseStreetView$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CloseReplayOnLiveMap>(ELayoutActions.CloseReplayOnLiveMap),
      withLatestFrom(this._store.pipe(select(getIsStreetViewOpen))),
      filter(([, isStreetViewOpen]) => isStreetViewOpen),
      map(() => new UpdateIsStreetViewOpen(false))
    )
  );

  public onCloseReplayStartNonPanicNotifications$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CloseReplayOnLiveMap>(ELayoutActions.CloseReplayOnLiveMap),
      map(() => new StartNonPanicNotifications())
    )
  );

  public onOpenReplayBackButtonBehaviour$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<OpenReplayOnLiveMap>(ELayoutActions.OpenReplayOnLiveMap),
        withLatestFrom(this._windowResize.isDesktop$, this._store.pipe(select(getReplayLayout))),
        filter(([, isDesktop, replayLayout]) => isDesktop && !replayLayout.viewReplayParams?.onCloseNavigateToHome),
        tap(() => this._browserLocationService.onBackButtonCloseReplay())
      ),
    { dispatch: false }
  );

  public onCloseReplayBackButtonBehaviour$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<CloseReplayOnLiveMap>(ELayoutActions.CloseReplayOnLiveMap),
        withLatestFrom(this._windowResize.isDesktop$, this._store.pipe(select(getReplayLayout))),
        filter(([, isDesktop, replayLayout]) => isDesktop && !replayLayout.viewReplayParams?.onCloseNavigateToHome),
        tap(() => this._browserLocationService.navigateToFirstPage())
      ),
    { dispatch: false }
  );

  public onCloseReplayNavigateToHome$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<CloseReplayOnLiveMap>(ELayoutActions.CloseReplayOnLiveMap),
        withLatestFrom(this._store.pipe(select(getReplayLayout))),
        filter(([, replayLayout]) => replayLayout.viewReplayParams?.onCloseNavigateToHome),
        tap(() => (this._windowRefService.nativeWindow.location.href = '/'))
      ),
    { dispatch: false }
  );

  public onFullscreen$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsFullscreen>(ELayoutActions.UpdateIsFullscreen),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getAnimationState))),
      switchMap(([isFullscreen, animationState]) => {
        if (isFullscreen && animationState === EMapAnimationState.default) {
          return [new UpdateAnimationState(EMapAnimationState.fullscreen)];
        }
        if (!isFullscreen && animationState === EMapAnimationState.fullscreen) {
          return [new UpdateAnimationState(EMapAnimationState.default)];
        }
        return [];
      })
    )
  );

  public triggerRunReport$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerRunReport>(ELayoutActions.TriggerRunReport),
        map((action: TriggerRunReport) => action.payload),
        tap((params: IRunReportParams) => {
          this._realTimeDataHttpService
            .getLiveMapReportTicket(params.vehicleId, params.reportType)
            .pipe(
              delayedRetry(),
              catchError(() => of(null)),
              filter(response => !isElementNull(response)),
              take(1)
            )
            .subscribe((ticket: string) => {
              this._modalWindowService.redirectToUrl(`/report/default.aspx?runreportfromlivemap=${ticket}`);
            });
        })
      ),
    { dispatch: false }
  );

  public triggerEditDriver$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerEditDriver>(ELayoutActions.TriggerEditDriver),
        map((action: TriggerEditDriver) => action.payload),
        tap((params: IEditDriverParams) => {
          this._modalWindowService.redirectWithPost('/admin/drivers/default.aspx', '', params);
        })
      ),
    { dispatch: false }
  );

  public triggerEditVehicle$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerEditVehicle>(ELayoutActions.TriggerEditVehicle),
        map((action: TriggerEditVehicle) => action.payload),
        tap((params: IEditVehicleParams) => {
          this._modalWindowService.redirectWithPost('/admin/vehicles/default.aspx', '', params);
        })
      ),
    { dispatch: false }
  );

  public triggerEditAsset$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerEditAsset>(ELayoutActions.TriggerEditAsset),
        map((action: TriggerEditAsset) => action.payload),
        tap((params: IEditAssetParams) => {
          this._modalWindowService.redirectWithPost('/admin/assets/default.aspx', '', params);
        })
      ),
    { dispatch: false }
  );

  public triggerViewGarminWindow$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerViewGarminWindow>(ELayoutActions.TriggerViewGarminWindow),
        map((action: TriggerViewGarminWindow) => action.payload),
        tap((params: IViewGarminWindowParams) => {
          this._modalWindowService.openWithPostCentered('/garmin/default.aspx', 'Garmin', params);
        })
      ),
    { dispatch: false }
  );

  public triggerEditPlace$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerEditPlace>(ELayoutActions.TriggerEditPlace),
        map((action: TriggerEditPlace) => action.payload),
        tap((params: IEditPlaceParams) => {
          this._modalWindowService.openWithPostCentered(this._placesPath, 'Places', params);
        })
      ),
    { dispatch: false }
  );

  public triggerWorkOrderDetails$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerWorkOrderDetails>(ELayoutActions.TriggerWorkOrderDetails),
        map((action: TriggerWorkOrderDetails) => action.payload),
        tap((params: IWorkOrderParams) => {
          this._modalWindowService.openWithPostCentered('/map/workorder/default.aspx', 'directions', params);
        })
      ),
    { dispatch: false }
  );

  public triggerWorkOrderStatusHistory$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<TriggerWorkOrderStatusHistory>(ELayoutActions.TriggerWorkOrderStatusHistory),
        map((action: TriggerWorkOrderStatusHistory) => action.payload),
        tap((params: IWorkOrderParams) => {
          this._modalWindowService.openWithPostCentered('/workorder/statushistory/default.aspx', 'directions', params);
        })
      ),
    { dispatch: false }
  );

  public setReplayPageTitleForVehicleUpdate$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateViewReplayVehicleId>(ELayoutActions.UpdateViewReplayVehicleId),
      map(action => new SetReplayPageTitleFromVehicleId(action.payload))
    )
  );

  public setReplayPageTitleFromVehicleId$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SetReplayPageTitleFromVehicleId>(ELayoutActions.SetReplayPageTitleFromVehicleId),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getVehiclePlots))),
      map(([vehicleId, vehiclePlots]) => vehiclePlots.find(vehiclePlot => vehiclePlot.id === vehicleId)),
      filter(vehicle => !isElementNull(vehicle)),
      map(vehicle => new SetPageTitle(`${$localize`:@@S_REPLAY:Replay`} ${vehicle.vehicleName}`))
    )
  );

  public setPageTitleOnReplayClose$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CloseReplayOnLiveMap>(ELayoutActions.CloseReplayOnLiveMap),
      map(() => new SetPageTitle($localize`:@@S_SITE_PAGE_TITLE:Verizon Connect | GPS Fleet Tracking System`))
    )
  );

  public setPageTitle$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SetPageTitle>(ELayoutActions.SetPageTitle),
        map(action => action.payload),
        tap(title => {
          setTimeout(() => {
            // The title service seems to not update sometimes, using a timeout fixes it
            this._titleService.setTitle(title);
          }, 0);
        })
      ),
    { dispatch: false }
  );

  public onCloseBalloonRemoveZoomToMarker$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CloseBalloon>(EBalloonInfoActions.CloseBalloon),
      map(() => new RemoveZoomToMarker())
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _modalWindowService: ModalWindowService,
    private readonly _realTimeDataHttpService: RealTimeDataHttpService,
    private readonly _googleMapsService: GoogleMapsService,
    private readonly _windowRefService: FmWindowRefService,
    private readonly _windowResize: WindowResizeService,
    private readonly _browserLocationService: BrowserLocationService,
    private readonly _titleService: Title
  ) {}

  private _createFindDirectionParameters(coordinates: ILatLng, isFrom: boolean): IFindDirectionParams {
    return {
      m: '1',
      dp: `(${coordinates.lat}, ${coordinates.lng})`,
      from: isFrom,
      optionalVehicleID: -1
    };
  }

  private _createCreateGeofenceParameters(coordinates: ILatLng): ICreateGeofenceParams {
    return {
      loc: `${coordinates.lat} ${coordinates.lng}`
    };
  }
}
