/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { SendToGarminActions, ESendToGarminActions } from '../actions';
import { initialSendToGarminState, ISendToGarminState } from '../state';

export function sendToGarminReducer(
  state = initialSendToGarminState,
  action: SendToGarminActions
): ISendToGarminState {
  switch (action.type) {
    case ESendToGarminActions.GetNavigationEnabledVehiclesSuccess: {
      return {
        ...state,
        vehicles: action.payload
      };
    }
    case ESendToGarminActions.GetGeocodeInfoSuccess: {
      return {
        ...state,
        geocodeInfo: action.payload
      };
    }
    default: return state;
  }
}
