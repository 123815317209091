/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { ECheckboxState, isStringNullOrEmpty } from '@fleetmatics/ui.base-library';
import { isElementNull } from '@fleetmatics/ui.utilities';

import {
  IVehiclePlot,
  ILabel,
  IMapBalloonResponse,
  EEntityType,
  ESpeedType,
  EVehicleSelectionMode,
  IMapHierarchyItem,
  IMapHierarchyItemResponse,
  IPlaceBalloonResponse,
  IUserDataResponse,
  IUserFeatures,
  IFeaturesResponse,
  INotificationPreferencesResponse,
  IPermissionsResponse,
  IUserPermissions,
  ENodeType,
  IAppointmentResponse,
  ESpeedUnits,
  IVehiclePlotWebsubsResponse,
  EDisplayDriverAs
} from '../../core/models';
import { IDemoMapHierarchyItem, IDemoMapHierarchy, IDemoGeofenceData, IDemoApppointment } from '../models';
import {
  INotificationDetailsResponse,
  ENotificationTypes,
  INotification,
  INotificationResponse,
  INotificationPreferences
} from '../../core/components';
import { IUserMapSettingsState } from '../../core/store/state';
import { SpeedUnitsPipe } from '../../shared/pipes';
import { DemoDataResolverService } from './demo-data-resolver';

@Injectable()
export class DemoDtoMappingService {
  constructor(private readonly dataResolverService: DemoDataResolverService) {}

  userMapSettingsToUserDataResponse(userMapSettings: IUserMapSettingsState): IUserDataResponse {
    return {
      AccountId: userMapSettings.accountId,
      UniversalAccountId: userMapSettings.universalAccountId,
      AccountSettings: userMapSettings.accountSettings,
      Features: this._toFeaturesResponse(userMapSettings.features),
      FeatureToggles: userMapSettings.featureToggles,
      NotificationPreferences: this._toNotificationPreferencesResponse(userMapSettings.notificationPreferences),
      Permissions: this._toPermissionsResponse(userMapSettings.permissions),
      RowGuid: userMapSettings.userRowGuid,
      UserSettings: userMapSettings.userSettings,
      WebSocketAccountId: userMapSettings.webSocketAccountId,
      WebSocketUserId: userMapSettings.webSocketUserId,
      TimeZoneAndroidId: userMapSettings.userSettings.TimeZoneAndroidId
    };
  }

  notificationResponseToNotificationDetailsResponse(alert: INotification, plot: IVehiclePlot): INotificationDetailsResponse {
    return {
      AlertRuleName: 'some rule triggered me',
      AlertType: alert.type,
      AlertTypeName: ENotificationTypes[alert.type].toString(),
      ConfirmedBy: '',
      DateTime: this._formatTriggerTime(new Date()),
      DriverEmail: isElementNull(plot.driverId) ? '' : 'driver@email.com',
      DriverName: isElementNull(plot.driverId) ? '' : plot.driverDisplayName,
      DriverPhone: isElementNull(plot.driverId) ? '' : '055-555-5555',
      Location: plot.address,
      Threshold: 'some threshold',
      VehicleName: plot.vehicleName,
      VehicleNumber: alert.vehicleId.toString(),
      TriggerType: 'trigger type',
      Latitude: alert.coordinates.Latitude,
      Longitude: alert.coordinates.Longitude
    };
  }

  geofencePlotToPlaceBalloonResponse(geofenceData: IDemoGeofenceData): IPlaceBalloonResponse {
    return {
      name: geofenceData.plot.name,
      fulladdress: geofenceData.fullAddress,
      catcolor: 'black',
      catname: geofenceData.categoryName
    };
  }

  splitVehiclePlotDriverDisplayName(plot: IVehiclePlot) {
    const userSettings = this.dataResolverService.initialAppStateData.userMapSettings.userSettings;
    const splitDisplayName = {
      firstName: '',
      lastName: '',
      number: ''
    };
    switch (userSettings.DisplayDriverAs) {
      case EDisplayDriverAs.DriverNumber:
        if (!isNaN(+plot.driverDisplayName)) {
          splitDisplayName.number = plot.driverDisplayName;
          break;
        }
        splitDisplayName.firstName = plot.driverDisplayName.split(' ')[0];
        splitDisplayName.lastName = plot.driverDisplayName.split(' ')[1];
        break;
      case EDisplayDriverAs.NotSet:
      case EDisplayDriverAs.DriverFirstNameSurname:
        splitDisplayName.firstName = plot.driverDisplayName.split(' ')[0];
        splitDisplayName.lastName = plot.driverDisplayName.split(' ')[1];
        break;
      case EDisplayDriverAs.DriverSurnameFirstName:
        splitDisplayName.lastName = plot.driverDisplayName.split(', ')[0];
        splitDisplayName.firstName = plot.driverDisplayName.split(', ')[1];
        break;
    }
    return splitDisplayName;
  }

  vehiclePlotToVehicleResponse(plot: IVehiclePlot): IVehiclePlotWebsubsResponse {
    const splitDriverDetails = this.splitVehiclePlotDriverDisplayName(plot);
    return {
      id: plot.id,
      Coordinate: {
        Latitude: plot.coordinates.lat,
        Longitude: plot.coordinates.lng
      },
      GeofenceName: plot.geofenceName,
      LastUpdateForDisplay: plot.lastUpdateForDisplay,
      LastUpdateIsToday: plot.lastUpdateIsToday,
      Status: plot.status,
      ads: plot.address,
      adsId: plot.addressId,
      dir: plot.direction,
      DID: plot.driverId,
      dnme: plot.driverDisplayName,
      dur: plot.duration,
      evcd: plot.eventCode,
      grp: plot.group,
      icncls: plot.iconClass,
      offsensors: plot.offsensors,
      onsensors: plot.onsensors,
      spd: plot.speed,
      spdvio: {
        InrixSpeedLimitType: plot.speedViolation ? plot.speedViolation.inrixSpeedLimitType : null,
        ViolationSpeed: plot.speedViolation ? plot.speedViolation.violationSpeed : null
      },
      ticks: plot.ticks,
      tme: plot.time,
      tmeu: plot.timeUnits,
      vnme: plot.vehicleName,
      vspdlmt: plot.vehicleSpeedlimit,
      vspdlmttype: plot.vehicleSpeedlimitType,
      HasNavigationDevice: plot.hasNavigationDevice,
      driverNumber: splitDriverDetails.number,
      driverFirstName: splitDriverDetails.firstName,
      driverLastName: splitDriverDetails.lastName,
      label: plot.vehicleName,
      registrationNumber: plot.vehicleName,
      clientVehicleId: plot.vehicleName
    };
  }

  vehiclePlotToLabel(plot: IVehiclePlot, entityType: number): ILabel {
    return {
      mapEntityType: entityType,
      id: plot.id,
      isMapLabel: true,
      customElements: [plot.vehicleName]
    };
  }

  vehiclePlotToMapBalloonResponse(plot: IVehiclePlot, speedUnits: ESpeedUnits): IMapBalloonResponse {
    return {
      Avatar: null,
      BalloonColor: 'black',
      CanPing: false,
      CustomElements: [],
      DriverId: plot.driverId,
      DriverName: plot.driverDisplayName,
      HasNavigationDevice: plot.hasNavigationDevice,
      Id: plot.id,
      Links: true,
      MapEntityType: EEntityType.vehicle,
      ShowHeader: true,
      ShowStandardElements: true,
      Status: {
        AddressComponents: plot.address,
        ClosestGeoFence: null,
        FormattedAddress: plot.address,
        InrixSpeedLimitType: <ESpeedType>plot.speedViolation.inrixSpeedLimitType,
        LastUpdateIsToday: plot.lastUpdateIsToday,
        LastUpdateTime: plot.lastUpdateForDisplay,
        Speed: plot.speed,
        SpeedString: new SpeedUnitsPipe().transform(plot.speed, speedUnits),
        VehicleStatus: plot.status,
        ViolationSpeed: plot.speedViolation.violationSpeed
      },
      VehicleName: plot.vehicleName,
      VehicleEcm: {
        FuelUsedLitres: 0,
        LastUpdateDateTime: plot.lastUpdateForDisplay,
        RemainingFuelPercentage: 100,
        BatteryLifetimePercentage: 100,
        BatteryLifetimePercentageDateTime: plot.lastUpdateForDisplay
      }
    };
  }

  mapHierarchyItemsToDemoMapHierarchyItems(
    selectionMode: EVehicleSelectionMode,
    selectedItems: IMapHierarchyItem[],
    demoHierarchyItems: IDemoMapHierarchyItem[]
  ): IDemoMapHierarchyItem[] {
    switch (selectionMode) {
      default: {
        const children = selectedItems.filter(item => item.level === 1);
        return demoHierarchyItems.map(demoItem => {
          const selectedItem = children.find(child => child.id === demoItem.vehicleId);
          return {
            ...demoItem,
            state: selectedItem !== undefined ? selectedItem.state : ECheckboxState.Unchecked
          };
        });
      }
    }
  }

  demoHierarchyItemsToMapHierarchyItemResponse(
    demoHierarchy: IDemoMapHierarchy,
    selectionMode: EVehicleSelectionMode
  ): IMapHierarchyItemResponse[] {
    switch (selectionMode) {
      case EVehicleSelectionMode.Vehicles: {
        return this._getVehicleHierarchy(demoHierarchy);
      }
      case EVehicleSelectionMode.Drivers: {
        return this._getDriversHierarchy(demoHierarchy);
      }
      case EVehicleSelectionMode.Groups: {
        return this._getGroupsHierarchy(demoHierarchy);
      }
    }
  }

  toNotificationResponse(plot: IVehiclePlot, type: ENotificationTypes, message: string): INotificationResponse {
    return {
      Id: Math.floor(Math.random() * 1000 + 1000).toString(),
      meta: type,
      type: type,
      lat: plot.coordinates.lat,
      lng: plot.coordinates.lng,
      vid: plot.id,
      db: 1,
      body: message
    };
  }

  demoAppointmentToAppointmentResponse(demoAppointment: IDemoApppointment): IAppointmentResponse {
    return {
      arrivalStartDatetime: this._setDateToCurrentDate(demoAppointment.arrivalStartDatetime),
      category: demoAppointment.category,
      durationMin: demoAppointment.durationMin,
      id: demoAppointment.appointmentId,
      primaryContact: {
        contactId: demoAppointment.contactId,
        firstName: demoAppointment.contactFirstName,
        lastName: demoAppointment.contactLastName,
        companyName: null
      },
      site: {
        siteId: demoAppointment.siteId,
        formattedAddress: demoAppointment.siteFormattedAddress
      },
      technicianVisit: {
        eta: null,
        notificationStatus: demoAppointment.technicianVisitsNotificationStatus,
        notificationStatusUpdatedWhen: null,
        state: demoAppointment.technicianVisitsState,
        stateUpdatedWhen: null,
        technicianId: demoAppointment.technicianId
      }
    };
  }

  private _getGroupsHierarchy(demoHierarchy: IDemoMapHierarchy): IMapHierarchyItemResponse[] {
    const rootItem: IMapHierarchyItemResponse = this._getRootItem(demoHierarchy);
    // in group hierarchy, vehicles and drivers both appear in the tree.
    // if a vehicle has a driver assigned then both items will have the vehicle Id
    let hierarchyResponse = [rootItem];

    // drivers come first
    const drivers = demoHierarchy.hierarchyItems
      .filter(demoItem => !isElementNull(demoItem.driverId))
      .map(demoItem => this._mapGroupHierarchyItem(demoItem, true))
      .sort(this._byName());

    // then vehicles
    const vehicles = demoHierarchy.hierarchyItems.map(demoItem => this._mapGroupHierarchyItem(demoItem, false)).sort(this._byName());

    hierarchyResponse = hierarchyResponse.concat(drivers, vehicles);

    rootItem.checked = this._getGroupState(demoHierarchy.hierarchyItems);
    return hierarchyResponse;
  }

  private _getDriversHierarchy(demoHierarchy: IDemoMapHierarchy): IMapHierarchyItemResponse[] {
    const rootItem: IMapHierarchyItemResponse = this._getRootItem(demoHierarchy);
    const hierarchyItems = demoHierarchy.hierarchyItems.filter(demoItem => !isElementNull(demoItem.driverId));
    let hierarchy = [rootItem];
    const drivers = hierarchyItems.map(demoItem => this._mapDriverHierarchyItem(demoItem)).sort(this._byName());
    hierarchy = hierarchy.concat(drivers);
    rootItem.checked = this._getGroupState(hierarchyItems);
    return hierarchy;
  }

  private _getVehicleHierarchy(demoHierarchy: IDemoMapHierarchy) {
    const rootItem: IMapHierarchyItemResponse = this._getRootItem(demoHierarchy);
    let hierarchy = [rootItem];
    const vehicles = demoHierarchy.hierarchyItems.map(demoItem => this._mapVehicleHierarchyItem(demoItem)).sort(this._byName());
    hierarchy = hierarchy.concat(vehicles);
    rootItem.checked = this._getGroupState(demoHierarchy.hierarchyItems);
    return hierarchy;
  }

  private _byName(): (a: IMapHierarchyItemResponse, b: IMapHierarchyItemResponse) => number {
    return (a, b) => a.name.localeCompare(b.name);
  }

  private _mapDriverHierarchyItem(demoItem: IDemoMapHierarchyItem): IMapHierarchyItemResponse {
    return {
      id: demoItem.vehicleId,
      name: demoItem.driverName,
      type: ENodeType.Driver,
      checked: this._mapStates(demoItem.state),
      canExpand: false,
      level: 1
    };
  }

  private _mapStates(state: ECheckboxState): boolean {
    return state === ECheckboxState.Checked;
  }

  private _mapVehicleHierarchyItem(demoItem: IDemoMapHierarchyItem): IMapHierarchyItemResponse {
    return {
      id: demoItem.vehicleId,
      name: this._getVehicleName(demoItem),
      type: ENodeType.Vehicle,
      level: 1,
      checked: this._mapStates(demoItem.state),
      canExpand: false
    };
  }

  private _mapGroupHierarchyItem(demoItem: IDemoMapHierarchyItem, isDriver: boolean): IMapHierarchyItemResponse {
    if (isDriver) {
      return {
        id: demoItem.vehicleId,
        name: `${demoItem.driverName} (${demoItem.vehicleName})`,
        type: ENodeType.Driver,
        checked: this._mapStates(demoItem.state),
        canExpand: false,
        level: 1
      };
    } else {
      return {
        id: demoItem.vehicleId,
        name: this._getVehicleName(demoItem),
        type: ENodeType.Vehicle,
        checked: this._mapStates(demoItem.state),
        canExpand: false,
        level: 1
      };
    }
  }

  private _getVehicleName(demoHierarchyItem: IDemoMapHierarchyItem) {
    return isElementNull(demoHierarchyItem.driverId)
      ? demoHierarchyItem.vehicleName
      : `${demoHierarchyItem.vehicleName} (${demoHierarchyItem.driverName})`;
  }

  private _getRootItem(demoHierarchy: IDemoMapHierarchy): IMapHierarchyItemResponse {
    return {
      id: demoHierarchy.groupId,
      name: demoHierarchy.groupName,
      type: ENodeType.Group,
      level: 0,
      canExpand: true
    };
  }

  private _getGroupState(children: IDemoMapHierarchyItem[]): boolean {
    return children.every(child => child.state === ECheckboxState.Checked);
  }

  private _formatTriggerTime(time: Date): string {
    const month = (time.getMonth() + 1).toString().padStart(2, '0');
    const day = (time.getDay() + 1).toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    return `${month}/${day} ${time.getHours()}:${minutes} ${time.getHours() > 11 ? 'AM' : 'PM'}`;
  }

  private _toPermissionsResponse(permissions: IUserPermissions): IPermissionsResponse[] {
    const mapPermissions: Map<number, string> = new Map([
      [3, 'manageDrivers'],
      [5, 'manageVehicles'],
      [11, 'showSpeedLimits'],
      [13, 'places'],
      [16, 'geofences'],
      [19, 'enableIntegrations'],
      [15, 'dispatch'],
      [20, 'liveMapFindNearest'],
      [21, 'liveMapSendStopsToInVehicleNavigationDevices'],
      [22, 'replay'],
      [26, 'liveMapWorkOrders'],
      [27, 'liveMapWorkOrdersAssigned'],
      [28, 'liveMapWorkOrdersUnassigned'],
      [30, 'dispatchWindow'],
      [31, 'dispatchWindowMessages'],
      [32, 'dispatchWindowStops'],
      [33, 'directions'],
      [34, 'directionsSendToVehicle'],
      [53, 'alerts'],
      [83, 'reportDetailed'],
      [86, 'reportJourneyAndStops'],
      [98, 'liveMapTextMessaging'],
      [103, 'showVehicleSpeed'],
      [132, 'managePlaces'],
      [133, 'suggestedGeofences'],
      [140, 'createPlaceFromMap'],
      [189, 'manageDriverDispatch'],
      [192, 'routingLiveMap'],
      [211, 'showTowingNotifications'],
      [213, 'showGPSSignalStrength'],
      [214, 'assetTracking'],
      [218, 'manageAssets'],
      [226, 'driverPlannerPage'],
      [229, 'roadSideAssistance'],
      [243, 'scheduler']
    ]);

    const response: IPermissionsResponse[] = [];
    mapPermissions.forEach((permissionName, permissionId) => {
      response.push({
        PermissionId: permissionId,
        Status: permissions[permissionName]
      });
    });
    return response;
  }

  private _toNotificationPreferencesResponse(notificationPreferences: INotificationPreferences[]): INotificationPreferencesResponse[] {
    return notificationPreferences.map(
      (notificationPreference): INotificationPreferencesResponse => ({
        AudioEnabled: notificationPreference.audioEnabled,
        NumberBadge: notificationPreference.numberBadge,
        StationaryPopup: notificationPreference.stationaryPopup,
        TemporaryPopup: notificationPreference.temporaryPopup,
        Type: notificationPreference.type
      })
    );
  }
  private _toFeaturesResponse(features: IUserFeatures): IFeaturesResponse[] {
    const mapFeatures: Map<number, string> = new Map([
      [53, 'driverApp'],
      [78, 'realTimeTachoData'],
      [90, 'deviceStatusChange'],
      [93, 'assets'],
      [104, 'roadSideAssistance']
    ]);

    const response: IFeaturesResponse[] = [];
    mapFeatures.forEach((featureName, featureId) => {
      response.push({
        FeatureId: featureId,
        EnabledStatus: features[featureName]
      });
    });
    return response;
  }

  private readonly _setDateToCurrentDate = (date: string): string => {
    if (isStringNullOrEmpty(date)) {
      return null;
    }

    const updatedDate = new Date(date);
    const today = new Date();
    updatedDate.setDate(today.getDate());
    updatedDate.setMonth(today.getMonth());
    updatedDate.setFullYear(today.getFullYear());
    return updatedDate.toISOString();
  };
}
