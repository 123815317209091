/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EMapBalloonType {
  Vehicle = 1,
  Garmin = 3,
  WorkOrder = 8,
  Asset = 10,
  PoweredAsset = 11
}
