/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EDemoStories {
  SplashScreen = 'Splash Screen',
  FleetBasics = 'FleetBasics',
  Safety = 'Safety',
  SafetyAlerts = 'Safety Alerts',
  CostAndEfficiency = 'Cost and Efficiency',
  OnTheGoMap = 'On The Go Map',
  OnTheGoAlerts = 'On The Go Alerts',
  InterstitialGuide = 'Interstitial Guide',
  MobileWeb = 'Mobile Web',
  MobileWebSplashScreen = 'Mobile Web Splash Screen',
  MobileWebUnguidedMap = 'Mobile Web Unguided Map',
  FieldServiceDispatch = 'Field Service Dispatch'
}

export class DemoProgress {
  stories: DemoStoryProgress[];
}

export class DemoStoryProgress {
  name: EDemoStories;
  currentStep: number;
  steps: number;
  isDismissed: boolean;
  isReplayable: boolean;

  constructor(steps: number, name: EDemoStories) {
    this.steps = steps;
    this.name = name;
    this.currentStep = 0;
    this.isDismissed = false;
    this.isReplayable = false;
  }
  static isCompleted = (storyProgress: DemoStoryProgress) => storyProgress.currentStep > storyProgress.steps;

  static isInProgress = (storyProgress: DemoStoryProgress) =>
    storyProgress.currentStep > 0 && !DemoStoryProgress.isCompleted(storyProgress) && !storyProgress.isDismissed;

  static advanceToStep = (storyProgress: DemoStoryProgress, step: number): DemoStoryProgress => {
    return {
      ...storyProgress,
      isDismissed: false,
      currentStep: step
    };
  };
}
