/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformBrowser, DOCUMENT, Location } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
import { interval, fromEvent, Observable, of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, take, tap, debounceTime, delay, catchError } from 'rxjs/operators';

import { LoadDataSuccess, ENavbarActions } from '@fleetmatics/ui.navbar';
import { NavbarModel } from '@fleetmatics/ui.navbar/lib/store/state';
import { isElementNull, FmWindowRefService, ConfigService } from '@fleetmatics/ui.utilities';
import { isStringNullOrEmpty } from '@fleetmatics/ui.base-library';

import {
  SetupDemo,
  EDemoActions,
  StartDemoStories,
  UpdateDemoPlots,
  UpdateDemoHierarchy,
  StartSimulatingPlots,
  FocusMapToPlot,
  FocusMapToLocation,
  UpdateStoryProgress,
  UpdateDemoProgress,
  ShowInterstitialScreen,
  UpdateStoryProgressSuccess,
  DismissStory,
  StartStory,
  InterstitialScreenMoveLeft,
  InterstitialScreenMoveRight,
  InterstitialScreenMoveRightSuccess,
  InterstitialScreenMoveLeftSuccess,
  SetMarketingTextContentVariation,
  ShowShareDemoModal,
  ShareDemoLink,
  CopyDemoLink,
  SetDemoVariationContent
} from './demo.actions';
import { initialNavbarStateData, initialDemoProgressData } from '../data';
import {
  GetVehiclePlotsSuccess,
  ECustomerMetadataActions,
  UpdateVehiclesSelectedSuccess,
  EMapPlotsActions,
  UpdateVehicles,
  StartVehiclesPolling,
  EVehicleActions,
  StopVehiclesPolling,
  UpdateVehiclesSuccess,
  GetUserMapSettingsSuccess,
  FocusVehicle,
  CloseBalloon,
  SelectVehicle,
  UpdateVehicleAsDeselectedSuccess,
  ELayoutActions
} from '../../core/store/actions';
import {
  getDemoVehiclePlots,
  getDemoHierarchyItems,
  getDemoStoryProgress,
  getDemoProgress,
  getIsInterstitialScreenOffset,
  getMarketingContent,
  getDemoVariationContent
} from './demo.selectors';
import { AddNotifications, DeleteAllNotifications, AddNotificationsSuccess, ENotificationsActions } from '../../core/components';
import { IVehiclePlot, IMapHierarchyItem } from '../../core/models';
import { getLayoutPreferences, getUserMapSettings } from '../../core/store/selectors';
import { IAppState, ILayoutPreferencesState, IUserMapSettingsState } from '../../core/store/state';
import {
  DemoDtoMappingService,
  DemoGoogleMapsService,
  PlotSimulatorService,
  DemoDomService,
  PendoService,
  DemoDataResolverService
} from '../services';
import {
  EDemoStories,
  IDemoMapHierarchyItem,
  DemoStoryProgress,
  DemoProgress,
  IOnGuideStepParams,
  IAdvanceStoryProgressParams,
  IMarketingTextContent,
  IDemoVariationContent,
  IDemoNavBarMenuItem,
  EDemoCities
} from '../models';
import {
  BuyNowComponent,
  PendoGuidesComponent,
  InterstitialScreenComponent,
  SplashScreenComponent,
  MobileWebSplashScreenComponent,
  FreeTrialModalComponent,
  ShareDemoModalComponent
} from '../components';
import { DemoConstants } from '../constants';
import { IAppConfig } from '../../config';
import { FmCookieService } from '@fleetmatics/ui.utilities';

const SyntheticAccountId = 'RIABDemoSyntheticAccountId';
const IdlePlotId = 2;
const VTU_UPDATE_INTERVAL_MS = 30000;
const FleetmaticsNowFeatureType = 75;

@Injectable()
export class DemoEffects {
  setup$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SetupDemo>(EDemoActions.SetupDemo),
      switchMap(() => this._initializeDemoData()),
      filter(() => isPlatformBrowser(this._platformId)),
      delay(0),
      tap(() => this._updateTimestampsForPlots()),
      switchMap(() => this._getDemoVariationContent()),
      map(demoVariationContent => {
        if (!this._domService.elementExists('buy-now') && demoVariationContent.buyNowEnabled) {
          this._domService.appendComponentToBody(BuyNowComponent);
          this._domService.toggleBuyNowOnBodyElement();
        }
        this._domService.replaceComponent(BuyNowComponent);
        this._domService.appendComponentToBody(PendoGuidesComponent);

        this._removeSchedulerFromNavigationIfFeatureIsDisabled(demoVariationContent);

        return demoVariationContent;
      }),
      switchMap(demoVariationContent =>
        this._demoGoogleMapsService.isGoogleMapsLoaded().pipe(
          filter(isLoaded => isLoaded),
          take(1),
          map(() => demoVariationContent)
        )
      ),
      switchMap(demoVariationContent =>
        this._getUserMapSettings().pipe(
          switchMap(settings => {
            initialNavbarStateData.plans.find(plan => plan.type === FleetmaticsNowFeatureType).isEnabled =
              demoVariationContent.navbarNowPlanEnabled;

            return this._getDemoProgressFromCache().concat([
              new LoadDataSuccess(this._getLocalisedNavbarData(settings.accountSettings.Language)),
              new UpdateDemoPlots(this._demoDataResolver.initialVehiclePlotsData),
              new StartDemoStories(),
              new GetUserMapSettingsSuccess(settings),
              new SetDemoVariationContent(demoVariationContent)
            ]);
          })
        )
      )
    )
  );

  stopVehiclesPolling$ = createEffect(() =>
    this._actions$.pipe(
      ofType<StartVehiclesPolling>(EVehicleActions.StartVehiclesPolling),
      map(() => new StopVehiclesPolling())
    )
  );

  startDemoStory$ = createEffect(() =>
    this._actions$.pipe(
      ofType<StartDemoStories>(EDemoActions.StartDemoStories),
      map(() => this._domService.appendComponentToBody(InterstitialScreenComponent)),
      switchMap(() => {
        const isMobileBrowser = this._isMobileBrowser();
        const storiesToStart: Action[] = [];

        if (isMobileBrowser) {
          storiesToStart.push(new StartStory(EDemoStories.MobileWebSplashScreen));
        } else {
          // desktop or tablet
          storiesToStart.push(new StartStory(EDemoStories.SplashScreen));
        }

        return ([new StartSimulatingPlots()] as Action[]).concat(storiesToStart);
      })
    )
  );

  modifyMenuItemsStory$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LoadDataSuccess>(ENavbarActions.LoadDataSuccess),
        delay(0),
        switchMap(() => this._getDemoVariationContent()),
        withLatestFrom(this._store.pipe(select(getUserMapSettings))),
        tap(([, settings]) => {
          const isMobileBrowser = this._isMobileBrowser();

          if (isMobileBrowser) {
            this._domService.modifySideNavMenuItems(settings.accountSettings.Language);
          } else {
            this._domService.modifyMenuItems();
          }
        })
      ),
    { dispatch: false }
  );

  addSchedulerAddOnBadge$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LoadDataSuccess>(ENavbarActions.LoadDataSuccess),
        filter(() => this._configService.config.isFsdSchedulerDemoEnabled && isPlatformBrowser(this._platformId)),
        tap(() => this._domService.addSchedulerAddOnBadge())
      ),
    { dispatch: false }
  );

  setupNavBarLogo$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LoadDataSuccess>(ENavbarActions.LoadDataSuccess),
        filter(() => isPlatformBrowser(this._platformId)),
        tap(() => this._domService.replaceLogoImage())
      ),
    { dispatch: false }
  );

  saveToLocalStorageWhenStoriesUpdate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<StartDemoStories>(EDemoActions.StartDemoStories),
        switchMap(() => this._store.pipe(select(getDemoProgress))),
        tap(demoProgress => this._saveProgressToLocalStorage(demoProgress))
      ),
    { dispatch: false }
  );

  startSimulatingPlots$ = createEffect(() =>
    this._actions$.pipe(
      ofType<StartSimulatingPlots>(EDemoActions.StartSimulatingPlots),
      switchMap(() => interval(this._getPlotUpdateIntervalMs())),
      withLatestFrom(this._store.pipe(select(getDemoVehiclePlots))),
      map(([intervalValue, plots]): IVehiclePlot => plots.find(plot => plot.id === (intervalValue % plots.length) + 1)),
      map(plot => this._plotSimulatorService.getUpdatedPlot(plot, plot.id === IdlePlotId)),
      map(updatedPlot => this._dtoMappingService.vehiclePlotToVehicleResponse(updatedPlot)),
      map(plotResponse => new UpdateVehicles(plotResponse))
    )
  );

  focusMapToPlot$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<FocusMapToPlot>(EDemoActions.FocusMapToPlot),
        map(action => action.payload),
        withLatestFrom(this._store.pipe(select(getDemoVehiclePlots))),
        map(([plotId, plots]) => plots.find(plot => plot.id === plotId)),
        map(plot => new google.maps.LatLng(plot.coordinates.lat, plot.coordinates.lng)),
        map(plotCoordinates => this._demoGoogleMapsService.zoomTo(plotCoordinates, 17))
      ),
    { dispatch: false }
  );

  focusMapToLocation$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<FocusMapToLocation>(EDemoActions.FocusMapToLocation),
        map(action => action.payload),
        tap(params => {
          const location = new google.maps.LatLng(params.location.lat, params.location.lng);
          const zoomLevel = isElementNull(params.zoomLevel) ? 15 : params.zoomLevel;
          this._demoGoogleMapsService.zoomTo(location, zoomLevel);
        })
      ),
    { dispatch: false }
  );

  getVehiclePlotsSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetVehiclePlotsSuccess | UpdateVehiclesSuccess>(
        EMapPlotsActions.GetVehiclePlotsSuccess,
        EVehicleActions.UpdateVehiclesSuccess
      ),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getDemoVehiclePlots))),
      map(([plots, demoPlots]) => {
        const result = [...demoPlots];
        for (let i = 0; i < result.length; i++) {
          const updatedPlot = plots.find(plot => plot.id === result[i].id);
          if (!isElementNull(updatedPlot)) {
            result[i] = updatedPlot;
          }
        }
        return result;
      }),
      map(plots => new UpdateDemoPlots(plots))
    )
  );

  updateVehicleSelectedSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehiclesSelectedSuccess | UpdateVehicleAsDeselectedSuccess>(
        ECustomerMetadataActions.UpdateVehiclesSelectedSuccess,
        ELayoutActions.UpdateVehicleAsDeselectedSuccess
      ),
      map(action => action.payload.mapHierarchy),
      switchMap(mapHierarchy =>
        this._store.pipe(
          select(getLayoutPreferences),
          filter(layoutPreferencesState => !isElementNull(layoutPreferencesState)),
          take(1),
          map(layoutPreferencesState => [mapHierarchy, layoutPreferencesState] as [IMapHierarchyItem[], ILayoutPreferencesState])
        )
      ),
      withLatestFrom(this._store.pipe(select(getDemoHierarchyItems))),
      map(
        ([[updatedMapHierarchy, layoutPreferencesState], demoMapHierarchyItems]: [
          [IMapHierarchyItem[], ILayoutPreferencesState],
          IDemoMapHierarchyItem[]
        ]) => {
          const updatedDemoHierarchyItems = this._dtoMappingService.mapHierarchyItemsToDemoMapHierarchyItems(
            layoutPreferencesState.vehicleListPanel.selectionMode,
            updatedMapHierarchy,
            demoMapHierarchyItems
          );
          return new UpdateDemoHierarchy(updatedDemoHierarchyItems);
        }
      )
    )
  );

  showInterstitialScreenWhenStoryCompletes$ = createEffect(() => {
    const stories = [
      EDemoStories.SplashScreen,
      EDemoStories.MobileWebSplashScreen,
      EDemoStories.Safety,
      EDemoStories.OnTheGoMap,
      EDemoStories.InterstitialGuide,
      EDemoStories.MobileWeb
    ];
    return this._actions$.pipe(
      ofType<UpdateStoryProgressSuccess>(EDemoActions.UpdateStoryProgressSuccess),
      map(action => action.payload),
      filter(storyProgress => storyProgress.name in stories),
      filter(storyProgress => DemoStoryProgress.isCompleted(storyProgress)),
      map(() => new ShowInterstitialScreen())
    );
  });

  onGuideStepActivated$ = createEffect(() =>
    fromEvent<CustomEvent>(this._document, DemoConstants.OnGuideStepActivated).pipe(
      filter(e => !isElementNull(e.detail)),
      map((e: CustomEvent): IOnGuideStepParams => e.detail as IOnGuideStepParams),
      map(params => new UpdateStoryProgress({ story: params.story, step: params.step }))
    )
  );

  onGuideStepDismissed$ = createEffect(() =>
    fromEvent<CustomEvent>(this._document, DemoConstants.OnGuideStepDismissed).pipe(
      filter(e => !isElementNull(e.detail)),
      map((e: CustomEvent): IOnGuideStepParams => e.detail as IOnGuideStepParams),
      switchMap(params => [new DismissStory(params.story), new StartStory(EDemoStories.InterstitialGuide)])
    )
  );

  onFleetTrackingBasicsGuideActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateDemoProgress>(EDemoActions.UpdateDemoProgress),
        map(action => action.payload.stories.find(s => s.name === EDemoStories.FleetBasics)),
        filter(progress => DemoStoryProgress.isInProgress(progress)),
        tap(progress => {
          const isGuideShown$ = this._pendoService.tryShowPendoGuideRelativeToElement(
            this._configService.config.fleetTrackingBasicsPendoGuideId,
            DemoConstants.PendoGuideFleetTrackingBasicsId
          );

          this._advanceGuideIfNotAtBeginning(progress, isGuideShown$);
        })
      ),
    { dispatch: false }
  );

  onSafetyGuideActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateDemoProgress>(EDemoActions.UpdateDemoProgress),
        map(action => action.payload.stories.find(s => s.name === EDemoStories.Safety)),
        filter(progress => DemoStoryProgress.isInProgress(progress)),
        tap(progress => {
          const isGuideShown$ = this._pendoService.tryShowPendoGuideRelativeToElement(
            this._configService.config.safetyPendoGuideId,
            DemoConstants.PendoGuideSafetyId
          );

          this._advanceGuideIfNotAtBeginning(progress, isGuideShown$);
        })
      ),
    { dispatch: false }
  );

  onStoriesProgressDismissNotifications$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateStoryProgressSuccess | UpdateDemoProgress>(EDemoActions.UpdateStoryProgressSuccess, EDemoActions.UpdateDemoProgress),
      map(action => action.payload),
      filter(
        progress =>
          this._filterStoryProgress(progress, EDemoStories.Safety, 1) ||
          this._filterStoryProgress(progress, EDemoStories.MobileWeb, 2) ||
          this._filterStoryProgress(progress, EDemoStories.MobileWeb, 5)
      ),
      map(() => new DeleteAllNotifications())
    )
  );

  onSafetyStepTwoActivated$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateStoryProgressSuccess | UpdateDemoProgress>(EDemoActions.UpdateStoryProgressSuccess, EDemoActions.UpdateDemoProgress),
      map(action => action.payload),
      filter(progress => this._filterStoryProgress(progress, EDemoStories.Safety, 2)),
      map(() => {
        const notification = this._demoDataResolver.initialAlertsData[0];
        return new AddNotifications(notification);
      })
    )
  );

  onSafetyStepThreeActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateStoryProgressSuccess>(EDemoActions.UpdateStoryProgressSuccess),
        map(action => action.payload),
        filter(progress => this._filterStoryProgress(progress, EDemoStories.Safety, 3)),
        tap(() => this._navigateTo('/alerts-summary?aid=1'))
      ),
    { dispatch: false }
  );

  deleteNotificationsOnSafetyDismissStory$ = createEffect(() =>
    this._actions$.pipe(
      ofType<DismissStory>(EDemoActions.DismissStory),
      map(action => action.payload),
      filter(story => EDemoStories.Safety === story),
      map(() => new DeleteAllNotifications())
    )
  );

  onOnTheGoGuideActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateDemoProgress>(EDemoActions.UpdateDemoProgress),
        map(action => action.payload.stories.find(s => s.name === EDemoStories.OnTheGoMap)),
        filter(progress => DemoStoryProgress.isInProgress(progress)),
        tap(progress => {
          const isGuideShown$ = this._pendoService.tryShowPendoGuideRelativeToElement(
            this._configService.config.onTheGoPendoGuideId,
            DemoConstants.PendoGuideOnTheGoId
          );

          this._advanceGuideIfNotAtBeginning(progress, isGuideShown$);
        })
      ),
    { dispatch: false }
  );

  onOnTheGoStepThreeActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateStoryProgressSuccess>(EDemoActions.UpdateStoryProgressSuccess),
        map(action => action.payload),
        filter(progress => this._filterStoryProgress(progress, EDemoStories.OnTheGoMap, 3)),
        tap(() => this._navigateTo('/alerts'))
      ),
    { dispatch: false }
  );

  onFleetTrackingBasicsStepThreeActivated$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateStoryProgressSuccess | UpdateDemoProgress>(EDemoActions.UpdateStoryProgressSuccess, EDemoActions.UpdateDemoProgress),
      map(action => action.payload),
      filter(progress => this._filterStoryProgress(progress, EDemoStories.FleetBasics, 3)),
      // if this is executed when the page loads, map does not focus to vehicle, just opens baloon, seems like a map issue
      debounceTime(300),
      switchMap(() => [new SelectVehicle(`v${IdlePlotId}`), new FocusVehicle(`v${IdlePlotId}`)])
    )
  );

  onFleetTrackingBasicsStepFourActivated$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateStoryProgressSuccess | UpdateDemoProgress>(EDemoActions.UpdateStoryProgressSuccess, EDemoActions.UpdateDemoProgress),
      map(action => action.payload),
      filter(progress => this._filterStoryProgress(progress, EDemoStories.FleetBasics, 4)),
      switchMap(() => [
        new CloseBalloon(),
        new FocusMapToLocation({
          location: this._demoDataResolver.initialAppStateData.maps.mapCenter,
          zoomLevel: 14
        })
      ])
    )
  );

  updateStoryProgress$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateStoryProgress>(EDemoActions.UpdateStoryProgress),
      map(action => action.payload),
      switchMap(params =>
        this._store.pipe(
          select(getDemoStoryProgress(params.story)),
          take(1),
          map(storyProgress => [params, storyProgress] as [IAdvanceStoryProgressParams, DemoStoryProgress])
        )
      ),
      filter(
        ([, storyProgress]: [IAdvanceStoryProgressParams, DemoStoryProgress]) =>
          !storyProgress.isDismissed && !DemoStoryProgress.isCompleted(storyProgress)
      ),
      map(([params, storyProgress]: [IAdvanceStoryProgressParams, DemoStoryProgress]) => {
        const step = isElementNull(params.step) ? storyProgress.currentStep + 1 : params.step;
        return DemoStoryProgress.advanceToStep(storyProgress, step);
      }),
      map(advancedStoryProgress => new UpdateStoryProgressSuccess(advancedStoryProgress))
    )
  );

  startStory$ = createEffect(() =>
    this._actions$.pipe(
      ofType<StartStory>(EDemoActions.StartStory),
      map(action => action.payload),
      filter(
        story =>
          story !== EDemoStories.SplashScreen && story !== EDemoStories.MobileWebSplashScreen && story !== EDemoStories.InterstitialGuide
      ),
      withLatestFrom(this._store.pipe(select(getDemoProgress))),
      map(([storyToStart, demoProgress]) => {
        let subsequentStory: EDemoStories | string = '';
        if (storyToStart === EDemoStories.Safety) {
          subsequentStory = EDemoStories.SafetyAlerts;
        } else if (storyToStart === EDemoStories.OnTheGoMap) {
          subsequentStory = EDemoStories.OnTheGoAlerts;
        }
        return this._startStoryAndResetStoriesInProgress(demoProgress, storyToStart, subsequentStory);
      }),
      switchMap(demoProgress => [new UpdateDemoProgress(demoProgress), new DeleteAllNotifications()])
    )
  );

  startStorySplashScreen$ = createEffect(() =>
    this._actions$.pipe(
      ofType<StartStory>(EDemoActions.StartStory),
      map(action => action.payload),
      filter(story => story === EDemoStories.SplashScreen),
      withLatestFrom(this._store.pipe(select(getDemoStoryProgress(EDemoStories.SplashScreen)))),
      map(([, progress]) => progress),
      filter(progress => !DemoStoryProgress.isCompleted(progress)),
      map(() => new UpdateStoryProgress({ story: EDemoStories.SplashScreen, step: 1 })),
      tap(() => this._domService.appendComponentToBody(SplashScreenComponent))
    )
  );

  startStoryMobileWebSplashScreen$ = createEffect(() =>
    this._actions$.pipe(
      ofType<StartStory>(EDemoActions.StartStory),
      map(action => action.payload),
      filter(story => story === EDemoStories.MobileWebSplashScreen),
      withLatestFrom(this._store.pipe(select(getDemoStoryProgress(EDemoStories.MobileWebSplashScreen)))),
      map(([, progress]) => progress),
      filter(progress => !DemoStoryProgress.isCompleted(progress)),
      map(
        () =>
          new UpdateStoryProgress({
            story: EDemoStories.MobileWebSplashScreen,
            step: 1
          })
      ),
      tap(() => this._domService.appendComponentToBody(MobileWebSplashScreenComponent))
    )
  );

  startStoryInterstitialGuide$ = createEffect(() =>
    this._actions$.pipe(
      ofType<StartStory>(EDemoActions.StartStory),
      map(action => action.payload),
      filter(story => story === EDemoStories.InterstitialGuide),
      withLatestFrom(this._store.pipe(select(getDemoStoryProgress(EDemoStories.InterstitialGuide)))),
      map(([, progress]) => progress),
      filter(progress => !DemoStoryProgress.isCompleted(progress)),
      switchMap(() =>
        this._pendoService.tryShowPendoGuideRelativeToElement(
          this._configService.config.interstitialPendoGuideId,
          DemoConstants.PendoGuideInterstitialGuideId
        )
      ),
      filter(isGuideShown => isGuideShown),
      map(
        () =>
          new UpdateStoryProgress({
            story: EDemoStories.InterstitialGuide,
            step: 1
          })
      )
    )
  );

  dismissStoryInterstitialGuide$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateStoryProgressSuccess>(EDemoActions.UpdateStoryProgressSuccess),
      map(action => action.payload),
      filter(progress => this._filterStoryProgress(progress, EDemoStories.InterstitialGuide, 1)),
      delay(7000),
      tap(() => {
        if (this._domService.removePendoGuideTriggerElement(DemoConstants.PendoGuideInterstitialGuideId)) {
          this._pendoService.hideGuides();
        }
      }),
      map(
        () =>
          new UpdateStoryProgress({
            story: EDemoStories.InterstitialGuide,
            step: 2
          })
      )
    )
  );

  startStoryCost$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<StartStory>(EDemoActions.StartStory),
        map(action => action.payload),
        filter(story => story === EDemoStories.CostAndEfficiency),
        switchMap(() => this._store.pipe(select(getDemoProgress))),
        filter(progress => progress.stories.some(s => s.name === EDemoStories.CostAndEfficiency && s.currentStep === 1 && !s.isDismissed)),
        tap(() => this._navigateTo('/reports?id=daily-report'))
      ),
    { dispatch: false }
  );

  InterstitialScreenMoveLeft$ = createEffect(() =>
    this._actions$.pipe(
      ofType<InterstitialScreenMoveLeft>(EDemoActions.InterstitialScreenMoveLeft),
      withLatestFrom(this._store.pipe(select(getIsInterstitialScreenOffset))),
      filter(([, offset]) => offset < 0),
      tap(([, offset]) => this._domService.interstitialMoveLeft(offset)),
      map(() => {
        const cardWidth = this._domService.interstitialCardWidth();
        return new InterstitialScreenMoveLeftSuccess(cardWidth);
      })
    )
  );

  InterstitialScreenMoveRight$ = createEffect(() =>
    this._actions$.pipe(
      ofType<InterstitialScreenMoveRight>(EDemoActions.InterstitialScreenMoveRight),
      withLatestFrom(this._store.pipe(select(getIsInterstitialScreenOffset))),
      filter(([, offset]) => this._domService.interstitialHasCardsOnRight(offset)),
      tap(([, offset]) => this._domService.interstitialMoveRight(offset)),
      map(() => {
        const cardWidth = this._domService.interstitialCardWidth();
        return new InterstitialScreenMoveRightSuccess(cardWidth);
      })
    )
  );

  updateMarketingValues$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadDataSuccess>(ENavbarActions.LoadDataSuccess),
      filter(() => isPlatformBrowser(this._platformId)),
      withLatestFrom(this._store.pipe(select(getMarketingContent))),
      map(([, marketingContent]) => {
        const useVariation = this._router.parseUrl(this._router.url).queryParamMap.get('split_test_variation');

        if (useVariation === 'b') {
          const verizonConnectDomainRegex = /^(http|https):\/\/(www.)?verizonconnect.com/;
          const referrerUrl =
            this._document.referrer && verizonConnectDomainRegex.test(this._document.referrer)
              ? this._document.referrer
              : marketingContent.url;

          return new SetMarketingTextContentVariation({
            currentVariation: 'b',
            showDetailsLink: false,
            titleText: 'Get started today',
            buttonText: 'See plans and pricing',
            interstitialText: 'Get started today.',
            interstitialButtonText: 'See plans and pricing',
            url: `${referrerUrl}#demo`
          });
        }

        const cachedMarketingPreferences = this._getMarketingPreferenceFromCache();
        if (
          !isElementNull(cachedMarketingPreferences) &&
          (isElementNull(useVariation) || cachedMarketingPreferences.currentVariation === useVariation)
        ) {
          return new SetMarketingTextContentVariation(cachedMarketingPreferences);
        }

        const isMobileBrowser = this._isMobileBrowser();
        return new SetMarketingTextContentVariation({
          currentVariation: 'a',
          showDetailsLink: true,
          titleText: 'Start your 30-day free trial',
          buttonText: isMobileBrowser ? 'See pricing' : 'Get started',
          interstitialText: 'Ready to start your 30-day free trial?',
          interstitialButtonText: 'See details',
          url: marketingContent.url
        });
      })
    )
  );

  saveMessageVariationToLocalStorage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LoadDataSuccess>(ENavbarActions.LoadDataSuccess),
        filter(() => isPlatformBrowser(this._platformId)),
        switchMap(() => this._store.pipe(select(getMarketingContent))),
        tap(marketingPreference =>
          this._windowRefService.nativeWindow.localStorage.setItem(
            DemoConstants.LocalStorageMarketingPreferenceKey,
            JSON.stringify(marketingPreference)
          )
        )
      ),
    { dispatch: false }
  );

  saveDemoVariationToLocalStorage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LoadDataSuccess>(ENavbarActions.LoadDataSuccess),
        filter(() => isPlatformBrowser(this._platformId)),
        switchMap(() => this._store.pipe(select(getDemoVariationContent))),
        tap(demoVariationContent =>
          this._windowRefService.nativeWindow.localStorage.setItem(
            DemoConstants.LocalStorageDemoVariationContentKey,
            JSON.stringify(demoVariationContent)
          )
        )
      ),
    { dispatch: false }
  );

  onMobileWebActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateDemoProgress>(EDemoActions.UpdateDemoProgress),
        map(action => action.payload.stories.find(s => s.name === EDemoStories.MobileWeb)),
        filter(progress => DemoStoryProgress.isInProgress(progress)),
        tap(progress => {
          const isGuideShown$ = this._pendoService.tryShowPendoGuideRelativeToElement(
            this._configService.config.mobileWebPendoGuideId,
            DemoConstants.PendoGuideMobileWebId
          );

          this._advanceGuideIfNotAtBeginning(progress, isGuideShown$);
        })
      ),
    { dispatch: false }
  );

  onMobileWebStepThreeActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateStoryProgressSuccess | UpdateDemoProgress>(EDemoActions.UpdateStoryProgressSuccess, EDemoActions.UpdateDemoProgress),
        map(action => action.payload),
        filter(progress => this._filterStoryProgress(progress, EDemoStories.MobileWeb, 3)),
        tap(() => this._navigateTo('/replay'))
      ),
    { dispatch: false }
  );

  onMobileWebStepFourActivated$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateStoryProgressSuccess | UpdateDemoProgress>(EDemoActions.UpdateStoryProgressSuccess, EDemoActions.UpdateDemoProgress),
      map(action => action.payload),
      filter(progress => this._filterStoryProgress(progress, EDemoStories.MobileWeb, 4)),
      map(() => {
        const notification = this._demoDataResolver.initialAlertsData[1];

        return new AddNotifications(notification);
      })
    )
  );

  onMobileWebUnguidedMapActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateDemoProgress>(EDemoActions.UpdateDemoProgress),
        map(action => action.payload.stories.find(s => s.name === EDemoStories.MobileWebUnguidedMap)),
        filter(storyProgress => DemoStoryProgress.isInProgress(storyProgress)),
        tap(progress => {
          const isGuideShown$ = this._pendoService.tryShowPendoGuideRelativeToElement(
            this._configService.config.mobileWebUnguidedMapPendoId,
            DemoConstants.PendoGuideMobileWebUnguidedMapId
          );

          this._advanceGuideIfNotAtBeginning(progress, isGuideShown$);
        })
      ),
    { dispatch: false }
  );

  onNotificationAdded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<AddNotificationsSuccess>(ENotificationsActions.AddNotificationsSuccess),
        map(action => action.payload),
        delay(100),
        tap(notification => this._domService.addClassToNotification('notification-' + notification.id))
      ),
    { dispatch: false }
  );

  showFreeTrialModal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateStoryProgressSuccess>(EDemoActions.UpdateStoryProgressSuccess),
        map(action => action.payload),
        filter(storyProgress => storyProgress.name === EDemoStories.MobileWeb),
        filter(storyProgress => DemoStoryProgress.isCompleted(storyProgress)),
        switchMap(() => this._store.pipe(select(getDemoVariationContent))),
        tap(demoVariationContent => {
          if (demoVariationContent.currentVariation === 'a') {
            this._domService.appendComponentToBody(FreeTrialModalComponent);
          }
        })
      ),
    { dispatch: false }
  );

  onShowShareDemoModal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ShowShareDemoModal>(EDemoActions.ShowShareDemoModal),
        tap(() => this._domService.appendComponentToBody(ShareDemoModalComponent))
      ),
    { dispatch: false }
  );

  onShareDemoLink$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ShareDemoLink>(EDemoActions.ShareDemoLink),
        withLatestFrom(this._store.pipe(select(getDemoVariationContent))),
        switchMap(([, demoVariationContent]) => {
          return of(null).pipe(
            map(() => {
              const navigator = this._windowRefService.nativeWindow.navigator as any;

              if (navigator && navigator.share) {
                navigator.share({
                  title: demoVariationContent.shareDemoLinkTitle,
                  url: this._document.location.href
                });
              }
            }),
            catchError(() => of(null))
          );
        })
      ),
    { dispatch: false }
  );

  onCopyDemoLink$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<CopyDemoLink>(EDemoActions.CopyDemoLink),
        switchMap(() => {
          return of(null).pipe(
            map(() => {
              const navigator = this._windowRefService.nativeWindow.navigator as any;

              if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(this._document.location.href);
              }
            }),
            catchError(() => of(null))
          );
        })
      ),
    { dispatch: false }
  );

  onFieldServiceDispatchGuideActivated$ = createEffect(() =>
    this._actions$.pipe(
      ofType<StartStory>(EDemoActions.StartStory),
      map(action => action.payload),
      filter(story => story === EDemoStories.FieldServiceDispatch),
      map(
        () =>
          new UpdateStoryProgress({
            story: EDemoStories.FieldServiceDispatch,
            step: 1
          })
      ),
      tap(() => this._navigateTo('/fsd/technicians'))
    )
  );

  onFieldServiceDispatchStepFourActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateStoryProgressSuccess>(EDemoActions.UpdateStoryProgressSuccess),
        map(action => action.payload),
        filter(progress => this._filterStoryProgress(progress, EDemoStories.FieldServiceDispatch, 4)),
        tap(() => this._navigateTo('/fsd/technicians'))
      ),
    { dispatch: false }
  );

  onFieldServiceDispatchMapGuideActivated$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateStoryProgressSuccess | UpdateDemoProgress>(EDemoActions.UpdateStoryProgressSuccess, EDemoActions.UpdateDemoProgress),
        map(action => this._getStoryProgress(action.payload, EDemoStories.FieldServiceDispatch)),
        filter(progress => this._filterStoryProgress(progress, EDemoStories.FieldServiceDispatch, 6)),
        tap(progress => {
          const isGuideShown$ = this._pendoService.tryShowPendoGuideRelativeToElement(
            this._configService.config.PendoGuideFsdId,
            DemoConstants.PendoGuideFsdId
          );

          this._advanceGuideIfNotAtBeginning(progress, isGuideShown$);
        })
      ),
    { dispatch: false }
  );

  showWelcomeScreenForBVariant$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ShowInterstitialScreen>(EDemoActions.ShowInterstitialScreen),
        switchMap(() => this._store.pipe(select(getDemoVariationContent))),
        tap(demoVariationContent => {
          if (demoVariationContent.currentVariation === 'b') {
            this._domService.appendComponentToBody(SplashScreenComponent);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _dtoMappingService: DemoDtoMappingService,
    private readonly _demoGoogleMapsService: DemoGoogleMapsService,
    private readonly _plotSimulatorService: PlotSimulatorService,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _windowRefService: FmWindowRefService,
    private readonly _domService: DemoDomService,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _pendoService: PendoService,
    private readonly _configService: ConfigService<IAppConfig>,
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _demoDataResolver: DemoDataResolverService,
    private readonly cookieService: FmCookieService
  ) {}

  private _navigateTo(path: string): void {
    let currentQueryParams = this._location.path();
    if (path.includes('?')) {
      currentQueryParams = currentQueryParams.replace('?', '&');
    }
    const newUrl = `${path}${currentQueryParams}`;
    this._document.location.href = newUrl;
  }

  private _updateTimestampsForPlots(): void {
    this._demoDataResolver.initialVehiclePlotsData = this._demoDataResolver.initialVehiclePlotsData.map(plot =>
      this._plotSimulatorService.updatePlotTime(plot)
    );
  }

  private _initializeDemoData(): Observable<boolean> {
    return this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap(() => {
        const city = this._getCity();
        this._updateLocalStorageCity(city);
        return this._demoDataResolver.initialize(city);
      })
    );
  }

  private _getCity(): EDemoCities {
    // Will first try to get from query params
    // If it is missing or invalid, fallback to local storage,
    // If doesn't exist, try use the users locale
    // Else, fallback to EDemoCities.atlanta
    const { isCityFromQueryParamSpecifiedAndValid, cityFromQueryParam } = this._getCityFromQueryParams();
    const { isCityFromStorageSpecifiedAndValid, cityFromStorage } = this._getCityFromlocalStorage();
    const cityFromLocale = this._getCityFromLocale();

    let city: EDemoCities;
    if (isCityFromQueryParamSpecifiedAndValid) {
      city = EDemoCities[cityFromQueryParam as keyof typeof EDemoCities];
    } else if (isCityFromStorageSpecifiedAndValid) {
      city = cityFromStorage as EDemoCities;
    } else {
      city = cityFromLocale;
    }

    return city;
  }

  private _getCityFromQueryParams(): {
    isCityFromQueryParamSpecifiedAndValid: boolean;
    cityFromQueryParam: string;
  } {
    const cityFromQueryParam = this._router.routerState.root.snapshot.queryParamMap.get(DemoConstants.CityParam);
    const isCityFromQueryParamSpecifiedAndValid =
      !isStringNullOrEmpty(cityFromQueryParam) && !isElementNull(EDemoCities[cityFromQueryParam as keyof typeof EDemoCities]);
    return { isCityFromQueryParamSpecifiedAndValid, cityFromQueryParam };
  }

  private _getCityFromlocalStorage(): {
    isCityFromStorageSpecifiedAndValid: boolean;
    cityFromStorage: string;
  } {
    let isCityFromStorageSpecifiedAndValid = false;
    let cityFromStorage: string;
    if (isPlatformBrowser(this._platformId)) {
      cityFromStorage = this._windowRefService.nativeWindow.localStorage.getItem(DemoConstants.LocalStorageDemoCityKey);
      isCityFromStorageSpecifiedAndValid =
        !isStringNullOrEmpty(cityFromStorage) && !isElementNull(EDemoCities[cityFromStorage as keyof typeof EDemoCities]);
    }
    return { isCityFromStorageSpecifiedAndValid, cityFromStorage };
  }

  private _getCityFromLocale(): EDemoCities {
    const localeFromCookies = this.cookieService.getCookie('locale');
    if (localeFromCookies) {
      switch (localeFromCookies) {
        case 'it-IT':
          return EDemoCities.rome;
        case 'pt-PT':
          return EDemoCities.lisbon;
        default:
          return EDemoCities.atlanta;
      }
    }
    return EDemoCities.atlanta;
  }

  private _updateLocalStorageCity(city: EDemoCities): void {
    if (isPlatformBrowser(this._platformId)) {
      this._windowRefService.nativeWindow.localStorage.setItem(DemoConstants.LocalStorageDemoCityKey, city);
    }
  }

  private _saveProgressToLocalStorage(demoProgress: DemoProgress): void {
    this._windowRefService.nativeWindow.localStorage.setItem(DemoConstants.LocalStorageDemoProgressKey, JSON.stringify(demoProgress));
  }

  private _startStoryAndResetStoriesInProgress(
    demoProgress: DemoProgress,
    storyToStart: EDemoStories,
    subsequentStory: EDemoStories | string
  ): DemoProgress {
    const updatedStories: DemoStoryProgress[] = [];
    demoProgress.stories.forEach(story => {
      if (story.name === storyToStart) {
        updatedStories.push(DemoStoryProgress.advanceToStep(story, 1));
      } else if (story.name === subsequentStory || (DemoStoryProgress.isInProgress(story) && story.isReplayable)) {
        updatedStories.push(DemoStoryProgress.advanceToStep(story, 0));
      } else {
        updatedStories.push({ ...story });
      }
    });

    return { ...demoProgress, stories: updatedStories } as DemoProgress;
  }

  private _advanceGuideIfNotAtBeginning(progress: DemoStoryProgress, isGuideShown$: Observable<boolean>) {
    if (progress.currentStep > 1) {
      isGuideShown$.subscribe(success => {
        if (success) {
          this._pendoService.advancePendoGuideToStep(progress.currentStep);
        }
      });
    } else {
      isGuideShown$.subscribe();
    }
  }

  private _getUserMapSettings(): Observable<IUserMapSettingsState> {
    const useSyntheticAccount = this._router.parseUrl(this._router.url).queryParamMap.has('useSyntheticAccount');

    return this._store.pipe(
      select(getUserMapSettings),
      filter(settings => !isElementNull(settings.permissions)),
      take(1),
      map(
        (settings): IUserMapSettingsState => ({
          ...settings,
          userRowGuid: this._getPendoUserGuid(),
          accountId: useSyntheticAccount ? SyntheticAccountId : (settings.accountId as any),
          accountSettings: {
            ...settings.accountSettings,
            Language: this._getPendoLocale()
          }
        })
      )
    );
  }

  private _getDemoVariationContent(): Observable<IDemoVariationContent> {
    const cachedDemoVariationContent = this._getDemoVariationContentFromCache();
    const city = this._getCity();

    const domain = location.hostname;
    const isStarter = domain.includes(this._configService.config.starter.domain);

    if (isStarter) {
      return of({
        currentVariation: 'b',
        currentCity: city,
        welcomeTitle: 'Welcome to Reveal Starter',
        mobileNavCardText: 'For the full Reveal Starter experience check out our demo on your desktop.',
        takeGuidedTourText: 'Take the guided tour or click around to find out how Reveal Starter can help you manage your fleet.',
        mobileNavTitle: 'Reveal Starter',
        shareDemoModalContent:
          'For the full Reveal Starter experience (including Reports, Alerts, Places and Replay) check out the demo on your desktop.',
        shareDemoLinkTitle: 'Reveal Starter Demo',
        navbarNowPlanEnabled: true,
        buyNowEnabled: true,
        interstitialScreenActionEnabled: false
      });
    }

    // If not Starter, check cache
    if (!isElementNull(cachedDemoVariationContent)) {
      return of({
        ...cachedDemoVariationContent,
        currentCity: city,
        buyNowEnabled: city === EDemoCities.atlanta
      });
    }

    return this._store.pipe(
      select(getDemoVariationContent),
      take(1),
      map(
        (demoVariationContent): IDemoVariationContent => {
          const demoVariationContentWithCity: IDemoVariationContent = {
            ...demoVariationContent,
            currentCity: city,
            buyNowEnabled: city === EDemoCities.atlanta
          };
          this._windowRefService.nativeWindow.localStorage.setItem(
            DemoConstants.LocalStorageDemoVariationContentKey,
            JSON.stringify(demoVariationContentWithCity)
          );
          return demoVariationContentWithCity;
        }
      )
    );
  }

  private _getDemoVariationContentFromCache(): IDemoVariationContent {
    const demoVariationContent = this._windowRefService.nativeWindow.localStorage.getItem(
      DemoConstants.LocalStorageDemoVariationContentKey
    );
    return JSON.parse(demoVariationContent) as IDemoVariationContent;
  }

  private _getPendoLocale(): string {
    const localeCookie = this._document.cookie.match('(^|;) ?' + 'locale' + '=([^;]*)(;|$)');
    if (localeCookie && localeCookie[2]) {
      switch (localeCookie[2]) {
        case 'pt-PT':
        case 'it-IT':
        case 'es-ES':
        case 'fr-FR':
        case 'de-DE':
        case 'nl-NL':
        case 'pl-PL':
          return localeCookie[2];
      }
    }

    return 'en-US';
  }

  private _getDemoProgressFromCache(): Action[] {
    const actions: Action[] = [];
    const cachedProgress = this._windowRefService.nativeWindow.localStorage.getItem(DemoConstants.LocalStorageDemoProgressKey);
    if (!isElementNull(cachedProgress)) {
      const demoProgress = JSON.parse(cachedProgress) as DemoProgress;

      for (let i = 0; i < initialDemoProgressData.stories.length; i++) {
        const initialDemoStory = initialDemoProgressData.stories[i];
        this._updateStorySteps(demoProgress, initialDemoStory);
        this._addNewStory(demoProgress, initialDemoStory);
      }

      actions.push(new UpdateDemoProgress(demoProgress));
    }

    return actions;
  }

  private _updateStorySteps(demoProgress: DemoProgress, initialDemoStory: DemoStoryProgress): void {
    const index = demoProgress.stories.indexOf(
      demoProgress.stories.find(story => story.name === initialDemoStory.name && story.steps !== initialDemoStory.steps)
    );
    if (index !== -1) {
      demoProgress.stories[index] = {
        ...demoProgress.stories[index],
        currentStep: 0,
        steps: initialDemoStory.steps
      };
    }
  }

  private _addNewStory(demoProgress: DemoProgress, initialDemoStory: DemoStoryProgress): void {
    if (!demoProgress.stories.find(story => story.name === initialDemoStory.name)) {
      demoProgress.stories.push(initialDemoStory);
    }
  }

  private _getPendoUserGuid(): string {
    let pendoUserGuid = this._windowRefService.nativeWindow.localStorage.getItem(DemoConstants.LocalStoragePendoUserGuidKey);
    if (isElementNull(pendoUserGuid)) {
      pendoUserGuid = this._newGuid();
      this._windowRefService.nativeWindow.localStorage.setItem(DemoConstants.LocalStoragePendoUserGuidKey, pendoUserGuid);
    }
    return pendoUserGuid;
  }

  private _newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line: no-bitwise
      const r = (Math.random() * 16) | 0,
        // tslint:disable-next-line: no-bitwise
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  _getStoryProgress(progress: DemoProgress | DemoStoryProgress, story: EDemoStories): DemoStoryProgress {
    if (this._isDemoProgress(progress)) {
      return progress.stories.find(s => s.name === story);
    } else {
      return progress;
    }
  }

  private _filterStoryProgress(progress: DemoProgress | DemoStoryProgress, story: EDemoStories, step?: number): boolean {
    {
      if (this._isDemoProgress(progress)) {
        return isElementNull(step)
          ? progress.stories.some(s => s.name === story)
          : progress.stories.some(s => s.name === story && s.currentStep === step && !s.isDismissed);
      } else {
        return isElementNull(step)
          ? progress.name === story
          : progress.name === story && progress.currentStep === step && !progress.isDismissed;
      }
    }
  }

  private _getMarketingPreferenceFromCache(): IMarketingTextContent {
    const cachedPreference = this._windowRefService.nativeWindow.localStorage.getItem(DemoConstants.LocalStorageMarketingPreferenceKey);
    return JSON.parse(cachedPreference) as IMarketingTextContent;
  }

  private _isDemoProgress(progress: any): progress is DemoProgress {
    return !isElementNull((progress as DemoProgress).stories);
  }

  private _isMobileBrowser(): boolean {
    const screen = this._windowRefService.nativeWindow.screen;
    return screen.width <= 480 || screen.height <= 480;
  }

  private _removeSchedulerFromNavigationIfFeatureIsDisabled(demoVariation: IDemoVariationContent): void {
    if (!this._configService.config.isFsdSchedulerDemoEnabled || demoVariation.currentVariation === 'b') {
      const schedulerKey = +Object.keys(initialNavbarStateData.menuItems.items).find(
        (key: string) => initialNavbarStateData.menuItems.items[+key].displayText === 'Scheduler'
      );

      delete initialNavbarStateData.menuItems.items[schedulerKey];
    }
  }

  private _getLocalisedNavbarData(language: string): NavbarModel {
    const navbarModel: NavbarModel = {
      accountMenu: { ...initialNavbarStateData.accountMenu },
      contactUs: { ...initialNavbarStateData.contactUs },
      menuItems: { ...initialNavbarStateData.menuItems.items },
      plans: [...initialNavbarStateData.plans]
    };

    // check config option for new branding
    navbarModel.accountMenu.userDetails.isVerizonNewTickBrandingEnabled = this._configService.config.isVerizonNewTickBrandingEnabled;

    Object.keys(navbarModel.menuItems).forEach(item => {
      const demoNavbarItem = navbarModel.menuItems[+item] as IDemoNavBarMenuItem;
      let displayText: string;

      switch (language) {
        case 'it-IT':
          displayText = demoNavbarItem.displayTextItalian;
          break;
        case 'es-ES':
          displayText = demoNavbarItem.displayTextSpanish;
          break;
        case 'pt-PT':
          displayText = demoNavbarItem.displayTextPortuguese;
          break;
        case 'fr-FR':
          displayText = demoNavbarItem.displayTextFrench;
          break;
        case 'de-DE':
          displayText = demoNavbarItem.displayTextGerman;
          break;
        case 'nl-NL':
          displayText = demoNavbarItem.displayTextDutch;
          break;
        case 'pl-PL':
          displayText = demoNavbarItem.displayTextPolish;
          break;
        default:
          displayText = demoNavbarItem.displayText;
      }

      navbarModel.menuItems[+item].displayText = displayText;
    });

    return navbarModel;
  }

  private _getPlotUpdateIntervalMs(): number {
    return VTU_UPDATE_INTERVAL_MS / this._demoDataResolver.initialVehiclePlotsData.length;
  }
}
