/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ISearchEntity } from '../../models';

export interface ISearchState {
  searchEntities: ISearchEntity[];
  filteredEntities: ISearchEntity[];
  searchInput: string;
}

export const initialSearchState: ISearchState = {
  searchEntities: [],
  filteredEntities: [],
  searchInput: ''
};
