/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FmDocumentRefService, isElementNull } from '@fleetmatics/ui.utilities';

import { IWebsocketScriptsLoaderService } from './websocket-scripts-loader.service.interface';
import { IAppConfig } from '../../../config/app-config.interfaces';
import { APP_CONFIG_TOKEN } from '../../../config/app-config.token';

const timestamp = require('!raw-loader!../../../../../timestamp');

@Injectable()
export class WebsocketScriptsLoaderService implements IWebsocketScriptsLoaderService {
  public jQueryLoaded$: Observable<boolean>;
  public signalRLoaded$: Observable<boolean>;
  public hubsLoaded$: Observable<boolean>;
  public signalRSetupSuccess$: Observable<boolean>;
  public signalRSetupError$: Observable<boolean>;

  private readonly _jQueryLoadedSubject = new BehaviorSubject<boolean>(false);
  private readonly _signalRLoadedSubject = new BehaviorSubject<boolean>(false);
  private readonly _hubsLoadedSubject = new BehaviorSubject<boolean>(false);
  private readonly _signalRSetupSuccessSubject = new BehaviorSubject<boolean>(false);
  private readonly _signalRSetupErrorSubject = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _documentRefService: FmDocumentRefService,
    @Inject(APP_CONFIG_TOKEN) private readonly _config: IAppConfig
  ) {
    this.jQueryLoaded$ = this._jQueryLoadedSubject.asObservable();
    this.signalRLoaded$ = this._signalRLoadedSubject.asObservable();
    this.hubsLoaded$ = this._hubsLoadedSubject.asObservable();
    this.signalRSetupSuccess$ = this._signalRSetupSuccessSubject.asObservable().pipe(filter(isSuccess => isSuccess));
    this.signalRSetupError$ = this._signalRSetupErrorSubject.asObservable().pipe(filter(isError => isError));
  }

  public setupSignalR(elementRef: ElementRef): void {
    if (isPlatformServer(this._platformId) || (this.isJQueryLoaded() && this.isSignalRLoaded() && this.areHubsLoaded())) {
      return;
    }

    this._loadJQuery(elementRef);
  }

  public isJQueryLoaded(): boolean {
    if (isPlatformServer(this._platformId)) {
      return false;
    }

    return !isElementNull((window as IJQueryWindow).jQuery);
  }

  public isSignalRLoaded(): boolean {
    if (isPlatformServer(this._platformId)) {
      return false;
    }

    return !isElementNull((window as ISignalRWindow).SignalR);
  }

  public areHubsLoaded(): boolean {
    if (isPlatformServer(this._platformId)) {
      return false;
    }

    return !isElementNull(((window as unknown) as IHubsWindow).Hubs);
  }

  private _loadJQuery(elementRef: ElementRef): void {
    const jqueryScriptSrc = `./jquery-1.12.4.min.js?${timestamp.default}`;
    this._appendScript(
      jqueryScriptSrc,
      elementRef,
      () => this._onJQueryLoaded(elementRef),
      () => this._onScriptFail()
    );
  }

  private _loadSignalR(elementRef: ElementRef): void {
    const signalRScriptSrc = `./jquery.signalr-2.0.0.min.js?${timestamp.default}`;
    this._appendScript(
      signalRScriptSrc,
      elementRef,
      () => this._onSignalRLoaded(elementRef),
      () => this._onScriptFail()
    );
  }

  private _loadHubs(elementRef: ElementRef): void {
    const hubsScriptSrc = `${this._config.websocketUrl}/signalr/hubs`;
    this._appendScript(
      hubsScriptSrc,
      elementRef,
      () => this._onHubsLoaded(elementRef),
      () => this._onScriptFail()
    );
  }

  private _onJQueryLoaded(elementRef: ElementRef): void {
    this._jQueryLoadedSubject.next(true);
    this._loadSignalR(elementRef);
  }

  private _onSignalRLoaded(elementRef: ElementRef): void {
    this._signalRLoadedSubject.next(true);
    this._loadHubs(elementRef);
  }

  private _onHubsLoaded(elementRef: ElementRef): void {
    this._hubsLoadedSubject.next(true);
    this._signalRSetupSuccessSubject.next(true);
  }

  private _onScriptFail(): void {
    this._signalRSetupErrorSubject.next(true);
  }

  private _createScriptElement(
    scriptSrc: string,
    onLoadFunc: (this: GlobalEventHandlers, event: Event) => void,
    onErrorFunc: OnErrorEventHandler
  ): HTMLScriptElement {
    const script = this._documentRefService.nativeDocument.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = scriptSrc;
    script.onload = onLoadFunc;
    script.onerror = onErrorFunc;

    return script;
  }

  private _appendScript(
    scriptSrc: string,
    elementRef: ElementRef,
    onLoadFunc: (this: GlobalEventHandlers, event: Event) => void,
    onErrorFunc: OnErrorEventHandler
  ): void {
    const script = this._createScriptElement(scriptSrc, onLoadFunc, onErrorFunc);
    elementRef.nativeElement.appendChild(script);
    this._documentRefService.nativeDocument.body.appendChild(script);
  }
}
