/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { TransferState, makeStateKey } from '@angular/platform-browser';
import { RouterReducerState } from '@ngrx/router-store';

import { ICustomerMetadataState, initialCustomerMetadataState } from './customer-metadata.state';
import { ILayoutState, initialLayoutState } from './layout.state';
import { ILayoutPreferencesState, initialLayoutPreferencesState } from './layout-preferences.state';
import { IPlotsState, initialPlotsState } from './plots.state';
import { IRouterStateUrl } from './router-state.serializer';
import { IUserMapSettingsState, initialUserDataState } from './user-map-settings.state';
import { IVehicleDriverPanelState, initialVehicleDriverPanelState } from './vehicle-driver-panel.state';
import { IMapOptionsState, initialMapOptionsState } from './map-options.state';
import { IAdvancedOptionsState, initialAdvancedOptionsState } from './advanced-options.state';
import { IBalloonInfoState, initialBalloonInfoState } from './balloon-info.state';
import { IVehicleState, initialVehicleState } from './vehicle.state';
import { IMapsState, initialMapsState } from './maps.state';
import { IVehicleListPanelState, initialVehicleListPanelState } from './vehicle-list-panel.state';
import { IDriverMessageState, initialDriverMessageState } from './driver-message.state';
import { IGarminStopHistoryState, initialGarminStopHistoryState } from './garmin-stop-history.state';
import { ISendToGarminState, initialSendToGarminState } from './send-to-garmin.state';
import { IReverseGeocodeState, initialReverseGeocodeState } from './reverse-geocode.state';
import { IWorkOrderState, initialWorkOrderState } from './work-order.state';
import { IRoadsideAssistanceState, initialRoadsideAssistanceState } from './roadside-assistance.state';
import { IAssetsState, initialAssetsState } from './assets.state';
import { IHarshDrivingEventsState, initialHarshDrivingEventsState } from './harsh-driving-events.state';
import { IConfigState, initialConfigState } from './config.state';
import { IWorkingTimeDirectiveState, initialWorkingTimeDirectiveState } from './working-time-directive.state';
import { IReplayState } from '../../../replay/store';
import { initialModulesState, IModulesState } from './modules.state';
import { ISearchState } from '../../../search/store';

export const ROOT_STATE_KEY = makeStateKey('fm-app');

export interface IAppState {
  router?: RouterReducerState<IRouterStateUrl>;
  customerMetadata: ICustomerMetadataState;
  layout: ILayoutState;
  layoutPreferences: ILayoutPreferencesState;
  plots: IPlotsState;
  userMapSettings: IUserMapSettingsState;
  mapOptions: IMapOptionsState;
  vehicleDriverPanel: IVehicleDriverPanelState;
  advancedOptions: IAdvancedOptionsState;
  balloonInfo: IBalloonInfoState;
  vehicle: IVehicleState;
  maps: IMapsState;
  vehicleListPanel: IVehicleListPanelState;
  workingTimeDirective: IWorkingTimeDirectiveState;
  driverMessage: IDriverMessageState;
  garminStopHistory: IGarminStopHistoryState;
  sendToGarmin: ISendToGarminState;
  reverseGeocode: IReverseGeocodeState;
  workOrder: IWorkOrderState;
  roadsideAssistance: IRoadsideAssistanceState;
  assets: IAssetsState;
  harshDrivingEvent: IHarshDrivingEventsState;
  config: IConfigState;
  modules: IModulesState;
  // Replay state is needed here to avoid ngrx warnings related to not being able to access a lazy loaded module feature state
  replay?: IReplayState;
  search?: ISearchState;
}

export const initialAppState: IAppState = {
  customerMetadata: initialCustomerMetadataState,
  layout: initialLayoutState,
  layoutPreferences: initialLayoutPreferencesState,
  plots: initialPlotsState,
  userMapSettings: initialUserDataState,
  mapOptions: initialMapOptionsState,
  vehicleDriverPanel: initialVehicleDriverPanelState,
  advancedOptions: initialAdvancedOptionsState,
  balloonInfo: initialBalloonInfoState,
  vehicle: initialVehicleState,
  maps: initialMapsState,
  vehicleListPanel: initialVehicleListPanelState,
  workingTimeDirective: initialWorkingTimeDirectiveState,
  driverMessage: initialDriverMessageState,
  garminStopHistory: initialGarminStopHistoryState,
  sendToGarmin: initialSendToGarminState,
  reverseGeocode: initialReverseGeocodeState,
  workOrder: initialWorkOrderState,
  roadsideAssistance: initialRoadsideAssistanceState,
  config: initialConfigState,
  assets: initialAssetsState,
  harshDrivingEvent: initialHarshDrivingEventsState,
  modules: initialModulesState
};

export function getInitialState(transferState: TransferState): IAppState {
  const state = transferState.get<IAppState>(ROOT_STATE_KEY, initialAppState);
  transferState.remove(ROOT_STATE_KEY);

  return state;
}
