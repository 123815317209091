/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EDeviceStatusSnackbarTypes {
  correct = 'correct',
  incorrect = 'incorrect',
  seen = 'seen',
  standard = ''
}
