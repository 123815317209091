/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatIconModule } from '@angular/material/icon';

import { HierarchyTreeComponent } from './hierarchy-tree.component';
import { CheckboxModule } from '../../checkbox';
import { TooltipModule } from 'shared';

@NgModule({
  imports: [CdkTreeModule, CommonModule, CheckboxModule, ScrollingModule, MatIconModule, TooltipModule],
  declarations: [HierarchyTreeComponent],
  exports: [HierarchyTreeComponent]
})
export class HierarchyTreeModule {}
