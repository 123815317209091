/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TooltipModule } from 'shared';

import { ButtonToggleGroupComponent } from './button-toggle-group.component';
import { ButtonToggleComponent } from './button-toggle/button-toggle.component';

@NgModule({
  imports: [CommonModule, TooltipModule, MatIconModule],
  declarations: [ButtonToggleGroupComponent, ButtonToggleComponent],
  exports: [ButtonToggleGroupComponent, ButtonToggleComponent]
})
export class ButtonToggleGroupModule {}
