/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IReverseGeocodeExecutionBatch } from '../../models';

export interface IReverseGeocodeState {
  executionBatches: IReverseGeocodeExecutionBatch[];
}

export const initialReverseGeocodeState: IReverseGeocodeState = {
  executionBatches: []
};
