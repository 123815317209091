<fm-modal-container (contextmenu)="$event.preventDefault()">
  <fm-modal-header [headerTitle]="details?.notificationTypeName">
  </fm-modal-header>
  <fm-modal-content>
    <div class="notification-details-content">
      <div class="notification-details__column notification-details-map">
        <fm-google-maps-light [coordinates]="details?.coordinates"></fm-google-maps-light>
      </div>
      <div class="notification-details__column">
        <p class="notification-details-title"
          i18n="@@S_DRIVER_NAME">Driver Name</p>
        <p class="notification-details-info">{{ details?.driverName }}</p>

        <p class="notification-details-title"
          i18n="@@S_VEHICLE_NAME">Vehicle Name</p>
        <p class="notification-details-info">{{ details?.vehicleName }}</p>

        <p class="notification-details-title"
          i18n="@@S_ALERT_TYPE">Alert Type</p>
        <p class="notification-details-info">{{ details?.notificationType }}</p>

        <p class="notification-details-title"
          i18n="@@S_ALERT_TRIGGER">Alert Trigger</p>
        <p class="notification-details-info">{{ details?.triggerType }}</p>

        <p class="notification-details-title"
          i18n="@@S_LOCATION">Location</p>
        <p class="notification-details-info">{{ details?.location }}</p>
      </div>
      <div class="notification-details__column">
        <p class="notification-details-title"
          i18n="@@S_DRIVER_EMAIL">Driver Email</p>
        <p class="notification-details-info">{{ details?.driverEmail }}</p>

        <p class="notification-details-title"
          i18n="@@S_VEHICLE_NUMBER">Vehicle Number</p>
        <p class="notification-details-info">{{ details?.vehicleNumber }}</p>

        <p class="notification-details-title"
          i18n="@@S_THRESHOLD">Threshold</p>
        <p class="notification-details-info">{{ details?.threshold }}</p>

        <p class="notification-details-title"
          i18n="@@S_TRIGGER_TIME">Trigger Time</p>
        <p class="notification-details-info">{{ details?.dateTime }}</p>
      </div>
    </div>
  </fm-modal-content>
  <fm-modal-footer class=" d-none d-md-flex">
    <ds-button text="Close"
      id="btn-closeAlertDetail"
      i18n-text="@@S_CLOSE"
      componentStyle="primary"
      size="small"
      (click)="onCloseClick()">
    </ds-button>
  </fm-modal-footer>
</fm-modal-container>
