/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { filter, switchMap, map } from 'rxjs/operators';

import { isArrayNullOrEmpty } from '@fleetmatics/ui.utilities';

import { IAppState } from '../../../core/store/state';
import { ESearchActions, GetSearchEntities, GetSearchEntitiesSuccess } from '../actions';
import { getAllSearchPoweredAssetsFromHierarchy, getAllSearchVehiclesFromHierarchy } from '../selectors';
import { combineLatest, Observable, of } from 'rxjs';
import { ISearchEntity, ESearchType, ISearchVehicle, ISearchDriver, ISearchGroup, ISearchAsset, ISearchPoweredAsset } from '../../models';
import { SortUtils } from '../../../core/utils';

@Injectable()
export class SearchEffects {
  getSearchEntities$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSearchEntities>(ESearchActions.GetSearchEntities),
      map(action => action.payload),
      switchMap(types => this._getSearchEntities(types)),
      filter((searchEntities: ISearchEntity[]) => !isArrayNullOrEmpty(searchEntities)),
      map((searchEntities: ISearchEntity[]) => new GetSearchEntitiesSuccess(searchEntities))
    )
  );

  constructor(private readonly _actions$: Actions, private readonly _store: Store<IAppState>) {}

  private _getSearchEntities(types: ESearchType[]): Observable<ISearchEntity[]> {
    // Ideally this will be one api call where we pass the types and get an array containing the correct object back
    const searchEntities$: Observable<ISearchEntity[]> = combineLatest([
      this._handleSearchVehicles(types),
      this._handleSearchDrivers(types),
      this._handleSearchGroups(types),
      this._handleSearchAssets(types),
      this._handleSearchPoweredAssets(types)
    ]).pipe(
      map(searchItems => {
        const flatItems = [].concat(...searchItems);
        return flatItems.sort((a: ISearchEntity, b: ISearchEntity) => SortUtils.naturalSortComparer(a.name, b.name));
      })
    );

    return searchEntities$;
  }

  _handleSearchVehicles(types: ESearchType[]): Observable<ISearchVehicle[]> {
    if (types.includes(ESearchType.Vehicle)) {
      return this._store.pipe(select(getAllSearchVehiclesFromHierarchy));
    }

    return of([]);
  }

  _handleSearchDrivers(types: ESearchType[]): Observable<ISearchDriver[]> {
    if (types.includes(ESearchType.Driver)) {
      console.warn('Search type Driver is not supported');
    }
    return of([]);
  }

  _handleSearchGroups(types: ESearchType[]): Observable<ISearchGroup[]> {
    if (types.includes(ESearchType.Group)) {
      console.warn('Search type Group is not supported');
    }
    return of([]);
  }

  _handleSearchAssets(types: ESearchType[]): Observable<ISearchAsset[]> {
    if (types.includes(ESearchType.Asset)) {
      console.warn('Search type Asset is not supported');
    }
    return of([]);
  }

  _handleSearchPoweredAssets(types: ESearchType[]): Observable<ISearchPoweredAsset[]> {
    if (types.includes(ESearchType.PoweredAsset)) {
      return this._store.pipe(select(getAllSearchPoweredAssetsFromHierarchy));
    }
    return of([]);
  }
}
