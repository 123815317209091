/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IPlanningSchedulingWorkOrderHttpService } from './planning-scheduling-work-order-http.service.interface';
import { IApiResponseDto, IWorkOrderPlot } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';

@Injectable()
export class PlanningSchedulingWorkOrderHttpService implements IPlanningSchedulingWorkOrderHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = `${_configService.config.endpoints.planningSchedulingApiEndpoint}/WorkOrder`;
  }

  public getWorkOrderPlot(id: number): Observable<IWorkOrderPlot> {
    const params = new HttpParams().set('request[WorkOrderId]', id.toString());

    return this._httpClient
      .get<IApiResponseDto<IWorkOrderPlot>>(`${this._rootUrl}/GetWorkOrderPlot`, { params })
      .pipe(map((result: IApiResponseDto<IWorkOrderPlot>) => result.Data));
  }
}
