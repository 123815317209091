/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ArrayUtilities } from '../../../../utils';
import {
  VehicleActions,
  EVehicleActions,
  GetLayoutPreferencesSuccess,
  ELayoutPreferencesActions,
  GetAccessibleVehiclesCountSuccess
} from '../actions';
import { IVehicleState, initialVehicleState } from '../state';

export function vehicleReducer(
  state = initialVehicleState,
  action: VehicleActions | GetLayoutPreferencesSuccess | GetAccessibleVehiclesCountSuccess
): IVehicleState {
  switch (action.type) {
    case EVehicleActions.GetVehiclesSortedSuccess: {
      return {
        ...state,
        // update array ref only if the values are different
        sortedVehiclesIds: ArrayUtilities.compareArrays(state.sortedVehiclesIds, action.payload) ? state.sortedVehiclesIds : action.payload
      };
    }
    case EVehicleActions.UpdateVehiclesSortType: {
      return {
        ...state,
        vehiclesSortType: action.payload
      };
    }
    case ELayoutPreferencesActions.GetLayoutPreferencesSuccess: {
      return {
        ...state,
        vehiclesSortType: action.payload.vehiclesSortType
      };
    }

    case EVehicleActions.AddTowingVehicles: {
      return {
        ...state,
        towingVehicles: state.towingVehicles.concat(action.payload)
      };
    }

    case EVehicleActions.UpdateTowingVehicle: {
      return {
        ...state,
        towingVehicles: state.towingVehicles.map(towing => (towing.id === action.payload.id ? action.payload : towing))
      };
    }

    case EVehicleActions.RemoveTowingVehicles: {
      return {
        ...state,
        towingVehicles: state.towingVehicles.filter(towingVehicle =>
          action.payload.every(towingVehicleToRemove => towingVehicle.id !== towingVehicleToRemove.id)
        )
      };
    }

    case EVehicleActions.UpdatePlotsInBounds: {
      return {
        ...state,
        plotsInBounds: action.payload
      };
    }

    case EVehicleActions.GetAccessibleVehiclesCountSuccess: {
      return {
        ...state,
        accessibleVehiclesCount: action.payload
      };
    }

    default:
      return state;
  }
}
