/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IAppConfig } from '../../../config';
import { IAssetsHttpService } from './assets-http.service.interface';
import { IAssetSelectionResponse, IAssetPlotResponse, IAssetDetailResponse } from '../../models';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class AssetsHttpService implements IAssetsHttpService {
  private readonly _rootUrl: string;

  constructor(
    private readonly _httpClient: HttpClient,
    _configService: ConfigService<IAppConfig>,
    private readonly _sanitizer: DomSanitizer
  ) {
    this._rootUrl = _configService.config.endpoints.assetsApiEndpoint;
  }

  public getSelection(): Observable<IAssetSelectionResponse[]> {
    return this._httpClient.get<IAssetSelectionResponse[]>(this._rootUrl + '/api/assets/selection');
  }

  public updateSelection(assetIds: number[]): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this._httpClient.put<boolean>(this._rootUrl + '/api/assets/selection', JSON.stringify(assetIds), { headers });
  }

  public getAssetPlots(dbId: number, vehicleTrackingIds: string[]): Observable<IAssetPlotResponse[]> {
    return this._httpClient.post<IAssetPlotResponse[]>(this._rootUrl + '/api/assetplots', {
      dbId: dbId,
      vehicleTrackingId: vehicleTrackingIds
    });
  }

  public getAssetsByIds(ids: number[]): Observable<IAssetDetailResponse[]> {
    return this._httpClient.post<IAssetDetailResponse[]>(this._rootUrl + '/api/assets/assets', {
      ids: ids,
    });
  }

  public getAssetImageById(id: number): Observable<string> {
    const requestOptions: object = {
      responseType: 'blob'
    };
    return this._httpClient.get<Blob>(this._rootUrl + `/api/assets/image/${id}`, requestOptions).pipe(
      map(image => this._sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(image))),
      catchError(() => of(null)),
      shareReplay(1)
    );
  }
}
