/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, EventEmitter, ChangeDetectionStrategy, Input, Output, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'fm-button-toggle',
  templateUrl: './button-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonToggleComponent {
  @Input()
  public iconName: string;

  @Input()
  public tooltip: string;

  @Input()
  public value: string;

  @Input()
  public get isSelected(): boolean {
    return this._isSelected;
  }
  public set isSelected(valueSelected: boolean) {
    this._isSelected = valueSelected;
    this.selected.emit(this);
    this._changeDetectorRef.detectChanges();
  }

  @Output()
  public selected: EventEmitter<ButtonToggleComponent> = new EventEmitter<ButtonToggleComponent>();

  public controlId: number;
  private _isSelected: boolean;

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}
}
