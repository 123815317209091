/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EPlotDirection {
  North = 'North',
  NorthEast = 'NorthEast',
  East = 'East',
  SouthEast = 'SouthEast',
  South = 'South',
  SouthWest = 'SouthWest',
  West = 'West',
  NorthWest = 'NorthWest'
}
