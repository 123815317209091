/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IListSelectOption } from './model/list-select-option';
import { ListSelectItemComponent } from './list-select-item/list-select-item.component';

@Component({
  selector: 'fm-list-select',
  templateUrl: './list-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListSelectComponent implements AfterViewInit, OnDestroy {
  @Input()
  public selectAllText = 'Select All';
  @Input()
  public deselectAllText = 'Deselect All';
  @Output()
  public selectionChanged = new EventEmitter<IListSelectOption[]>();

  @ContentChildren(ListSelectItemComponent)
  private readonly _componentItems: QueryList<ListSelectItemComponent>;

  private _selectedOptions: IListSelectOption[] = [];
  private readonly _unsubscribe$ = new Subject<void>();

  public ngAfterViewInit() {
    this._subscribeListItemComponents();
    this._componentItems.changes.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
      this._subscribeListItemComponents();
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public get selectedOptions(): IListSelectOption[] {
    this._selectedOptions = this._componentItems
      ? this._componentItems
          .filter(comp => comp.isSelected)
          .map(comp => {
            return {
              displayText: comp.displayText,
              displayValue: comp.displayValue,
              tooltip: comp.tooltip,
              isSelected: comp.isSelected
            };
          })
      : [];
    return this._selectedOptions;
  }

  public get isDeselectAllDisabled(): boolean {
    return this.selectedOptions.length === 0;
  }

  public get isSelectAllDisabled(): boolean {
    return this.selectedOptions.length === this._componentItems.length;
  }

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  public selectItem(item: IListSelectOption): void {
    item.isSelected = !item.isSelected;
    this.selectionChanged.emit(this.selectedOptions);
  }

  public selectAll(): void {
    this._componentItems.forEach((comp: ListSelectItemComponent) => {
      comp.isSelected = true;
    });
    this.selectionChanged.emit(this.selectedOptions);
  }

  public deselectAll(): void {
    this._componentItems.forEach(comp => {
      comp.isSelected = false;
    });
    this.selectionChanged.emit([]);
  }

  private _subscribeListItemComponents(): void {
    this._componentItems.forEach((comp: ListSelectItemComponent) => {
      comp.selectionChange.pipe(takeUntil(this._unsubscribe$)).subscribe(() => this._selectionChange());
    });
    this._cdr.markForCheck();
  }

  private _selectionChange(): void {
    this.selectionChanged.emit(this.selectedOptions);
  }
}
