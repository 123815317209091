/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, BrowserTransferStateModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HelpPanelConnectorModule } from '@fleetmatics/ui.help-panel/connector';
import { ConfigService, TitleModule } from '@fleetmatics/ui.utilities';
import { CookieBannerConnectorModule } from '@fleetmatics/ui.utilities/cookie-banner-connector';
import { BehaviorSubject } from 'rxjs';
import { AppIconModule } from './app-icon.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appConfigFactory, APP_CONFIG_TOKEN } from './config';
import { CoreModule } from './core/core.module';
import { IUserSettings } from './core/models';
import { USER_SETTINGS_TOKEN } from './core/tokens/user-settings.token';
import { HammerConfig } from './hammer.config';
import { LazyLoaderModule } from './lazy-loader/lazy-loader.module';
import { DemoModule } from './demo/demo.module';

/*
    App module should be in sync with project ui.application.map, except the following changes
    that were done for the demo:
    * add DemoModule to imports
    * remove AuthBrowserModule
*/
//region Asset paths
@NgModule({
  declarations: [AppComponent],
  imports: [
    DemoModule,
    BrowserModule.withServerTransition({ appId: 'ui-application-map' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatIconModule,
    AppIconModule,
    TitleModule,
    CoreModule,
    LazyLoaderModule,
    HelpPanelConnectorModule.forRoot(() => import('../lazy-components/help-panel-wrapper.component')),
    MatSnackBarModule,
    CookieBannerConnectorModule.forRoot(() => import('../lazy-components/cookie-banner-wrapper.component'))
  ],
  providers: [
    { provide: APP_CONFIG_TOKEN, useFactory: appConfigFactory, deps: [ConfigService] },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: USER_SETTINGS_TOKEN, useValue: new BehaviorSubject<IUserSettings>(null) }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
