/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { INotificationsState } from '../state';
import { INotification, ENotificationTypes, INotificationDetails } from '../../models';

export const getNotificationsState = createFeatureSelector<INotificationsState>('notificationsFeature');

export const getNotifications = createSelector(getNotificationsState, (state: INotificationsState): INotification[] => state.notifications);

export const getNotificationDetails = createSelector(
  getNotificationsState,
  (state: INotificationsState): INotificationDetails => state.notificationDetail
);

export const getPanicNotifications = createSelector(getNotifications, (notifications: INotification[]) =>
  notifications.filter((notification: INotification) => notification.type === ENotificationTypes.panic)
);

export const getNonPanicNotifications = createSelector(getNotifications, (notifications: INotification[]) =>
  notifications.filter(
    (notification: INotification) =>
      notification.type !== ENotificationTypes.panic && notification.type !== ENotificationTypes.deviceStatusChange
  )
);

export const getDeviceStatusNotifications = createSelector(getNotifications, (notifications: INotification[]) =>
  notifications.filter((notification: INotification) => notification.type === ENotificationTypes.deviceStatusChange)
);

export const getShouldShowNonPanicNotifications = createSelector(
  getNotificationsState,
  (state: INotificationsState) => state.shouldShowNonPanicNotifications
);

export const getSnackbarError = createSelector(getNotificationsState, (state: INotificationsState): boolean => state.snackbarError);

export const getSnackbarSuccess = createSelector(getNotificationsState, (state: INotificationsState): string => state.snackbarSuccess);
