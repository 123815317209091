/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IAppConfig } from '../../../config';
import { IReplayHttpService } from './replay-http.service.interface';
import { IGetReplayPlotsRequest, IGetReplayPlotsResponse, IGetJourneyForVehicleRequest, IGetJourneyForVehicleResponse } from '../../models';
import { EpochTimeUtilities } from '../../../../utils';

@Injectable()
export class ReplayHttpService implements IReplayHttpService {
  private readonly _plotRootUrl: string;
  private readonly _reportsRootUrl: string;

  constructor(private readonly _httpClient: HttpClient, private readonly _configService: ConfigService<IAppConfig>) {
    this._plotRootUrl = this._configService.config.endpoints.plotApiEndpoint;
    this._reportsRootUrl = this._configService.config.endpoints.reportsApiEndpoint;
  }

  getReplayPlots(request: IGetReplayPlotsRequest): Observable<IGetReplayPlotsResponse> {
    let params = new HttpParams();
    params = params.append('request.vehicleId', request.vehicleId.toString());
    params = params.append('request.StartEpochSeconds', EpochTimeUtilities.fromDateToEpochSeconds(request.startTime).toString());
    params = params.append('request.endEpochSeconds', EpochTimeUtilities.fromDateToEpochSeconds(request.endTime).toString());

    const options = { params: params };
    return this._httpClient.get<IGetReplayPlotsResponse>(`${this._plotRootUrl}/v2/ReplayPlots`, options).pipe(map(result => result));
  }
  getReplayJourneyForVehicle(request: IGetJourneyForVehicleRequest): Observable<IGetJourneyForVehicleResponse> {
    let params = new HttpParams();
    params = params.append('centricities[0].type', 'VehicleCentricity');
    params = params.append('centricities[0].centricities[0].type', 'JourneySegmentCentricity');
    params = params.append('timeZone', 'UniversalTimeCoordinated');
    params = params.append('metrics[0]', 'TotalDistanceTravelled');

    params = params.append('Subjects.vehicleIds[0]', request.vehicleId.toString());
    params = params.append('shifts[0].start', request.startTime.toISOString());
    params = params.append('shifts[0].end', request.endTime.toISOString());
    params = params.append('speedingThreshold', request.speedThreshold.toString());
    params = params.append('minimumStopDuration', request.minimumStopDuration.toString());
    params = params.append('minimumIdleDuration', request.minimumIdleDuration.toString());
    params = params.append('IgnitionOnly', request.ignitionOnly.toString());
    params = params.append('includePlaces', request.includePlaces.toString());

    const options = { params: params };

    return this._httpClient.get<IGetJourneyForVehicleResponse>(`${this._reportsRootUrl}/metric-data`, options).pipe(map(result => result));
  }
}
