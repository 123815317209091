/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  EventEmitter,
  Input,
  Optional,
  OnInit,
  Output,
  QueryList
} from '@angular/core';

@Component({
  selector: 'fm-radio-group',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioGroupComponent {
  @Input()
  set name(value: string) {
    this._name = value;
    this._updateRadioButtonNames();
  }
  get name(): string {
    return this._name;
  }

  @Input()
  set value(value: number | string) {
    if (this._value !== value) {
      this._value = value;
      this._updateRadioButtonIsChecked();
    }
  }
  get value(): number | string {
    return this._value;
  }

  @Output() public valueChange = new EventEmitter<number | string>();

  // tslint:disable:no-forward-ref
  @ContentChildren(forwardRef(() => RadioButtonComponent))
  private readonly _radios: QueryList<RadioButtonComponent>;

  private _name: string;
  private _value: number | string;

  public emitValueChange(): void {
    this.valueChange.emit(this.value);
  }

  public uncheckRadioButtons(name: string, excludeValue: number | string): void {
    if (this._radios) {
      this._radios
        .filter(radio => radio.name === name)
        .forEach(radio => {
          radio.isChecked = radio.value === excludeValue;
        });
    }
  }

  private _updateRadioButtonIsChecked(): void {
    if (this._radios) {
      const selectedRadioButton = this._radios.find(radio => radio.value === this.value);
      if (selectedRadioButton) {
        selectedRadioButton.isChecked = true;
      }
    }
  }

  private _updateRadioButtonNames(): void {
    if (this._radios) {
      this._radios.forEach(radio => {
        radio.name = this.name;
      });
    }
  }
}

@Component({
  selector: 'fm-radio-button',
  templateUrl: './radio-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonComponent implements OnInit {
  @Input()
  public set isChecked(value: boolean) {
    const newIsCheckedState = value;
    if (this._isChecked !== newIsCheckedState) {
      this._isChecked = value;
      if (newIsCheckedState && this.radioGroup && this.radioGroup.value !== this.value) {
        this.radioGroup.value = this.value;
      } else if (!newIsCheckedState && this.radioGroup && this.radioGroup.value === this.value) {
        this.radioGroup.value = null;
      }
      if (newIsCheckedState && this.radioGroup) {
        this.radioGroup.uncheckRadioButtons(this.name, this.value);
      }

      this._changeDetector.markForCheck();
    }
  }
  public get isChecked(): boolean {
    return this._isChecked;
  }

  @Input() name: string;

  @Input()
  public set value(value: number | string) {
    if (this._value !== value) {
      this._value = value;
      if (this.radioGroup !== null) {
        if (!this.isChecked) {
          this.isChecked = this.radioGroup.value === value;
        } else {
          this.radioGroup.value = value;
        }
      }
    }
  }
  public get value(): number | string {
    return this._value;
  }

  @Output() public changed = new EventEmitter<number | string>();

  private _isChecked: boolean;
  private _value: number | string;

  constructor(@Optional() public radioGroup: RadioGroupComponent, private readonly _changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.radioGroup) {
      this.isChecked = this.radioGroup.value === this._value;
      this.name = this.radioGroup.name;
    }
  }

  public onChange(event: Event): void {
    event.stopPropagation();

    const isGroupValueChanged = this.radioGroup ? this.radioGroup.value !== this.value : false;
    this.isChecked = true;
    this.changed.emit(this.value);
    if (isGroupValueChanged) {
      this.radioGroup.emitValueChange();
      this.radioGroup.uncheckRadioButtons(this.name, this.value);
    }
  }
}
