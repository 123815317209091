/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IEcmVehicleResponse, IEcmVehicleRequest } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';
import { IReportsHttpService } from './reports-http.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportsHttpService implements IReportsHttpService {
  private readonly _reportsRootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._reportsRootUrl = _configService.config.endpoints.reportsApiEndpoint;
  }

  public getEcmFuelEfficiency(request: IEcmVehicleRequest): Observable<IEcmVehicleResponse> {
    let params = new HttpParams();
    params = params.append('centricities[0].type', 'VehicleCentricity');
    params = params.append('timeZone', 'UniversalTimeCoordinated');
    params = params.append('metrics[0]', 'TotalFuelEfficiency');
    params = params.append('Subjects.vehicleIds[0]', request.vehicleId.toString());
    params = params.append('shifts[0].start', request.startTime.toISOString());
    params = params.append('shifts[0].end', request.endTime.toISOString());

    const options = { params: params };

    return this._httpClient.get<IEcmVehicleResponse>(`${this._reportsRootUrl}/metric-data`, options).pipe(map(result => result));
  }
}
