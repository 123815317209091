/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EHarshDrivingEventClassification {
  Undetermined = 0,
  HarshDriving,
  Dangerous,
  Collision,
  LowRisk
}
