<div cdkOverlayOrigin>
  <div class="fm-search-light-fab"
    [class.fm-search-light-fab--active]="isActive">
    <button #inputIcon
      class="fm-search-light-fab__open-button btn fab fab-secondary"
      type="button"
      a7s-id="live-map-search-light-fab-open"
      i18n-aria-label="@@S_ROUTIST_SEARCH"
      aria-label="search"
      [dsTooltip]="tooltipMessage"
      (click)="onOpen()">
      <ds-icon name="ds-ic-search"
        [style.visibility]="isLoading ? 'hidden' : 'visible'"
        size="extraSmall"
        i18n-aria-label="@@S_ROUTIST_SEARCH"
        aria-label="Search"></ds-icon>
      <span *ngIf="isLoading"
        class="loader fm-search-light-fab__loader"></span>
    </button>
    <div class="fm-search-light-fab__input-container"
      #inputContainer>
      <fm-search-light-input class="fm-search-light-fab__input-comp"
        classes="fm-search-light-fab__input"
        elementId="txt-search-light-fab"
        [(ngModel)]="searchText"
        [placeholder]="placeholder"
        [triggerable]="triggerable"
        [isActive]="isActive"
        [isLoading]="isLoading"
        (closed)="onClose()"></fm-search-light-input>

      <button class="fm-search-light-fab__close-button fab fab-secondary"
        type="button"
        a7s-id="live-map-search-light-fab-close"
        i18n-aria-label="@@S_CLOSE_SEARCH_BUTTON"
        aria-label="Close Search"
        (click)="onClose(true)">
        <ds-icon name="ds-ic-close"
          size="extraSmall"
          i18n-aria-label="@@S_CLOSE_BUTTON"
          aria-label="Close"></ds-icon>
      </button>
    </div>
  </div>
</div>