/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ELazyModules {
  Maps = 'maps',
  MapOptions = 'map-options',
  Replay = 'replay',
  VehicleDriverPanel = 'vehicle-driver-panel',
  External = 'external',
  OverlayControls = 'overlay-controls'
}
