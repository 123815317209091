<ng-container *ngIf="(variationContent$ | async)?.currentVariation === 'a'">
  <ng-container *ngIf="(bannerContent$ | async)?.currentVariation === 'a'">
    <div id="buy-now">
      <span class="buy-now-text"
        ><span i18n="@@S_DEMO_VARIATION_A_TRIAL">Start your 30-day free trial</span
        ><a [href]="(bannerContent$ | async)?.url" class="see-details" a7s-id="demo-live-map-see-details" i18n="@@S_DEMO_VARIATION_A_SEE"
          >See details</a
        ></span
      >
      <a
        a7s-id="demo-live-map-buy-now"
        [href]="(bannerContent$ | async)?.url"
        class="btn top-banner-button"
        *ngIf="isMobileBrowser"
        i18n="@@S_DEMO_SEE_PRICING"
        >See pricing</a
      >
      <a
        a7s-id="demo-live-map-buy-now"
        [href]="(bannerContent$ | async)?.url"
        class="btn top-banner-button"
        *ngIf="!isMobileBrowser"
        i18n="@@S_DEMO_GET_STARTED"
        >Get started</a
      >
    </div>
  </ng-container>
  <ng-container *ngIf="(bannerContent$ | async)?.currentVariation === 'b'">
    <div id="buy-now">
      <span class="buy-now-text" i18n="@@S_DEMO_VARIATION_B_START">Get started today</span>
      <a a7s-id="demo-live-map-buy-now" [href]="(bannerContent$ | async)?.url" class="btn top-banner-button" i18n="@@S_DEMO_VARIATION_B_SEE"
        >See plans and pricing</a
      >
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="(variationContent$ | async)?.currentVariation === 'b'">
  <div id="buy-now">
    <span class="buy-now-text" i18n="@@S_DEMO_VARIATION_B_COMPATIBILITY"
      >Are your vehicles compatible with Reveal Starter self-install hardware?</span
    >
    <a
      a7s-id="demo-live-map-buy-now"
      href="https://www.verizonconnect.com/compatibility-checker/"
      class="btn top-banner-button"
      i18n="@@S_DEMO_VARIATION_B_COMPATIBILITY_CHECK"
      >Check compatibility</a
    >
  </div>
</ng-container>
