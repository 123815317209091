/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { INotification, INotificationDetails, EDeviceStatusSnackbarTypes } from '../../models';

export interface INotificationsState {
  notifications: INotification[];
  notificationDetail: INotificationDetails;
  snackbarSuccess: EDeviceStatusSnackbarTypes;
  snackbarError: boolean;
  shouldShowNonPanicNotifications: boolean;
}

export const initialNotificationsState: INotificationsState = {
  notifications: [],
  notificationDetail: null,
  snackbarSuccess: EDeviceStatusSnackbarTypes.standard,
  snackbarError: false,
  shouldShowNonPanicNotifications: true
};
