/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IPlotHttpService } from './plot-http.service.interface';
import {
  IVehiclePlotResponse,
  IWorkOrderRequest,
  IWorkOrderPlot,
  IWorkOrderTypesAndStatusesResponse,
  IWorkOrderUserSettings,
  IRootGroupResponse,
  EVehicleSelectionMode,
  IMapHierarchyResponse
} from '../../models';
import { IAppConfig } from '../../../config';

@Injectable()
export class PlotHttpService implements IPlotHttpService {
  private readonly _plotRootUrl: string;
  private readonly _hierarchyRootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._plotRootUrl = `${_configService.config.endpoints.plotApiEndpoint}/Plot`;
    this._hierarchyRootUrl = `${_configService.config.endpoints.plotApiEndpoint}/v2/Hierarchy`;
  }

  public getVehiclePlots(entityIds: string): Observable<IVehiclePlotResponse[]> {
    const body = new HttpParams().set('SelectedEntityIds', entityIds).toString();
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._httpClient.post<IVehiclePlotResponse[]>(`${this._plotRootUrl}/GetVehiclePlots/`, body, { headers });
  }

  public getAssignedWorkOrders(request: IWorkOrderRequest): Observable<IWorkOrderPlot[]> {
    return this._httpClient.post<IWorkOrderPlot[]>(`${this._plotRootUrl}/AssignedWorkOrders/`, request).pipe(map(response => response));
  }

  public getUnassignedWorkOrders(request: IWorkOrderRequest): Observable<IWorkOrderPlot[]> {
    return this._httpClient.post<IWorkOrderPlot[]>(`${this._plotRootUrl}/UnassignedWorkOrders/`, request).pipe(map(response => response));
  }

  public getWorkOrderTypesAndStatuses(): Observable<IWorkOrderTypesAndStatusesResponse> {
    return this._httpClient.get<IWorkOrderTypesAndStatusesResponse>(`${this._plotRootUrl}/WorkOrderTypesAndStatuses/`);
  }

  public getWorkOrderSettings(): Observable<IWorkOrderUserSettings> {
    return this._httpClient.get<IWorkOrderUserSettings>(`${this._plotRootUrl}/GetWorkOrderUserSettings/`);
  }

  public updateWorkOrderSettings(request: IWorkOrderUserSettings): Observable<void> {
    return this._httpClient.post<void>(`${this._plotRootUrl}/UpdateWorkOrderUserSettings/`, request);
  }

  public getAccessibleVehiclesCount(): Observable<number> {
    return this._httpClient.get<number>(`${this._plotRootUrl}/GetAccessibleVehiclesCount/`);
  }

  public getRootGroupForHierarchy(): Observable<IRootGroupResponse> {
    return this._httpClient.get<IRootGroupResponse>(`${this._plotRootUrl}/GetRootGroup/`);
  }

  public getVehiclePlotsForUser(): Observable<IVehiclePlotResponse[]> {
    return this._httpClient.get<IVehiclePlotResponse[]>(`${this._plotRootUrl}/GetVehiclePlotsForUser`);
  }

  public getMapHierarchy(requestMode: EVehicleSelectionMode, isScopingEnabled: boolean): Observable<IMapHierarchyResponse> {
    let params = new HttpParams();
    params = params.append('request[Mode]', requestMode.toString());
    params = params.append('request[CookieExists]', 'true');
    params = params.append('request[KeepItemsUnderRootGroup]', 'true');
    params = params.append('request[ScopedVehiclesOnly]', `${isScopingEnabled}`);

    return this._httpClient
      .get<IMapHierarchyResponse>(`${this._hierarchyRootUrl}/LiveMap`, { params })
      .pipe(map(result => result));
  }

  public getMapHierarchyScoping(): Observable<IMapHierarchyResponse> {
    return this._httpClient.get<IMapHierarchyResponse>(`${this._hierarchyRootUrl}/LiveMapScoping`).pipe(map(result => result));
  }
}
