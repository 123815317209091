/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IWorkOrderState } from '../state';

export const getWorkOrderState = createFeatureSelector<IWorkOrderState>('workOrder');

export const getIsEditWorkOrderModalOpen = createSelector(
  getWorkOrderState,
  state => state.isEditWorkOrderModalOpen
);
export const getWorkOrderToEdit = createSelector(
  getWorkOrderState,
  state => state.workOrderToEdit
);
export const getHasEditWorkOrderAnError = createSelector(
  getWorkOrderState,
  state => state.hasError
);
export const getIsEditWorkOrderSaving = createSelector(
  getWorkOrderState,
  state => state.isSaving
);
