/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { SubscriptionLike } from 'rxjs';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { IAppState } from '../../store/state';
import { CloseReplayOnLiveMap } from '../../store/actions';
import { IBrowserLocationService } from './browser-location.service.interface';

@Injectable({
  providedIn: 'root'
})
export class BrowserLocationService implements IBrowserLocationService, OnDestroy {
  private _replaySubscription: SubscriptionLike;

  constructor(private readonly _location: Location, private readonly _store: Store<IAppState>) {}

  ngOnDestroy(): void {
    if (!isElementNull(this._replaySubscription)) {
      this._replaySubscription.unsubscribe();
    }
  }

  onBackButtonCloseReplay(): void {
    if (isElementNull(this._replaySubscription)) {
      // setup behavior for the first time
      this._replaySubscription = this._location.subscribe(event => {
        // this event is fired when back/forward buttons are pressed
        if (event.state?.page === 0) {
          // page 0 represents the main page when the panel is closed
          this._store.dispatch(new CloseReplayOnLiveMap());
        }
      });
      // update current history entry. page 0 is the main replay page
      this._location.replaceState(this._location.path(), undefined, { page: 0 });
      // add to history a new entry
      this._location.go(this._location.path(), undefined, { page: 1 });
    } else {
      // naviagate forward so if the back button is clicked we'll stay on the map
      this._location.forward();
    }
  }

  navigateToFirstPage(): void {
    const currentState = <any>this._location.getState();
    if (currentState?.page === 1) {
      // replay was closed but we are still on page 1.
      // naviagate back one entry so if the user clicks back the browser will actually go the previous page
      this._location.back();
    }
  }
}
