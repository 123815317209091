/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { LatLng } from './lat-lng.class';
import { EPlotType } from './plot-type.enum';
import { IMarkerOptions } from './marker-options.interface';

export abstract class MarkerInfo {
  constructor(
    public id: number,
    public latLng: LatLng,
    public title: string,
    public type: EPlotType,
    public options: IMarkerOptions,
    public shape?: google.maps.Polygon | google.maps.Circle,
    // @TODO remove this property after managing the max plottable markers out of the map
    public shouldAlwaysBePlotted?: boolean
  ) {}
}
