/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EDateRange {
  Yesterday,
  Today,
  Tomorrow,
  Custom
}
