/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { getHarshDrivingEvent } from './harsh-driving-events.selectors';
import { getVehicleStatusFilterType } from './map-options.selectors';
import {
  getGeofencePlots,
  getHotspotPlots,
  getVehiclePlots,
  getGarminStopsPlots,
  getWorkOrdersPlots,
  getAssetPlots
} from './plots.selectors';
import { IMapsState } from '../state';
import {
  EPlotType,
  ICoordinates,
  IGeofencePlot,
  IHotspotPlot,
  IShapes,
  IVehiclePlot,
  LatLng,
  MarkerInfo,
  MarkerShape,
  IGarminStopPlot,
  VehicleMarkerInfo,
  GarminMarkerInfo,
  GeofenceMarkerInfo,
  IWorkOrderPlot,
  WorkOrderMarkerInfo,
  IMarkerOptions,
  EMarkerShapeType,
  ILatLng,
  EVehicleStatusType,
  HarshDrivingEventMarkerInfo,
  EPlotIcon,
  IAssetPlot,
  AssetMarkerInfo,
  ILatLngBounds,
  EStartLocationSource
} from '../../models';

const _getPlotMarkerInfo = (plot: IGeofencePlot | IGarminStopPlot | IHotspotPlot, plotType: EPlotType): MarkerInfo => {
  const latLng = new LatLng(plot.center.Latitude, plot.center.Longitude);
  const shapes: LatLng[] = [];

  plot.shapes.Shapes.forEach((shape: IShapes) => {
    shape.Points.forEach((point: ICoordinates) => {
      const latLng2 = new LatLng(point.Latitude, point.Longitude);
      shapes.push(latLng2);
    });
  });

  let googleMarkerShape: google.maps.Polygon | google.maps.Circle = null;
  if (plot.shapes.Center) {
    const markerShape = {
      center: new LatLng(plot.shapes.Center.Latitude, plot.shapes.Center.Longitude),
      radius: plot.shapes.Radius,
      shapes: shapes,
      shapeColor: plot.shpcolor,
      shapeType: plot.shptype
    };
    googleMarkerShape = new MarkerShape(markerShape).toGoogleMapShape();
  }

  switch (plotType) {
    case EPlotType.garmin:
      return new GarminMarkerInfo(
        plot.id,
        latLng,
        plot.name,
        plotType,
        plot.extraoptionalid,
        { iconName: plot.iconclass } as IMarkerOptions,
        googleMarkerShape
      );
    default:
      return new GeofenceMarkerInfo(
        plot.id,
        latLng,
        plot.name,
        plotType,
        plot.type,
        { iconName: plot.iconclass } as IMarkerOptions,
        googleMarkerShape,
        plot.isFromSearchLight
      );
  }
};

export const getMapsState = createFeatureSelector<IMapsState>('maps');
export const getMapCenter = createSelector(
  getMapsState,
  (state: IMapsState): ILatLng => new LatLng(state.mapCenter.lat, state.mapCenter.lng)
);

export const getMapVehicleMarkers = createSelector(getVehiclePlots, (vehiclePlots: IVehiclePlot[]) =>
  vehiclePlots.map((plot: IVehiclePlot) => {
    const latLng = new LatLng(plot.coordinates.lat, plot.coordinates.lng);
    return new VehicleMarkerInfo(plot.id, latLng, plot.vehicleName, plot.type, plot.status, plot.driverId, plot.hasNavigationDevice, {
      iconName: plot.iconClass
    } as IMarkerOptions);
  })
);

export const getMapVehicleMarkersFilteredByStatusType = createSelector(
  getMapVehicleMarkers,
  getVehicleStatusFilterType,
  (vehicleMarkers: VehicleMarkerInfo[], vehicleStatusTypeFilter: EVehicleStatusType) => {
    let markersFiltered = [...vehicleMarkers];
    if (vehicleStatusTypeFilter !== EVehicleStatusType.All) {
      if (vehicleStatusTypeFilter === EVehicleStatusType.Stopped) {
        markersFiltered = markersFiltered.filter(
          marker => marker.status === EVehicleStatusType.Stopped || marker.status === EVehicleStatusType.Idle
        );
      } else {
        markersFiltered = markersFiltered.filter(marker => marker.status === vehicleStatusTypeFilter);
      }
    }
    return markersFiltered;
  }
);

export const getMapHotspotsMarkers = createSelector(getHotspotPlots, (hotspotPlots: IHotspotPlot[]) =>
  hotspotPlots.map((plot: IHotspotPlot) => {
    return _getPlotMarkerInfo(plot, EPlotType.hotspot);
  })
);

export const getMapGeofenceMarkers = createSelector(getGeofencePlots, (geofencePlots: IGeofencePlot[]) =>
  geofencePlots.map((plot: IGeofencePlot) => {
    return _getPlotMarkerInfo(plot, EPlotType.geofence);
  })
);

export const getMapGarminStopMarkers = createSelector(getGarminStopsPlots, (garminStopsPlots: IGarminStopPlot[]) =>
  garminStopsPlots.map((plot: IGarminStopPlot) => {
    return _getPlotMarkerInfo(plot, EPlotType.garmin);
  })
);

export const getAssetsMarkers = createSelector(getAssetPlots, (assetPlots: IAssetPlot[]) =>
  assetPlots.map((plot: IAssetPlot) => {
    const latLng = new LatLng(plot.center.Latitude, plot.center.Longitude);
    const mapOptions = {
      iconName: plot.iconClass
    } as IMarkerOptions;
    return new AssetMarkerInfo(plot.id, latLng, plot.name, EPlotType.asset, mapOptions);
  })
);

export const getMapWorkOrderMarkers = createSelector(getWorkOrdersPlots, (workOrdersPlots: IWorkOrderPlot[]) =>
  workOrdersPlots.map((plot: IWorkOrderPlot) => {
    const latLng = new LatLng(plot.Point.Latitude, plot.Point.Longitude);
    const className = 'workorder ' + (plot.ShapeId === 0 ? 'square' : 'circle') + plot.ColorId;
    const shapes: LatLng[] = [];
    let googleMarkerShape: google.maps.Circle = null;
    if (plot.Radius > 0) {
      const markerShape = {
        center: latLng,
        radius: plot.Radius,
        shapes: shapes,
        shapeColor: '#00FFFF',
        shapeType: EMarkerShapeType.Circle
      };
      googleMarkerShape = new MarkerShape(markerShape).toGoogleMapShape() as google.maps.Circle;

      return new WorkOrderMarkerInfo(
        plot.WorkOrderId,
        latLng,
        '',
        EPlotType.workOrder,
        plot.DriverId,
        {
          className: className,
          htmlContent: plot.Letter
        } as IMarkerOptions,
        googleMarkerShape,
        plot.isFromSearchLight
      );
    }
  })
);

export const getHarshDrivingEventMarker = createSelector(getHarshDrivingEvent, event => {
  return !isElementNull(event)
    ? new HarshDrivingEventMarkerInfo(
        event.id,
        new LatLng(event.address.latitude, event.address.longitude),
        event.classification.toString(),
        EPlotType.harshDrivingEvent,
        {
          iconName: EPlotIcon.HarshDrivingEvent
        },
        null,
        true
      )
    : null;
});

export const getIsTrafficEnabled = createSelector(getMapsState, (state: IMapsState): boolean => state.isTrafficEnabled);

export const getIsClusteringEnabled = createSelector(getMapsState, (state: IMapsState): boolean => state.isClusteringEnabled);

export const getMapBounds = createSelector(getMapsState, (state: IMapsState): ILatLngBounds => state.bounds);
export const getMapChangeBounds = createSelector(getMapsState, (state: IMapsState): ILatLng[] => state.changeBounds);
export const getIsStreetViewOpen = createSelector(getMapsState, (state: IMapsState): boolean => state.isStreetViewOpen);
export const getStreetViewLocation = createSelector(getMapsState, (state: IMapsState): ILatLng => state.streetViewLocation);
export const getShowStartingLocation = createSelector(
  getMapsState,
  (state: IMapsState): EStartLocationSource => state.showStartingLocation
);
export const getMapZoomLevel = createSelector(getMapsState, (state: IMapsState): number => state.zoomLevel);
export const getMarkerToHighlight = createSelector(getMapsState, (state: IMapsState): MarkerInfo => state.markerToHighlight);
export const getSelectedMarker = createSelector(getMapsState, (state: IMapsState): MarkerInfo => state.selectedMarker);
export const getMarkersToHighlight = createSelector(getAssetsMarkers, getMapVehicleMarkers,
  (vehicleMarkers: MarkerInfo[], assetMarkers: MarkerInfo[]): MarkerInfo[] => [...vehicleMarkers, ...assetMarkers]);
