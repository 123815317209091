/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of, iif } from 'rxjs';
import { switchMap, map, catchError, filter } from 'rxjs/operators';

import { isStringNullOrEmpty, isElementNull } from '@fleetmatics/ui.utilities';

import {
  TriggerEditWorkOrder,
  EWorkOrderActions,
  TriggerEditWorkOrderSuccess,
  SaveWorkOrder,
  SaveWorkOrderSuccess,
  SaveWorkOrderError,
  GetWorkOrdersPlots
} from '../actions';
import {
  IGetWorkOrderResponse,
  IWorkOrder,
  IUpdateWorkOrderRequest,
  IApiResponseDto,
  EHttpStatusCode,
  IReverseGeocodeResponse
} from '../../models';
import { PlanningSchedulingHttpService, AddressResolutionHttpService } from '../../services';
import { delayedRetry } from '../../operators';

@Injectable()
export class WorkOrderEffects {

  public triggerEditWorkOrder$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<TriggerEditWorkOrder>(EWorkOrderActions.TriggerEditWorkOrder),
    switchMap(action =>
      this._planningSchedulingHttpService.getWorkOrder(action.payload).pipe(
        delayedRetry(),
        catchError(() => of(null))
      )
    ),
    filter(response => !isElementNull(response)),
    map(this._mapGetWorkOrderResponseToModel),
    switchMap(workOrder =>
      this._reverseGeocodeWorkOrder(workOrder).pipe(
        map(results => {
          if (results.length > 0) {
            const result = results[0];
            workOrder.displayAddress = result.fulladdress;
          }

          return workOrder;
        }),
        // if reverse geocoding fails, return the original work order object
        catchError(() => of(workOrder))
      )
    ),
    map(workOrder => new TriggerEditWorkOrderSuccess(workOrder))
  ));

  public saveWorkOrder$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<SaveWorkOrder>(EWorkOrderActions.SaveWorkOrder),
    switchMap(action =>
      this._ensureAddressIsResolved(action.payload).pipe(
        delayedRetry(),
        map(workOrder => this._mapWorkOrderToUpdateWorkOrderRequest(workOrder)),
        catchError(() => of(null))
      )
    ),
    switchMap((updateWorkOrderRequest: IUpdateWorkOrderRequest) =>
      iif(
        () => isElementNull(updateWorkOrderRequest),
        of(new SaveWorkOrderError()),
        this._planningSchedulingHttpService.updateWorkOrder(updateWorkOrderRequest).pipe(
          delayedRetry(),
          map((response: IApiResponseDto<any>) => {
            if (response.Message.StatusCode === EHttpStatusCode.Ok) {
              return new SaveWorkOrderSuccess();
            }
            return new SaveWorkOrderError();
          }),
          catchError(() => of(new SaveWorkOrderError()))
        )
      )
    )
  ));

  public saveWorkOrderSuccess$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<SaveWorkOrderSuccess>(EWorkOrderActions.SaveWorkOrderSuccess),
    map(() => new GetWorkOrdersPlots())
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _planningSchedulingHttpService: PlanningSchedulingHttpService,
    private readonly _addressResolutionHttpService: AddressResolutionHttpService
  ) {}

  private _ensureAddressIsResolved(workOrder: IWorkOrder): Observable<IWorkOrder> {
    if (isStringNullOrEmpty(workOrder.address1)) {
      return this._reverseGeocodeWorkOrder(workOrder).pipe(
        map(results => {
          if (results.length > 0) {
            const result = results[0];
            workOrder.address1 = result.fulladdress;
            workOrder.address2 = result.addrComponents;
          }

          return workOrder;
        })
      );
    }
    return of(workOrder);
  }

  private _mapGetWorkOrderResponseToModel(response: IGetWorkOrderResponse): IWorkOrder {
    return {
      address1: response.address1,
      address2: response.address2,
      capacity1: response.capacity1,
      capacity2: response.capacity2,
      capacity3: response.capacity3,
      category: response.category,
      categoryCode: response.categoryCode,
      city: response.city,
      clientWorkOrderId: response.clientWorkOrderID,
      contact: response.contact,
      country: response.country,
      customer: response.customer,
      deliveryType: response.deliveryType,
      description: response.description,
      displayAddress: response.displayAddress,
      driverId: response.driverid,
      durationInHours: response.durationH,
      durationInMinutes: response.durationM,
      enableCustomerCreation: response.EnableCustomerCreation,
      endTimeHours: response.endTimeH,
      endTimeHours2: response.endTime2H,
      endTimeMinutes: response.endTimeM,
      endTimeMinutes2: response.endTime2M,
      hasStopMode: response.hasStopMode,
      lat: response.lat,
      lng: response.lng,
      phone: response.phone,
      postalCode: response.postcode,
      priority: response.priority,
      routePlanId: response.routeplanID,
      scheduledDateTime: response.scheduledDateTime,
      skillCount: response.skillCount,
      skillDescriptions: response.skillDescriptions,
      skillIds: response.skillIds,
      startTimeHours: response.startTimeH,
      startTimeHours2: response.startTime2H,
      startTimeMinutes: response.startTimeM,
      startTimeMinutes2: response.startTime2M,
      state: response.state,
      type: response.type,
      typeCode: response.typeCode,
      workOrderId: response.workOrderId,
      workorderCompleteStatus: response.workorderCompleteStatus
    };
  }

  private _mapWorkOrderToUpdateWorkOrderRequest(workOrder: IWorkOrder): IUpdateWorkOrderRequest {
    return {
      updateDTO: {
        address1: workOrder.address1,
        address2: workOrder.address2,
        capacity1: workOrder.capacity1,
        capacity2: workOrder.capacity2,
        capacity3: workOrder.capacity3,
        category: workOrder.category,
        categoryCode: workOrder.categoryCode,
        city: workOrder.city,
        clientWorkOrderID: workOrder.clientWorkOrderId,
        contact: workOrder.contact,
        country: workOrder.country,
        customer: workOrder.customer,
        deliveryType: workOrder.deliveryType,
        description: workOrder.description,
        displayAddress: workOrder.displayAddress,
        driverid: workOrder.driverId,
        durationH: workOrder.durationInHours,
        durationM: workOrder.durationInMinutes,
        EnableCustomerCreation: workOrder.enableCustomerCreation,
        endTime2H: workOrder.endTimeHours2,
        endTime2M: workOrder.endTimeMinutes2,
        endTimeH: workOrder.endTimeHours,
        endTimeM: workOrder.endTimeMinutes,
        hasStopMode: workOrder.hasStopMode,
        lat: workOrder.lat,
        lng: workOrder.lng,
        phone: workOrder.phone,
        postcode: workOrder.postalCode,
        priority: workOrder.priority,
        routeplanID: workOrder.routePlanId,
        scheduledDateTime: workOrder.scheduledDateTime,
        skillCount: workOrder.skillCount,
        skillDescriptions: workOrder.skillDescriptions,
        skillIds: workOrder.skillIds,
        startTime2H: workOrder.startTimeHours2,
        startTime2M: workOrder.startTimeMinutes2,
        startTimeH: workOrder.startTimeHours,
        startTimeM: workOrder.startTimeMinutes,
        state: workOrder.state,
        type: workOrder.type,
        typeCode: workOrder.typeCode,
        workOrderId: workOrder.workOrderId,
        workorderCompleteStatus: workOrder.workorderCompleteStatus
      }
    };
  }

  private _reverseGeocodeWorkOrder(workOrder: IWorkOrder): Observable<IReverseGeocodeResponse[]> {
    const reverseGeocodeRequest = [
      {
        key: 'placeaddress',
        data: {
          Latitude: workOrder.lat,
          Longitude: workOrder.lng
        }
      }
    ];
    return this._addressResolutionHttpService.getReverseGeocode(reverseGeocodeRequest);
  }
}
