/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Observable, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class FlatTreeVirtualScrollingDataSource<T> extends DataSource<T> {
  constructor(private readonly _treeControl: FlatTreeControl<T>) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return merge(of(this._treeControl.expansionModel.selected), this._treeControl.expansionModel.changed).pipe(
      map(() => {
        const results: T[] = [];
        const levelExpansionStatus: boolean[] = [true];

        this._treeControl.dataNodes.forEach(node => {
          let expand = true;
          for (let i = 0; i <= this._treeControl.getLevel(node); i++) {
            expand = expand && levelExpansionStatus[i];
          }
          if (expand) {
            results.push(node);
          }

          if (this._treeControl.isExpandable(node)) {
            levelExpansionStatus[this._treeControl.getLevel(node) + 1] = this._treeControl.isExpanded(node);
          }
        });
        return results;
      })
    );
  }

  disconnect(collectionViewer: CollectionViewer): void {
    // no op
  }
}
