/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CheckboxModule } from '@fleetmatics/ui.base-library';

import { ScopingTreeComponent } from './scoping-tree.component';

@NgModule({
  imports: [CdkTreeModule, CommonModule, CheckboxModule, ScrollingModule],
  declarations: [ScopingTreeComponent],
  exports: [ScopingTreeComponent]
})
export class ScopingTreeModule {}
