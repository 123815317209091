/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GoogleMapsLightComponent } from './google-maps-light.component';

@NgModule({
  imports: [CommonModule],
  declarations: [GoogleMapsLightComponent],
  exports: [GoogleMapsLightComponent]
})
export class GoogleMapsLightModule {}
