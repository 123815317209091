/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

/* tslint:disable:directive-selector */
import { Directive, Input, Output, EventEmitter, TemplateRef, ElementRef, OnDestroy } from '@angular/core';

import { TabsComponent } from '../tabs.component';
import { ITabContext } from './tab-context.interface';

@Directive({
  selector: 'fmTab'
})
export class TabDirective implements OnDestroy {
  @Input()
  public shouldDestroyOnDeactivation: boolean;
  @Input()
  public heading: string;
  @Input()
  public id: string | number;
  @Input()
  public ariaLabelName: string;
  @Input()
  public ariaLabelTranslation: string;
  @Input()
  public set visible(value: boolean) {
    this._visible = value;
    if (!this._visible) {
      this.tabset.removeTab(this);
    } else if (!this.tabset.contains(this)) {
      this.tabset.addTab(this);
    }
  }
  public get visible(): boolean {
    return this._visible;
  }

  @Input()
  public get active(): boolean {
    return this._active;
  }

  public set active(active: boolean) {
    if (this._active === active) {
      return;
    }
    if (!active) {
      this._setInactive();
      return;
    }

    this._setActive();
  }

  @Output()
  public selected: EventEmitter<TabDirective> = new EventEmitter();
  @Output()
  public deselected: EventEmitter<TabDirective> = new EventEmitter();
  @Output()
  public removed: EventEmitter<TabDirective> = new EventEmitter();

  public contentRef: TemplateRef<any>;
  public headingRef: TemplateRef<any>;
  public tabContext: ITabContext;
  public tabset: TabsComponent;

  protected _active: boolean;

  private _visible = true;

  constructor(tabset: TabsComponent, public elementRef: ElementRef) {
    this.tabContext = { active: this._active };
    this.tabset = tabset;
    this.tabset.addTab(this);
  }

  ngOnDestroy(): void {
    this.tabset.removeTab(this, { reselect: true, emit: false });
  }

  private _setInactive(): void {
    if (this._active) {
      this.deselected.emit(this);
      this._active = false;
      this.tabContext.active = this._active;
      if (this.tabset.activeTab === this) {
        this.tabset.activeTab = null;
      }
    }
  }

  private _setActive(): void {
    if (this.tabset.contains(this)) {
      this._active = true;
      this.tabContext.active = this._active;
      this.tabset.activeTab = this;
      this.selected.emit(this);
      this.tabset.tabs.forEach((tab: TabDirective) => {
        if (tab !== this) {
          tab.active = false;
        }
      });
    } else {
      this.tabset.ensureThereIsOneTabSelected();
    }
  }
}
