<div class="modal"
  [ngClass]="config.customClass">
  <div class="modal__wrapper"
    [ngClass]="{'modal__wrapper-disable-responsiveness': config.disableResponsiveness,
      'modal__wrapper-sm': config.size === 'small',
      'modal__wrapper-lg': config.size === 'large'}">
    <div class="modal__close">
      <ds-icon *ngIf="config.showCloseIcon"
        (click)="onCloseClick()"
        class="modal__close-icon"
        [name]="config.closeIcon"
        size="extraSmall"></ds-icon>
    </div>
    <ng-container #content></ng-container>
  </div>
  <div class="modal__overlay"></div>
</div>
