/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { PendoService } from '@fleetmatics/ui.insights';
import { EPendoCustomEvents } from '../../../core/models';
import { IPendoTrackEventService } from './pendo-track-event.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PendoTrackEventService implements IPendoTrackEventService {
  public sendPendoTrackEventWhenReady(event: EPendoCustomEvents, properties: any = {}): void {
    let retries = 0;
    if (this._isPendoLoaded()) {
      this._sendTrackEvent(event, properties);
    }
    const pendoReady = setInterval(() => {
      if (retries === 3) {
        clearInterval(pendoReady);
      }
      if (this._isPendoLoaded()) {
        this._sendTrackEvent(event, properties);
        clearInterval(pendoReady);
      }
      retries++;
    }, 5000);
  }

  constructor(private readonly _pendoService: PendoService) {}

  private _sendTrackEvent(event: EPendoCustomEvents, properties: any): void {
    this._pendoService.trackEvent(`${event}`, properties);
  }

  private _isPendoLoaded(): boolean {
    return window.pendo && (window.pendo as any).track && (window.pendo as any).isReady();
  }
}
