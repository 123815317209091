<label class="radio-button">
  <ng-content></ng-content>
  <input class="radio-button__input"
    type="radio"
    [attr.name]="name"
    [checked]="isChecked"
    (change)="onChange($event)"
    (click)="$event.stopPropagation()">
  <div class="radio-button__checkmark"
    [class.radio-button__checkmark--checked]="isChecked"></div>
</label>
