/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import {
  IDriverDetails,
  ISmsOptInStatus,
  IDriverEmailMessage,
  IDriverDetailsParams,
  ISmsInvitation,
  IDriverSmsMessage
} from '../../models';

export enum EDriverMessageActions {
  OpenDriverMessageModal = '[Driver Message] Open Driver Message Modal',
  OpenDriverMessageModalSuccess = '[Driver Message] Open Driver Message Modal Success',
  GetSmsOptInStatus = '[Driver Message] Get SMS Opt-In Status',
  GetSmsOptInStatusSuccess = '[Driver Message] Get SMS Opt-In Status Success',
  SendEmailToDriver = '[Driver Message] Send Email To Driver',
  SendEmailToDriverSuccess = '[Driver Message] Send Email To Driver Success',
  CloseDriverMessageModal = '[Driver Message] Close Driver Message Modal',
  SendSmsInvitationToDriver = '[Driver Message] Send SMS Invitation To Driver',
  SendSmsInvitationToDriverSuccess = '[Driver Message] Send SMS Invitation To Driver Success',
  SendSmsToDriver = '[Driver Message] Send SMS To Driver',
  SendSmsToDriverSuccess = '[Driver Message] Send SMS To Driver Success'
}

export class OpenDriverMessageModal implements Action {
  public readonly type = EDriverMessageActions.OpenDriverMessageModal;

  constructor(public payload: IDriverDetailsParams) { }
}

export class OpenDriverMessageModalSuccess implements Action {
  public readonly type = EDriverMessageActions.OpenDriverMessageModalSuccess;

  constructor(public payload: IDriverDetails) { }
}

export class GetSmsOptInStatus implements Action {
  public readonly type = EDriverMessageActions.GetSmsOptInStatus;

  constructor(public payload: number) { }
}

export class GetSmsOptInStatusSuccess implements Action {
  public readonly type = EDriverMessageActions.GetSmsOptInStatusSuccess;

  constructor(public payload: ISmsOptInStatus) { }
}

export class SendEmailToDriver implements Action {
  public readonly type = EDriverMessageActions.SendEmailToDriver;

  constructor(public payload: IDriverEmailMessage) { }
}

export class SendEmailToDriverSuccess implements Action {
  public readonly type = EDriverMessageActions.SendEmailToDriverSuccess;

  constructor(public payload: boolean) { }
}

export class CloseDriverMessageModal implements Action {
  public readonly type = EDriverMessageActions.CloseDriverMessageModal;
}

export class SendSmsInvitationToDriver implements Action {
  public readonly type = EDriverMessageActions.SendSmsInvitationToDriver;

  constructor(public payload: number) { }
}

export class SendSmsInvitationToDriverSuccess implements Action {
  public readonly type = EDriverMessageActions.SendSmsInvitationToDriverSuccess;

  constructor(public payload: ISmsInvitation) { }
}

export class SendSmsToDriver implements Action {
  public readonly type = EDriverMessageActions.SendSmsToDriver;

  constructor(public payload: IDriverSmsMessage) { }
}

export class SendSmsToDriverSuccess implements Action {
  public readonly type = EDriverMessageActions.SendSmsToDriverSuccess;

  constructor(public payload: boolean) { }
}

export type DriverMessageActions =
  OpenDriverMessageModal |
  OpenDriverMessageModalSuccess |
  GetSmsOptInStatus |
  GetSmsOptInStatusSuccess |
  SendEmailToDriver |
  SendEmailToDriverSuccess |
  CloseDriverMessageModal |
  SendSmsInvitationToDriver |
  SendSmsInvitationToDriverSuccess |
  SendSmsToDriver |
  SendSmsToDriverSuccess;
