/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Type, NgModuleFactory } from '@angular/core';

import { ELazyModules } from '../core/models';

const lazyModules: { name: string; loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> }[] = [
  {
    name: ELazyModules.External,
    loadChildren: () => import('../external/external.module').then(m => m.ExternalModule)
  },
  {
    name: ELazyModules.VehicleDriverPanel,
    loadChildren: () => import('../vehicle-driver-panel').then(m => m.VehicleDriverPanelModule)
  },
  {
    name: ELazyModules.OverlayControls,
    loadChildren: () => import('../overlay-controls').then(m => m.OverlayControlsModule)
  }
];

export function configureLazyLoader(): { [key: string]: () => Promise<NgModuleFactory<any> | Type<any>> } {
  const result: { [key: string]: () => Promise<NgModuleFactory<any> | Type<any>> } = {};
  for (const w of lazyModules) {
    result[w.name] = w.loadChildren;
  }
  return result;
}
