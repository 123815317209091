/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Observable } from 'rxjs';
import { tap, withLatestFrom, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { ILayoutPreferencesState, IAppState } from '../../core/store/state';
import { LayoutPreferencesService } from '../../core/services/layout-preferences';
import { getLayoutPreferences } from '../../core/store/selectors';
import { UpdateLayoutPreferences } from '../store/actions/layout-preferences.actions';

export const updateLayoutPreferences = (
  func: (action: any, state: ILayoutPreferencesState) => ILayoutPreferencesState,
  storeInstance: Store<IAppState>,
  layoutPreferencesService: LayoutPreferencesService
) => <T1>(source: Observable<T1>): Observable<UpdateLayoutPreferences> => {
  return source.pipe(
    withLatestFrom(storeInstance.pipe(select(getLayoutPreferences)), func),
    tap((layoutPreferences: ILayoutPreferencesState) => layoutPreferencesService.save(layoutPreferences)),
    map((layoutPreferences: ILayoutPreferencesState) => new UpdateLayoutPreferences(layoutPreferences))
  );
};
