/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { ESpeedUnits } from '../../../core/models';

@Pipe({
  name: 'speedUnits'
})
export class SpeedUnitsPipe implements PipeTransform {
  transform(speed: number, speedUnits: ESpeedUnits): string {
    let speedUnit = `${speed} mph`;

    switch (speedUnits) {
      case ESpeedUnits.CentimetersPerSecond:
        speedUnit = `${speed} cm/sec`;
        break;
      case ESpeedUnits.KilometersPerHour:
        speedUnit = `${speed} km/h`;
        break;
      case ESpeedUnits.MilesPerHour:
        speedUnit = `${speed} mph`;
        break;
      case ESpeedUnits.Knots:
        speedUnit = `${speed} knots`;
        break;
    }

    return speedUnit;
  }
}
