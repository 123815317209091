<div class="d-flex fm-search-light-input"
  cdkOverlayOrigin
  [class.fm-search-light-input--active]="isTriggerableOpen">
  <ng-content></ng-content>
  <input type="text"
    autocomplete="off"
    [attr.placeholder]="placeholder"
    [attr.id]="elementId"
    [class.fm-search-light-input__input--with-icon]="showIcon"
    [ngClass]="classes"
    [ngModel]="searchText"
    (ngModelChange)="onSearchTextChanged($event)"
    (keydown)="onKeyDown($event)"
    fmTrigger
    fmFocusTrigger
    fmKeyboardTrigger
    focusOnClose="false"
    #searchInput>
  <ds-icon *ngIf="showIcon && !isLoading"
    class="fm-search-light-input__icon"
    name="ds-ic-search"
    size="extraSmall"></ds-icon>
  <div class="fm-search-light-input__loader">
    <span *ngIf="showIcon && isLoading"
      class="loader fm-search-light-input__loader-icon"></span>
  </div>
</div>
