/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ButtonModule, InputModule, SelectModule } from '@fleetmatics/ui.base-library';

import { GoogleMapsLightModule } from '../google-maps-light';
import { ModalModule } from '../modal';
import { TabsModule } from '../tabs';
import { DeviceStatusComponent } from './device-status';
import { NotificationComponent } from './notification';
import { NotificationDetailsComponent } from './notification-details';
import { NotificationsComponent } from './notifications.component';
import { BoldWrapPipe } from './pipes';
import { AlertService, DeviceStatusHttpService, NotificationsService, RedirectService } from './services';
import { NotificationsEffects, NotificationsReducer } from './store';
import { NotificationsIconModule } from './notifications-icon.module';

@NgModule({
  imports: [
    CommonModule,
    NotificationsIconModule,
    ButtonModule,
    SelectModule,
    ReactiveFormsModule,
    FormsModule,
    InputModule,
    ModalModule,
    TabsModule,
    GoogleMapsLightModule,
    StoreModule.forFeature('notificationsFeature', NotificationsReducer),
    EffectsModule.forFeature([NotificationsEffects]),
    MatIconModule
  ],
  declarations: [NotificationsComponent, NotificationComponent, BoldWrapPipe, NotificationDetailsComponent, DeviceStatusComponent],
  exports: [NotificationsComponent, NotificationComponent],
  providers: [NotificationsService, DeviceStatusHttpService, RedirectService, AlertService]
})
export class NotificationsModule {}
