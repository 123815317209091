/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { DemoProgress, EDemoStories } from '../models';

export const initialDemoProgressData: DemoProgress = {
  stories: [
    {
      currentStep: 0,
      steps: 1,
      isDismissed: false,
      name: EDemoStories.SplashScreen,
      isReplayable: false
    },
    {
      currentStep: 0,
      steps: 4,
      isDismissed: false,
      name: EDemoStories.FleetBasics,
      isReplayable: true
    },
    {
      currentStep: 0,
      steps: 2,
      isDismissed: false,
      name: EDemoStories.Safety,
      isReplayable: true
    },
    {
      currentStep: 0,
      steps: 3,
      isDismissed: false,
      name: EDemoStories.SafetyAlerts,
      isReplayable: true
    },
    {
      currentStep: 0,
      steps: 5,
      isDismissed: false,
      name: EDemoStories.CostAndEfficiency,
      isReplayable: true
    },
    {
      currentStep: 0,
      steps: 2,
      isDismissed: false,
      name: EDemoStories.OnTheGoMap,
      isReplayable: true
    },
    {
      currentStep: 0,
      steps: 2,
      isDismissed: false,
      name: EDemoStories.OnTheGoAlerts,
      isReplayable: true
    },
    {
      currentStep: 0,
      steps: 1,
      isDismissed: false,
      name: EDemoStories.InterstitialGuide,
      isReplayable: false
    },
    {
      currentStep: 0,
      steps: 4,
      isDismissed: false,
      name: EDemoStories.MobileWeb,
      isReplayable: true
    },
    {
      currentStep: 0,
      steps: 1,
      isDismissed: false,
      name: EDemoStories.MobileWebSplashScreen,
      isReplayable: false
    },
    {
      currentStep: 0,
      steps: 1,
      isDismissed: false,
      name: EDemoStories.MobileWebUnguidedMap,
      isReplayable: false
    },
    {
      currentStep: 0,
      steps: 6,
      isDismissed: false,
      name: EDemoStories.FieldServiceDispatch,
      isReplayable: true
    }
  ]
};
