/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { style, animate, AnimationBuilder, AnimationMetadata } from '@angular/animations';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { isPlatformServer } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  Provider,
  forwardRef,
  ElementRef,
  PLATFORM_ID,
  Inject,
  AfterViewInit
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ITriggerable } from '@fleetmatics/ui.navbar';
import { isElementNull, isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import { ISearchLightInput, FmSearchLightInputComponent } from '../search-light-input';

export const SEARCH_LIGHT_FAB_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-forward-ref
  useExisting: forwardRef(() => FmSearchLightFabInputComponent),
  multi: true
};

@Component({
  selector: 'fm-search-light-fab-input',
  templateUrl: './search-light-fab-input.component.html',
  styleUrls: ['./search-light-fab-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SEARCH_LIGHT_FAB_INPUT_VALUE_ACCESSOR],
  exportAs: 'fmSearchLightFabInput'
})
export class FmSearchLightFabInputComponent implements ISearchLightInput, AfterViewInit {
  @Input()
  public containerWidth: number;
  @Input()
  public set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    this._changeDetectorRef.markForCheck();
  }
  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input()
  public isSearchValid = false;
  @Input()
  public placeholder: string;
  @Input()
  public tooltipMessage: any;
  @Input()
  public triggerable: ITriggerable;

  @Output()
  public closed = new EventEmitter<boolean>();
  @Output()
  public opened = new EventEmitter<void>();
  @Output()
  public search = new EventEmitter<string>();
  @Output()
  public searchTextChange = new EventEmitter<string>();

  @ViewChild(CdkOverlayOrigin, { static: false })
  public trigger: CdkOverlayOrigin;

  public isActive = false;
  public searchText = '';
  public onChange: (value: string) => void;
  public onTouch: () => void;

  @ViewChild(FmSearchLightInputComponent, { static: false })
  private readonly _input: FmSearchLightInputComponent;
  @ViewChild('inputContainer', { static: false })
  private readonly _inputContainerRef: ElementRef;
  @ViewChild('inputIcon', { static: false })
  private readonly _inputIconRef: ElementRef;

  private _inputWidth = 0;
  private _isLoading = false;
  private readonly _subscriptions = new Subscription();

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _animationBuilder: AnimationBuilder,
    @Inject(PLATFORM_ID) private readonly _platformId: Object
  ) {}

  public ngAfterViewInit(): void {
    if (isElementNull(this._input)) {
      return;
    }

    this._subscriptions
      .add(
        this._input.search.subscribe((searchText: string) => {
          this.search.emit(searchText);
        })
      )
      .add(
        this._input.searchTextChange.subscribe((searchText: string) => {
          this.isSearchValid = this._input.isSearchValid;
          this.searchTextChange.emit(searchText);
        })
      );
  }

  public writeValue(value: string): void {
    if (!isElementNull(value)) {
      this.searchText = value;
      this._changeDetectorRef.markForCheck();
    }
  }

  public registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouch: () => void): void {
    this.onTouch = onTouch;
  }

  public onOpen(): void {
    if (!this.isActive) {
      this._inputWidth = this._getInputWidth();
      this._playAnimation([style({ minWidth: 0 }), animate('192ms ease-out', style({ minWidth: `${this._inputWidth}px` }))], () => {
        this.isActive = true;
        this._changeDetectorRef.markForCheck();
        this._focus();
      });
    } else if (isStringNullOrEmpty(this.searchText)) {
      this._close();
    } else {
      this.opened.emit();
      this._focus();
    }
  }

  public onClose(clearValue = false): void {
    this._close(clearValue);
  }

  private _focus(): void {
    if (!isElementNull(this._input)) {
      this._input.focus();
    }
  }

  private _close(clearValue = false): void {
    this._playAnimation([style({ minWidth: this._inputWidth }), animate('192ms ease-out', style({ minWidth: 0 }))]);

    this.isActive = false;
    this.isLoading = false;
    this.closed.emit(clearValue);
  }

  private _getInputWidth(): number {
    if (isPlatformServer(this._platformId)) {
      return 0;
    }
    return this.containerWidth - this._inputIconRef.nativeElement.getBoundingClientRect().width;
  }

  private _playAnimation(animation: AnimationMetadata | AnimationMetadata[], onDone?: () => void): void {
    const animationPlayer = this._animationBuilder.build(animation).create(this._inputContainerRef.nativeElement);
    if (!isElementNull(onDone)) {
      animationPlayer.onDone(onDone);
    }
    animationPlayer.play();
  }
}
