/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

import { IUserSettings } from '../../../core/models';
import { IReplayFormattingService } from './replay-formatting.service.interface';
import { EReplaySegmentStatus, DateRange } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ReplayFormattingService implements IReplayFormattingService {
  private readonly _replaySegmentStatusReverseMap: { [id: string]: string } = {};

  segmentStartTime(startTime: Date | Moment, userSettings: IUserSettings): string {
    const start = this._applyTimezoneAndLocale(startTime, userSettings);
    return start.format('LT');
  }

  journeyRange(journeyRange: DateRange, userSettings: IUserSettings): string {
    const localizedStart = this._applyTimezoneAndLocale(journeyRange.start, userSettings);
    const localizedEnd = this._applyTimezoneAndLocale(journeyRange.end, userSettings);

    if (journeyRange.isMultiDay()) {
      const startString = localizedStart.format('llll').replace(localizedStart.format('LT'), '');
      const endString = localizedEnd.format('llll').replace(localizedEnd.format('LT'), '');
      return `${startString} — ${endString}`;
    }

    const longStartString = localizedStart.format('LLLL').replace(localizedStart.format('LT'), '');
    return longStartString;
  }

  balloonStartTime(startTime: Date, userSettings: IUserSettings): string {
    const start = this._applyTimezoneAndLocale(startTime, userSettings);
    return start.format(`L LT (z)`);
  }

  datepickerHeaderDate(startTime: Moment, userSettings: IUserSettings): string {
    const start = this._applyTimezoneAndLocale(startTime, userSettings);
    return start.format('llll').replace(start.format('LT'), '');
  }

  durationString(durationInSeconds: number): { string: string; isMinsOnly: boolean } {
    const durationSeconds = durationInSeconds ?? 0;
    const hours = Math.floor(durationSeconds / 3600);
    const residualSeconds = durationSeconds % 3600;
    const mins = Math.round(residualSeconds / 60);

    if (hours === 0) {
      return {
        string: mins < 10 ? `0${mins}` : `${mins}`,
        isMinsOnly: true
      };
    }

    const hoursString = hours < 10 ? `0${hours} h` : `${hours} h`;
    const minsString = mins === 0 ? `00 m` : mins < 10 ? `0${mins} m` : `${mins} m`;
    const fullString = `${hoursString} ${minsString}`;
    return {
      string: fullString,
      isMinsOnly: false
    };
  }

  getReplaySegmentStatusString(status: EReplaySegmentStatus): string {
    return this._replaySegmentStatusReverseMap[status];
  }

  journeyStartDate(startTime: Date | Moment, userSettings: IUserSettings): string {
    const start = this._applyTimezoneAndLocale(startTime, userSettings);
    return start.format('LL');
  }

  multidayCardDate(date: Moment, userSettings: IUserSettings): string {
    if (moment.locale() !== userSettings.Language) {
      moment.locale(userSettings.Language);
    }

    return date.format('LLLL').replace(date.format('LT'), '');
  }

  multidayCardStartEndTime(dateRange: DateRange, userSettings: IUserSettings): string {
    const formattedStart = this._applyTimezoneAndLocale(dateRange.start, userSettings).format('LT');
    const formattedEnd = this._applyTimezoneAndLocale(dateRange.end, userSettings).format('LT');
    return `${formattedStart} - ${formattedEnd}`;
  }

  polylineBalloonDate(date: Moment, userSettings: IUserSettings): string {
    const localizedDate = this._applyTimezoneAndLocale(date, userSettings);
    return localizedDate.format('LLLL').replace(localizedDate.format('LT'), '');
  }

  segmentStartEndTimes(start: Date, end: Date, userSettings: IUserSettings): string {
    const localizedStart = this._applyTimezoneAndLocale(start, userSettings);
    const localizedEnd = this._applyTimezoneAndLocale(end, userSettings);
    return `${localizedStart.format('LT')} - ${localizedEnd.format('LT')}`;
  }

  journeySummaryDate(start: Date | Moment, userSettings: IUserSettings): string {
    const localizedStart = this._applyTimezoneAndLocale(start, userSettings);
    const longStartString = localizedStart.format('LLLL').replace(localizedStart.format('LT'), '');
    return longStartString;
  }

  noActivityTime(time: Date | Moment, userSettings: IUserSettings): string {
    return this._applyTimezoneAndLocale(time, userSettings).format('hA');
  }

  constructor() {
    this._createEnumReverseMap();
  }

  private _applyTimezoneAndLocale(startTime: Date | Moment, userSettings: IUserSettings): Moment {
    const momentObject = moment.tz(startTime, userSettings.TimeZoneAndroidId).locale(userSettings.Language);
    return momentObject;
  }

  private _createEnumReverseMap() {
    const values = Object.values(EReplaySegmentStatus);
    const keys = Object.keys(EReplaySegmentStatus);
    for (let i = 0; i < keys.length; i++) {
      this._replaySegmentStatusReverseMap[values[i]] = keys[i];
    }
  }
}
