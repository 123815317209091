/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { isElementNull } from '@fleetmatics/ui.utilities';
import { TooltipDirective } from 'shared';
import { IVehicleTachoStatus, EDriverWorkingState, EDrivingLimitWarning, IDriverWorkingTime, IDriverCard } from '../../models';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'lib-tacho-message-section',
  templateUrl: './tacho-message-section.component.html',
  styleUrls: ['./tacho-message-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TachoMessageSectionComponent implements OnChanges {
  @Input()
  public tacho: IVehicleTachoStatus;

  @ViewChild('errorMessageTooltip', { read: TooltipDirective })
  public errorMessageTooltip: TooltipDirective;

  @ViewChild('clickToCopyText')
  public clickToCopyText: ElementRef;

  @ViewChild('copiedCardNumberText')
  public copiedCardNumberText: ElementRef;

  public EDrivingLimitWarning = EDrivingLimitWarning;
  public EDriverWorkingState = EDriverWorkingState;
  public isDataUnavailable: boolean;
  public isCardNotInserted: boolean;
  public isCardNotRecognised: boolean;
  public isNoMatchingDrivingCards: boolean;
  public isBreakDue: boolean;
  public isBreakOverdue: boolean;

  public constructor(private readonly clipboard: Clipboard) {}
  public copyToClipboard(event: MouseEvent): void {
    if (this._hasVehicleData()) {
      const pending = this.clipboard.beginCopy(this.tacho.DriverCardInVehicle.DriverCardNumber);

      let remainingAttempts = 3;
      const attempt = () => {
        const result = pending.copy();
        if (!result && --remainingAttempts) {
          setTimeout(attempt);
        } else {
          // Remember to destroy when you're done!
          pending.destroy();
        }
      };
      attempt();
      this._toggleTooltipMessage(event);
    }
  }

  public isDriving(): boolean {
    if (!this._hasTachoData()) {
      return false;
    }

    return this.tacho.DriverWorkingTime.TachoStatus === EDriverWorkingState.Drive;
  }

  public isWorking(): boolean {
    if (!this._hasTachoData()) {
      return false;
    }

    return this.tacho.DriverWorkingTime.TachoStatus === EDriverWorkingState.Work;
  }

  public isAvailable(): boolean {
    if (!this._hasTachoData()) {
      return false;
    }

    return this.tacho.DriverWorkingTime.TachoStatus === EDriverWorkingState.Available;
  }

  public isResting(): boolean {
    if (!this._hasTachoData()) {
      return false;
    }

    return this.tacho.DriverWorkingTime.TachoStatus === EDriverWorkingState.Rest;
  }

  ngOnChanges(): void {
    this.isDataUnavailable = false;
    this.isCardNotInserted = false;
    this.isCardNotRecognised = false;
    this.isNoMatchingDrivingCards = false;
    this.isBreakDue = false;
    this.isBreakOverdue = false;

    let actualDriverId: number;
    let assignedDriverId: number;
    let driverCardNumberInVehicle: string;
    let drivingWarning: EDrivingLimitWarning;
    let vehicleData: IDriverCard;
    let tachoData: IDriverWorkingTime;

    if (this._hasAssignedDriver()) {
      assignedDriverId = this.tacho.AssignedDriverId;
    }
    if (this._hasActualDriver()) {
      actualDriverId = this.tacho.ActualDriverId;
    }
    if (this._hasVehicleData()) {
      vehicleData = this.tacho.DriverCardInVehicle;
      driverCardNumberInVehicle = vehicleData.DriverCardNumber || '0';
    }
    if (this._hasTachoData()) {
      tachoData = this.tacho.DriverWorkingTime;
      drivingWarning = tachoData.DrivingWarning;
    }

    if (isElementNull(driverCardNumberInVehicle)) {
      this.isDataUnavailable = true;
    } else if (driverCardNumberInVehicle === '0') {
      this.isCardNotInserted = true;
    } else if (!actualDriverId) {
      this.isCardNotRecognised = true;
    } else if (actualDriverId !== assignedDriverId) {
      this.isNoMatchingDrivingCards = true;
    } else if (!this._hasTachoData()) {
      this.isDataUnavailable = true;
    } else if (drivingWarning === EDrivingLimitWarning.In15Min) {
      this.isBreakDue = true;
    } else if (drivingWarning === EDrivingLimitWarning.Reached) {
      this.isBreakOverdue = true;
    }
  }

  public showStartTime(): boolean {
    if (this._hasTachoData() && this._hasVehicleData() && !isElementNull(this.tacho.DriverWorkingTime.StartTimeValue)) {
      return this.tacho.DriverWorkingTime.DriverCardNumber === this.tacho.DriverCardInVehicle.DriverCardNumber;
    }

    return false;
  }

  private _hasActualDriver(): boolean {
    return !isElementNull(this.tacho) && !isElementNull(this.tacho.ActualDriverId);
  }

  private _hasAssignedDriver(): boolean {
    return !isElementNull(this.tacho) && !isElementNull(this.tacho.AssignedDriverId);
  }

  private _hasTachoData(): boolean {
    return !isElementNull(this.tacho) && !isElementNull(this.tacho.DriverWorkingTime);
  }

  private _hasVehicleData(): boolean {
    return !isElementNull(this.tacho) && !isElementNull(this.tacho.DriverCardInVehicle);
  }

  private _toggleTooltipMessage(event: MouseEvent) {
    this.errorMessageTooltip.fmTooltip = this.copiedCardNumberText.nativeElement.value;
    this.errorMessageTooltip.rerender(event);

    setTimeout(() => {
      this.errorMessageTooltip.fmTooltip = this.clickToCopyText.nativeElement.value;
      this.errorMessageTooltip.rerender(event);
    }, 1000);
  }
}
