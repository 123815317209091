/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  EVehicleListPanelActions,
  VehicleListPanelActions,
  ELayoutPreferencesActions,
  GetLayoutPreferencesSuccess,
  ECustomerMetadataActions,
  SaveMapHierarchyScopingWarning
} from '../actions';
import { initialVehicleListPanelState, IVehicleListPanelState } from '../state';

export function vehicleListPanelReducer(
  state = initialVehicleListPanelState,
  action: VehicleListPanelActions | GetLayoutPreferencesSuccess | SaveMapHierarchyScopingWarning
): IVehicleListPanelState {
  switch (action.type) {
    case EVehicleListPanelActions.UpdateVehicleSelectionMode: {
      return {
        ...state,
        selectionMode: action.payload
      };
    }
    case ELayoutPreferencesActions.GetLayoutPreferencesSuccess: {
      return {
        ...state,
        selectionMode: action.payload.vehicleListPanel.selectionMode
      };
    }
    case EVehicleListPanelActions.OpenScopingModal: {
      return {
        ...state,
        scopingModal: {
          ...state.scopingModal,
          isOpen: true
        }
      };
    }
    case EVehicleListPanelActions.CloseScopingModal: {
      return {
        ...state,
        scopingModal: {
          isOpen: false,
          warning: null
        }
      };
    }
    case ECustomerMetadataActions.SaveMapHierarchyScopingWarning: {
      return {
        ...state,
        scopingModal: {
          ...state.scopingModal,
          warning: action.payload
        }
      };
    }

    default:
      return state;
  }
}
