/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { EMapTypeId, ILatLng, IReplayLayout, Position, IIndexableObject } from '../../models';

export enum EMapAnimationState {
  default = 'default',
  fullscreen = 'fullscreen',
  overlayHidden = 'overlayHidden',
  replayPanelClosed = 'replayPanelClosed',
  replayPanelOpen = 'replayPanelOpen'
}

export interface ILayoutState {
  isFullscreen: boolean;
  isMapFitted: boolean;
  triggerFitToMap: Object;
  locationToZoom: ILatLng;
  mapType: EMapTypeId;
  locationToMarker: ILatLng;
  locationToMarkerId: number;
  replayLayout: IReplayLayout;
  animationState: EMapAnimationState;
  isAppAnimationsEnabled: boolean;
  overlayMargins: IIndexableObject<Position>;
}

export const initialLayoutState: ILayoutState = {
  isFullscreen: false,
  isMapFitted: true,
  triggerFitToMap: undefined,
  locationToZoom: null,
  locationToMarker: null,
  locationToMarkerId: null,
  mapType: EMapTypeId.ROADMAP,
  replayLayout: {
    isReplayOpen: false,
    isReplayDatePickerOpen: false,
    viewReplayParams: null
  },
  animationState: EMapAnimationState.default,
  isAppAnimationsEnabled: true,
  overlayMargins: {}
};
