/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TooltipModule } from 'shared';

import { TachoMessageSectionComponent } from './tacho-message-section.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [TachoMessageSectionComponent],
  exports: [TachoMessageSectionComponent],
  imports: [TooltipModule, MatIconModule, CommonModule, ClipboardModule]
})
export class TachoMessageSectionModule {}
