/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IUserPermissions, IUserFeatures, IAccountSettings, IUserSettings, IReplayUserSettings } from '../../models';
import { INotificationPreferences } from '../../components/notifications';

export interface IUserMapSettingsState {
  accountId: number;
  accountSettings: IAccountSettings;
  features: IUserFeatures;
  notificationPreferences: INotificationPreferences[];
  permissions: IUserPermissions;
  universalAccountId: string;
  userRowGuid: string;
  userSettings: IUserSettings;
  webSocketAccountId: string;
  webSocketUserId: string;
  featureToggles: string[];
  replaySettings?: IReplayUserSettings;
}

export const initialUserDataState: IUserMapSettingsState = {
  accountId: null,
  accountSettings: null,
  features: null,
  notificationPreferences: [],
  permissions: null,
  universalAccountId: null,
  userRowGuid: null,
  userSettings: null,
  webSocketAccountId: null,
  webSocketUserId: null,
  featureToggles: [],
  replaySettings: null
};
