/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  ComponentRef,
  Inject,
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
  ComponentFactory,
  TemplateRef,
  Injectable
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { FmDocumentRefService } from '@fleetmatics/ui.utilities';

import { ISnackbarService } from './snackbar.service.interface';
import { SnackbarComponent } from '../snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService implements ISnackbarService {
  public closed: Observable<void>;

  private readonly _closed = new Subject<void>();
  private _componentRef: ComponentRef<SnackbarComponent>;
  private _closeTimeoutId: any;

  constructor(
    @Inject(ComponentFactoryResolver) private readonly _factoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _applicationRef: ApplicationRef,
    private readonly _documentRefService: FmDocumentRefService
  ) {
    this.closed = this._closed.asObservable();
  }

  close(emit = true): void {
    if (!this._componentRef) {
      return;
    }

    const componentElement = this._componentRef.location.nativeElement;
    componentElement.parentNode.removeChild(componentElement);
    this._applicationRef.detachView(this._componentRef.hostView);
    this._componentRef.destroy();
    this._componentRef = null;
    if (emit) {
      this._closed.next();
    }
  }

  open(message: TemplateRef<any> | string, timeout?: number): void {
    if (this._componentRef) {
      this.close(false);
      clearTimeout(this._closeTimeoutId);
    }

    const factory: ComponentFactory<SnackbarComponent> = this._factoryResolver.resolveComponentFactory(SnackbarComponent);
    const injector = Injector.create({ providers: [], parent: this._injector });
    const component: ComponentRef<SnackbarComponent> = factory.create(injector);
    this._componentRef = component;
    if (typeof message === 'string') {
      component.instance.message = message;
    } else {
      component.instance.messageTemplate = message;
    }
    component.changeDetectorRef.detectChanges();
    this._applicationRef.attachView(component.hostView);
    component.instance.closed.pipe(take(1)).subscribe(() => this.close());
    this._documentRefService.nativeDocument.body.appendChild(component.location.nativeElement);

    if (timeout > 0) {
      this._closeTimeoutId = setTimeout(() => {
        this.close();
      }, timeout);
    }
  }
}
