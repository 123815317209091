/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { LatLng } from './lat-lng.class';
import { EPlotType } from './plot-type.enum';
import { MarkerInfo } from './marker-info.class';
import { IMarkerOptions } from './marker-options.interface';

export class GarminMarkerInfo extends MarkerInfo {
  constructor(
    public id: number,
    public latLng: LatLng,
    public title: string,
    public type: EPlotType,
    public vehicleId: number,
    public options: IMarkerOptions,
    public shape?: google.maps.Polygon | google.maps.Circle,
  ) {
    super(id, latLng, title, type, options, shape);
  }
}
