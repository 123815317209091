/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isElementNull } from '@fleetmatics/ui.utilities';

export class ArrayUtilities {
  static findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
      if (predicate(array[l], l, array)) {
        return l;
      }
    }
    return -1;
  }

  static findLast<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): T {
    const index = this.findLastIndex(array, predicate);
    if (index === -1) {
      return null;
    }
    return array[index];
  }

  static compareArrays<T>(array1: T[], array2: T[]): boolean {
    if (isElementNull(array1) || isElementNull(array2) || array1.length !== array2.length) {
      return false;
    }

    let i = 0;
    while (i < array1.length) {
      // if two elements are different than the arrays are different
      if (array1[i] !== array2[i]) {
        return false;
      }
      i++;
    }

    // all elements are the same
    return true;
  }

  static compareSets<T>(first: Set<T>, second: Set<T>): boolean {
    if (isElementNull(first) || isElementNull(second) || first.size !== second.size) {
      return false;
    }
    return [...first.values()].every(i => second.has(i));
  }
}
