/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { IBalloonInfoState } from '../state';
import { ILabel, IBalloon, IVehicleWorkOrderBalloon } from '../../models';
import { getIsBalloonLinksEnabledFromMapOptions } from './map-options.selectors';
import {
  getIsCustomBalloonsFeatureEnabled,
  getIsBalloonEcmFeatureEnabled,
  getIsBalloonEcmPermissionAllowed
} from './user-map-settings.selectors';

export const getBalloonInfoState = createFeatureSelector<IBalloonInfoState>('balloonInfo');

export const getLabels = createSelector(getBalloonInfoState, (state: IBalloonInfoState): ILabel[] => state.labels);

export const getLabel = createSelector(getBalloonInfoState, (state: IBalloonInfoState): ILabel => state.currentLabel);

export const getBalloon = createSelector(getBalloonInfoState, (state: IBalloonInfoState): IBalloon => state.balloon);

export const getVehicleBalloonShowLinks = createSelector(
  getBalloon,
  (balloon: IBalloon): boolean => !isElementNull(balloon) && (<IVehicleWorkOrderBalloon>balloon).showLinks
);

// Should show vehicle balloon links if;
//  - User has fleetcare feature enabled AND balloon is configured to show links
//  - User has fleetcare feature disabled AND user has feature toggle enabled AND state is enabled
export const getShouldShowVehicleBalloonLinks = createSelector(
  getIsBalloonLinksEnabledFromMapOptions,
  getIsCustomBalloonsFeatureEnabled,
  getVehicleBalloonShowLinks,
  (isBalloonLinksEnabledFromMapOptions, isCustomBalloonFeatureEnabled, vehicleBalloonShowLinks: boolean): boolean =>
    (isCustomBalloonFeatureEnabled && vehicleBalloonShowLinks) || (!isCustomBalloonFeatureEnabled && isBalloonLinksEnabledFromMapOptions)
);

export const getShouldShowVehicleBalloonEcm = createSelector(
  getIsBalloonEcmFeatureEnabled,
  getIsBalloonEcmPermissionAllowed,
  (isBalloonEcmFeatureEnabled, isBalloonEcmPermissionAllowed) => isBalloonEcmFeatureEnabled && isBalloonEcmPermissionAllowed
);
