/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, Input, ContentChild, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { ContextMenuComponent } from '../context-menu.component';
import { ContextMenuPanelComponent } from '../context-menu-panel/context-menu-panel.component';

@Component({
  selector: 'fm-context-menu-item',
  templateUrl: './context-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuItemComponent {
  @Input()
  public hasSeparator = false;
  @Input()
  public isDisabled = false;

  @ContentChild(ContextMenuPanelComponent)
  public set contextMenuPanel(panel: ContextMenuPanelComponent) {
    this._contextMenuPanel = panel;
    this._changeDetectorRef.markForCheck();
  }
  public get contextMenuPanel(): ContextMenuPanelComponent {
    return this._contextMenuPanel;
  }

  public hasSubMenu: boolean;
  public isSubMenuItem: boolean;
  public subMenuHovered: boolean;
  public subMenuPosition: {
    left?: string;
    right?: string;
    top: string;
  };

  @ViewChild('childPanel', { read: ElementRef })
  private readonly _childPanel: ElementRef;

  @ViewChild('itemWrapper', { read: ElementRef })
  private readonly _itemWrapper: ElementRef;

  private _isSubMenuPositionCalculated: boolean;
  private _contextMenuPanel: ContextMenuPanelComponent;

  constructor(private readonly _contextMenu: ContextMenuComponent, private readonly _changeDetectorRef: ChangeDetectorRef) {
    this.subMenuHovered = false;
  }

  public mouseEnterSub() {
    this.subMenuHovered = true;
  }

  public updateChildrenPosition() {
    if (!isElementNull(this._contextMenuPanel) && !this._isSubMenuPositionCalculated) {
      const { offsetTop: menuItemTop } = this._itemWrapper.nativeElement;
      const { offsetHeight: subMenuHeight, offsetWidth: subMenuWidth } = this._childPanel.nativeElement;
      const { contextMenuWidth, menuTop, menuLeft, totalHeight, totalWidth } = this._contextMenu;
      this.subMenuPosition = {
        top: `${menuItemTop}px`
      };

      if (menuTop + menuItemTop + subMenuHeight > totalHeight) {
        this.subMenuPosition.top = `${totalHeight - menuTop - subMenuHeight}px`;
      }

      if (menuLeft + contextMenuWidth + subMenuWidth > totalWidth) {
        this.subMenuPosition.right = `${contextMenuWidth}px`;
      } else {
        this.subMenuPosition.left = `${contextMenuWidth}px`;
      }

      this._isSubMenuPositionCalculated = true;
    }
  }

  public mouseLeaveSub() {
    this.subMenuHovered = false;
  }
}
