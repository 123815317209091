<div class="modal">
  <div class="modal__wrapper"
    [ngClass]="{'modal__wrapper-disable-responsiveness': config.disableResponsiveness,
      'modal__wrapper-sm': config.size === 'small',
      'modal__wrapper-lg': config.size === 'large',
      'modal__wrapper-xl': config.size === 'extraLarge',
      'modal__wrapper-transparent': config.transparent}">
    <div class="modal__close">
      <mat-icon *ngIf="config.showCloseIcon"
        (click)="onCloseClick()"
        class="modal__close-icon"
        [ngClass]="{
          'mat-icon-size-xs': config.closeIconSize === 'extraSmall',
          'mat-icon-size-md': config.closeIconSize === 'medium'
        }"
        [svgIcon]="config.closeIcon"></mat-icon>
    </div>
    <ng-container #content></ng-container>
  </div>
  <div class="modal__overlay"></div>
</div>