/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IVideoEventHttpService } from './video-event-http.service.interface';
import { EVideoClassifications, EVideoEventGenerationType, IHarshDrivingEvent, IVideoEvent, IVideoEventDetails } from '../../models';
import { IAppConfig, APP_CONFIG_TOKEN } from '../../../config';
import { IVideoEventListQueryRequest } from '../../models/video-event-list-query-request.interface';

@Injectable()
export class VideoEventHttpService implements IVideoEventHttpService {
  constructor(@Inject(APP_CONFIG_TOKEN) private readonly _config: IAppConfig, private readonly _httpClient: HttpClient) {}

  getEvent(eventId: number): Observable<IHarshDrivingEvent> {
    return this._httpClient.get<IHarshDrivingEvent>(`${this._config.endpoints.videoEventApiEndpoint}/v1/events/${eventId}`);
  }

  getVideoEvents(start: Date, end: Date, totalDays: number, vehicleId: number): Observable<IVideoEvent[]> {
    const body: IVideoEventListQueryRequest = {
      eventStartTimeUtc: start,
      eventEndTimeUtc: end,
      classifications: [
        EVideoClassifications.Collision,
        EVideoClassifications.Dangerous,
        EVideoClassifications.HarshDriving,
        EVideoClassifications.LowRisk,
        EVideoClassifications.Requested
      ],
      eventGenerationTypes: [EVideoEventGenerationType.VTU, EVideoEventGenerationType.VoD],
      vehicleIds: [vehicleId],
      limit: 100 * totalDays
    };
    return this._httpClient.post<{ data: IVideoEvent[] }>(`${this._config.endpoints.videoEventApiEndpoint}/v1/events/query`, body).pipe(
      map(response =>
        response.data.map(responseDetail => ({
          ...responseDetail,
          timeUtc: new Date(responseDetail.timeUtc)
        }))
      )
    );
  }

  getVideoDetails(videoKey: string): Observable<IVideoEventDetails[]> {
    return this._httpClient.get<IVideoEventDetails[]>(`${this._config.endpoints.videoEventApiEndpoint}/v1/events/${videoKey}/videos`);
  }
}
