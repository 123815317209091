/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IUserSettings } from '../models';

export const USER_SETTINGS_TOKEN = new InjectionToken<BehaviorSubject<IUserSettings>>('USER_SETTINGS_TOKEN');
