/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, isPlatformBrowser } from '@angular/common';
import { Observable, combineLatest } from 'rxjs';
import { tap, map, withLatestFrom, filter, take, switchMap, delay, takeUntil } from 'rxjs/operators';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { isElementNull, isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import { ModalWindowService } from '../../services';
import {
  RedirectToSchedulerPage,
  ESchedulerActions,
  UpdateScheduledJob,
  UpdateVehicleBalloonScheduler,
  SelectAndFocusVehicle,
  SelectRoutedVehicle,
  EmitTimeTick,
  EMapPlotsActions
} from '../actions';
import { IScheduledJob, EPlotType, IVehicleWorkOrderBalloon, EScheduledJobStatus, IScheduledJobUpdate } from '../../models';
import { getBalloon, getHierarchyItemById, getSchedulerAllowed, getUserSettings } from '../selectors';
import { IAppState } from '../state';
import { ROUTE_PARAMS } from '../../constants';

export const schedulerAppointmentTransitionStatusNotified = 'notified';
export const schedulerAppointmentStatusSent = 'sent';
export const schedulerAppointmentStatusReceived = 'received';
export const schedulerAppointmentStatusRead = 'read';
export const schedulerAppointmentStatusOnMyWay = 'onmyway';
export const schedulerAppointmentStatusInProgress = 'inprogress';
export const schedulerAppointmentStatusFinished = 'finished';

@Injectable()
export class SchedulerEffects {
  public updateScheduledJob$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateScheduledJob>(ESchedulerActions.UpdateScheduledJob),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getBalloon))),
      filter(([, balloon]) => !isElementNull(balloon) && (balloon.type === EPlotType.vehicle || balloon.type === EPlotType.poweredAsset)),
      withLatestFrom(this._store.pipe(select(getUserSettings))),
      map(([[scheduledJobUpdate, balloon], userSettings]) => {
        const vehicleBalloon = balloon as IVehicleWorkOrderBalloon;
        if (
          !isElementNull(vehicleBalloon.scheduler) &&
          !isElementNull(vehicleBalloon.scheduler.scheduledJobs) &&
          vehicleBalloon.scheduler.scheduledJobs.length > 0 &&
          scheduledJobUpdate.technicianId === vehicleBalloon.driverId
        ) {
          let scheduledJobs = vehicleBalloon.scheduler.scheduledJobs;
          const scheduledJob = scheduledJobs.find(job => job.appointmentId === scheduledJobUpdate.appointmentId);

          if (!isElementNull(scheduledJob)) {
            if (!isStringNullOrEmpty(scheduledJobUpdate.newNotificationStatus)) {
              const updatedStatus = this._getUpdatedScheduledJobStatus(scheduledJobUpdate);
              if (updatedStatus === EScheduledJobStatus.None) {
                scheduledJobs = scheduledJobs.filter(job => job !== scheduledJob);
              } else if (scheduledJob.status !== updatedStatus) {
                scheduledJob.status = updatedStatus;
                if (
                  !isElementNull(scheduledJob.estimation) &&
                  (updatedStatus === EScheduledJobStatus.Started || updatedStatus === EScheduledJobStatus.Finished)
                ) {
                  scheduledJob.estimation = null;
                }
              }
            }

            if (!isStringNullOrEmpty(scheduledJobUpdate.eta) && !isStringNullOrEmpty(scheduledJobUpdate.requestTimestamp)) {
              const localEstimatedTime = new Date(scheduledJobUpdate.eta);
              const localEstimatedTimeRemoveDate = new Date(scheduledJobUpdate.requestTimestamp);
              const estimatedTimeRemoveDate = new Date(localEstimatedTimeRemoveDate.getTime() + scheduledJobUpdate.ttl * 60000);

              scheduledJob.estimation = {
                estimatedTime: this._getFormattedTime(localEstimatedTime, userSettings.Language).toUpperCase(),
                estimatedTimeRemoveDate: estimatedTimeRemoveDate > new Date() ? estimatedTimeRemoveDate : null,
                isOnTime: !isStringNullOrEmpty(scheduledJobUpdate.etaStatus) && scheduledJobUpdate.etaStatus === 'onTime'
              };
            }

            const index = scheduledJobs.indexOf(scheduledJob);
            scheduledJobs[index] = { ...scheduledJob };

            return {
              ...vehicleBalloon,
              scheduler: {
                ...vehicleBalloon.scheduler,
                scheduledJobs: [...scheduledJobs]
              }
            };
          }
        }
        return null;
      }),
      filter(balloon => !isElementNull(balloon)),
      map((balloon: IVehicleWorkOrderBalloon) => new UpdateVehicleBalloonScheduler(balloon))
    )
  );

  vehicleRouteQueryParams$ = createEffect(() =>
    this._activatedRoute.queryParamMap.pipe(
      filter(params => isPlatformBrowser(this._platformId) && params.has(ROUTE_PARAMS.SCHEDULER.VEHICLE_ID)),
      take(1),
      map(params => {
        const urlTree = this._router.parseUrl(this._router.url);
        urlTree.queryParams = {};
        this._location.replaceState(urlTree.toString());
        return new SelectRoutedVehicle(`v${params.get(ROUTE_PARAMS.SCHEDULER.VEHICLE_ID)}`);
      })
    )
  );

  selectRoutedVehicle$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectRoutedVehicle>(ESchedulerActions.SelectRoutedVehicle),
      map(action => action.payload),
      switchMap(vehicleToSelect => {
        return this._store.pipe(select(getHierarchyItemById, vehicleToSelect)).pipe(
          filter(item => !isElementNull(item)), // wait for hierarchy to load
          take(1),
          delay(0),
          map(() => new SelectAndFocusVehicle(vehicleToSelect))
        );
      })
    )
  );

  public updateScheduledJobEstimation$: Observable<any> = createEffect(() =>
    combineLatest([this._store.pipe(select(getSchedulerAllowed)), this._store.pipe(select(getBalloon))]).pipe(
      filter(([isSchedulerAllowed, balloon]) => {
        if (
          isSchedulerAllowed &&
          !isElementNull(balloon) &&
          (balloon.type === EPlotType.vehicle || balloon.type === EPlotType.poweredAsset)
        ) {
          const vehicleBalloon = balloon as IVehicleWorkOrderBalloon;

          return (
            !isElementNull(vehicleBalloon.scheduler) &&
            vehicleBalloon.scheduler.scheduledJobs.length > 0 &&
            vehicleBalloon.scheduler.scheduledJobs.some(scheduledJob => !isElementNull(scheduledJob.estimation))
          );
        }
        return false;
      }),
      switchMap(() =>
        this._actions$.pipe(
          ofType<EmitTimeTick>(EMapPlotsActions.EmitTimeTick),
          map(action => action.payload),
          takeUntil(
            this._store.pipe(
              select(getBalloon),
              filter(balloon => {
                if (!isElementNull(balloon) && (balloon.type === EPlotType.vehicle || balloon.type === EPlotType.poweredAsset)) {
                  const vehicleBalloon = balloon as IVehicleWorkOrderBalloon;

                  return (
                    isElementNull(vehicleBalloon.scheduler) ||
                    vehicleBalloon.scheduler.scheduledJobs.length === 0 ||
                    !vehicleBalloon.scheduler.scheduledJobs.some(scheduledJob => !isElementNull(scheduledJob.estimation))
                  );
                }
                return true;
              })
            )
          ),
          withLatestFrom(this._store.pipe(select(getBalloon))),
          map(([currentTime, vehicleBalloon]) => {
            const scheduler = (<IVehicleWorkOrderBalloon>vehicleBalloon).scheduler;
            const scheduledJobs = scheduler.scheduledJobs;
            let isScheduledJobUpdated = false;

            scheduledJobs.forEach(scheduledJob => {
              if (!isElementNull(scheduledJob.estimation) && scheduledJob.estimation.estimatedTimeRemoveDate < currentTime) {
                isScheduledJobUpdated = true;
                scheduledJob.estimation = null;
                const index = scheduledJobs.indexOf(scheduledJob);
                scheduledJobs[index] = { ...scheduledJob };
              }
            });

            if (isScheduledJobUpdated) {
              return <IVehicleWorkOrderBalloon>{
                ...vehicleBalloon,
                scheduler: {
                  ...scheduler,
                  scheduledJobs: [...scheduledJobs]
                }
              };
            }
            return null;
          }),
          filter(balloon => !isElementNull(balloon)),
          map(balloon => new UpdateVehicleBalloonScheduler(balloon))
        )
      )
    )
  );

  public redirectToSchedulerPage$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<RedirectToSchedulerPage>(ESchedulerActions.RedirectToSchedulerPage),
        map((action: RedirectToSchedulerPage) => action.scheduledJob),
        tap((scheduledJob: IScheduledJob) => {
          let schedulerUrl = '/fsd/technicians?setFeatureToggle=fsdLivemapIntegration|true';
          schedulerUrl += `&appointmentId=${scheduledJob.appointmentId}`;
          schedulerUrl += `&technicianId=${scheduledJob.technicianId}`;
          schedulerUrl += `&date=${scheduledJob.timestamp}`;
          this._modalWindowService.redirectToUrl(schedulerUrl);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly _store: Store<IAppState>,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _actions$: Actions,
    private readonly _modalWindowService: ModalWindowService,
    @Inject(PLATFORM_ID) private readonly _platformId: Object
  ) {}

  private _getUpdatedScheduledJobStatus(scheduledJobUpdate: IScheduledJobUpdate): EScheduledJobStatus {
    let status = EScheduledJobStatus.None;
    const transitionStatus = scheduledJobUpdate.transitionStatus.toLowerCase();

    if (transitionStatus === schedulerAppointmentTransitionStatusNotified) {
      switch (scheduledJobUpdate.newNotificationStatus.toLowerCase()) {
        case schedulerAppointmentStatusSent:
          status = EScheduledJobStatus.Sent;
          break;
        case schedulerAppointmentStatusReceived:
          status = EScheduledJobStatus.Received;
          break;
        case schedulerAppointmentStatusRead:
          status = EScheduledJobStatus.Read;
          break;
      }
    } else {
      switch (transitionStatus) {
        case schedulerAppointmentStatusOnMyWay:
          status = EScheduledJobStatus.OnMyWay;
          break;
        case schedulerAppointmentStatusInProgress:
          status = EScheduledJobStatus.Started;
          break;
        case schedulerAppointmentStatusFinished:
          status = EScheduledJobStatus.Finished;
          break;
      }
    }
    return status;
  }

  private _getFormattedTime(date: Date, userLanguage: string): string {
    return date.toLocaleTimeString(userLanguage, { hour: 'numeric', minute: 'numeric' });
  }
}
