/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ILatLng, MarkerInfo, ILatLngBounds, EStartLocationSource } from '../../models';

export interface IMapsState {
  bounds: ILatLngBounds;
  changeBounds: ILatLng[];
  isClusteringEnabled: boolean;
  isStreetViewOpen: boolean;
  isTrafficEnabled: boolean;
  mapCenter: ILatLng;
  markerToHighlight: MarkerInfo;
  selectedMarker: MarkerInfo;
  showStartingLocation: EStartLocationSource;
  streetViewLocation: ILatLng;
  zoomLevel: number;
}

export const initialMapsState: IMapsState = {
  bounds: null,
  changeBounds: null,
  isClusteringEnabled: false,
  isStreetViewOpen: false,
  isTrafficEnabled: false,
  mapCenter: { lat: 0, lng: 0 },
  markerToHighlight: null,
  selectedMarker: null,
  showStartingLocation: null,
  streetViewLocation: null,
  zoomLevel: null
};
