<ng-template #splashScreenModalContent>
  <div class="header">
    <div class="verizon-connect-img"></div>
    <div class="title" i18n="@@S_DEMO_WELCOME">Welcome to Reveal Starter</div>
  </div>
  <div class="body">
    <div class="splash-screen"></div>
    <ul>
      <li i18n="@@S_DEMO_MOBILE_A">Know where your vehicles are at all times.</li>
      <li i18n="@@S_DEMO_MOBILE_B">Improve fleet efficiency and save money.</li>
      <li i18n="@@S_DEMO_MOBILE_C">Promote safer driving and reduce accidents.</li>
    </ul>
  </div>
  <div class="footer">
    <button id="take-tour-button"
      a7s-id="demo-mobile-splash-screen-take-tour"
      class="btn btn-primary"
      (click)="showMobileWebGuide()"
      i18n="@@S_DEMO_MOBILE_TOUR">Take the tour</button>
  </div>
</ng-template>
