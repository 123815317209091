/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { BalloonInfoActions, EBalloonInfoActions, ReverseGeocodeActions } from '../actions';
import { initialBalloonInfoState, IBalloonInfoState } from '../state';
import { IVehicleWorkOrderBalloon } from '../../models';

export function balloonInfoReducer(state = initialBalloonInfoState, action: BalloonInfoActions | ReverseGeocodeActions): IBalloonInfoState {
  switch (action.type) {
    case EBalloonInfoActions.GetLabelSuccess: {
      return {
        ...state,
        currentLabel: action.payload
      };
    }

    case EBalloonInfoActions.ClearLabel: {
      return {
        ...state,
        currentLabel: null
      };
    }

    case EBalloonInfoActions.GetLabelsSuccess: {
      return {
        ...state,
        labels: action.payload
      };
    }

    case EBalloonInfoActions.GetGarminStopBalloonSuccess:
    case EBalloonInfoActions.GetPlaceBalloonSuccess:
    case EBalloonInfoActions.GetVehicleBalloonSuccess:
    case EBalloonInfoActions.GetWorkOrderBalloonSuccess:
    case EBalloonInfoActions.GetHarshDrivingEventBalloonSuccess:
    case EBalloonInfoActions.GetAssetBalloonSuccess:
    case EBalloonInfoActions.GetReplayBalloonSuccess:
    case EBalloonInfoActions.GetPolylineBalloonSuccess:
    case EBalloonInfoActions.GetClusterBalloonSuccess: {
      return {
        ...state,
        balloon: action.payload
      };
    }

    case EBalloonInfoActions.CloseBalloon: {
      return {
        ...state,
        balloon: null
      };
    }

    case EBalloonInfoActions.UpdateVehicleBalloonScheduler:
    case EBalloonInfoActions.UpdateSpeedLimitVehicleBalloon:
    case EBalloonInfoActions.UpdateVehicleBalloonAddress:
    case EBalloonInfoActions.UpdateVehicleBalloonWorkingTimeDirective: {
      return {
        ...state,
        balloon: { ...action.payload } as IVehicleWorkOrderBalloon
      };
    }

    default:
      return state;
  }
}
