/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EPlotIcon {
  MovingN = 'moving-n',
  MovingNE = 'moving-ne',
  MovingE = 'moving-e',
  MovingSE = 'moving-se',
  MovingS = 'moving-s',
  MovingSW = 'moving-sw',
  MovingW = 'moving-w',
  MovingNW = 'moving-nw',
  Idle = 'idle',
  Stopped = 'stop',
  Towing = 'towing',
  LostSignal = 'ls',
  Private = 'private',
  Panic = 'panic',
  Hotspot = 'hotSpot',
  Geofence = '0',
  GarminStopPeding = 'garminIcon-pending',
  GarminStopCompleted = 'garminIcon-completed',
  GarminStopEnRoute = 'garminIcon-enRoute',
  CustomerCare = 'customer-care',
  Asset = 'asset-plot',
  HarshDrivingEvent = 'harsh-driving-event',
  ReplayStart = 'replay-start',
  ReplayEnd = 'replay-end',
  Violation = 'violation-triangle',
  MultidayPin = 'multiday-pin',
  MultidayArrow = 'multiday-arrow-marker',
  MultidayArrowActive = 'multiday-arrow-active-marker',
  MovingPin = 'moving-pin',
  IdlePin = 'idle-pin',
  StopPin = 'stop-pin',
  MovingNPoweredAsset = 'moving-n-powered-asset',
  MovingNEPoweredAsset = 'moving-ne-powered-asset',
  MovingEPoweredAsset = 'moving-e-powered-asset',
  MovingSEPoweredAsset = 'moving-se-powered-asset',
  MovingSPoweredAsset = 'moving-s-powered-asset',
  MovingSWPoweredAsset = 'moving-sw-powered-asset',
  MovingWPoweredAsset = 'moving-w-powered-asset',
  MovingNWPoweredAsset = 'moving-nw-powered-asset',
  IdlePoweredAsset = 'idle-powered-asset',
  StoppedPoweredAsset = 'stop-powered-asset',
  TowingPoweredAsset = 'towing-powered-asset',
  LostSignalPoweredAsset = 'ls-powered-asset',
  CustomerCarePoweredAsset = 'customer-care-powered-asset',
  Cluster = 'cluster'
}
