/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  EMapPlotsActions,
  MapPlotsActions,
  VehicleActions,
  EVehicleActions,
  ESearchLightActions,
  SearchLightActions,
  ReverseGeocodeActions,
  EReverseGeocodeActions,
  EAssetsActions,
  AssetsActions,
  EWorkingTimeDirectiveActions,
  WorkingTimeDirectiveActions
} from '../actions';
import { initialPlotsState, IPlotsState } from '../state';

export function plotsReducer(
  state = initialPlotsState,
  action: MapPlotsActions | VehicleActions | SearchLightActions | ReverseGeocodeActions | AssetsActions | WorkingTimeDirectiveActions
): IPlotsState {
  switch (action.type) {
    case EVehicleActions.UpdateVehiclesSuccess:
    case EMapPlotsActions.UpdateVehiclePlotsIconsSuccess:
    case EVehicleActions.UpdateVehiclesStatusSuccess:
    case EVehicleActions.UpdateVehicleSpeedSuccess:
    case EMapPlotsActions.GetVehiclePlotsSuccess:
    case EWorkingTimeDirectiveActions.UpdateVehiclesDriverName:
    case ESearchLightActions.GetSearchLightVehiclePlotSuccess:
    case EReverseGeocodeActions.ReverseGeocodeVehiclePlotsSuccess: {
      return {
        ...state,
        vehiclePlots: action.payload
      };
    }
    case EMapPlotsActions.GetHotspotPlotsSuccess: {
      return {
        ...state,
        hotspotPlots: action.payload
      };
    }
    case EMapPlotsActions.GetGeofencePlotsSuccess: {
      return {
        ...state,
        geofencePlots: action.payload
      };
    }
    case EMapPlotsActions.GetGarminStopsPlotsSuccess: {
      return {
        ...state,
        garminStopsPlots: action.payload
      };
    }
    case EMapPlotsActions.GetWorkOrdersPlotsSuccess: {
      return {
        ...state,
        workOrdersPlots: action.payload
      };
    }
    case EAssetsActions.GetAssetPlotsSuccess: {
      return {
        ...state,
        assetsPlots: action.payload
      };
    }

    case ESearchLightActions.RemoveSearchLightResultFromMap: {
      return {
        ...state,
        vehiclePlots: state.vehiclePlots.filter(vehicle => vehicle.id !== action.payload)
      };
    }

    case EMapPlotsActions.UpdateVehiclePlotsTimers: {
      return {
        ...state,
        vehiclePlotsTimers: { ...action.payload }
      };
    }

    default:
      return state;
  }
}
