/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { AssetsActions, EAssetsActions } from '../actions';
import { initialAssetsState, IAssetsState } from '../state';

export function assetsReducer(state = initialAssetsState, action: AssetsActions): IAssetsState {
  switch (action.type) {
    case EAssetsActions.GetSelectedAssetsSuccess:
    case EAssetsActions.UpdateAssetSelectionSuccess: {
      return {
        ...state,
        assetSelection: action.payload
      };
    }
    case EAssetsActions.GetAssetDetailsSuccess: {
      return {
        ...state,
        assetDetails: action.payload
      };
    }
    default:
      return state;
  }
}
