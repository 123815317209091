/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { IApiResponseDto } from '../../core/models';
import { IAppState } from '../../core/store/state';
import { DemoDtoMappingService } from '../services';
import { INotificationDetailsResponse, getNotifications } from '../../core/components';
import { getDemoVehiclePlotById } from '../store';

@Injectable()
export class AlertHttpInterceptor implements HttpInterceptor {
  constructor(private readonly _store: Store<IAppState>, private readonly _demoDtoMappingService: DemoDtoMappingService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.endsWith('/Alert/GetAlertLogInfo')) {
      const notificationId = req.params.get('request[AlertLogId]');

      return this._store.pipe(
        select(getNotifications),
        take(1),
        map(notifications => notifications.find(notification => notification.id === notificationId)),
        switchMap(notification =>
          of(notification).pipe(withLatestFrom(this._store.pipe(select(getDemoVehiclePlotById(notification.vehicleId)))))
        ),
        map(([notification, plot]) => this._demoDtoMappingService.notificationResponseToNotificationDetailsResponse(notification, plot)),
        map((details): IApiResponseDto<INotificationDetailsResponse> => ({ Data: details, Message: null })),
        map(apiResponse => new HttpResponse({ body: apiResponse, status: 200 }))
      );
    }

    return next.handle(req);
  }
}
