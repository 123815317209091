/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, EMPTY, of } from 'rxjs';
import { map, tap, switchMap, mergeMap, catchError, filter, take } from 'rxjs/operators';

import { isElementNull } from '@fleetmatics/ui.utilities';

import {
  ESendToGarminActions,
  GetNavigationEnabledVehicles,
  GetNavigationEnabledVehiclesSuccess,
  SendNavigationStop,
  GetGeocodeInfo,
  GetGeocodeInfoSuccess,
  GetSendToGarminModalData
} from '../actions';
import { PlanningSchedulingGarminHttpService, AddressResolutionHttpService } from '../../services';
import {
  INavigationEnabledVehiclesResponse,
  INavigationEnabledVehicles,
  ICoordinates,
  IReverseGeocodeResponse,
  IReverseGeocode,
  ISendToGarminParams,
  IReverseGeocodeRequest
} from '../../models';
import { delayedRetry } from '../../operators';

@Injectable()
export class SendToGarminEffects {

  public sendNavigationStop$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<SendNavigationStop>(ESendToGarminActions.SendNavigationStop),
    map((action: SendNavigationStop) => action.payload),
    tap((param: ISendToGarminParams) => {
      this._planningSchedulingGarminService
        .sendNavigationStop(param)
        .pipe(
          delayedRetry(),
          catchError(() => EMPTY),
          take(1)
        )
        .subscribe();
    })
  ), { dispatch: false });

  public getNavigationEnabledVehicles$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetNavigationEnabledVehicles>(ESendToGarminActions.GetNavigationEnabledVehicles),
    switchMap(() =>
      this._planningSchedulingGarminService.getNavigationEnabledVehicles().pipe(
        delayedRetry(),
        catchError(() => of(null))
      )
    ),
    filter(response => !isElementNull(response)),
    map(
      (response: INavigationEnabledVehiclesResponse[]) => new GetNavigationEnabledVehiclesSuccess(this._convertToNavigationModel(response))
    )
  ));

  public getSendToGarminModalData$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetSendToGarminModalData>(ESendToGarminActions.GetSendToGarminModalData),
    map((action: GetSendToGarminModalData) => action.payload),
    mergeMap((params: ICoordinates) => [new GetNavigationEnabledVehicles(), new GetGeocodeInfo(params)])
  ));

  public getGeocodeInfo$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetGeocodeInfo>(ESendToGarminActions.GetGeocodeInfo),
    map((action: GetGeocodeInfo) => action.payload),
    switchMap((parameters: ICoordinates) => {
      const reverseGeocodeRequests: IReverseGeocodeRequest[] = [
        {
          key: 'placeedit',
          data: {
            adrid: null,
            vid: null,
            Latitude: parameters.Latitude,
            Longitude: parameters.Longitude
          }
        }
      ];
      return this._addressResolutionService.getReverseGeocode(reverseGeocodeRequests).pipe(
        delayedRetry(),
        catchError(() => of(null))
      );
    }),
    filter(response => !isElementNull(response)),
    map((response: IReverseGeocodeResponse[]) => new GetGeocodeInfoSuccess(this._convertToGeocodeModel(response[0])))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _planningSchedulingGarminService: PlanningSchedulingGarminHttpService,
    private readonly _addressResolutionService: AddressResolutionHttpService
  ) {}

  private _convertToNavigationModel(response: INavigationEnabledVehiclesResponse[]): INavigationEnabledVehicles[] {
    const responseConverted: INavigationEnabledVehicles[] = [];
    response.forEach((vehicle: INavigationEnabledVehiclesResponse) => {
      responseConverted.push({
        hasNavigationDevice: vehicle.HasNavigationDevice,
        label: vehicle.Label,
        registrationNumber: vehicle.RegistrationNumber,
        vehicleId: vehicle.VehicleID,
        vehicleIcon: vehicle.VehicleIcon,
        vehicleName: vehicle.VehicleName,
        vehicleNumber: vehicle.VehicleNumber
      });
    });

    return responseConverted;
  }

  private _convertToGeocodeModel(response: IReverseGeocodeResponse): IReverseGeocode {
    const responseConverted: IReverseGeocode = {
      key: response.key,
      status: response.status,
      fullAddress: response.fulladdress
    };

    if (response.addr) {
      responseConverted.address = {
        formattedAddress: response.addr.formattedAddress,
        addressLine1: response.addr.addressLine1,
        addressLine2: response.addr.addressLine2,
        city: response.addr.city,
        state: response.addr.state,
        postalCode: response.addr.postalCode,
        country: response.addr.country,
        latitude: response.addr.latitude,
        longitude: response.addr.longitude
      };
    }

    return responseConverted;
  }
}
