/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as moment from 'moment-timezone';

import { IAccountAndUser } from '@fleetmatics/ui.insights';
import { isElementNull } from '@fleetmatics/ui.utilities';

import { IUserMapSettingsState } from '../state';
import {
  IUserFeatures,
  IUserPermissions,
  IAccountSettings,
  EPlotType,
  IUserCredentials,
  IUserSettings,
  EPermissionStatus,
  IReplayUserSettings,
  EFuelEfficiencyUnits
} from '../../models';
import { INotificationPreferences } from '../../components/notifications';
import { getAccessibleVehiclesCount } from './vehicle.selectors';
import { getIsWorkingTimeDirectiveEnabled, getIsSchedulerEnabled } from './config.selectors';
import { FEATURE_TOGGLES } from '../../constants';

export const getUserMapSettingsState = createFeatureSelector<IUserMapSettingsState>('userMapSettings');

export const getUserMapSettings = createSelector(getUserMapSettingsState, (state: IUserMapSettingsState): IUserMapSettingsState => state);

export const getUserPermissions = createSelector(
  getUserMapSettingsState,
  (state: IUserMapSettingsState): IUserPermissions => state.permissions
);

export const getFeatureToggles = createSelector(getUserMapSettingsState, (state: IUserMapSettingsState): string[] => state.featureToggles);

export const getFeatureToggle = createSelector(getFeatureToggles, (toggles: string[], props: any): boolean =>
  toggles.includes(props.featureName)
);

export const getUserNotificationPreferences = createSelector(
  getUserMapSettingsState,
  (state: IUserMapSettingsState): INotificationPreferences[] => state.notificationPreferences
);

export const getUserSettings = createSelector(getUserMapSettingsState, (state: IUserMapSettingsState): IUserSettings => state.userSettings);

export const getUserFeatures = createSelector(getUserMapSettingsState, (state: IUserMapSettingsState): IUserFeatures => state.features);

export const getUserAccountSettings = createSelector(
  getUserMapSettingsState,
  (state: IUserMapSettingsState): IAccountSettings => state.accountSettings
);

export const getUserCredentials = createSelector(
  getUserMapSettingsState,
  (state: IUserMapSettingsState): IUserCredentials => {
    return {
      userId: state.webSocketUserId,
      accountId: state.webSocketAccountId
    };
  }
);

export const getPendoConfiguration = createSelector(
  getUserMapSettingsState,
  (state): IAccountAndUser =>
    !isElementNull(state.accountSettings)
      ? {
          accountId: state.accountId,
          lang: state.accountSettings.Language,
          regionId: state.accountSettings.RegionId,
          userGuid: state.userRowGuid,
          universalAccountId: state.universalAccountId
        }
      : null
);

export const getSpeedUnits = createSelector(getUserMapSettingsState, (userSettings: IUserMapSettingsState) =>
  !isElementNull(userSettings.userSettings) ? userSettings.userSettings.SpeedUnits : null
);

export const getUserMaxPlottableMarkers = createSelector(getUserAccountSettings, (accountSettings: IAccountSettings) => {
  if (!isElementNull(accountSettings)) {
    const maxPlottableMarkers = [
      {
        type: EPlotType.hotspot,
        max: accountSettings.MaxPlottableHotspots
      },
      {
        type: EPlotType.geofence,
        max: accountSettings.MaxPlottableGeofences
      },
      {
        type: EPlotType.balloon,
        max: accountSettings.MaxPlottableBalloons
      },
      {
        type: EPlotType.garmin,
        max: 100
      },
      {
        type: EPlotType.workOrder,
        max: accountSettings.MaxPlottableWorkOrders
      }
    ];
    return maxPlottableMarkers;
  }
});

export const getMaxSelectableVehiclesCount = createSelector(getUserAccountSettings, (accountSettings: IAccountSettings): number => {
  return !isElementNull(accountSettings) ? accountSettings.MaxPlottableVehicles : null;
});

export const getMaxScopingVehicles = createSelector(getUserMapSettingsState, (state: IUserMapSettingsState): number => {
  if (!isElementNull(state.accountSettings)) {
    return state.accountSettings.MaxScopingVehicles;
  }
  return null;
});

export const getScopingEnabled = createSelector(
  getAccessibleVehiclesCount,
  getMaxScopingVehicles,
  (accessibleVehiclesCount: number, maxScopingVehicles: number) => {
    if (!isElementNull(accessibleVehiclesCount) && !isElementNull(maxScopingVehicles)) {
      return accessibleVehiclesCount > maxScopingVehicles;
    }
    return null;
  }
);

const isPermissionAllowed = (permission: EPermissionStatus) =>
  permission === EPermissionStatus.Full || permission === EPermissionStatus.ReadOnly;

export const getDeviceStatusNotificationsEnabled = createSelector(getUserMapSettingsState, (state: IUserMapSettingsState): boolean => {
  if (!isElementNull(state.permissions)) {
    return isPermissionAllowed(state.permissions.showTowingNotifications);
  }
  return null;
});

export const getIsWorkOrdersAllowed = createSelector(getUserPermissions, permissions => {
  if (!isElementNull(permissions)) {
    return (
      isPermissionAllowed(permissions.liveMapWorkOrders) &&
      (isPermissionAllowed(permissions.liveMapWorkOrdersAssigned) || isPermissionAllowed(permissions.liveMapWorkOrdersUnassigned))
    );
  }
  return null;
});

export const getWorkingTimeDirectiveFeatureAllowed = createSelector(
  getUserFeatures,
  getIsWorkingTimeDirectiveEnabled,
  (userFeatures: IUserFeatures, isWorkingTimeDirectiveEnabled: boolean) => {
    return !isElementNull(userFeatures) && userFeatures.realTimeTachoData && isWorkingTimeDirectiveEnabled;
  }
);

export const getGarminStopsPermissionAllowed = createSelector(getUserPermissions, (permissions: IUserPermissions) => {
  return (
    !isElementNull(permissions) && !isElementNull(permissions.dispatchWindowStops) && isPermissionAllowed(permissions.dispatchWindowStops)
  );
});

export const getGeofencesPermissionAllowed = createSelector(getUserPermissions, (permissions: IUserPermissions) => {
  return !isElementNull(permissions) && !isElementNull(permissions.geofences) && isPermissionAllowed(permissions.geofences);
});

export const getSuggestedGeofencesPermissionAllowed = createSelector(getUserPermissions, (permissions: IUserPermissions) => {
  return (
    !isElementNull(permissions) && !isElementNull(permissions.suggestedGeofences) && isPermissionAllowed(permissions.suggestedGeofences)
  );
});

export const getSchedulerAllowed = createSelector(
  getUserPermissions,
  getIsSchedulerEnabled,
  (permissions: IUserPermissions, isSchedulerEnabled: boolean) => {
    return !isElementNull(permissions) && !isElementNull(permissions.scheduler) && permissions.scheduler && isSchedulerEnabled;
  }
);

export const getReplayUserSettings = createSelector(
  getUserMapSettingsState,
  (state: IUserMapSettingsState): IReplayUserSettings => state.replaySettings
);

export const getDataPlanMinimumDate = createSelector(
  getUserSettings,
  getUserAccountSettings,
  (userSettings: IUserSettings, accountSettings: IAccountSettings): Date => {
    /*
    Minimum date should be inclusive of current date.
    Example: Today is Friday 13 March, Data plan is 7 days
    Result: start of Saturday 7 March
    */
    if (!isElementNull(userSettings) && !isElementNull(accountSettings)) {
      const minimumDate = moment()
        .tz(userSettings.TimeZoneAndroidId)
        .startOf('day')
        .subtract(accountSettings.DataPlan - 1, 'days');
      return minimumDate.toDate();
    }

    return null;
  }
);

export const getIsCustomBalloonsFeatureEnabled = createSelector(
  getUserFeatures,
  (userFeatures: IUserFeatures): boolean => !isElementNull(userFeatures) && userFeatures.customBalloons
);

export const getIsBalloonEcmFeatureEnabled = createSelector(
  getUserFeatures,
  (userFeatures: IUserFeatures): boolean => !isElementNull(userFeatures) && userFeatures.ecm
);

export const getIsBalloonEcmPermissionAllowed = createSelector(
  getUserPermissions,
  permissions => !isElementNull(permissions) && isPermissionAllowed(permissions.ecmFuelLevel)
);

export const getAccountFuelEfficiencyUnit = createSelector(getUserAccountSettings, (accountSettings: IAccountSettings) => {
  return !isElementNull(accountSettings) ? accountSettings.FuelEfficiencyUnits : EFuelEfficiencyUnits.LitresPer100km;
});

export const getShowHarshDrivingEvents = createSelector(
  getUserFeatures,
  (userFeatures: IUserFeatures): boolean => !isElementNull(userFeatures) && userFeatures.drivingStyle
);

export const getShowSpeedingEvents = createSelector(
  getUserPermissions,
  (permissions: IUserPermissions): boolean =>
    !isElementNull(permissions) && isPermissionAllowed(permissions.showSpeedLimits) && isPermissionAllowed(permissions.showVehicleSpeed)
);

export const getShowReplayVideoEnabled = createSelector(getUserPermissions, getUserFeatures, (permissions, features) => {
  const featureEnabled = !isElementNull(features) && (features.videoExperience || features.videoAccess);
  const permissionEnabled =
    !isElementNull(permissions) && (isPermissionAllowed(permissions.videoExperience) || isPermissionAllowed(permissions.videoAccess));
  return featureEnabled && permissionEnabled;
});

export const getIsClusteringByDefaultFeatureToggleEnabled = createSelector(getFeatureToggles, toggles =>
  getFeatureToggle.projector(toggles, { featureName: FEATURE_TOGGLES.ClusteringByDefault })
);
