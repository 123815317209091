/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';
import { ISearchEntity, ESearchType } from '../../models';

export const enum ESearchActions {
  GetSearchEntities = '[Search] Get Search Entities',
  GetSearchEntitiesSuccess = '[Search] Get Search Entities Success',
  SetSearchInput = '[Search] Set Search Input'
}

export class GetSearchEntities implements Action {
  public readonly type = ESearchActions.GetSearchEntities;

  constructor(public payload: ESearchType[]) {}
}

export class GetSearchEntitiesSuccess implements Action {
  public readonly type = ESearchActions.GetSearchEntitiesSuccess;

  constructor(public payload: ISearchEntity[]) {}
}

export class SetSearchInput implements Action {
  public readonly type = ESearchActions.SetSearchInput;

  constructor(public payload: string) {}
}

export type SearchActions = GetSearchEntities | GetSearchEntitiesSuccess | SetSearchInput;
