/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ILabel, IBalloon } from '../../models/';

export interface IBalloonInfoState {
  balloon: IBalloon;
  currentLabel: ILabel;
  labels: ILabel[];
}

export const initialBalloonInfoState: IBalloonInfoState = {
  balloon: undefined,
  currentLabel: undefined,
  labels: []
};
