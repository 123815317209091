/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { FmWindowRefService } from '@fleetmatics/ui.utilities';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'fm-tooltip-content',
  templateUrl: './tooltip.component.html'
})
export class TooltipComponent implements AfterViewInit {
  public eventPageX: number;
  public eventPageY: number;
  public top: string;
  public left: string;
  public contentWidth: number;

  @ViewChild('tooltipContainer')
  private readonly _tooltipContainer: ElementRef;

  constructor(private readonly _windowRefService: FmWindowRefService, private readonly _changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const { width } = this._tooltipContainer.nativeElement.getBoundingClientRect();
    this.contentWidth = width;
    this.setPosition(this.eventPageX, this.eventPageY);
    this._changeDetectorRef.detectChanges();
  }

  public setPosition(pageX: number, pageY: number): void {
    const left = pageX + this.contentWidth > this._windowRefService.nativeWindow.innerWidth ? pageX - this.contentWidth : pageX;
    this.top = `${pageY + 32}px`;
    this.left = `${left}px`;
  }
}
