/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { initialMapsState, IMapsState } from '../state';
import {
  ELayoutPreferencesActions,
  EMapsActions,
  EMapOptionsActions,
  GetLayoutPreferencesSuccess,
  MapsActions,
  UpdateIsClusteringEnabled,
  UpdateIsTrafficEnabled
} from '../actions';

export function mapsReducer(
  state = initialMapsState,
  action: MapsActions | UpdateIsTrafficEnabled | GetLayoutPreferencesSuccess | UpdateIsClusteringEnabled
): IMapsState {
  switch (action.type) {
    case EMapsActions.UpdateMapCenter: {
      return {
        ...state,
        mapCenter: action.payload
      };
    }
    case EMapsActions.MapBoundsUpdated: {
      return {
        ...state,
        bounds: action.payload
      };
    }
    case EMapsActions.ChangeMapBounds: {
      return {
        ...state,
        changeBounds: action.payload
      };
    }
    case EMapOptionsActions.UpdateIsTrafficEnabled: {
      return {
        ...state,
        isTrafficEnabled: action.payload
      };
    }
    case ELayoutPreferencesActions.GetLayoutPreferencesSuccess: {
      return {
        ...state,
        isTrafficEnabled: action.payload.mapOptions.isTrafficEnabled,
        isClusteringEnabled: action.payload.mapOptions.isClusteringEnabled
      };
    }
    case EMapOptionsActions.UpdateIsClusteringEnabled: {
      return {
        ...state,
        isClusteringEnabled: action.payload
      };
    }
    case EMapsActions.UpdateIsStreetViewOpen: {
      return {
        ...state,
        isStreetViewOpen: action.payload,
        streetViewLocation: action.payload ? state.streetViewLocation : null
      };
    }
    case EMapsActions.UpdateStreetViewLocation: {
      return {
        ...state,
        streetViewLocation: action.payload
      };
    }
    case EMapsActions.ShowStartingLocation: {
      return {
        ...state,
        showStartingLocation: action.payload
      };
    }
    case EMapsActions.HighlightMarkerSuccess: {
      return {
        ...state,
        markerToHighlight: { ...action.payload }
      };
    }
    case EMapsActions.RemoveMarkerHighlight: {
      return {
        ...state,
        markerToHighlight: null
      };
    }

    case EMapsActions.ZoomLevelChanged: {
      return {
        ...state,
        zoomLevel: action.payload
      };
    }
    case EMapsActions.GetMapBoundsFromUserPlotsSuccess: {
      return {
        ...state,
        changeBounds: action.payload
      };
    }
    case EMapsActions.SetSelectedMarkerSuccess: {
      return {
        ...state,
        selectedMarker: { ...action.payload }
      };
    }
    case EMapsActions.RemoveSelectedMarker: {
      return {
        ...state,
        selectedMarker: null
      };
    }
    default:
      return state;
  }
}
