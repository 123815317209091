/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IReverseGeocode, INavigationEnabledVehicles } from '../../models';

export interface ISendToGarminState {
  vehicles: INavigationEnabledVehicles[];
  geocodeInfo: IReverseGeocode;
}

export const initialSendToGarminState: ISendToGarminState = {
  vehicles: [],
  geocodeInfo: null
};
