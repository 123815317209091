/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable, Inject, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { AUTH_TOKEN_COOKIE_NAME } from '@fleetmatics/ui.authentication';
import { addNotifications, RevealGenericNotificationMessage } from '@fleetmatics/ui.notifications/connector';
import { isElementNull, FmCookieService, minutesToMilliseconds, isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import { IWebsocketConnectorService } from './websocket-connector.service.interface';
import { IWebsocketConfig, IRetryIntervals } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';
import { APP_CONFIG_TOKEN } from '../../../config/app-config.token';
import {
  INotificationResponse,
  IDeviceStatusResponse,
  EDeviceStatusCode,
  ENotificationSettingsTypes
} from '../../../core/components/notifications';
import { AddNotifications, DeletePanicNotification } from '../../../core/components/notifications/store';
import {
  IVehiclePlotWebsubsResponse,
  ISignalStatusUpdate,
  ESignalStatus,
  ISpeedViolationMessage,
  IFieldServiceMessageResponse
} from '../../../core/models';
import {
  UpdateVehicles,
  UpdateVehicleSpeed,
  StartVehiclesPolling,
  StopVehiclesPolling,
  UpdateScheduledJob,
  UpdateVehiclesStatus
} from '../../../core/store/actions';
import { IAppState } from '../../../core/store/state';

@Injectable()
export class WebsocketConnectorService implements IWebsocketConnectorService {
  private readonly _connectionEstablished$: Observable<void>;
  private readonly _connectionEstablishedSubject = new Subject<void>();
  private readonly _vehiclesToSubscribe$: Observable<string[]>;
  private readonly _vehiclesToSubscribeSubject = new Subject<string[]>();
  private _reconnectAttempts = 0;
  private _notifierHub: any;

  private readonly _retryIntervals: IRetryIntervals[] = [
    { min: 5, max: 30 },
    { min: 30, max: 90 },
    { min: 90, max: 180 }
  ];

  constructor(
    private readonly _store: Store<IAppState>,
    @Inject(APP_CONFIG_TOKEN) private readonly _config: IAppConfig,
    private readonly _zone: NgZone,
    private readonly _cookieService: FmCookieService
  ) {
    this._connectionEstablished$ = this._connectionEstablishedSubject.asObservable();
    this._vehiclesToSubscribe$ = this._vehiclesToSubscribeSubject.asObservable();
  }

  public start(config: IWebsocketConfig): void {
    const authToken = this._cookieService.getCookie(AUTH_TOKEN_COOKIE_NAME);

    $.connection.hub.logging = true;
    $.connection.hub.url = `${this._config.websocketUrl}/signalr`;
    $.connection.hub.qs = `token=${authToken}`;
    $.connection.hub.createHubProxy('notifierHub');

    this._notifierHub = $.connection.hub.proxies.notifierhub;

    this._setupVehiclesSubscription();
    this._setupReconnectionHandlers();
    this._subscribeToEvents();
    this._startConnection();
    this._setupRefreshAuthToken();
  }

  public subscribeVehicles(vehicles: string[]): void {
    this._vehiclesToSubscribeSubject.next(vehicles);
  }

  private _startConnection(): void {
    $.connection.hub
      .start({})
      .done(() => {
        this._reconnectAttempts = 0;
        this._zone.run(() => {
          this._connectionEstablishedSubject.next();
        });
      })
      .fail((signalRError: string) => {
        this._zone.run(() => {
          this._store.dispatch(new StartVehiclesPolling());
        });
      });
  }

  private _setupReconnectionHandlers(): void {
    $.connection.hub.disconnected(() => {
      this._zone.run(() => {
        if (this._reconnectAttempts === 0) {
          this._store.dispatch(new StartVehiclesPolling());
        }
      });

      setTimeout(() => {
        this._reconnectAttempts++;
        $.connection.hub.start({});
      }, this._calculateRandomInterval());
    });

    this._notifierHub.client.joined = () => {
      this._connectionEstablished();
    };

    this._notifierHub.client.rejoined = () => {
      this._connectionEstablished();
    };
  }

  private _connectionEstablished(): void {
    this._zone.run(() => {
      this._reconnectAttempts = 0;
      this._connectionEstablishedSubject.next();

      this._store.dispatch(new StopVehiclesPolling());
    });
  }

  private _setupRefreshAuthToken(): void {
    setInterval(() => {
      const authToken = this._cookieService.getCookie(AUTH_TOKEN_COOKIE_NAME);
      $.connection.hub.qs = `token=${authToken}`;
    }, minutesToMilliseconds(5));
  }

  private _setupVehiclesSubscription(): void {
    this._vehiclesToSubscribe$
      .pipe(
        filter(vehicleIds => !isElementNull(vehicleIds)),
        switchMap(vehicleIds => {
          if ($.connection.hub.state === $.connection.connectionState.connected) {
            this._notifierHub.server.subscribeVehicles(vehicleIds.join(','));
          }

          return this._connectionEstablished$.pipe(tap(() => this._notifierHub.server.subscribeVehicles(vehicleIds.join(','))));
        })
      )
      .subscribe();
  }

  private _calculateRandomInterval(): number {
    const attempt = this._reconnectAttempts <= 2 ? this._reconnectAttempts : 2;
    return (
      (Math.floor(Math.random() * (this._retryIntervals[attempt].max - this._retryIntervals[attempt].min + 1)) +
        this._retryIntervals[attempt].min) *
      1000
    );
  }

  private _subscribeToEvents(): void {
    this._notifierHub.client.rVehiclePlotMessage = (vehicle: IVehiclePlotWebsubsResponse) => {
      this._zone.run(() => {
        this._store.dispatch(new UpdateVehicles(vehicle));
      });
    };

    this._notifierHub.client.rVehicleSpeedViolationMessage = (vehicleId: number, notification: ISpeedViolationMessage) => {
      this._zone.run(() => {
        this._store.dispatch(new UpdateVehicleSpeed(notification));
      });
    };

    this._notifierHub.client.rWebPanicOffNotificationMessage = (notification: INotificationResponse) => {
      this._zone.run(() => {
        this._store.dispatch(new DeletePanicNotification(notification.vid));
      });
    };

    this._notifierHub.client.rWebNotificationMessage = (notification: INotificationResponse) => {
      if (notification.type === ENotificationSettingsTypes.fleetServiceReminder) {
        return;
      }
      this._zone.run(() => {
        this._store.dispatch(new AddNotifications(notification));
      });
    };

    this._notifierHub.client.VerizonConnectWebNotification = (notification: RevealGenericNotificationMessage) => {
      this._zone.run(() => {
        this._store.dispatch(addNotifications({ notifications: [notification] }));
      });
    };

    this._notifierHub.client.rReportNotificationMessage = (notification: INotificationResponse) => {
      this._zone.run(() => {
        this._store.dispatch(new AddNotifications(notification));
      });
    };

    this._subscribeFsdMessage();

    this._notifierHub.client.rBalloonCacheConnectionStatus = (connectionState: boolean) => {
      this._zone.run(() => {
        if (connectionState) {
          this._store.dispatch(new StopVehiclesPolling());
        } else {
          this._store.dispatch(new StartVehiclesPolling());
        }
      });
    };

    this._notifierHub.client.rRoutingPlanOptimizationJobResult = (n: any) => {};

    this._notifierHub.client.rRoutistEvaluationStatusEvent = (n: any) => {};

    this._subscribeDeviceStatusNotification();
  }

  private _subscribeFsdMessage(): void {
    this._notifierHub.client.FsdGenericWebMessage = (message: IFieldServiceMessageResponse) => {
      this._zone.run(() => {
        if (
          !isElementNull(message) &&
          !isElementNull(message.Data) &&
          (message.Data.subscriptionName === 'VisitNotificationUpdatedEvent' ||
            message.Data.subscriptionName === 'VisitTransitionUpdatedEvent' ||
            message.Data.subscriptionName === 'fsd-visit-eta') &&
          !isStringNullOrEmpty(message.Data.payload)
        ) {
          try {
            this._store.dispatch(new UpdateScheduledJob(JSON.parse(message.Data.payload)));
          } catch {}
        }
      });
    };
  }

  private _subscribeDeviceStatusNotification(): void {
    this._notifierHub.client.rDeviceStatusNotification = (deviceStatusNotification: IDeviceStatusResponse) => {
      this._zone.run(() => {
        if (deviceStatusNotification.Alert.TroubleCode === EDeviceStatusCode.gpsStrength) {
          const vehicleStatus: ISignalStatusUpdate = {
            alert: {
              troubleCode: ESignalStatus.Low,
              vehicleId: deviceStatusNotification.Alert.VehicleId
            }
          };
          this._store.dispatch(new UpdateVehiclesStatus([vehicleStatus]));
        } else if (
          deviceStatusNotification.Alert.TroubleCode === EDeviceStatusCode.towing ||
          deviceStatusNotification.Alert.TroubleCode === EDeviceStatusCode.installationError
        ) {
          this._store.dispatch(new AddNotifications(deviceStatusNotification));
        }
      });
    };
  }
}
