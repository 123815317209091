/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IPlaceCategory, IWorkOrderAdvancedOptions } from '../../models';

export interface IAdvancedOptionsState {
  placeCategories: IPlaceCategory[];
  placeCategoryIds: number[];
  garminStopStatusesIdsSelected: number[];
  workOrderAdvancedOptions: IWorkOrderAdvancedOptions;
}

export const initialAdvancedOptionsState: IAdvancedOptionsState = {
  placeCategories: [],
  placeCategoryIds: null,
  garminStopStatusesIdsSelected: null,
  workOrderAdvancedOptions: null
};
