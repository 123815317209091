/*!
 * Copyright © 2020-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

export class SortUtils {
  static collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

  static simpleValueComparer(a: number, b: number): number {
    return a - b;
  }

  static naturalSortComparer(a: string, b: string): number {
    return SortUtils.collator.compare(a, b);
  }

  static dateComparer(a: Date, b: Date, direction: 'asc' | 'desc' = 'asc'): number {
    const multiplier = direction === 'asc' ? 1 : -1;
    const firstTime = a?.getTime();
    const secondTime = b?.getTime();
    if (firstTime === secondTime) {
      return 0;
    }
    if (firstTime < secondTime) {
      return -1 * multiplier;
    }
    return multiplier;
  }
}
