<div class="list-select">
  <div class="list-select__controls">
    <ds-button [text]="selectAllText"
      componentStyle="tertiary"
      size="small"
      class="select-all"
      (clickBtn)="selectAll()"
      [disabled]="isSelectAllDisabled">
    </ds-button>
    <ds-button [text]="deselectAllText"
      componentStyle="tertiary"
      size="small"
      class="deselect-all"
      (clickBtn)="deselectAll()"
      [disabled]="isDeselectAllDisabled">
    </ds-button>
  </div>
  <hr class="list-select__hr" />
  <ul class="list-select__ul">
    <ng-content></ng-content>
  </ul>
</div>
