/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FmWindowRefService } from '@fleetmatics/ui.utilities';

import { IAppState } from '../../../core/store/state';
import { DemoConstants } from '../../constants';
import { ModalService } from '../../services';
import { ShareDemoLink, CopyDemoLink } from '../../store/demo.actions';
import { IDemoVariationContent } from '../../models';
import { getDemoVariationContent } from '../../store/demo.selectors';

@Component({
  selector: 'fm-share-demo-modal',
  templateUrl: './share-demo-modal.component.html',
  styleUrls: ['./share-demo-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareDemoModalComponent implements OnInit {
  public hasNavigatorShare = true;
  public isCopyAvailable = false;
  public demoVariationContent$: Observable<IDemoVariationContent>;

  @ViewChild('shareDemoModalContent', { static: false }) modalTemplate: TemplateRef<any>;

  constructor(
    private readonly _modalService: ModalService,
    private readonly _store: Store<IAppState>,
    private readonly _windowRefService: FmWindowRefService
  ) {}

  ngOnInit() {
    this.demoVariationContent$ = this._store.pipe(select(getDemoVariationContent));
    this.hasNavigatorShare = this._hasNavigatorShare();
    setTimeout(
      () =>
        this._modalService.open(DemoConstants.MobileWebShareDemoModalId, this.modalTemplate, {
          customClass: 'share-demo-modal',
          size: 'small',
          disableResponsiveness: true
        }),
      0
    );
  }

  public shareDemoLink(): void {
    this._store.dispatch(new ShareDemoLink());
  }

  public copyDemoLink(): void {
    this._store.dispatch(new CopyDemoLink());
    this._toggleCopiedMessage();
  }

  private _hasNavigatorShare(): boolean {
    const navigator = this._windowRefService.nativeWindow.navigator as any;
    return navigator && navigator.share;
  }

  private _toggleCopiedMessage(): void {
    this.isCopyAvailable = true;
    setTimeout(() => {
      this.isCopyAvailable = false;
    }, 5000);
  }
}
