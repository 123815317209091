/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  logout(): void {}
}
