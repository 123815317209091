<div class="tacho">
  <div>
    <mat-icon svgIcon="ic-wtd-driving"
      class="tacho__icon mat-icon-size-xs"
      [class.tacho--active]="isDriving()"
      i18n-fmTooltip="@@S_TACHO_STATUS_DRIVING"
      fmTooltip="Driving"></mat-icon>
    <mat-icon svgIcon="ic-wtd-other-work"
      class="tacho__icon mat-icon-size-xs"
      [class.tacho--active]="isWorking()"
      i18n-fmTooltip="@@S_TACHO_STATUS_OTHER_WORK"
      fmTooltip="Other Work"></mat-icon>
    <mat-icon svgIcon="ic-wtd-availability"
      class="tacho__icon mat-icon-size-xs"
      [class.tacho--active]="isAvailable()"
      i18n-fmTooltip="@@S_TACHO_STATUS_AVAILABLE"
      fmTooltip="Period of Availability"></mat-icon>
    <mat-icon svgIcon="ic-wtd-resting"
      class="tacho__icon mat-icon-size-xs"
      [class.tacho--active]="isResting()"
      i18n-fmTooltip="@@S_TACHO_STATUS_RESTING"
      fmTooltip="Resting"></mat-icon>

    <span *ngIf="showStartTime()"
      class="tacho__start-time">
      <span i18n="@@S_TACHO_START_TIME">Start time:</span> {{ tacho?.DriverWorkingTime?.StartTimeToDisplay }}
    </span>
  </div>

  <div *ngIf="isDataUnavailable"
    class="tacho-container__error">
    <span class="tacho-container-message"
      i18n="@@S_TACHO_DATA_UNAVAILABLE">Tachograph data unavailable</span>
  </div>

  <div *ngIf="isCardNotRecognised"
    class="tacho-container__error">
    <span #errorMessageTooltip
      class="tacho-container-message"
      i18n-fmTooltip="@@S_CLICK_CARD_NUMBER"
      fmTooltip="Click to copy Driver Card Number."
      (click)="copyToClipboard($event)">
      <span i18n="@@S_TACHO_CARD_DOES_NOT_EXIST_IN_ACCOUNT">Inserted driver card: <b> {{ tacho.DriverCardInVehicle?.DriverCardNumber }} </b> does not exist in account.</span>
    </span>

    <input #clickToCopyText
      type="hidden"
      i18n="@@S_CLICK_CARD_NUMBER"
      value="Click to copy Driver Card Number." />
    <input #copiedCardNumberText
      type="hidden"
      i18n="@@S_CARD_NUMBER_COPIED"
      value="Driver Card Number copied." />
  </div>

  <div *ngIf="isCardNotInserted"
    class="tacho-container__error">
    <span class="tacho-container-message"
      i18n="@@S_TACHO_CARD_NOT_INSERTED">Driver card not inserted</span>
  </div>

  <div *ngIf="isNoMatchingDrivingCards"
    class="tacho-container__error">
    <span #errorMessageTooltip
      class="tacho-container-message"
      i18n-fmTooltip="@@S_CLICK_CARD_NUMBER"
      fmTooltip="Click to copy Driver Card Number."
      (click)="copyToClipboard($event)">
      <span i18n="@@S_TACHO_INSERTED_CARD_DOES_NOT_MATCH_DRIVER">Inserted driver card: <b> {{ tacho.DriverCardInVehicle?.DriverCardNumber }} </b> exists in account but does not match assigned driver.</span>
    </span>

    <input #clickToCopyText
      type="hidden"
      i18n="@@S_CLICK_CARD_NUMBER"
      value="Click to copy Driver Card Number." />
    <input #copiedCardNumberText
      type="hidden"
      i18n="@@S_CARD_NUMBER_COPIED"
      value="Driver Card Number copied." />
  </div>

  <div *ngIf="isBreakDue"
    class="tacho-container__warn">
    <span class="tacho-container-message"
      i18n-fmTooltip="@@S_TACHO_BREAK_DUE_TIP"
      fmTooltip="Driver should have a break in {{ tacho.DriverWorkingTime?.TimeToBreakToDisplay }}">
      <span i18n="@@S_TACHO_BREAK_DUE">Break due:</span><b> {{ tacho.DriverWorkingTime?.TimeToBreakToDisplay }}</b>
    </span>
  </div>

  <div *ngIf="isBreakOverdue"
    class="tacho-container__error">
    <span class="tacho-container-message"
      i18n-fmTooltip="@@S_TACHO_BREAK_OVERDUE_TIP"
      fmTooltip="Driver should have had a break {{tacho.DriverWorkingTime?.BreakOverdueToDisplay}} ago">
      <span i18n="@@S_TACHO_BREAK_OVERDUE">Break overdue:</span><b> {{ tacho.DriverWorkingTime?.BreakOverdueToDisplay }}</b>
    </span>
  </div>
</div>
