/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IDemoVariationContent } from '../../models';
import { IAppState } from '../../../core/store/state';
import { getDemoVariationContent } from '../../store/demo.selectors';

@Component({
  selector: 'fm-mobile-nav-title',
  templateUrl: './mobile-nav-title.component.html',
  styleUrls: ['./mobile-nav-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileNavTitleComponent implements OnInit {
  public demoVariationContent$: Observable<IDemoVariationContent>;

  constructor(private readonly _store: Store<IAppState>) {}

  ngOnInit(): void {
    this.demoVariationContent$ = this._store.pipe(select(getDemoVariationContent));
  }
}
