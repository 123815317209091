/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FmWindowRefService, isElementNull } from '@fleetmatics/ui.utilities';

import { DemoConstants } from '../constants';
import { DemoDataResolverService } from '../services';

@Injectable()
export class UsabillaHttpInterceptor implements HttpInterceptor {
  constructor(private readonly _windowRefService: FmWindowRefService, private readonly _demoDataResolver: DemoDataResolverService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.endsWith('/Usabilla/GetUsabillaSpecifications')) {
      return this._demoDataResolver.isReady$.pipe(
        map(() => this._demoDataResolver.initialAppStateData.userMapSettings),
        map(
          settings =>
            new HttpResponse({
              body: {
                ScriptId: 'eabbf9405602',
                UserName: settings.accountId,
                UniversalAccountId: settings.accountId,
                RowGuid: this._getUsabillaRowGuid(),
                AccountRegionId: settings.accountSettings.RegionId,
                ButtonText: 'FEEDBACK'
              },
              status: 200
            })
        )
      );
    }

    return next.handle(req);
  }

  private _getUsabillaRowGuid(): string {
    let userGuidKey = this._windowRefService.nativeWindow.localStorage.getItem(DemoConstants.LocalStoragePendoUserGuidKey);
    if (isElementNull(userGuidKey)) {
      userGuidKey = this._newGuid();
      this._windowRefService.nativeWindow.localStorage.setItem(DemoConstants.LocalStoragePendoUserGuidKey, userGuidKey);
    }
    return userGuidKey;
  }

  private _newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line: no-bitwise
      const r = (Math.random() * 16) | 0,
        // tslint:disable-next-line: no-bitwise
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
