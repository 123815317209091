/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EOrientation {
  top,
  right,
  bottom,
  left
}
