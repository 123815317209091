/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  HostListener,
  Input,
  Renderer2,
  OnDestroy,
  Injector,
  ApplicationRef
} from '@angular/core';

import { FmDocumentRefService } from '@fleetmatics/ui.utilities';

import { TooltipComponent } from './tooltip.component';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[fmTooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input()
  public fmTooltip: string;

  @Input()
  public shouldDisplayFmTooltip = true;

  private _componentRef: ComponentRef<TooltipComponent>;
  private _isTouch: boolean;
  private _touchTimer: any;

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _resolver: ComponentFactoryResolver,
    private readonly _applicationRef: ApplicationRef,
    private readonly _injector: Injector,
    private readonly _documentRefService: FmDocumentRefService
  ) {}

  public ngOnDestroy(): void {
    this._destroy();
  }

  @HostListener('touchstart')
  public onTouchStart(): void {
    clearTimeout(this._touchTimer);
    this._isTouch = true;
    this._touchTimer = setTimeout(() => {
      this._isTouch = false;
    }, 500);
  }

  @HostListener('mousemove', ['$event'])
  public onMouseMove(event: MouseEvent): void {
    if (this._isTouch || !this.shouldDisplayFmTooltip) {
      return;
    }

    if (this._componentRef) {
      this._componentRef.instance.setPosition(event.pageX, event.pageY);
    } else {
      this._render(event);
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this._destroy();
  }

  public rerender(event: MouseEvent): void {
    if (!this._componentRef) {
      return;
    }

    this._destroy();
    this._render(event);
  }

  public hide(): void {
    this._destroy();
  }

  private _destroy(): void {
    if (!this._componentRef) {
      return;
    }

    const componentElement = this._componentRef.location.nativeElement;
    componentElement.parentNode.removeChild(componentElement);
    this._applicationRef.detachView(this._componentRef.hostView);
    this._componentRef.destroy();
    this._componentRef = null;
  }

  private _render(event: MouseEvent): void {
    const element = this._renderer.createText(this.fmTooltip);
    const factory = this._resolver.resolveComponentFactory(TooltipComponent);
    const injector = Injector.create({
      providers: [],
      parent: this._injector
    });
    this._componentRef = factory.create(injector, [[element]]);
    this._componentRef.instance.eventPageX = event.pageX;
    this._componentRef.instance.eventPageY = event.pageY;
    this._applicationRef.attachView(this._componentRef.hostView);
    this._documentRefService.nativeDocument.body.appendChild(this._componentRef.location.nativeElement);
  }
}
