<div #contextMenu
  class="context-menu"
  [style.top.px]="menuTop"
  [style.left.px]="menuLeft"
  dsClickOutside
  (clickOutside)="onClose()"
  (mouseleave)="onCloseWithDelay()"
  (mouseenter)="cancelClose()"
  (contextmenu)="onRightClick($event)">
  <p class="context-menu-name">{{ menuName }}
    <ng-content select="menuName"></ng-content>
  </p>
  <div *ngIf="latLng"
    class="context-menu-coordinates"
    i18n-fmTooltip="@@S_COPY_COORD"
    fmTooltip="Copy coordinates"
    (click)="copyCoordinates()"
    a7s-id="context-menu-copy-coordinates">
    <span class="context-menu-coordinates__text">{{ formattedCoordinates }}</span>
    <mat-icon svgIcon="ic-copy"
      class="mat-icon-size-xs"></mat-icon>
  </div>
  <span class="context-menu-separator"></span>
  <ng-content></ng-content>
</div>
