/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { ECheckboxState } from '@fleetmatics/ui.base-library';

import { IMapHierarchyOptions, IMapHierarchyInfo, IMapHierarchyItem, IUpdateVehicleAsSelectedParams } from '../../models';

export enum ECustomerMetadataActions {
  GetMapHierarchy = '[CustomerMetadata] Get Map Hierarchy',
  GetMapHierarchySuccess = '[CustomerMetadata] Get Map Hierarchy Success',
  GetMapHierarchyScoping = '[CustomerMetadata] Get Map Hierarchy Scoping',
  GetMapHierarchyScopingSuccess = '[CustomerMetadata] Get Map Hierarchy Scoping Success',
  SaveMapHierarchyScoping = '[CustomerMetadata] Save Map Hierarchy Scoping',
  SaveMapHierarchyScopingWarning = '[CustomerMetadata] Save Map Hierarchy Scoping Warning',
  SaveMapHierarchyScopingSuccess = '[CustomerMetadata] Save Map Hierarchy Scoping Success',
  StoreMapHierarchyScoping = '[CustomerMetadata] Store Map Hierarchy Scoping',
  UpdateVehicleAsSelected = '[CustomerMetadata] Update Vehicle as Selected',
  UpdateVehicleAsSelectedSuccess = '[CustomerMetadata] Update Vehicle as Selected Success',
  UpdateVehiclesSelected = '[CustomerMetadata] Update Vehicles Selected',
  UpdateVehiclesSelectedSuccess = '[CustomerMetadata] Update Vehicles Selected Success',
  UpdateMapHierarchyOptions = '[CustomerMetadata] Update Map Hierarchy Options',
  GetUserSpeedingThresholdSetting = '[CustomerMetadata] Get User Speeding Threshold Setting',
  GetUserSpeedingThresholdSettingSuccess = '[CustomerMetadata] Get User Speeding Threshold Setting Success'
}

export class GetMapHierarchy implements Action {
  public readonly type = ECustomerMetadataActions.GetMapHierarchy;

  constructor(public payload: IMapHierarchyOptions) {}
}

export class GetMapHierarchySuccess implements Action {
  public readonly type = ECustomerMetadataActions.GetMapHierarchySuccess;

  constructor(public payload: IMapHierarchyInfo) {}
}

export class GetMapHierarchyScoping implements Action {
  public readonly type = ECustomerMetadataActions.GetMapHierarchyScoping;
}

export class GetMapHierarchyScopingSuccess implements Action {
  public readonly type = ECustomerMetadataActions.GetMapHierarchyScopingSuccess;

  constructor(public payload: IMapHierarchyItem[]) {}
}

export class SaveMapHierarchyScoping implements Action {
  public readonly type = ECustomerMetadataActions.SaveMapHierarchyScoping;

  constructor(public payload: string[]) {}
}

export class SaveMapHierarchyScopingSuccess implements Action {
  public readonly type = ECustomerMetadataActions.SaveMapHierarchyScopingSuccess;

  constructor(public payload: string[]) {}
}

export class SaveMapHierarchyScopingWarning implements Action {
  public readonly type = ECustomerMetadataActions.SaveMapHierarchyScopingWarning;

  constructor(public payload: string) {}
}

export class StoreMapHierarchyScoping implements Action {
  public readonly type = ECustomerMetadataActions.StoreMapHierarchyScoping;

  constructor(public payload: IMapHierarchyItem[]) {}
}

export class UpdateVehiclesSelected implements Action {
  public readonly type = ECustomerMetadataActions.UpdateVehiclesSelected;

  constructor(public payload: Map<string, ECheckboxState>) {}
}

export class UpdateVehiclesSelectedSuccess implements Action {
  public readonly type = ECustomerMetadataActions.UpdateVehiclesSelectedSuccess;

  constructor(public payload: IMapHierarchyInfo) {}
}

export class UpdateVehicleAsSelected implements Action {
  public readonly type = ECustomerMetadataActions.UpdateVehicleAsSelected;

  constructor(public payload: IUpdateVehicleAsSelectedParams) {}
}

export class UpdateVehicleAsSelectedSuccess implements Action {
  public readonly type = ECustomerMetadataActions.UpdateVehicleAsSelectedSuccess;

  constructor(public payload: IMapHierarchyInfo) {}
}
export class GetUserSpeedingThresholdSetting implements Action {
  public readonly type = ECustomerMetadataActions.GetUserSpeedingThresholdSetting;

  constructor(public payload: string) {}
}

export class GetUserSpeedingThresholdSettingSuccess implements Action {
  public readonly type = ECustomerMetadataActions.GetUserSpeedingThresholdSettingSuccess;

  constructor(public payload: number) {}
}

export class UpdateMapHierarchyOptions implements Action {
  public readonly type = ECustomerMetadataActions.UpdateMapHierarchyOptions;

  constructor(public payload: IMapHierarchyOptions) {}
}

export type CustomerMetadataActions =
  | GetMapHierarchy
  | GetMapHierarchySuccess
  | GetMapHierarchyScoping
  | GetMapHierarchyScopingSuccess
  | SaveMapHierarchyScoping
  | SaveMapHierarchyScopingSuccess
  | SaveMapHierarchyScopingWarning
  | StoreMapHierarchyScoping
  | UpdateVehicleAsSelected
  | UpdateVehicleAsSelectedSuccess
  | UpdateVehiclesSelected
  | UpdateVehiclesSelectedSuccess
  | GetUserSpeedingThresholdSetting
  | GetUserSpeedingThresholdSettingSuccess
  | UpdateMapHierarchyOptions;
