/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { LatLng } from './lat-lng.class';
import { MarkerInfo } from './marker-info.class';
import { EPlotType } from './plot-type.enum';
import { IMarkerOptions } from './marker-options.interface';

export class ReplayMarkerInfo extends MarkerInfo {
  constructor(public id: number, public latLng: LatLng, public title: string, public type: EPlotType, public options: IMarkerOptions) {
    super(id, latLng, title, type, options);
  }
}
