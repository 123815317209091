/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  IVehiclePlot,
  IHotspotPlot,
  IGeofencePlot,
  IGarminStopPlot,
  IWorkOrderPlot,
  IIndexableObject,
  IAssetPlot,
  IReplayPlot
} from '../../models';

export interface IPlotsState {
  vehiclePlots: IVehiclePlot[];
  hotspotPlots: IHotspotPlot[];
  geofencePlots: IGeofencePlot[];
  garminStopsPlots: IGarminStopPlot[];
  workOrdersPlots: IWorkOrderPlot[];
  assetsPlots: IAssetPlot[];
  replayPlots: IReplayPlot[];
  vehiclePlotsTimers: IIndexableObject<number>;
}

export const initialPlotsState: IPlotsState = {
  vehiclePlots: [],
  hotspotPlots: [],
  geofencePlots: [],
  garminStopsPlots: [],
  workOrdersPlots: [],
  assetsPlots: [],
  replayPlots: [],
  vehiclePlotsTimers: {}
};
