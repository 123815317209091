/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IAppConfig } from './app-config.interfaces';

export function appConfigFactory(configService: ConfigService<IAppConfig>): IAppConfig {
  return configService.config;
}
