/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of, combineLatest } from 'rxjs';
import {
  switchMap,
  map,
  withLatestFrom,
  filter,
  catchError,
  delay,
  tap,
  startWith,
  distinctUntilChanged,
  take,
  takeUntil
} from 'rxjs/operators';
import * as moment from 'moment';

import { isArrayNullOrEmpty, isStringNullOrEmpty, isElementNull } from '@fleetmatics/ui.utilities';
import { PendoService } from '@fleetmatics/ui.insights';
import { IVehicleTachoStatus } from 'tacho';

import {
  EBalloonInfoActions,
  GetLabel,
  GetLabelSuccess,
  GetVehicleBalloon,
  GetVehicleBalloonSuccess,
  ShowBalloon,
  GetPlaceBalloon,
  GetPlaceBalloonSuccess,
  GetGarminStopBalloon,
  GetGarminStopBalloonSuccess,
  GetWorkOrderBalloon,
  GetWorkOrderBalloonSuccess,
  UpdateVehicleBalloon,
  ReverseGeocodeVehiclePlotsSuccess,
  EReverseGeocodeActions,
  UpdateVehicleBalloonAddress,
  GetHarshDrivingEventBalloon,
  GetHarshDrivingEventBalloonSuccess,
  GetAssetBalloon,
  GetAssetBalloonSuccess,
  GetVehiclesTachoStatusSuccess,
  ClearLabel,
  GetReplayBalloon,
  GetReplayBalloonSuccess,
  UpdateTachoVehicleCoordinates,
  GetPolylineBalloon,
  GetPolylineBalloonSuccess,
  StartTachoCardHighlight,
  GetClusterBalloon,
  GetClusterBalloonSuccess,
  CloseBalloon
} from '../actions';
import {
  getMapGeofenceMarkers,
  getMapHotspotsMarkers,
  getWorkOrdersPlots,
  getVehiclePlots,
  getMapVehicleMarkers,
  getBalloon,
  getHarshDrivingEvent,
  getAssetPlots,
  getHarshDrivingEventPage,
  getTachoEnabledVehicleIds,
  getWorkingTimeDirectiveDisplayEnabled,
  getSchedulerAllowed,
  getGarminStopsPlots,
  getUserSettings,
  getIsReplayOpen,
  getUserSpeedingThreshold,
  getShouldShowVehicleBalloonEcm,
  getWtdSettings,
  getAccountFuelEfficiencyUnit,
  getShowReplayVideoEnabled
} from '../selectors';
import { IAppState } from '../state';
import {
  MarkerInfo,
  EPlotType,
  IMapBalloonResponse,
  IVehicleWorkOrderBalloon,
  ICustomClickEventData,
  IPlaceBalloonResponse,
  IPlaceBalloon,
  EPlaceBalloonType,
  EMapBalloonType,
  ILoadGarminStopBalloonResponse,
  IGarminBalloon,
  LatLng,
  IHarshDrivingEventBalloon,
  IVehiclePlot,
  IAssetBalloon,
  IAppointmentResponse,
  IScheduledJob,
  EScheduledJobStatus,
  IScheduledJobEstimation,
  ILabel,
  IVehicleTachoInfo,
  IReverseGeocodeRequest,
  IPolylineBalloon,
  EPolylineHoverTrigger,
  IVehicleEcm,
  IUserSettings,
  IEcmVehicleRequest,
  IEcmVehicleResponse,
  EFuelEfficiencyUnits,
  EFuelEfficiencyConstants,
  EPendoCustomEvents,
  VehicleMarkerInfo,
  IAssetEcm,
  IClusterBalloon,
  IAssetPlot,
  IClusterClickEvent,
  IClusterBalloonItem,
  EVideoChannel,
  IVideoEventDetails
} from '../../models';
import {
  BalloonService,
  PlacesHttpService,
  WorkingTimeDirectiveHttpService,
  SchedulerHttpService,
  AddressResolutionHttpService,
  AssetsHttpService,
  ReportsHttpService,
  VideoEventHttpService
} from '../../services';
import {
  schedulerAppointmentTransitionStatusNotified,
  schedulerAppointmentStatusSent,
  schedulerAppointmentStatusReceived,
  schedulerAppointmentStatusRead,
  schedulerAppointmentStatusOnMyWay,
  schedulerAppointmentStatusInProgress,
  schedulerAppointmentStatusFinished
} from './scheduler.effects';
import { delayedRetry } from '../../operators';
import { IReplayBalloon } from '../../../replay/models/replay-balloon.interface';
import { getSegmentOfPlot, getPlotData, getReplayJourney } from '../../../replay/store';
import { IReplayPlotData, IReplayJourneySegment } from '../../../replay/models';
import { ReplayMappingUtilities } from '../../../replay/store/effects/replay-mapping.utilities';
import { ISize } from '../../../maps/models';

export const SCHEDULER_APPOINTMENT_LIMIT = 2;

@Injectable()
export class BalloonInfoEffects {
  public getLabel$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetLabel | ClearLabel>(EBalloonInfoActions.GetLabel, EBalloonInfoActions.ClearLabel),
      withLatestFrom(this._store.pipe(select(getIsReplayOpen))),
      switchMap(([action, isReplayOpen]: [GetLabel | ClearLabel, boolean]) => {
        const stopper: Observable<ILabel[]> = of([]);

        if (action.type === EBalloonInfoActions.ClearLabel || isReplayOpen) {
          return stopper;
        }
        const plotsInBounds: MarkerInfo[] = action.payload;
        return this._balloonService.getLabels(plotsInBounds).pipe(catchError(() => stopper));
      }),
      filter((labels: ILabel[]) => !isArrayNullOrEmpty(labels)),
      map((labels: ILabel[]) => new GetLabelSuccess(labels[0]))
    )
  );

  public showBalloon$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<ShowBalloon>(EBalloonInfoActions.ShowBalloon),
      map((action: ShowBalloon) => action.payload),
      map((markerClickData: ICustomClickEventData<MarkerInfo>) => {
        switch (markerClickData.data.type) {
          case EPlotType.vehicle:
          case EPlotType.poweredAsset:
            return new GetVehicleBalloon(markerClickData);
          case EPlotType.workOrder:
            return new GetWorkOrderBalloon(markerClickData);
          case EPlotType.hotspot:
          case EPlotType.geofence:
            return new GetPlaceBalloon(markerClickData);
          case EPlotType.garmin:
            return new GetGarminStopBalloon(markerClickData);
          case EPlotType.harshDrivingEvent:
            return new GetHarshDrivingEventBalloon(markerClickData);
          case EPlotType.asset:
            return new GetAssetBalloon(markerClickData);
          case EPlotType.replay:
            return new GetReplayBalloon(markerClickData);
          default:
            return null;
        }
      }),
      filter(action => !isElementNull(action))
    )
  );

  public updateVehicleBalloon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicleBalloon>(EBalloonInfoActions.UpdateVehicleBalloon),
      map(
        action =>
          <ICustomClickEventData<MarkerInfo>>{
            data: action.payload.marker,
            point: action.payload.point
          }
      ),
      map(balloonData => new GetVehicleBalloon(balloonData))
    )
  );

  public getVehicleBalloon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetVehicleBalloon>(EBalloonInfoActions.GetVehicleBalloon),
      map(action => action.payload),
      // 1) we need to get the balloon data - get additional data from the store needed to build the requests
      withLatestFrom(
        this._store.pipe(select(getWtdSettings)),
        this._store.pipe(select(getVehiclePlots)),
        this._store.pipe(select(getUserSettings)),
        this._store.pipe(select(getSchedulerAllowed)),
        this._store.pipe(select(getShouldShowVehicleBalloonEcm))
      ),
      switchMap(([markerClickData, wtdSettings, vehicles, userSettings, isSchedulerAllowed, isEcmAllowed]) =>
        // balloon data might emit more than once - this ensures that the balloon doesn't block for getting "additional data"
        // only the main call will block
        this._getVehicleBalloonData(wtdSettings, markerClickData, vehicles, isSchedulerAllowed, isEcmAllowed, userSettings).pipe(
          takeUntil(this._actions$.pipe(ofType<CloseBalloon>(EBalloonInfoActions.CloseBalloon), take(1)))
        )
      ),
      // 2) get the latest plot and marker data from the store
      withLatestFrom(
        this._store.pipe(select(getMapVehicleMarkers)),
        // ensure we are getting the latest plot data
        this._store.pipe(select(getVehiclePlots))
      ),
      map(
        ([balloon, markers, plots]) =>
          [balloon, markers, plots.find(p => p.id === balloon.id)] as [IVehicleWorkOrderBalloon, VehicleMarkerInfo[], IVehiclePlot]
      ),
      // if the latest plot doesn't exist, it means the plot was removed before the balloon data was loaded
      filter(([, , latestPlot]) => !isElementNull(latestPlot)),
      map(([balloon, markers, latestPlot]) => {
        this._addPlotAndMarkerDataToVehicleBalloon(balloon, markers, latestPlot);
        return balloon;
      }),
      map((balloon: IVehicleWorkOrderBalloon) => new GetVehicleBalloonSuccess(balloon))
    )
  );

  public getVehicleBalloonSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetVehicleBalloonSuccess>(EBalloonInfoActions.GetVehicleBalloonSuccess),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getWorkingTimeDirectiveDisplayEnabled)), this._store.pipe(select(getTachoEnabledVehicleIds))),
      filter(
        ([balloon, isDisplayEnabled, tachoEnabledIds]) =>
          isDisplayEnabled && tachoEnabledIds.includes(balloon.id) && !isElementNull(balloon.tacho)
      ),
      switchMap(([balloon]) => [new GetVehiclesTachoStatusSuccess([balloon.tacho]), new UpdateTachoVehicleCoordinates(balloon)])
    )
  );

  /*
      UpdateVehicleBalloon is dispatched when a vehicle update arrives, and a balloon is open.
      If the balloon is for a tacho enabled vehicle (working time directive),
      wait for the balloon success action and highlight the tacho card.

      Note that a GetVehicleBalloonSuccess can be triggered by a user - in this scenario we don't want to highlight the card
  */

  public onUpdateVehicleBalloonHighlightTacho$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicleBalloon>(EBalloonInfoActions.UpdateVehicleBalloon),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getWorkingTimeDirectiveDisplayEnabled)), this._store.pipe(select(getTachoEnabledVehicleIds))),
      filter(([balloon, isDisplayEnabled, tachoEnabledIds]) => isDisplayEnabled && tachoEnabledIds.includes(balloon.id)),
      switchMap(([balloon]) =>
        this._actions$.pipe(
          ofType<GetVehicleBalloonSuccess>(EBalloonInfoActions.GetVehicleBalloonSuccess),
          map(action => action.payload),
          take(1),
          // ensure the balloon success is for the same vehicle and it contains tacho information
          filter(balloonSuccess => balloon.id === balloonSuccess.id && !isElementNull(balloonSuccess.tacho)),
          map(balloonSuccess => ({ ...balloonSuccess.tacho, isUpdating: true }))
        )
      ),
      map(tacho => new StartTachoCardHighlight(tacho))
    )
  );

  public getWorkOrderBalloon$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetWorkOrderBalloon>(EBalloonInfoActions.GetWorkOrderBalloon),
      map((action: GetWorkOrderBalloon) => action.payload),
      switchMap((markerClickData: ICustomClickEventData<MarkerInfo>) =>
        this._balloonService.getMapBalloon(`${this._balloonRequestTypeMap.get(markerClickData.data.type)}_${markerClickData.data.id}`).pipe(
          delayedRetry(),
          map((balloon: IMapBalloonResponse) =>
            this._mapVehicleOrWorkOrderBalloonResponse(balloon, markerClickData, false, null, null, null, null, null)
          ),
          catchError(() => of(null))
        )
      ),
      filter(balloon => !isElementNull(balloon)),
      withLatestFrom(this._store.pipe(select(getWorkOrdersPlots))),
      map(([balloon, workOrders]) => {
        const balloonWorkOrder = workOrders.find(workOrder => workOrder.WorkOrderId === balloon.id);
        balloon.latLng = new LatLng(balloonWorkOrder.Point.Latitude, balloonWorkOrder.Point.Longitude);
        return balloon;
      }),
      map((balloon: IVehicleWorkOrderBalloon) => new GetWorkOrderBalloonSuccess(balloon))
    )
  );

  public getPlaceBalloon$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetPlaceBalloon>(EBalloonInfoActions.GetPlaceBalloon),
      map((action: GetPlaceBalloon) => action.payload),
      switchMap((markerClickData: ICustomClickEventData<MarkerInfo>) =>
        this._placesHttpService.getPlaceBalloon(markerClickData.data.id, this._balloonRequestTypeMap.get(markerClickData.data.type)).pipe(
          delayedRetry(),
          map((balloon: IPlaceBalloonResponse) => this._mapPlaceBalloonResponse(balloon, markerClickData)),
          catchError(() => of(null))
        )
      ),
      filter(balloon => !isElementNull(balloon)),
      withLatestFrom(this._store.pipe(select(getMapGeofenceMarkers)), this._store.pipe(select(getMapHotspotsMarkers))),
      map(([balloon, geofences, hotspots]) => {
        if (balloon.type === EPlotType.geofence) {
          balloon.latLng = geofences.find(geofence => geofence.id === balloon.id).latLng;
        } else if (balloon.type === EPlotType.hotspot) {
          balloon.latLng = hotspots.find(hotspot => hotspot.id === balloon.id).latLng;
        }
        return balloon;
      }),
      map((balloon: IPlaceBalloon) => new GetPlaceBalloonSuccess(balloon))
    )
  );

  public getAssetBalloon$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAssetBalloon>(EBalloonInfoActions.GetAssetBalloon),
      map((action: GetAssetBalloon) => action.payload),
      map(
        (markerClickData: ICustomClickEventData<MarkerInfo>) =>
          ({
            id: markerClickData.data.id,
            name: markerClickData.data.title,
            latLng: markerClickData.data.latLng,
            marker: markerClickData.data,
            type: markerClickData.data.type,
            point: markerClickData.point
          } as IAssetBalloon)
      ),
      switchMap(balloon => this._getAssetBalloonData(balloon)),
      withLatestFrom(this._store.pipe(select(getAssetPlots))),
      map(([balloon, assets]) => {
        const balloonAsset = assets.find(asset => asset.id === balloon.id);
        balloon.address = balloonAsset.address;
        balloon.lastUpdate = balloonAsset.lastUpdate;
        balloon.latLng = new LatLng(balloonAsset.center.Latitude, balloonAsset.center.Longitude);
        balloon.avatar$ = this._assetsHttpService.getAssetImageById(balloonAsset.id);
        return balloon;
      }),
      delay(0),
      map((balloon: IAssetBalloon) => new GetAssetBalloonSuccess(balloon))
    )
  );

  public getGarminStopBalloon$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetGarminStopBalloon>(EBalloonInfoActions.GetGarminStopBalloon),
      map((action: GetGarminStopBalloon) => action.payload),
      withLatestFrom(this._store.pipe(select(getGarminStopsPlots))),
      switchMap(([markerClickData, garminPlotData]) => {
        const garminPlot = garminPlotData.find(plot => plot.id === markerClickData.data.id);

        return this._balloonService.getGarminStopBalloon(markerClickData.data.id, garminPlot.extraoptionalid).pipe(
          delayedRetry(),
          map((balloon: ILoadGarminStopBalloonResponse) => this._mapGarminStopBalloonResponse(balloon, markerClickData)),
          map(balloon => {
            balloon.latLng = new LatLng(garminPlot.center.Latitude, garminPlot.center.Longitude);
            return balloon;
          }),
          catchError(() => of(null))
        );
      }),
      filter(balloon => !isElementNull(balloon)),
      map((balloon: IGarminBalloon) => new GetGarminStopBalloonSuccess(balloon))
    )
  );

  public getHarshDrivingEventBalloon$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetHarshDrivingEventBalloon>(EBalloonInfoActions.GetHarshDrivingEventBalloon),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getHarshDrivingEvent)), this._store.pipe(select(getHarshDrivingEventPage))),
      map(([markerData, event, eventPage]) => {
        const balloon: IHarshDrivingEventBalloon = {
          address: event.address.formatted,
          classification: event.classification,
          date: new Date(event.timeUtc),
          driverName: event.driver.name,
          id: event.id,
          latLng: new LatLng(event.address.latitude, event.address.longitude),
          marker: markerData.data,
          eventPage: eventPage,
          type: EPlotType.harshDrivingEvent,
          vehicleId: event.vehicle.id,
          vehicleName: event.vehicle.name
        };
        return new GetHarshDrivingEventBalloonSuccess(balloon);
      })
    )
  );

  public reverseGeocodeVehiclePlotsSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<ReverseGeocodeVehiclePlotsSuccess>(EReverseGeocodeActions.ReverseGeocodeVehiclePlotsSuccess),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getBalloon))),
      filter(
        ([vehiclePlots, balloon]) =>
          !isElementNull(balloon) &&
          (balloon.type === EPlotType.vehicle || balloon.type === EPlotType.poweredAsset) &&
          (balloon as IVehicleWorkOrderBalloon).isSearchingAddress &&
          vehiclePlots.some(plot => plot.id === balloon.id && !plot.isSearchingAddress)
      ),
      map(([vehiclePlots, balloon]) => {
        (balloon as IVehicleWorkOrderBalloon).isSearchingAddress = false;
        (balloon as IVehicleWorkOrderBalloon).status.formattedAddress = vehiclePlots.find(plot => balloon.id === plot.id).address;
        return new UpdateVehicleBalloonAddress(balloon as IVehicleWorkOrderBalloon);
      })
    )
  );

  public getReplayBalloon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetReplayBalloon>(EBalloonInfoActions.GetReplayBalloon),
      map(action => action.payload),
      switchMap(markerClickData =>
        of(markerClickData).pipe(
          withLatestFrom(
            this._store.pipe(select(getSegmentOfPlot, { plotId: markerClickData.data.id })),
            this._store.pipe(select(getPlotData, { plotId: markerClickData.data.id })),
            this._store.pipe(select(getUserSpeedingThreshold)),
            this._store.pipe(select(getShowReplayVideoEnabled))
          )
        )
      ),
      filter(
        ([, segment, plotData, userSpeedingThreshold]) =>
          !isElementNull(segment) && !isElementNull(plotData) && !isElementNull(userSpeedingThreshold)
      ),
      switchMap(([markerClickData, segment, plotData, userSpeedingThreshold, isVideoAllowed]) =>
        // balloon data might emit more than once - this ensures that the balloon doesn't block for getting "additional data"
        this._getReplayBalloonData(markerClickData, segment, plotData, userSpeedingThreshold, isVideoAllowed).pipe(
          takeUntil(this._actions$.pipe(ofType<CloseBalloon>(EBalloonInfoActions.CloseBalloon), take(1)))
        )
      ),
      map(replayBalloon => new GetReplayBalloonSuccess(replayBalloon))
    )
  );

  public getClusterBalloon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetClusterBalloon>(EBalloonInfoActions.GetClusterBalloon),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getVehiclePlots)), this._store.pipe(select(getAssetPlots))),
      map(([clusterClickData, vehiclePlots, assetPlots]) => {
        const items = this._getPlotsFromClusterClickData(clusterClickData, vehiclePlots, assetPlots);
        const balloon: IClusterBalloon = this._createClusterBalloon(clusterClickData, items);
        return new GetClusterBalloonSuccess(balloon);
      })
    )
  );

  public getPolylineHoverBalloon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetPolylineBalloon>(EBalloonInfoActions.GetPolylineBalloon),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getReplayJourney))),
      map(([polylineHoverParams, journey]) => {
        const day = polylineHoverParams.polylineInfo.id;
        const totalDays = journey.days.length;
        const date = journey.days[day - 1].segments[0].dateRange.start.toDate();

        const polylineBalloon: IPolylineBalloon = {
          day: day,
          totalDays: totalDays,
          date: date,
          id: polylineHoverParams.polylineInfo.id,
          latLng: polylineHoverParams.hoveredLatLng,
          type: EPlotType.polyline,
          marker: {
            id: polylineHoverParams.polylineInfo.id,
            latLng: polylineHoverParams.hoveredLatLng,
            title: '',
            type: EPlotType.polyline,
            options: {
              addListeners: false,
              className: null,
              priority: null,
              htmlContent: null,
              iconName: null,
              offset: this._getPolylineBalloonOffset(polylineHoverParams.trigger),
              rotate: null,
              size: { height: 0, width: 0 }
            }
          }
        };
        return new GetPolylineBalloonSuccess(polylineBalloon);
      })
    )
  );

  public trackShowBalloonEvent$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ShowBalloon>(EBalloonInfoActions.ShowBalloon),
        map(action => action.payload.data.type),
        tap((eplotType: EPlotType) => {
          const type = EPlotType[eplotType];
          if (eplotType !== EPlotType.vehicle && eplotType !== EPlotType.poweredAsset && !isElementNull(type)) {
            this._pendoService.trackEvent(`${EPendoCustomEvents.ShowBalloon}_${type}`, {});
          }
        })
      ),
    { dispatch: false }
  );

  public trackVehicleBalloonEvent$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<GetVehicleBalloonSuccess>(EBalloonInfoActions.GetVehicleBalloonSuccess),
        map(action => action.payload),
        tap(balloon => {
          const type = isElementNull(balloon.vehicleEcm) ? EPlotType[balloon.type] : 'vehicle_ecm';
          this._pendoService.trackEvent(`${EPendoCustomEvents.ShowBalloon}_${type}`, {});
        })
      ),
    { dispatch: false }
  );

  private readonly _balloonRequestTypeMap = new Map<number, number>([
    [EPlotType.geofence, EPlaceBalloonType.Geofence],
    [EPlotType.vehicle, EMapBalloonType.Vehicle],
    [EPlotType.poweredAsset, EMapBalloonType.PoweredAsset],
    [EPlotType.hotspot, EPlaceBalloonType.Hotspot],
    [EPlotType.garmin, EMapBalloonType.Garmin],
    [EPlotType.workOrder, EMapBalloonType.WorkOrder],
    [EPlotType.asset, EMapBalloonType.Asset]
  ]);

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _balloonService: BalloonService,
    private readonly _placesHttpService: PlacesHttpService,
    private readonly _workingTimeDirectiveService: WorkingTimeDirectiveHttpService,
    private readonly _schedulerHttpService: SchedulerHttpService,
    private readonly _addressResolutionHttpService: AddressResolutionHttpService,
    private readonly _assetsHttpService: AssetsHttpService,
    private readonly _reportsHttpService: ReportsHttpService,
    private readonly _pendoService: PendoService,
    private readonly _videoEventHttpService: VideoEventHttpService
  ) {}

  private _createClusterBalloon(clusterClickData: IClusterClickEvent, items: IClusterBalloonItem[]): IClusterBalloon {
    return {
      id: clusterClickData.id,
      latLng: clusterClickData.latLng,
      type: EPlotType.cluster,
      marker: {
        id: clusterClickData.id,
        latLng: clusterClickData.latLng,
        title: '',
        type: EPlotType.cluster,
        options: {
          addListeners: false,
          offset: {
            height: 0,
            width: 0
          },
          size: {
            height: 10,
            width: 10
          }
        }
      },
      clusteredItems: items
    };
  }

  private _getPlotsFromClusterClickData(
    clusterClickEvent: IClusterClickEvent,
    vehiclePlots: IVehiclePlot[],
    assetPlots: IAssetPlot[]
  ): IClusterBalloonItem[] {
    const clusteredMarkerIds = clusterClickEvent.markerIdsByPlotType;
    const vehicleIds = clusteredMarkerIds.get(EPlotType.vehicle) ?? [];
    const poweredAssetIds = clusteredMarkerIds.get(EPlotType.poweredAsset) ?? [];
    const nonPoweredAssetIds = clusteredMarkerIds.get(EPlotType.asset) ?? [];

    const vehiclePoweredAssetClusterItems: IClusterBalloonItem[] = vehiclePlots
      .filter(plot => [...vehicleIds, ...poweredAssetIds].includes(plot.id))
      .map(plot => this._mapVehiclePlotToClusterBalloonItem(plot));
    const nonPoweredAssetItems: IClusterBalloonItem[] = assetPlots
      .filter(plot => nonPoweredAssetIds.includes(plot.id))
      .map(plot => this._mapAssetPlotToClusterBalloonItem(plot));
    return [...vehiclePoweredAssetClusterItems, ...nonPoweredAssetItems];
  }

  private _mapVehiclePlotToClusterBalloonItem(plot: IVehiclePlot): IClusterBalloonItem {
    const item: IClusterBalloonItem = {
      id: plot.id,
      label: plot.vehicleName,
      type: plot.type,
      iconClass: plot.iconClass,
      latLng: new LatLng(plot.coordinates.lat, plot.coordinates.lng)
    };
    if (plot.driverId) {
      item.driver = plot.driverDisplayName;
    }
    return item;
  }

  private _mapAssetPlotToClusterBalloonItem(plot: IAssetPlot): IClusterBalloonItem {
    const item: IClusterBalloonItem = {
      id: plot.id,
      label: plot.name,
      type: plot.type,
      iconClass: plot.iconClass,
      latLng: new LatLng(plot.center.Latitude, plot.center.Longitude)
    };
    return item;
  }

  private _getVehicleBalloonData(
    wtdSettings: any,
    markerClickData: ICustomClickEventData<MarkerInfo>,
    vehicles: IVehiclePlot[],
    isSchedulerAllowed: boolean,
    isEcmAllowed: boolean,
    userSettings: IUserSettings
  ): Observable<IVehicleWorkOrderBalloon> {
    const isTachoEnabledForVehicle = wtdSettings.enabledVehicleIds.includes(markerClickData.data.id);
    const shouldGetTachoStatus = wtdSettings.isWorkingTimeDirectiveDisplayEnabled && isTachoEnabledForVehicle;
    const vehiclePlot = vehicles.find(vehicle => vehicle.id === markerClickData.data.id);

    return combineLatest([
      this._balloonService.getMapBalloon(`${this._getBaloonType(markerClickData)}_${markerClickData.data.id}`).pipe(
        delayedRetry(),
        catchError(() => of(null))
      ),
      shouldGetTachoStatus
        ? this._workingTimeDirectiveService.getVehicleTachoStatus(markerClickData.data.id.toString()).pipe(
            startWith(null as IVehicleTachoStatus),
            delayedRetry(),
            catchError(() => of(null))
          )
        : of(null),
      isSchedulerAllowed && !isElementNull(vehiclePlot.driverId)
        ? this._schedulerHttpService.getAppointments(vehiclePlot.driverId).pipe(
            startWith(null as IAppointmentResponse[]),
            delayedRetry(),
            catchError(() => of(null))
          )
        : of(null),
      isEcmAllowed
        ? this._reportsHttpService
            .getEcmFuelEfficiency({
              vehicleId: markerClickData.data.id,
              startTime: moment().subtract(1, 'day').utc(),
              endTime: moment().utc()
            } as IEcmVehicleRequest)
            .pipe(
              startWith(null as IEcmVehicleResponse),
              delayedRetry(),
              catchError(() => of(null))
            )
        : of(null)
    ]).pipe(
      filter(([balloon]) => !isElementNull(balloon)),
      withLatestFrom(this._store.pipe(select(getAccountFuelEfficiencyUnit))),
      map(([[balloon, tacho, appointments, ecm], fuelEfficiencyUnit]) =>
        this._mapVehicleOrWorkOrderBalloonResponse(
          balloon,
          markerClickData,
          isTachoEnabledForVehicle,
          tacho,
          appointments,
          userSettings,
          fuelEfficiencyUnit,
          ecm
        )
      ),
      distinctUntilChanged()
    );
  }

  private _getBaloonType(markerClickData: ICustomClickEventData<MarkerInfo>) {
    return this._balloonRequestTypeMap.get(markerClickData.data.type);
  }

  private _addPlotAndMarkerDataToVehicleBalloon(
    balloon: IVehicleWorkOrderBalloon,
    markers: VehicleMarkerInfo[],
    vehiclePlot: IVehiclePlot
  ): void {
    balloon.marker = markers.find(marker => marker.id === balloon.id);
    balloon.latLng = new LatLng(vehiclePlot.coordinates.lat, vehiclePlot.coordinates.lng);
    balloon.lastStateChangeDuration = vehiclePlot.duration;
    balloon.status.inrixSpeedLimitType = vehiclePlot.speedViolation.inrixSpeedLimitType;
    balloon.status.violationSpeed = vehiclePlot.speedViolation.violationSpeed;
    balloon.status.speed = vehiclePlot.speed;

    if (balloon.driverId <= 0 && vehiclePlot.driverId > 0) {
      balloon.driverId = vehiclePlot.driverId;
    }
    if (isStringNullOrEmpty(balloon.status.formattedAddress)) {
      balloon.status.formattedAddress = vehiclePlot.address;
      balloon.isSearchingAddress = vehiclePlot.isSearchingAddress;
    }
  }

  private _mapVehicleOrWorkOrderBalloonResponse(
    balloon: IMapBalloonResponse,
    markerClickData: ICustomClickEventData<MarkerInfo>,
    tachoIsEnabled: boolean,
    tachoStatus: IVehicleTachoStatus,
    appointments: IAppointmentResponse[],
    userSettings: IUserSettings,
    userFuelEfficencyUnit: EFuelEfficiencyUnits,
    ecm: IEcmVehicleResponse
  ): IVehicleWorkOrderBalloon {
    return {
      avatar: balloon.Avatar,
      canPing: balloon.CanPing,
      color: balloon.BalloonColor,
      customElements: balloon.CustomElements,
      driverId: balloon.DriverId,
      driverName: balloon.DriverName,
      hasNavigationDevice: balloon.HasNavigationDevice,
      id: balloon.Id,
      latLng: markerClickData.data.latLng,
      lastStateChangeDuration: null,
      marker: markerClickData.data,
      point: markerClickData.point,
      showHeader: balloon.ShowHeader,
      showLinks: balloon.Links,
      showStandardElements: balloon.ShowStandardElements,
      status: {
        addressComponents: balloon.Status.AddressComponents,
        closestGeoFence: balloon.Status.ClosestGeoFence,
        formattedAddress: balloon.Status.FormattedAddress,
        inrixSpeedLimitType: balloon.Status.InrixSpeedLimitType,
        isLastUpdateFromToday: balloon.Status.LastUpdateIsToday,
        lastUpdateTime: balloon.Status.LastUpdateTime,
        speed: balloon.Status.Speed,
        speedString: balloon.Status.SpeedString,
        vehicleStatus: balloon.Status.VehicleStatus,
        violationSpeed: balloon.Status.ViolationSpeed
      },
      tacho: this._mapToVehicleTachoInfo(tachoStatus, tachoIsEnabled),
      scheduler: {
        scheduledJobs: this._mapAppointmentResponse(appointments, userSettings?.Language)
      },
      type: markerClickData.data.type,
      vehicleName: balloon.VehicleName,
      vehicleEcm: this._mapToVehicleEcm(balloon, ecm, userSettings?.TimeZoneAndroidId, userFuelEfficencyUnit)
    };
  }

  private _mapToVehicleTachoInfo(tachoStatus: IVehicleTachoStatus, tachoIsEnabled: boolean): IVehicleTachoInfo {
    return isElementNull(tachoStatus)
      ? null
      : {
          status: tachoStatus,
          isEnabled: tachoIsEnabled,
          isUpdating: false
        };
  }

  private _mapAppointmentResponse(appointments: IAppointmentResponse[], userLanguage: string): IScheduledJob[] {
    const scheduledJobs: IScheduledJob[] = [];

    if (!isElementNull(appointments) && appointments.length > 0) {
      appointments.slice(0, SCHEDULER_APPOINTMENT_LIMIT).forEach(appointment => {
        const localArrivalDate = new Date(appointment.arrivalStartDatetime);
        const timestamp = localArrivalDate.getTime();
        const status = this._getScheduledJobStatus(appointment);

        if (status !== EScheduledJobStatus.None) {
          scheduledJobs.push({
            appointmentId: appointment.id,
            technicianId: appointment.technicianVisit.technicianId,
            timestamp,
            title: appointment.category,
            primaryContact: `${appointment.primaryContact.firstName} ${appointment.primaryContact.lastName}`,
            shortDate: this._getFormattedDate(localArrivalDate, userLanguage),
            startTime: this._getFormattedTime(localArrivalDate, userLanguage),
            endTime: this._getFormattedTime(new Date(timestamp + appointment.durationMin * 60000), userLanguage),
            address: appointment.site.formattedAddress,
            status,
            estimation: this._getScheduledJobEstimation(appointment, status, userLanguage)
          });
        }
      });
    }

    return scheduledJobs;
  }

  private _mapToVehicleEcm(
    balloon: IMapBalloonResponse,
    ecm: IEcmVehicleResponse,
    timeZoneAndroidId: string,
    userFuelEfficencyUnit: EFuelEfficiencyUnits
  ): IVehicleEcm | IAssetEcm {
    // powered asset - map battery
    const isPoweredAsset = !isElementNull(balloon.VehicleEcm?.BatteryLifetimePercentage);
    if (isPoweredAsset) {
      return {
        remainingBatteryPercentage: balloon.VehicleEcm.BatteryLifetimePercentage
      } as IAssetEcm;
    }

    if (isElementNull(balloon.VehicleEcm) || isElementNull(ecm)) {
      return null;
    }

    const now = moment().tz(timeZoneAndroidId);
    const lastUpdate = moment(balloon.VehicleEcm?.LastUpdateDateTime).tz(timeZoneAndroidId);
    const lastUpdateDiff = Math.ceil(moment.duration(now.diff(lastUpdate)).asMinutes());

    return {
      fuelEfficiencyUnit: userFuelEfficencyUnit,
      fuelUsedLitres: this._convertFuelEfficiencyUnits(ecm?.metrics[0].litersPer100Kilometers, userFuelEfficencyUnit),
      lastUpdateDiff: lastUpdateDiff,
      remainingFuelPercentage: balloon.VehicleEcm?.RemainingFuelPercentage ?? 0,
      remainingBatteryPercentage: null
    } as IVehicleEcm;
  }

  private _convertFuelEfficiencyUnits(fuelUsed: number, userFuelEfficencyUnit: EFuelEfficiencyUnits): number {
    if (isElementNull(fuelUsed) || fuelUsed === 0) {
      return 0;
    }

    let convertedFuelEfficiency = fuelUsed;
    switch (userFuelEfficencyUnit) {
      case EFuelEfficiencyUnits.KilometersPerLitre:
        convertedFuelEfficiency = EFuelEfficiencyConstants.LitresPer100kmToKilometersPerLitre / fuelUsed;
        break;
      case EFuelEfficiencyUnits.KilometersPerGallonUS:
        convertedFuelEfficiency = EFuelEfficiencyConstants.LitresPer100kmToKilometersPerGallonUS / fuelUsed;
        break;
      case EFuelEfficiencyUnits.MilesPerGallonUS:
        convertedFuelEfficiency = EFuelEfficiencyConstants.LitresPer100kmToMilesPerGallonUS / fuelUsed;
        break;
      case EFuelEfficiencyUnits.MilesPerGallonUK:
        convertedFuelEfficiency = EFuelEfficiencyConstants.LitresPer100kmToMilesPerGallonUK / fuelUsed;
        break;
    }

    return convertedFuelEfficiency;
  }

  private _getScheduledJobStatus(appointment: IAppointmentResponse): EScheduledJobStatus {
    let status = EScheduledJobStatus.None;

    if (isElementNull(appointment.technicianVisit)) {
      return status;
    }

    const transitionStatus = appointment.technicianVisit.state.toLowerCase();

    if (transitionStatus === schedulerAppointmentTransitionStatusNotified) {
      switch (appointment.technicianVisit.notificationStatus.toLowerCase()) {
        case schedulerAppointmentStatusSent:
          status = EScheduledJobStatus.Sent;
          break;
        case schedulerAppointmentStatusReceived:
          status = EScheduledJobStatus.Received;
          break;
        case schedulerAppointmentStatusRead:
          status = EScheduledJobStatus.Read;
          break;
      }
    } else {
      switch (transitionStatus) {
        case schedulerAppointmentStatusOnMyWay:
          status = EScheduledJobStatus.OnMyWay;
          break;
        case schedulerAppointmentStatusInProgress:
          status = EScheduledJobStatus.Started;
          break;
        case schedulerAppointmentStatusFinished:
          status = EScheduledJobStatus.Finished;
          break;
      }
    }
    return status;
  }

  private _getScheduledJobEstimation(
    appointment: IAppointmentResponse,
    scheduledJobStatus: EScheduledJobStatus,
    userLanguage: string
  ): IScheduledJobEstimation {
    let scheduledJobEstimation: IScheduledJobEstimation = null;

    if (
      !isElementNull(appointment.technicianVisit.eta) &&
      scheduledJobStatus !== EScheduledJobStatus.Started &&
      scheduledJobStatus !== EScheduledJobStatus.Finished
    ) {
      const appointmentEta = appointment.technicianVisit.eta;
      const localEstimatedTime = new Date(appointmentEta.eta);
      const localEstimatedTimeRemoveDate = new Date(appointmentEta.requestTimestamp);
      const estimatedTimeRemoveDate = new Date(localEstimatedTimeRemoveDate.getTime() + appointmentEta.ttl * 60000);

      scheduledJobEstimation = {
        estimatedTime: this._getFormattedTime(localEstimatedTime, userLanguage).toUpperCase(),
        estimatedTimeRemoveDate: estimatedTimeRemoveDate > new Date() ? estimatedTimeRemoveDate : null,
        isOnTime: !isStringNullOrEmpty(appointment.technicianVisit.eta.etaStatus) && appointment.technicianVisit.eta.etaStatus === 'onTime'
      };
    }
    return scheduledJobEstimation;
  }

  private _getFormattedTime(date: Date, userLanguage: string): string {
    return date.toLocaleTimeString(userLanguage, { hour: 'numeric', minute: 'numeric' });
  }

  private _getFormattedDate(date: Date, userLanguage: string): string {
    return date.toLocaleDateString(userLanguage, { year: 'numeric', month: 'numeric', day: 'numeric' });
  }

  private _mapPlaceBalloonResponse(balloon: IPlaceBalloonResponse, markerClickData: ICustomClickEventData<MarkerInfo>): IPlaceBalloon {
    return {
      address: balloon.fulladdress,
      category: balloon.catname,
      categoryColor: `#${balloon.catcolor}`,
      id: markerClickData.data.id,
      latLng: markerClickData.data.latLng,
      marker: markerClickData.data,
      name: balloon.name,
      placeType: this._balloonRequestTypeMap.get(markerClickData.data.type),
      point: markerClickData.point,
      type: markerClickData.data.type
    };
  }

  private _mapGarminStopBalloonResponse(
    balloon: ILoadGarminStopBalloonResponse,
    markerClickData: ICustomClickEventData<MarkerInfo>
  ): IGarminBalloon {
    return {
      address: balloon.FullAddress,
      description: balloon.Description,
      deviceStopId: balloon.DeviceStopId,
      driverName: balloon.DriverName,
      id: balloon.id,
      latLng: new LatLng(balloon.Location.Latitude, balloon.Location.Longitude),
      marker: markerClickData.data,
      point: markerClickData.point,
      status: balloon.CurrentState,
      statusDescription: balloon.CurrentStateDescription,
      stopId: balloon.StopId,
      timestamp: balloon.TimeStamp,
      type: balloon.type,
      vehicleName: balloon.VehicleLabel
    };
  }

  private _reverseGeocodePlotLocation(plot: IReplayPlotData): Observable<string> {
    const reverseGeocodeRequest: IReverseGeocodeRequest = {
      key: plot.id.toString(),
      data: {
        Latitude: plot.location.Latitude,
        Longitude: plot.location.Longitude
      }
    };
    return this._addressResolutionHttpService.getReverseGeocode([reverseGeocodeRequest]).pipe(
      delayedRetry(),
      catchError(() => of(null)),
      filter(response => !isElementNull(response)),
      map(result => result[0].fulladdress)
    );
  }

  private _getPolylineBalloonOffset(polylineTrigger: EPolylineHoverTrigger): ISize {
    const offset: ISize = {
      height: 5,
      width: 5
    };
    if (polylineTrigger === EPolylineHoverTrigger.MultidayCard) {
      offset.height = 15;
      offset.width = 15;
    }

    return offset;
  }

  private _getAssetBalloonData(balloon: IAssetBalloon): Observable<IAssetBalloon> {
    return this._balloonService.getMapBalloon(`${this._balloonRequestTypeMap.get(EPlotType.asset)}_${balloon.id}`).pipe(
      delayedRetry(),
      map(balloonResponse => {
        balloon.remainingBatteryPercentage = balloonResponse?.VehicleEcm?.BatteryLifetimePercentage;
        return balloon;
      }),
      catchError(() => of(balloon))
    );
  }

  private _getReplayBalloonData(
    markerClickData: ICustomClickEventData<MarkerInfo>,
    segment: IReplayJourneySegment,
    plotData: IReplayPlotData,
    userSpeedingThreshold: number,
    isVideoAllowed: boolean
  ): Observable<IReplayBalloon> {
    const videoForPlot = isVideoAllowed ? plotData.video : null;

    return combineLatest([
      of({ markerClickData, segment, plotData, userSpeedingThreshold }),
      isStringNullOrEmpty(plotData.address) ? this._reverseGeocodePlotLocation(plotData) : of(plotData.address),
      videoForPlot
        ? this._videoEventHttpService.getVideoDetails(videoForPlot.key).pipe(
            startWith(null as IVideoEventDetails[]),
            delayedRetry(),
            map(videoDetails => videoDetails?.find(video => video.channel === EVideoChannel.FrontFacing)),
            catchError(() => of(null as IVideoEventDetails))
          )
        : of(null as IVideoEventDetails)
    ]).pipe(
      map(([balloonData, address, videoDetails]) => ({
        id: balloonData.markerClickData.data.id,
        latLng: balloonData.markerClickData.data.latLng,
        marker: balloonData.markerClickData.data,
        type: balloonData.markerClickData.data.type,
        point: balloonData.markerClickData.point,
        address: address,
        distanceKms: balloonData.segment.distanceKms,
        durationSeconds: balloonData.segment.durationSeconds,
        segmentStatus: balloonData.segment.status,
        startTime: balloonData.plotData.time,
        speed: balloonData.plotData.speed,
        postedSpeedLimit: balloonData.plotData.postedSpeedLimit,
        harshDrivingType: ReplayMappingUtilities.getHarshDrivingEventTypeFromPlot(balloonData.plotData, balloonData.userSpeedingThreshold),
        plotData: balloonData.plotData,
        hasVideo: !isElementNull(videoForPlot),
        videoDetails: {
          details: videoDetails,
          eventId: videoForPlot?.id,
          thumbnailUrl: videoForPlot?.thumbnailUrl
        }
      }))
    );
  }
}
