/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import { IAppointmentResponse } from '../../core/models';
import { DemoDtoMappingService, DemoDataResolverService } from '../services';
import { EDemoAppointmentType } from '../models';

@Injectable()
export class SchedulerHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly _demoDtoMappingService: DemoDtoMappingService,
    private readonly _router: Router,
    private readonly _demoDataResolver: DemoDataResolverService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.match(/\/technicians\/\d+\/visible-appointments/i)) {
      if (!isStringNullOrEmpty(this._router.url) && this._router.url.match(/\?vehicleId=\d+/i)) {
        const params = req.url.replace('/visible-appointments', '').match(/\d+$/);
        return this._getAppointments(params[0]);
      } else {
        return of(new HttpResponse({ body: [], status: 200 }));
      }
    }
    return next.handle(req);
  }

  private _getAppointments(driverId: string): Observable<HttpResponse<IAppointmentResponse[]>> {
    return this._demoDataResolver.isReady$.pipe(
      map(() => this._demoDataResolver.initialDemoApppointmentData),
      map(appointments =>
        appointments.filter(
          appointment =>
            appointment.demoAppointmentType === EDemoAppointmentType.ready &&
            appointment.technicianId === +driverId &&
            appointment.showInBalloon
        )
      ),
      map(appointments => appointments.map(appointment => this._demoDtoMappingService.demoAppointmentToAppointmentResponse(appointment))),
      map(appointments => new HttpResponse({ body: appointments, status: 200 }))
    );
  }
}
