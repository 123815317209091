/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import {
  LatLng,
  ILatLng,
  MarkerInfo,
  ILatLngBounds,
  IPolylineInfo,
  IPolylineHoverParams,
  EStartLocationSource
} from '../../models';

export enum EMapsActions {
  ShowStartingLocation = '[Maps] Show Starting Location',
  UpdateMapCenter = '[Maps] Update Map center',
  MapBoundsUpdated = '[Maps] Map Bounds Updated',
  ChangeMapBounds = '[Maps] Change Map Bounds',
  UpdateIsStreetViewOpen = '[Maps] Update Is Street View Open',
  UpdateStreetViewLocation = '[Maps] Update Street View Location',
  PolylineStartHover = '[Maps] Polyline Start Hover',
  PolylineEndHover = '[Maps] Polyline End Hover',
  PolylineClicked = '[Maps] Polyline Clicked',
  ZoomLevelChanged = '[Maps] Zoom Level Changed',
  GetMapBoundsFromUserPlots = '[Maps] Get Map Bounds From User Plots',
  GetMapBoundsFromUserPlotsSuccess = '[Maps] Get Map Bounds From User Plots Success',
  HighlightMarker = '[Maps] Highlight marker',
  HighlightMarkerSuccess = '[Maps] Highlight Marker Success',
  RemoveMarkerHighlight = '[Maps] Remove Marker Highlight',
  SetSelectedMarker = '[Maps] Set Selected Marker',
  SetSelectedMarkerSuccess = '[Maps] Set Selected Marker Success',
  RemoveSelectedMarker = '[Maps] Remove Selected Marker'
}

export class ShowStartingLocation implements Action {
  public readonly type = EMapsActions.ShowStartingLocation;

  constructor(public payload: EStartLocationSource) {}
}

export class UpdateMapCenter implements Action {
  public readonly type = EMapsActions.UpdateMapCenter;

  constructor(public payload: LatLng) {}
}

export class MapBoundsUpdated implements Action {
  public readonly type = EMapsActions.MapBoundsUpdated;

  constructor(public payload: ILatLngBounds) {}
}

export class ChangeMapBounds implements Action {
  public readonly type = EMapsActions.ChangeMapBounds;

  constructor(public payload: ILatLng[]) {}
}

export class UpdateIsStreetViewOpen implements Action {
  public readonly type = EMapsActions.UpdateIsStreetViewOpen;

  constructor(public payload: boolean) {}
}

export class UpdateStreetViewLocation implements Action {
  public readonly type = EMapsActions.UpdateStreetViewLocation;

  constructor(public payload: ILatLng) {}
}

export class PolylineStartHover implements Action {
  public readonly type = EMapsActions.PolylineStartHover;

  constructor(public payload: IPolylineHoverParams) {}
}

export class PolylineEndHover implements Action {
  public readonly type = EMapsActions.PolylineEndHover;
}

export class PolylineClicked implements Action {
  public readonly type = EMapsActions.PolylineClicked;

  constructor(public payload: IPolylineInfo) {}
}

export class ZoomLevelChanged implements Action {
  public readonly type = EMapsActions.ZoomLevelChanged;

  constructor(public payload: number) {}
}

export class GetMapBoundsFromUserPlots implements Action {
  public readonly type = EMapsActions.GetMapBoundsFromUserPlots;
}

export class GetMapBoundsFromUserPlotsSuccess implements Action {
  public readonly type = EMapsActions.GetMapBoundsFromUserPlotsSuccess;

  constructor(public payload: ILatLng[]) {}
}

export class SetSelectedMarker implements Action {
  public readonly type = EMapsActions.SetSelectedMarker;

  constructor(public payload: number) {}
}

export class SetSelectedMarkerSuccess implements Action {
  public readonly type = EMapsActions.SetSelectedMarkerSuccess;

  constructor(public payload: MarkerInfo) {}
}

export class RemoveSelectedMarker implements Action {
  public readonly type = EMapsActions.RemoveSelectedMarker;
}

export class HighlightMarker implements Action {
  public readonly type = EMapsActions.HighlightMarker;

  constructor(public payload: number) {}
}

export class HighlightMarkerSuccess implements Action {
  public readonly type = EMapsActions.HighlightMarkerSuccess;

  constructor(public payload: MarkerInfo) {}
}

export class RemoveMarkerHighlight implements Action {
  public readonly type = EMapsActions.RemoveMarkerHighlight;
}

export type MapsActions =
  | ShowStartingLocation
  | UpdateMapCenter
  | MapBoundsUpdated
  | ChangeMapBounds
  | UpdateIsStreetViewOpen
  | UpdateStreetViewLocation
  | PolylineStartHover
  | PolylineEndHover
  | PolylineClicked
  | ZoomLevelChanged
  | GetMapBoundsFromUserPlots
  | GetMapBoundsFromUserPlotsSuccess
  | SetSelectedMarker
  | SetSelectedMarkerSuccess
  | RemoveSelectedMarker
  | HighlightMarker
  | HighlightMarkerSuccess
  | RemoveMarkerHighlight;
