/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, map, switchMapTo } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { IApiResponseDto, IMapBalloonResponse, ILabel } from '../../core/models';
import { IAppState } from '../../core/store/state';
import { getVehiclePlots } from '../../core/store/selectors';
import { DemoDtoMappingService, DemoDataResolverService } from '../services';

@Injectable()
export class BalloonHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly _store: Store<IAppState>,
    private readonly _demoDtoMappingService: DemoDtoMappingService,
    private readonly _demoDataResolver: DemoDataResolverService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.endsWith('/MapBalloon')) {
      const id = +req.body.BalloonIds.substring(2);
      return this._demoDataResolver.isReady$.pipe(
        switchMapTo(this._store),
        select(getVehiclePlots),
        take(1),
        map(plots => plots.find(plot => plot.id === id)),
        map(plot =>
          this._demoDtoMappingService.vehiclePlotToMapBalloonResponse(
            plot,
            this._demoDataResolver.initialAppStateData.userMapSettings.userSettings.SpeedUnits
          )
        ),
        map(balloon => <IApiResponseDto<IMapBalloonResponse>>{ Data: balloon, Message: null }),
        map(
          apiReponseDto => new HttpResponse<IApiResponseDto<IMapBalloonResponse>>({ body: apiReponseDto, status: 200 })
        )
      );
    } else if (req.url.endsWith('/LoadLabel/')) {
      const idsEntityTypesString: string[] = req.body.EntityIds.split(',');

      const idsMap: { [index: number]: number } = {};
      idsEntityTypesString.forEach(id => (idsMap[+id.substring(2)] = +id.substring(0, 1)));

      return this._store.pipe(
        select(getVehiclePlots),
        take(1),
        map(plots => plots.filter(plot => idsMap[plot.id])),
        map(plots => plots.map(plot => this._demoDtoMappingService.vehiclePlotToLabel(plot, idsMap[plot.id]))),
        map(
          labelResponse => new HttpResponse<ILabel[]>({ body: labelResponse, status: 200 })
        )
      );
    }

    return next.handle(req);
  }
}
