/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export class Position {
  top: number;
  right: number;
  bottom: number;
  left: number;

  constructor(top?: number, right?: number, bottom?: number, left?: number) {
    this.top = top ? top : 0;
    this.right = right ? right : 0;
    this.bottom = bottom ? bottom : 0;
    this.left = left ? left : 0;
  }

  static equals(x: Position, y: Position) {
    return x.top === y.top && x.right === y.right && x.bottom === y.bottom && x.left === y.left;
  }
}
