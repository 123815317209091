/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ECountry {
  AUS = 'AUS',
  CAN = 'CAN',
  FRA = 'FRA',
  GBR = 'GBR',
  IRL = 'IRL',
  NLD = 'NLD',
  USA = 'USA'
}
