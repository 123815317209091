/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';

import { ButtonDirection } from './button-direction';
import { FmDocumentRefService } from '@fleetmatics/ui.utilities';

@Component({
  selector: 'fm-carousel',
  templateUrl: './carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements AfterContentInit  {
  constructor(private readonly _documentService: FmDocumentRefService) {}

  ngAfterContentInit() {
    this.initCarousel(24);
  }

  initCarousel(cardMarginLeft: number = 0, cardMarginRight: number = 0, activeClassName = 'active', noOfActiveCardCount = 1) {
    const carousel = this._documentService.nativeDocument.querySelector('[data-target="carousel"]') as HTMLElement;
    const card = carousel.querySelector('[data-target="carousel-card"]') as HTMLElement;
    const cards = carousel.querySelectorAll('[data-target="carousel-card"]') as NodeListOf<HTMLElement>;
    const cardsCount = cards.length;

    const leftButton = document.querySelector('[data-action="slideLeft"]') as HTMLElement;
    const rightButton = document.querySelector('[data-action="slideRight"]') as HTMLElement;

    const cardWidth = card.offsetWidth;
    let offset = 0;
    let activeCardIndex = 0;
    let initialTouchDownPositionX: number;
    let initialTouchDownPositionY: number;

    const that = this;
    rightButton.addEventListener('click', function() {
      if (activeCardIndex < cardsCount - 1) {
        if (activeCardIndex === 0) {
          offset -= 0.8 * cardWidth + cardMarginLeft;
        } else {
          offset -= cardWidth + cardMarginLeft;
        }
        carousel.style.transform = `translateX(${offset}px)`;
        that.activateCard(activeCardIndex + 1, ButtonDirection.right, cards, activeClassName, noOfActiveCardCount);
        if (cardsCount > noOfActiveCardCount) {
          that.activateButton(leftButton);
        }
        activeCardIndex++;
        if (activeCardIndex === cardsCount - 1) {
          that.deactivateButton(rightButton);
        }
      }
    });

    leftButton.addEventListener('click', function() {
      if (activeCardIndex > 0) {
        if (activeCardIndex === 1) {
          offset += 0.8 * cardWidth + cardMarginLeft;
        } else {
          offset += cardWidth + cardMarginLeft;
        }

        carousel.style.transform = `translateX(${offset}px)`;
        that.activateCard(activeCardIndex - 1, ButtonDirection.left, cards, activeClassName, noOfActiveCardCount);
        if (cardsCount > noOfActiveCardCount) {
          that.activateButton(rightButton);
        }
        activeCardIndex--;
        if (activeCardIndex === 0) {
          that.deactivateButton(leftButton);
        }
      }
    });

    that.activateButton(rightButton);
    cards.item(activeCardIndex).classList.add(activeClassName);

    for (let i = 0; i < cards.length; i++) {
      cards[i].addEventListener('touchstart', function(event: TouchEvent) {
        initialTouchDownPositionX = event.touches[0].clientX;
        initialTouchDownPositionY = event.touches[0].clientY;
      });
      cards[i].addEventListener('touchmove', function(event: TouchEvent) {
        if (!initialTouchDownPositionX || !initialTouchDownPositionY) {
          return;
        }

        const clientXDiff = initialTouchDownPositionX - event.touches[0].clientX;
        const clientYDiff = initialTouchDownPositionY - event.touches[0].clientY;

        if (Math.abs(clientXDiff) > Math.abs(clientYDiff)) {
          if (clientXDiff > 0) {
            if (activeCardIndex < cardsCount - 1) {
              if (activeCardIndex === 0) {
                offset -= 0.8 * cardWidth + cardMarginLeft;
              } else {
                offset -= cardWidth + cardMarginLeft;
              }
              carousel.style.transform = `translateX(${offset}px)`;
              that.activateCard(activeCardIndex + 1, ButtonDirection.right, cards, activeClassName, noOfActiveCardCount);
              if (cardsCount > noOfActiveCardCount) {
                that.activateButton(leftButton);
              }
              activeCardIndex++;
              if (activeCardIndex === cardsCount - 1) {
                that.deactivateButton(rightButton);
              }
            }
          } else {
            if (activeCardIndex > 0) {
              if (activeCardIndex === 1) {
                offset += 0.8 * cardWidth + cardMarginLeft;
              } else {
                offset += cardWidth + cardMarginLeft;
              }

              carousel.style.transform = `translateX(${offset}px)`;
              that.activateCard(activeCardIndex - 1, ButtonDirection.left, cards, activeClassName, noOfActiveCardCount);
              if (cardsCount > noOfActiveCardCount) {
                that.activateButton(rightButton);
              }
              activeCardIndex--;
              if (activeCardIndex === 0) {
                that.deactivateButton(leftButton);
              }
            }
          }
        }

        initialTouchDownPositionX = null;
        initialTouchDownPositionY = null;
      });
    }
  }

  activateButton(buttonHtml: HTMLElement, activeClassName: string = 'active'): void {
    buttonHtml.classList.add(activeClassName);
  }

  deactivateButton(buttonHtml: HTMLElement, activeClassName: string = 'active'): void {
    buttonHtml.classList.remove(activeClassName);
  }

  activateCard(
    cardIndex: number,
    direction: ButtonDirection,
    currentCardSet: NodeListOf<HTMLElement>,
    activeClassName: string,
    activeCount = 1
  ): void {
    const cardToActivate = currentCardSet.item(cardIndex);
    const cardToDeActivate =
      direction === ButtonDirection.right ? currentCardSet.item(cardIndex - activeCount) : currentCardSet.item(cardIndex + activeCount);
    cardToActivate.classList.add(activeClassName);
    cardToDeActivate.classList.remove(activeClassName);
  }
}
