/*!
 * Copyright © 2020. Verizon Connect. All rights reserved.
 */

export enum EVehicleFuelType {
  Unknown = 0,
  Unleaded = 1,
  Diesel = 2,
  Electric = 3
}
