/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EWorkOrderFilterOptions {
  None = 0,
  ShowOnMap = 1,
  ShowOnReplay = 2,
  Assigned = 4,
  Unassigned = 8,
  IncludeCompleted = 16
}
