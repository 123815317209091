/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Observable, throwError, of } from 'rxjs';
import { retryWhen, delay, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export const DELAYED_RETRY_OPERATOR_DEFAULT_DELAY = 5000;
export const DELAYED_RETRY_OPERATOR_DEFAULT_MAX_RETRIES = 3;
const STATUS_CODES_NOT_TO_RETRY = [400, 401, 402, 403, 404];

export const delayedRetry = (delayMs = DELAYED_RETRY_OPERATOR_DEFAULT_DELAY, maxRetry = DELAYED_RETRY_OPERATOR_DEFAULT_MAX_RETRIES) => {
  let retries = maxRetry;

  return <T>(src: Observable<T>): Observable<T> =>
    src.pipe(
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          mergeMap(error => {
            const shouldNotRetry = error instanceof HttpErrorResponse && STATUS_CODES_NOT_TO_RETRY.includes(error.status);

            if (shouldNotRetry) {
              return throwError(error);
            }

            if (retries > 0) {
              retries--;
              return of(error).pipe(delay(delayMs));
            }

            return throwError(error);
          })
        )
      )
    );
};
