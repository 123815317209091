/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ConfigActions, EConfigActions } from '../actions';
import { initialConfigState, IConfigState } from '../state';

export function configReducer(state = initialConfigState, action: ConfigActions): IConfigState {
  switch (action.type) {
    case EConfigActions.UpdateConfigs: {
      return {
        ...state,
        isWorkingTimeDirectiveEnabled: action.payload.isWorkingTimeDirectiveEnabled,
        isSchedulerEnabled: action.payload.isSchedulerEnabled
      };
    }
    default:
      return state;
  }
}
