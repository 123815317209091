/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ENodeType {
  Unknown = 0,
  Group,
  Vehicle,
  Driver,
  User,
  Asset,
  PoweredAsset
}
