/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

export class MomentUtilities {
  static isToday(input: Moment): boolean {
    return input.isSame(moment(), 'day');
  }

  static isSameDay(date1: Date, date2: Date, timezone: string): boolean {
    const dateMoment1 = MomentUtilities.getTimeZoneAdjusted(date1, timezone);
    const dateMoment2 = MomentUtilities.getTimeZoneAdjusted(date2, timezone);
    return dateMoment1.isSame(dateMoment2, 'day');
  }
  /*
    This will be used for changing the timezone on a moment object without changing the time date
    Example:
      Input: 2020/03/16 12:00:00+0000, America/Indianopolis
      Output: 2020/03.16 12:00:00-0500

    This differs from using moment .tz alone as this will adjust the date to be equivalent to the utc input
  */
  static changeTimezoneWithoutChangingTime(input: Moment, timezone: string): Moment {
    const correctedDate = input.clone().tz(timezone, true);
    return correctedDate;
  }

  static getUtcDateFromMoment(input: Moment): Date {
    const inputDate = input.clone().toDate();
    return new Date(
      Date.UTC(
        inputDate.getUTCFullYear(),
        inputDate.getUTCMonth(),
        inputDate.getUTCDate(),
        inputDate.getUTCHours(),
        inputDate.getUTCMinutes(),
        inputDate.getUTCSeconds(),
        inputDate.getUTCMilliseconds()
      )
    );
  }

  static getTimeZoneAdjusted(input: Date, timeZone: string): Moment {
    return moment(input).tz(timeZone);
  }
}
