/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { MapOptionsActions, EMapOptionsActions, ELayoutPreferencesActions, GetLayoutPreferencesSuccess } from '../actions';

import { initialMapOptionsState, IMapOptionsState } from '../state';

export function mapOptionsReducer(
  state = initialMapOptionsState,
  action: MapOptionsActions | GetLayoutPreferencesSuccess
): IMapOptionsState {
  switch (action.type) {
    case EMapOptionsActions.UpdateIsSuggestedGeofenceEnabled: {
      return {
        ...state,
        isSuggestedGeofencesEnabled: action.payload
      };
    }
    case EMapOptionsActions.UpdateIsGeofenceEnabled: {
      return {
        ...state,
        isGeofencesEnabled: action.payload
      };
    }
    case EMapOptionsActions.UpdateIsGarminStopsEnabled: {
      return {
        ...state,
        isGarminStopsEnabled: action.payload
      };
    }
    case EMapOptionsActions.UpdateIsWorkOrdersEnabled: {
      return {
        ...state,
        isWorkOrdersEnabled: action.payload
      };
    }
    case EMapOptionsActions.UpdateIsShowLabelsEnabled: {
      return {
        ...state,
        isShowLabelsEnabled: action.payload
      };
    }
    case EMapOptionsActions.UpdateVehicleStatusFilterType: {
      return {
        ...state,
        vehicleStatusFilterType: action.payload
      };
    }
    case ELayoutPreferencesActions.GetLayoutPreferencesSuccess: {
      return {
        ...state,
        isSuggestedGeofencesEnabled: action.payload.mapOptions.isSuggestedGeofencesEnabled,
        isGeofencesEnabled: action.payload.mapOptions.isGeofencesEnabled,
        isGarminStopsEnabled: action.payload.mapOptions.isGarminStopsEnabled,
        isWorkOrdersEnabled: action.payload.mapOptions.isWorkOrdersEnabled,
        isShowLabelsEnabled: action.payload.mapOptions.isShowLabelsEnabled,
        isShowTachoInformationEnabled: action.payload.mapOptions.isShowTachoInformationEnabled,
        isShowVehicleBalloonLinksEnabled: action.payload.mapOptions.isShowVehicleBalloonLinksEnabled
      };
    }
    case EMapOptionsActions.UpdateIsShowTachoInformationEnabled: {
      return {
        ...state,
        isShowTachoInformationEnabled: action.payload
      };
    }
    case EMapOptionsActions.UpdateIsShowVehicleBalloonLinksEnabled: {
      return {
        ...state,
        isShowVehicleBalloonLinksEnabled: action.payload
      };
    }
    case EMapOptionsActions.OpenMapOptions: {
      return {
        ...state,
        isMapOptionsOpened: true
      };
    }
    case EMapOptionsActions.CloseMapOptions: {
      return {
        ...state,
        isMapOptionsOpened: false
      };
    }
    case EMapOptionsActions.UpdateShouldShowClusteringOption: {
      return {
        ...state,
        shouldShowClusteringOption: action.payload
      };
    }
    default:
      return state;
  }
}
