/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EGarminStopStatusGroup {
  QUEUED = 1,
  RECEIVED = 2,
  READ = 3,
  EN_ROUTE = 4,
  COMPLETED = 5
}
