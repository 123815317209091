/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  HostBinding,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  AfterViewInit
} from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'fm-snackbar',
  templateUrl: './snackbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideIn', [
      state('in', style({ transform: 'translateY(0)' })),
      transition(':enter', [style({ transform: 'translateY(10px)' }), animate('192ms')]),
      transition(':leave', [animate('192ms', style({ transform: 'translateY(10px)' }))])
    ])
  ]
})
export class SnackbarComponent implements AfterViewInit {
  @Input()
  public message: string;

  @Input()
  public set messageTemplate(value: TemplateRef<any>) {
    this._isViewReady$
      .pipe(
        filter(isReady => isReady),
        take(1)
      )
      .subscribe(() => {
        this._messageContainer.createEmbeddedView(value);
      });
  }

  @Output() public closed = new EventEmitter<void>();

  @HostBinding('@slideIn') public animationType = 'in';
  @HostBinding('class.snackbar') public hostClass = true;

  @ViewChild('messageContainer', { read: ViewContainerRef })
  private readonly _messageContainer: ViewContainerRef;

  private readonly _isViewReadySubject = new BehaviorSubject(false);
  private readonly _isViewReady$: Observable<boolean>;

  constructor() {
    this._isViewReady$ = this._isViewReadySubject.asObservable();
  }

  public ngAfterViewInit(): void {
    this._isViewReadySubject.next(true);
  }

  public onCloseClick(): void {
    this.closed.emit();
  }
}
