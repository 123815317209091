/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IVehicleListPanelState } from '../state';
import { EVehicleSelectionMode } from '../../models';

export const getVehicleListPanelState = createFeatureSelector<IVehicleListPanelState>('vehicleListPanel');

export const getVehicleListPanel = createSelector(
  getVehicleListPanelState,
  (state: IVehicleListPanelState): IVehicleListPanelState => state
);

export const getVehicleListPanelScopingModalIsOpen = createSelector(
  getVehicleListPanel,
  (state: IVehicleListPanelState): boolean => state.scopingModal.isOpen
);

export const getVehicleListPanelScopingModalWarning = createSelector(
  getVehicleListPanel,
  (state: IVehicleListPanelState): string => state.scopingModal.warning
);

export const getVehicleSelectionMode = createSelector(
  getVehicleListPanel,
  (state: IVehicleListPanelState): EVehicleSelectionMode => state.selectionMode
);
