/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IVehicleTachoStatus } from 'tacho';

import { APP_CONFIG_TOKEN, IAppConfig } from '../../../config';
import { IApiResponseDto, IVehicleIsTachoEnabled } from '../../models';
import { IWorkingTimeDirectiveHttpService } from './working-time-directive-http.service.interface';

@Injectable({
  providedIn: 'root'
})
export class WorkingTimeDirectiveHttpService implements IWorkingTimeDirectiveHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG_TOKEN) _config: IAppConfig) {
    this._rootUrl = _config.endpoints.workingTimeDirectiveApiEndpoint;
  }

  public getIsTachoEnabledForVehicles(vehicleIds: number[]): Observable<IVehicleIsTachoEnabled[]> {
    return this._httpClient
      .post<IApiResponseDto<IVehicleIsTachoEnabled[]>>(`${this._rootUrl}/DriverWorkingTime/IsTachoEnabledForVehicles`, {
        VehicleIds: vehicleIds
      })
      .pipe(map(result => result.Data));
  }

  public getVehicleTachoStatus(vehicleId: string): Observable<IVehicleTachoStatus> {
    return this._httpClient
      .get<IApiResponseDto<IVehicleTachoStatus>>(`${this._rootUrl}/DriverWorkingTime/GetVehicleTachoStatus`, { params: { vehicleId } })
      .pipe(map((result: IApiResponseDto<IVehicleTachoStatus>) => result.Data));
  }

  public getVehiclesTachoStatus(vehicleIds: number[]): Observable<IVehicleTachoStatus[]> {
    return this._httpClient
      .post<IApiResponseDto<IVehicleTachoStatus[]>>(`${this._rootUrl}/DriverWorkingTime/GetVehiclesTachoStatus`, { VehicleIds: vehicleIds })
      .pipe(map(result => result.Data));
  }
}
