/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import {
  IVehiclePlot,
  EVehiclesSortType,
  ISignalStatusUpdate,
  ITowingVehicle,
  ISpeedViolationMessage,
  MarkerInfo,
  IVehiclePlotWebsubsResponse
} from '../../models';

export const enum EVehicleActions {
  GetSelectedVehicleIds = '[Vehicle] Get Selected Vehicle Ids',
  UpdateVehicles = '[Vehicle] Updated Vehicles',
  UpdateVehicleSpeed = '[Vehicle] Updated Vehicle Speed',
  UpdateVehicleSpeedSuccess = '[Vehicle] Updated Vehicle Speed Success',
  UpdateVehiclesSuccess = '[Vehicle] Updated Vehicles Success',
  GetVehiclesSorted = '[Vehicle] Get Vehicles Sorted',
  GetVehiclesSortedSuccess = '[Vehicle] Get Vehicles Sorted Success',
  UpdateVehiclesSortType = '[Vehicle] Updated Vehicles Sort Type',
  UpdateVehiclesStatus = '[Vehicle] Updated Vehicles Status',
  UpdateVehiclesStatusSuccess = '[Vehicle] Updated Vehicles Status Success',
  AddTowingVehicles = '[Vehicle] Add Towing Vehicles',
  UpdateTowingVehicle = '[Vehicle] Update Towing Vehicle',
  UpdateTowingVehicleSuccess = '[Vehicle] Update Towing Vehicle Success',
  RemoveTowingVehicles = '[Vehicle] Remove Towing Vehicles',
  UpdatePlotsInBounds = '[Vehicle] Update plots in bounds',
  GetAccessibleVehiclesCount = '[Vehicle] Get Accessible Vehicles Count',
  GetAccessibleVehiclesCountSuccess = '[Vehicle] Get Accessible Vehicles Count Success',
  StartVehiclesPolling = '[Vehicle] Start Vehicles Polling',
  StopVehiclesPolling = '[Vehicle] Stop Vehicles Polling'
}

export class GetSelectedVehicleIds implements Action {
  public readonly type = EVehicleActions.GetSelectedVehicleIds;
}

export class UpdateVehicles implements Action {
  public readonly type = EVehicleActions.UpdateVehicles;

  constructor(public payload: IVehiclePlotWebsubsResponse) {}
}

export class StartVehiclesPolling implements Action {
  public readonly type = EVehicleActions.StartVehiclesPolling;
}

export class StopVehiclesPolling implements Action {
  public readonly type = EVehicleActions.StopVehiclesPolling;
}

export class UpdateVehiclesSuccess implements Action {
  public readonly type = EVehicleActions.UpdateVehiclesSuccess;

  constructor(public payload: IVehiclePlot[]) {}
}

export class UpdatePlotsInBounds implements Action {
  public readonly type = EVehicleActions.UpdatePlotsInBounds;

  constructor(public payload: MarkerInfo[]) {}
}

export class UpdateVehicleSpeed implements Action {
  public readonly type = EVehicleActions.UpdateVehicleSpeed;

  constructor(public payload: ISpeedViolationMessage) {}
}

export class UpdateVehicleSpeedSuccess implements Action {
  public readonly type = EVehicleActions.UpdateVehicleSpeedSuccess;

  constructor(public payload: IVehiclePlot[]) {}
}

export class UpdateVehiclesStatus implements Action {
  public readonly type = EVehicleActions.UpdateVehiclesStatus;

  constructor(public payload: ISignalStatusUpdate[]) {}
}

export class UpdateVehiclesStatusSuccess implements Action {
  public readonly type = EVehicleActions.UpdateVehiclesStatusSuccess;

  constructor(public payload: IVehiclePlot[]) {}
}

export class GetVehiclesSorted implements Action {
  public readonly type = EVehicleActions.GetVehiclesSorted;

  constructor(public payload: number[]) {}
}

export class GetVehiclesSortedSuccess implements Action {
  public readonly type = EVehicleActions.GetVehiclesSortedSuccess;

  constructor(public payload: number[]) {}
}

export class UpdateVehiclesSortType implements Action {
  public readonly type = EVehicleActions.UpdateVehiclesSortType;

  constructor(public payload: EVehiclesSortType) {}
}

export class AddTowingVehicles implements Action {
  public readonly type = EVehicleActions.AddTowingVehicles;

  constructor(public payload: ITowingVehicle[]) {}
}
export class UpdateTowingVehicle implements Action {
  public readonly type = EVehicleActions.UpdateTowingVehicle;

  constructor(public payload: ITowingVehicle) {}
}

export class RemoveTowingVehicles implements Action {
  public readonly type = EVehicleActions.RemoveTowingVehicles;

  constructor(public payload: ITowingVehicle[]) {}
}

export class GetAccessibleVehiclesCount implements Action {
  public readonly type = EVehicleActions.GetAccessibleVehiclesCount;
}

export class GetAccessibleVehiclesCountSuccess implements Action {
  public readonly type = EVehicleActions.GetAccessibleVehiclesCountSuccess;

  constructor(public payload: number) {}
}

export type VehicleActions =
  | GetSelectedVehicleIds
  | UpdateVehicles
  | UpdateVehiclesSuccess
  | GetVehiclesSorted
  | GetVehiclesSortedSuccess
  | UpdateVehiclesSortType
  | UpdateVehiclesStatus
  | UpdateVehiclesStatusSuccess
  | AddTowingVehicles
  | UpdateTowingVehicle
  | RemoveTowingVehicles
  | GetAccessibleVehiclesCount
  | GetAccessibleVehiclesCountSuccess
  | UpdateVehicleSpeed
  | UpdateVehicleSpeedSuccess
  | UpdatePlotsInBounds
  | StartVehiclesPolling
  | StopVehiclesPolling;
