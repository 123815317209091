/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EFeatureId {
  CustomBalloons = 1,
  DrivingStyle = 7,
  DriverApp = 53,
  MisLive = 69,
  RealTimeTachoDate = 78,
  Ecm = 83,
  DeviceStatusChange = 90,
  AssetTracking = 93,
  VideoExperience = 100,
  RoadSideAssistance = 104,
  VideoAccess = 112
}
