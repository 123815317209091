/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    pan: { direction: typeof Hammer !== 'undefined' ? Hammer.DIRECTION_VERTICAL : 0 }
  };
}
