/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError, filter } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { createEffect, ofType, Actions } from '@ngrx/effects';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { CustomerMetadataHttpService, NotificationHttpService } from '../../services';
import {
  EDriverMessageActions,
  SendEmailToDriver,
  SendEmailToDriverSuccess,
  GetSmsOptInStatusSuccess,
  SendSmsToDriver,
  SendSmsToDriverSuccess,
  SendSmsInvitationToDriver,
  SendSmsInvitationToDriverSuccess,
  GetSmsOptInStatus,
  OpenDriverMessageModal,
  OpenDriverMessageModalSuccess
} from '../actions';
import {
  IDriverDetails,
  IDriverEmailMessage,
  IDriverDetailsParams,
  ISmsOptInStatus,
  IDriverSmsMessage,
  ISmsInvitation
} from '../../models';
import { delayedRetry } from '../../operators';

@Injectable()
export class DriverMessageEffects {

  public openDriverMessageModal$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<OpenDriverMessageModal>(EDriverMessageActions.OpenDriverMessageModal),
    map(action => action.payload),
    switchMap((driverDetailsParams: IDriverDetailsParams) =>
      this._customerMetadataHttpService.getDriverDetails(driverDetailsParams.driverId).pipe(
        delayedRetry(),
        map((driverDetails: IDriverDetails) => <[IDriverDetails, IDriverDetailsParams]>[driverDetails, driverDetailsParams]),
        catchError(() => of(null).pipe(filter(() => false)))
      )
    ),
    switchMap(([driverDetails, driverDetailsParams]: [IDriverDetails, IDriverDetailsParams]) => {
      if (driverDetailsParams.isTextMessagingEnabled) {
        return [new OpenDriverMessageModalSuccess(driverDetails), new GetSmsOptInStatus(driverDetailsParams.driverId)];
      }
      return [new OpenDriverMessageModalSuccess(driverDetails)];
    })
  ));

  public getSmsOptInStatus$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetSmsOptInStatus>(EDriverMessageActions.GetSmsOptInStatus),
    map(action => action.payload),
    switchMap((driverId: number) =>
      this._notificationHttpService.getDriverSmsOptInStatus(driverId).pipe(
        delayedRetry(),
        catchError(() => of(null))
      )
    ),
    filter(smsOptInStatus => !isElementNull(smsOptInStatus)),
    map((smsOptInStatus: ISmsOptInStatus) => new GetSmsOptInStatusSuccess(smsOptInStatus))
  ));

  public sendEmailToDriver$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<SendEmailToDriver>(EDriverMessageActions.SendEmailToDriver),
    map(action => action.payload),
    switchMap((message: IDriverEmailMessage) =>
      this._customerMetadataHttpService.sendEmailToDriver(message).pipe(
        delayedRetry(),
        catchError(() => of(null))
      )
    ),
    filter(result => !isElementNull(result)),
    map((result: boolean) => new SendEmailToDriverSuccess(result))
  ));

  public sendSmsInvitationToDriver$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<SendSmsInvitationToDriver>(EDriverMessageActions.SendSmsInvitationToDriver),
    map(action => action.payload),
    switchMap((driverId: number) => {
      return this._notificationHttpService.sendSmsInvitationToDriver(driverId).pipe(
        delayedRetry(),
        map((smsInvitation: ISmsInvitation) => <[ISmsInvitation, number]>[smsInvitation, driverId]),
        catchError(() => of(null).pipe(filter(() => false)))
      );
    }),
    switchMap(([smsInvitation, driverId]: [ISmsInvitation, number]) => {
      if (smsInvitation.Invited) {
        return [new SendSmsInvitationToDriverSuccess(smsInvitation), new GetSmsOptInStatus(driverId)];
      }
      return [new SendSmsInvitationToDriverSuccess(smsInvitation)];
    })
  ));

  public sendSmsToDriver$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<SendSmsToDriver>(EDriverMessageActions.SendSmsToDriver),
    map(action => action.payload),
    switchMap((message: IDriverSmsMessage) =>
      this._notificationHttpService.sendSmsToDriver(message).pipe(
        delayedRetry(),
        catchError(() => of(null))
      )
    ),
    filter(result => !isElementNull(result)),
    map((result: boolean) => new SendSmsToDriverSuccess(result))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _customerMetadataHttpService: CustomerMetadataHttpService,
    private readonly _notificationHttpService: NotificationHttpService
  ) {}
}
