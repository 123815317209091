/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

import { isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import { EDisplayDriverAs, EDisplayVehicleAs, ESpeedUnits, IUserSettings, IVehiclePlotWebsubsResponse } from '../../models';
import { EpochTimeUtilities } from '../../../../utils';
import { IPlotFormattingService } from './plot-formatting.service.interface';
import { SpeedConvertPipe } from '../../../shared/pipes';

@Injectable({
  providedIn: 'root'
})
export class PlotFormattingService implements IPlotFormattingService {
  private readonly _defaultTimezone = 'America/New_York';
  private readonly _defaultLanuage = 'en-US';

  // return last updated string
  formatLastUpdateForDisplay(ticks: number, userSettings: IUserSettings): string {
    // ticks to date
    const lastUpdateDateTime = EpochTimeUtilities.fromDotNetTicksToDate(ticks);
    const localizedUpdate = this._applyTimezoneAndLocale(lastUpdateDateTime, userSettings);
    return this._formatMomentObject(localizedUpdate);
  }

  getLastUpdateIsToday(ticks: number, timeZoneAndroidId: string): boolean {
    const currentDay = moment().tz(timeZoneAndroidId);
    const dateToCompare = moment(EpochTimeUtilities.fromDotNetTicksToDate(ticks)).tz(timeZoneAndroidId);

    return currentDay.isSame(dateToCompare, 'day');
  }

  formatDriverName(vehiclePlot: IVehiclePlotWebsubsResponse, displayDriverAs: EDisplayDriverAs): string {
    displayDriverAs = displayDriverAs ?? EDisplayDriverAs.NotSet;
    let driverName: string;
    switch (displayDriverAs) {
      case EDisplayDriverAs.DriverSurnameFirstName:
        driverName = `${vehiclePlot.driverLastName}, ${vehiclePlot.driverFirstName}`;
        break;
      case EDisplayDriverAs.DriverNumber:
        driverName = isStringNullOrEmpty(vehiclePlot.driverNumber)
          ? `${vehiclePlot.driverFirstName} ${vehiclePlot.driverLastName}`
          : vehiclePlot.driverNumber;
        break;
      case EDisplayDriverAs.NotSet:
      case EDisplayDriverAs.DriverFirstNameSurname:
        driverName = `${vehiclePlot.driverFirstName} ${vehiclePlot.driverLastName}`;
        break;
    }
    return driverName;
  }

  formatVehicleName(vehiclePlot: IVehiclePlotWebsubsResponse, displayVehicleAs: EDisplayVehicleAs): string {
    let vehicleName: string;

    switch (displayVehicleAs) {
      case EDisplayVehicleAs.VehicleRegistrationNumber:
        vehicleName = !isStringNullOrEmpty(vehiclePlot.registrationNumber) ? vehiclePlot.registrationNumber : vehiclePlot.label;
        break;
      case EDisplayVehicleAs.VehicleNumber:
        vehicleName = !isStringNullOrEmpty(vehiclePlot.clientVehicleId) ? vehiclePlot.clientVehicleId : vehiclePlot.label;
        break;
      default:
        vehicleName = vehiclePlot.label;
        break;
    }

    return vehicleName;
  }

  formatSpeed(speedKmh: number, speedUnits: ESpeedUnits): number {
    return Math.round(new SpeedConvertPipe().transform(speedKmh, speedUnits));
  }

  // date to timezone/locale -> moment object
  private _applyTimezoneAndLocale(lastUpdate: Date | Moment, userSettings: IUserSettings): Moment {
    const timeZone = userSettings?.TimeZoneAndroidId ?? moment.tz.guess() ?? this._defaultTimezone;
    const language = userSettings?.Language ?? window?.navigator?.language ?? this._defaultLanuage;
    const momentObject = moment.tz(lastUpdate, timeZone).locale(language);
    return momentObject;
  }

  // format moment object
  private _formatMomentObject(lastUpdate: Moment): string {
    // if today: format -> LT
    if (moment().isSame(lastUpdate, 'day')) {
      return `${lastUpdate.format('LT')}`;
    }
    // else: format -> DD/MM LT
    return `${lastUpdate.format('L').replace(`/${lastUpdate.format('Y')}`, '')} ${lastUpdate.format('LT')}`;
  }
}
