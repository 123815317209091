<fm-search-light [entityTypes]="entityTypes"
  [input]="searchLightFabInput"
  [options]="options"
  [panel]="searchLightFabPanel"></fm-search-light>

<fm-search-light-fab-input #searchLightFabInput="fmSearchLightFabInput"
  ngModel
  [containerWidth]="containerWidth"
  [placeholder]="placeholder"
  [tooltipMessage]="tooltipMessage"
  (closed)="onClose($event)"
  (opened)="onOpen()">
</fm-search-light-fab-input>

<fm-search-light-panel #searchLightFabPanel="fmSearchLightPanel"
  [containerWidth]="containerWidth"
  [errorMessage]="errorMessage"
  [minCharsMessage]="minCharsMessage"
  [noResultMessage]="noResultMessage"
  (resultSelected)="onModelChanged($event)"></fm-search-light-panel>