<ng-template #shareDemoModalContent>
  <div class="header">
    <div class="verizon-connect-img"></div>
    <div class="title"
      i18n="@@S_DEMO_FULL">Check out the full demo experience</div>
  </div>
  <div class="body">
    <div class="demo-desktop"></div>
    <div class="content"
        i18n="@@S_DEMO_SHARE">
        For the full Reveal Starter experience (including Reports, Alerts, Places and Replay) check out the demo on your desktop.
      </div>
  </div>

  <div *ngIf="hasNavigatorShare; else copyToClipboard"
    class="footer">
    <button class="btn"
      a7s-id="demo-mobile-try-desktop-send-demo-link"
      (click)="shareDemoLink()"
      i18n="@@S_DEMO_SEND">Send demo link</button>
  </div>

  <ng-template #copyToClipboard>
    <div class="footer">
      <button [hidden]="isCopyAvailable"
        id="copy-demo-link"
        class="btn"
        a7s-id="demo-mobile-try-desktop-copy-demo-link"
        (click)="copyDemoLink()"
        i18n="@@S_DEMO_COPY">Copy demo link</button>

      <div [hidden]="!isCopyAvailable"
        id="copied-to-clipboard">
        <span i18n="@@S_DEMO_COPIED">Copied to clipboard</span>
        <div class="guide-completed"></div>
      </div>
    </div>
  </ng-template>
</ng-template>
