/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

import { filter, take } from 'rxjs/operators';

import { IVehiclePlot, EVehicleStatusType, ILatLng, EPlotIcon, ESpeedUnits } from '../../core/models';
import { ILocationAddress } from '../models';
import { DemoDataResolverService } from './demo-data-resolver';
import { SpeedConversionUtilities } from '../../../utils';

const VTU_UPDATE_INTERVAL_MS = 30000;

const MaxSpeed = 60,
  MinSpeed = 10,
  MinDistance = 150,
  EARTH_RADIUS = 6378137;
const SpeedMphToRadius = ((1609.34 / 3600) * VTU_UPDATE_INTERVAL_MS) / 1000; // meters / mile / hour * plot update time

@Injectable()
export class PlotSimulatorService {
  private _geocodedData: ILocationAddress[];

  getUpdatedPlot(inputPlot: IVehiclePlot, keepStatus: boolean): IVehiclePlot {
    let newStatus = this._getRandomVehicleStatus();
    if (keepStatus) {
      newStatus = inputPlot.status;
    }

    let returnedPoint;
    if (newStatus === EVehicleStatusType.Moving) {
      const newValues = this._getNewPlotValues(inputPlot);

      returnedPoint = this._updateMovingPlot(inputPlot, newValues.speed, newStatus, newValues.latLng, newValues.address);
    } else {
      returnedPoint = this._updateStaticPlot(inputPlot, newStatus);
    }
    return returnedPoint;
  }

  updatePlotTime(plot: IVehiclePlot): IVehiclePlot {
    const time = new Date();
    const ticks = this._getTicks(time);
    const updatedPlot: IVehiclePlot = {
      ...plot,
      ticks: ticks,
      time: time.toLocaleString(),
      timeUnits: time.toISOString(),
      lastUpdateForDisplay: this._formatLastUpdateForDisplay(time),
      lastUpdateIsToday: true
    };
    return updatedPlot;
  }

  constructor(private readonly _demoDataResolver: DemoDataResolverService) {
    this._demoDataResolver.isReady$
      .pipe(
        filter(isReady => isReady),
        take(1)
      )
      .subscribe(() => (this._geocodedData = this._demoDataResolver.locationAddresses));
  }

  private _getRandomVehicleStatus(): EVehicleStatusType {
    const u = Math.random();
    let status: EVehicleStatusType;
    if (u < 0.2) {
      status = EVehicleStatusType.Idle;
    } else if (u >= 0.2 && u < 0.8) {
      status = EVehicleStatusType.Moving;
    } else {
      status = EVehicleStatusType.Stopped;
    }
    return status;
  }

  private _movePlot(latLng: ILatLng): { latLng: ILatLng; speed: number } {
    const randomSpeedMph = Math.floor(Math.random() * (MaxSpeed - MinSpeed) + MinSpeed);
    const radiusMeters = Math.max(randomSpeedMph * SpeedMphToRadius, MinDistance);

    const r = radiusMeters / 111300, // convert meters to degrees
      y0 = latLng.lat,
      x0 = latLng.lng,
      randomAngle = 2 * Math.PI * Math.random(),
      x = r * Math.cos(randomAngle),
      y1 = r * Math.sin(randomAngle),
      x1 = x / Math.cos(y0);

    const newLatLng: ILatLng = {
      lat: y0 + y1,
      lng: x0 + x1
    };

    const speedUnits = this._demoDataResolver.initialAppStateData.userMapSettings.userSettings.SpeedUnits;
    const newSpeed = Math.floor(SpeedConversionUtilities.Convert(randomSpeedMph, ESpeedUnits.MilesPerHour, speedUnits));
    return { latLng: newLatLng, speed: newSpeed };
  }

  private _updateMovingPlot(
    plot: IVehiclePlot,
    newSpeed: number,
    newStatus: EVehicleStatusType,
    newCoodinates: ILatLng,
    newAddress: string
  ): IVehiclePlot {
    const direction = this._computeHeading(plot.coordinates, newCoodinates);
    const icon: EPlotIcon = this._computeIcon(direction);

    const time = new Date();
    const ticks = this._getTicks(time);
    const updatedPlot: IVehiclePlot = {
      ...plot,
      speed: newSpeed,
      ticks: ticks,
      time: time.toLocaleString(),
      timeUnits: time.toISOString(),
      coordinates: newCoodinates,
      address: newAddress,
      lastUpdateForDisplay: this._formatLastUpdateForDisplay(time),
      lastUpdateIsToday: true,
      direction: direction,
      iconClass: icon,
      status: newStatus,
      duration: newStatus !== plot.status ? 0 : plot.duration
    };
    return updatedPlot;
  }

  private _getTicks(time: Date) {
    return time.getTime() * 10000 + 621355968000000000;
  }

  private _updateStaticPlot(plot: IVehiclePlot, newStatus: EVehicleStatusType): IVehiclePlot {
    let icon: EPlotIcon;
    switch (newStatus) {
      case EVehicleStatusType.Idle: {
        icon = EPlotIcon.Idle;
        break;
      }
      case EVehicleStatusType.Stopped: {
        icon = EPlotIcon.Stopped;
        break;
      }
    }
    const time = new Date();
    const updatedPlot: IVehiclePlot = {
      ...plot,
      speed: 0,
      ticks: time.getTime() * 10000 + 621355968000000000,
      time: time.toLocaleString(),
      timeUnits: time.toISOString(),
      lastUpdateForDisplay: this._formatLastUpdateForDisplay(time),
      lastUpdateIsToday: true,
      iconClass: icon,
      status: newStatus,
      duration: newStatus !== plot.status ? 0 : plot.duration
    };

    return updatedPlot;
  }

  private _computeIcon(direction: number): EPlotIcon {
    if (direction < 22.5 || direction > 337.5) {
      return EPlotIcon.MovingN;
    } else if (direction > 22.5 && direction < 67.5) {
      return EPlotIcon.MovingNW;
    } else if (direction > 67.5 && direction < 112.5) {
      return EPlotIcon.MovingW;
    } else if (direction > 112.5 && direction < 157.5) {
      return EPlotIcon.MovingSW;
    } else if (direction > 157.5 && direction < 202.5) {
      return EPlotIcon.MovingS;
    } else if (direction > 202.5 && direction < 247.5) {
      return EPlotIcon.MovingSE;
    } else if (direction > 247.5 && direction < 292.5) {
      return EPlotIcon.MovingE;
    } else {
      return EPlotIcon.MovingNE;
    }
  }

  private _computeHeading(p1: ILatLng, p2: ILatLng): number {
    const toRad = (degrees: number) => (degrees * Math.PI) / 180;
    const toDeg = (radians: number) => (radians * 180) / Math.PI;
    const lng1 = toRad(p1.lng);
    const lng2 = toRad(p2.lng);
    const lat1 = toRad(p1.lat);
    const lat2 = toRad(p2.lat);

    const dLon = lng2 - lng1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const bearing = toDeg(Math.atan2(y, x));
    return 360 - ((bearing + 360) % 360); // transform to range [0,360] counter-clockwise
  }
  private _formatLastUpdateForDisplay(time: Date): string {
    const timeFormat = this._demoDataResolver.initialAppStateData.userMapSettings.userSettings.TimeFormat;
    const timeString = formatDate(time, timeFormat, this._demoDataResolver.initialAppStateData.userMapSettings.userSettings.Language);

    return timeString;
  }

  private _getDistance(latLngA: ILatLng, latLngB: ILatLng) {
    // convert to radians
    const c = (latLngA.lat * Math.PI) / 180;
    const a = (latLngA.lng * Math.PI) / 180;
    const d = (latLngB.lat * Math.PI) / 180;
    const b = (latLngB.lng * Math.PI) / 180;

    const distance =
      2 * Math.asin(Math.sqrt(Math.pow(Math.sin((c - d) / 2), 2) + Math.cos(c) * Math.cos(d) * Math.pow(Math.sin((a - b) / 2), 2)));
    return distance * EARTH_RADIUS;
  }
  private _getNewPlotValues(
    inputPlot: IVehiclePlot
  ): {
    latLng: ILatLng;
    address: string;
    speed: number;
  } {
    const randomPlotAndSpeed = this._movePlot(inputPlot.coordinates);
    const geocodedPointsSortedByDistance = this._geocodedData
      .map(point => ({
        value: point,
        distance: this._getDistance(<ILatLng>randomPlotAndSpeed.latLng, point.location)
      }))
      .sort((a, b) => a.distance - b.distance);
    const newLatLngAddress = this._getPlotGreaterThanMinDistance(geocodedPointsSortedByDistance, inputPlot);
    return {
      latLng: newLatLngAddress.location,
      address: newLatLngAddress.address,
      speed: randomPlotAndSpeed.speed
    };
  }

  private _getPlotGreaterThanMinDistance(pointsSortedByDistance: { value: ILocationAddress; distance: number }[], inputPlot: IVehiclePlot) {
    return pointsSortedByDistance.find(
      point =>
        this._getDistance(inputPlot.coordinates, point.value.location) >= MinDistance &&
        point.value.location !== inputPlot.coordinates &&
        point.value.address !== inputPlot.address
    ).value;
  }
}
