/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { ISearchState } from '../state/search.state';
import { ISearchVehicle, ESearchType, ISearchEntity, ISearchPoweredAsset } from '../../models';
import { getMapHierarchyInfo } from '../../../core/store/selectors';
import { IMapHierarchyInfo, ENodeType } from '../../../core/models';

export const getSearchState = createFeatureSelector<ISearchState>('search');

export const getSearchEntities = createSelector(getSearchState, (state: ISearchState): ISearchEntity[] =>
  !isElementNull(state) ? state.searchEntities : []
);

export const getFilteredEntities = createSelector(getSearchState, (state: ISearchState): ISearchEntity[] =>
  !isElementNull(state) ? state.filteredEntities : []
);

export const getSearchInput = createSelector(getSearchState, (state: ISearchState): string =>
  !isElementNull(state) ? state.searchInput : ''
);

export const getAllSearchVehiclesFromHierarchy = createSelector(
  getMapHierarchyInfo,
  (mapHierarchyInfo: IMapHierarchyInfo): ISearchVehicle[] => {
    const searchVehicles: ISearchVehicle[] = [];
    mapHierarchyInfo.mapHierarchy.forEach(mapHierarchyItem => {
      if (mapHierarchyItem.type === ENodeType.Vehicle) {
        const vehicle: ISearchVehicle = {
          name: mapHierarchyItem.name,
          id: mapHierarchyItem.id,
          type: ESearchType.Vehicle,
          fuelType: mapHierarchyItem.fuelType,
          // TODO: Add group and driver assignment
          group: null,
          driver: null
        };
        searchVehicles.push(vehicle);
      }
    });
    const uniqueVehicles = getDistinctEntitiesArrayById<ISearchVehicle>(searchVehicles);
    return uniqueVehicles;
  }
);

export const getAllSearchPoweredAssetsFromHierarchy = createSelector(
  getMapHierarchyInfo,
  (mapHierarchyInfo: IMapHierarchyInfo): ISearchPoweredAsset[] => {
    const searchPoweredAssets: ISearchPoweredAsset[] = [];
    mapHierarchyInfo.mapHierarchy.forEach(mapHierarchyItem => {
      if (mapHierarchyItem.type === ENodeType.PoweredAsset) {
        const poweredAsset: ISearchPoweredAsset = {
          name: mapHierarchyItem.name,
          id: mapHierarchyItem.id,
          type: ESearchType.PoweredAsset,
          fuelType: mapHierarchyItem.fuelType
        };
        searchPoweredAssets.push(poweredAsset);
      }
    });
    const uniquePoweredAsset = getDistinctEntitiesArrayById<ISearchPoweredAsset>(searchPoweredAssets);
    return uniquePoweredAsset;
  }
);

const getDistinctEntitiesArrayById = <T extends ISearchEntity>(entities: T[]): T[] => {
  const result: T[] = [];
  const map = new Map();
  for (const entity of entities) {
    if (!map.has(entity.id)) {
      map.set(entity.id, true);
      result.push(entity);
    }
  }
  return result;
};
