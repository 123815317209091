/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, EMPTY } from 'rxjs';

@Injectable()
export class LastInTheChainInterceptor implements HttpInterceptor {
  private readonly _excludedUrls: string[] = [];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (this._isURLExcluded(req.url)) {
      return next.handle(req);
    } else if (req.url.includes('/assets/')) {
      return next.handle(req);
    }

    return EMPTY;
  }

  private _isURLExcluded(url: string): boolean {
    return this._excludedUrls.some(excludedUrl => url.endsWith(excludedUrl));
  }
}
