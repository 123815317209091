/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take, switchMap, withLatestFrom, filter, mergeMap } from 'rxjs/operators';

import { isElementNull } from '@fleetmatics/ui.utilities';
import { ECheckboxState } from '@fleetmatics/ui.base-library';

import {
  EVehicleListPanelActions,
  FocusVehicle,
  SelectVehicle,
  UpdateIsMapFitted,
  UpdateVehicleAsSelected,
  TryUpdateZoomToMarker,
  GetSearchLightVehiclePlot,
  GetSearchLightVehiclePlotSuccess,
  ESearchLightActions,
  ECustomerMetadataActions,
  GetVehiclePlotsSuccess,
  EMapPlotsActions,
  SelectAsset,
  FocusAsset,
  UpdateAssetAsSelected,
  GetAssetPlotsSuccess,
  EAssetsActions,
  GetSearchLightAssetPlot,
  SelectAndFocusVehicle
} from '../actions';
import {
  getSelectedVehicleIds,
  getMapVehicleMarkers,
  getMapHierarchyInfo,
  getMaxSelectableVehiclesCount,
  getMapVehicleMarkersFilteredByStatusType,
  getAssetsMarkers,
  getHierarchyItemById
} from '../selectors';
import { IAppState } from '../state';
import { MarkerInfo, EPlotType, IMapHierarchyItem } from '../../models';

@Injectable()
export class VehicleListPanelEffects {
  public selectAndFocusVehicleWithSearchLightResults$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectVehicle>(EVehicleListPanelActions.SelectVehicle),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getMapHierarchyInfo))),
      switchMap(([vehicleToSelect, mapHierarchyInfo]) => {
        if (!this._checkMapHierarchyContainsVehicle(mapHierarchyInfo.mapHierarchy, vehicleToSelect)) {
          return of(new GetSearchLightVehiclePlot(vehicleToSelect));
        } else {
          return of(new SelectAndFocusVehicle(vehicleToSelect));
        }
      })
    )
  );

  public selectAndFocusVehicle$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SelectAndFocusVehicle>(EVehicleListPanelActions.SelectAndFocusVehicle),
        map(action => action.payload),
        withLatestFrom(
          this._store.pipe(select(getSelectedVehicleIds)),
          this._store.pipe(select(getMaxSelectableVehiclesCount)),
          this._store.pipe(select(getMapVehicleMarkersFilteredByStatusType))
        ),
        switchMap(([vehicleToSelect, selectedVehicleIds, maxSelectableVehiclesCount, vehicleMarkers]) => {
          if (selectedVehicleIds.has(vehicleToSelect)) {
            const vehicle = vehicleMarkers.find(vehicleMarker => vehicleMarker.id === +vehicleToSelect.substring(1));
            if (!isElementNull(vehicle)) {
              return of(new FocusVehicle(vehicleToSelect));
            }
          }

          if (isElementNull(maxSelectableVehiclesCount) || selectedVehicleIds.size < maxSelectableVehiclesCount) {
            return [
              new UpdateIsMapFitted(false),
              new UpdateVehicleAsSelected({
                focusVehicle: true,
                selectedVehicle: vehicleToSelect
              })
            ];
          }

          return of();
        })
      ) as Observable<UpdateIsMapFitted | UpdateVehicleAsSelected>
  );

  public focusVehicleAfterGetSearchLightVehiclePlotSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectVehicle>(EVehicleListPanelActions.SelectVehicle),
      map(action => action.payload),
      switchMap((vehicleToSelect: string) =>
        this._actions$.pipe(
          ofType<GetSearchLightVehiclePlotSuccess>(ESearchLightActions.GetSearchLightVehiclePlotSuccess),
          map(() => vehicleToSelect),
          take(1)
        )
      ),
      map(vehicleToSelect => new FocusVehicle(vehicleToSelect))
    )
  );

  public focusVehicleAfterUpdateVehicleAsSelected$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicleAsSelected>(ECustomerMetadataActions.UpdateVehicleAsSelected),
      filter(action => action.payload.focusVehicle),
      map(action => action.payload.selectedVehicle),
      switchMap((vehicleToSelect: string) =>
        this._actions$.pipe(
          ofType<GetVehiclePlotsSuccess>(EMapPlotsActions.GetVehiclePlotsSuccess),
          map(() => vehicleToSelect),
          take(1)
        )
      ),
      map(vehicleToSelect => new FocusVehicle(vehicleToSelect))
    )
  );

  public focusVehicle$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<FocusVehicle>(EVehicleListPanelActions.FocusVehicle),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getMapVehicleMarkers))),
      filter(([vehicleToFocus, vehicleMarkers]: [string, MarkerInfo[]]) => {
        const vehicleToFocusId = parseInt(vehicleToFocus.substring(1), 10);
        const exists = vehicleMarkers.some(markerInfo => markerInfo.id === vehicleToFocusId);
        return exists;
      }),
      map(([vehicleToFocus, vehicleMarkers]: [string, MarkerInfo[]]) => {
        const vehicleToFocusId = parseInt(vehicleToFocus.substring(1), 10);
        const marker = vehicleMarkers.find(markerInfo => markerInfo.id === vehicleToFocusId);
        if (marker) {
          return new TryUpdateZoomToMarker({
            latLng: marker.latLng,
            id: marker.id,
            type: marker.type,
            title: marker.title
          });
        }
      })
    )
  );

  public selectAndFocusAsset$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectAsset>(EVehicleListPanelActions.SelectAsset),
      map(action => action.payload),
      mergeMap(id =>
        this._store.pipe(
          select(getHierarchyItemById, id),
          take(1),
          map(item => <[string, IMapHierarchyItem]>[id, item])
        )
      ),
      switchMap(([assetToSelect, item]): Observable<Action> | Action[] => {
        if (isElementNull(item)) {
          return of(new GetSearchLightAssetPlot(assetToSelect));
        } else {
          if (item.state === ECheckboxState.Checked) {
            return this._store.pipe(
              select(getAssetsMarkers),
              filter(markers => markers.some(marker => marker.id === +assetToSelect.substring(1))),
              take(1),
              map(() => new FocusAsset(assetToSelect))
            );
          }

          return [new UpdateIsMapFitted(false), new UpdateAssetAsSelected(assetToSelect)];
        }
      })
    )
  );

  public focusAssetAfterUpdateAssetAsSelected$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateAssetAsSelected>(EAssetsActions.UpdateAssetAsSelected),
      map(action => action.payload),
      switchMap((assetToSelect: string) =>
        this._actions$.pipe(
          ofType<GetAssetPlotsSuccess>(EAssetsActions.GetAssetPlotsSuccess),
          map(() => assetToSelect),
          take(1)
        )
      ),
      map(assetToSelect => new FocusAsset(assetToSelect))
    )
  );

  public focusAsset$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<FocusAsset>(EVehicleListPanelActions.FocusAsset),
      map(action => parseInt(action.payload.substring(1), 10)),
      withLatestFrom(this._store.pipe(select(getAssetsMarkers))),
      filter(([assetToFocusId, assetMarkers]: [number, MarkerInfo[]]) => {
        const exists = assetMarkers.some(markerInfo => markerInfo.id === assetToFocusId);
        return exists;
      }),
      map(([assetToFocusId, assetMarkers]: [number, MarkerInfo[]]) => {
        const marker = assetMarkers.find(markerInfo => markerInfo.id === assetToFocusId);
        if (marker) {
          return new TryUpdateZoomToMarker({
            latLng: marker.latLng,
            id: marker.id,
            type: EPlotType.asset,
            title: marker.title
          });
        }
      })
    )
  );

  constructor(private readonly _actions$: Actions, private readonly _store: Store<IAppState>) {}

  private _checkMapHierarchyContainsVehicle(mapHierarchy: IMapHierarchyItem[], vehicleId: string): boolean {
    const vehicle = mapHierarchy.find(item => item.id === vehicleId);
    return !isElementNull(vehicle);
  }
}
