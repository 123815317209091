/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ILayoutPreferencesState } from '../state';

export const getLayoutPreferencesState = createFeatureSelector<ILayoutPreferencesState>('layoutPreferences');

export const getLayoutPreferences = createSelector(
  getLayoutPreferencesState,
  (state: ILayoutPreferencesState): ILayoutPreferencesState => state
);
