/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, zip } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { isArrayNullOrEmpty, isElementNull } from '@fleetmatics/ui.utilities';

import { ModalService } from '../modal';
import {
  EDeviceStatusActionType,
  EDeviceStatusAlertSource,
  EDeviceStatusCode,
  ENotificationSettingsTypes,
  ENotificationTypes,
  IDeviceStatusModalData,
  INotification,
  INotificationDetails,
  INotificationDetailsParams,
  INotificationPreferences
} from './models';
import {
  AcknowledgePanic,
  CloseNonPanicNotification,
  DeleteAllNotifications,
  DeleteDeviceStatusNotification,
  DeleteNotification,
  getDeviceStatusNotifications,
  getNonPanicNotifications,
  getNotificationDetails,
  GetNotificationInfo,
  getPanicNotifications,
  IRootState,
  ReviewAsCorrect,
  ReviewAsIncorrect,
  ReviewAsSeen,
  ReviewPanic,
  getShouldShowNonPanicNotifications
} from './store';

const playAudio = (processedNotifications: string[], playAudioFunction: Function) =>
  tap((notifications: INotification[]) => {
    let shouldPlayAudio = false;
    notifications.forEach(notification => {
      const exists = processedNotifications.some(processedNotification => processedNotification === notification.id);
      if (!exists) {
        processedNotifications.push(notification.id);

        if (notification.shouldPlayAudio) {
          shouldPlayAudio = true;
        }
      }
    });

    if (shouldPlayAudio) {
      playAudioFunction();
    }
  });

@Component({
  selector: 'fm-notifications',
  templateUrl: './notifications.component.html',
  animations: [
    trigger('onClean', [
      state('fade', style({ transform: 'translateX(440px)' })),
      transition('* => fade', [style({ transform: 'translateX(0)' }), animate('192ms')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Input() notificationPreferences: INotificationPreferences[];
  @Input() notificationsEnabled: boolean;
  @Input() showPanicAlertsOnly: boolean;

  @Input()
  public set deviceStatusEnabled(deviceStatusEnabled: boolean) {
    this.deviceStatusNotificationsEnabled = deviceStatusEnabled;
    this._initializeNonPanicNotifications();
  }

  @Output() destroyed = new EventEmitter<string>();
  @Output() notificationClicked = new EventEmitter<INotification>();
  @Output() showOnMapClicked = new EventEmitter<string>();

  @ViewChild('notificationDetails') notificationDetailsModalTemplate: TemplateRef<any>;
  @ViewChild('deviceStatus') deviceStatusModalTemplate: TemplateRef<any>;

  public notifications$: Observable<INotification[]>;
  public panicNotifications$: Observable<INotification[]>;
  public commonNotifications$: Observable<INotification[]>;
  public nonPanicNotifications$: Observable<INotification[]>;
  public deviceStatusNotifications$: Observable<INotification[]>;
  public persistedNotifications$: Observable<INotification[]>;
  public notificationDetails$: Observable<INotificationDetails>;
  public shouldShowNonPanicNotifications$: Observable<boolean>;
  public notificationWrapper = '';
  public notificationSettingType = ENotificationSettingsTypes;
  public animationSpeed = 192;
  public isDeviceStatusModalOpen = false;
  public isDeviceStatusModalMinimized = false;
  public deviceStatusNotificationsEnabled = false;
  public deviceStatusNotificationsToReview: INotification[] = [];
  public notificationsToClose = new Set<string>();
  private readonly _processedNonPanicNotifications: string[] = [];
  private readonly _processedPanicNotifications: string[] = [];
  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _notificationsStore: Store<IRootState>,
    private readonly _modalService: ModalService,
    private readonly _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._modalService.closed
      .pipe(
        filter(() => this.isDeviceStatusModalOpen),
        takeUntil(this._unsubscribe$)
      )
      .subscribe(() => {
        this.isDeviceStatusModalMinimized = this.deviceStatusNotificationsToReview.length !== 0;
        this.isDeviceStatusModalOpen = false;
        this._cdr.markForCheck();
      });

    this.notificationDetails$ = this._notificationsStore.pipe(select(getNotificationDetails));
    this.shouldShowNonPanicNotifications$ = this._notificationsStore.pipe(select(getShouldShowNonPanicNotifications));

    this._initializeDeviceStatusNotifications();

    this.commonNotifications$ = this._notificationsStore.pipe(
      select(getNonPanicNotifications),
      map(notifications => notifications.map(notification => this._processNotification(notification)))
    );

    this.panicNotifications$ = this._notificationsStore.pipe(
      select(getPanicNotifications),
      map(notifications => notifications.map(notification => this._processNotification(notification))),
      playAudio(this._processedPanicNotifications, this._playAudio)
    );

    this._initializeNonPanicNotifications();
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public onClick(notification: INotification): void {
    this.notificationsToClose.add(notification.id);

    if (notification.settingType === ENotificationSettingsTypes.exceededAlertLimit || notification.type === ENotificationTypes.videoAlert) {
      this._cleanSingle(notification, true);
    } else if (notification.type !== ENotificationTypes.panic && notification.type !== ENotificationTypes.deviceStatusChange) {
      const parameters: INotificationDetailsParams = {
        id: notification.id,
        db: notification.db,
        type: notification.type
      };
      this._notificationsStore.dispatch(new GetNotificationInfo(parameters));
      this.openDetail();
    } else if (notification.type === ENotificationTypes.panic) {
      this._notificationsStore.dispatch(new ReviewPanic(notification));
    }
    this.notificationClicked.emit(notification);
  }

  public onCloseModal(): void {
    this._modalService.close();
  }

  public cleanAll(): void {
    this.notificationWrapper = 'fade';
    let notificationsToClear: INotification[];
    this.nonPanicNotifications$.pipe(take(1)).subscribe((notifications: INotification[]) => {
      if (notifications) {
        notificationsToClear = [...notifications];
        notifications.forEach(notification => {
          this._notificationsStore.dispatch(new CloseNonPanicNotification({ notification }));
          this.notificationsToClose.delete(notification.id);
        });
      }
    });
    setTimeout(() => {
      this._notificationsStore.dispatch(new DeleteAllNotifications());
      this.notificationWrapper = '';
      if (!isArrayNullOrEmpty(notificationsToClear)) {
        setTimeout(() => {
          while (notificationsToClear.length > 0) {
            this._removeProcessedNotification(this._processedNonPanicNotifications, notificationsToClear[0]);
            notificationsToClear.splice(0, 1);
          }
        });
      }
    }, this.animationSpeed);
  }

  public removeNotification(notification: INotification): void {
    this._cleanSingle(notification, false, true);
  }

  public closeNotification(notification: INotification): void {
    this._cleanSingle(notification);
  }

  public openDeviceStatusModal(): void {
    this.isDeviceStatusModalMinimized = false;
    this.isDeviceStatusModalOpen = true;
    this._modalService.open(this.deviceStatusModalTemplate, { closeIcon: 'ic-collapse' });
  }

  public openDetail(): void {
    this._modalService.open(this.notificationDetailsModalTemplate);
  }

  public onDeviceStatusNotificationDismissed(notification: INotification): void {
    this._notificationsStore.dispatch(
      new ReviewAsSeen({
        notification,
        action: EDeviceStatusActionType.seen
      })
    );
  }

  public onDeviceStatusNotificationNotAcknowledged(data: IDeviceStatusModalData): void {
    this._notificationsStore.dispatch(
      new ReviewAsIncorrect({
        notification: data.notification,
        action: EDeviceStatusActionType.confirmedAsIncorrect,
        modalData: data.formData
      })
    );
  }

  public onDeviceStatusNotificationAcknowledged(notification: INotification): void {
    this._notificationsStore.dispatch(
      new ReviewAsCorrect({
        notification,
        action: EDeviceStatusActionType.confirmedAsCorrect
      })
    );
  }

  public showOnMap(id: string) {
    this._modalService.close();
    this.showOnMapClicked.emit(id);
  }

  private _initializeDeviceStatusNotifications(): void {
    this.deviceStatusNotifications$ = this._notificationsStore.pipe(
      select(getDeviceStatusNotifications),
      map(deviceStatusNotifications => {
        const notifications: INotification[] = [];
        this.deviceStatusNotificationsToReview = [];

        if (!isArrayNullOrEmpty(deviceStatusNotifications)) {
          deviceStatusNotifications.forEach(deviceStatus => {
            const isAlertToBeReviewed =
              (isElementNull(deviceStatus.alertSource) || deviceStatus.alertSource === EDeviceStatusAlertSource.device) &&
              isElementNull(deviceStatus.exclusiveActionBy) &&
              !deviceStatus.alertSeen;

            const hasExlusiveActionBeenTaken = deviceStatus.alertSource === EDeviceStatusAlertSource.exclusiveAction;

            if (hasExlusiveActionBeenTaken && deviceStatus.troubleCode === EDeviceStatusCode.towing) {
              // add the exlusive notification to notify the user about the update
              notifications.push(deviceStatus);
            } else if (isAlertToBeReviewed && deviceStatus.troubleCode === EDeviceStatusCode.towing) {
              this.deviceStatusNotificationsToReview.push(deviceStatus);
            }
          });

          return notifications.filter(
            notification =>
              notification.troubleCode !== EDeviceStatusCode.towing || notification.alertSource === EDeviceStatusAlertSource.exclusiveAction
          );
        }
      }),
      tap(() => {
        if (this.isDeviceStatusModalOpen && this.deviceStatusNotificationsToReview.length === 0) {
          this._modalService.close();
        }
      })
    );
  }

  private _cleanSingle(notification: INotification, clicked = false, removed = false): void {
    let removeNonPanic = true;
    if (notification.type !== ENotificationTypes.panic && notification.type !== ENotificationTypes.deviceStatusChange && !removed) {
      this._notificationsStore.dispatch(new CloseNonPanicNotification({ notification, clicked }));
    } else if (notification.type === ENotificationTypes.panic) {
      this._notificationsStore.dispatch(new AcknowledgePanic(notification));
      removeNonPanic = false;
    }
    this.destroyed.emit(notification.id);

    this._dispatchDeleteNotification(notification);

    this.notificationsToClose.delete(notification.id);

    setTimeout(() => {
      if (removeNonPanic) {
        this._removeProcessedNotification(this._processedNonPanicNotifications, notification);
      } else {
        this._removeProcessedNotification(this._processedPanicNotifications, notification);
      }
    });
  }

  private _dispatchDeleteNotification(notification: INotification): void {
    if (notification.type === ENotificationTypes.deviceStatusChange) {
      this._notificationsStore.dispatch(new DeleteDeviceStatusNotification(notification.id));
    } else {
      this._notificationsStore.dispatch(new DeleteNotification(notification.id));
    }
  }

  private _processNotification(item: INotification): INotification {
    let option = this.notificationPreferences.find(preference => preference.type === item.settingType);

    if (!isElementNull(option)) {
      this._setTimeoutOption(option, item);
      item.shouldPlayAudio = option.audioEnabled;
    } else if (item.settingType === ENotificationSettingsTypes.panicAlerts) {
      item.temporaryPopup = false;
      item.shouldPlayAudio = true;
    } else {
      option = this.notificationPreferences.find(preference => preference.type === ENotificationSettingsTypes.highPriorityAlerts);
      if (!isElementNull(option)) {
        this._setTimeoutOption(option, item);
      }
    }

    if (
      item.type === ENotificationTypes.deviceStatusChange &&
      (!isElementNull(item.exclusiveActionType) || item.troubleCode === EDeviceStatusCode.installationError)
    ) {
      item.temporaryPopup = true;
    }

    return item;
  }

  private _playAudio(): void {
    try {
      const audio = new Audio('./ding.mp3');
      audio.load();
      const audioPromise = audio.play();
      if (audioPromise) {
        audioPromise.catch(() => {
          // This might happen in browsers where the autoplay is disabled (https://goo.gl/xX8pDD), we hide the error
        });
      }
    } catch {
      // This might happen when the Windows Media Pack is not installed in the user computer, we hide the error
    }
  }

  private _setTimeoutOption(option: INotificationPreferences, item: INotification): void {
    if (!option.temporaryPopup && !option.stationaryPopup) {
      if (option.type === this.notificationSettingType.standardAlerts) {
        item.temporaryPopup = true;
      } else if (
        option.type === this.notificationSettingType.highPriorityAlerts ||
        option.type === this.notificationSettingType.garminMessages
      ) {
        item.temporaryPopup = false;
      }
    } else {
      item.temporaryPopup = option.temporaryPopup;
    }
  }

  private _initializeNonPanicNotifications(): void {
    if (this.deviceStatusNotificationsEnabled) {
      this.nonPanicNotifications$ = zip(this.deviceStatusNotifications$, this.commonNotifications$).pipe(
        map(([deviceStatusNotifications, commonNotifications]: [INotification[], INotification[]]) => [
          ...(!isArrayNullOrEmpty(commonNotifications) ? commonNotifications : []),
          ...(!isArrayNullOrEmpty(deviceStatusNotifications) ? deviceStatusNotifications : [])
        ])
      );
    } else {
      this.nonPanicNotifications$ = this.commonNotifications$;
    }

    if (!isElementNull(this.nonPanicNotifications$)) {
      this.nonPanicNotifications$ = this.nonPanicNotifications$.pipe(playAudio(this._processedNonPanicNotifications, this._playAudio));
    }
  }

  private _removeProcessedNotification(processedNotifications: string[], notificationToRemove: INotification): void {
    const index = processedNotifications.findIndex(processedNotification => processedNotification === notificationToRemove.id);
    if (index !== -1) {
      processedNotifications.splice(index, 1);
    }
  }
}
