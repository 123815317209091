<ng-template #freeTrialModalContent>
  <div class="header">
    <div class="verizon-connect-img"></div>
    <div class="title"
      i18n="@@S_DEMO_30_DAY">30-day free trial</div>
  </div>
  <div class="body">
    <div class="free-trial"></div>
    <ul>
      <li i18n="@@S_DEMO_GPS">GPS hardware included.</li>
      <li i18n="@@S_DEMO_INSTALL">Easy to install plug-and-play device.</li>
      <li i18n="@@S_DEMO_SHIPPING">Free shipping.</li>
    </ul>
  </div>
  <div class="footer">
    <a id="plans-button"
      a7s-id="demo-mobile-free-trial-see-plans"
      [href]="(marketingContent$ | async)?.url"
      class="btn btn-primary"
      i18n="@@S_DEMO_VARIATION_B_SEE">See plans and pricing</a>
    <button id="try-on-desktop-button"
      a7s-id="demo-mobile-free-trial-try-desktop"
      class="btn"
      (click)="showTryOnDesktop()"
      i18n="@@S_DEMO_MOBILE_DESKTOP">Try on desktop</button>
  </div>
</ng-template>
