/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { EVehicleDriverPanelTabs, Position, IMapContextMenuData, MarkerInfo } from '../../../core/models';

export enum EVehicleDriverPanelActions {
  UpdateIsCollapsed = '[Layout] Update Is Vehicle Driver Panel Collapsed',
  UpdateActiveTab = '[Layout] Update Vehicle Driver Panel Active Tab',
  OverlaySidebarMarginsChanged = '[Layout] Overlay Sidebar Margins Changed',
  OpenVehicleContextMenu = '[Layout] Open Vehicle Context Menu',
  CloseVehicleContextMenu = '[Layout] Close Vehicle Context Menu'
}

export class UpdateIsVehicleDriverPanelCollapsed implements Action {
  public readonly type = EVehicleDriverPanelActions.UpdateIsCollapsed;

  constructor(public payload: boolean) {}
}

export class UpdateVehicleDriverPanelActiveTab implements Action {
  public readonly type = EVehicleDriverPanelActions.UpdateActiveTab;

  constructor(public payload: EVehicleDriverPanelTabs) {}
}

export class OverlaySidebarMarginsChanged implements Action {
  public readonly type = EVehicleDriverPanelActions.OverlaySidebarMarginsChanged;

  constructor(public payload: Position) {}
}

export class OpenVehicleContextMenu implements Action {
  public readonly type = EVehicleDriverPanelActions.OpenVehicleContextMenu;

  constructor(public payload: IMapContextMenuData<MarkerInfo>) {}
}

export class CloseVehicleContextMenu implements Action {
  public readonly type = EVehicleDriverPanelActions.CloseVehicleContextMenu;
}

export type VehicleDriverPanelActions =
  | UpdateIsVehicleDriverPanelCollapsed
  | UpdateVehicleDriverPanelActiveTab
  | OverlaySidebarMarginsChanged
  | OpenVehicleContextMenu
  | CloseVehicleContextMenu;
