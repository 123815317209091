/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { EUserMapSettingsActions, UserMapSettingsActions } from '../actions';
import { initialUserDataState, IUserMapSettingsState } from '../state';

export function userMapSettingsReducer(state = initialUserDataState, action: UserMapSettingsActions): IUserMapSettingsState {
  switch (action.type) {
    case EUserMapSettingsActions.GetUserMapSettingsSuccess: {
      return {
        ...state,
        ...action.payload
      };
    }

    case EUserMapSettingsActions.GetReplayUserSettingsSuccess: {
      return {
        ...state,
        replaySettings: action.payload
      };
    }

    case EUserMapSettingsActions.EnableFeatureToggle: {
      return {
        ...state,
        featureToggles: [...new Set(state.featureToggles.concat(action.payload))]
      };
    }

    case EUserMapSettingsActions.DisableFeatureToggle: {
      const disable = new Set(action.payload);
      return {
        ...state,
        featureToggles: state.featureToggles.filter(feature => !disable.has(feature))
      };
    }

    default:
      return state;
  }
}
