/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IRoadsideAssistanceHttpService } from './roadside-assistance-http.service.interface';
import { IVehicleDetailsResponse, IUserRoadsideAssistanceStatus } from '../../models';
import { IAppConfig } from '../../../config';

@Injectable()
export class RoadsideAssistanceHttpService implements IRoadsideAssistanceHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.roadsideAssistanceApiEndpoint;
  }

  getVehicleDetails(vehicleId: string): Observable<IVehicleDetailsResponse> {
    return this._httpClient.get<IVehicleDetailsResponse>(`${this._rootUrl}/v1/Vehicles/${vehicleId}`).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return of(null);
        }
        return throwError(err);
      })
    );
  }

  getUserRoadsideAssistanceStatus(): Observable<IUserRoadsideAssistanceStatus> {
    return this._httpClient.get<IUserRoadsideAssistanceStatus>(`${this._rootUrl}/v1/User/RSAStatus`);
  }
}
