/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { take, filter, shareReplay } from 'rxjs/operators';

import { IDemoDataResolverService } from './demo-data-resolver.service.interface';
import { IAppState } from '../../../core/store/state';
import { IDemoApppointment, IDemoGeofenceData, ILocationAddress, EDemoCities, IDemoMapHierarchy } from '../../models';
import { IVehiclePlot } from '../../../core/models';
import { INotificationResponse } from '../../../core/components';

@Injectable({
  providedIn: 'root'
})
export class DemoDataResolverService implements IDemoDataResolverService {
  isReady$: Observable<boolean>;
  initialAppStateData: IAppState;
  initialDemoApppointmentData: IDemoApppointment[];
  initialDemoGeofenceData: IDemoGeofenceData[];
  initialVehiclePlotsData: IVehiclePlot[];
  initialDemoHierarchyData: IDemoMapHierarchy;
  initialAlertsData: INotificationResponse[];
  locationAddresses: ILocationAddress[];

  private readonly _isReadySubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isReady$ = this._isReadySubject.asObservable().pipe(
      filter(isReady => isReady),
      take(1),
      shareReplay(1)
    );
  }
  initialize(city: EDemoCities): Observable<boolean> {
    switch (city) {
      case EDemoCities.atlanta: {
        forkJoin(this._loadAtlanta())
          .pipe(take(1))
          .subscribe(() => this._isReadySubject.next(true));
        break;
      }
      case EDemoCities.lisbon: {
        forkJoin(this._loadLisbon())
          .pipe(take(1))
          .subscribe(() => this._isReadySubject.next(true));
        break;
      }
      case EDemoCities.rome: {
        forkJoin(this._loadRome())
          .pipe(take(1))
          .subscribe(() => this._isReadySubject.next(true));
        break;
      }
    }
    return this.isReady$;
  }

  private _loadAtlanta(): Promise<any>[] {
    return [
      import('../../data/atlanta/atlanta-initial-app-state.data').then(d => (this.initialAppStateData = d.atlantaInitialAppStateData)),
      import('../../data/atlanta/atlanta-initial-demo-appointment.data').then(
        d => (this.initialDemoApppointmentData = d.atlantaInitialDemoApppointmentData)
      ),
      import('../../data/atlanta/atlanta-initial-demo-geofence-data').then(
        d => (this.initialDemoGeofenceData = d.atlantaInitialDemoGeofenceData)
      ),
      import('../../data/atlanta/atlanta-initial-vehicle-plots.data').then(
        d => (this.initialVehiclePlotsData = d.atlantaInitialVehiclePlotsData)
      ),
      import('../../data/atlanta/atlanta-initial-demo-hierarchy.data').then(
        d => (this.initialDemoHierarchyData = d.atlantaInitialDemoHierarchyData)
      ),
      import('../../data/atlanta/atlanta-initial-alerts.data').then(d => (this.initialAlertsData = d.atlantaInitialAlertsData)),
      import('../../data/atlanta/atlanta.json').then(json => (this.locationAddresses = (json as any).default))
    ];
  }

  private _loadLisbon(): Promise<any>[] {
    return [
      import('../../data/lisbon/lisbon-initial-app-state.data').then(d => (this.initialAppStateData = d.lisbonInitialAppStateData)),
      import('../../data/lisbon/lisbon-initial-demo-appointment.data').then(
        d => (this.initialDemoApppointmentData = d.lisbonInitialDemoApppointmentData)
      ),
      import('../../data/lisbon/lisbon-initial-demo-geofence-data').then(
        d => (this.initialDemoGeofenceData = d.lisbonInitialDemoGeofenceData)
      ),
      import('../../data/lisbon/lisbon-initial-vehicle-plots.data').then(
        d => (this.initialVehiclePlotsData = d.lisbonInitialVehiclePlotsData)
      ),
      import('../../data/lisbon/lisbon-initial-demo-hierarchy.data').then(
        d => (this.initialDemoHierarchyData = d.lisbonInitialDemoHierarchyData)
      ),
      import('../../data/lisbon/lisbon-initial-alerts.data').then(d => (this.initialAlertsData = d.lisbonInitialAlertsData)),
      import('../../data/lisbon/lisbon.json').then(json => (this.locationAddresses = (json as any).default))
    ];
  }

  private _loadRome(): Promise<any>[] {
    return [
      import('../../data/rome/rome-initial-app-state.data').then(d => (this.initialAppStateData = d.romeInitialAppStateData)),
      import('../../data/rome/rome-initial-demo-appointment.data').then(
        d => (this.initialDemoApppointmentData = d.romeInitialDemoApppointmentData)
      ),
      import('../../data/rome/rome-initial-demo-geofence-data').then(
        d => (this.initialDemoGeofenceData = d.romeInitialDemoGeofenceData)
      ),
      import('../../data/rome/rome-initial-vehicle-plots.data').then(
        d => (this.initialVehiclePlotsData = d.romeInitialVehiclePlotsData)
      ),
      import('../../data/rome/rome-initial-demo-hierarchy.data').then(
        d => (this.initialDemoHierarchyData = d.romeInitialDemoHierarchyData)
      ),
      import('../../data/rome/rome-initial-alerts.data').then(d => (this.initialAlertsData = d.romeInitialAlertsData)),
      import('../../data/rome/rome.json').then(json => (this.locationAddresses = (json as any).default))
    ];
  }
}
