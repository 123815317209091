/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EOverlayContainer {
  Usabilla,
  VehicleDriverPanel,
  MapControls,
  Replay
}
