/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import * as moment from 'moment-timezone';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { MomentUtilities } from '../utils';

type TDateOrMoment = Date | moment.Moment;

export class DateRange {
  public start: moment.Moment;
  public end: moment.Moment;

  constructor(start: TDateOrMoment, end: TDateOrMoment) {
    this.start = isElementNull(start) ? null : moment(start);
    this.end = isElementNull(end) ? null : moment(end);
  }

  public getUtcStart(): Date {
    return MomentUtilities.getUtcDateFromMoment(this.start);
  }

  public getUtcEnd(): Date {
    return MomentUtilities.getUtcDateFromMoment(this.end);
  }

  public equals(other: DateRange): boolean {
    return this.start.isSame(other.start) && this.end.isSame(other.end);
  }

  public isMultiDay(): boolean {
    return this.differenceDays() > 1;
  }

  public differenceDays(): number {
    /*
      a day is counted as 'how many times the date range crosses 00:00'
      examples:
      1/1 00:00 --> 1/1 23:00:00 length of journey is 23 hours and is counted as 1 days because it does not cross the 00:00 point
      1/1 00:00 --> 2/1 01:00 length of journey is 25 hours and is counted as 2 days because it crosses the 00:00 point
      1/1 23:00 --> 2/1 01:00:00 length of journey is 2 hours and is counted as 2 days because it crosses the 00:00 point
    */
    const startDate = this.start < this.end ? this.start.clone() : this.end.clone();
    const endDate = this.end > this.start ? this.end.clone() : this.start.clone();
    let dateCounter = 0;

    while (startDate <= endDate) {
      dateCounter++;
      startDate.add(1, 'day').startOf('day');
    }

    return dateCounter;
  }

  public getDateRangeInTimezone(timezone: string, keepLocalTime?: boolean): DateRange {
    const adjustedStart = this.start.clone().tz(timezone, keepLocalTime);
    const adjustedEnd = this.end.clone().tz(timezone, keepLocalTime);
    return new DateRange(adjustedStart, adjustedEnd);
  }

  public getPreviousDayDateRange(): DateRange {
    const previousDayStart = this.start.clone().subtract(1, 'day').startOf('day');
    const previousDayEnd = this.end.clone().subtract(1, 'day').endOf('day');
    return new DateRange(previousDayStart, previousDayEnd);
  }

  public getNextDayDateRange(): DateRange {
    const nextDayStart = this.start.clone().add(1, 'day').startOf('day');
    const nextDayEnd = this.end.clone().add(1, 'day').endOf('day');
    return new DateRange(nextDayStart, nextDayEnd);
  }

  public contains(date: TDateOrMoment): boolean {
    const d = moment(date);
    // The '[]' inputs mean start and end are inclusive
    return d.isBetween(this.start, this.end, undefined, '[]');
  }

  public isWholeDay(): boolean {
    // Should only return true when start is start of day, and end is end of the same day
    return this.start.isSame(this.start.clone().startOf('day')) && this.end.isSame(this.start.clone().endOf('day'));
  }

  public toString(format = 'LLLL'): string {
    return `${this.start.clone().format(format)} - ${this.end.clone().format(format)}`;
  }
}
