/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { FmDocumentRefService } from '@fleetmatics/ui.utilities';

import { IDomHelperService } from './dom-helper.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DomHelperService implements IDomHelperService {
  constructor(private readonly _documentRefService: FmDocumentRefService) {}

  public getBoundingClientRectWithSelector(selector: string): ClientRect | DOMRect {
    const element = this._documentRefService.nativeDocument.querySelector(selector);
    return this.getBoundingClientRectWithElement(element);
  }

  public getBoundingClientRectWithElement(element: Element): ClientRect | DOMRect {
    return element.getBoundingClientRect();
  }

  public copyToClipboard(valueToCopy: string): void {
    const input = this._documentRefService.nativeDocument.createElement('input');
    input.value = valueToCopy;
    this._documentRefService.nativeDocument.body.appendChild(input);
    input.select();
    this._documentRefService.nativeDocument.execCommand('copy');
    this._documentRefService.nativeDocument.body.removeChild(input);
  }
}
