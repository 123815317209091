/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMapTo } from 'rxjs/operators';

import { DemoDataResolverService } from '../services';

@Injectable()
export class CategoriesHttpInterceptor implements HttpInterceptor {
  constructor(private readonly _demoDataResolver: DemoDataResolverService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.endsWith('/Category/MapCategories/')) {
      return this._demoDataResolver.isReady$.pipe(
        switchMapTo(of(this._demoDataResolver.initialAppStateData.advancedOptions.placeCategories)),
        map(categories => new HttpResponse({ body: categories, status: 200 }))
      );
    }

    return next.handle(req);
  }
}
