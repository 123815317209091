/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DemoConstants } from '../../constants';
import { ModalService } from '../../services';
import { IMarketingTextContent } from '../../models';
import { getMarketingContent } from '../../store/demo.selectors';
import { ShowShareDemoModal } from '../../store/demo.actions';
import { IAppState } from '../../../core/store/state';

@Component({
  selector: 'fm-free-trial-modal',
  templateUrl: './free-trial-modal.component.html',
  styleUrls: ['./free-trial-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeTrialModalComponent implements OnInit {
  public marketingContent$: Observable<IMarketingTextContent>;

  @ViewChild('freeTrialModalContent', { static: false }) modalTemplate: TemplateRef<any>;

  constructor(private readonly _modalService: ModalService, private readonly _store: Store<IAppState>) {}

  ngOnInit() {
    this.marketingContent$ = this._store.pipe(select(getMarketingContent));
    setTimeout(
      () =>
        this._modalService.open(DemoConstants.MobileWebFreeTrialModalId, this.modalTemplate, {
          customClass: 'free-trial-modal',
          size: 'small',
          disableResponsiveness: true
        }),
      0
    );
  }

  public showTryOnDesktop(): void {
    this._store.dispatch(new ShowShareDemoModal());
    this._modalService.close();
  }
}
