/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { LayoutPreferencesActions, ELayoutPreferencesActions } from '../actions';
import { ILayoutPreferencesState, initialLayoutPreferencesState } from '../state';

export function layoutPreferencesReducer(
  state = initialLayoutPreferencesState,
  action: LayoutPreferencesActions
): ILayoutPreferencesState {
  switch (action.type) {
    case ELayoutPreferencesActions.GetLayoutPreferencesSuccess:
    case ELayoutPreferencesActions.UpdateLayoutPreferences: {
      return { ...action.payload };
    }

    default: return state;
  }
}
