/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, forkJoin, of, interval, EMPTY } from 'rxjs';
import { map, tap, switchMap, filter, catchError, mergeMap, skipWhile, withLatestFrom } from 'rxjs/operators';

import { isArrayNullOrEmpty, isElementNull } from '@fleetmatics/ui.utilities';

import {
  ENotificationsActions,
  AddNotifications,
  AddNotificationsSuccess,
  CloseNonPanicNotification,
  ReviewPanic,
  AcknowledgePanic,
  GetPersistedNotifications,
  GetPersistedNotificationsSuccess,
  GetNotificationInfo,
  GetNotificationInfoSuccess,
  ReviewAsCorrect,
  ReviewAsIncorrect,
  ReviewAsSeen,
  ReviewDeviceStatusNotificationError,
  ReviewAsIncorrectSuccess,
  ReviewAsSeenSuccess,
  ReviewAsCorrectSuccess,
  InitializeNotifications,
  SetupDeviceStatusNotificationsCleanup,
  NotificationsExpired,
  UpdateNotificationSuccess
} from '../actions';
import { getDeviceStatusNotifications, getNotifications } from '../selectors';
import {
  INotification,
  INotificationResponse,
  INotificationDetailsResponse,
  INotificationDetails,
  INotificationDetailsParams,
  ENotificationTypes,
  IDeviceStatusResponse,
  IDeviceStatusParams,
  IDeviceStatusRequest,
  IVehicleDetailsResponse,
  ENotificationSettingsTypes,
  INotificationClosingParams,
  IDeviceStatusAlertsResponse,
  EDeviceStatusCode,
  EDeviceStatusActionType
} from '../../models';
import { NotificationsService, DeviceStatusHttpService, RedirectService, AlertService } from '../../services';
import { IAppState } from '../../../../store/state';
import { getDeviceStatusNotificationsEnabled, getVehiclePlots, getTowingVehicles, getMapHierarchy } from '../../../../store/selectors';
import { SelectVehicle, UpdateTowingVehicle, RemoveTowingVehicles, AddTowingVehicles } from '../../../../store/actions';
import { delayedRetry } from '../../../../operators';
import { IVehiclePlot, ITowingVehicle, EPlotIcon, EPlotType, IMapHierarchyItem, ENodeType } from '../../../../models';

const deviceStatusNotificationExpirationInSeconds = 120;
const expirationCheckIntervalInSeconds = 5;

@Injectable()
export class NotificationsEffects {

  public initializeNotifications$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<InitializeNotifications>(ENotificationsActions.InitializeNotifications),
    filter(() => isPlatformBrowser(this._platformId)),
    switchMap(() => [new GetPersistedNotifications(), new SetupDeviceStatusNotificationsCleanup()])
  ));

  public setupDeviceStatusNotificationsCleanup$ = createEffect(() => this._actions$.pipe(
    ofType<SetupDeviceStatusNotificationsCleanup>(ENotificationsActions.SetupDeviceStatusNotificationsCleanup),
    switchMap(() => interval(expirationCheckIntervalInSeconds * 1000)),
    withLatestFrom(this._store.pipe(select(getDeviceStatusNotifications)), this._store.pipe(select(getTowingVehicles))),
    map(([, notifications, towingVehicles]) => {
      const expiredNotifications = this._getExpiredNotifications(notifications);
      const towingVehiclesOfExpiredNotifications = this._getTowingVehiclesOfExpiredNotifications(towingVehicles, expiredNotifications);

      const expiredTowingVehicles = this._getExpiredTowingVehicles(towingVehicles);
      const towingVehiclesToRemove = expiredTowingVehicles.concat(towingVehiclesOfExpiredNotifications);
      return <[INotification[], ITowingVehicle[]]>[expiredNotifications, towingVehiclesToRemove];
    }),
    map(([expiredNotifications, towingVehiclesToRemove]) => {
      const actions: Action[] = [];
      if (expiredNotifications.length > 0) {
        actions.push(new NotificationsExpired(expiredNotifications));
      }
      if (towingVehiclesToRemove.length > 0) {
        actions.push(new RemoveTowingVehicles(towingVehiclesToRemove));
      }
      return actions;
    }),
    filter((actions: Action[]) => actions.length > 0),
    switchMap(actions => actions)
  ));

  public addNotificationsDeviceStatusResponse$ = createEffect(() => this._actions$.pipe(
    ofType<AddNotifications>(ENotificationsActions.AddNotifications),
    map((action: AddNotifications) => <IDeviceStatusResponse>action.payload),
    filter(notificationResponse => !isElementNull(notificationResponse.Alert)),
    filter(deviceStatusResponse => !deviceStatusResponse.Metadata.AlertSeen),
    withLatestFrom(this._store.pipe(select(getNotifications))),
    mergeMap(([receivedNotification, notifications]) => {
      const existingNotification = notifications.find(n => n.id === receivedNotification.Alert.VehicleId.toString());
      if (existingNotification) {
        const updatedNotification: INotification = this._getUpdatedNotification(existingNotification, receivedNotification);
        return of(new UpdateNotificationSuccess(updatedNotification));
      }

      return this._deviceStatusService.getVehicleDetails(receivedNotification.Alert.VehicleId.toString()).pipe(
        map((response: IVehicleDetailsResponse) => this._convertFromDsnToNotification(receivedNotification, response)),
        delayedRetry(),
        map(notification => new AddNotificationsSuccess(notification)),
        catchError(() => EMPTY)
      );
    })
  ));

  public addNotifications$ = createEffect(() => this._actions$.pipe(
    ofType<AddNotifications>(ENotificationsActions.AddNotifications),
    map((action: AddNotifications) => action.payload),
    filter(notificationResponse => isElementNull((notificationResponse as IDeviceStatusResponse).Alert)),
    map(notificationResponse => this._convertToNotificationModel(<INotificationResponse>notificationResponse)),
    map(notification => new AddNotificationsSuccess(notification))
  ));

  public closeNonPanicNotification$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CloseNonPanicNotification>(ENotificationsActions.CloseNonPanicNotification),
    map((action: CloseNonPanicNotification) => action.payload),
    tap((params: INotificationClosingParams) => {
      this._notificationsService.updatePersistedNotification(this._convertToServiceModel(params.notification)).subscribe();
      if (params.clicked) {
        if (params.notification.settingType === ENotificationSettingsTypes.exceededAlertLimit) {
          this._redirectService.redirectToUrl(`/Alerts/?v=l`);
        } else if (params.notification.type === ENotificationTypes.videoAlert) {
          this._redirectService.redirectToVideoEventPage(+params.notification.id);
        }
      }
    })
  ), { dispatch: false });

  public reviewPanic$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<ReviewPanic>(ENotificationsActions.ReviewPanic),
    map((action: ReviewPanic) => action.payload),
    tap((param: INotification) => {
      this._notificationsService.reviewPanic(this._convertToServiceModel(param)).subscribe();
    }),
    map(notification => new SelectVehicle(`v${notification.vehicleId}`))
  ));

  public acknowledgePanic$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<AcknowledgePanic>(ENotificationsActions.AcknowledgePanic),
    map((action: AcknowledgePanic) => action.payload),
    tap((param: INotification) => {
      this._notificationsService.acknowledgePanic(this._convertToServiceModel(param)).subscribe();
    })
  ), { dispatch: false });

  public reviewDsnAsCorrect$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<ReviewAsCorrect>(ENotificationsActions.ReviewAsCorrect),
    map((action: ReviewAsCorrect) => action.payload),
    switchMap((parameter: IDeviceStatusRequest) => {
      return this._deviceStatusService.saveUserAction(this._convertToDsnServiceModel(parameter)).pipe(
        map(response => new ReviewAsCorrectSuccess(parameter.notification)),
        catchError(error => of(new ReviewDeviceStatusNotificationError()))
      );
    })
  ));

  public reviewDsnAsIncorrect$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<ReviewAsIncorrect>(ENotificationsActions.ReviewAsIncorrect),
    map((action: ReviewAsIncorrect) => action.payload),
    switchMap((parameter: IDeviceStatusRequest) => {
      return this._deviceStatusService.saveUserAction(this._convertToDsnServiceModel(parameter)).pipe(
        map(response => new ReviewAsIncorrectSuccess(parameter.notification)),
        catchError(error => of(new ReviewDeviceStatusNotificationError()))
      );
    })
  ));

  public reviewDsnAsSeen$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<ReviewAsSeen>(ENotificationsActions.ReviewAsSeen),
    map((action: ReviewAsSeen) => action.payload),
    switchMap((parameter: IDeviceStatusRequest) => {
      return this._deviceStatusService.saveUserAction(this._convertToDsnServiceModel(parameter)).pipe(
        map(response => new ReviewAsSeenSuccess(parameter.notification)),
        catchError(error => of(new ReviewDeviceStatusNotificationError()))
      );
    })
  ));

  public getPersistedNotifications$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetPersistedNotifications>(ENotificationsActions.GetPersistedNotifications),
    switchMap(() =>
      this._store.pipe(select(getDeviceStatusNotificationsEnabled)).pipe(
        skipWhile(deviceStatusNotificationsEnabled => isElementNull(deviceStatusNotificationsEnabled as null)),
        switchMap((deviceStatusNotificationsEnabled: boolean) => {
          return forkJoin([
            deviceStatusNotificationsEnabled ? this._deviceStatusService.getDeviceStatusAlerts() : of([]),
            this._notificationsService.getPersistedNotifications()
          ]).pipe(
            map(([deviceStatusAlerts, persistedNotifications]) => {
              if (isArrayNullOrEmpty(deviceStatusAlerts) && isArrayNullOrEmpty(persistedNotifications)) {
                return [];
              }
              return deviceStatusAlerts.filter(alert => !alert.HasExclusiveAction && !alert.AlertSeen).concat(persistedNotifications);
            })
          );
        }),
        switchMap((notifications: (IDeviceStatusAlertsResponse | INotificationResponse)[]) => {
          const notificationsObservables: Observable<INotification>[] = [];
          notifications.forEach((notification: IDeviceStatusAlertsResponse | INotificationResponse) => {
            if ((notification as IDeviceStatusAlertsResponse).DeviceStatusMessageTimeFormatted) {
              const convertedNotification$ = this._deviceStatusService
                .getVehicleDetails((notification as IDeviceStatusAlertsResponse).VehicleId.toString())
                .pipe(map(response => this._convertFromPolledDsnToNotification(notification as IDeviceStatusAlertsResponse, response)));
              notificationsObservables.push(convertedNotification$);
            } else {
              notificationsObservables.push(of(this._convertToNotificationModel(notification as INotificationResponse)));
            }
          });

          return forkJoin(notificationsObservables);
        }),
        map(notifications => new GetPersistedNotificationsSuccess(notifications))
      )
    )
  ));

  public getNotificationInfo$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetNotificationInfo>(ENotificationsActions.GetNotificationInfo),
    map(action => action.payload),
    switchMap((parameters: INotificationDetailsParams) => this._alertService.getAlertLogInfo(parameters)),
    map((notification: INotificationDetailsResponse) => {
      const notificationsConverted: INotificationDetails = {
        notificationRuleName: notification.AlertRuleName,
        notificationType: notification.AlertType,
        notificationTypeName: notification.AlertTypeName,
        vehicleName: notification.VehicleName,
        vehicleNumber: notification.VehicleNumber,
        driverName: notification.DriverName,
        driverEmail: notification.DriverEmail,
        driverPhone: notification.DriverPhone,
        triggerType: notification.TriggerType,
        threshold: notification.Threshold,
        dateTime: notification.DateTime,
        location: notification.Location,
        coordinates: {
          Latitude: notification.Latitude,
          Longitude: notification.Longitude
        },
        confirmedBy: notification.ConfirmedBy
      };

      return new GetNotificationInfoSuccess(notificationsConverted);
    })
  ));

  public onDeviceStatusNotificationUpdateTowingVehicles$ = createEffect(() => this._actions$.pipe(
    ofType<AddNotifications>(ENotificationsActions.AddNotifications),
    filter((action: AddNotifications) => !isElementNull((action.payload as IDeviceStatusResponse).Alert)),
    map((action: AddNotifications) => <IDeviceStatusResponse>action.payload),
    withLatestFrom(
      this._store.pipe(select(getVehiclePlots)),
      this._store.pipe(select(getTowingVehicles)),
      this._store.pipe(select(getMapHierarchy))
    ),
    map(([notification, vehiclePlots, towingVehicles, hierarchyItems]) => {
      // if this is a new notification create a new towing vehicle object
      let towingVehicle = towingVehicles.find(plot => plot.id === notification.Alert.VehicleId);
      if (isElementNull(towingVehicle)) {
        towingVehicle = this._getTowingVehicleToAdd(vehiclePlots, notification, hierarchyItems);
        return new AddTowingVehicles([towingVehicle]);
      } else {
        // existing notification, update towing vehicle
        towingVehicle = this._getTowingVehicleToUpdate(towingVehicle, notification);
        return new UpdateTowingVehicle(towingVehicle);
      }
    })
  ));

  public onGetPersistedNotificationsSuccessAddTowingVehicles$ = createEffect(() => this._actions$.pipe(
    ofType<GetPersistedNotificationsSuccess>(ENotificationsActions.GetPersistedNotificationsSuccess),
    map(action => action.payload),
    map(notifications =>
      notifications.filter(
        notification =>
          !isElementNull(notification.troubleCode) &&
          (notification.troubleCode === EDeviceStatusCode.towing || notification.troubleCode === EDeviceStatusCode.installationError)
      )
    ),
    withLatestFrom(this._store.pipe(select(getVehiclePlots))),
    map(([towingNotifications, vehicleplots]) =>
      towingNotifications.map(towingNotification => {
        const vehiclePlot = vehicleplots.find(plot => plot.id === towingNotification.vehicleId);
        const originalIconClass = !isElementNull(vehiclePlot) ? vehiclePlot.iconClass : null;
        const plotType = vehiclePlot?.type ?? EPlotType.vehicle;
        const towingVehicle: ITowingVehicle = {
          id: towingNotification.vehicleId,
          originalIconClass: originalIconClass,
          iconClass: this._getDsnIcon(towingNotification.troubleCode, towingNotification.exclusiveActionType, plotType),
          lastUpdatedTime: towingNotification.lastUpdate,
          actionType: towingNotification.exclusiveActionType,
          troubleCode: towingNotification.troubleCode,
          plotType: plotType
        };
        return towingVehicle;
      })
    ),
    map(towingVehicles => new AddTowingVehicles(towingVehicles))
  ));

  public onReviewAsIncorrectUpdateTowingVehicle$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<ReviewAsIncorrectSuccess>(ENotificationsActions.ReviewAsIncorrectSuccess),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(select(getTowingVehicles))),
    map(
      ([notification, vehiclePlots]): ITowingVehicle => {
        const towingVehicle = vehiclePlots.find(vehicle => vehicle.id === notification.vehicleId);
        return { ...towingVehicle, iconClass: EPlotIcon.CustomerCare, actionType: EDeviceStatusActionType.confirmedAsIncorrect };
      }
    ),
    map(towingVehicle => new UpdateTowingVehicle(towingVehicle))
  ));

  public onReviewAsCorrectUpdateTowingVehicle$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<ReviewAsCorrectSuccess>(ENotificationsActions.ReviewAsCorrectSuccess),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(select(getTowingVehicles))),
    map(
      ([notification, vehiclePlots]): ITowingVehicle => {
        const towingVehicle = vehiclePlots.find(vehicle => vehicle.id === notification.vehicleId);
        return { ...towingVehicle, actionType: EDeviceStatusActionType.confirmedAsCorrect };
      }
    ),
    map(towingVehicle => new UpdateTowingVehicle(towingVehicle))
  ));

  public onReviewAsSeenUpdateTowingVehicle$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<ReviewAsSeenSuccess>(ENotificationsActions.ReviewAsSeenSuccess),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(select(getTowingVehicles))),
    map(
      ([notification, vehiclePlots]): ITowingVehicle => {
        const towingVehicle = vehiclePlots.find(vehicle => vehicle.id === notification.vehicleId);
        return { ...towingVehicle, actionType: EDeviceStatusActionType.seen };
      }
    ),
    map(towingVehicle => new UpdateTowingVehicle(towingVehicle))
  ));

  constructor(
    private readonly _actions$: Actions,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _notificationsService: NotificationsService,
    private readonly _deviceStatusService: DeviceStatusHttpService,
    private readonly _redirectService: RedirectService,
    private readonly _alertService: AlertService,
    private readonly _store: Store<IAppState>
  ) {}

  private _getUpdatedNotification(existingNotification: INotification, receivedNotification: IDeviceStatusResponse): INotification {
    return {
      ...existingNotification,
      alertSource: receivedNotification.AlertSource,
      alertSeen: receivedNotification.Metadata.AlertSeen,
      exclusiveActorFirstName: receivedNotification.Metadata.ExclusiveActorFirstName,
      exclusiveActorLastName: receivedNotification.Metadata.ExclusiveActorLastName,
      messageTimeFormatted: receivedNotification.Metadata.MessageTimeFormatted,
      exclusiveActionType: receivedNotification.Alert.ExclusiveActionType,
      exclusiveActionBy: receivedNotification.Alert.ExclusiveActionBy,
      messageDateUtc: receivedNotification.Alert.MessageDateUtc,
      lastUpdate: new Date().getTime()
    };
  }

  private _isExpired<T>(items: T[], getLastUpdatedMs: (item: T) => number): T[] {
    const now = Date.now();

    return items.filter(item => now - getLastUpdatedMs(item) > deviceStatusNotificationExpirationInSeconds * 1000);
  }

  private _getExpiredTowingVehicles(towingVehicles: ITowingVehicle[]): ITowingVehicle[] {
    return this._isExpired(towingVehicles, towingVehicle => towingVehicle.lastUpdatedTime);
  }

  private _getExpiredNotifications(notifications: INotification[]): INotification[] {
    return this._isExpired(notifications, notification => notification.lastUpdate);
  }

  private _convertToNotificationModel(notificationResponse: INotificationResponse): INotification {
    const notification: INotification = {
      id: notificationResponse.Id,
      type: notificationResponse.meta,
      title: notificationResponse.title,
      vehicleId: notificationResponse.vid,
      coordinates: {
        Latitude: notificationResponse.lat,
        Longitude: !isElementNull(notificationResponse.lng) ? notificationResponse.lng : notificationResponse.long
      },
      badge: notificationResponse.badge,
      db: notificationResponse.db,
      settingType: notificationResponse.type,
      content: notificationResponse.body,
      temporaryPopup: true
    };

    return notification;
  }

  private _convertToServiceModel(notification: INotification): INotificationResponse {
    const notificationResponse: INotificationResponse = {
      Id: notification.id,
      body: notification.content,
      title: notification.title,
      meta: notification.type,
      vid: notification.vehicleId,
      lat: notification.coordinates ? notification.coordinates.Latitude : null,
      lng: notification.coordinates ? notification.coordinates.Longitude : null,
      db: notification.db,
      type: notification.settingType,
      badge: notification.badge
    };

    return notificationResponse;
  }

  private _convertToDsnServiceModel(data: IDeviceStatusRequest): IDeviceStatusParams {
    const dataForService: IDeviceStatusParams = {
      VehicleId: data.notification.vehicleId,
      TroubleCode: data.notification.troubleCode,
      Action: data.action
    };

    if (data.modalData) {
      dataForService.ActionMetaInfo = {
        Email: data.modalData.email ? data.modalData.email : '',
        Phone: data.modalData.phone ? data.modalData.phone : '',
        Name: data.modalData.name
      };
    }

    return dataForService;
  }

  private _convertFromDsnToNotification(
    deviceStatusNotification: IDeviceStatusResponse,
    vehicleInfo: IVehicleDetailsResponse
  ): INotification {
    return {
      id: deviceStatusNotification.Alert.VehicleId.toString(),
      type: ENotificationTypes.deviceStatusChange,
      title: vehicleInfo.VehicleLabel,
      vehicleId: deviceStatusNotification.Alert.VehicleId,
      coordinates: {
        Latitude: null,
        Longitude: null
      },
      alertSource: deviceStatusNotification.AlertSource,
      alertSeen: deviceStatusNotification.Metadata.AlertSeen,
      driverName: vehicleInfo.Driver,
      messageTimeFormatted: deviceStatusNotification.Metadata.MessageTimeFormatted,
      exclusiveActionBy: deviceStatusNotification.Alert.ExclusiveActionBy,
      exclusiveActionType: deviceStatusNotification.Alert.ExclusiveActionType,
      messageDateUtc: deviceStatusNotification.Alert.MessageDateUtc,
      troubleCode: deviceStatusNotification.Alert.TroubleCode,
      exclusiveActorFirstName: deviceStatusNotification.Metadata.ExclusiveActorFirstName,
      exclusiveActorLastName: deviceStatusNotification.Metadata.ExclusiveActorLastName,
      lastUpdate: Date.now()
    };
  }

  private _convertFromPolledDsnToNotification(
    deviceStatusNotification: IDeviceStatusAlertsResponse,
    vehicleInfo: IVehicleDetailsResponse
  ): INotification {
    return {
      id: deviceStatusNotification.VehicleId.toString(),
      type: ENotificationTypes.deviceStatusChange,
      title: vehicleInfo.VehicleLabel,
      vehicleId: deviceStatusNotification.VehicleId,
      coordinates: {
        Latitude: null,
        Longitude: null
      },
      alertSeen: deviceStatusNotification.AlertSeen,
      driverName: vehicleInfo.Driver,
      messageTimeFormatted: deviceStatusNotification.DeviceStatusMessageTimeFormatted,
      exclusiveActionBy: deviceStatusNotification.ExclusiveActionBy,
      exclusiveActionType: deviceStatusNotification.ExclusiveActionType,
      troubleCode: deviceStatusNotification.TroubleCode,
      lastUpdate: new Date().getTime()
    };
  }

  private _getTowingVehicleToAdd(
    selectedVehiclePlots: IVehiclePlot[],
    notification: IDeviceStatusResponse,
    hierarchyItems: IMapHierarchyItem[]
  ): ITowingVehicle {
    const currentVehiclePlot = selectedVehiclePlots.find(vehicle => vehicle.id === notification.Alert.VehicleId);
    const hierarchyType = hierarchyItems.find(item => item.id === `v${notification.Alert.VehicleId}`)?.type;
    const plotType = hierarchyType === ENodeType.PoweredAsset ? EPlotType.poweredAsset : EPlotType.vehicle;
    const newIcon = this._getDsnIcon(notification.Alert.TroubleCode, notification.Alert.ExclusiveActionType, plotType);
    const oldIcon = !isElementNull(currentVehiclePlot) ? currentVehiclePlot.iconClass : newIcon;
    const towingVehicle: ITowingVehicle = {
      id: notification.Alert.VehicleId,
      originalIconClass: oldIcon,
      iconClass: newIcon,
      lastUpdatedTime: this._parseDate(notification.Alert.MessageDateUtc).getTime(),
      actionType: notification.Alert.ExclusiveActionType,
      troubleCode: notification.Alert.TroubleCode,
      plotType: plotType
    };

    return towingVehicle;
  }

  private _getTowingVehicleToUpdate(towingVehicle: ITowingVehicle, notification: IDeviceStatusResponse): ITowingVehicle {
    return {
      ...towingVehicle,
      lastUpdatedTime: this._parseDate(notification.Alert.MessageDateUtc).getTime(),
      actionType: notification.Alert.ExclusiveActionType,
      troubleCode: notification.Alert.TroubleCode,
      iconClass: this._getDsnIcon(notification.Alert.TroubleCode, notification.Alert.ExclusiveActionType, towingVehicle.plotType)
    };
  }

  private _getDsnIcon(troubleCode: EDeviceStatusCode, exclusiveActionType: EDeviceStatusActionType, plotType: EPlotType): EPlotIcon {
    const isPoweredAsset = plotType === EPlotType.poweredAsset;
    if (troubleCode === EDeviceStatusCode.installationError || exclusiveActionType === EDeviceStatusActionType.confirmedAsIncorrect) {
      return isPoweredAsset ? EPlotIcon.CustomerCarePoweredAsset : EPlotIcon.CustomerCare;
    }
    return isPoweredAsset ? EPlotIcon.TowingPoweredAsset : EPlotIcon.Towing;
  }

  private _getTowingVehiclesOfExpiredNotifications(
    towingVehicles: ITowingVehicle[],
    expiredNotifications: INotification[]
  ): ITowingVehicle[] {
    return towingVehicles.filter(towingVehicle => expiredNotifications.some(notification => +notification.id === towingVehicle.id));
  }

  private _parseDate(messageTimeUTC: string): Date {
    // notifications arriving from the server are not formatted as UTC! example: '2019-08-20T14:38:50'
    if (!messageTimeUTC.endsWith('Z')) {
      messageTimeUTC = `${messageTimeUTC}Z`;
    }
    const date = new Date(messageTimeUTC);
    return date;
  }
}
