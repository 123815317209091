<div
  class="navbar-container"
  [@hideNavbar]="animationState"
  [class.navbar-container--hidden]="isFullscreen$ | async"
  [class.navbar-container--sm]="(menuItems$ | async).length > 7"
>
  <fm-navbar
    ntfnConnector
    hlpConnector
    hlpConnectorArticleLabel="RevealMap"
    #helpPanel="hlpConnector"
    #notifications="ntfnConnector"
    (notificationBellClick)="notifications?.toggle()"
    (toggleHelpPanel)="helpPanel.toggle($event)"
    [isHelpPanelToggled]="helpPanel.toggled$ | async"
    [notificationsBellProperties]="navbarBellProperties$ | async"
  ></fm-navbar>
</div>
