/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  OnChanges,
  Input,
  SimpleChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
  HostBinding
} from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';

import { HierarchyTreeSelectionModel } from './hierarchy-tree-selection-model';
import { IHierarchyTreeNode, IHierarchyTreeData, IHierarchyTreeNodeRightClickData } from './models';
import { FlatTreeDataSource, FlatTreeVirtualScrollingDataSource } from '../shared';

@Component({
  selector: 'fm-hierarchy-tree',
  templateUrl: './hierarchy-tree.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyTreeComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input()
  treeData: IHierarchyTreeData<string>;
  @Input()
  maxSelectionMap: Map<number, number>;

  @Output()
  nodeClick = new EventEmitter<IHierarchyTreeNode<string>>();
  @Output()
  nodeTextRightClick = new EventEmitter<IHierarchyTreeNodeRightClickData<string>>();
  @Output()
  selectionChange = new EventEmitter<IHierarchyTreeNode<string>[]>();

  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll: CdkVirtualScrollViewport;

  @HostBinding('class.hierarchy-ready')
  isHierarchyReady = false;

  checklistSelection: HierarchyTreeSelectionModel<string>;
  dataSource: FlatTreeDataSource<IHierarchyTreeNode<string>>;
  expandedNodesDataSource: FlatTreeVirtualScrollingDataSource<IHierarchyTreeNode<string>>;
  treeControl: FlatTreeControl<IHierarchyTreeNode<string>>;
  readonly treeNodeSize = 24;

  private _selectionChangeSubscription: Subscription;
  private readonly _virtualScrollingRangeChange$: Observable<ListRange>;
  private readonly _virtualScrollingRangeChangeSubject = new Subject<ListRange>();
  private _virtualScrollingRenderedRangeSubscription: Subscription;

  constructor() {
    this._virtualScrollingRangeChange$ = this._virtualScrollingRangeChangeSubject.asObservable();
  }

  ngAfterViewInit(): void {
    this._virtualScrollingRenderedRangeSubscription = this.virtualScroll.renderedRangeStream.subscribe(range => {
      this._virtualScrollingRangeChangeSubject.next(range);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.treeData.data && this.treeData.data.length > 0) {
      this.isHierarchyReady = true;
    }
    // We are assuming maxSelectionMap comes at the same time or even before the treeData
    if (changes['treeData']) {
      this._handleTreeDataReceived();
    }
  }

  ngOnDestroy(): void {
    if (this._selectionChangeSubscription) {
      this._selectionChangeSubscription.unsubscribe();
    }
    if (this._virtualScrollingRenderedRangeSubscription) {
      this._virtualScrollingRenderedRangeSubscription.unsubscribe();
    }
  }

  getLevel = (node: IHierarchyTreeNode<string>) => node.level;
  hasChild = (_: number, node: IHierarchyTreeNode<string>) => node.isExpandable;
  isExpandable = (node: IHierarchyTreeNode<string>) => node.isExpandable;

  isSelected(node: IHierarchyTreeNode<string>): boolean {
    const isSelected = this.checklistSelection.isSelected(node);
    return isSelected;
  }

  toggleSelection(node: IHierarchyTreeNode<string>): void {
    this.checklistSelection.toggle(node);
  }

  onNodeClick(treeNode: IHierarchyTreeNode<string>): void {
    if (!this.isSelected(treeNode)) {
      this.toggleSelection(treeNode);
    }

    this.nodeClick.emit(treeNode);
  }

  onNodeContentRightClick($event: MouseEvent, treeNode: IHierarchyTreeNode<string>): void {
    const treeNodeRightClickData: IHierarchyTreeNodeRightClickData<string> = {
      eventPageX: $event.pageX,
      eventPageY: $event.pageY,
      treeNode: treeNode
    };
    this.nodeTextRightClick.emit(treeNodeRightClickData);
  }

  private _handleTreeDataReceived(): void {
    if (this.treeData.isNewDataSource || !this.dataSource) {
      const range: ListRange = { start: 0, end: 50 };
      if (this.virtualScroll) {
        const viewportSize = this.virtualScroll.getViewportSize();
        range.end = Math.round(viewportSize / this.treeNodeSize + 10);
        this.virtualScroll.setRenderedRange(range);
        this.virtualScroll.scrollToIndex(0);
      }

      this.treeControl = new FlatTreeControl<IHierarchyTreeNode<string>>(this.getLevel, this.isExpandable);
      this.dataSource = new FlatTreeDataSource(this.treeControl, this.treeData.data, this._virtualScrollingRangeChange$, range);
      this.expandedNodesDataSource = new FlatTreeVirtualScrollingDataSource(this.treeControl);
      this.checklistSelection = new HierarchyTreeSelectionModel(this.treeControl, this.maxSelectionMap, this.treeData.areIdsUnique);

      this._initializeExpansion();
      this._setupSelectionChange();
    } else {
      this.checklistSelection.updateSelection(this.treeData.data);
    }
  }

  private _initializeExpansion(): void {
    for (let i = 0; i < this.treeData.data.length; i++) {
      const node = this.treeData.data[i];
      if (node.level === 0 || this.checklistSelection.isIndeterminate(node)) {
        this.treeControl.expand(node);
      }
    }
  }

  private _setupSelectionChange(): void {
    if (this._selectionChangeSubscription) {
      this._selectionChangeSubscription.unsubscribe();
    }

    this._selectionChangeSubscription = this.checklistSelection.selectionChange$.subscribe(selectedNodes =>
      this.selectionChange.emit(selectedNodes)
    );
  }
}
