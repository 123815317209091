/*!
 * Copyright © 2020-2021. Verizon Connect Ireland Limited. All rights reserved.
 */
import { NgModule } from '@angular/core';

import { MapIconRegistryService } from './core/services';

import dsSprite from '!!raw-loader!../../node_modules/@fleetmatics/ui.base-library/styles/svg/ds-sprite.svg';
import iconsSprite from '!!raw-loader!../../node_modules/@fleetmatics/ui.navbar/assets/icons-sprite.svg';

@NgModule()
export class AppIconModule {
  constructor(private readonly _mapIconRegistryService: MapIconRegistryService) {
    this._registerIcons();
  }
  private _registerIcons() {
    this._mapIconRegistryService.addSvgIconSet(dsSprite);
    this._mapIconRegistryService.addSvgIconSet(iconsSprite);
  }
}
