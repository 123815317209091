/*
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isElementNull, ConfigService } from '@fleetmatics/ui.utilities';

import {
  ESearchEntitySubType,
  ESearchEntityType,
  ISearchLightCategory,
  ISearchLightRequestOptions,
  ISearchLightResponse
} from '../../model';
import { IAppConfig } from '../../../config';

@Injectable({
  providedIn: 'root'
})
export class FmSearchLightService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.searchlightApiEndpoint;
  }

  public getResults(
    searchText: string,
    entityTypes: ESearchEntityType,
    options: ISearchLightRequestOptions
  ): Observable<ISearchLightResponse> {
    let params = new HttpParams();
    params = params.append('request[SearchText]', searchText.trim());
    params = params.append('request[EntityTypes]', entityTypes.toString());

    if (!isElementNull(options)) {
      params = params.append('request[Options]', JSON.stringify(options));
    }

    return this._httpClient
      .get<ISearchLightCategory[]>(`${this._rootUrl}/v2/Search`, { params })
      .pipe(
        map(categories => {
          const response: ISearchLightResponse = { Data: categories };
          // ESearchEntityType is parsed as the string value - cast to the enum / number representation
          if (response.Data) {
            response.Data?.forEach(c =>
              c.items?.forEach(i => {
                i.type = (<any>ESearchEntityType)[i.type];
                i.subtype = (<any>ESearchEntitySubType)[i.subtype];
              })
            );
          }
          return response;
        })
      );
  }
}
