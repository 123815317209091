/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, forkJoin, timer, of } from 'rxjs';
import { map, switchMap, withLatestFrom, filter, tap, takeUntil, catchError, concatMap, delay } from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

import { isElementNull, ConfigService } from '@fleetmatics/ui.utilities';

import { IVehicleTachoStatus } from 'tacho';

import { WorkingTimeDirectiveHttpService, ModalWindowService, BalloonService } from '../../services';
import {
  GetDriversStatusSuccess,
  EWorkingTimeDirectiveActions,
  UpdateVehicleBalloonWorkingTimeDirective,
  GetVehiclePlotsSuccess,
  EMapPlotsActions,
  OpenDriverPlanner,
  GetDriversStatus,
  GetVehiclesTachoStatusSuccess,
  UpdateVehiclesDriverName,
  GetVehiclesTachoStatusError,
  GetDriversStatusError,
  GetVehiclesTachoStatus,
  StartTachoCardHighlight,
  StopTachoCardHighlight
} from '../actions';
import { IAppState } from '../state';
import { getVehiclePlots, getWorkingTimeDirectiveDisplayEnabled, getBalloon, getTachoEnabledVehicleIds } from '../selectors';
import {
  IDriverStatus,
  IVehicleIsTachoEnabled,
  VehicleMarkerInfo,
  IVehiclePlot,
  LatLng,
  IMarkerOptions,
  IVehicleWorkOrderBalloon,
  IVehiclesDriverNameResponse,
  IVehicleTachoInfo
} from '../../models';
import { IAppConfig } from '../../../config';
import { delayedRetry } from '../../operators';

export const WORKING_TIME_DIRECTIVE_DEFAULT_INTERVAL = 90000;

@Injectable()
export class WorkingTimeDirectiveEffects {

  public startWorkingTimeDirectiveStatusTimer$: Observable<Action> = createEffect(() => this._store.pipe(
    select(getWorkingTimeDirectiveDisplayEnabled),
    filter(displayEnabled => displayEnabled && isPlatformBrowser(this._platformId)),
    switchMap(() => {
      const workingTimeDirective = this._configService.config.workingTimeDirectiveInterval || WORKING_TIME_DIRECTIVE_DEFAULT_INTERVAL;
      return timer(0, workingTimeDirective).pipe(
        takeUntil(
          this._store.pipe(
            select(getWorkingTimeDirectiveDisplayEnabled),
            filter(displayEnabled => !displayEnabled)
          )
        )
      );
    }),
    map(() => new GetDriversStatus())
  ));

  public updateWorkingTimeDirectiveForSelectedVehicles$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetVehiclePlotsSuccess>(EMapPlotsActions.GetVehiclePlotsSuccess),
    withLatestFrom(this._store.pipe(select(getWorkingTimeDirectiveDisplayEnabled))),
    filter(([, displayEnabled]) => displayEnabled),
    map(() => new GetDriversStatus())
  ));

  public getDriversStatus$ = createEffect(() => this._actions$.pipe(
    ofType<GetDriversStatus>(EWorkingTimeDirectiveActions.GetDriversStatus),
    withLatestFrom(this._store.pipe(select(getVehiclePlots))),
    filter(([, vehiclePlots]: [Action, null | IVehiclePlot[]]) => !isElementNull(vehiclePlots)),
    switchMap(([, vehiclePlots]: [Action, IVehiclePlot[]]) => {
      const vehicleIds: number[] = vehiclePlots.map(vehiclePlot => vehiclePlot.id);
      return forkJoin([
        vehicleIds.length > 0
          ? this._workingTimeDirectiveHttpService.getVehiclesTachoStatus(vehicleIds).pipe(catchError(() => of(null)))
          : of([]),
        vehicleIds.length > 0
          ? this._workingTimeDirectiveHttpService.getIsTachoEnabledForVehicles(vehicleIds).pipe(catchError(() => of(null)))
          : of([]),
        vehicleIds.length > 0 ? this._balloonService.getVehiclesDriverName(vehicleIds).pipe(catchError(() => of(null))) : of([])
      ]).pipe(
        map(([tachoStatuses, tachoEnabledStatuses, vehiclesDriverName]) => {
          if (isElementNull(tachoStatuses) || isElementNull(tachoEnabledStatuses)) {
            return <[IDriverStatus[], IVehiclePlot[]]>[null, null];
          }

          return this._populateDriverStatusesWithTachoAndPlotData(tachoStatuses, tachoEnabledStatuses, vehiclesDriverName, vehiclePlots);
        }),
        withLatestFrom(this._store.pipe(select(getBalloon))),
        map(([[driverStatusData, updatedVehiclePlots], balloon]) => {
          if (!isElementNull(balloon) && !isElementNull(driverStatusData) && !isElementNull(updatedVehiclePlots)) {
            const driverStatus = driverStatusData.find(data => data.markerInfo.id === balloon.id);
            if (!isElementNull(driverStatus)) {
              (balloon as IVehicleWorkOrderBalloon).tacho.status = driverStatus.vehicleTachoInfo.status;
              (balloon as IVehicleWorkOrderBalloon).tacho.isEnabled = driverStatus.vehicleTachoInfo.isEnabled;
              (balloon as IVehicleWorkOrderBalloon).driverName = driverStatus.driverName;
              return <[IDriverStatus[], IVehiclePlot[], IVehicleWorkOrderBalloon]>[driverStatusData, updatedVehiclePlots, balloon];
            }
          }
          return <[IDriverStatus[], IVehiclePlot[], IVehicleWorkOrderBalloon]>[driverStatusData, updatedVehiclePlots, null];
        }),
        switchMap(([driverStatusData, updatedVehiclePlots, updatedBalloon]) => {
          if (isElementNull(driverStatusData) && isElementNull(updatedVehiclePlots)) {
            return [new GetDriversStatusError()];
          }

          if (updatedBalloon) {
            return [
              new GetDriversStatusSuccess(driverStatusData),
              new UpdateVehiclesDriverName(updatedVehiclePlots),
              new UpdateVehicleBalloonWorkingTimeDirective(updatedBalloon)
            ];
          } else {
            return [new GetDriversStatusSuccess(driverStatusData), new UpdateVehiclesDriverName(updatedVehiclePlots)];
          }
        }),
        catchError(() => of(new GetDriversStatusError()))
      );
    })
  ));

  public getVehiclesTachoStatus$ = createEffect(() => this._actions$.pipe(
    ofType<GetVehiclesTachoStatus>(EWorkingTimeDirectiveActions.GetVehiclesTachoStatus),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(select(getTachoEnabledVehicleIds))),
    map(([vehicleIds, tachoEnabledIds]) => {
      const allowedIds = new Set<number>(tachoEnabledIds);
      return vehicleIds.filter(id => allowedIds.has(id));
    }),
    filter(vehicleIds => vehicleIds.length > 0),
    switchMap(vehicleIds => {
      return this._workingTimeDirectiveHttpService.getVehiclesTachoStatus(vehicleIds).pipe(
        delayedRetry(),
        map(vehiclesTachoStatus =>
          vehiclesTachoStatus.map(vehicleTachoStatus => ({
            status: vehicleTachoStatus,
            isEnabled: true,
            isUpdating: true // this will cause the cards to highlight
          }))
        ),
        map((vehiclesTachoStatusInfo: IVehicleTachoInfo[]) => new GetVehiclesTachoStatusSuccess(vehiclesTachoStatusInfo)),
        catchError(() => of(new GetVehiclesTachoStatusError()))
      );
    })
  ));

  public GetVehiclesTachoStatusSuccess$ = createEffect(() => this._actions$.pipe(
    ofType<GetVehiclesTachoStatusSuccess>(EWorkingTimeDirectiveActions.GetVehiclesTachoStatusSuccess),
    map(action => action.payload),
    map(tachoUpdates => tachoUpdates.filter(u => u.isUpdating)), // only highlight items with this property
    map(tachoUpdates => tachoUpdates.map(tacho => new StartTachoCardHighlight(tacho))),
    concatMap(tachoUpdates => tachoUpdates)
  ));

  public StopTachoCardHighlight$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<StartTachoCardHighlight>(EWorkingTimeDirectiveActions.StartTachoCardHighlight),
    map(action => action.payload),
    delay(1000),
    map(tacho => {
      tacho.isUpdating = false;
      return tacho;
    }),
    map(tacho => new StopTachoCardHighlight(tacho))
  ));

  public openDriverPlanner$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<OpenDriverPlanner>(EWorkingTimeDirectiveActions.OpenDriverPlanner),
    tap(() => {
      const driverPlannerPagePath: string = this._configService.config.driverPlannerPagePath;
      this._modalWindowService.redirectToUrl(`${driverPlannerPagePath}`);
    })
  ), { dispatch: false });

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _workingTimeDirectiveHttpService: WorkingTimeDirectiveHttpService,
    private readonly _balloonService: BalloonService,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _modalWindowService: ModalWindowService,
    private readonly _configService: ConfigService<IAppConfig>
  ) {}

  private _populateDriverStatusesWithTachoAndPlotData(
    tachoStatuses: IVehicleTachoStatus[],
    tachoEnabledStatuses: IVehicleIsTachoEnabled[],
    vehiclesDriverName: IVehiclesDriverNameResponse[],
    plots: IVehiclePlot[]
  ): [IDriverStatus[], IVehiclePlot[]] {
    const enabledTachoStatusMap: any = tachoEnabledStatuses.reduce((accumulator: any, status) => {
      if (status.IsEnabled) {
        accumulator[status.VehicleId] = tachoStatuses.find(tachoStatus => tachoStatus.VehicleId === status.VehicleId);
      }
      return accumulator;
    }, {});

    const driverStatusFilteredArray: IDriverStatus[] = [];

    plots.forEach(vehiclePlot => {
      let tachoStatus: IVehicleTachoStatus;
      let driverName = vehiclePlot.driverDisplayName;

      const hasTachoStatus = enabledTachoStatusMap[vehiclePlot.id];
      if (hasTachoStatus) {
        tachoStatus = enabledTachoStatusMap[vehiclePlot.id];
        if (!isElementNull(vehiclesDriverName)) {
          const vehicleDriver = vehiclesDriverName.find(vehicleDriverName => vehicleDriverName.vehicleId === tachoStatus.VehicleId);
          if (!isElementNull(vehicleDriver)) {
            driverName = vehicleDriver.driverName;
            vehiclePlot.driverDisplayName = vehicleDriver.driverName;
          }
        }

        const vehicleLatLng: LatLng = new LatLng(vehiclePlot.coordinates.lat, vehiclePlot.coordinates.lng);
        const vehicleMarkerInfo: VehicleMarkerInfo = new VehicleMarkerInfo(
          vehiclePlot.id,
          vehicleLatLng,
          vehiclePlot.vehicleName,
          vehiclePlot.type,
          vehiclePlot.status,
          vehiclePlot.driverId,
          vehiclePlot.hasNavigationDevice,
          { iconName: vehiclePlot.iconClass } as IMarkerOptions
        );

        driverStatusFilteredArray.push({
          icon: vehiclePlot.iconClass,
          vehicleTachoInfo: {
            status: tachoStatus,
            isEnabled: hasTachoStatus,
            isUpdating: false
          },
          vehicleName: vehiclePlot.vehicleName,
          driverName,
          markerInfo: vehicleMarkerInfo
        });
      }
    });

    return [driverStatusFilteredArray, plots];
  }
}
