/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { EVehicleDriverPanelTabs, Position, IMapContextMenuData, MarkerInfo } from '../../models';

export interface IVehicleDriverPanelState {
  isCollapsed: boolean;
  activeTab: EVehicleDriverPanelTabs;
  overlayMargins: Position;
  contextMenu: IMapContextMenuData<MarkerInfo>;
}

export const initialVehicleDriverPanelState: IVehicleDriverPanelState = {
  isCollapsed: false,
  activeTab: EVehicleDriverPanelTabs.VehicleStatus,
  overlayMargins: null,
  contextMenu: null
};
