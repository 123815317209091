/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ETrackableClassification {
  NotFound = 0,
  Vehicle = 1,
  NonPoweredAsset = 2,
  PoweredAsset = 3
}
