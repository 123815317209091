/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IMapHierarchyOptions, IMapHierarchyInfo, IMapHierarchyItem } from '../../models';

export interface ICustomerMetadataState {
  mapHierarchyInfo: IMapHierarchyInfo;
  mapHierarchyScoping: IMapHierarchyItem[];
  mapHierarchyOptions: IMapHierarchyOptions;
  userSpeedingThresholdKmh: number;
}

export const initialCustomerMetadataState: ICustomerMetadataState = {
  mapHierarchyInfo: {
    isNewMapHierarchy: true,
    mapHierarchy: []
  },
  mapHierarchyScoping: [],
  mapHierarchyOptions: null,
  userSpeedingThresholdKmh: null
};
