/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, Input, ViewChild, forwardRef, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ISearchLightComponent } from '../../search-light-component.interface';
import { ESearchEntityType, ISearchLightRequestOptions, ISearchLightResult } from '../../model';
import { FmSearchLightComponent } from '../../search-light.component';
import { ISearchLightInput } from '../search-light-input';
import { ISearchLightPanel } from '../search-light-panel';

export const SEARCH_LIGHT_FAB_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-forward-ref
  useExisting: forwardRef(() => FmSearchLightFabComponent),
  multi: true
};

@Component({
  selector: 'fm-search-light-fab',
  templateUrl: './search-light-fab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SEARCH_LIGHT_FAB_VALUE_ACCESSOR]
})
export class FmSearchLightFabComponent implements ISearchLightComponent {
  @Input()
  public placeholder: string;

  @Input()
  public containerWidth: number;

  @Input()
  public errorMessage: string;

  @Input()
  public minCharsMessage: string;

  @Input()
  public noResultMessage: string;

  @Input()
  public tooltipMessage: any;

  @Input()
  public entityTypes: ESearchEntityType;

  @Input()
  public options: ISearchLightRequestOptions;

  @ViewChild(FmSearchLightComponent, { static: false })
  public searchLightComponent: FmSearchLightComponent;

  public model: ISearchLightResult;
  public searchLightFabInput: ISearchLightInput;
  public searchLightFabPanel: ISearchLightPanel;

  public onChange: (model: ISearchLightResult) => void;
  public onTouch: () => void;

  public writeValue(model: ISearchLightResult): void {
    if (model !== undefined && model !== null) {
      this.model = model;
    }
  }

  public registerOnChange(onChange: (model: ISearchLightResult) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouch: () => void): void {
    this.onTouch = onTouch;
  }

  public onModelChanged(model: ISearchLightResult): void {
    this.model = model;
    this.onChange(model);
  }

  public onOpen(): void {
    this.searchLightComponent.getResults();
  }

  public onClose(clearValue: boolean): void {
    this.searchLightComponent.close(clearValue);
  }
}
