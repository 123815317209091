/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { isArrayNullOrEmpty } from '@fleetmatics/ui.utilities';

import { ISortItem, EVehiclesSortType, IVehiclePlot, EPlotType, IStatusPanelItem } from '../../models';
import { IVehicleSortService } from './vehicle-sort.service.interface';
import { SortUtils } from '../../utils';

@Injectable()
export class VehicleSortService implements IVehicleSortService {
  /*
  based on the "display driver as" setting, empty drivers will appear as follows:
  last name, first name - ', ', driver number - '', first name last name - ' '
  */
  private readonly _emptyDriverName = new Set<string>([', ', '', ' ']);

  public sortItems(type: EVehiclesSortType, statusPanelItems: IStatusPanelItem[]): number[] {
    if (!isArrayNullOrEmpty(statusPanelItems)) {
      switch (type) {
        case EVehiclesSortType.LastUpdate:
          return this._orderByLastUpdate(statusPanelItems);
        case EVehiclesSortType.Status:
          return this._orderByStatus(statusPanelItems);
        case EVehiclesSortType.Group:
          return this._orderByGroup(statusPanelItems);
        case EVehiclesSortType.Driver:
          return this._orderByDriver(statusPanelItems);
        default:
          return this._orderByType(type, statusPanelItems);
      }
    }
    return [];
  }
  private _orderByDriver(statusPanelItems: IStatusPanelItem[]): number[] {
    const itemsWithDrivers = new Set(
      statusPanelItems.filter(
        // only vehicles and powered assets can have drivers
        i => (i.type === EPlotType.vehicle || i.type === EPlotType.poweredAsset) && !this._emptyDriverName.has(i.driverDisplayName)
      )
    );
    const others = statusPanelItems.filter(i => !itemsWithDrivers.has(i));
    const orderedDrivers = this._orderByType(EVehiclesSortType.Driver, [...itemsWithDrivers]);
    const orderedOthers = this._orderByType(EVehiclesSortType.Vehicle, others);
    // display trackables with drivers first, then everything else ordered by name
    return [...orderedDrivers, ...orderedOthers];
  }

  private _orderByGroup(statusPanelItems: IStatusPanelItem[]): number[] {
    // order by name and then by group so that items within a group also get ordered
    const itemsOrderedByName = [...statusPanelItems].sort((a, b) =>
      SortUtils.naturalSortComparer(a.vehicleName || a.name, b.vehicleName || b.name)
    );

    return this._orderByType(EVehiclesSortType.Group, itemsOrderedByName);
  }

  private _orderByLastUpdate(vehiclesInfo: IStatusPanelItem[]): number[] {
    return [...vehiclesInfo].sort(this._tickComparer).map(vehicleInfo => vehicleInfo.id);
  }

  private _orderByStatus(statusPanelItems: IVehiclePlot[]): number[] {
    const itemsWithStatus = new Set(statusPanelItems.filter(i => i.type !== EPlotType.asset));

    const others = statusPanelItems.filter(i => !itemsWithStatus.has(i));

    const orderedItemsWithStatus = [...itemsWithStatus].sort(this._statusAndDurationComparer).map(vehicleInfo => vehicleInfo.id);
    const orderedOthers = this._orderByType(EVehiclesSortType.Vehicle, others);

    return [...orderedItemsWithStatus, ...orderedOthers];
  }

  private _orderByType(sortingType: EVehiclesSortType, vehiclesInfo: IVehiclePlot[]): number[] {
    const sortData: ISortItem[] = vehiclesInfo.map((vehicle: any) => this._getSortItem(vehicle, sortingType));
    return [...sortData].sort((a, b) => SortUtils.naturalSortComparer(a.Value, b.Value)).map(sortedItem => sortedItem.Id);
  }

  private _getSortItem(item: IStatusPanelItem, sortingType: EVehiclesSortType): ISortItem {
    const sortItem: ISortItem = {
      Id: item.id,
      Value: ''
    };

    if (item.type === EPlotType.vehicle || item.type === EPlotType.poweredAsset) {
      switch (sortingType) {
        case EVehiclesSortType.Driver:
          sortItem.Value = item.driverDisplayName;
          break;
        case EVehiclesSortType.Group:
          sortItem.Value = item.group;
          break;
        default:
          sortItem.Value = item.vehicleName;
          break;
      }
    } else if (item.type === EPlotType.asset) {
      sortItem.Value = sortingType !== EVehiclesSortType.Group ? item.name : '';
    }

    return sortItem;
  }

  private _tickComparer(a: IStatusPanelItem, b: IStatusPanelItem): number {
    // order by ticks
    return b.ticks !== a.ticks
      ? SortUtils.simpleValueComparer(b.ticks, a.ticks)
      : // if ticks are the same, prefer items that were processed together
      b.type !== EPlotType.asset && a.type !== EPlotType.asset
      ? a.batchTimestampTicks !== b.batchTimestampTicks
        ? SortUtils.simpleValueComparer(b.batchTimestampTicks, a.batchTimestampTicks)
        : // if items were processed in the same batch, sort by name
          SortUtils.naturalSortComparer(b.vehicleName, a.vehicleName)
      : // assets are not process in batches, simply sort by name
        SortUtils.naturalSortComparer(b.name, a.name);
  }

  private _statusAndDurationComparer(a: IVehiclePlot, b: IVehiclePlot): number {
    const a1 = [SortUtils.simpleValueComparer(a.status, b.status), -SortUtils.simpleValueComparer(a.duration, b.duration)],
      a2 = [SortUtils.simpleValueComparer(b.status, a.status), -SortUtils.simpleValueComparer(b.duration, a.duration)];
    return a1 < a2 ? -1 : 1;
  }
}
