/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IEnvironment } from './environment.interface';
import { IAppConfig } from '../app/config/app-config.interfaces';

export const environment: IEnvironment = {
  production: true,
  config: {
    revealFeature: 8,
    auth: {
      shouldKeepSessionAlive: true
    }
  } as IAppConfig,
  overrideBaseUrl: undefined
};
