/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EVehicleSelectionMode {
  Groups = 0,
  Vehicles,
  Drivers,
  Assets,
  PoweredAssets,
  EntireFleet
}
