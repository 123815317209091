/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IUserRoadsideAssistanceStatus } from '../../models';

export interface IRoadsideAssistanceState {
  isModalOpen: boolean;
  status: IUserRoadsideAssistanceStatus;
  vin: string;
}

export const initialRoadsideAssistanceState: IRoadsideAssistanceState = {
  isModalOpen: false,
  status: null,
  vin: null
};
