/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EDisplayVehicleAs {
  NotSet = 0,
  VehicleName = 1,
  VehicleNumber = 2,
  VehicleRegistrationNumber = 3
}
