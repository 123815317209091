/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IWorkOrder } from '../../models';

export interface IWorkOrderState {
  hasError: boolean;
  isEditWorkOrderModalOpen: boolean;
  isSaving: boolean;
  workOrderToEdit: IWorkOrder;
}

export const initialWorkOrderState: IWorkOrderState = {
  hasError: false,
  isEditWorkOrderModalOpen: false,
  isSaving: false,
  workOrderToEdit: null
};
