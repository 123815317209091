/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Location, isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { asyncScheduler, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom, observeOn, take, catchError } from 'rxjs/operators';

import { isElementNull } from '@fleetmatics/ui.utilities';

import {
  GetHarshDrivingEvent,
  EHarshDrivingEventsActions,
  GetHarshDrivingEventSuccess,
  TryUpdateZoomToMarker,
  UpdateIsMapFitted
} from '../actions';
import { getHarshDrivingEventMarker } from '../selectors';
import { IAppState } from '../state';
import { VideoEventHttpService } from '../../services';
import { delayedRetry } from '../../operators';

export const harshDrivingEventIdParamName = 'hdeId';
export const harshDrivingEventPageParamName = 'hdePage';

@Injectable()
export class HarshDrivingEventsEffects {

  harshDrivingEventRouteQueryParams$ = createEffect(() => this._activatedRoute.queryParamMap.pipe(
    filter(params => isPlatformBrowser(this._platformId) && params.has(harshDrivingEventIdParamName)),
    take(1),
    map(
      params =>
        new GetHarshDrivingEvent({ id: +params.get(harshDrivingEventIdParamName), eventPage: params.get(harshDrivingEventPageParamName) })
    )
  ));

  stopFitToMap$ = createEffect(() => this._actions$.pipe(
    ofType<GetHarshDrivingEvent>(EHarshDrivingEventsActions.GetHarshDrivingEvent),
    map(() => new UpdateIsMapFitted(false))
  ));

  getHarshDrivingEvent$ = createEffect(() => this._actions$.pipe(
    ofType<GetHarshDrivingEvent>(EHarshDrivingEventsActions.GetHarshDrivingEvent),
    switchMap(action => {
      const urlTree = this._router.parseUrl(this._router.url);
      urlTree.queryParams = {};
      this._location.replaceState(urlTree.toString());
      return this._videoEventHttpService.getEvent(action.payload.id).pipe(
        delayedRetry(),
        catchError(() => of(null))
      );
    }),
    filter(event => !isElementNull(event)),
    map(event => new GetHarshDrivingEventSuccess(event))
  ));

  getHarshDrivingEventSuccess$ = createEffect(() => this._actions$.pipe(
    ofType<GetHarshDrivingEventSuccess>(EHarshDrivingEventsActions.GetHarshDrivingEventSuccess),
    withLatestFrom(this._store.pipe(select(getHarshDrivingEventMarker))),
    switchMap(([, marker]) => [
      new TryUpdateZoomToMarker({
        id: marker.id,
        latLng: marker.latLng,
        title: marker.title,
        type: marker.type
      })
    ]),
    observeOn(asyncScheduler)
  ));

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _store: Store<IAppState>,
    private readonly _videoEventHttpService: VideoEventHttpService,
    private readonly _location: Location,
    @Inject(PLATFORM_ID) private readonly _platformId: Object
  ) {}
}
