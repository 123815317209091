/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IVehiclePlotResponse, IVideoEvent } from '../../../core/models';
import {
  IGetJourneyForVehicleParams,
  IReplayJourney,
  EReplayScrollEvent,
  EPanelDrawerState,
  DateRange,
  IReplayBalloonVideoDetails
} from '../../models';

export const enum EReplayActions {
  OpenReplayOptions = '[Replay] Open Replay Options',
  CloseReplayOptions = '[Replay] Close Replay Options',
  GetJourneyForVehicle = '[Replay] Get Journey For Vehicle',
  GetJourneyForVehicleSuccess = '[Replay] Get Journey For Vehicle Success',
  GetJourneyForVehicleError = '[Replay] Get Journey For Vehicle Error',
  GetJourneyForNewVehicle = '[Replay] Get Journey For New Vehicle',
  ChangeDateRangeOfJourney = '[Replay] Change Date Journey',
  GetJourneyUpdates = '[Replay] Get Journey Updates',
  GetJourneyUpdatesSuccess = '[Replay] Get Journey Updates Success',
  SelectReplaySegmentFromTimeline = '[Replay] Select Replay Segment From Timeline',
  HoverReplaySegment = '[Replay] Hover Replay Segment',
  EndHoverReplaySegment = '[Replay] End Hover Replay Segment',
  ViewAll = '[Replay] View All',
  UpdateCanClickViewAll = '[Replay] Update Can Click View All',
  FitToMap = '[Replay] Fit To Map',
  UpdateCanFitToMap = '[Replay] Update Can Fit To Map',
  StartReplayPlotAnimation = '[Replay] Start Replay Plot Animation',
  EndReplayPlotAnimation = '[Replay] End Replay Plot Animation',
  ClearReplayDataWhileFetching = '[Replay] Clear Replay Data While Fetching',
  SelectDayFromMultidayPanel = '[Replay] Select Day From Multiday Panel',
  DeselectDayFromMultidayPanel = '[Replay] Deselect Day From Multiday Panel',
  PanelScrolled = '[Replay] Panel Scrolled',
  NextPlotInJourney = '[Replay] Next Plot In Journey',
  PreviousPlotInJourney = '[Replay] Previous Plot In Journey',
  SelectReplaySegmentFromMap = '[Replay] Select Replay Segment From Map',
  DeselectReplaySegment = '[Replay] Deselect Replay Segment',
  UpdateMobileDrawerState = '[Replay] Update Mobile Drawer State',
  AddLivePlotToJourney = '[Replay] Add Live Plot To Journey',
  AddLivePlotToJourneySuccess = '[Replay] Add Live Plot To Journey Success',
  SelectSegmentFromTime = '[Replay] Select A Segment From A Time',
  SegmentVideoClicked = '[Replay] Segment Video Clicked',
  GetReplayBalloonVideoDetails = '[Replay] Get Replay Balloon Video Details',
  GetReplayBalloonVideoDetailsSuccess = '[Replay] Get Replay Balloon Video Details Success'
}
export class GetJourneyForVehicle implements Action {
  public readonly type = EReplayActions.GetJourneyForVehicle;

  constructor(public payload: IGetJourneyForVehicleParams) {}
}

export class GetJourneyForVehicleError implements Action {
  public readonly type = EReplayActions.GetJourneyForVehicleError;
}

export class GetJourneyForVehicleSuccess implements Action {
  public readonly type = EReplayActions.GetJourneyForVehicleSuccess;

  constructor(public payload: IReplayJourney) {}
}

export class GetJourneyForNewVehicle implements Action {
  public readonly type = EReplayActions.GetJourneyForNewVehicle;

  constructor(public payload: IGetJourneyForVehicleParams) {}
}

export class ChangeDateRangeOfJourney implements Action {
  public readonly type = EReplayActions.ChangeDateRangeOfJourney;

  constructor(public payload: DateRange) {}
}

export class GetJourneyUpdates implements Action {
  public readonly type = EReplayActions.GetJourneyUpdates;
}

export class GetJourneyUpdatesSuccess implements Action {
  public readonly type = EReplayActions.GetJourneyUpdatesSuccess;

  constructor(public payload: IReplayJourney) {}
}

export class OpenReplayOptions implements Action {
  public readonly type = EReplayActions.OpenReplayOptions;
}

export class CloseReplayOptions implements Action {
  public readonly type = EReplayActions.CloseReplayOptions;
}

export class SelectReplaySegmentFromTimeline implements Action {
  public readonly type = EReplayActions.SelectReplaySegmentFromTimeline;

  constructor(public payload: number) {}
}

export class SelectReplaySegmentFromMap implements Action {
  public readonly type = EReplayActions.SelectReplaySegmentFromMap;

  constructor(public payload: number) {}
}

export class HoverReplaySegment implements Action {
  public readonly type = EReplayActions.HoverReplaySegment;

  constructor(public payload: number) {}
}
export class EndHoverReplaySegment implements Action {
  public readonly type = EReplayActions.EndHoverReplaySegment;
}

export class ViewAll implements Action {
  public readonly type = EReplayActions.ViewAll;
}

export class UpdateCanClickViewAll implements Action {
  public readonly type = EReplayActions.UpdateCanClickViewAll;

  constructor(public payload: boolean) {}
}

export class FitToMap implements Action {
  public readonly type = EReplayActions.FitToMap;
}

export class UpdateCanFitToMap implements Action {
  public readonly type = EReplayActions.UpdateCanFitToMap;

  constructor(public payload: boolean) {}
}

export class StartReplayPlotAnimation implements Action {
  public readonly type = EReplayActions.StartReplayPlotAnimation;
}
export class EndReplayPlotAnimation implements Action {
  public readonly type = EReplayActions.EndReplayPlotAnimation;
}

export class ClearReplayDataWhileFetching implements Action {
  public readonly type = EReplayActions.ClearReplayDataWhileFetching;

  constructor(public payload: IReplayJourney) {}
}

export class SelectDayFromMultidayPanel implements Action {
  public readonly type = EReplayActions.SelectDayFromMultidayPanel;

  constructor(public payload: number) {}
}

export class DeselectDayFromMultidayPanel implements Action {
  public readonly type = EReplayActions.DeselectDayFromMultidayPanel;
}

export class PanelScrolled implements Action {
  public readonly type = EReplayActions.PanelScrolled;

  constructor(public payload: EReplayScrollEvent) {}
}

export class NextPlotInJourney implements Action {
  public readonly type = EReplayActions.NextPlotInJourney;

  constructor(public payload: number) {}
}

export class PreviousPlotInJourney implements Action {
  public readonly type = EReplayActions.PreviousPlotInJourney;

  constructor(public payload: number) {}
}

export class DeselectReplaySegment implements Action {
  public readonly type = EReplayActions.DeselectReplaySegment;
}

export class UpdateMobileDrawerState implements Action {
  public readonly type = EReplayActions.UpdateMobileDrawerState;

  constructor(public payload: EPanelDrawerState) {}
}

export class AddLivePlotToJourney implements Action {
  public readonly type = EReplayActions.AddLivePlotToJourney;

  constructor(public payload: IVehiclePlotResponse) {}
}

export class AddLivePlotToJourneySuccess implements Action {
  public readonly type = EReplayActions.AddLivePlotToJourneySuccess;

  constructor(public payload: IReplayJourney) {}
}

export class SelectSegmentFromTime implements Action {
  public readonly type = EReplayActions.SelectSegmentFromTime;

  constructor(public payload: Date) {}
}

export class SegmentVideoClicked implements Action {
  public readonly type = EReplayActions.SegmentVideoClicked;

  constructor(public payload: IVideoEvent) {}
}

export class GetReplayBalloonVideoDetails implements Action {
  public readonly type = EReplayActions.GetReplayBalloonVideoDetails;

  constructor(public payload: IVideoEvent) {}
}

export class GetReplayBalloonVideoDetailsSuccess implements Action {
  public readonly type = EReplayActions.GetReplayBalloonVideoDetailsSuccess;

  constructor(public payload: IReplayBalloonVideoDetails) {}
}

export type ReplayActions =
  | OpenReplayOptions
  | CloseReplayOptions
  | GetJourneyForVehicle
  | GetJourneyForVehicleError
  | GetJourneyForVehicleSuccess
  | GetJourneyForNewVehicle
  | ChangeDateRangeOfJourney
  | GetJourneyUpdates
  | GetJourneyUpdatesSuccess
  | SelectReplaySegmentFromTimeline
  | SelectReplaySegmentFromMap
  | HoverReplaySegment
  | EndHoverReplaySegment
  | ViewAll
  | UpdateCanClickViewAll
  | FitToMap
  | UpdateCanFitToMap
  | StartReplayPlotAnimation
  | EndReplayPlotAnimation
  | ClearReplayDataWhileFetching
  | SelectDayFromMultidayPanel
  | DeselectDayFromMultidayPanel
  | NextPlotInJourney
  | PreviousPlotInJourney
  | DeselectReplaySegment
  | UpdateMobileDrawerState
  | AddLivePlotToJourney
  | AddLivePlotToJourneySuccess
  | SelectSegmentFromTime
  | SegmentVideoClicked
  | GetReplayBalloonVideoDetails
  | GetReplayBalloonVideoDetailsSuccess;
