/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IMarkerShape } from './marker-shape.interface';
import { EMarkerShapeType } from './marker-shape-type.enum';

export class MarkerShape {
  constructor(public shape: IMarkerShape) {}

  public toGoogleMapShape(): google.maps.Polygon | google.maps.Circle {
    let shape: google.maps.Polygon | google.maps.Circle = null;
    switch (this.shape.shapeType) {
      case EMarkerShapeType.Circle: {
        shape = new google.maps.Circle({
          fillColor: this.shape.shapeColor,
          fillOpacity: 0.45,
          strokeColor: this.shape.shapeColor,
          strokeWeight: 2,
          center: this.shape.center,
          editable: false,
          radius: this.shape.radius,
          draggable: false
        });
        break;
      }
      case EMarkerShapeType.Polygon:
      case EMarkerShapeType.Rectangle: {
        shape = new google.maps.Polygon({
          paths: this.shape.shapes,
          strokeColor: this.shape.shapeColor,
          strokeWeight: 2,
          fillColor: this.shape.shapeColor,
          fillOpacity: 0.45
        });
        break;
      }
    }
    return shape;
  }
}
