/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { initialReplayState, IReplayState } from '../state';
import { ReplayActions, EReplayActions } from '../actions';
import { LayoutActions, ELayoutActions, MapsActions, EMapsActions } from '../../../core/store/actions';

export function replayReducer(state = initialReplayState, action: ReplayActions | LayoutActions | MapsActions): IReplayState {
  switch (action.type) {
    case EReplayActions.ClearReplayDataWhileFetching: {
      return {
        ...initialReplayState,
        journey: action.payload,
        isLoadingJourney: true
      };
    }
    case EReplayActions.GetJourneyForVehicleSuccess:
    case EReplayActions.AddLivePlotToJourneySuccess:
    case EReplayActions.GetJourneyUpdatesSuccess: {
      return {
        ...state,
        journey: action.payload,
        isLoadingJourney: false,
        selectedSegmentId:
          // if selected segment is the last segment of the journey
          state.selectedSegmentId === state.journey?.days.slice(-1)[0].segments?.length - 1
            ? // update the selection to the "new last segment" (in case the journey was extended)
              action.payload.days.slice(-1)[0].segments?.length - 1
            : // keep the selection if it's not the last
              state.selectedSegmentId
      };
    }

    case EReplayActions.GetJourneyForVehicleError: {
      return {
        ...state,
        isLoadingJourney: false
      };
    }

    case EReplayActions.SelectReplaySegmentFromTimeline: {
      return {
        ...state,
        selectedSegmentId: action.payload,
        shouldAnimatePlots: false,
        canClickViewAll: true,
        isSelectedSegmentSticky: true
      };
    }

    case EReplayActions.HoverReplaySegment: {
      return {
        ...state,
        hoveredSegmentId: action.payload
      };
    }

    case EReplayActions.EndHoverReplaySegment: {
      return {
        ...state,
        hoveredSegmentId: null
      };
    }

    case EReplayActions.ViewAll:
    case EReplayActions.DeselectReplaySegment: {
      return {
        ...state,
        selectedSegmentId: null,
        hoveredSegmentId: null,
        canClickViewAll: false,
        isSelectedSegmentSticky: false
      };
    }

    case EReplayActions.UpdateCanClickViewAll: {
      return {
        ...state,
        canClickViewAll: action.payload
      };
    }

    case EReplayActions.FitToMap: {
      return {
        ...state,
        canFitToMap: false
      };
    }

    case EReplayActions.UpdateCanFitToMap: {
      return {
        ...state,
        canFitToMap: action.payload
      };
    }

    case ELayoutActions.CloseReplayOnLiveMap: {
      return {
        ...state,
        journey: null,
        selectedSegmentId: null,
        shouldAnimatePlots: false
      };
    }

    case EReplayActions.OpenReplayOptions: {
      return {
        ...state,
        isReplayOptionsOpen: true
      };
    }

    case EReplayActions.CloseReplayOptions: {
      return {
        ...state,
        isReplayOptionsOpen: false
      };
    }

    case EReplayActions.StartReplayPlotAnimation: {
      return {
        ...state,
        shouldAnimatePlots: true
      };
    }
    case EReplayActions.EndReplayPlotAnimation: {
      return {
        ...state,
        shouldAnimatePlots: false
      };
    }

    case EReplayActions.SelectDayFromMultidayPanel: {
      return {
        ...state,
        multidaySelectedDay: action.payload
      };
    }

    case EReplayActions.DeselectDayFromMultidayPanel: {
      return {
        ...state,
        multidaySelectedDay: null,
        hoveredPolyline: null
      };
    }

    case EMapsActions.PolylineStartHover: {
      return {
        ...state,
        hoveredPolyline: action.payload
      };
    }

    case EMapsActions.PolylineEndHover: {
      return {
        ...state,
        hoveredPolyline: null
      };
    }

    case EReplayActions.SelectReplaySegmentFromMap: {
      return {
        ...state,
        selectedSegmentId: action.payload,
        shouldAnimatePlots: false,
        canClickViewAll: true
      };
    }

    case EReplayActions.UpdateMobileDrawerState: {
      return {
        ...state,
        drawerState: action.payload
      };
    }

    case EReplayActions.ChangeDateRangeOfJourney: {
      return {
        ...state,
        journey: {
          ...state.journey,
          selectedDateRange: action.payload
        }
      };
    }

    case EReplayActions.GetReplayBalloonVideoDetails: {
      return {
        ...state,
        balloonVideo: {
          isLoading: true,
          details: null
        }
      };
    }

    case EReplayActions.GetReplayBalloonVideoDetailsSuccess: {
      return {
        ...state,
        balloonVideo: {
          isLoading: false,
          details: action.payload
        }
      };
    }

    default:
      return state;
  }
}
