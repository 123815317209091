/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: 'replay',
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'corrected' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
