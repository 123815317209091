/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { INavigationEnabledVehicles, ICoordinates, IReverseGeocode, ISendToGarminParams } from '../../models';

export enum ESendToGarminActions {
  GetNavigationEnabledVehicles = '[SendToGarmin] Get navigation enabled vehicles',
  GetNavigationEnabledVehiclesSuccess = '[SendToGarmin] Get navigation enabled vehicles success',
  SendNavigationStop = '[SendToGarmin] Send Navigation Stop',
  GetGeocodeInfo = '[SendToGarmin] Get geocode info',
  GetGeocodeInfoSuccess = '[SendToGarmin] Get geocode info success',
  GetSendToGarminModalData = '[SendToGarmin] Get send to garmin modal data'
}

export class SendNavigationStop implements Action {
  public readonly type = ESendToGarminActions.SendNavigationStop;

  constructor(public payload: ISendToGarminParams) { }
}

export class GetSendToGarminModalData implements Action {
  public readonly type = ESendToGarminActions.GetSendToGarminModalData;

  constructor(public payload: ICoordinates) { }
}

export class GetNavigationEnabledVehicles implements Action {
  public readonly type = ESendToGarminActions.GetNavigationEnabledVehicles;
}

export class GetNavigationEnabledVehiclesSuccess implements Action {
  public readonly type = ESendToGarminActions.GetNavigationEnabledVehiclesSuccess;

  constructor(public payload: INavigationEnabledVehicles[]) { }
}

export class GetGeocodeInfo implements Action {
  public readonly type = ESendToGarminActions.GetGeocodeInfo;

  constructor(public payload: ICoordinates) { }
}

export class GetGeocodeInfoSuccess implements Action {
  public readonly type = ESendToGarminActions.GetGeocodeInfoSuccess;

  constructor(public payload: IReverseGeocode) { }
}

export type SendToGarminActions =
  SendNavigationStop |
  GetNavigationEnabledVehicles |
  GetNavigationEnabledVehiclesSuccess |
  GetGeocodeInfo |
  GetGeocodeInfoSuccess |
  GetSendToGarminModalData;
