/*!
 * Copyright © 2019-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IReplayJourney, EPanelDrawerState, IReplayBalloonVideo } from '../../models';
import { IPolylineHoverParams } from '../../../core/models';

export interface IReplayState {
  journey: IReplayJourney;
  isLoadingJourney: boolean;
  isReplayOptionsOpen: boolean;
  selectedSegmentId: number;
  isSelectedSegmentSticky: boolean;
  hoveredSegmentId: number;
  shouldAnimatePlots: boolean;
  canClickViewAll: boolean;
  canFitToMap: boolean;
  multidaySelectedDay?: number;
  drawerState: EPanelDrawerState;
  hoveredPolyline?: IPolylineHoverParams;
  balloonVideo?: IReplayBalloonVideo;
}

export const initialReplayState: IReplayState = {
  journey: null,
  isLoadingJourney: false,
  isReplayOptionsOpen: false,
  selectedSegmentId: null,
  isSelectedSegmentSticky: false,
  hoveredSegmentId: null,
  shouldAnimatePlots: false,
  canClickViewAll: false,
  multidaySelectedDay: null,
  hoveredPolyline: null,
  canFitToMap: false,
  drawerState: null,
  balloonVideo: null
};
