/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export class EpochTimeUtilities {
  static fromDateStringToEpochSeconds(date: string): number {
    return Math.round(Date.parse(date) / 1000);
  }

  static fromDateToEpochSeconds(date: Date): number {
    return Math.round(date.getTime() / 1000);
  }

  static fromEpochSecondsToDate(epochSeconds: number): Date {
    return new Date(epochSeconds * 1000);
  }

  static fromEpochSecondsToDotNetTicks(epochSeconds: number): number {
    return this.fromDateToDotNetTicks(this.fromEpochSecondsToDate(epochSeconds));
  }

  // 621355968000000000 is the number of 10^-7 seconds from 12:00:00 midnight, January 1, 0001 to the epoch (1 January 1970)
  static fromDotNetTicksToSeconds(ticks: number): number {
    return (ticks - 621355968000000000) / 10000000;
  }

  // 621355968000000000 is the number of 10^-7 seconds from 12:00:00 midnight, January 1, 0001 to the epoch (1 January 1970)
  static fromDotNetTicksToDate(ticks: number): Date {
    return new Date((ticks - 621355968000000000) / 10000);
  }

  static fromDateToDotNetTicks(date: Date): number {
    return date.getTime() * 10000 + 621355968000000000;
  }
}
