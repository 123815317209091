/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class DemoGoogleMapsService {
  private _geocoder: google.maps.Geocoder;
  private get geocoder(): google.maps.Geocoder {
    if (this._geocoder === undefined) {
      this._geocoder = new google.maps.Geocoder();
    }

    return this._geocoder;
  }

  private get _map(): google.maps.Map {
    return (<IFleetmaticsWindow>(<unknown>window)).Fleetmatics.Map.Instance;
  }

  isGoogleMapsLoaded(): Observable<boolean> {
    const isLoaded = new BehaviorSubject<boolean>(false);

    if (this._isMapInstanceLoaded()) {
      isLoaded.next(true);
      return isLoaded.asObservable();
    }

    const intervalId = setInterval(() => {
      if ((<IFleetmaticsWindow>(<unknown>window)).Fleetmatics && (<IFleetmaticsWindow>(<unknown>window)).Fleetmatics.Map && this._map) {
        isLoaded.next(true);
        clearInterval(intervalId);
      }
    }, 100);

    return isLoaded.asObservable();
  }

  zoomTo(latLng: google.maps.LatLng, zoomLevel?: number): void {
    if (zoomLevel) {
      this._map.setZoom(zoomLevel);
    }
    this._map.panTo(latLng);
  }

  reverseGeocode(latLng: google.maps.LatLng): Observable<google.maps.GeocoderResult> {
    const resultSubject = new Subject<google.maps.GeocoderResult>();

    const geocodeRequest = {
      location: { lat: latLng.lat(), lng: latLng.lng() }
    };
    this.geocoder.geocode(geocodeRequest, (result, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        resultSubject.next(result[0]);
      } else {
        resultSubject.next(null);
      }
    });

    return resultSubject.asObservable();
  }

  private _isMapInstanceLoaded() {
    return (<IFleetmaticsWindow>(<unknown>window)).Fleetmatics && (<IFleetmaticsWindow>(<unknown>window)).Fleetmatics.Map && this._map;
  }
}
