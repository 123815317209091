/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IDriverDetails, ISmsOptInStatus, ISmsInvitation } from '../../models';

export interface IDriverMessageState {
  driverDetails: IDriverDetails;
  smsOptInStatus: ISmsOptInStatus;
  isEmailSent: boolean;
  isSmsSent: boolean;
  isModalOpen: boolean;
  smsInvitation: ISmsInvitation;
}

export const initialDriverMessageState: IDriverMessageState = {
  driverDetails: null,
  smsOptInStatus: null,
  isEmailSent: null,
  isSmsSent: null,
  isModalOpen: false,
  smsInvitation: null
};
