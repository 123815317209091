/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';

import { FmWindowRefService, FmDocumentRefService, isElementNull, ConfigService } from '@fleetmatics/ui.utilities';

import { IModalWindowService } from './modal-window.service.interface';
import { IModalWindowDimensions } from './modal-window-dimensions.interface';
import {
  ICreateGeofenceParams,
  IFindNearestParams,
  IFindDirectionParams,
  IReplayWindowParameters,
  IEditDriverParams,
  IEditVehicleParams,
  IViewGarminWindowParams,
  IEditPlaceParams,
  IWorkOrderParams,
  IEditAssetParams
} from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';

@Injectable()
export class ModalWindowService implements IModalWindowService {
  private readonly _rootUrl: string;

  constructor(
    private readonly _windowRefService: FmWindowRefService,
    private readonly _documentRefService: FmDocumentRefService,
    _configService: ConfigService<IAppConfig>
  ) {
    this._rootUrl = _configService.config.legacyBaseUrl;
  }

  public openWithPostCentered(
    url: string,
    name: string,
    params?:
      | ICreateGeofenceParams
      | IFindNearestParams
      | IFindDirectionParams
      | IReplayWindowParameters
      | IEditDriverParams
      | IEditVehicleParams
      | IViewGarminWindowParams
      | IEditPlaceParams
      | IWorkOrderParams,
    shouldScaleModal?: boolean
  ): void {
    const nativeWindow = this._windowRefService.nativeWindow;
    const nativeDocument = this._documentRefService.nativeDocument;

    const { width, height, top, left } = this._getDimensions(nativeWindow, nativeDocument, shouldScaleModal);
    const windowFeatures = `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`;

    const form = nativeDocument.createElement('form');
    this._setForm(form, `${this._rootUrl}${url}`, name, params);

    nativeDocument.body.appendChild(form);
    const newWindow = nativeWindow.open('about:blank', name, windowFeatures);
    form.submit();
    nativeDocument.body.removeChild(form);

    if (newWindow) {
      newWindow.focus();
    }
  }

  public openWithPostCenteredAsync(
    url: string,
    name: string,
    params$: Observable<IReplayWindowParameters>,
    shouldScaleModal?: boolean
  ): void {
    const nativeWindow = this._windowRefService.nativeWindow;
    const nativeDocument = this._documentRefService.nativeDocument;
    const { width, height, top, left } = this._getDimensions(nativeWindow, nativeDocument, shouldScaleModal);
    const windowFeatures = `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`;
    const newWindow = nativeWindow.open('about:blank', name, windowFeatures);
    params$
      .pipe(
        catchError(() => {
          newWindow.close();
          return of(null);
        }),
        filter(params => !isElementNull(params)),
        take(1)
      )
      .subscribe(params => {
        const form = nativeDocument.createElement('form');
        this._setForm(form, `${this._rootUrl}${url}`, name, params);
        nativeDocument.body.appendChild(form);
        form.submit();
        nativeDocument.body.removeChild(form);

        if (newWindow) {
          newWindow.focus();
        }
      });
  }

  public redirectToUrl(url: string): void {
    this._windowRefService.nativeWindow.location.href = `${this._rootUrl}${url}`;
  }

  public redirectWithPost(url: string, name: string, params?: IEditDriverParams | IEditVehicleParams | IEditAssetParams): void {
    const nativeDocument = this._documentRefService.nativeDocument;
    const form = nativeDocument.createElement('form');
    this._setForm(form, `${this._rootUrl}${url}`, name, params);

    nativeDocument.body.appendChild(form);
    form.submit();
    nativeDocument.body.removeChild(form);
  }

  public openNewWindow(url: string, target: string): void {
    this._windowRefService.nativeWindow.open(url, target);
  }

  private _setForm(form: HTMLFormElement, url: string, name: string, params: any) {
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    form.setAttribute('target', name);

    for (const i in params) {
      if (params.hasOwnProperty(i)) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = i;
        input.value = params[i];
        form.appendChild(input);
      }
    }
  }

  private _getDimensions(nativeWindow: Window, nativeDocument: Document, shouldScaleModal: boolean = false): IModalWindowDimensions {
    let width = 1068;
    let height = 667;
    const division = 2;
    const nativeWidth = nativeWindow.screen.width;
    const nativeHeight = nativeWindow.screen.height;
    const nativeLeft = !isElementNull(nativeWindow.screenLeft) ? nativeWindow.screenLeft : nativeWindow.screenX;
    const nativeTop = !isElementNull(nativeWindow.screenTop) ? nativeWindow.screenTop : nativeWindow.screenY;

    if (shouldScaleModal) {
      if (nativeWidth <= 1024) {
        width = nativeWidth - 120;
        height = nativeHeight - 200;
      } else if (nativeWidth <= 1280) {
        width = nativeWidth - 120;
        height = nativeHeight - 215;
      } else if (nativeWidth === 1920) {
        width = nativeWidth - 175;
        height = nativeHeight - 250;
      } else {
        width = nativeWidth - 170;
        height = nativeHeight - 225;
      }
    }
    const marginLeft = nativeWidth / division - width / division + nativeLeft;
    const marginTop = nativeHeight / division - height / division + nativeTop;

    return {
      width: width,
      height: height,
      left: marginLeft,
      top: marginTop
    };
  }
}
