<div #itemWrapper
  [class.context-menu-item-wrapper]="contextMenuPanel"
  (mouseenter)='updateChildrenPosition()'>
  <li class="context-menu-item"
    [ngClass]="{ 'context-menu-item--disabled': isDisabled, 'context-menu-item--active': subMenuHovered }">
    <ng-content></ng-content>
    <mat-icon *ngIf="contextMenuPanel"
      class="context-menu__list-item-icon mat-icon-size-xs"
      svgIcon="ds-ic-arrowRight"></mat-icon>
  </li>
  <span *ngIf="hasSeparator"
    class="context-menu-separator"></span>
  <div #childPanel
    (mouseenter)='mouseEnterSub()'
    (mouseleave)='mouseLeaveSub()'
    class="context-sub-menu"
    [ngStyle]="subMenuPosition">
    <ng-content select="fm-context-menu-panel"></ng-content>
  </div>
</div>
