/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ESCAPE } from '@angular/cdk/keycodes';

import { FmMenuPanelComponent } from '@fleetmatics/ui.navbar';
import { isElementNull } from '@fleetmatics/ui.utilities';

import { ISearchLightResult, ISearchLightCategory } from '../../model';

@Component({
  selector: 'fm-search-light-category-results-menu',
  templateUrl: './search-light-category-results-menu.component.html',
  styleUrls: ['./search-light-category-results-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'fmSearchLightCategoryResultsMenu'
})
export class FmSearchLightCategoryResultsMenuComponent {
  @Input()
  public category: ISearchLightCategory;

  @Output()
  public resultSelect: EventEmitter<ISearchLightResult> = new EventEmitter();

  @Output()
  public menuClose: EventEmitter<void> = new EventEmitter();

  @Output()
  public menuChange: EventEmitter<FmMenuPanelComponent> = new EventEmitter();

  @ViewChild(FmMenuPanelComponent, { static: false })
  public set menu(menu: FmMenuPanelComponent) {
    this._menu = menu;
    this.menuChange.emit(this._menu);
  }

  public get menu(): FmMenuPanelComponent {
    return this._menu;
  }

  private _menu: FmMenuPanelComponent;

  public onMenuOptionKeydown(event: KeyboardEvent): void {
    // tslint:disable-next-line:deprecation
    if (event.keyCode === ESCAPE) {
      this.menuClose.emit();
    }
  }

  public open(focus: boolean = true): void {
    this._menu.open();
    if (focus) {
      this._menu.focusFirstItem();
    }
  }

  public close(): void {
    this._menu.close();
  }

  public shouldShowMenu(): boolean {
    return (
      this.category !== undefined &&
      this.category !== null &&
      this.category.items !== undefined &&
      this.category.items !== null &&
      this.category.items.length > 0
    );
  }

  public onMenuOptionSelected(result: ISearchLightResult): void {
    if (!isElementNull(result.lat) && !isElementNull(result.lng)) {
      this.resultSelect.emit(result);
    }
  }
}
