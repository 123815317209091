<div id="interstitial-screen">
  <ng-template #interstitialModalContent>
    <div id="interstitial-modal">
      <div class="top-info">
        <h2 class="interstitial-screen-title"
          i18n="@@S_DEMO_INTERSTITIAL_HEADER">What do you want to do next?</h2>
        <div class="guide-navigation">
          <div a7s-id="demo-interstitial-arrow"
            (click)="moveLeft()"
            class="previous-guide inactive">
          </div>
          <div a7s-id="demo-interstitial-arrow"
            (click)="moveRight()"
            class="next-guide">
          </div>
        </div>
      </div>
      <div class="guides-container">
        <div class="guides">
          <div a7s-id="demo-interstitial-fleet-tracking-basics"
            class="guide"
            (click)="showGuide(EDemoStories.FleetBasics)"
            (swipeleft)="moveRight()"
            (swiperight)="moveLeft()">
            <div class="guide-icon basics"></div>
            <div class="guide-title"
              i18n="@@S_DEMO_FLEET_HEADER">Fleet tracking basics</div>
            <div class="guide-text"
              i18n="@@S_DEMO_FLEET_DESC">See where your vehicles are and what they are doing all day, everyday.</div>
            <div class="guide-learn-more"
              i18n="@@S_DEMO_LEARN">Learn more</div>
            <div [class.completed]="isBasicCompleted$ | async"
              class="guide-status basics"></div>
          </div>
          <div a7s-id="demo-interstitial-fsd"
            class="guide"
            (click)="showGuide(EDemoStories.FieldServiceDispatch)"
            (swipeleft)="moveRight()"
            (swiperight)="moveLeft()">
            <div class="guide-icon fsd"></div>
            <div class="guide-title"
              i18n="@@S_DEMO_MANAGE_HEADER">Manage your mobile workforce</div>
            <div class="guide-text"
              i18n="@@S_DEMO_MANAGE_DESC">Organize your technicians and see their job progress in near real time.</div>
            <div class="guide-learn-more"
              i18n="@@S_DEMO_LEARN">Learn more</div>
            <div [class.completed]="isFsdCompleted$ | async"
              class="guide-status fsd"></div>
          </div>
          <div a7s-id="demo-interstitial-cost-and-efficiency"
            class="guide"
            (click)="showGuide(EDemoStories.CostAndEfficiency)"
            (swipeleft)="moveRight()"
            (swiperight)="moveLeft()">
            <div class="guide-icon cost"></div>
            <div class="guide-title"
              i18n="@@S_DEMO_COST_HEADER">Cost & efficiency</div>
            <div class="guide-text"
              i18n="@@S_DEMO_COST_DESC">Run your fleet efficiently and economically.</div>
            <div class="guide-learn-more"
              i18n="@@S_DEMO_LEARN">Learn more</div>
            <div [class.completed]="isCostCompleted$ | async"
              class="guide-status cost"></div>
          </div>
          <div a7s-id="demo-interstitial-fleet-safety"
            class="guide"
            (click)="showGuide(EDemoStories.Safety)"
            (swipeleft)="moveRight()"
            (swiperight)="moveLeft()">
            <div class="guide-icon safety"></div>
            <div class="guide-title"
              i18n="@@S_DEMO_SAFETY_HEADER">Fleet safety</div>
            <div class="guide-text"
              i18n="@@S_DEMO_SAFETY_DESC">Keep your fleet safe while at work and save money by helping to reduce incidents.</div>
            <div class="guide-learn-more"
              i18n="@@S_DEMO_LEARN">Learn more</div>
            <div [class.completed]="isSafetyCompleted$ | async"
              class="guide-status safety"></div>
          </div>
          <div class="guide"
            a7s-id="demo-interstitial-mobile-on-the-go"
            (click)="showGuide(EDemoStories.OnTheGoMap)"
            (swipeleft)="moveRight()"
            (swiperight)="moveLeft()">
            <div class="guide-icon mobile"></div>
            <div class="guide-title"
              i18n="@@S_DEMO_GO_HEADER">On the go</div>
            <div class="guide-text"
              i18n="@@S_DEMO_GO_DESC">Run your fleet from your phone or tablet as smoothly as you do from the office.</div>
            <div class="guide-learn-more"
              i18n="@@S_DEMO_LEARN">Learn more</div>
            <div [class.completed]="isMobileCompleted$ | async"
              class="guide-status mobile"></div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="text"
          *ngIf="(demoVariationContent$ | async)?.interstitialScreenActionEnabled">
          <ng-container *ngIf="(actionsContent$ | async)?.currentVariation === 'a'">
            <span i18n="@@S_DEMO_VARIATION_A_START">Ready to start your 30-day free trial?</span>
            <a [href]="(actionsContent$ | async)?.url"
              a7s-id="demo-interstitial-see-plans-and-pricing"
              class="link"
              i18n="@@S_DEMO_VARIATION_A_SEE">See details</a>.
          </ng-container>
          <ng-container *ngIf="(actionsContent$ | async)?.currentVariation === 'b'">
            <span i18n="@@S_DEMO_VARIATION_B_START">Get started today</span>.
            <a [href]="(actionsContent$ | async)?.url"
              a7s-id="demo-interstitial-see-plans-and-pricing"
              class="link"
              i18n="@@S_DEMO_VARIATION_B_SEE">See plans and pricing</a>.
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</div>
