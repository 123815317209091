/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError, filter } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { createEffect, ofType, Actions } from '@ngrx/effects';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { PlanningSchedulingHttpService } from '../../services';
import { EGarminStopHistoryActions, GetGarminStopHistory, GetGarminStopHistorySuccess } from '../actions';
import { IGarminStopHistoryParams, IGarminStopHistory } from '../../models';
import { delayedRetry } from '../../operators';

@Injectable()
export class GarminStopHistoryEffects {

  public getGarminStopHistory$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetGarminStopHistory>(EGarminStopHistoryActions.GetGarminStopHistory),
    map(action => action.payload),
    switchMap((params: IGarminStopHistoryParams) =>
      this._planningSchedulingHttpService.getGarminStopHistory(params).pipe(
        delayedRetry(),
        catchError(() => of(null))
      )
    ),
    filter(stopHistory => !isElementNull(stopHistory)),
    map((stopHistory: IGarminStopHistory[]) => new GetGarminStopHistorySuccess(stopHistory))
  ));

  constructor(private readonly _actions$: Actions, private readonly _planningSchedulingHttpService: PlanningSchedulingHttpService) {}
}
