<fm-modal-container>
  <fm-modal-header headerTitle="Device Status"
    i18n-headerTitle="@@S_DEVICESTATUS_MODAL_HEADER"> </fm-modal-header>
  <fm-modal-content class="device-status-modal-body">
    <fm-tabs class="device-status-modal-body__tabs">
      <fmTab [heading]="deviceStatus.title"
        *ngFor="let deviceStatus of deviceStatusNotifications"
        (selected)="selectTab(deviceStatus)">
        <ng-template fmTabContent>
          <div *ngIf="!changeToNotCorrectForm">
            <h3 class="device-status-modal-body__tabs-content"
              i18n="@@S_DEVICESTATUS_TOWING_MODAL_TITLE">
              Vehicle {{ deviceStatus.title }} status changed to towing at {{ deviceStatus.messageTimeFormatted }} Is this correct?
            </h3>
            <p i18n="@@S_DEVICESTATUS_MODAL_BODY"
              class="device-status-modal-body__tabs-content-text">
              An incorrect vehicle status may indicate a problem with your vehicle tracking unit.
            </p>
            <p i18n="@@S_DEVICESTATUS_MODAL_VEHICLE_ASSIGNMENT"
              class="device-status-modal-body__tabs-content-text">
              {{ deviceStatus.driverName }} is assigned to the vehicle
            </p>
            <ds-button text="SHOW ON MAP"
              class="device-status-modal-body-btn"
              id="btn-deviceStatusShowOnMap"
              i18n-text="@@S_DEVICESTATUS_BUTTON_SHOW_ON_MAP"
              componentStyle="secondary"
              size="small"
              (click)="onShowOnMapClicked(deviceStatus.id)">
            </ds-button>
          </div>
          <form *ngIf="changeToNotCorrectForm"
            [formGroup]="sendContactDetailsForm"
            novalidate>
            <h3 class="device-status-modal-body__tabs-content"
              i18n="@@S_DEVICESTATUS_CONTACT_INFO_OPTION">
              Our Customer Care Team is investigating. How would you like us to contact you?
            </h3>
            <div class="device-status-modal-body__tabs-content-form">
              <label class="form-label device-status-modal-body__tabs-content-label"
                i18n="@@S_DEVICESTATUS_CONTACT_NAME">Name</label>
              <span class="form-label device-status-modal-body__tabs-content-label">*</span>
              <input type="text"
                [class.device-status-modal-body__tabs-content-input--error]="name.invalid && name.touched"
                class="device-status-modal-body__tabs-content-input-name device-status-modal-body__tabs-content-input"
                dsInput
                ngDefaultControl
                formControlName="name"
                size="small"
                i18n-placeholder="@@S_DEVICESTATUS_CONTACT_NAME_PLACEHOLDER"
                placeholder="Enter Name" />
              <p *ngIf="name.invalid && name.touched"
                class="device-status-modal-body__tabs-content-label--error"
                i18n="@@S_DEVICESTATUS_CONTACT_NAME_ERROR">
                Please enter contact name.
              </p>
            </div>
            <div class="device-status-modal-body__tabs-content-details">
              <label class="form-label device-status-modal-body__tabs-content-label"
                i18n="@@S_DEVICESTATUS_CONTACT_TYPE">Contact Type</label>
              <div class="form-group">
                <div class="device-status-modal-body__tabs-content-select">
                  <ds-select (selectionChange)="changeSelected($event)">
                    <ds-select-item [value]="EDeviceStatusContactDetails.email"
                      i18n="@@S_DEVICESTATUS_CONTACT_EMAIL"
                      [isSelected]="true">Email</ds-select-item>
                    <ds-select-item [value]="EDeviceStatusContactDetails.phone"
                      i18n="@@S_DEVICESTATUS_CONTACT_PHONE">Phone</ds-select-item>
                  </ds-select>
                </div>
                <input type="text"
                  *ngIf="contactMethodSelected === EDeviceStatusContactDetails.phone"
                  ngDefaultControl
                  formControlName="contact"
                  [class.device-status-modal-body__tabs-content-input--error]="contact.invalid && contact.touched"
                  class="device-status-modal-body__tabs-content-input-contact device-status-modal-body__tabs-content-input"
                  dsInput
                  size="small"
                  i18n-placeholder="@@S_DEVICESTATUS_CONTACT_PHONE_PLACEHOLDER"
                  placeholder="Enter Phone" />
                <input type="text"
                  ngDefaultControl
                  *ngIf="contactMethodSelected === EDeviceStatusContactDetails.email"
                  formControlName="contact"
                  [class.device-status-modal-body__tabs-content-input--error]="contact.invalid && contact.touched"
                  class="device-status-modal-body__tabs-content-input-contact device-status-modal-body__tabs-content-input"
                  dsInput
                  size="small"
                  i18n-placeholder="@@S_DEVICESTATUS_CONTACT_EMAIL_PLACEHOLDER"
                  placeholder="Enter Email" />
                <p *ngIf="contactMethodSelected === EDeviceStatusContactDetails.phone && contact.invalid && contact.touched"
                  class="device-status-modal-body__tabs-content-label--error"
                  i18n="@@S_DEVICESTATUS_CONTACT_PHONE_ERROR">
                  Please enter a contact phone number.
                </p>
                <p *ngIf="contactMethodSelected === EDeviceStatusContactDetails.email && contact.invalid && contact.touched"
                  class="device-status-modal-body__tabs-content-label--error"
                  i18n="@@S_DEVICESTATUS_CONTACT_EMAIL_ERROR">
                  Please enter a valid email address.
                </p>
              </div>
            </div>
          </form>
        </ng-template>
      </fmTab>
    </fm-tabs>
  </fm-modal-content>
  <fm-modal-footer>
    <div *ngIf="!changeToNotCorrectForm">
      <ds-button text="DISMISS"
        class="device-status-modal__footer-btn"
        id="btn-dismissDeviceStatusModal"
        i18n-text="@@S_DEVICESTATUS_DISMISS"
        componentStyle="tertiary"
        size="small"
        (click)="onDismissClick(currentDeviceStatus)">
      </ds-button>
      <ds-button text="NO"
        class="device-status-modal__footer-btn"
        id="btn-deviceStatusModalNo"
        i18n-text="@@S_DEVICESTATUS_NO"
        componentStyle="secondary"
        size="small"
        (click)="changeToNotCorrectForm = true">
      </ds-button>
      <ds-button text="YES"
        class="device-status-modal__footer-btn"
        id="btn-deviceStatusModalYes"
        i18n-text="@@S_DEVICESTATUS_YES"
        componentStyle="primary"
        size="small"
        (click)="onYesClick(currentDeviceStatus)">
      </ds-button>
    </div>
    <div *ngIf="changeToNotCorrectForm">
      <ds-button text="CANCEL"
        class="device-status-modal__footer-btn"
        id="btn-deviceStatusModalCancel"
        i18n-text="@@S_CANCEL_BUTTON"
        componentStyle="tertiary"
        size="small"
        (click)="onCancelClick()">
      </ds-button>
      <ds-button text="SEND"
        class="device-status-modal__footer-btn"
        id="btn-deviceStatusModalSend"
        i18n-text="@@S_DEVICESTATUS_SEND"
        componentStyle="primary"
        size="small"
        (click)="onSendClick(currentDeviceStatus)">
      </ds-button>
    </div>
  </fm-modal-footer>
</fm-modal-container>
