/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IAssetSelectionResponse, IAssetDetailResponse } from '../../models';

export interface IAssetsState {
  assetSelection: IAssetSelectionResponse[];
  assetDetails: IAssetDetailResponse[];
}

export const initialAssetsState: IAssetsState = {
  assetSelection: [],
  assetDetails: []
};
