/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IRealTimeDataHttpService } from './real-time-data-http.service.interface';
import { IAppConfig } from '../../../config/app-config.interfaces';
import { IApiResponseDto, EReportTypeId } from '../../models';

@Injectable()
export class RealTimeDataHttpService implements IRealTimeDataHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.realTimeDataApiEndpoint;
  }

  public getLiveMapReportTicket(vehicleId: number, reportType: EReportTypeId): Observable<string> {
    const data = {
      VehicleId: vehicleId,
      ReportTypeId: reportType
    };

    return this._httpClient
      .post<IApiResponseDto<string>>(`${this._rootUrl}/Report/LiveMapReport/`, data)
      .pipe(map(response => response.Data));
  }
}
