/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ELayoutActions, LayoutActions, GetLayoutPreferencesSuccess, ELayoutPreferencesActions } from '../actions';

import { initialLayoutState, ILayoutState } from '../state';
import { IIndexableObject, Position } from '../../models';

export function layoutReducer(state = initialLayoutState, action: LayoutActions | GetLayoutPreferencesSuccess): ILayoutState {
  switch (action.type) {
    case ELayoutActions.UpdateIsFullscreen: {
      return {
        ...state,
        isFullscreen: action.payload
      };
    }
    case ELayoutActions.UpdateZoomToLocation: {
      return {
        ...state,
        locationToZoom: { ...action.payload }
      };
    }
    case ELayoutActions.UpdateZoomToMarker: {
      return {
        ...state,
        locationToMarker: { ...action.payload.latLng },
        locationToMarkerId: action.payload.id
      };
    }
    case ELayoutActions.RemoveZoomToMarker: {
      return {
        ...state,
        locationToMarker: null,
        locationToMarkerId: null
      };
    }
    case ELayoutActions.TriggerFitToMap: {
      return {
        ...state,
        isMapFitted: true,
        triggerFitToMap: {}
      };
    }
    case ELayoutActions.UpdateIsMapFitted: {
      return {
        ...state,
        isMapFitted: action.payload
      };
    }
    case ELayoutActions.SwitchMapType: {
      return {
        ...state,
        mapType: action.payload
      };
    }

    case ELayoutPreferencesActions.GetLayoutPreferencesSuccess: {
      return {
        ...state,
        mapType: action.payload.mapOptions.mapType
      };
    }

    case ELayoutActions.OpenReplayOnLiveMap: {
      return {
        ...state,
        replayLayout: {
          ...state.replayLayout,
          isReplayOpen: true,
          viewReplayParams: action.payload
        }
      };
    }

    case ELayoutActions.CloseReplayOnLiveMap: {
      return {
        ...state,
        replayLayout: {
          ...state.replayLayout,
          isReplayOpen: false
        }
      };
    }

    case ELayoutActions.UpdateAnimationState: {
      return {
        ...state,
        animationState: action.payload
      };
    }

    case ELayoutActions.OpenReplayDatePickerPanel: {
      return {
        ...state,
        replayLayout: {
          ...state.replayLayout,
          isReplayDatePickerOpen: true
        }
      };
    }

    case ELayoutActions.CloseReplayDatePickerPanel: {
      return {
        ...state,
        replayLayout: {
          ...state.replayLayout,
          isReplayDatePickerOpen: false
        }
      };
    }

    case ELayoutActions.UpdateIsAppAnimationsEnabled: {
      return {
        ...state,
        isAppAnimationsEnabled: action.payload
      };
    }

    case ELayoutActions.OverlayMarginsChanged: {
      const margins: IIndexableObject<Position> = { ...state.overlayMargins };
      margins[action.payload.overlayContainer] = action.payload.position;

      return {
        ...state,
        overlayMargins: margins
      };
    }

    case ELayoutActions.UpdateViewReplayVehicleId: {
      return {
        ...state,
        replayLayout: {
          ...state.replayLayout,
          viewReplayParams: {
            ...state.replayLayout.viewReplayParams,
            id: action.payload
          }
        }
      };
    }

    default:
      return state;
  }
}
