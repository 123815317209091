/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { FmWindowRefService, ConfigService } from '@fleetmatics/ui.utilities';

import { IRedirectService } from './redirect.service.interface';
import { IAppConfig } from '../../../../../config/app-config.interfaces';

@Injectable()
export class RedirectService implements IRedirectService {
  private readonly _rootUrl: string;
  private readonly _videoEventPath: string;

  constructor(private readonly _windowRefService: FmWindowRefService, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.legacyBaseUrl;
    this._videoEventPath = _configService.config.videoEventPath;
    if (!this._videoEventPath.startsWith('/')) {
      this._videoEventPath = `/${this._videoEventPath}`;
    }
    if (!this._videoEventPath.endsWith('/')) {
      this._videoEventPath = `${this._videoEventPath}/`;
    }
  }

  public redirectToUrl(url: string): void {
    this._windowRefService.nativeWindow.location.href = `${this._rootUrl}${url}`;
  }

  public redirectToVideoEventPage(videoEventId: number): void {
    return this.redirectToUrl(`${this._videoEventPath}${videoEventId}`);
  }
}
