/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IGarminStopsService } from './garmin-stops-http.service.interface';
import { IGarminStopsRequest, IApiResponseDto, IGarminStopPlot, ILatLngBounds } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';

@Injectable()
export class GarminStopsHttpService implements IGarminStopsService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.plotApiEndpoint;
  }

  public getGarminStopsPlots(bounds: ILatLngBounds, statusIds: number[]): Observable<IGarminStopPlot[]> {
    const data: IGarminStopsRequest = {
      TopBoundaryPoint: {
        Latitude: bounds.north,
        Longitude: bounds.east
      },
      BottomBoundaryPoint: {
        Latitude: bounds.south,
        Longitude: bounds.west
      },
      StatusIds: statusIds ? statusIds.toString() : null
    };

    return this._httpClient
      .post<IApiResponseDto<IGarminStopPlot[]>>(`${this._rootUrl}/Plot/NavigationStops/`, data)
      .pipe(map((result: IApiResponseDto<IGarminStopPlot[]>) => result.Data));
  }
}
