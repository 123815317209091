<cdk-virtual-scroll-viewport [itemSize]="treeNodeSize">
  <ng-container *cdkVirtualFor="let item of expandedNodesDataSource"></ng-container>

  <cdk-tree [dataSource]="dataSource"
    [treeControl]="treeControl">
    <cdk-tree-node *cdkTreeNodeDef="let node"
      [attr.node-id]="'tree-node-' + node.id"
      class="tree-node"
      [style.marginLeft]="node.level * 22 + 20 + 'px'">

      <ng-container *ngTemplateOutlet="template; context: { node: node }"></ng-container>
    </cdk-tree-node>

    <cdk-tree-node *cdkTreeNodeDef="let node; when: hasChild"
      [attr.node-id]="'tree-node-' + node.id"
      class="tree-node"
      [style.marginLeft]="node.level * 22 + 'px'">
      <a cdkTreeNodeToggle
        [attr.aria-label]="'toggle ' + node.name"
        [class.tree-node--expanded]="treeControl.isExpanded(node)"
        class="tree-node-expander">
      </a>

      <ng-container *ngTemplateOutlet="template; context: { node: node }"></ng-container>
    </cdk-tree-node>
  </cdk-tree>

</cdk-virtual-scroll-viewport>

<ng-template #template
  let-node="node">
  <fm-checkbox [state]="checklistSelection.getState(node)"
    [isDisabled]="node.isDisabled"
    (changed)="toggleSelection(node)">
  </fm-checkbox>
  <div class="tree-node-content"
    [class.tree-node--disabled]="node.isDisabled"
    (click)="onNodeClick(node)"
    (contextmenu)="onNodeContentRightClick($event, node)">
    <mat-icon *ngIf="node.icon && !node.secondaryIcon"
      [svgIcon]="node.icon"
      class="tree-node-icon mat-icon-size-xs"
      componentStyle="primary"></mat-icon>
    <mat-icon *ngIf="node.secondaryIcon"
      fmTooltip="Electric Vehicle"
      i18n-fmTooltip="@@S_ELECTRIC_VEHICLE_TOOLTIP"
      [svgIcon]="node.secondaryIcon"
      class="tree-node-icon mat-icon-size-xs">
    </mat-icon>
    <span class="tree-node-text">
      {{ node.name }}
    </span>
  </div>
</ng-template>