/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store, Action, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, skipWhile, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { ConfigService, isElementNull } from '@fleetmatics/ui.utilities';

import {
  EVehicleDriverPanelActions,
  UpdateIsVehicleDriverPanelCollapsed,
  GetLayoutPreferences,
  ELayoutPreferencesActions,
  GetLayoutPreferencesSuccess,
  UpdateVehicleDriverPanelActiveTab,
  UpdateIsSuggestedGeofenceEnabled,
  EMapOptionsActions,
  UpdateIsTrafficEnabled,
  UpdateIsGeofenceEnabled,
  UpdateIsGarminStopsEnabled,
  UpdateIsClusteringEnabled,
  EAdvancedOptionsActions,
  UpdatePlaceCategoryIds,
  UpdatePlaceCategoryIdsSilent,
  UpdateGarminStopStatusesIdsSelected,
  UpdateIsShowLabelsEnabled,
  UpdateGarminStopStatusesIdsSelectedSilent,
  EVehicleActions,
  UpdateVehiclesSortType,
  UpdateVehicleSelectionMode,
  EVehicleListPanelActions,
  UpdateIsWorkOrdersEnabled,
  SwitchMapType,
  ELayoutActions,
  SyncLegacyLayoutPreferences,
  UpdateLegacyLayoutPreferences,
  UpdateIsShowTachoInformation,
  EWorkingTimeDirectiveActions,
  UpdateDriverWorkingStateFilter,
  UpdateIsBreakDueSelectedFilter,
  UpdateDriverStatusSortType,
  UpdateIsShowVehicleBalloonLinksEnabled,
  GetMapHierarchySuccess,
  ECustomerMetadataActions,
  UpdateShouldShowClusteringOption
} from '../actions';
import { IAppState, ILayoutPreferencesState, defaultLayoutPreferences } from '../state';
import { LayoutPreferencesService, LegacyLayoutPreferencesService, WindowResizeService } from '../../services';
import { updateLayoutPreferences } from '../../operators/layout-preferences.operator';
import {
  ILegacyMapPreferences,
  EMapTypeId,
  EVehiclesSortType,
  ILegacyTreeMapPreferences,
  EVehicleSelectionMode,
  EPendoCustomEvents
} from '../../models';
import { isPlatformBrowser } from '@angular/common';
import { PendoTrackEventService } from '../../../external/services';
import { getAccessiblePlotCount, getExternalModuleLoaded, getIsClusteringByDefaultFeatureToggleEnabled } from '../selectors';
import { IAppConfig } from '../../../config';

@Injectable()
export class LayoutPreferencesEffects {
  public updateVehicleDriverPanelActiveTab$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicleDriverPanelActiveTab>(EVehicleDriverPanelActions.UpdateActiveTab),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          vehicleDriverPanel: {
            ...state.vehicleDriverPanel,
            activeTab: action.payload
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateVehicleDriverPanelIsCollapsed$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsVehicleDriverPanelCollapsed>(EVehicleDriverPanelActions.UpdateIsCollapsed),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          vehicleDriverPanel: {
            ...state.vehicleDriverPanel,
            isCollapsed: action.payload
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateIsSuggestedGeofenceEnabled$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsSuggestedGeofenceEnabled>(EMapOptionsActions.UpdateIsSuggestedGeofenceEnabled),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: { ...state.mapOptions, isSuggestedGeofencesEnabled: action.payload }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public getLayoutPreferences$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetLayoutPreferences>(ELayoutPreferencesActions.GetLayoutPreferences),
      map(() => this._layoutPreferencesService.get({ ...defaultLayoutPreferences })),
      map((layoutPreferences: ILayoutPreferencesState) => {
        if (!layoutPreferences.isLegacyLayoutPreferencesSynced) {
          const legacyMapPreferences = this._legacyLayoutPreferencesService.getMapPreferences();
          const legacyTreeMapPreferences = this._legacyLayoutPreferencesService.getTreeMapPreferences();
          return new SyncLegacyLayoutPreferences({
            layoutPreferences,
            legacyMapPreferences,
            legacyTreeMapPreferences
          });
        }
        return new GetLayoutPreferencesSuccess(layoutPreferences);
      })
    )
  );

  public syncLegacyLayoutPreferences$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SyncLegacyLayoutPreferences>(ELayoutPreferencesActions.SyncLegacyLayoutPreferences),
      map(
        action =>
          <[ILayoutPreferencesState, ILegacyMapPreferences, ILegacyTreeMapPreferences]>[
            action.payload.layoutPreferences,
            action.payload.legacyMapPreferences,
            action.payload.legacyTreeMapPreferences
          ]
      ),
      switchMap(([layoutPreferences, legacyMapPreferences, legacyTreeMapPreferences]) => {
        layoutPreferences.isLegacyLayoutPreferencesSynced = true;
        if (!isElementNull(legacyMapPreferences)) {
          if (!isElementNull(legacyMapPreferences.GeoFence)) {
            layoutPreferences.mapOptions.isGeofencesEnabled = legacyMapPreferences.GeoFence.enabled;
            if (!isElementNull(legacyMapPreferences.GeoFence.advancedoptions)) {
              const geofenceKey = Object.keys(legacyMapPreferences.GeoFence.advancedoptions)[0];
              if (geofenceKey) {
                layoutPreferences.mapOptions.advancedOptions.placeCategoryIds = legacyMapPreferences.GeoFence.advancedoptions[geofenceKey]
                  .split(',')
                  .map((options: string) => +options);
              }
            }
          }
          if (!isElementNull(legacyMapPreferences.GarminStop)) {
            layoutPreferences.mapOptions.isGarminStopsEnabled = legacyMapPreferences.GarminStop.enabled;
            const garminStopAdvancedOptions = legacyMapPreferences.GarminStop.advancedoptions;
            if (!isElementNull(garminStopAdvancedOptions)) {
              const garminKey = Object.keys(garminStopAdvancedOptions)[0];
              if (garminKey) {
                layoutPreferences.mapOptions.advancedOptions.garminStopStatusesIdsSelected = garminStopAdvancedOptions[garminKey]
                  .split(',')
                  .map((options: string) => +options);
              }
            }
          }
          if (!isElementNull(legacyMapPreferences.Hotspot)) {
            layoutPreferences.mapOptions.isSuggestedGeofencesEnabled = legacyMapPreferences.Hotspot.enabled;
          }
          if (!isElementNull(legacyMapPreferences.Map)) {
            layoutPreferences.mapOptions.isClusteringEnabled = legacyMapPreferences.Map.groupMarkers;
            layoutPreferences.mapOptions.isTrafficEnabled = legacyMapPreferences.Map.traffic;
            layoutPreferences.mapOptions.isShowTachoInformationEnabled = legacyMapPreferences.Map.showTacho;
            if (legacyMapPreferences.Map.highContrast) {
              layoutPreferences.mapOptions.mapType = EMapTypeId.SATURATED;
            } else if (legacyMapPreferences.Map.MapTypeId === 'hybrid') {
              layoutPreferences.mapOptions.mapType = EMapTypeId.HYBRID;
            } else {
              layoutPreferences.mapOptions.mapType = EMapTypeId.ROADMAP;
            }

            if (!isElementNull(legacyMapPreferences.Map.DriverStatusOptions)) {
              if (+legacyMapPreferences.Map.DriverStatusOptions.statusFilter === -1) {
                layoutPreferences.mapOptions.workingTimeDirective.filter = null;
              } else {
                layoutPreferences.mapOptions.workingTimeDirective.filter = +legacyMapPreferences.Map.DriverStatusOptions.statusFilter;
              }
              layoutPreferences.mapOptions.workingTimeDirective.sortType = +legacyMapPreferences.Map.DriverStatusOptions.sortBy;
              layoutPreferences.mapOptions.workingTimeDirective.filterBreakDue = legacyMapPreferences.Map.DriverStatusOptions.breakFilter;
            }
          }
          if (!isElementNull(legacyMapPreferences.Accordion)) {
            layoutPreferences.vehicleDriverPanel.activeTab = +legacyMapPreferences.Accordion.activeBlade;
            layoutPreferences.vehicleDriverPanel.isCollapsed = legacyMapPreferences.Accordion.sideBarCollapsed;
          }
          if (!isElementNull(legacyMapPreferences.Balloons)) {
            layoutPreferences.mapOptions.isShowLabelsEnabled = legacyMapPreferences.Balloons.showLabels;
          }
          if (!isElementNull(legacyMapPreferences.VehicleStatusSort)) {
            if (+legacyMapPreferences.VehicleStatusSort.sortyBy === 4) {
              layoutPreferences.vehiclesSortType = EVehiclesSortType.Status;
            } else if (+legacyMapPreferences.VehicleStatusSort.sortyBy === 5) {
              layoutPreferences.vehiclesSortType = EVehiclesSortType.LastUpdate;
            } else {
              layoutPreferences.vehiclesSortType = +legacyMapPreferences.VehicleStatusSort.sortyBy;
            }
          }
        }

        if (!isElementNull(legacyTreeMapPreferences)) {
          if (legacyTreeMapPreferences.mode === 5) {
            layoutPreferences.vehicleListPanel.selectionMode = EVehicleSelectionMode.Assets;
          } else {
            layoutPreferences.vehicleListPanel.selectionMode = +legacyTreeMapPreferences.mode;
          }
        }
        return [new UpdateLegacyLayoutPreferences(layoutPreferences), new GetLayoutPreferencesSuccess(layoutPreferences)];
      })
    )
  );

  public updateLegacyLayoutPreferences$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateLegacyLayoutPreferences>(ELayoutPreferencesActions.UpdateLegacyLayoutPreferences),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          ...action.payload
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateIsTrafficEnabled$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsTrafficEnabled>(EMapOptionsActions.UpdateIsTrafficEnabled),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: { ...state.mapOptions, isTrafficEnabled: action.payload }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public UpdateIsGeofenceEnabled$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsGeofenceEnabled>(EMapOptionsActions.UpdateIsGeofenceEnabled),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: { ...state.mapOptions, isGeofencesEnabled: action.payload }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateIsGarminStopsEnabled$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsGarminStopsEnabled>(EMapOptionsActions.UpdateIsGarminStopsEnabled),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: {
            ...state.mapOptions,
            isGarminStopsEnabled: action.payload
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateIsWorkOrdersEnabled$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsWorkOrdersEnabled>(EMapOptionsActions.UpdateIsWorkOrdersEnabled),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: { ...state.mapOptions, isWorkOrdersEnabled: action.payload }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateIsClusteringEnabled$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsClusteringEnabled>(EMapOptionsActions.UpdateIsClusteringEnabled),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: { ...state.mapOptions, isClusteringEnabled: action.payload }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public UpdatePlaceCategoryIds$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdatePlaceCategoryIds | UpdatePlaceCategoryIdsSilent>(
        EAdvancedOptionsActions.UpdatePlaceCategoryIds,
        EAdvancedOptionsActions.UpdatePlaceCategoryIdsSilent
      ),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: {
            ...state.mapOptions,
            advancedOptions: {
              ...state.mapOptions.advancedOptions,
              placeCategoryIds: action.payload
            }
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateGarminStopStatusesIdsSelected$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateGarminStopStatusesIdsSelected | UpdateGarminStopStatusesIdsSelectedSilent>(
        EAdvancedOptionsActions.UpdateGarminStopStatusesIdsSelected,
        EAdvancedOptionsActions.UpdateGarminStopStatusesIdsSelectedSilent
      ),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: {
            ...state.mapOptions,
            advancedOptions: {
              ...state.mapOptions.advancedOptions,
              garminStopStatusesIdsSelected: action.payload ? action.payload : []
            }
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateIsShowLabelsEnabled$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsShowLabelsEnabled>(EMapOptionsActions.UpdateIsShowLabelsEnabled),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: { ...state.mapOptions, isShowLabelsEnabled: action.payload }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateVehicleSelectionMode$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicleSelectionMode>(EVehicleListPanelActions.UpdateVehicleSelectionMode),
      updateLayoutPreferences(
        (action: UpdateVehicleSelectionMode, state: ILayoutPreferencesState) => ({
          ...state,
          vehicleListPanel: {
            ...state.vehicleListPanel,
            selectionMode: action.payload
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateVehiclesSortType$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehiclesSortType>(EVehicleActions.UpdateVehiclesSortType),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          vehiclesSortType: action.payload
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateMapType$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SwitchMapType>(ELayoutActions.SwitchMapType),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: { ...state.mapOptions, mapType: action.payload }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateIsShowTachoInformationEnabled$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsShowTachoInformation>(EMapOptionsActions.UpdateIsShowTachoInformationEnabled),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: {
            ...state.mapOptions,
            isShowTachoInformationEnabled: action.payload
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateDriverWorkingStateFilter$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateDriverWorkingStateFilter>(EWorkingTimeDirectiveActions.UpdateDriverWorkingStateFilter),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: {
            ...state.mapOptions,
            workingTimeDirective: {
              ...state.mapOptions.workingTimeDirective,
              filter: action.payload
            }
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateIsBreakDueSelectedFilter$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsBreakDueSelectedFilter>(EWorkingTimeDirectiveActions.UpdateIsBreakDueSelectedFilter),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: {
            ...state.mapOptions,
            workingTimeDirective: {
              ...state.mapOptions.workingTimeDirective,
              filterBreakDue: action.payload
            }
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateDriverStatusSortType$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateDriverStatusSortType>(EWorkingTimeDirectiveActions.UpdateDriverStatusSortType),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: {
            ...state.mapOptions,
            workingTimeDirective: {
              ...state.mapOptions.workingTimeDirective,
              sortType: action.payload
            }
          }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public updateIsShowVehicleBalloonLinksEnabled$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateIsShowVehicleBalloonLinksEnabled>(EMapOptionsActions.UpdateIsShowVehicleBalloonLinksEnabled),
      updateLayoutPreferences(
        (action: any, state: ILayoutPreferencesState) => ({
          ...state,
          mapOptions: { ...state.mapOptions, isShowVehicleBalloonLinksEnabled: action.payload }
        }),
        this._store,
        this._layoutPreferencesService
      )
    )
  );

  public trackClusterEnabledPendoEvent$: Observable<Action> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<GetLayoutPreferencesSuccess | UpdateIsClusteringEnabled>(
          ELayoutPreferencesActions.GetLayoutPreferencesSuccess,
          EMapOptionsActions.UpdateIsClusteringEnabled
        ),
        filter(() => isPlatformBrowser(this._platformId)),
        switchMap(action =>
          this._store.pipe(
            select(getExternalModuleLoaded),
            skipWhile(isExternalLoaded => !isExternalLoaded),
            take(1),
            map(() => action)
          )
        ),
        tap(action => {
          const enabled = action instanceof GetLayoutPreferencesSuccess ? action.payload.mapOptions.isClusteringEnabled : action.payload;
          this._pendoTrackEventService.sendPendoTrackEventWhenReady(EPendoCustomEvents.ClusteringEnabled, { enabled });
        })
      ),
    { dispatch: false }
  );

  public setClusteringByDefaultFromHierarchy$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetMapHierarchySuccess>(ECustomerMetadataActions.GetMapHierarchySuccess),
      filter(() => isPlatformBrowser(this._platformId)),
      withLatestFrom(
        this._store.pipe(select(getIsClusteringByDefaultFeatureToggleEnabled)),
        this._store.pipe(select(getAccessiblePlotCount)),
        this._windowResizeService.isMobile$
      ),
      filter(
        ([_, isClusteringByDefaultFeatureToggleEnabled, plottableCount, isMobile]) =>
          isClusteringByDefaultFeatureToggleEnabled && (isMobile || plottableCount > this._configService.config.clusteringByDefaultMaxPlots)
      ),
      map(([, , plottableCount]) => {
        if (plottableCount > this._configService.config.clusteringByDefaultMaxPlots) {
          const exceeded = plottableCount > this._configService.config.clusteringByDefaultMaxPlots;
          this._pendoTrackEventService.sendPendoTrackEventWhenReady(EPendoCustomEvents.ClusteringDefaultThresholdExceeded, {
            exceeded,
            plottableCount
          });
        }
      }),
      switchMap(() => {
        const actions: Action[] = [new UpdateIsClusteringEnabled(true), new UpdateShouldShowClusteringOption(false)];
        return actions;
      })
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _layoutPreferencesService: LayoutPreferencesService,
    private readonly _legacyLayoutPreferencesService: LegacyLayoutPreferencesService,
    private readonly _pendoTrackEventService: PendoTrackEventService,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _configService: ConfigService<IAppConfig>,
    private readonly _windowResizeService: WindowResizeService
  ) {}
}
