/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformBrowser } from '@angular/common';
import { Observable, iif, EMPTY } from 'rxjs';
import { bufferTime } from 'rxjs/operators';

export const bufferTimeBrowser = (delayMs: number, platformId: object) => {
  return <T>(src: Observable<T>): Observable<T[]> => iif(() => isPlatformBrowser(platformId), src.pipe(bufferTime(delayMs)), EMPTY);
};
