/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { of, forkJoin, Observable, merge, partition, EMPTY } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  catchError,
  withLatestFrom,
  take,
  share,
  tap,
  skip,
  concatMap,
  throttleTime,
  delay,
  debounceTime
} from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';

import { ConfigService, isElementNull, isStringNullOrEmpty } from '@fleetmatics/ui.utilities';
import { PendoService } from '@fleetmatics/ui.insights';

import {
  GetJourneyForVehicle,
  EReplayActions,
  GetJourneyForVehicleSuccess,
  GetJourneyForVehicleError,
  StartReplayPlotAnimation,
  SelectReplaySegmentFromTimeline,
  ViewAll,
  ClearReplayDataWhileFetching,
  SelectDayFromMultidayPanel,
  DeselectDayFromMultidayPanel,
  EndReplayPlotAnimation,
  FitToMap,
  UpdateCanFitToMap,
  PanelScrolled,
  NextPlotInJourney,
  PreviousPlotInJourney,
  SelectReplaySegmentFromMap,
  DeselectReplaySegment,
  UpdateMobileDrawerState,
  GetJourneyForNewVehicle,
  GetJourneyUpdates,
  GetJourneyUpdatesSuccess,
  AddLivePlotToJourney,
  AddLivePlotToJourneySuccess,
  ChangeDateRangeOfJourney,
  SegmentVideoClicked
} from '../actions';
import {
  IGetJourneyForVehicleResponse,
  IGetReplayPlotsResponse,
  IReplayJourney,
  IGetJourneyForVehicleParams,
  IGetJourneyForVehicleRequest,
  Segment,
  Vehicle,
  IGetReplayPlotsRequest,
  EPanelDrawerState,
  DateRange,
  TGetReplayData
} from '../../models';
import { delayedRetry } from '../../../core/operators/delayed-retry.operator';
import { IAppState } from '../../../core/store/state';
import {
  getVehiclePlots,
  getUserSpeedingThreshold,
  getReplayUserSettings,
  getUserSettings,
  getIsStreetViewOpen,
  getBalloon,
  getShowSpeedingEvents,
  getShowHarshDrivingEvents,
  getReplayLayout,
  getShowReplayVideoEnabled
} from '../../../core/store/selectors';
import { ReplayHttpService } from '../../services';
import {
  IVehiclePlot,
  IReplayUserSettings,
  IReverseGeocodeRequest,
  IReverseGeocodeResponse,
  IUserSettings,
  IDriverDetails,
  IIndexableObject,
  LatLng,
  EPlotType,
  IReplayPlot,
  IVehiclePlotResponse,
  IVideoEvent
} from '../../../core/models';
import {
  UpdateIsMapFitted,
  ELayoutActions,
  GetReplayUserSettings,
  UpdateIsStreetViewOpen,
  PolylineClicked,
  EMapsActions,
  CloseBalloon,
  PolylineStartHover,
  GetPolylineBalloon,
  PolylineEndHover,
  GetReplayBalloon,
  GetReplayBalloonSuccess,
  EBalloonInfoActions,
  ChangeMapBounds,
  GetPlaceBalloonSuccess,
  UpdateVehicleAsSelected,
  UpdateViewReplayVehicleId,
  UpdateVehicles,
  EVehicleActions,
  GetMapHierarchy
} from '../../../core/store/actions';
import {
  getReplayJourney,
  getPlotsOfSelectedSegment,
  getReplayPlots,
  getSegmentOfPlot,
  getIsSelectedSegmentSticky,
  getIsReplaySettingsLoaded,
  canFitToMap
} from '../selectors/replay.selectors';
import { ReplayMappingUtilities } from './replay-mapping.utilities';
import {
  AddressResolutionHttpService,
  CustomerMetadataHttpService,
  VideoEventHttpService,
  WindowResizeService
} from '../../../core/services';
import { MomentUtilities } from '../../utils';
import { EpochTimeUtilities } from '../../../../utils';
import { IAppConfig } from '../../../config';

const GetJourneyUpdatesDelayMs = 10000;
const ChangeDateRangeDebounceTimeMs = 500;

@Injectable()
export class ReplayEffects {
  private readonly _driverNamesMap = new Map<number, IDriverDetails>();

  getReplayUserSettingsIfEmpty$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJourneyForVehicle>(EReplayActions.GetJourneyForVehicle),
      filter(() => isPlatformBrowser(this._platformId)),
      switchMap(() => this._store.pipe(select(getReplayUserSettings))),
      filter(replayUserSettings => isElementNull(replayUserSettings)),
      map(() => new GetReplayUserSettings())
    )
  );

  getReplayUserSpeedingThreshholdIfEmpty$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJourneyForVehicle>(EReplayActions.GetJourneyForVehicle),
      filter(() => isPlatformBrowser(this._platformId)),
      switchMap(() => this._store.pipe(select(getUserSpeedingThreshold))),
      filter(userSpeedingThreshold => isElementNull(userSpeedingThreshold)),
      map(() => new GetMapHierarchy({ refreshPlots: false }))
    )
  );

  onDateChangeGetJourneyForVehicle$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ChangeDateRangeOfJourney>(EReplayActions.ChangeDateRangeOfJourney),
      filter(() => isPlatformBrowser(this._platformId)),
      debounceTime(ChangeDateRangeDebounceTimeMs),
      withLatestFrom(this._store.pipe(select(getReplayLayout)), this._store.pipe(select(getReplayJourney))),
      map(([, replayLayout, journey]) => {
        const journeyParams: IGetJourneyForVehicleParams = {
          vehicleId: journey.vehicleId,
          dateRange: journey.selectedDateRange,
          accountSettingOverrides: replayLayout.viewReplayParams.accountSettingOverrides
        };
        return new GetJourneyForVehicle(journeyParams);
      })
    )
  );

  clearReplayPanelWhileFetchingReplayData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJourneyForVehicle>(EReplayActions.GetJourneyForVehicle),
      filter(() => isPlatformBrowser(this._platformId)),
      map(action => action.payload),
      withLatestFrom(
        this._store.pipe(select(getReplayJourney)),
        this._store.pipe(select(getVehiclePlots)),
        this._store.pipe(select(getUserSettings))
      ),
      map(([request, journey, vehiclePlots, userSettings]) => {
        const vehicleName = this._getVehicleName(vehiclePlots, request, journey);
        const adjustedDates = this._adjustParamsToUserTimezone(request, userSettings);

        return ReplayMappingUtilities.getEmptyJourney(request.vehicleId, adjustedDates.dateRange, vehicleName);
      }),
      switchMap(journey => [new ClearReplayDataWhileFetching(journey), new UpdateIsStreetViewOpen(false)])
    )
  );

  getJourneyForVehicle$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJourneyForVehicle>(EReplayActions.GetJourneyForVehicle),
      filter(() => isPlatformBrowser(this._platformId)),
      switchMap(action =>
        // wait until replay settings are loaded
        this._store.pipe(
          select(getIsReplaySettingsLoaded),
          filter(isLoaded => isLoaded),
          take(1),
          map(() => action)
        )
      ),
      map(action => action.payload),
      withLatestFrom<
        IGetJourneyForVehicleParams,
        [IGetJourneyForVehicleParams, IReplayUserSettings, number, IUserSettings, boolean, boolean, boolean]
      >(
        this._store.pipe(select(getReplayUserSettings)),
        this._store.pipe(select(getUserSpeedingThreshold)),
        this._store.pipe(select(getUserSettings)),
        this._store.pipe(select(getShowSpeedingEvents)),
        this._store.pipe(select(getShowHarshDrivingEvents)),
        this._store.pipe(select(getShowReplayVideoEnabled))
      ),
      switchMap(
        ([
          requestParams,
          replayUserSettings,
          userSpeedingThreshold,
          userSettings,
          showSpeedingEvents,
          showHarshDrivingEvents,
          showVideoEvents
        ]) => {
          const getData$ = this._getReplayData(
            requestParams,
            replayUserSettings,
            userSpeedingThreshold,
            userSettings,
            showSpeedingEvents,
            showHarshDrivingEvents,
            showVideoEvents
          ).pipe(
            share(),
            catchError(() => of<TGetReplayData>([null, null, null, null, null]))
          );

          const [callApisFailed$, callApisSucceeded$] = partition(
            getData$,
            ([journeyResponse, plotsResponse]) => isElementNull(journeyResponse) || isElementNull(plotsResponse)
          );

          const handleFailed$ = callApisFailed$.pipe(map(() => new GetJourneyForVehicleError()));
          const handleSucceeded$ = callApisSucceeded$.pipe(
            withLatestFrom(this._store.pipe(select(getVehiclePlots))),
            switchMap(([[journeyResponse, plotsResponse, params, driverNames, videoEvents], vehiclePlots]) => {
              const plot = vehiclePlots.find(p => p.id === params.vehicleId);

              const journey = this._getJourneyOrDefaultIfNoData(
                journeyResponse,
                plotsResponse,
                plot,
                params,
                driverNames,
                this._shouldIncludeRealtimePlots(params),
                videoEvents
              );
              let actions: Action[] = [new GetJourneyForVehicleSuccess(journey)];
              actions = this._shouldPlayPlotAnimation(journey, actions);
              actions = this._shouldSelectASegmentFromTime(journey, params.selectedSegmentTime, actions);
              return actions;
            })
          );

          return merge(handleFailed$, handleSucceeded$);
        }
      )
    )
  );

  public onReplaySelectUpdateMapBounds$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectReplaySegmentFromTimeline>(EReplayActions.SelectReplaySegmentFromTimeline),
      withLatestFrom(this._store.pipe(select(getPlotsOfSelectedSegment))),
      filter(([, plots]) => !isElementNull(plots) && plots.length > 0),
      map(([, plots]) => plots.map(p => p.location)),
      map(coordinates => coordinates.map(coordinate => LatLng.fromCoordinate(coordinate))),
      map(latLngs => new ChangeMapBounds(latLngs))
    )
  );

  public onReplaySelectTryAndCloseStreetView$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectReplaySegmentFromTimeline | ViewAll>(EReplayActions.SelectReplaySegmentFromTimeline, EReplayActions.ViewAll),
      withLatestFrom(this._store.pipe(select(getIsStreetViewOpen))),
      filter(([, isStreetViewOpen]) => isStreetViewOpen),
      map(() => new UpdateIsStreetViewOpen(false))
    )
  );

  public fitToMap$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FitToMap>(EReplayActions.FitToMap),
      switchMap(() => this._store.pipe(select(getReplayPlots), take(1))),
      map(plots => plots.map(p => p.center)),
      map(coordinates => coordinates.map(coordinate => LatLng.fromCoordinate(coordinate))),
      map(latLngs => new ChangeMapBounds(latLngs))
    )
  );

  public dispatchFitToMap$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJourneyForVehicleSuccess | ViewAll>(EReplayActions.GetJourneyForVehicleSuccess, EReplayActions.ViewAll),
      map(() => new FitToMap())
    )
  );

  public updateCanFitToMap$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FitToMap | GetJourneyForVehicleSuccess>(EReplayActions.FitToMap, EReplayActions.GetJourneyForVehicleSuccess),
      switchMap(() =>
        this._actions$.pipe(
          ofType<UpdateIsMapFitted>(ELayoutActions.UpdateIsMapFitted),
          map(action => action.payload),
          filter(isMapFitted => !isMapFitted),
          skip(1),
          take(1)
        )
      ),
      map(() => new UpdateCanFitToMap(true))
    )
  );

  public onSelectDayFromMultidayPanel$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectDayFromMultidayPanel>(EReplayActions.SelectDayFromMultidayPanel),
      switchMap(() => [new EndReplayPlotAnimation(), new StartReplayPlotAnimation(), new ViewAll()])
    )
  );

  public onDeselectDayFromMultidayPanel$ = createEffect(() =>
    this._actions$.pipe(
      ofType<DeselectDayFromMultidayPanel>(EReplayActions.DeselectDayFromMultidayPanel),
      switchMap(() => [new EndReplayPlotAnimation(), new ViewAll()])
    )
  );

  public onMapPolylineClicked$ = createEffect(() =>
    this._actions$.pipe(
      ofType<PolylineClicked>(EMapsActions.PolylineClicked),
      map(action => action.payload),
      map(polylineInfo => new SelectDayFromMultidayPanel(polylineInfo.id))
    )
  );

  public onSelectedSegmentChangesCloseBalloon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectReplaySegmentFromTimeline | ViewAll>(EReplayActions.SelectReplaySegmentFromTimeline, EReplayActions.ViewAll),
      withLatestFrom(this._store.pipe(select(getBalloon))),
      filter(([, balloon]) => !isElementNull(balloon)),
      map(() => new CloseBalloon())
    )
  );

  public showPolylineHoverBalloon$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<PolylineStartHover>(EMapsActions.PolylineStartHover),
      map(action => action.payload),
      map(polylineHoverParams => new GetPolylineBalloon(polylineHoverParams))
    )
  );

  public closePolylineHoverBalloon$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<PolylineEndHover>(EMapsActions.PolylineEndHover),
      map(() => new CloseBalloon())
    )
  );

  public onPanelScrolled$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<PanelScrolled>(EReplayActions.PanelScrolled),
        map(action => action.payload),
        tap(scrollEvent => this._pendoService.trackEvent(scrollEvent, {}))
      ),
    { dispatch: false }
  );

  public onNextPlotInJourney$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<NextPlotInJourney>(EReplayActions.NextPlotInJourney),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getReplayPlots))),
      switchMap(([replayPlotId, plots]) => {
        const currentPlotIndex = plots.findIndex(p => p.id === replayPlotId);
        if (currentPlotIndex >= plots.length - 1) {
          return EMPTY;
        }

        const nextPlot = plots[currentPlotIndex + 1];

        const actions: Action[] = this._getBalloonActions(nextPlot);
        return actions;
      })
    )
  );

  public onPreviousPlotInJourney$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<PreviousPlotInJourney>(EReplayActions.PreviousPlotInJourney),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getReplayPlots))),
      switchMap(([replayPlotId, plots]) => {
        const currentPlotIndex = plots.findIndex(p => p.id === replayPlotId);
        if (currentPlotIndex === 0) {
          return EMPTY;
        }

        const nextPlot = plots[currentPlotIndex - 1];

        const actions: Action[] = this._getBalloonActions(nextPlot);
        return actions;
      })
    )
  );

  public onOpenBalloonSelectSegment$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetReplayBalloonSuccess>(EBalloonInfoActions.GetReplayBalloonSuccess),
      withLatestFrom(this._windowResizeService.isMobileOrTablet$),
      filter(([, isMobileOrTablet]) => isMobileOrTablet),
      map(([action]) => action.payload.id),
      switchMap(id => this._store.pipe(select(getSegmentOfPlot, { plotId: id }), take(1))),
      map(segment => new SelectReplaySegmentFromMap(segment.id))
    )
  );

  public onOpenBalloonZoomToPlot$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetReplayBalloonSuccess>(EBalloonInfoActions.GetReplayBalloonSuccess),
      withLatestFrom(this._windowResizeService.isMobileOrTablet$),
      filter(([, isMobileOrTablet]) => isMobileOrTablet),
      map(([action]) => action.payload.latLng),
      map(latLng => new ChangeMapBounds([latLng]))
    )
  );

  public onCloesBalloonDeselectReplaySegment$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CloseBalloon>(EBalloonInfoActions.CloseBalloon),
      withLatestFrom(this._windowResizeService.isMobileOrTablet$, this._store.pipe(select(getIsSelectedSegmentSticky))),
      filter(([, isMobileOrTablet]) => isMobileOrTablet),
      filter(([, , isSticky]) => !isSticky),
      map(() => new DeselectReplaySegment())
    )
  );

  public onMobileIneractionsCloseDrawer$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetReplayBalloonSuccess | SelectReplaySegmentFromTimeline | ViewAll | GetPlaceBalloonSuccess>(
        EBalloonInfoActions.GetReplayBalloonSuccess,
        EReplayActions.SelectReplaySegmentFromTimeline,
        EReplayActions.ViewAll,
        EBalloonInfoActions.GetPlaceBalloonSuccess
      ),
      withLatestFrom(this._windowResizeService.isMobileOrTablet$),
      filter(([, isMobileOrTablet]) => isMobileOrTablet),
      map(() => new UpdateMobileDrawerState(EPanelDrawerState.Closed))
    )
  );

  public getJourneyForNewVehicle$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJourneyForNewVehicle>(EReplayActions.GetJourneyForNewVehicle),
      map(action => action.payload),
      concatMap(journeyParams => {
        // Select vehicle so plot is on the map
        // Update the replay layout vehicle id
        // Get new journey data
        const actions: Action[] = [];
        actions.push(
          new UpdateVehicleAsSelected({
            focusVehicle: false,
            selectedVehicle: `v${journeyParams.vehicleId}`
          })
        );
        actions.push(new UpdateViewReplayVehicleId(journeyParams.vehicleId));
        actions.push(new GetJourneyForVehicle(journeyParams));
        return actions;
      })
    )
  );

  public onVehicleUpdateGetJourneyUpdates$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicles>(EVehicleActions.UpdateVehicles),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getReplayJourney)), this._store.pipe(select(getUserSettings))),
      filter(() => this._configService.config.replayRealtimePlotsEnabled),
      filter(
        ([vehicleUpdate, journey, userSettings]) =>
          // if the vehicle update matches the vehicle that is replayed
          // AND the replay date range contains the current day
          vehicleUpdate.id === journey?.vehicleId &&
          journey?.selectedDateRange.contains(this._getTimeZoneAdjustedTime(vehicleUpdate, userSettings.TimeZoneAndroidId)) &&
          journey?.days[0].segments.length > 0
      ),
      map(([vehicleUpdate]) => new AddLivePlotToJourney(vehicleUpdate))
    )
  );

  public onAddLivePlotToJourney$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddLivePlotToJourney>(EReplayActions.AddLivePlotToJourney),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getReplayJourney))),
      switchMap(([vehiclePlotResponse, replayJourney]) => {
        const journey = ReplayMappingUtilities.addLivePlotToJourney(replayJourney, vehiclePlotResponse);
        return [new AddLivePlotToJourneySuccess(journey), new GetJourneyUpdates()];
      })
    )
  );

  public onGetJourneyUpdatesForVehicle$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJourneyUpdates>(EReplayActions.GetJourneyUpdates),
      withLatestFrom(this._store.pipe(select(getReplayJourney))),
      filter(() => this._configService.config.replayRealtimePlotsEnabled),
      filter(([, journey]) => !isElementNull(journey) && journey.days[0].segments.length > 0),
      delay(GetJourneyUpdatesDelayMs),
      /*
     we are making calls to the APIs so throttle the requests according to a congfiguration value.
     by default throttleTime will let the first request through, then ignore all subsequent requests during the throttleTime period
     this configuration { leading: true, trailing: true } will ensure that if there were any requests during the throttleTime period,
     at the end of the period it will fire a request to the APIs
    */
      throttleTime(this._configService.config.replayUpdateThrottleMs, undefined, {
        leading: true,
        trailing: true
      }),
      map(([, journey]) => {
        // get the data with time stamp >  the start time of the last segment
        return this._getRequestParamsForJourneyUpdates(journey);
      }),
      withLatestFrom<
        IGetJourneyForVehicleParams,
        [IGetJourneyForVehicleParams, IReplayUserSettings, number, IUserSettings, boolean, boolean, boolean]
      >(
        this._store.pipe(select(getReplayUserSettings)),
        this._store.pipe(select(getUserSpeedingThreshold)),
        this._store.pipe(select(getUserSettings)),
        this._store.pipe(select(getShowSpeedingEvents)),
        this._store.pipe(select(getShowHarshDrivingEvents)),
        this._store.pipe(select(getShowReplayVideoEnabled))
      ),
      switchMap(
        ([
          requestParams,
          replayUserSettings,
          userSpeedingThreshold,
          userSettings,
          showSpeedingEvents,
          showHarshDrivingEvents,
          showVideoEvents
        ]) => {
          const getData$ = this._getReplayData(
            requestParams,
            replayUserSettings,
            userSpeedingThreshold,
            userSettings,
            showSpeedingEvents,
            showHarshDrivingEvents,
            showVideoEvents
          ).pipe(
            share(),
            catchError(() => of<TGetReplayData>([null, null, null, null, null]))
          );

          const [callApisFailed$, callApisSucceeded$] = partition(
            getData$,
            ([journeyResponse, plotsResponse]) => isElementNull(journeyResponse) || isElementNull(plotsResponse)
          );

          const handleFailed$ = callApisFailed$.pipe(map(() => new GetJourneyForVehicleError()));
          const handleSucceeded$ = callApisSucceeded$.pipe(
            withLatestFrom(this._store.pipe(select(getReplayJourney)), this._store.pipe(select(getVehiclePlots))),
            filter(([, existingJourney]) => !isElementNull(existingJourney)),
            switchMap(([[journeyResponse, plotsResponse, params, driverNames], existingJourney, vehiclePlots]) => {
              const plot = vehiclePlots.find(p => p.id === params.vehicleId);

              const extendedJourney = ReplayMappingUtilities.extendJourney(
                journeyResponse,
                plotsResponse,
                plot,
                params,
                driverNames,
                existingJourney,
                this._configService.config.replayRealtimePlotsEnabled
              );

              return [new GetJourneyUpdatesSuccess(extendedJourney)];
            })
          );

          return merge(handleFailed$, handleSucceeded$);
        }
      )
    )
  );

  public onJourneyUpdatesFitToMap$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJourneyUpdatesSuccess | AddLivePlotToJourneySuccess>(
        EReplayActions.GetJourneyUpdatesSuccess,
        EReplayActions.AddLivePlotToJourneySuccess
      ),
      withLatestFrom(this._store.pipe(select(canFitToMap))),
      filter(([, canFit]) => !canFit), // only disptach fit to map if fit to map is enabled
      map(() => new FitToMap())
    )
  );

  public onSegmentVideoClickedOpenBalloon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SegmentVideoClicked>(EReplayActions.SegmentVideoClicked),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getReplayPlots))),
      map(([video, plots]) => plots.find(plot => plot.video?.key === video?.key)),
      switchMap(videoPlot => this._getBalloonActions(videoPlot))
    )
  );

  constructor(
    private readonly _actions$: Actions,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _replayHttpService: ReplayHttpService,
    private readonly _addressResolutionHttpService: AddressResolutionHttpService,
    private readonly _customerMetadataHttpService: CustomerMetadataHttpService,
    private readonly _store: Store<IAppState>,
    private readonly _pendoService: PendoService,
    private readonly _windowResizeService: WindowResizeService,
    private readonly _configService: ConfigService<IAppConfig>,
    private readonly _videoEventHttpService: VideoEventHttpService
  ) {}

  private _shouldIncludeRealtimePlots(params: IGetJourneyForVehicleParams): boolean {
    return (
      this._configService.config.replayRealtimePlotsEnabled &&
      !params.dateRange.isMultiDay() &&
      MomentUtilities.isToday(params.dateRange.start)
    );
  }

  private _getRequestParamsForJourneyUpdates(journey: IReplayJourney): IGetJourneyForVehicleParams {
    const lastNonSyntheticSegment = journey.days
      .slice(-1)[0]
      .segments.filter(s => !s.isSynthetic)
      .slice(-1)[0];

    return {
      vehicleId: journey.vehicleId,
      dateRange: new DateRange(lastNonSyntheticSegment.dateRange.start.toDate(), journey.selectedDateRange.end)
    };
  }

  private _getTimeZoneAdjustedTime(vehicleUpdate: IVehiclePlotResponse, timeZone: string) {
    const date = EpochTimeUtilities.fromDotNetTicksToDate(vehicleUpdate.ticks);
    return MomentUtilities.getTimeZoneAdjusted(date, timeZone);
  }

  private _getBalloonActions(plot: IReplayPlot): Action[] {
    const actions: Action[] = [];
    const latLng = new LatLng(plot.center.Latitude, plot.center.Longitude);
    actions.push(
      new GetReplayBalloon({
        data: {
          id: plot.id,
          latLng: latLng,
          type: EPlotType.replay,
          title: plot.name,
          options: {}
        }
      })
    );
    actions.push(new ChangeMapBounds([latLng]));
    return actions;
  }

  private _getVehicleName(vehiclePlots: IVehiclePlot[], request: IGetJourneyForVehicleParams, journey: IReplayJourney): string {
    const hasNameFromJourney = !isElementNull(journey) && !isStringNullOrEmpty(journey.vehicleName);
    if (hasNameFromJourney) {
      return journey.vehicleName;
    }

    const vehicleNameFromPlot = vehiclePlots.find(p => p.id === request.vehicleId)?.vehicleName;
    if (!isStringNullOrEmpty(vehicleNameFromPlot)) {
      return vehicleNameFromPlot;
    }

    return '';
  }

  private _shouldPlayPlotAnimation(journey: IReplayJourney, actions: Action[]): Action[] {
    // animation should only play in a single day
    if (!journey.selectedDateRange.isMultiDay() && journey.days[0].segments.length > 0) {
      actions = [new StartReplayPlotAnimation(), ...actions];
    }
    return actions;
  }

  private _shouldSelectASegmentFromTime(journey: IReplayJourney, segmentSelectDate: Date, actions: Action[]): Action[] {
    if (isElementNull(segmentSelectDate)) {
      return actions;
    }

    const dayIndex = journey.days.findIndex(day => day.selectedDateRange.contains(segmentSelectDate));

    // If journey is multiday, open correct day
    // Find segment id, and select segment
    if (dayIndex >= 0) {
      const segmentId = journey.days[dayIndex].segments.find(segment => segment.dateRange.contains(segmentSelectDate))?.id;
      if (!isElementNull(segmentId)) {
        if (journey.days.length > 1) {
          actions.push(new SelectDayFromMultidayPanel(dayIndex + 1));
        }

        actions.push(new SelectReplaySegmentFromTimeline(segmentId));
      }
    }

    return actions;
  }

  private _getJourneyOrDefaultIfNoData(
    journeyResponse: IGetJourneyForVehicleResponse,
    plotsResponse: IGetReplayPlotsResponse,
    plot: IVehiclePlot,
    params: IGetJourneyForVehicleParams,
    driverNames: IIndexableObject<string>,
    includeRealtimePlots: boolean,
    videoEvents: IVideoEvent[]
  ): IReplayJourney {
    let journey = ReplayMappingUtilities.mapJourney(
      journeyResponse,
      plotsResponse,
      plot,
      params,
      driverNames,
      includeRealtimePlots,
      videoEvents
    );

    if (isElementNull(journey)) {
      journey = ReplayMappingUtilities.getEmptyJourney(params.vehicleId, params.dateRange, plot.vehicleName);
    }
    return journey;
  }

  private _getPlotResponse(getJourneyForVehicleParams: IGetJourneyForVehicleParams): Observable<IGetReplayPlotsResponse> | null {
    const plotsRequest: IGetReplayPlotsRequest = {
      vehicleId: getJourneyForVehicleParams.vehicleId,
      startTime: getJourneyForVehicleParams.dateRange.getUtcStart(),
      endTime: getJourneyForVehicleParams.dateRange.getUtcEnd()
    };
    const plotsResponse = this._replayHttpService.getReplayPlots(plotsRequest).pipe(delayedRetry());
    return plotsResponse;
  }

  private _getJourneyResponse(
    getJourneyForVehicleParams: IGetJourneyForVehicleParams,
    replayUserSettings: IReplayUserSettings,
    userSpeedingThreshold: number
  ): Observable<IGetJourneyForVehicleResponse> | null {
    const journeyRequest = this._buildJourneyRequest(getJourneyForVehicleParams, replayUserSettings, userSpeedingThreshold);
    const journeyResponse = this._replayHttpService.getReplayJourneyForVehicle(journeyRequest).pipe(
      delayedRetry(),
      switchMap(response => this._reverseGeocodeJourneyResponse(response))
    );
    return journeyResponse;
  }

  private _buildJourneyRequest(
    getJourneyForVehicleParams: IGetJourneyForVehicleParams,
    replayUserSettings: IReplayUserSettings,
    userSpeedingThreshold: number
  ): IGetJourneyForVehicleRequest {
    /*
     * minimumIdleDuration defaults to the user setting
     * minimumStopDuration defaults to the user setting
     * ignitionOnly defaults to true
     */
    const minimumIdleDuration = isElementNull(getJourneyForVehicleParams.accountSettingOverrides?.minimumIdleDuration)
      ? replayUserSettings.minimumIdleDuration
      : getJourneyForVehicleParams.accountSettingOverrides.minimumIdleDuration;
    const minimumStopDuration = isElementNull(getJourneyForVehicleParams.accountSettingOverrides?.minimumStopDuration)
      ? replayUserSettings.minimumStopDuration
      : getJourneyForVehicleParams.accountSettingOverrides.minimumStopDuration;
    const ignitionOnly =
      getJourneyForVehicleParams.accountSettingOverrides?.showIdleSegments === null ||
      getJourneyForVehicleParams.accountSettingOverrides?.showIdleSegments === undefined
        ? true
        : getJourneyForVehicleParams.accountSettingOverrides.showIdleSegments;

    const request: IGetJourneyForVehicleRequest = {
      vehicleId: getJourneyForVehicleParams.vehicleId,
      startTime: getJourneyForVehicleParams.dateRange.getUtcStart(),
      endTime: getJourneyForVehicleParams.dateRange.getUtcEnd(),
      minimumIdleDuration: minimumIdleDuration,
      minimumStopDuration: minimumStopDuration,
      speedThreshold: userSpeedingThreshold,
      includePlaces: true,
      ignitionOnly: ignitionOnly
    };
    return request;
  }

  private _reverseGeocodeJourneyResponse(response: IGetJourneyForVehicleResponse): Observable<IGetJourneyForVehicleResponse> {
    const vehicleCentricity = response.centricities[0].vehicles[0];
    if (!isElementNull(vehicleCentricity) && this._vehicleCentricityHasSegments(vehicleCentricity)) {
      const segments = vehicleCentricity.centricities[0].segments;
      const reverseGeocodeParams: IReverseGeocodeRequest[] = this._getReverseGeocodeParamsFromSegments(vehicleCentricity.id, segments);
      return reverseGeocodeParams.length > 0
        ? this._addressResolutionHttpService.getReverseGeocode(reverseGeocodeParams).pipe(
            delayedRetry(),
            map(addresses => {
              response.centricities[0].vehicles[0].centricities[0].segments = this._mapAddressesToSegments(addresses, segments);
              return response;
            })
          )
        : of(response);
    }
    return of(response);
  }

  private _vehicleCentricityHasSegments(vehicleCentricity: Vehicle): boolean {
    return vehicleCentricity.centricities.length > 0 && vehicleCentricity.centricities[0].segments.length > 0;
  }

  private _getReverseGeocodeParamsFromSegments(vehicleId: number, segments: Segment[]): IReverseGeocodeRequest[] {
    const reverseGeocodeParams: IReverseGeocodeRequest[] = [];
    segments
      .filter(segment => this._shouldSegmentBeReverseGeocoded(segment))
      .forEach(segment => {
        reverseGeocodeParams.push({
          key: segment.start.time.utc.toString(),
          data: {
            vid: vehicleId,
            Latitude: segment.start.address.position.latitude,
            Longitude: segment.start.address.position.longitude
          }
        });
      });
    return reverseGeocodeParams;
  }

  private _shouldSegmentBeReverseGeocoded(segment: Segment): boolean {
    return isStringNullOrEmpty(segment.start.address.formattedAddress);
  }

  private _mapAddressesToSegments(addresses: IReverseGeocodeResponse[], segments: Segment[]): Segment[] {
    addresses.forEach(address => {
      segments.forEach(segment => {
        if (address.key === segment.start.time.utc.toString()) {
          segment.start.address.formattedAddress = address.fulladdress;
        }
      });
    });
    return segments;
  }

  private _adjustParamsToUserTimezone(
    requestParams: IGetJourneyForVehicleParams,
    userSettings: IUserSettings
  ): IGetJourneyForVehicleParams {
    const adjustedRequestParams = {
      ...requestParams,
      dateRange: requestParams.dateRange.getDateRangeInTimezone(userSettings.TimeZoneAndroidId)
    };

    return adjustedRequestParams;
  }

  private _getReplayData(
    requestParams: IGetJourneyForVehicleParams,
    replayUserSettings: IReplayUserSettings,
    userSpeedingThreshold: number,
    userSettings: IUserSettings,
    showSpeedingEvents: boolean,
    showHarshDrivingEvents: boolean,
    showVideoEvents: boolean
  ): Observable<TGetReplayData> {
    /*
        get journey and plots adjusted for user time zone (not the machine time zone - user timezone is defined at the account setup)
        for example, if the user is in GMT-6:00 and current time is 10:00AM 15/1/2020.
        before the adjustment we would request:
        0:00:00 15/1/2020 (UTC)
        23:59:59 15/1/2020 (UTC)

        after the adjustment we would request:
        6:00:00 15/1/2020 (UTC)
        5:59:59 16/1/2020 (UTC)
      */
    const timezoneAdjustedParams = this._adjustParamsToUserTimezone(requestParams, userSettings);

    const journeyResponseObservable = this._getJourneyResponse(timezoneAdjustedParams, replayUserSettings, userSpeedingThreshold).pipe(
      map(journeyResponse =>
        this._removeHarshDrivingDataFromJourneyIfNoPermission(journeyResponse, showSpeedingEvents, showHarshDrivingEvents)
      ),
      switchMap(journeyResponse => this._getDriverNames(journeyResponse).pipe(map(driverNames => ({ journeyResponse, driverNames }))))
    );

    const plotsResponseObservable = this._getPlotResponse(timezoneAdjustedParams).pipe(
      map(plotsResponse => this._removeHarshDrivingDataFromPlotsIfNoPermission(plotsResponse, showSpeedingEvents, showHarshDrivingEvents))
    );

    const videoEventsResponseObservable = showVideoEvents
      ? this._videoEventHttpService.getVideoEvents(
          timezoneAdjustedParams.dateRange.start.toDate(),
          timezoneAdjustedParams.dateRange.end.toDate(),
          timezoneAdjustedParams.dateRange.differenceDays(),
          timezoneAdjustedParams.vehicleId
        )
      : of([]);

    return forkJoin([journeyResponseObservable, plotsResponseObservable, of(timezoneAdjustedParams), videoEventsResponseObservable]).pipe(
      map(([joureyAndDrivers, plotResponse, params, videoEvents]) => [
        joureyAndDrivers.journeyResponse,
        plotResponse,
        params,
        joureyAndDrivers.driverNames,
        videoEvents
      ])
    );
  }

  private _getDriverNames(journeyResponse: IGetJourneyForVehicleResponse): Observable<IIndexableObject<string>> {
    const driverIds = ReplayMappingUtilities.getDriverIds(journeyResponse);

    if (isElementNull(driverIds) || driverIds.length === 0) {
      return of({});
    }

    const getDriverDetails$: Observable<IDriverDetails>[] = driverIds.map(driverId =>
      this._driverNamesMap.has(driverId)
        ? of(this._driverNamesMap.get(driverId))
        : this._customerMetadataHttpService.getDriverDetails(driverId).pipe(
            delayedRetry(),
            tap(driverDetails => this._driverNamesMap.set(driverId, driverDetails))
          )
    );

    return forkJoin([...getDriverDetails$]).pipe(
      switchMap((driverDetails: IDriverDetails[]) => {
        const driverNames: IIndexableObject<string> = {};
        driverDetails
          .filter(driver => !isElementNull(driver))
          .forEach(driver => {
            driverNames[driver.DriverId] = `${driver.FirstName} ${driver.LastName}`;
          });

        return of(driverNames);
      })
    );
  }

  private _removeHarshDrivingDataFromJourneyIfNoPermission(
    journeyResponse: IGetJourneyForVehicleResponse,
    showSpeedingEvents: boolean,
    showHarshDrivingEvents: boolean
  ): IGetJourneyForVehicleResponse {
    if (ReplayMappingUtilities.journeyHasNoDataForVehicle(journeyResponse)) {
      return journeyResponse;
    }

    const segments = journeyResponse.centricities[0].vehicles[0].centricities[0].segments.map(segment => {
      if (!showSpeedingEvents) {
        segment.harshDrivingEvents.highSpeedEvents = 0;
      }

      if (!showHarshDrivingEvents) {
        segment.harshDrivingEvents.moderateAccelerationEvents = 0;
        segment.harshDrivingEvents.moderateBrakingEvents = 0;
        segment.harshDrivingEvents.moderateLeftCorneringEvents = 0;
        segment.harshDrivingEvents.moderateRightCorneringEvents = 0;
        segment.harshDrivingEvents.severeAccelerationEvents = 0;
        segment.harshDrivingEvents.severeBrakingEvents = 0;
        segment.harshDrivingEvents.severeLeftCorneringEvents = 0;
        segment.harshDrivingEvents.severeRightCorneringEvents = 0;
      }

      return segment;
    });

    journeyResponse.centricities[0].vehicles[0].centricities[0].segments = segments;

    return journeyResponse;
  }

  private _removeHarshDrivingDataFromPlotsIfNoPermission(
    plotsResponse: IGetReplayPlotsResponse,
    showSpeedingEvents: boolean,
    showHarshDrivingEvents: boolean
  ): IGetReplayPlotsResponse {
    if (plotsResponse.plots && plotsResponse.plots.length > 0) {
      plotsResponse.plots = plotsResponse.plots.map(plot => {
        if (plot.postedSpeedLimit && !showSpeedingEvents) {
          plot.postedSpeedLimit = null;
        }

        if (plot.accelerationSeverity && !showHarshDrivingEvents) {
          plot.accelerationSeverity = null;
        }

        return plot;
      });
    }

    return plotsResponse;
  }
}
