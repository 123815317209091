/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef
} from '@angular/core';
import { ESCAPE } from '@angular/cdk/keycodes';

import { FmMenuPanelComponent } from '@fleetmatics/ui.navbar';
import { isElementNull } from '@fleetmatics/ui.utilities';

import { ISearchLightCategory, ISearchLightResult } from '../../model';
import { FmSearchLightCategoryResultsMenuComponent } from '../search-light-category-results-menu';

export interface IMenuMapEntry {
  menuPanel: FmMenuPanelComponent;
  isActive: boolean;
}

export interface IMenuMap {
  [key: string]: IMenuMapEntry;
}

@Component({
  selector: 'fm-search-light-category-menu',
  templateUrl: './search-light-category-menu.component.html',
  styleUrls: ['./search-light-category-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FmSearchLightCategoryMenuComponent {
  @Input()
  public categories: ISearchLightCategory[];

  @Output()
  public categoryResultSelect: EventEmitter<ISearchLightResult> = new EventEmitter();

  @Output()
  public menuClose: EventEmitter<void> = new EventEmitter();

  @ViewChild(FmMenuPanelComponent, { static: false })
  public primaryMenuPanel: FmMenuPanelComponent;

  @ViewChild(FmSearchLightCategoryMenuComponent, { static: false })
  public categoryResultsMenu: FmSearchLightCategoryResultsMenuComponent;

  @ViewChildren(FmSearchLightCategoryResultsMenuComponent)
  public set childMenus(childMenus: QueryList<FmSearchLightCategoryResultsMenuComponent>) {
    this._childMenus = childMenus;
    this.menuMap = {};

    childMenus.forEach((childMenu: FmSearchLightCategoryResultsMenuComponent) => {
      this.menuMap[childMenu.category.entityType] = {
        menuPanel: childMenu.menu,
        isActive: false
      };
    });

    this.changeDetectorRef.detectChanges();
  }

  public get childMenus(): QueryList<FmSearchLightCategoryResultsMenuComponent> {
    return this._childMenus;
  }

  public menuMap: IMenuMap = {};

  private _childMenus: QueryList<FmSearchLightCategoryResultsMenuComponent>;

  constructor(public readonly changeDetectorRef: ChangeDetectorRef) {}

  public setIsSubMenuOpen(category: ISearchLightCategory, isActive: boolean): void {
    // there is an issue with fm-menu-panel not adding subscriptions (_addMenuOptionSubscriptions)
    Object.keys(this.menuMap).forEach(entityType => {
      this.menuMap[entityType].isActive = category.entityType === entityType ? isActive : !isActive;
      if (!this.menuMap[entityType].isActive && this.menuMap[entityType].menuPanel.isOpen) {
        this.menuMap[entityType].menuPanel.close();
      }
    });
  }

  public getIsSubMenuOpen(category: ISearchLightCategory): boolean {
    return this.menuMap[category.entityType].isActive;
  }

  public getCategorySubMenu(category: ISearchLightCategory): FmMenuPanelComponent {
    const menuMapEntry: IMenuMapEntry = this.menuMap[category.entityType];

    if (isElementNull(menuMapEntry)) {
      return undefined;
    }

    return menuMapEntry.menuPanel;
  }

  public onCategoryResultsMenuSet(): void {
    this.changeDetectorRef.detectChanges();
  }

  public onMenuOptionKeydown(event: KeyboardEvent): void {
    // tslint:disable-next-line:deprecation
    if (event.keyCode === ESCAPE) {
      this.menuClose.emit();
    }
  }

  public onCategoryResultSelected(result: ISearchLightResult): void {
    this.categoryResultSelect.emit(result);
  }

  public open(focus: boolean = true): void {
    this.primaryMenuPanel.open();

    if (focus) {
      this.primaryMenuPanel.focusFirstItem();
    }

    if (this.childMenus.length <= 0) {
      return;
    }

    this.setIsSubMenuOpen(this.categories[0], true);
    this.childMenus.first.open(focus);
    this.changeDetectorRef.detectChanges();
  }

  public close(): void {
    this.primaryMenuPanel.close();
  }
}
