/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { IRoadsideAssistanceState } from '../state';
import { IUserRoadsideAssistanceStatus } from '../../models';

export const getRoadsideAssistanceState = createFeatureSelector<IRoadsideAssistanceState>('roadsideAssistance');

export const getIsRoadsideAssistanceModalOpen = createSelector(
  getRoadsideAssistanceState,
  (state: IRoadsideAssistanceState): boolean => state.isModalOpen
);

export const getRoadsideAssistanceVin = createSelector(
  getRoadsideAssistanceState,
  (state: IRoadsideAssistanceState): string => state.vin
);

export const getRoadsideAssistanceStatus = createSelector(
  getRoadsideAssistanceState,
  (state: IRoadsideAssistanceState): IUserRoadsideAssistanceStatus => state.status
);

export const getIsRoadsideAssistanceAdmin = createSelector(
  getRoadsideAssistanceStatus,
  (state: IUserRoadsideAssistanceStatus): boolean => !isElementNull(state) && state.isRSAEnabled && state.canManageVehicles
);

export const getIsRoadsideAssistanceReadOnly = createSelector(
  getRoadsideAssistanceStatus,
  (state: IUserRoadsideAssistanceStatus): boolean => !isElementNull(state) && state.isRSAEnabled && !state.canManageVehicles
);
