/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TransferState, HammerModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { EffectsModule } from '@ngrx/effects';
import { INITIAL_STATE, StoreModule } from '@ngrx/store';
import { RouterStateSerializer, StoreRouterConnectingModule, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { FmNavbarModule } from '@fleetmatics/ui.navbar';
import { ConfigModule, FmDomModule, IConfigMapperFn, FmCookieModule } from '@fleetmatics/ui.utilities';

import { environment } from '../../environments/environment';
import { APP_CONFIG_TOKEN, configMapper } from '../config';
import { ModalModule } from './components';
import { NavbarContainerComponent } from './containers';
import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  BalloonService,
  CategoriesHttpService,
  CustomerMetadataHttpService,
  GarminStopsHttpService,
  GeofencesHttpService,
  GoogleMapsService,
  HotspotsHttpService,
  LayoutPreferencesService,
  LegacyLayoutPreferencesService,
  ModalWindowService,
  PlacesHttpService,
  PlanningSchedulingGarminHttpService,
  PlanningSchedulingWorkOrderHttpService,
  PlotHttpService,
  UserDataHttpService,
  WindowResizeService,
  BrowserLocationService,
  VideoEventHttpService,
  AssetsHttpService,
  RoadsideAssistanceHttpService,
  SchedulerHttpService,
  VehicleSortService,
  RealTimeDataHttpService,
  NotificationHttpService,
  PlanningSchedulingHttpService,
  AddressResolutionHttpService,
  DomHelperService,
  MapIconRegistryService,
  CompoundIconRegistryService,
  PlotFormattingService
} from './services';
import {
  CustomerMetadataEffects,
  LayoutPreferencesEffects,
  PlotsEffects,
  UserMapSettingsEffects,
  HarshDrivingEventsEffects,
  VehicleEffects,
  LayoutEffects,
  AdvancedOptionsEffects,
  BalloonInfoEffects,
  DriverMessageEffects,
  VehicleListPanelEffects,
  GarminStopHistoryEffects,
  SendToGarminEffects,
  MapsEffects,
  ReverseGeocodeEffects,
  WorkOrderEffects,
  RoadsideAssistanceEffects,
  AssetsEffects,
  WorkingTimeDirectiveEffects,
  SchedulerEffects
} from './store/effects';
import { appReducers } from './store/reducers';
import { CustomRouterStateSerializer, getInitialState } from './store/state';
import { HelpPanelConnectorModule } from '@fleetmatics/ui.help-panel/connector';
import { NotificationsConnectorModule } from '@fleetmatics/ui.notifications/connector';
import { SideDrawerModule, SideDrawerComponent } from '@fleetmatics/ui.base-library/side-drawer';

@NgModule({
  imports: [
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      AdvancedOptionsEffects,
      AssetsEffects,
      BalloonInfoEffects,
      CustomerMetadataEffects,
      DriverMessageEffects,
      GarminStopHistoryEffects,
      HarshDrivingEventsEffects,
      LayoutEffects,
      LayoutPreferencesEffects,
      MapsEffects,
      PlotsEffects,
      ReverseGeocodeEffects,
      RoadsideAssistanceEffects,
      SchedulerEffects,
      SendToGarminEffects,
      UserMapSettingsEffects,
      VehicleEffects,
      VehicleListPanelEffects,
      WorkOrderEffects,
      WorkingTimeDirectiveEffects
    ]),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    CommonModule,
    ConfigModule,
    FmNavbarModule.forRoot(APP_CONFIG_TOKEN, environment.overrideBaseUrl),
    FmCookieModule.forRoot(),
    ModalModule.forRoot(),
    FmDomModule.forRoot(),
    HttpClientModule,
    ConfigModule.forRoot(configMapper as IConfigMapperFn, environment.config),
    HammerModule,
    MatIconModule,
    HelpPanelConnectorModule,
    NotificationsConnectorModule.forRoot(() =>
      import('./components/side-drawer/notifications-wrapper.module').then(m => m.NotificationsWrapperModule)
    ),
    SideDrawerModule
  ],
  providers: [
    {
      provide: INITIAL_STATE,
      useFactory: getInitialState,
      deps: [TransferState]
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer
    },
    // http services:
    AddressResolutionHttpService,
    AssetsHttpService,
    CategoriesHttpService,
    CustomerMetadataHttpService,
    GarminStopsHttpService,
    GeofencesHttpService,
    HotspotsHttpService,
    NotificationHttpService,
    PlacesHttpService,
    PlanningSchedulingGarminHttpService,
    PlanningSchedulingHttpService,
    PlanningSchedulingWorkOrderHttpService,
    PlotHttpService,
    RealTimeDataHttpService,
    RoadsideAssistanceHttpService,
    SchedulerHttpService,
    UserDataHttpService,
    VehicleSortService,
    VideoEventHttpService,
    // end http services
    // required for effects that are part of the core effects:
    BalloonService,
    GoogleMapsService,
    LayoutPreferencesService,
    LegacyLayoutPreferencesService,
    ModalWindowService,
    // services that should be singleton because they have some shared state
    BrowserLocationService,
    CompoundIconRegistryService,
    MapIconRegistryService,
    WindowResizeService,
    // services that don't have a state and can potentially be removed from Core
    // but service will have multiple instances
    DomHelperService,
    PlotFormattingService
  ],
  declarations: [NavbarContainerComponent],
  exports: [NavbarContainerComponent, SideDrawerComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
