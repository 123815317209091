/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IMapBounds, IHotspotPlotsResponse, ILatLngBounds } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';
import { IHotspotsService } from './hotspots-http.service.interface';

@Injectable()
export class HotspotsHttpService implements IHotspotsService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.plotApiEndpoint;
  }

  public getHotspots(bounds: ILatLngBounds): Observable<IHotspotPlotsResponse> {
    const data: IMapBounds = {
      TopBoundaryBox: {
        Latitude: bounds.north,
        Longitude: bounds.east
      },
      BottomBoundaryBox: {
        Latitude: bounds.south,
        Longitude: bounds.west
      }
    };

    return this._httpClient.post<IHotspotPlotsResponse>(`${this._rootUrl}/Plot/Hotspots/`, data);
  }
}
