/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SnackbarService } from './service/snackbar.service';
import { SnackbarComponent } from './snackbar.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [SnackbarComponent],
  entryComponents: [SnackbarComponent],
  providers: [SnackbarService],
  exports: [SnackbarComponent]
})
export class SnackbarModule {}
