/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ERegion {
  CAN = 124,
  GBR = 826,
  IRL = 372,
  USA = 840
}
