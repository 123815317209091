/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Directive, OnInit, OnDestroy, NgModuleRef, ViewContainerRef, Input } from '@angular/core';

import { ELazyModules } from '../core/models';
import { LazyLoaderService } from './lazy-loader.service';

@Directive({
  selector: '[fmLoadModule]'
})
export class LoadModuleDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('fmLoadModule') moduleName: ELazyModules;

  private _moduleRef: NgModuleRef<any>;

  constructor(private readonly _vcr: ViewContainerRef, private readonly _loaderService: LazyLoaderService) {}

  ngOnInit(): void {
    this._loaderService.load(this.moduleName, this._vcr).then(moduleRef => (this._moduleRef = moduleRef));
  }
  ngOnDestroy(): void {
    if (this._moduleRef) {
      this._moduleRef.destroy();
    }
  }
}
