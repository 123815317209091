/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';

import { FmFlyoutPanelComponent } from '@fleetmatics/ui.navbar';
import { isElementNull } from '@fleetmatics/ui.utilities';
import { ISearchLightPanel } from './search-light-panel.interface';
import { ISearchLightCategory, ISearchLightResult } from '../../model';
import { FmSearchLightCategoryMenuComponent } from '../search-light-category-menu';

@Component({
  selector: 'fm-search-light-panel',
  templateUrl: './search-light-panel.component.html',
  styleUrls: ['./search-light-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'fmSearchLightPanel'
})
export class FmSearchLightPanelComponent implements ISearchLightPanel {
  @Input()
  public containerWidth: number;
  @Input()
  public errorMessage: string;
  @Input()
  public set hasError(hasError: boolean) {
    this._hasError = hasError;
    this.changeDetectorRef.markForCheck();
  }
  public get hasError(): boolean {
    return this._hasError;
  }
  @Input()
  public set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    this.changeDetectorRef.markForCheck();
  }
  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input()
  public isOpen = false;
  @Input()
  public set isSearchValid(isSearchValid: boolean) {
    this._searchValid = isSearchValid;
    this.changeDetectorRef.markForCheck();
  }
  public get isSearchValid(): boolean {
    return this._searchValid;
  }
  @Input()
  public minCharsMessage: string;
  @Input()
  public noResultMessage: string;
  @Input()
  public set results(results: ISearchLightCategory[]) {
    this._results = results;
    this.changeDetectorRef.detectChanges();

    this.showResults(true);
  }
  public get results(): ISearchLightCategory[] {
    return this._results;
  }
  @Input()
  public trigger: CdkOverlayOrigin;

  @Output()
  public isOpenChange = new EventEmitter<boolean>();
  @Output()
  public resultSelected = new EventEmitter<ISearchLightResult>();

  @ViewChild(FmSearchLightCategoryMenuComponent, { static: false })
  public categoryMenu: FmSearchLightCategoryMenuComponent;
  @ViewChild(FmFlyoutPanelComponent, { static: false })
  public set flyoutPanel(flyoutPanel: FmFlyoutPanelComponent) {
    if (isElementNull(flyoutPanel)) {
      return;
    }

    this._flyoutPanel = flyoutPanel;

    this._flyoutPanel.isOpenChange.subscribe((isOpen: boolean) => {
      this.isOpen = isOpen;
      this.isOpenChange.emit(isOpen);
    });
  }
  public get flyoutPanel(): FmFlyoutPanelComponent {
    return this._flyoutPanel;
  }

  public mouseLeave: EventEmitter<MouseEvent> = new EventEmitter();
  public mouseOver: EventEmitter<MouseEvent> = new EventEmitter();

  private _flyoutPanel: FmFlyoutPanelComponent;
  private _hasError = false;
  private _isLoading = false;
  private _results: ISearchLightCategory[];
  private _searchValid: boolean;

  constructor(public readonly changeDetectorRef: ChangeDetectorRef) {}

  public onCategoryMenuClosed(): void {
    this.close();
  }

  public onCategoryResultSelected(result: ISearchLightResult): void {
    this.close();
    this.resultSelected.emit(result);
  }

  public open(): void {
    this._flyoutPanel.open();
  }

  public close(): void {
    this._flyoutPanel.close();
  }

  public onPanelOpened(): void {
    this.showResults(false);
  }

  public onPanelClosed(): void {
    this.hideResults();
  }

  public hideResults(): void {
    if (!this._areResultsValid()) {
      return;
    }

    if (this.results.length > 0) {
      this.categoryMenu.close();
    }
  }

  public showResults(focus: boolean): void {
    if (!this._areResultsValid()) {
      return;
    }

    if (this.results.length > 0) {
      this.categoryMenu.open(focus);
    }
  }

  private _areResultsValid(): boolean {
    return this.results !== undefined && this.results !== null;
  }
}
