/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, HostBinding, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'fm-modal-footer',
  templateUrl: './modal-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFooterComponent {
  @HostBinding('class.modal-footer')
  hostClass = true;
}
