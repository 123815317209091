/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { ConfigService, isElementNull } from '@fleetmatics/ui.utilities';

import { INotification, ENotificationTypes, EDeviceStatusCode, EDeviceStatusActionType } from '../models';
import { IAppConfig } from '../../../../config';

const DEFAULT_NOTIFICATION_TEMPORARY_TIMEOUT_MS = 5000;

@Component({
  selector: 'fm-notification',
  animations: [
    trigger('onInteraction', [
      state('in', style({ transform: 'translateX(0px)' })),
      transition('* => in', [style({ transform: 'translateX(345px)' }), animate('192ms')]),

      state('fade', style({ transform: 'translateX(345px)' })),
      transition('* => fade', [style({ transform: 'translateX(0)' }), animate('192ms')]),

      state('panicIn', style({ transform: 'translateY(0px)' })),
      transition('* => in', [style({ transform: 'translateY(5px)' }), animate('192ms')]),

      state('panicFade', style({ transform: 'translateY(5px)' })),
      transition('* => fade', [style({ transform: 'translateY(0)' }), animate('192ms')])
    ])
  ],
  templateUrl: './notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input()
  public set data(notification: INotification) {
    this.isPanic = notification.type === ENotificationTypes.panic;
    this.isDeviceStatus = notification.type === ENotificationTypes.deviceStatusChange;
    if (!isElementNull(notification.troubleCode)) {
      this.isTowing = notification.troubleCode === EDeviceStatusCode.towing;
      this.isInstallationIssue = notification.troubleCode === EDeviceStatusCode.installationError;
    }
    if (!isElementNull(notification.exclusiveActionType)) {
      this.isCorrect = notification.exclusiveActionType === EDeviceStatusActionType.confirmedAsCorrect;
      this.isIncorrect = notification.exclusiveActionType === EDeviceStatusActionType.confirmedAsIncorrect;
    }
    this._setInitialAnimation();
    this.notification = notification;
  }

  @Input() set invokeClose(value: boolean) {
    if (value) {
      this.close(null);
    }
  }

  @Output() clicked = new EventEmitter<INotification>();
  @Output() removeNotification = new EventEmitter<INotification>();
  @Output() closeNotification = new EventEmitter<INotification>();

  public dataState: string;
  public isPanic: boolean;
  public isDeviceStatus: boolean;
  public isTowing: boolean;
  public isCorrect: boolean;
  public isIncorrect: boolean;
  public isInstallationIssue: boolean;
  public notification: INotification;

  private readonly _timeout: number;
  private _timer: any;
  private readonly _animationSpeed = 192;

  constructor(private readonly _cdr: ChangeDetectorRef, private readonly _configSvc: ConfigService<IAppConfig>) {
    this._timeout = this._configSvc?.config?.notificationsTemporaryTimeoutMs || DEFAULT_NOTIFICATION_TEMPORARY_TIMEOUT_MS;
  }

  ngOnInit(): void {
    if (this.notification.temporaryPopup) {
      this._startTimeOut();
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this._timer);
  }

  public onEnter(): void {
    if (this._timer) {
      clearTimeout(this._timer);
    }
  }

  public onLeave(): void {
    if (this.notification.temporaryPopup) {
      clearTimeout(this._timer);
      this._startTimeOut();
    }
  }

  public close(event: Event): void {
    if (!isElementNull(event)) {
      event.stopPropagation();
    }
    this._remove();
    setTimeout(() => {
      this.closeNotification.emit(this.notification);
    }, this._animationSpeed);
  }

  public notificationClicked(data: INotification): void {
    this.clicked.emit(data);
  }

  private _setInitialAnimation(): void {
    if (this.isPanic) {
      this.dataState = 'panicIn';
    } else {
      this.dataState = 'in';
    }
  }

  private _startTimeOut(): void {
    this._timer = setTimeout(() => {
      this._remove();
      setTimeout(() => {
        this.removeNotification.emit(this.notification);
      }, this._animationSpeed);
      this._cdr.markForCheck();
    }, this._timeout);
  }

  private _remove(): void {
    if (this.isPanic) {
      this.dataState = 'panicFade';
    } else {
      this.dataState = 'fade';
    }
  }
}
