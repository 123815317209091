/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  HostBinding,
  HostListener,
  ViewChild
} from '@angular/core';
import { Router, NavigationEnd, Params, RouterOutlet } from '@angular/router';
import { AnimationEvent } from '@angular/animations';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom, mapTo } from 'rxjs/operators';

import { getNavbarMenuItemsArray, INavbarMenuItem } from '@fleetmatics/ui.navbar';
import { ConfigService, isArrayNullOrEmpty, isElementNull } from '@fleetmatics/ui.utilities';

import { IAppConfig } from './config';
import { InitializeNotifications } from './core/components';
import { IUserPermissions } from './core/models';
import { WindowResizeService, AppLauncherService, IReplayRouteParams } from './core/services';
import {
  GetAccessibleVehiclesCount,
  GetLayoutPreferences,
  GetLayoutPreferencesSuccess,
  GetSelectedAssets,
  GetUserMapSettings,
  GetUserRoadsideAssistanceStatus,
  StartGarminStopsPolling,
  UpdateConfigs,
  UpdateAnimationState,
  SetUserSettingsTokenValue,
  UpdateIsAppAnimationsEnabled,
  GetVehiclePlotsForUser,
  GetMapBoundsFromUserPlots
} from './core/store/actions';
import {
  getIsFullscreen,
  getIsGarminStopsDisplayEnabled,
  getIsRoadsideAssistanceAdmin,
  getIsRoadsideAssistanceReadOnly,
  getLayoutPreferences,
  getUserFeatures,
  getUserPermissions,
  getAnimationState,
  getUserSettings,
  getIsAppAnimationsEnabled,
  getMapChangeBounds,
  getVehicleDriverPanelModuleLoaded,
  getMapModuleLoaded
} from './core/store/selectors';
import { IAppState, ILayoutPreferencesState, EMapAnimationState } from './core/store/state';
import { ISize } from './maps/models';
import { MapAnimations } from './maps/animations';
import { ROUTE_PARAMS } from './core/constants';

@Component({
  selector: 'fm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [MapAnimations.appAnimation, MapAnimations.expandMainWindow]
})
export class AppComponent implements OnInit, OnDestroy {
  public isFullscreen$: Observable<boolean>;
  public menuItems$: Observable<INavbarMenuItem[]>;
  public isRoadsideAssistanceAdmin$: Observable<boolean>;
  public isRoadsideAssistanceReadOnly$: Observable<boolean>;
  public animationState$: Observable<EMapAnimationState>;
  public isVehicleDriverPanelLoaded$: Observable<boolean>;
  public isMapLoaded$: Observable<boolean>;
  public isPlatformBrowser: boolean;

  @HostBinding('@appAnimation')
  public animationState: {
    value: string;
    params: {
      mainWindowHeight: number;
      mainWindowWidthPanelOpen: number;
      navbarHeight: number;
    };
  };

  @HostBinding('@.disabled')
  public isAnimationDisabled: boolean;

  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  private _navbarHeight: number;
  private _mainWindowHeight: number;
  private _mainWindowWidthWithPanel: number;

  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _store: Store<IAppState>,
    private readonly _configService: ConfigService<IAppConfig>,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _windowResizeService: WindowResizeService,
    private readonly _router: Router,
    private readonly _appLauncherService: AppLauncherService
  ) {
    this.isPlatformBrowser = isPlatformBrowser(this._platformId);
  }

  public ngOnInit(): void {
    this.isFullscreen$ = this._store.pipe(select(getIsFullscreen));
    this.menuItems$ = this._store.pipe(select(getNavbarMenuItemsArray));
    this.isRoadsideAssistanceAdmin$ = this._store.pipe(select(getIsRoadsideAssistanceAdmin));
    this.isRoadsideAssistanceReadOnly$ = this._store.pipe(select(getIsRoadsideAssistanceReadOnly));

    this.animationState$ = this._store.pipe(select(getAnimationState));
    this._onChangesCalculateHeightAndWidth();
    this._setupAnimationStateTransitions();
    this._populateTokensFromStore();

    this.isVehicleDriverPanelLoaded$ = this._store.pipe(select(getVehicleDriverPanelModuleLoaded));
    this.isMapLoaded$ = this._store.pipe(select(getMapModuleLoaded));
    this._launchApp();

    // Custom error handler to reset the router and refresh the page on error
    // Error is caused when navigated to aux route (notification panel for example) and refreshing while aux route is active
    this._router.errorHandler = e => {
      console.warn(e);
      this.outlet.deactivate();
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => this._router.navigate(['/']));
    };
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public shouldDisableSsrApis() {
    return isPlatformServer(this._platformId) && this._configService.config.disableSsrApis;
  }

  @HostListener('@appAnimation.done', ['$event'])
  onAnimationComplete(event: AnimationEvent) {
    // when overlay hidden is completed then start replay side panel animation
    if (
      (event.fromState === EMapAnimationState.default || event.fromState === EMapAnimationState.fullscreen) &&
      event.toState === EMapAnimationState.overlayHidden
    ) {
      this._store.dispatch(new UpdateAnimationState(EMapAnimationState.replayPanelClosed));
    }

    // turn animation on when panel open/close animation (disabled animation) is finished
    if (event.toState === EMapAnimationState.replayPanelOpen || event.fromState === EMapAnimationState.replayPanelOpen) {
      this._store.dispatch(new UpdateIsAppAnimationsEnabled(true));
    }
  }

  private _setupAnimationStateTransitions(): void {
    this.animationState$.pipe(takeUntil(this._unsubscribe$)).subscribe(toState => {
      this.animationState = {
        value: toState,
        params: {
          mainWindowHeight: this._mainWindowHeight,
          mainWindowWidthPanelOpen: this._mainWindowWidthWithPanel,
          navbarHeight: this._navbarHeight
        }
      };
    });

    this._store.pipe(select(getIsAppAnimationsEnabled), takeUntil(this._unsubscribe$)).subscribe(isAppAnimationsEnabled => {
      this.isAnimationDisabled = !isAppAnimationsEnabled;
    });
  }

  private _onChangesCalculateHeightAndWidth() {
    this._windowResizeService.windowSize$
      .pipe(takeUntil(this._unsubscribe$), withLatestFrom(this.menuItems$))
      .subscribe(([size, menuItems]: [ISize, INavbarMenuItem[]]) => {
        const tooManyItems = menuItems.length > 7;
        const isLarge = size.width >= 1200 || (size.width >= 1024 && size.width <= 1200 && !tooManyItems);
        this._navbarHeight = isLarge ? 80 : 56;
        this._calculateMainWindowHeight(size.height);
        this._calculateMainWindowWidthWithPanel(size.width);
      });
  }

  private _calculateMainWindowHeight(windowHeight: number): void {
    this._mainWindowHeight = windowHeight - this._navbarHeight;
  }

  private _calculateMainWindowWidthWithPanel(windowWidth: number): void {
    this._mainWindowWidthWithPanel = windowWidth - 360;
  }

  private _populateTokensFromStore(): void {
    if (isPlatformBrowser(this._platformId)) {
      this._store
        .pipe(
          select(getUserSettings),
          filter(userSettings => !isElementNull(userSettings)),
          take(1)
        )
        .subscribe(userSettings => {
          this._store.dispatch(new SetUserSettingsTokenValue(userSettings));
        });
    }
  }

  private _launchApp() {
    const onNavigationCompleted$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(1),
      mapTo(void 0)
    );

    onNavigationCompleted$.subscribe(() => {
      const urlTree = this._router.parseUrl(this._router.url);
      const isReplay =
        urlTree.root.children?.primary?.segments?.length > 0 &&
        urlTree.root.children.primary.segments[0]?.path === ROUTE_PARAMS.REPLAY.ROUTE;
      if (isReplay) {
        this._launchReplay(urlTree.queryParams);
      } else {
        this._appComponentActions();
        this._afterMapLoadsActions();
        this._featureTogglesOverrides(urlTree.queryParams);
      }
    });
  }

  /* usage:

  features to enable:
  use featureTogglesEnable to include a ';' seprated list of feature to enable
  use featureTogglesDisable to include a ';' seprated list of feature to disable

  exmaple below will enable myFeature1 and myFeature2 and disable myFeature3
  http://localhost/?featureTogglesEnable=myFeature1;myFeature2&featureTogglesDisable=myFeature3;

  it is case insensetive and the following should work as well:
  http://localhost/?featuretogglesenable=myfeature1;myfeature2&featuretogglesdisable=myfeature3;
  */
  private _featureTogglesOverrides(queryParams: Params): void {
    const overrides = this._readFeatureTogglesToEnableFromQueryParams(queryParams);

    if (!isArrayNullOrEmpty(overrides.enable) || !isArrayNullOrEmpty(overrides.disable)) {
      this._appLauncherService.overrideFeatureToggles(overrides.enable, overrides.disable);
    }
  }

  private _readFeatureTogglesToEnableFromQueryParams(
    queryParams: Params
  ): {
    enable: string[];
    disable: string[];
  } {
    // convert query params to lower case
    const lowerCaseQueryParam = this._paramsToLowerCase(queryParams);
    // read feature toggles parameter
    const togglesToEnable = (lowerCaseQueryParam[ROUTE_PARAMS.FeatureToggles.Enabled.toLowerCase()]?.toLowerCase() as string)?.split(';');
    const togglesToDisable = (lowerCaseQueryParam[ROUTE_PARAMS.FeatureToggles.Disabled.toLowerCase()]?.toLowerCase() as string)?.split(';');

    return {
      enable: togglesToEnable,
      disable: togglesToDisable
    };
  }

  private _launchReplay(queryParams: Params): void {
    const routeParams: IReplayRouteParams = this._mapQueryParamsToLaunchReplayParams(queryParams);
    this._appLauncherService.launchReplay(routeParams);
  }

  private _mapQueryParamsToLaunchReplayParams(queryParams: Params): IReplayRouteParams {
    const lowerCaseQueryParam = this._paramsToLowerCase(queryParams);
    const routeParams: IReplayRouteParams = {
      vehicleId: lowerCaseQueryParam[ROUTE_PARAMS.REPLAY.VEHICLE_ID.toLowerCase()],
      start: lowerCaseQueryParam[ROUTE_PARAMS.REPLAY.START.toLowerCase()],
      preselectSegmentTime: lowerCaseQueryParam[ROUTE_PARAMS.REPLAY.PRESELECT_SEGMENT_TIME.toLowerCase()],
      minimumStopDuration: lowerCaseQueryParam[ROUTE_PARAMS.REPLAY.MINIMUM_STOP_DURATION.toLowerCase()],
      minimumIdleDuration: lowerCaseQueryParam[ROUTE_PARAMS.REPLAY.MINIMUM_IDLE_DURATION.toLowerCase()],
      showIdleSegments: lowerCaseQueryParam[ROUTE_PARAMS.REPLAY.SHOW_IDLE_SEGMENTS.toLowerCase()]
    };
    return routeParams;
  }

  private _paramsToLowerCase(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      if ((<object>params).hasOwnProperty(key)) {
        lowerParams[key.toLowerCase()] = params[key];
      }
    }

    return lowerParams;
  }

  private _appComponentActions(): void {
    // actions to dispatch on client only
    if (this.isPlatformBrowser) {
      this._getLayoutPreferences();
      if (!this._configService.config.ssrGetMapBounds.enabled) {
        this._store.dispatch(new GetVehiclePlotsForUser());
      }
    }
    if (this.shouldDisableSsrApis()) {
      return;
    }

    // actions to dispatch on SSR and on client only if the call failed on SSR
    this._getUserMapSettings();
    if (this._configService.config.ssrGetMapBounds.enabled) {
      this._getMapBoundsFromUserPlots();
    }
  }

  private _afterMapLoadsActions(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.isMapLoaded$
        .pipe(
          filter(isLoaded => isLoaded),
          take(1)
        )
        .subscribe(() => {
          this._getInitialDataAfterMapLoads();
        });
    }
  }

  private _getInitialDataAfterMapLoads(): void {
    this._store.dispatch(new InitializeNotifications());
    this._store.dispatch(new GetAccessibleVehiclesCount());
    this._store.dispatch(new UpdateConfigs(this._configService.config));
    this._store.dispatch(new GetSelectedAssets());
    if (this._configService.config.ssrGetMapBounds.enabled) {
      this._store.dispatch(new GetVehiclePlotsForUser());
    }

    this._startGarminStopPolling();

    this._getUserRoadsideAssistanceStatus();
  }

  private _getUserRoadsideAssistanceStatus(): void {
    this._store
      .pipe(
        select(getUserFeatures),
        filter(features => !isElementNull(features) && features.roadSideAssistance),
        take(1)
      )
      .subscribe(() => this._store.dispatch(new GetUserRoadsideAssistanceStatus()));
  }

  private _startGarminStopPolling(): void {
    this._store
      .pipe(
        select(getIsGarminStopsDisplayEnabled),
        filter(isEnabled => isEnabled),
        takeUntil(this._unsubscribe$)
      )
      .subscribe(() => {
        this._store.dispatch(new StartGarminStopsPolling());
      });
  }

  private _getUserMapSettings(): void {
    this._store.pipe(select(getUserPermissions), take(1)).subscribe((userData: IUserPermissions) => {
      if (isElementNull(userData)) {
        this._store.dispatch(new GetUserMapSettings());
      }
    });
  }

  private _getMapBoundsFromUserPlots(): void {
    this._store.pipe(select(getMapChangeBounds), take(1)).subscribe(bounds => {
      // dispatch only if map bounds are unknown at this stage
      if (isElementNull(bounds)) {
        this._store.dispatch(new GetMapBoundsFromUserPlots());
      }
    });
  }

  private _getLayoutPreferences(): void {
    this._store.pipe(select(getLayoutPreferences), take(1)).subscribe((layoutPreferences: ILayoutPreferencesState) => {
      if (isElementNull(layoutPreferences)) {
        this._store.dispatch(new GetLayoutPreferences());
      } else {
        this._store.dispatch(new GetLayoutPreferencesSuccess(layoutPreferences));
      }
    });
  }
}
