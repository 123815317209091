/*
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { ISearchLightCategory } from '../../model';

@Component({
  selector: 'fm-search-light-category-menu-option',
  templateUrl: './search-light-category-menu-option.component.html',
  styleUrls: ['./search-light-category-menu-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FmSearchLightCategoryMenuOptionComponent {
  @Input()
  public category: ISearchLightCategory;

  @Input()
  public isActive = false;

  public shouldShowMenuOption(): boolean {
    return this.category !== undefined && this.category !== null;
  }
}
