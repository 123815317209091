/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { ILayoutState, EMapAnimationState } from '../state';
import { EMapTypeId, ILatLng, IReplayLayout, Position, IIndexableObject, EOverlayContainer } from '../../models';

export const getLayoutState = createFeatureSelector<ILayoutState>('layout');

export const getZoomToLocation = createSelector(getLayoutState, (state: ILayoutState): ILatLng => state.locationToZoom);

export const getZoomToMarkerId = createSelector(getLayoutState, (state: ILayoutState): number => state.locationToMarkerId);

export const getZoomToMarker = createSelector(getLayoutState, (state: ILayoutState): ILatLng => state.locationToMarker);

export const getIsMapFitted = createSelector(getLayoutState, (state: ILayoutState): boolean => state.isMapFitted);

export const getTriggerFitToMap = createSelector(getLayoutState, (state: ILayoutState): Object => state.triggerFitToMap);

export const getMapType = createSelector(getLayoutState, (state: ILayoutState): EMapTypeId => state.mapType);

export const getReplayLayout = createSelector(getLayoutState, (state: ILayoutState): IReplayLayout => state.replayLayout);

export const getIsReplayOpen = createSelector(getReplayLayout, (state: IReplayLayout): boolean => state.isReplayOpen);

export const getIsReplayDatePickerPanelOpen = createSelector(
  getReplayLayout,
  (state: IReplayLayout): boolean => state.isReplayDatePickerOpen
);

export const getAnimationState = createSelector(getLayoutState, (state): EMapAnimationState => state.animationState);

export const getShowReplay = createSelector(
  getAnimationState,
  getIsReplayOpen,
  (animationState, isReplayOpen): boolean =>
    animationState === EMapAnimationState.replayPanelOpen || (isReplayOpen && animationState === EMapAnimationState.replayPanelClosed)
);

export const getIsFullscreen = createSelector(getLayoutState, (state: ILayoutState): boolean => state.isFullscreen);

export const getIsAppAnimationsEnabled = createSelector(getLayoutState, (state: ILayoutState): boolean => state.isAppAnimationsEnabled);

export const getLayoutMarginsMap = createSelector(getLayoutState, (state): IIndexableObject<Position> => state.overlayMargins);

export const getLayoutMargins = createSelector(
  getIsReplayOpen,
  getLayoutMarginsMap,
  (isReplayOpen, marginsMap): Position => {
    const overlays: EOverlayContainer[] = isReplayOpen
      ? [EOverlayContainer.Replay]
      : [EOverlayContainer.VehicleDriverPanel, EOverlayContainer.Usabilla, EOverlayContainer.MapControls];
    const layoutMargins: Position = aggregateMargins(overlays, marginsMap);

    return layoutMargins;
  }
);

function aggregateMargins(overlays: EOverlayContainer[], marginsMap: IIndexableObject<Position>): Position {
  const margins = new Position();
  overlays.forEach(overlay => {
    const currentMargins = marginsMap[overlay];
    if (!isElementNull(currentMargins)) {
      margins.top = Math.max(margins.top, currentMargins.top);
      margins.right = Math.max(margins.right, currentMargins.right);
      margins.bottom = Math.max(margins.bottom, currentMargins.bottom);
      margins.left = Math.max(margins.left, currentMargins.left);
    }
  });

  return margins;
}
