/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

export interface IConfigState {
  isWorkingTimeDirectiveEnabled: boolean;
  isSchedulerEnabled: boolean;
}

export const initialConfigState: IConfigState = {
  isWorkingTimeDirectiveEnabled: false,
  isSchedulerEnabled: false
};
