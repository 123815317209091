/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EGarminStopStatus {
  DISPATCH_ABORTED = -7,
  DRIVER_ABORTED = -6,
  FAILED = -4,
  SENDING = -1,
  SENDING_AND_MAKING_ACTIVE = 1,
  MAKING_ACTIVE = 2,
  MAKING_DONE = 3,
  RE_SYNCING = 5,
  RE_SYNCING_AND_MAKING_ACTIVE = 7,
  RE_SYNCING_AND_MAKING_DONE = 8,
  EN_ROUTE = 100,
  DONE = 101,
  RECEIVED = 102,
  PENDING = 103,
  DELETED = 104
}
