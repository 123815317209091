/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */
import { NgModule } from '@angular/core';

import { MapIconRegistryService } from '../../services';
import { IIconNamePathPair } from '../../models/icon-name-path-pair.interface';

import icExclamation from '!!raw-loader!../../../../assets/images/notifications/ic-exclamation.svg';
import icExpand from '!!raw-loader!../../../../assets/images/notifications/ic-expand.svg';
import icCollapse from '!!raw-loader!../../../../assets/images/notifications/ic-collapse.svg';

@NgModule()
export class NotificationsIconModule {
  private readonly _icons: IIconNamePathPair[] = [
    { name: 'ic-exclamation', svg: icExclamation },
    { name: 'ic-expand', svg: icExpand },
    { name: 'ic-collapse', svg: icCollapse }
  ];

  constructor(private readonly _mapIconRegistryService: MapIconRegistryService) {
    this._registerIcons();
  }

  private _registerIcons() {
    this._icons.forEach(icon => this._mapIconRegistryService.addSvgIcon(icon));
  }
}
