/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ISchedulerHttpService } from './scheduler-http.service.interface';
import { IAppointmentResponse } from '../../models';
import { IAppConfig, APP_CONFIG_TOKEN } from '../../../config';

@Injectable()
export class SchedulerHttpService implements ISchedulerHttpService {
  constructor(@Inject(APP_CONFIG_TOKEN) private readonly _config: IAppConfig, private readonly _httpClient: HttpClient) {}

  getAppointments(driverId: number): Observable<IAppointmentResponse[]> {
    return this._httpClient.get<IAppointmentResponse[]>(
      `${this._config.endpoints.schedulerApiEndpoint}/v2/technicians/${driverId}/visible-appointments`
    );
  }
}
