/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  Output,
  EventEmitter,
  AfterViewInit,
  Input,
  OnDestroy
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { ButtonToggleComponent } from './button-toggle/button-toggle.component';

@Component({
  selector: 'fm-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonToggleGroupComponent implements AfterViewInit, OnDestroy {
  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;

    if (this._toggleButtons) {
      for (const toggleButton of this._toggleButtons.toArray()) {
        if (value === toggleButton.value) {
          toggleButton.isSelected = true;
          break;
        }
      }
      this.selectionChanged.emit(value);
    }
  }

  @Output()
  public selectionChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public selectionCleared: EventEmitter<void> = new EventEmitter<void>();

  @ContentChildren(ButtonToggleComponent)
  private readonly _toggleButtons: QueryList<ButtonToggleComponent>;

  private _selectedToggleButton: ButtonToggleComponent;
  private _value: string;
  private readonly _unsubscribe$ = new Subject<void>();

  public ngAfterViewInit(): void {
    this._toggleButtons.forEach((toggleButton, index) => {
      toggleButton.controlId = index;
      if (this._value === toggleButton.value) {
        toggleButton.isSelected = true;
        this._selectedToggleButton = toggleButton;
      }
      toggleButton.selected
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe((selectedToggleButton: ButtonToggleComponent) => this._selectSingle(selectedToggleButton));
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private _selectSingle(selectedToggle: ButtonToggleComponent): void {
    if (selectedToggle.isSelected) {
      this._toggleButtons.forEach(toggleButton => {
        if (toggleButton.controlId !== selectedToggle.controlId) {
          toggleButton.isSelected = false;
          this._selectedToggleButton = toggleButton;
        }
      });
      this._selectedToggleButton = selectedToggle;
      if (!isElementNull(selectedToggle.value)) {
        this.selectionChanged.emit(selectedToggle.value);
      }
    } else {
      if (this._selectedToggleButton && this._selectedToggleButton.controlId === selectedToggle.controlId) {
        this.selectionCleared.emit();
      }
    }
  }
}
