/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ISendToGarminState } from '../state';
import { INavigationEnabledVehicles, IReverseGeocode } from '../../models';

export const getSendToGarminState = createFeatureSelector<ISendToGarminState>('sendToGarmin');

export const getGeocodeInfo = createSelector(
  getSendToGarminState,
  (state: ISendToGarminState): IReverseGeocode => state.geocodeInfo
);

export const getNavigationEnabledVehicles = createSelector(
  getSendToGarminState,
  (state: ISendToGarminState): INavigationEnabledVehicles[] => state.vehicles
);
