/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EReplaySegmentStatus {
  Start,
  Moving,
  Stopped,
  Idle,
  End,
  Private,
  InProgress
}
