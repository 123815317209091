/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { SortUtils } from '../../../core/utils';

@Pipe({
  name: 'objectFieldSort'
})
export class ObjectFieldSortPipe implements PipeTransform {
  transform<T>(array: T[], field: string, direction: 'asc' | 'desc' = 'asc'): T[] {
    if (!array || array.length === 0 || !array[0].hasOwnProperty(field)) {
      return array;
    }
    array.sort((a: any, b: any) =>
      typeof a[field] === 'string' ? SortUtils.naturalSortComparer(a[field], b[field]) : this._simpleSort(a[field], b[field])
    );
    return direction === 'asc' ? array : array.reverse();
  }

  private _simpleSort(a: any, b: any): -1 | 0 | 1 {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
}
