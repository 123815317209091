/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export interface IGetJourneyForVehicleResponse {
  centricities: VehicleCentricity[];
  metrics: IMetric[];
}

export interface ICentricity {
  $type: ECentricityType;
}

export interface IMetric {
  $type: EMetricType;
}

export class VehicleCentricity {
  vehicles: Vehicle[];
}

export class SegmentCentricity implements ICentricity {
  $type: ECentricityType.JourneySegmentCentricity;
  segments: Segment[];
}

export class Vehicle implements ICentricity {
  id: number;
  $type: ECentricityType.VehicleCentricity;
  centricities: SegmentCentricity[];
  metrics: IMetric[];
}

export class Segment {
  $type: ESegmentType;
  distanceKms: number;
  driver: IDriver;
  durationSeconds: number;
  start: ISegmentStartStopTimeLocation;
  stop: ISegmentStartStopTimeLocation;
  harshDrivingEvents: ISegmentHarshDrivingResponse;
}

export interface ISegmentHarshDrivingResponse {
  highSpeedEvents: number;
  moderateAccelerationEvents: number;
  moderateBrakingEvents: number;
  moderateLeftCorneringEvents: number;
  moderateRightCorneringEvents: number;
  severeAccelerationEvents: number;
  severeBrakingEvents: number;
  severeLeftCorneringEvents: number;
  severeRightCorneringEvents: number;
}

export interface ISegmentStartStopTimeLocation {
  address: {
    formattedAddress: string;
    position: { latitude: number; longitude: number };
    places: ISegmentPlace[];
  };
  private: boolean;
  time: {
    utc: string;
    timeZone: string;
  };
}

export interface IDriver {
  id: number;
  $type: EDriverType;
}

export interface ISegmentPlace {
  $type: ESegmentPlaceType;
  placeId: number;
  placeName: string;
}

export enum ECentricityType {
  VehicleCentricity = 'VehicleCentricity',
  JourneySegmentCentricity = 'JourneySegmentCentricity'
}

export enum ESegmentType {
  JourneySegment = 'JourneySegment',
  IdleSegment = 'IdleSegment',
  StopSegment = 'StopSegment'
}
export enum EMetricType {
  TotalDistanceTravelled = 1
}
export enum EDriverType {
  UnknownDriver = 'UnknownDriver',
  Driver = 'Driver'
}
export enum ESegmentPlaceType {
  Geofence = 'GeoFence',
  Hotspot = 'HotSpot',
  Fuelsite = 'FuelSite'
}
