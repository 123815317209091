/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAppState } from '../../../core/store/state/app.state';
import { ShowShareDemoModal } from '../../store/demo.actions';
import { IDemoVariationContent } from '../../models';
import { getDemoVariationContent } from '../../store/demo.selectors';

@Component({
  selector: 'fm-mobile-nav-card',
  templateUrl: './mobile-nav-card.component.html',
  styleUrls: ['./mobile-nav-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileNavCardComponent implements OnInit {
  public demoVariationContent$: Observable<IDemoVariationContent>;

  constructor(private readonly _store: Store<IAppState>) {}

  ngOnInit(): void {
    this.demoVariationContent$ = this._store.pipe(select(getDemoVariationContent));
  }

  public showTryOnDesktop(): void {
    this._store.dispatch(new ShowShareDemoModal());
  }
}
