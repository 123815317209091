/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IModulesState } from '../state';
import { ELazyModules } from '../../models';

export const getModulesState = createFeatureSelector<IModulesState>('modules');

export const getModuleState = (module: ELazyModules) =>
  createSelector(getModulesState, (state: IModulesState): boolean => state.loadedModules[module]);

export const getMapModuleLoaded = getModuleState(ELazyModules.Maps);

export const getMapOptionsModuleLoaded = getModuleState(ELazyModules.MapOptions);

export const getReplayModuleLoaded = getModuleState(ELazyModules.Replay);

export const getVehicleDriverPanelModuleLoaded = getModuleState(ELazyModules.VehicleDriverPanel);

export const getExternalModuleLoaded = getModuleState(ELazyModules.External);

export const getOverlayControlsModuleLoaded = getModuleState(ELazyModules.OverlayControls);
