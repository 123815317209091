/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { ICategoriesHttpService } from './categories-http.service.interface';
import { IPlaceCategory } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';

@Injectable()
export class CategoriesHttpService implements ICategoriesHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.categoriesApiEndpoint;
  }

  public getPlaceCategories(): Observable<IPlaceCategory[]> {
    return this._httpClient.get<IPlaceCategory[]>(`${this._rootUrl}/Category/MapCategories/`);
  }
}
