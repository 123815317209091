/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

import { ISelectItem } from '@fleetmatics/ui.base-library';
import { isArrayNullOrEmpty } from '@fleetmatics/ui.utilities';

import { INotification, IDeviceStatusModalData, EDeviceStatusContactDetails } from '../models';

@Component({
  selector: 'fm-device-status',
  templateUrl: './device-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceStatusComponent implements OnInit {
  @Input()
  public set devicesStatusToReview(value: INotification[]) {
    this.deviceStatusNotifications = value;
    if (!isArrayNullOrEmpty(value)) {
      this.currentDeviceStatus = value[0];
    }
  }

  @Output()
  public notificationAcknowledged = new EventEmitter<INotification>();
  @Output()
  public notificationDismissed = new EventEmitter<INotification>();
  @Output()
  public notificationNotAcknowledged = new EventEmitter<IDeviceStatusModalData>();
  @Output()
  public showOnMapClicked = new EventEmitter<string>();

  public changeToNotCorrectForm = false;
  public currentDeviceStatus: INotification;
  public deviceStatusNotifications: INotification[];
  public sendContactDetailsForm: FormGroup;
  public EDeviceStatusContactDetails = EDeviceStatusContactDetails;
  public contactMethodSelected = EDeviceStatusContactDetails.email;

  public get name(): AbstractControl {
    return this.sendContactDetailsForm.get('name');
  }

  public get contact(): AbstractControl {
    return this.sendContactDetailsForm.get('contact');
  }

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.sendContactDetailsForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      contact: new FormControl(null, Validators.required)
    });
  }

  public onShowOnMapClicked(id: string): void {
    this.showOnMapClicked.emit(id);
  }

  public onDismissClick(notification: INotification): void {
    this.notificationDismissed.emit(notification);
  }

  public onYesClick(notification: INotification): void {
    this.notificationAcknowledged.emit(notification);
  }

  public selectTab(notification: INotification): void {
    this.changeToNotCorrectForm = false;
    this.currentDeviceStatus = notification;
    this._cdr.markForCheck();
  }

  public changeSelected(item: ISelectItem): void {
    this.contactMethodSelected = item.value as EDeviceStatusContactDetails;
  }

  public onCancelClick(): void {
    this.changeToNotCorrectForm = false;
    this.sendContactDetailsForm.reset();
  }

  public onSendClick(notification: INotification): void {
    if (this.sendContactDetailsForm.valid) {
      const data: IDeviceStatusModalData = {
        notification,
        formData: {
          name: this.name.value
        }
      };
      if (this.contactMethodSelected === EDeviceStatusContactDetails.email) {
        data.formData.email = this.contact.value;
      } else {
        data.formData.phone = this.contact.value;
      }
      this.notificationNotAcknowledged.emit(data);
    } else {
      if (this.name.invalid) {
        this.name.markAsTouched();
      }
      if (this.contact.invalid) {
        this.contact.markAsTouched();
      }
    }
  }
}
