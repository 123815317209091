/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';

import { FmDocumentRefService, isElementNull } from '@fleetmatics/ui.utilities';

import { IPoint } from './point.interface';
import { LatLng } from '../../models';

@Component({
  selector: 'fm-context-menu',
  templateUrl: './context-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuComponent implements AfterViewInit {
  @Input()
  public menuName = 'Menu';
  @Input()
  public latLng?: LatLng;

  @Input()
  public set position(position: IPoint) {
    if (!isElementNull(position)) {
      this.menuLeft = position.x;
      this.menuTop = position.y;
    }
  }
  @Input()
  public isRightClickDisabled = false;

  @Output()
  public closed: EventEmitter<void> = new EventEmitter();
  @Output()
  public copyCoordinatesClicked: EventEmitter<string> = new EventEmitter();

  public totalWidth: number;
  public totalHeight: number;
  public menuTop: number;
  public menuLeft: number;
  public contextMenuWidth: number;
  public contextMenuHeight: number;
  public closeTimeout: any;
  public formattedCoordinates: string;

  @ViewChild('contextMenu', { read: ElementRef })
  private readonly _contextMenu: ElementRef;

  constructor(private readonly _documentService: FmDocumentRefService, private readonly _cd: ChangeDetectorRef) {}

  public ngAfterViewInit() {
    this._formatLatLng();
    this.contextMenuWidth = this._contextMenu.nativeElement.offsetWidth;
    this.contextMenuHeight = this._contextMenu.nativeElement.offsetHeight;

    // @TODO investigate a better way to handle this
    const mapElement = <HTMLElement>this._documentService.nativeDocument.querySelector('div.fm-map-container');
    this.totalWidth = mapElement.offsetWidth;
    this.totalHeight = mapElement.offsetHeight;

    if (this.contextMenuHeight > this.totalHeight) {
      this.menuTop = 0;
    } else if (this.contextMenuHeight + this.menuTop > this.totalHeight) {
      this.menuTop = this.totalHeight - this.contextMenuHeight;
    } else {
      this.menuTop -= 5;
    }

    if (this.contextMenuWidth + this.menuLeft > this.totalWidth) {
      this.menuLeft -= this.contextMenuWidth - 5;
    } else {
      this.menuLeft -= 5;
    }

    this._cd.detectChanges();
  }

  public onClose(): void {
    this.closed.emit();
  }

  public onCloseWithDelay(): void {
    this.closeTimeout = setTimeout(() => {
      this.closed.emit();
    }, 500);
  }

  public cancelClose(): void {
    clearTimeout(this.closeTimeout);
  }

  public onRightClick($event: MouseEvent) {
    if (this.isRightClickDisabled) {
      $event.preventDefault();
    }
  }

  public copyCoordinates(): void {
    this.copyCoordinatesClicked.emit(this.formattedCoordinates);
  }

  private _formatLatLng(): void {
    if (this.latLng) {
      this.formattedCoordinates = `${this.latLng.lat.toFixed(5)}, ${this.latLng.lng.toFixed(5)}`;
    }
  }
}
