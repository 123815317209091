/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  TemplateRef
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DocumentRef } from '@fleetmatics/ui.base-library';

import { ModalComponent } from '../modal.component';
import { IModalConfig } from '../models/modal-config.interface';
import { IModalService } from './modal.service.interface';

@Injectable()
export class ModalService implements IModalService {
  public closed: Observable<void>;

  private _componentRef: ComponentRef<ModalComponent>;
  private readonly _closed = new Subject<void>();

  constructor(
    @Inject(ComponentFactoryResolver) private readonly _factoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _applicationRef: ApplicationRef,
    private readonly _documentRef: DocumentRef
  ) {
    this.closed = this._closed.asObservable();
  }

  public open(contentTemplate: TemplateRef<any>, config?: IModalConfig): void {
    const factory: ComponentFactory<ModalComponent> = this._factoryResolver.resolveComponentFactory(ModalComponent);
    const injector = Injector.create({ providers: [], parent: this._injector });
    const component: ComponentRef<ModalComponent> = factory.create(injector);
    this._componentRef = component;
    component.instance.contentTemplate = contentTemplate;
    component.instance.closeModal = this.close.bind(this);
    this.setConfig(config);
    this._applicationRef.attachView(component.hostView);
    this._documentRef.appendChild(component.location.nativeElement);
  }

  public close(): void {
    if (!this._componentRef) {
      return;
    }

    const componentEl = this._componentRef.location.nativeElement;
    componentEl.parentNode.removeChild(componentEl);
    this._applicationRef.detachView(this._componentRef.hostView);
    this._componentRef.destroy();
    this._componentRef = null;
    this._closed.next();
  }

  public setConfig(config: IModalConfig): void {
    if (!this._componentRef || !config) {
      return;
    }
    this._componentRef.instance.setConfig(config);
  }
}
