<fm-flyout-panel [connectedElement]="trigger?.elementRef.nativeElement"
  connectedSide="bottomLeft"
  connectedDirection="right"
  (panelOpen)="onPanelOpened()"
  (panelClose)="onPanelClosed()"
  [panelWidth]="containerWidth">

  <div class="fm-search-light-panel"
    [class.fm-search-light-panel--search-active]="results && results.length > 0">
    <div *ngIf="!results && !isSearchValid"
      class="fm-search-light-panel__warning dropdown-menu show">
      {{ minCharsMessage }}
    </div>

    <div *ngIf="hasError"
      class="fm-search-light-panel__warning dropdown-menu show">
      {{ errorMessage }}
    </div>

    <div *ngIf="results">
      <fm-search-light-category-menu *ngIf="results.length > 0"
        [categories]="results"
        (categoryResultSelect)="onCategoryResultSelected($event)"
        (menuClose)="onCategoryMenuClosed()">
      </fm-search-light-category-menu>

      <div *ngIf="results.length === 0 && !isLoading && !hasError"
        class="fm-search-light-panel__warning dropdown-menu show">
        {{ noResultMessage }}
      </div>
    </div>
  </div>

</fm-flyout-panel>
