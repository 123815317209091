/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HarshDrivingEventsActions, EHarshDrivingEventsActions } from '../actions';
import { initialHarshDrivingEventsState, IHarshDrivingEventsState } from '../state';

export function harshDrivingEventsReducer(
  state = initialHarshDrivingEventsState,
  action: HarshDrivingEventsActions
): IHarshDrivingEventsState {
  switch (action.type) {
    case EHarshDrivingEventsActions.GetHarshDrivingEvent:
      return {
        ...state,
        eventPage: action.payload.eventPage
      };

    case EHarshDrivingEventsActions.GetHarshDrivingEventSuccess:
      return {
        ...state,
        event: action.payload
      };

    default:
      return state;
  }
}
