/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { EVehicleSelectionMode } from '../../models';

export interface IVehicleListPanelState {
  scopingModal: {
    isOpen: boolean;
    warning: string;
  };
  selectionMode: EVehicleSelectionMode;
}

export const initialVehicleListPanelState: IVehicleListPanelState = {
  scopingModal: {
    isOpen: false,
    warning: null
  },
  selectionMode: EVehicleSelectionMode.Vehicles
};
