/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformServer } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, of, EMPTY } from 'rxjs';
import { take, filter, withLatestFrom, map, switchMap, timeout, catchError } from 'rxjs/operators';

import { isElementNull, ConfigService, isArrayNullOrEmpty } from '@fleetmatics/ui.utilities';

import {
  ShowStartingLocation,
  ELayoutActions,
  UpdateStreetViewLocation,
  UpdateZoomToMarker,
  EMapsActions,
  EUserMapSettingsActions,
  GetUserMapSettingsSuccess,
  GetMapBoundsFromUserPlots,
  GetMapBoundsFromUserPlotsSuccess,
  GetVehiclePlotsSuccess,
  EMapPlotsActions,
  EAssetsActions,
  GetAssetPlotsSuccess,
  SetSelectedMarker,
  EBalloonInfoActions,
  RemoveSelectedMarker,
  CloseBalloon,
  SetSelectedMarkerSuccess,
  HighlightMarker,
  HighlightMarkerSuccess
} from '../actions';
import { getIsStreetViewOpen, getUserSettings, getMapChangeBounds, getMarkersToHighlight } from '../selectors';
import { IAppState } from '../state';
import {
  IVehiclePlotResponse,
  EStartLocationSource,
  LatLng,
  IAssetPlot,
  IVehiclePlot,
  MarkerInfo,
} from '../../models';
import { PlotHttpService } from '../../services';
import { IAppConfig } from '../../../config';

@Injectable()
export class MapsEffects {
  private readonly _getMapBoundsFromUserPlotsTimeout: number;

  public getMapBoundsFromUserPlots$ = createEffect(() => this._actions$.pipe(
    ofType<GetMapBoundsFromUserPlots>(EMapsActions.GetMapBoundsFromUserPlots),
    switchMap(() => {
      let call$ = this._plotsHttpService.getVehiclePlotsForUser();
      if (isPlatformServer(this._platformId)) {
        call$ = call$.pipe(timeout(this._getMapBoundsFromUserPlotsTimeout));
      }
      return call$.pipe(
        take(1),
        catchError(() => of(null))
      );
    }),
    switchMap((res: IVehiclePlotResponse[] | null) => {
      if (isArrayNullOrEmpty(res)) {
        return EMPTY;
      } else {
        const latLngs = res.map(p => new LatLng(p.Coordinate.Latitude, p.Coordinate.Longitude));
        return [new GetMapBoundsFromUserPlotsSuccess(latLngs), new ShowStartingLocation(EStartLocationSource.FromUserPlots)];
      }
    })
  ));

  public onGetVehiclePlotsSuccessShowStartLocation$ = createEffect(() => this._actions$.pipe(
    ofType<GetVehiclePlotsSuccess | GetAssetPlotsSuccess>(EMapPlotsActions.GetVehiclePlotsSuccess, EAssetsActions.GetAssetPlotsSuccess),
    withLatestFrom(this._store.pipe(select(getMapChangeBounds))),
    filter(([, mapBounds]) => isElementNull(mapBounds)),
    map(([action]) => action.payload),
    filter(plots => plots.length > 0),
    map(plots =>
      this._isAssetPlots(plots)
        ? plots.map(p => new LatLng(p.center.Latitude, p.center.Longitude))
        : plots.map(p => new LatLng(p.coordinates.lat, p.coordinates.lng))
    ),
    switchMap(latLngs => {
      return [new GetMapBoundsFromUserPlotsSuccess(latLngs), new ShowStartingLocation(EStartLocationSource.FromUserPlots)];
    })
  ));

  public onGetUserMapSettingsSuccessShowStartLocation$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetUserMapSettingsSuccess>(EUserMapSettingsActions.GetUserMapSettingsSuccess),
    take(1),
    withLatestFrom(this._store.pipe(select(getUserSettings))),
    filter(([, userSettings]) => !isElementNull(userSettings.StartLocation)),
    map(() => new ShowStartingLocation(EStartLocationSource.FromUserSettings))
  ));

  public updateStreetViewLocationWhenZoomToMarker$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<UpdateZoomToMarker>(ELayoutActions.UpdateZoomToMarker),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(select(getIsStreetViewOpen))),
    filter(([, isStreetViewOpen]) => isStreetViewOpen),
    map(([params]) => new UpdateStreetViewLocation(params.latLng))
  ));

  public highlightMarker$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<HighlightMarker>(EMapsActions.HighlightMarker),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(select(getMarkersToHighlight))),
    map(([id, markers]: [number, MarkerInfo[]]) => markers.find(marker => marker.id === id)),
    filter(marker => !isElementNull(marker)),
    map((marker: MarkerInfo) => new HighlightMarkerSuccess(marker))
  ));

  public setSelectedMarker$ = createEffect(() => this._actions$.pipe(
    ofType<SetSelectedMarker>(EMapsActions.SetSelectedMarker),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(select(getMarkersToHighlight))),
    map(([id, markers]: [number, MarkerInfo[]]) => markers.find(marker => marker.id === id)),
    map((marker) => new SetSelectedMarkerSuccess(marker))
  ));

  public removeSelectedMarker$ = createEffect(() => this._actions$.pipe(
    ofType<CloseBalloon>(EBalloonInfoActions.CloseBalloon),
    map(() => new RemoveSelectedMarker())
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _plotsHttpService: PlotHttpService,
    configService: ConfigService<IAppConfig>
  ) {
    this._getMapBoundsFromUserPlotsTimeout = configService.config.ssrGetMapBounds.timeoutMs;
  }

  private _isAssetPlots(plots: IVehiclePlot[] | IAssetPlot[]): plots is IAssetPlot[] {
    return (plots[0] as IAssetPlot).center !== undefined;
  }
}
