/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAppConfig } from '../../../../../config/app-config.interfaces';
import { APP_CONFIG_TOKEN } from '../../../../../config/app-config.token';
import { INotificationsService } from './notifications-http.service.interface';
import { INotificationResponse, IApiResponseDto } from '../../models';

@Injectable()
export class NotificationsService implements INotificationsService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG_TOKEN) _config: IAppConfig) {
    this._rootUrl = _config.endpoints.notificationsApiEndpoint;
  }

  public updatePersistedNotification(notification: INotificationResponse): Observable<string> {
    return this._httpClient
      .post<IApiResponseDto<string>>(`${this._rootUrl}/Notification/UpdatePersistedNotification`, notification)
      .pipe(map(result => result.Data));
  }

  public reviewPanic(notification: INotificationResponse): Observable<string> {
    return this._httpClient
      .post<IApiResponseDto<string>>(`${this._rootUrl}/Notification/PanicReviewed`, notification)
      .pipe(map(result => result.Data));
  }

  public acknowledgePanic(notification: INotificationResponse): Observable<string> {
    return this._httpClient
      .post<IApiResponseDto<string>>(`${this._rootUrl}/Notification/PanicAcknowledged`, notification)
      .pipe(map(result => result.Data));
  }

  public getPersistedNotifications(): Observable<INotificationResponse[]> {
    return this._httpClient
      .get<IApiResponseDto<INotificationResponse[]>>(`${this._rootUrl}/Notification/GetPersistedNotifications`)
      .pipe(map(result => result.Data));
  }
}
