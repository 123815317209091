/*
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { DocumentRef, DocumentRefFactory, PlatformService } from '@fleetmatics/ui.base-library';

import { ModalContainerComponent } from './modal-container/modal-container.component';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalComponent } from './modal.component';
import { ModalService } from './service/modal.service';

@NgModule({
  imports: [CommonModule, MatIconModule],
  providers: [
    PlatformService,
    {
      provide: DocumentRef,
      useFactory: DocumentRefFactory.create,
      deps: [PlatformService]
    }
  ],
  declarations: [ModalComponent, ModalContainerComponent, ModalHeaderComponent, ModalContentComponent, ModalFooterComponent],
  exports: [ModalComponent, ModalContainerComponent, ModalHeaderComponent, ModalContentComponent, ModalFooterComponent],
  entryComponents: [ModalComponent, ModalHeaderComponent, ModalContentComponent]
})
export class ModalModule {
  public static forRoot(): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule,
      providers: [ModalService]
    };
  }
}
