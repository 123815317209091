/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IPlaceBalloonResponse } from '../../core/models';
import { DemoDtoMappingService, DemoDataResolverService } from '../services';
import { map } from 'rxjs/operators';

@Injectable()
export class PlacesHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly _demoDtoMappingService: DemoDtoMappingService,
    private readonly _demoDataResolver: DemoDataResolverService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.endsWith('/Place/LoadPlacesBalloon')) {
      const id = +req.params.get('request[PlotId]');

      return this._demoDataResolver.isReady$.pipe(
        map(() => {
          const geofencePlot = this._demoDataResolver.initialDemoGeofenceData.find(plot => plot.plot.id === id);
          const response: IPlaceBalloonResponse = this._demoDtoMappingService.geofencePlotToPlaceBalloonResponse(geofencePlot);
          return new HttpResponse<IPlaceBalloonResponse>({ body: response, status: 200 });
        })
      );
    }
    return next.handle(req);
  }
}
