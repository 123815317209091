/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { IHarshDrivingEventsState } from '../state';

export const getHarshDrivingEventsState = createFeatureSelector<IHarshDrivingEventsState>('harshDrivingEvent');

export const getHarshDrivingEvent = createSelector(
  getHarshDrivingEventsState,
  state => (!isElementNull(state) ? state.event : null)
);
export const getHarshDrivingEventPage = createSelector(
  getHarshDrivingEventsState,
  state => (!isElementNull(state) ? state.eventPage : null)
);
