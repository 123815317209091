/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IConfig } from '../../models';

export const enum EConfigActions {
  UpdateConfigs = '[Config] Update configs'
}

export class UpdateConfigs implements Action {
  public readonly type = EConfigActions.UpdateConfigs;
  constructor(public payload: IConfig) {}
}

export type ConfigActions = UpdateConfigs;
