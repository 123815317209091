/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EHarshDrivingType {
  None,
  Speeding,
  HardBrakingModerate,
  HardBrakingSevere,
  HardAccelerationModerate,
  HardAccelerationSevere,
  HardCorneringModerate,
  HardCorneringSevere
}
