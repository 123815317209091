/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take, filter, switchMapTo } from 'rxjs/operators';

import { isElementNull } from '@fleetmatics/ui.base-library';

import { getDemoVehiclePlots } from '../store';
import { IAppState } from '../../core/store/state';
import { IVehiclePlotResponse, EVehicleSelectionMode, IMapHierarchyResponse, IMapHierarchyItemResponse } from '../../core/models';
import { DemoDtoMappingService, DemoDataResolverService } from '../services';

@Injectable()
export class PlotHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly _store: Store<IAppState>,
    private readonly _demoDtoMappingService: DemoDtoMappingService,
    private readonly _demoDataResolver: DemoDataResolverService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.endsWith('/GetVehiclePlots/')) {
      return this._demoDataResolver.isReady$.pipe(switchMapTo(this._getSelectedVehiclePlots(req)));
    } else if (req.url.endsWith('/GetVehiclePlotsForUser')) {
      return this._demoDataResolver.isReady$.pipe(switchMapTo(this._getAllPlots()));
    } else if (req.url.endsWith('/GetAccessibleVehiclesCount/')) {
      return this._demoDataResolver.isReady$.pipe(switchMapTo(this._getAccessibleVehiclesCount()));
    } else if (req.url.endsWith('/Hierarchy/LiveMap')) {
      const selectionMode = <EVehicleSelectionMode>+req.params.get('request[Mode]');

      return this._demoDataResolver.isReady$.pipe(
        map(() =>
          this._demoDtoMappingService.demoHierarchyItemsToMapHierarchyItemResponse(
            this._demoDataResolver.initialDemoHierarchyData,
            selectionMode
          )
        ),
        map(
          (apiResponseDto: IMapHierarchyItemResponse[]) =>
            new HttpResponse({ body: { nodes: apiResponseDto } as IMapHierarchyResponse, status: 200 })
        )
      );
    }

    return next.handle(req);
  }

  private _getAccessibleVehiclesCount(): Observable<HttpResponse<number>> {
    return of(this._demoDataResolver.initialAppStateData.vehicle.accessibleVehiclesCount).pipe(
      map(
        accessibleVehiclesCount => new HttpResponse<number>({ body: accessibleVehiclesCount, status: 200 })
      )
    );
  }

  private _getAllPlots(): Observable<HttpResponse<IVehiclePlotResponse[]>> {
    return this._store.pipe(
      select(getDemoVehiclePlots),
      filter(plots => !isElementNull(plots)),
      take(1),
      map(plots => plots.map(plot => this._demoDtoMappingService.vehiclePlotToVehicleResponse(plot))),
      map(
        plots => new HttpResponse<IVehiclePlotResponse[]>({ body: plots, status: 200 })
      )
    );
  }

  private _getSelectedVehiclePlots(req: HttpRequest<any>): Observable<HttpResponse<IVehiclePlotResponse[]>> {
    const selectedIdsString = req.body.replace('SelectedEntityIds=', '').replace(/v/g, '').replace(/d/g, '');

    const selectedIds = selectedIdsString
      .split(',')
      .filter(Boolean)
      .map((id: string) => +id);

    return this._store.pipe(
      select(getDemoVehiclePlots),
      take(1),
      map(plots => (selectedIdsString.startsWith('g') ? plots : plots.filter(plot => selectedIds.some((id: number) => plot.id === id)))),
      map(plots => plots.map(this._demoDtoMappingService.vehiclePlotToVehicleResponse)),
      map(
        plots => new HttpResponse<IVehiclePlotResponse[]>({ body: plots, status: 200 })
      )
    );
  }
}
