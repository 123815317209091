/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FmWindowRefService } from '@fleetmatics/ui.utilities';

import { IDemoState } from '../../store';
import { getDemoVariationContent, getMarketingContent } from '../../store/demo.selectors';
import { IDemoVariationContent, IMarketingTextContent } from '../../models';

@Component({
  selector: 'fm-buy-now',
  templateUrl: './buy-now.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuyNowComponent implements OnInit {
  bannerContent$: Observable<IMarketingTextContent>;
  variationContent$: Observable<IDemoVariationContent>;
  isMobileBrowser: boolean;

  constructor(private readonly _store: Store<IDemoState>, private readonly _windowRefService: FmWindowRefService) {}

  ngOnInit(): void {
    this.bannerContent$ = this._store.pipe(select(getMarketingContent));
    this.variationContent$ = this._store.pipe(select(getDemoVariationContent));
    this.isMobileBrowser = this._isMobileBrowser();
  }

  private _isMobileBrowser(): boolean {
    const screen = this._windowRefService.nativeWindow.screen;
    return screen.width <= 480 || screen.height <= 480;
  }
}
