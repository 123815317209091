/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EDeviceStatusCode {
  towing = 0,
  ignitionEvent = 1,
  accelerometerEvent = 2,
  gpsStrength = 3,
  ptoSensor = 4,
  installationError = 5
}
