/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IAdvancedOptionsState } from '../state';
import { IPlaceCategory, IWorkOrderAdvancedOptions } from '../../models';

export const getAdvancedOptionsState = createFeatureSelector<IAdvancedOptionsState>('advancedOptions');

export const getPlaceCategories = createSelector(
  getAdvancedOptionsState,
  (state: IAdvancedOptionsState): IPlaceCategory[] => state.placeCategories
);

export const getPlaceCategoryIds = createSelector(
  getAdvancedOptionsState,
  (state: IAdvancedOptionsState): number[] => state.placeCategoryIds
);

export const getGarminStopStatusesIdsSelected = createSelector(
  getAdvancedOptionsState,
  (state: IAdvancedOptionsState): number[] => state.garminStopStatusesIdsSelected
);

export const getWorkOrderAdvancedOptions = createSelector(
  getAdvancedOptionsState,
  (state: IAdvancedOptionsState): IWorkOrderAdvancedOptions => state.workOrderAdvancedOptions
);
