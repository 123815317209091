/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAppConfig } from '../../../../../config/app-config.interfaces';
import { APP_CONFIG_TOKEN } from '../../../../../config/app-config.token';
import { IAlertService } from './alert-http.service.interface';
import { IApiResponseDto, INotificationDetailsResponse, INotificationDetailsParams } from '../../models';

@Injectable()
export class AlertService implements IAlertService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG_TOKEN) _config: IAppConfig) {
    this._rootUrl = _config.endpoints.alertApiEndpoint;
  }

  public getAlertLogInfo(parameters: INotificationDetailsParams): Observable<INotificationDetailsResponse> {
    let params = new HttpParams();
    params = params.append('request[AlertLogId]', parameters.id);
    params = params.append('request[DbId]', (parseInt(parameters.db.toString(), 10) + 100).toString());
    params = params.append('request[AlertType]', parameters.type.toString());

    return this._httpClient
      .get<IApiResponseDto<INotificationDetailsResponse>>(`${this._rootUrl}/Alert/GetAlertLogInfo`, { params })
      .pipe(map(response => response.Data));
  }
}
