/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IHarshDrivingEvent } from '../../models';

export interface IHarshDrivingEventsState {
  event: IHarshDrivingEvent;
  eventPage: string;
}

export const initialHarshDrivingEventsState: IHarshDrivingEventsState = null;
