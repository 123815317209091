/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { CommonModule as BaseLibraryCommonModule } from '@fleetmatics/ui.base-library';
import { TooltipModule } from 'shared';

import { ContextMenuComponent } from './context-menu.component';
import { ContextMenuItemComponent } from './context-menu-item/context-menu-item.component';
import { ContextMenuPanelComponent } from './context-menu-panel/context-menu-panel.component';

@NgModule({
  imports: [CommonModule, BaseLibraryCommonModule, MatIconModule, TooltipModule],
  declarations: [ContextMenuComponent, ContextMenuItemComponent, ContextMenuPanelComponent],
  exports: [ContextMenuComponent, ContextMenuItemComponent, ContextMenuPanelComponent]
})
export class ContextMenuModule {}
