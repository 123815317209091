/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'fm-context-menu-panel',
  templateUrl: './context-menu-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuPanelComponent {}
