/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { FmCookieService, isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import { ILegacyLayoutPreferencesService } from './legacy-layout-preferences.service.interface';
import { ILegacyMapPreferences, ILegacyTreeMapPreferences } from '../../models';

@Injectable()
export class LegacyLayoutPreferencesService implements ILegacyLayoutPreferencesService {
  private readonly _mapPreferencesCookieName = 'MapPreferences';
  private readonly _treeMapPreferencesCookieName = 'TreeSelection_Map';

  constructor(private readonly _cookieService: FmCookieService) { }

  public getMapPreferences(): ILegacyMapPreferences {
    const rawPreferences = this._cookieService.getCookie(this._mapPreferencesCookieName);
    if (!isStringNullOrEmpty(rawPreferences)) {
      return JSON.parse(rawPreferences);
    }
    return null;
  }

  public getTreeMapPreferences(): ILegacyTreeMapPreferences {
    const rawPreferences = this._cookieService.getCookie(this._treeMapPreferencesCookieName);
    if (!isStringNullOrEmpty(rawPreferences)) {
      return JSON.parse(rawPreferences);
    }
    return null;
  }
}
