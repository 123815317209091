/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fm-list-select-item',
  templateUrl: './list-select-item.component.html'
})
export class ListSelectItemComponent {
  @Input()
  displayText: string;
  @Input()
  displayValue: string;
  @Input()
  tooltip: string;
  @Input()
  isSelected: boolean;

  @Output()
  selectionChange = new EventEmitter<void>();

  changeSelected(): void {
    this.isSelected = !this.isSelected;
    this.selectionChange.emit();
  }
}
