/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { WorkOrderActions, EWorkOrderActions } from '../actions';
import { initialWorkOrderState, IWorkOrderState } from '../state';

export function workOrderReducer(state = initialWorkOrderState, action: WorkOrderActions): IWorkOrderState {
  switch (action.type) {
    case EWorkOrderActions.TriggerEditWorkOrderSuccess:
      return {
        ...state,
        isEditWorkOrderModalOpen: true,
        workOrderToEdit: action.payload
      };

    case EWorkOrderActions.SaveWorkOrder:
      return {
        ...state,
        hasError: false,
        isSaving: true
      };

    case EWorkOrderActions.CloseEditWorkOrderModal:
    case EWorkOrderActions.SaveWorkOrderSuccess:
      return {
        ...state,
        hasError: false,
        isEditWorkOrderModalOpen: false,
        isSaving: false,
        workOrderToEdit: null
      };

    case EWorkOrderActions.SaveWorkOrderError:
      return {
        ...state,
        hasError: true,
        isSaving: false
      };

    default:
      return state;
  }
}
