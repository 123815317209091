/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Directive, Input, ViewContainerRef } from '@angular/core';

import { ITranscludeInfo } from './transclude-info.interface';
import { ITranscludeView } from './transclude-view.interface';

@Directive({
  selector: '[fmTransclude]'
})
export class TranscludeDirective {
  @Input()
  public set fmTransclude(transcludeInfo: ITranscludeInfo) {
    if (transcludeInfo && transcludeInfo.template) {
      this._removeCurrentView();
      this._addView(transcludeInfo);
    }
  }

  currentViewId: string | number;
  public views = new Map<string | number, ITranscludeView>();
  public viewRef: ViewContainerRef;

  constructor(viewRef: ViewContainerRef) {
    this.viewRef = viewRef;
  }

  private _addView(transcludeInfo: ITranscludeInfo): void {
    const cachedView = this.views.get(transcludeInfo.id);
    if (cachedView) {
      this.viewRef.insert(cachedView.viewRef);
    } else {
      const viewRef = this.viewRef.createEmbeddedView(transcludeInfo.template, { $implicit: transcludeInfo.context });
      this.views.set(transcludeInfo.id, { viewRef, shouldDestroyOnDeactivation: transcludeInfo.shouldDestroyOnDeactivation });
    }
    this.currentViewId = transcludeInfo.id;
  }

  private _removeCurrentView(): void {
    const currentView = this.views.get(this.currentViewId);
    if (currentView) {
      if (currentView.shouldDestroyOnDeactivation) {
        currentView.viewRef.destroy();
        this.views.delete(this.currentViewId);
      } else {
        this.viewRef.detach();
      }
    }
  }
}
