/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class UsabillaService {
  isLoaded$: Observable<boolean>;
  isLoaded = new BehaviorSubject<boolean>(true);

  constructor() {
    this.isLoaded$ = this.isLoaded.asObservable();
  }

  setup(feature: number): void {}
}
