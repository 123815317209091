/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ECheckboxState } from '@fleetmatics/ui.base-library';

@Component({
  selector: 'fm-checkbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
  @Input() public defaultLabel: string;
  @Input()
  public set isChecked(isChecked: boolean) {
    this._isChecked = isChecked;
    this._state = isChecked ? ECheckboxState.Checked : ECheckboxState.Unchecked;
    this.isIndeterminate = false;
  }
  public get isChecked(): boolean {
    return this._isChecked;
  }
  @Input() isDisabled: boolean;

  @Input()
  public set state(state: ECheckboxState) {
    this._state = state;
    this._isChecked = this._state === ECheckboxState.Checked;
    this.isIndeterminate = this._state === ECheckboxState.Indeterminate;
  }
  public get state(): ECheckboxState {
    return this._state;
  }

  @Output() public changed: EventEmitter<boolean> = new EventEmitter();

  public isIndeterminate = false;

  private _isChecked = false;
  private _state: ECheckboxState = ECheckboxState.Unchecked;

  public onChange(): void {
    if (!this.isDisabled) {
      this.changed.emit(!this.isChecked);
    }
  }
}
