/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { isElementNull } from '@fleetmatics/ui.utilities';

import {
  IVehiclePlotResponse,
  ETrackableClassification,
  IUserSettings,
  IVehiclePlot,
  EPlotIcon,
  ESignalStatus,
  EVehicleStatusType,
  EPlotType,
  IVehiclePlotWebsubsResponse,
  IAccountSettings,
  EDisplayDriverAs,
  EDisplayVehicleAs
} from '../../models';
import { PlotFormattingService } from '../plot-formatting/plot-formatting.service';
import { IPlotMappingService } from './plot-mapping.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PlotMappingService implements IPlotMappingService {
  private readonly vehicleToPoweredAssetIconMapping = new Map<EPlotIcon, EPlotIcon>([
    [EPlotIcon.MovingN, EPlotIcon.MovingNPoweredAsset],
    [EPlotIcon.MovingNE, EPlotIcon.MovingNEPoweredAsset],
    [EPlotIcon.MovingE, EPlotIcon.MovingEPoweredAsset],
    [EPlotIcon.MovingSE, EPlotIcon.MovingSEPoweredAsset],
    [EPlotIcon.MovingS, EPlotIcon.MovingSPoweredAsset],
    [EPlotIcon.MovingSW, EPlotIcon.MovingSWPoweredAsset],
    [EPlotIcon.MovingW, EPlotIcon.MovingWPoweredAsset],
    [EPlotIcon.MovingNW, EPlotIcon.MovingNWPoweredAsset],
    [EPlotIcon.Idle, EPlotIcon.IdlePoweredAsset],
    [EPlotIcon.Stopped, EPlotIcon.StoppedPoweredAsset],
    [EPlotIcon.LostSignal, EPlotIcon.LostSignalPoweredAsset],
    [EPlotIcon.Towing, EPlotIcon.TowingPoweredAsset],
    [EPlotIcon.CustomerCare, EPlotIcon.CustomerCarePoweredAsset]
  ]);

  constructor(private readonly _plotFormattingService: PlotFormattingService) {}

  public mapVehiclePlotsResponseToStateModel(
    response: IVehiclePlotResponse[],
    searchLightVehicleIds: number[],
    classificationsFromHierachy: Map<string, ETrackableClassification>,
    userSettings: IUserSettings,
    accountSettings: IAccountSettings
  ): IVehiclePlot[] {
    let vehiclePlots: IVehiclePlot[] = [];
    response.forEach((vehicle: IVehiclePlotResponse) => {
      const isFromSearchLight = searchLightVehicleIds.includes(vehicle.id);
      vehiclePlots = vehiclePlots.concat(
        this.convertVehiclePlotModels([vehicle], isFromSearchLight, classificationsFromHierachy, userSettings, accountSettings)
      );
    });
    return vehiclePlots;
  }

  public convertVehiclePlotModels(
    vehiclePlots: (IVehiclePlotResponse | IVehiclePlotWebsubsResponse)[],
    isFromSearchLight = false,
    classificationsFromHierachy: Map<string, ETrackableClassification>,
    userSettings: IUserSettings,
    accountSettings: IAccountSettings
  ): IVehiclePlot[] {
    const now = Date.now();
    const mappedPlots = vehiclePlots.map(vehiclePlot => {
      return this._mapVehiclePlotResponseToModel(
        vehiclePlot,
        userSettings,
        accountSettings,
        isFromSearchLight,
        now,
        classificationsFromHierachy
      );
    });

    return mappedPlots;
  }

  private _mapVehiclePlotResponseToModel(
    vehiclePlot: IVehiclePlotResponse | IVehiclePlotWebsubsResponse,
    userSettings: IUserSettings,
    accountSettings: IAccountSettings,
    isFromSearchLight: boolean,
    batchTimestampTicks: number,
    classificationsFromHierachy: Map<string, ETrackableClassification>
  ) {
    // map the propperties of IVehiclePlotResponse
    const vehiclePlotModel: IVehiclePlot = this._mapVehiclePlotResponsetoModelBase(
      vehiclePlot,
      userSettings,
      isFromSearchLight,
      batchTimestampTicks,
      classificationsFromHierachy
    );

    const isIVehiclePlotWebsubsResponse = !isElementNull((vehiclePlot as IVehiclePlotWebsubsResponse).driverFirstName);
    if (isIVehiclePlotWebsubsResponse) {
      // map the properties that are not localized:
      this._mapAndLocalizeWebsubsOnlyFields(vehiclePlotModel, vehiclePlot as IVehiclePlotWebsubsResponse, userSettings, accountSettings);
    }

    return vehiclePlotModel;
  }

  private _mapAndLocalizeWebsubsOnlyFields(
    vehiclePlotModel: IVehiclePlot,
    vehiclePlot: IVehiclePlotWebsubsResponse,
    userSettings: IUserSettings,
    accountSettings: IAccountSettings
  ): void {
    // check if the message timestamp is from today, in the user local timezone
    vehiclePlotModel.lastUpdateIsToday = this._plotFormattingService.getLastUpdateIsToday(
      vehiclePlot.ticks,
      userSettings.TimeZoneAndroidId
    );

    // format the driver name based on user / account settings
    vehiclePlotModel.driverDisplayName = this._plotFormattingService.formatDriverName(
      vehiclePlot,
      // prefer user settings but default to account settings
      userSettings.DisplayDriverAs !== EDisplayDriverAs.NotSet ? userSettings.DisplayDriverAs : accountSettings.DisplayDriverAs
    );

    // format the vehicle name based on user / account settings
    vehiclePlotModel.vehicleName = this._plotFormattingService.formatVehicleName(
      vehiclePlot,
      // prefer user settings but default to account settings
      userSettings.DisplayVehicleAs !== EDisplayVehicleAs.NotSet ? userSettings.DisplayVehicleAs : accountSettings.DisplayVehicleAs
    );

    // format the speed based on user settings
    vehiclePlotModel.speed = this._plotFormattingService.formatSpeed(vehiclePlot.spd, userSettings.SpeedUnits);
  }

  private _mapVehiclePlotResponsetoModelBase(
    vehiclePlot: IVehiclePlotResponse | IVehiclePlotWebsubsResponse,
    userSettings: IUserSettings,
    isFromSearchLight: boolean,
    batchTimestampTicks: number,
    classificationsFromHierachy: Map<string, ETrackableClassification>
  ) {
    const vehiclePlotModel: IVehiclePlot = {
      id: vehiclePlot.id,
      coordinates: {
        lat: vehiclePlot.Coordinate.Latitude,
        lng: vehiclePlot.Coordinate.Longitude
      },
      geofenceName: vehiclePlot.GeofenceName,
      signalStatus: {
        iconClass: vehiclePlot.icncls === EPlotIcon.LostSignal ? ESignalStatus.Lost : ESignalStatus.Normal,
        lastUpdate: null
      },
      lastUpdateForDisplay: this._plotFormattingService.formatLastUpdateForDisplay(vehiclePlot.ticks, userSettings),
      lastUpdateIsToday: vehiclePlot.LastUpdateIsToday,
      status: vehiclePlot.Status as EVehicleStatusType,
      address: vehiclePlot.ads,
      addressId: vehiclePlot.adsId,
      direction: vehiclePlot.dir,
      driverId: vehiclePlot.DID,
      driverDisplayName: vehiclePlot.dnme,
      duration: vehiclePlot.dur >= 0 ? vehiclePlot.dur : 0,
      eventCode: vehiclePlot.evcd,
      group: vehiclePlot.grp,
      iconClass: vehiclePlot.icncls,
      offsensors: vehiclePlot.offsensors,
      onsensors: vehiclePlot.onsensors,
      speed: vehiclePlot.spd,
      speedViolation: {
        inrixSpeedLimitType: vehiclePlot.spdvio ? vehiclePlot.spdvio.InrixSpeedLimitType : null,
        violationSpeed: vehiclePlot.spdvio ? vehiclePlot.spdvio.ViolationSpeed : null
      },
      ticks: vehiclePlot.ticks,
      time: vehiclePlot.tme,
      timeUnits: vehiclePlot.tmeu,
      vehicleName: vehiclePlot.vnme,
      vehicleSpeedlimit: vehiclePlot.vspdlmt,
      vehicleSpeedlimitType: vehiclePlot.vspdlmttype,
      hasNavigationDevice: vehiclePlot.HasNavigationDevice,
      isFromSearchLight: isFromSearchLight,
      isUpdating: false,
      batchTimestampTicks: batchTimestampTicks,
      type: EPlotType.vehicle
    };

    if (vehiclePlot.TrackableClassification === ETrackableClassification.NotFound) {
      // If value is missing, use hierarchy value
      const hierarchyValue = classificationsFromHierachy.get(`v${vehiclePlot.id}`);
      vehiclePlot.TrackableClassification = hierarchyValue ?? ETrackableClassification.Vehicle;
    }

    vehiclePlotModel.iconClass = this._getIconClass(vehiclePlot);
    vehiclePlotModel.type = this._convertTrackableClassificationToPlotType(vehiclePlot.TrackableClassification);
    return vehiclePlotModel;
  }

  private _getIconClass(vehiclePlot: IVehiclePlotResponse): EPlotIcon {
    if (
      !isElementNull(vehiclePlot.TrackableClassification) &&
      vehiclePlot.TrackableClassification === ETrackableClassification.PoweredAsset
    ) {
      if (this.vehicleToPoweredAssetIconMapping.has(vehiclePlot.icncls)) {
        return this.vehicleToPoweredAssetIconMapping.get(vehiclePlot.icncls);
      }
    }
    return vehiclePlot.icncls;
  }

  private _convertTrackableClassificationToPlotType(trackableClassification: ETrackableClassification): EPlotType {
    return trackableClassification === ETrackableClassification.PoweredAsset ? EPlotType.poweredAsset : EPlotType.vehicle;
  }
}
