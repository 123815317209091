/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ETrackingDeviceEventCode {
  /// <summary>
  /// High speed related events
  /// </summary>
  HighSpeedStart = 70,
  HighSpeed = 71,
  HighSpeedEnd = 72,

  /// <summary>
  /// Harsh driving events
  /// </summary>
  HardAcceleration = 153,
  HardCornering = 154,
  HardBraking = 155,
  /// <summary>
  /// Journey related events
  /// </summary>
  StartUP = 122,
  Position = 1,
  IdlingStart = 143,
  IdlingEnd = 145,
  ShutDown = 123
}
