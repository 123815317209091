/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ENotificationSettingsTypes {
  highPriorityAlerts = 1,
  standardAlerts = 2,
  garminMessages = 3,
  fleetServiceOverdue = 4,
  fleetServiceReminder = 5,
  panicAlerts = 6,
  routeOptimizationStatus = 7,
  exceededAlertLimit = 8
}
