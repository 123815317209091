/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  Component,
  HostBinding,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'fm-modal-container',
  templateUrl: './modal-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalContainerComponent {
  @Input()
  @HostBinding('style.height')
  height: string;

  @HostBinding('class.modal-container')
  hostClass = true;
}
