<div id="splash-screen">
  <ng-template #splashModalContent>
    <div id="splash-modal">
      <div class="left-panel">
        <div class="vzc-logo"></div>
        <h1 class="welcome-to-reveal"
          i18n="@@S_DEMO_WELCOME">Welcome to Reveal Starter</h1>
        <p class="take-the-guided-tour"
          i18n="@@S_DEMO_GUIDE">Take the guided tour or click around to find out how Reveal Starter can help you manage your fleet.</p>
        <div class="splash-actions">
          <button id="guided-tour"
            a7s-id="demo-splash-screen-take-a-guided-tour"
            (click)="showGuide(EDemoStories.FleetBasics)"
            i18n="@@S_DEMO_TOUR">Take a guided tour</button>
          <button id="skip-tour"
            a7s-id="demo-splash-screen-skip-the-tour"
            (click)="closeModal()"
            i18n="@@S_DEMO_SKIP">Skip the tour</button>
        </div>
      </div>
      <div class="right-panel">
        <div class="top-info">
          <h2 class="understand-challenges"
            i18n="@@S_DEMO_BUSINESS">See how we can help your business</h2>
          <div class="guide-navigation">
            <a a7s-id="demo-splash-screen-arrows"
              class="previous-guide carousel-button"
              data-action="slideLeft">
            </a>
            <a a7s-id="demo-splash-screen-arrows"
              class="next-guide carousel-button"
              data-action="slideRight">
            </a>
          </div>
        </div>
        <div class="guides-container">
          <fm-carousel>
            <div class="guides">
              <div a7s-id="demo-splash-screen-fleet-tracking-learn-more"
                class="guide carousel-card"
                data-target="carousel-card"
                (click)="showGuide(EDemoStories.FleetBasics)">
                <div class="guide-icon basics"></div>
                <div class="guide-title"
                  i18n="@@S_DEMO_FLEET_HEADER">Fleet tracking basics</div>
                <div class="guide-text"
                  i18n="@@S_DEMO_FLEET_DESC">See where your vehicles are and what they are doing all day, everyday.</div>
                <div class="guide-learn-more"
                  i18n="@@S_DEMO_LEARN">Learn more</div>
              </div>
              <div a7s-id="demo-splash-screen-fsd-learn-more"
                class="guide carousel-card"
                data-target="carousel-card"
                (click)="showGuide(EDemoStories.FieldServiceDispatch)"
                *ngIf="(demoVariationContent$ | async)?.currentVariation !== 'b'">
                <div class="guide-icon fsd"></div>
                <div class="guide-title"
                  i18n="@@S_DEMO_MANAGE_HEADER">Manage your mobile workforce</div>
                <div class="guide-text"
                  i18n="@@S_DEMO_MANAGE_DESC">Organize your technicians and see their job progress in near real time.</div>
                <div class="guide-learn-more"
                  i18n="@@S_DEMO_LEARN">Learn more</div>
              </div>
              <div a7s-id="demo-splash-screen-cost-n-efficiency-learn-more"
                class="guide carousel-card"
                data-target="carousel-card"
                (click)="showGuide(EDemoStories.CostAndEfficiency)">
                <div class="guide-icon cost"></div>
                <div class="guide-title"
                  i18n="@@S_DEMO_COST_HEADER">Cost & efficiency</div>
                <div class="guide-text"
                  i18n="@@S_DEMO_COST_DESC">Run your fleet efficiently and economically.</div>
                <div class="guide-learn-more"
                  i18n="@@S_DEMO_LEARN">Learn more</div>
              </div>
              <div a7s-id="demo-splash-screen-fleet-safety-learn-more"
                class="guide carousel-card"
                data-target="carousel-card"
                (click)="showGuide(EDemoStories.Safety)">
                <div class="guide-icon safety"></div>
                <div class="guide-title"
                  i18n="@@S_DEMO_SAFETY_HEADER">Fleet safety</div>
                <div class="guide-text"
                  i18n="@@S_DEMO_SAFETY_DESC">Keep your fleet safe while at work and save money by helping to reduce incidents.</div>
                <div class="guide-learn-more"
                  i18n="@@S_DEMO_LEARN">Learn more</div>
              </div>
              <div a7s-id="demo-splash-screen-mobile-on-the-go-learn-more"
                class="guide carousel-card"
                data-target="carousel-card"
                (click)="showGuide(EDemoStories.OnTheGoMap)">
                <div class="guide-icon mobile"></div>
                <div class="guide-title"
                  i18n="@@S_DEMO_GO_HEADER">On the go</div>
                <div class="guide-text"
                  i18n="@@S_DEMO_GO_DESC">Run your fleet from your phone or tablet as smoothly as you do from the office.</div>
                <div class="guide-learn-more"
                  i18n="@@S_DEMO_LEARN">Learn more</div>
              </div>
            </div>
          </fm-carousel>
        </div>
      </div>
    </div>
  </ng-template>
</div>
