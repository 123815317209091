/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import {
  IVehiclePlot,
  IHotspotPlot,
  IGeofencePlot,
  IGarminStopPlot,
  IWorkOrderPlot,
  IIndexableObject,
  ITowingVehiclePlotIcon
} from '../../models';

export const enum EMapPlotsActions {
  EmitTimeTick = '[Plots] Emit Time Tick',
  GetVehiclePlots = '[Plots] Get vehicle plots',
  GetVehiclePlotsForUser = '[Plots] Get vehicle plots for user',
  GetVehiclePlotsSuccess = '[Plots] Get vehicle plots success',
  GetHotspotPlots = '[Plots] Get hotspot plots',
  GetHotspotPlotsSuccess = '[Plots] Get hotspot plots success',
  GetGeofencePlots = '[Plots] Get geofence plots',
  GetGeofencePlotsSuccess = '[Plots] Get geofence plots success',
  StartGarminStopsPolling = '[Plots] Start Garmin stops polling',
  GetGarminStopsPlots = '[Plots] Get garmin stops plots',
  GetGarminStopsPlotsSuccess = '[Plots] Get garmin stops plots success',
  GetWorkOrdersPlots = '[Plots] Get work orders plots',
  GetWorkOrdersPlotsSuccess = '[Plots] Get work orders plots success',
  RetrieveWorkOrderPlots = '[Plots] Retrieve work orders plots',
  RemoveFromMap = '[Plots] Remove From Map',
  ResetVehiclePlotTimer = '[Plots] Reset Vehicle Plot Timer',
  UpdateVehiclePlotsTimers = '[Plots] Update Vehicle Plots Timers',
  UpdateTowingVehiclePlotIcons = '[Plots] Update Towing Vehicle Plot Icons',
  UpdateVehiclePlotsIconsSuccess = '[Plots] Update Vehicle Plots Icons Success'
}

export class EmitTimeTick implements Action {
  public readonly type = EMapPlotsActions.EmitTimeTick;

  constructor(public payload: Date) {}
}

export class GetHotspotPlots implements Action {
  public readonly type = EMapPlotsActions.GetHotspotPlots;
}

export class GetHotspotPlotsSuccess implements Action {
  public readonly type = EMapPlotsActions.GetHotspotPlotsSuccess;

  constructor(public payload: IHotspotPlot[]) {}
}

export class GetGeofencePlots implements Action {
  public readonly type = EMapPlotsActions.GetGeofencePlots;
}

export class GetGeofencePlotsSuccess implements Action {
  public readonly type = EMapPlotsActions.GetGeofencePlotsSuccess;

  constructor(public payload: IGeofencePlot[]) {}
}

export class StartGarminStopsPolling implements Action {
  public readonly type = EMapPlotsActions.StartGarminStopsPolling;
}

export class GetGarminStopsPlots implements Action {
  public readonly type = EMapPlotsActions.GetGarminStopsPlots;
}

export class GetGarminStopsPlotsSuccess implements Action {
  public readonly type = EMapPlotsActions.GetGarminStopsPlotsSuccess;

  constructor(public payload: IGarminStopPlot[]) {}
}

export class GetVehiclePlots implements Action {
  public readonly type = EMapPlotsActions.GetVehiclePlots;
}

export class GetVehiclePlotsForUser implements Action {
  public readonly type = EMapPlotsActions.GetVehiclePlotsForUser;
}

export class GetVehiclePlotsSuccess implements Action {
  public readonly type = EMapPlotsActions.GetVehiclePlotsSuccess;

  constructor(public payload: IVehiclePlot[]) {}
}

export class GetWorkOrdersPlots implements Action {
  public readonly type = EMapPlotsActions.GetWorkOrdersPlots;
}

export class GetWorkOrdersPlotsSuccess implements Action {
  public readonly type = EMapPlotsActions.GetWorkOrdersPlotsSuccess;

  constructor(public payload: IWorkOrderPlot[]) {}
}

export class RetrieveWorkOrderPlots implements Action {
  public readonly type = EMapPlotsActions.RetrieveWorkOrderPlots;
}

export class RemoveFromMap implements Action {
  public readonly type = EMapPlotsActions.RemoveFromMap;

  constructor(public payload: number) {}
}

export class ResetVehiclePlotTimer implements Action {
  public readonly type = EMapPlotsActions.ResetVehiclePlotTimer;

  constructor(public payload: IVehiclePlot[]) {}
}

export class UpdateVehiclePlotsTimers implements Action {
  public readonly type = EMapPlotsActions.UpdateVehiclePlotsTimers;

  constructor(public payload: IIndexableObject<number>) {}
}

export class UpdateTowingVehiclePlotIcons implements Action {
  public readonly type = EMapPlotsActions.UpdateTowingVehiclePlotIcons;

  constructor(public payload: ITowingVehiclePlotIcon[]) {}
}

export class UpdateVehiclePlotsIconsSuccess implements Action {
  public readonly type = EMapPlotsActions.UpdateVehiclePlotsIconsSuccess;

  constructor(public payload: IVehiclePlot[]) {}
}

export type MapPlotsActions =
  | EmitTimeTick
  | GetVehiclePlots
  | GetVehiclePlotsForUser
  | GetVehiclePlotsSuccess
  | GetHotspotPlots
  | GetHotspotPlotsSuccess
  | GetGeofencePlots
  | GetGeofencePlotsSuccess
  | StartGarminStopsPolling
  | GetGarminStopsPlots
  | GetGarminStopsPlotsSuccess
  | GetWorkOrdersPlots
  | GetWorkOrdersPlotsSuccess
  | RetrieveWorkOrderPlots
  | RemoveFromMap
  | ResetVehiclePlotTimer
  | UpdateVehiclePlotsTimers
  | UpdateTowingVehiclePlotIcons
  | UpdateVehiclePlotsIconsSuccess;
