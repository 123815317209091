/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap, withLatestFrom, filter, skipWhile, take, catchError } from 'rxjs/operators';

import { ECheckboxState } from '@fleetmatics/ui.base-library';
import { isElementNull } from '@fleetmatics/ui.utilities';

import {
  ECustomerMetadataActions,
  GetMapHierarchy,
  GetMapHierarchyScoping,
  GetMapHierarchyScopingSuccess,
  UpdateVehicleAsSelected,
  UpdateVehicleAsSelectedSuccess,
  UpdateVehicleSelectionMode,
  GetLayoutPreferencesSuccess,
  ELayoutPreferencesActions,
  ELayoutActions,
  UpdateVehicleAsDeselected,
  UpdateVehicleAsDeselectedSuccess,
  SaveMapHierarchyScoping,
  SaveMapHierarchyScopingSuccess,
  StoreMapHierarchyScoping,
  SaveMapHierarchyScopingWarning,
  CloseScopingModal,
  UpdateVehiclesSelected,
  UpdateVehiclesSelectedSuccess,
  GetMapHierarchySuccess,
  UpdateAssetSelection,
  GetVehiclePlots,
  GetGarminStopsPlots,
  GetUserSpeedingThresholdSetting,
  GetUserSpeedingThresholdSettingSuccess,
  UpdateMapHierarchyOptions,
  EVehicleListPanelActions
} from '../actions';
import {
  getVehicleSelectionMode,
  getMapHierarchyScoping,
  getMapHierarchyInfo,
  getScopingEnabled,
  getUserFeatures,
  getRefreshPlots,
  getSpeedUnits,
  getVehiclePlots
} from '../selectors';
import { IAppState, ILayoutPreferencesState } from '../state';
import {
  EVehicleSelectionMode,
  ENodeType,
  IMapHierarchyResponse,
  IMapHierarchyItem,
  IMapHierarchyItemResponse,
  IUserFeatures,
  ESpeedUnits,
  ISpeedMetricResponse,
  IMapHierarchyInfo,
  MapHierarchyInfo
} from '../../models';
import { CustomerMetadataHttpService, PlotHttpService } from '../../services';
import { delayedRetry } from '../../operators';
import { SpeedConversionUtilities } from '../../../../utils';

@Injectable()
export class CustomerMetadataEffects {
  public initializeMapHierarchy$ = createEffect(() =>
    of(isPlatformBrowser(this._platformId)).pipe(
      filter(isBrowser => isBrowser),
      switchMap(() =>
        combineLatest([
          this._store.pipe(select(getScopingEnabled)).pipe(
            filter(scopingEnabled => !isElementNull(scopingEnabled as null)),
            take(1)
          ),
          this._actions$.pipe(
            ofType<GetLayoutPreferencesSuccess>(ELayoutPreferencesActions.GetLayoutPreferencesSuccess),
            map(action => action.payload),
            switchMap(layoutPreferences =>
              this._store.pipe(
                select(getUserFeatures),
                filter(userFeatures => !isElementNull(userFeatures)),
                take(1),
                map(userFeatures => <[ILayoutPreferencesState, IUserFeatures]>[layoutPreferences, userFeatures])
              )
            )
          )
        ])
      ),
      withLatestFrom(this._store.pipe(select(getVehiclePlots))),
      switchMap(([[isScopingEnabled, [layoutPreferences, userFeatures]], vehiclePlots]) => {
        const actions: Action[] = [];

        const selectionMode = layoutPreferences.vehicleListPanel.selectionMode;
        if (this._isSelectionModeNotAllowed(selectionMode, userFeatures)) {
          actions.push(new UpdateVehicleSelectionMode(EVehicleSelectionMode.Vehicles));
        }

        actions.push(new GetMapHierarchy({ refreshPlots: true && vehiclePlots.length > 0 }));

        if (isScopingEnabled) {
          actions.push(new GetMapHierarchyScoping());
        }

        return actions;
      })
    )
  );

  public updateVehicleSelectionMode$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicleSelectionMode>(EVehicleListPanelActions.UpdateVehicleSelectionMode),
      map(
        () =>
          // if scoping was changed, refresh plos might be set to true.
          // we don't need to refresh plots when changing selection mode
          // so we can update the refreshPlots to false
          new UpdateMapHierarchyOptions({ refreshPlots: false })
      )
    )
  );

  public updateScopingSelection$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<StoreMapHierarchyScoping>(ECustomerMetadataActions.StoreMapHierarchyScoping),
      map(() => new GetMapHierarchy({ refreshPlots: true }))
    )
  );

  public refreshPlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetMapHierarchySuccess>(ECustomerMetadataActions.GetMapHierarchySuccess),
      withLatestFrom(this._store.pipe(select(getRefreshPlots))),
      filter(([, refreshPlots]) => refreshPlots),
      map(() => new GetVehiclePlots())
    )
  );

  public getMapHierarchy$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<GetMapHierarchy>(ECustomerMetadataActions.GetMapHierarchy),
        filter(() => isPlatformBrowser(this._platformId)),
        switchMap(
          (): Observable<boolean> =>
            this._store.pipe(select(getScopingEnabled)).pipe(
              skipWhile((scopingEnabled: null | boolean) => isElementNull(scopingEnabled as null)),
              map((scopingEnabled): boolean => scopingEnabled)
            )
        ),
        withLatestFrom(this._store.pipe(select(getScopingEnabled))),
        switchMap(([, scopingEnabled]) =>
          this._plotHttpService.getMapHierarchy(EVehicleSelectionMode.Groups, scopingEnabled).pipe(
            delayedRetry(),
            catchError(() => of(<IMapHierarchyResponse>null))
          )
        ),
        filter(mapHierarchyResponse => !isElementNull(mapHierarchyResponse)),
        map(mapHierarchyResponse => {
          const groupHierarchy = this._convertMapHierarchyResponseToModel(mapHierarchyResponse.nodes);
          return {
            isNewMapHierarchy: true,
            mapHierarchy: groupHierarchy
          } as IMapHierarchyInfo;
        }),
        switchMap((mapHierarchyInfo: IMapHierarchyInfo) => [
          new GetMapHierarchySuccess(mapHierarchyInfo),
          new GetUserSpeedingThresholdSetting(this._getFirstNormalizedGroupId(mapHierarchyInfo.mapHierarchy))
        ])
      ) as Observable<GetMapHierarchySuccess | GetUserSpeedingThresholdSetting>
  );

  public getMapHierarchyScoping$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetMapHierarchyScoping>(ECustomerMetadataActions.GetMapHierarchyScoping),
      filter(() => isPlatformBrowser(this._platformId)),
      switchMap(() =>
        this._plotHttpService.getMapHierarchyScoping().pipe(
          delayedRetry(),
          catchError(() => of(<IMapHierarchyResponse>null))
        )
      ),
      filter(mapHierarchyScopingResponse => !isElementNull(mapHierarchyScopingResponse)),
      map(({ nodes: mapHierarchyScoping }) => this._convertMapHierarchyResponseToModel(mapHierarchyScoping)),
      map(mapHierarchyScoping => new GetMapHierarchyScopingSuccess(mapHierarchyScoping))
    )
  );

  public saveMapHierarchyScoping$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveMapHierarchyScoping>(ECustomerMetadataActions.SaveMapHierarchyScoping),
      map((action: SaveMapHierarchyScoping) => action.payload),
      switchMap((selectedGroups: string[]) =>
        this._customerMetadataHttpService.saveMapHierarchyScoping(selectedGroups).pipe(
          delayedRetry(),
          map(result => [result, selectedGroups]),
          catchError(() => of(null).pipe(filter(() => false)))
        )
      ),
      switchMap(([warningMessage, selectedGroups]: [string, string[]]): Observable<Action> | Action[] => {
        return [
          new SaveMapHierarchyScopingSuccess(selectedGroups),
          warningMessage === '' ? new CloseScopingModal() : new SaveMapHierarchyScopingWarning(warningMessage)
        ];
      })
    )
  );

  public saveMapHierarchyScopingSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveMapHierarchyScopingSuccess>(ECustomerMetadataActions.SaveMapHierarchyScopingSuccess),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getMapHierarchyScoping))),
      map(([selectedIds, mapHierarchyScoping]) => this._updateScopingSelectedNodes(mapHierarchyScoping, selectedIds)),
      map(mapHierarchyScoping => new StoreMapHierarchyScoping(mapHierarchyScoping))
    )
  );

  public updateVehiclesSelected$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehiclesSelected>(ECustomerMetadataActions.UpdateVehiclesSelected),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getMapHierarchyInfo)), this._store.pipe(select(getVehicleSelectionMode))),
      map(
        ([selectedIds, mapHierarchyInfo, vehicleSelectionMode]) =>
          [
            new MapHierarchyInfo(mapHierarchyInfo).updateMapHierarchySelectedNodes(selectedIds, vehicleSelectionMode),
            vehicleSelectionMode
          ] as [IMapHierarchyInfo, EVehicleSelectionMode]
      ),
      withLatestFrom(this._store.pipe(select(getUserFeatures))),
      switchMap(([[mapHierarchyInfo, vehicleSelectionMode], userFeatures]) => {
        const actions: Action[] = [new UpdateVehiclesSelectedSuccess({ ...mapHierarchyInfo, isNewMapHierarchy: false })];

        if (vehicleSelectionMode === EVehicleSelectionMode.Assets) {
          const selectedAssets = this._getSelectedAssets(mapHierarchyInfo.mapHierarchy);
          actions.push(new UpdateAssetSelection(selectedAssets));
        } else {
          actions.push(new GetVehiclePlots(), new GetGarminStopsPlots());

          if (
            userFeatures.assets &&
            (vehicleSelectionMode === EVehicleSelectionMode.Groups || vehicleSelectionMode === EVehicleSelectionMode.EntireFleet)
          ) {
            const selectedAssets = this._getSelectedAssets(mapHierarchyInfo.mapHierarchy);
            actions.push(new UpdateAssetSelection(selectedAssets));
          }
        }

        return actions;
      })
    )
  );

  public updateVehicleAsSelected$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicleAsSelected>(ECustomerMetadataActions.UpdateVehicleAsSelected),
      map(action => action.payload.selectedVehicle),
      withLatestFrom(this._store.pipe(select(getMapHierarchyInfo))),
      map(([vehicleToSelect, mapHierarchyInfo]) =>
        new MapHierarchyInfo(mapHierarchyInfo).updateMapHierarchySelectedNode(vehicleToSelect, ECheckboxState.Checked)
      ),
      map(mapHierarchyInfo => new UpdateVehicleAsSelectedSuccess(mapHierarchyInfo))
    )
  );

  public updateVehicleAsDeselected$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicleAsDeselected>(ELayoutActions.UpdateVehicleAsDeselected),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getMapHierarchyInfo))),
      map(([vehicleToSelect, mapHierarchyInfo]) =>
        new MapHierarchyInfo(mapHierarchyInfo).updateMapHierarchySelectedNode(vehicleToSelect, ECheckboxState.Unchecked)
      ),
      map(mapHierarchyInfo => new UpdateVehicleAsDeselectedSuccess(mapHierarchyInfo))
    )
  );

  public getUserSpeedingThresholdSetting$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetUserSpeedingThresholdSetting>(ECustomerMetadataActions.GetUserSpeedingThresholdSetting),
      map(action => action.payload),
      switchMap(entireFleetGroupId =>
        this._customerMetadataHttpService.getSpeedMetric(entireFleetGroupId).pipe(
          delayedRetry(),
          catchError(() => of(<ISpeedMetricResponse>null))
        )
      ),
      filter(speedMetricResponse => !isElementNull(speedMetricResponse)),
      withLatestFrom(this._store.pipe(select(getSpeedUnits))),
      map(([speedMetricResponse, speedUnits]: [ISpeedMetricResponse, ESpeedUnits]) => {
        const speedThresholdKmh = SpeedConversionUtilities.Convert(
          speedMetricResponse.RoadSpecificSpeedLimit,
          speedUnits,
          ESpeedUnits.KilometersPerHour
        );
        return new GetUserSpeedingThresholdSettingSuccess(speedThresholdKmh);
      })
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _customerMetadataHttpService: CustomerMetadataHttpService,
    private readonly _plotHttpService: PlotHttpService
  ) {}

  private _isSelectionModeNotAllowed(selectionMode: EVehicleSelectionMode, userFeatures: IUserFeatures): boolean {
    const isNonPoweredAssetModeNotAllowed = selectionMode === EVehicleSelectionMode.Assets && !userFeatures.assets;
    const isPoweredAssetModeNotAllowed = selectionMode === EVehicleSelectionMode.PoweredAssets;
    const isEntireFleetModeNotAllowed = selectionMode === EVehicleSelectionMode.EntireFleet;

    return isNonPoweredAssetModeNotAllowed || isPoweredAssetModeNotAllowed || isEntireFleetModeNotAllowed;
  }

  private _getFirstNormalizedGroupId(mapHierarchy: IMapHierarchyItem[]): string {
    return mapHierarchy.find(mapHierarchyItem => mapHierarchyItem.type === ENodeType.Group).id.substr(1);
  }

  private _getSelectedAssets(mapHierarchy: IMapHierarchyItem[]): string[] {
    const assetIds: string[] = [];
    for (let i = 0; i < mapHierarchy.length; i++) {
      const item = mapHierarchy[i];
      if (item.type === ENodeType.Asset && item.state === ECheckboxState.Checked) {
        assetIds.push(item.id);
      }
    }

    return assetIds;
  }

  private _convertMapHierarchyResponseToModel(items: IMapHierarchyItemResponse[]): IMapHierarchyItem[] {
    const result: IMapHierarchyItem[] = items.map(item => {
      return {
        id: item.id,
        isExpandable: item.canExpand,
        level: item.level,
        name: item.name,
        state: item.checked ? ECheckboxState.Checked : ECheckboxState.Unchecked,
        type: item.type,
        fuelType: item.type === ENodeType.Vehicle ? item.fuelType : undefined,
        driverId: item.driverId
      };
    });

    return result;
  }

  private _updateScopingSelectedNodes(mapHierarchy: IMapHierarchyItem[], selectedIds: string[]): IMapHierarchyItem[] {
    for (let i = 0; i < mapHierarchy.length; i++) {
      const item = mapHierarchy[i];
      const isItemSelected = selectedIds.includes(item.id);
      if (isItemSelected) {
        item.state = ECheckboxState.Checked;
      } else {
        item.state = ECheckboxState.Unchecked;
      }
    }

    return mapHierarchy;
  }
}
