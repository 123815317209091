/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
  Renderer2,
  Inject,
  RendererFactory2
} from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';

import { isArrayNullOrEmpty } from '@fleetmatics/ui.utilities';

import { DemoConstants } from '../constants';
import { initialNavbarStateData } from '../data';
import { IDemoNavBarMenuItem } from '../models';
import { MobileNavTitleComponent } from '../components/mobile-nav-title/mobile-nav-title.component';
import { MobileNavCardComponent } from '../components/mobile-nav-card/mobile-nav-card.component';

@Injectable()
export class DemoDomService {
  renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector,
    rendererFactory: RendererFactory2,
    private readonly _location: Location
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public appendComponentToBody(component: any): void {
    const domElem = this._createComponentElement(component);
    this.renderer.insertBefore(this.document.body, domElem, this.document.body.firstChild);
  }

  public replaceChildWithComponent(parent: HTMLElement, existingChild: HTMLElement, component: any): void {
    const domElem = this._createComponentElement(component);
    this.renderer.insertBefore(parent, domElem, existingChild);
    this.renderer.removeChild(parent, existingChild);
  }

  public appendComponentToElement(component: any, element: HTMLElement): void {
    const domElem = this._createComponentElement(component);
    this.renderer.appendChild(element, domElem);
  }

  public replaceComponent(component: any): void {
    const selector = this.componentFactoryResolver.resolveComponentFactory(component).selector;
    const element = this.document.getElementsByTagName(selector)[0];
    if (element) {
      const parent = element.parentElement;
      this.renderer.removeChild(parent, element);
      const domElem = this._createComponentElement(component);
      this.renderer.insertBefore(parent, domElem, parent.firstChild);
    }
  }

  public toggleBuyNowOnBodyElement(): void {
    this.document.body.classList.add('buy-now-enabled');
  }

  public elementExists(id: string): boolean {
    return this.document.getElementById(id) !== null;
  }

  public addSchedulerAddOnBadge(): void {
    const timer = setInterval(() => {
      let navbarSchedulerLink: Element;
      const navbarMenuItems = document.getElementsByClassName('fm-navbar-menu-item');

      if (navbarMenuItems.length > 0) {
        for (let i = 0; i < navbarMenuItems.length; i++) {
          const navbarItem = initialNavbarStateData.menuItems.items[i] as IDemoNavBarMenuItem;
          if (navbarItem.addOn) {
            navbarSchedulerLink = navbarMenuItems[i];
          }
        }
      }

      if (navbarSchedulerLink) {
        const navbarSchedulerLinkIcons = navbarSchedulerLink.getElementsByClassName('fm-navbar-menu-item__icon');
        if (navbarSchedulerLinkIcons.length > 0) {
          const badgeElement = this._createAddOnBadge();
          navbarSchedulerLink.insertBefore(badgeElement, navbarSchedulerLinkIcons[0].nextSibling);
          clearInterval(timer);
        }
      }
    }, 50);
  }

  public addPendoGuideTriggerElement(id: string): void {
    const pendoGuideElement = this.document.getElementsByClassName('pendo-guide')[0];
    pendoGuideElement.id = id;
  }

  public modifySideNavMenuItems(language: string): void {
    const openButton = this.document.getElementsByClassName('fm-navbar__sidenav-open')[0];
    openButton.addEventListener('click', () => {
      this._removeLogoutComponent();
      const sideBarMenu = this.document.getElementsByClassName('fm-sidenav-menu')[0] as HTMLElement;
      const parentNavs = Array.from(sideBarMenu.querySelectorAll('a.nav-item'));
      const navTitleParent = this.document.querySelector('div.fm-sidenav') as HTMLElement;
      const navChildToReplace = this.document.querySelector('div.account-dropdown__header') as HTMLElement;

      parentNavs.forEach((navItem, index) => {
        if (!initialNavbarStateData.menuItems.items[index]) {
          return;
        }

        this._modifyMenuItem(navItem, index, language);
      });

      this.replaceChildWithComponent(navTitleParent, navChildToReplace, MobileNavTitleComponent);
      this.appendComponentToElement(MobileNavCardComponent, sideBarMenu);
    });
  }

  public modifyMenuItems(): void {
    const timer = setInterval(() => {
      const navbarMenuItems = document.getElementsByClassName('fm-navbar-menu-item');
      if (navbarMenuItems.length > 0) {
        for (let i = 0; i < navbarMenuItems.length; i++) {
          this._appendDemoVariationParameter(navbarMenuItems[i]);
        }
        clearInterval(timer);
      }
    }, 50);
  }

  public removePendoGuideTriggerElement(id: string): boolean {
    const pendoGuideElement = this.document.getElementById(id);
    if (pendoGuideElement) {
      pendoGuideElement.removeAttribute('id');
      return true;
    }

    return false;
  }

  public addClassToNotification(newClass: string): void {
    let maxRetires = 100;
    const timer = setInterval(() => {
      const element = this.document.getElementById('notification');
      if (element) {
        this.renderer.addClass(element, newClass);
        clearInterval(timer);
      } else {
        maxRetires--;
      }

      if (maxRetires === 0) {
        clearInterval(timer);
      }
    }, 50);
  }

  public interstitialMoveLeft(offset: number): void {
    if (offset < 0) {
      const newOffset = offset + this.interstitialCardWidth();
      this.renderer.setStyle(this.document.querySelector('.guides'), 'transform', `translateX(${newOffset}px)`);

      this._interstitialActivateButton('.next-guide');
      if (newOffset === 0) {
        this._interstitialDeactivateButton('.previous-guide');
      }
    }
  }

  public interstitialMoveRight(offset: number): void {
    const cardsLength = this.document.querySelectorAll('.guide').length;
    const cardWidth = this.interstitialCardWidth();
    const cardsWidth = cardsLength * cardWidth;
    const containerWidth = (this.document.querySelector('.guides-container') as HTMLElement).offsetWidth;

    if (this.interstitialHasCardsOnRight(offset)) {
      const newOffset = offset - cardWidth;
      this.renderer.setStyle(this.document.querySelector('.guides'), 'transform', `translateX(${newOffset}px)`);

      if (newOffset < 0) {
        this._interstitialActivateButton('.previous-guide');
      }

      if (cardsWidth <= containerWidth - newOffset) {
        this._interstitialDeactivateButton('.next-guide');
      }
    }
  }

  public interstitialCardWidth(): number {
    return (this.document.querySelector('.guide') as HTMLElement).offsetWidth + 20;
  }

  public replaceLogoImage(): void {
    const timer = setInterval(() => {
      const vzcLogo = this.document.querySelector('.verizon-connect-logo') as HTMLImageElement;
      const revealStarterLogo = this.document.querySelector('.reveal-bundle-logo') as HTMLImageElement;

      if (vzcLogo && revealStarterLogo) {
        const parentElement = this.document.querySelector('.fm-logo-wrapper') as HTMLDivElement;
        const link = this.document.createElement('a');
        link.href = initialNavbarStateData.accountMenu.navbarUrls.rootUrl;
        link.classList.add('demo-logo-image');
        this.appendImageCloneToLinkElement(link, vzcLogo);
        this.appendImageCloneToLinkElement(link, revealStarterLogo);

        parentElement.appendChild(link);
        clearInterval(timer);
      }
    }, 50);
  }

  private appendImageCloneToLinkElement(link: HTMLAnchorElement, image: HTMLImageElement) {
    const clonedImage = image.cloneNode() as HTMLImageElement;
    link.appendChild(clonedImage);
  }

  public interstitialHasCardsOnRight(offset: number): boolean {
    const cardsLength = this.document.querySelectorAll('.guide').length;
    const cardWidth = this.interstitialCardWidth();
    const cardsWidth = cardsLength * cardWidth;
    const containerWidth = (this.document.querySelector('.guides-container') as HTMLElement).offsetWidth;

    return cardsWidth >= containerWidth - offset;
  }

  private _interstitialActivateButton(selector: string) {
    this.renderer.removeClass(this.document.querySelector(selector), 'inactive');
  }

  private _interstitialDeactivateButton(selector: string) {
    this.renderer.addClass(this.document.querySelector(selector), 'inactive');
  }

  private _removeLogoutComponent() {
    const logoutComponent = this.document.querySelector('.fm-sidenav__logout');
    if (logoutComponent) {
      logoutComponent.remove();
    }
  }

  private _createComponentElement(component: any): HTMLElement {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return domElem;
  }

  private _modifyMenuItem(navItem: Element, index: number, language: string): void {
    this._appendDemoVariationParameter(navItem);

    const titleDiv = navItem.querySelector('.fm-sidenav-menu-item__title');
    const navBarMenuItem = initialNavbarStateData.menuItems.items[index] as IDemoNavBarMenuItem;

    const subtitleTextElement = this._createSubtitleTextElement(titleDiv, navBarMenuItem, language);
    titleDiv.appendChild(subtitleTextElement);

    if (navBarMenuItem.addOn) {
      const addOnBadge = this._createAddOnBadge();
      titleDiv.firstChild.appendChild(addOnBadge);
    }

    if (navBarMenuItem.isDisabledInMobile) {
      this._disableNavItem(navItem, navBarMenuItem);
    }
  }

  private _disableNavItem(navItem: Element, navBarMenuItem: IDemoNavBarMenuItem) {
    navItem.id = navBarMenuItem.id;
    navItem.removeAttribute('href');
    navItem.addEventListener('click', event => {
      event.preventDefault();
    });
  }

  private _createSubtitleTextElement(titleDiv: Element, navbarItem: IDemoNavBarMenuItem, language: string): HTMLDivElement {
    const subtitleTextElement = this.document.createElement('div');
    subtitleTextElement.innerText = this._getSideBarSubtitleText(navbarItem, language);
    if (titleDiv && isArrayNullOrEmpty(Array.from(titleDiv.getElementsByClassName(DemoConstants.SideMenuItemSubtitleClass)))) {
      subtitleTextElement.classList.add(DemoConstants.SideMenuItemSubtitleClass);
    }
    return subtitleTextElement;
  }

  private _getSideBarSubtitleText(navbarItem: IDemoNavBarMenuItem, language: string): string {
    switch (language) {
      case 'it-IT':
        return navbarItem.subtitleTextItalian;
      case 'es-ES':
        return navbarItem.subtitleTextSpanish;
      case 'pt-PT':
        return navbarItem.subtitleTextPortuguese;
      case 'fr-FR':
        return navbarItem.subtitleTextFrench;
      case 'de-DE':
        return navbarItem.subtitleTextGerman;
      case 'nl-NL':
        return navbarItem.subtitleTextDutch;
      case 'pl-PL':
        return navbarItem.subtitleTextPolish;
      default:
        return navbarItem.subtitleText;
    }
  }

  private _createAddOnBadge(): HTMLDivElement {
    const badgeElement = this.document.createElement('div');
    badgeElement.classList.add('fm-navbar-menu-item__add-on');
    badgeElement.innerText = 'Add on';
    return badgeElement;
  }

  private _appendDemoVariationParameter(navbarItem: Element): void {
    const queryParams = this._location.path();
    (navbarItem as HTMLLinkElement).href += queryParams;
  }
}
