/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable, Injector, Compiler, Inject, NgModuleFactory, Type, ViewContainerRef, NgModuleRef, ɵNgModuleType } from '@angular/core';

import { LAZY_LOADER_TOKEN } from './lazy-loader.token';

@Injectable({
  providedIn: 'root'
})
export class LazyLoaderService {
  constructor(
    private readonly _injector: Injector,
    private readonly _compiler: Compiler,
    @Inject(LAZY_LOADER_TOKEN) private readonly _lazyModules: { [key: string]: () => Promise<NgModuleFactory<any> | Type<any>> }
  ) {}

  async load(name: string, container: ViewContainerRef): Promise<NgModuleRef<any>> {
    const tempModule = await this._lazyModules[name]();

    let moduleFactory;

    if (tempModule instanceof NgModuleFactory) {
      // For AOT
      moduleFactory = tempModule;
    } else {
      // For JIT
      moduleFactory = await this._compiler.compileModuleAsync(tempModule);
    }

    let entryComponent;
    const bootstrap = (moduleFactory.moduleType as ɵNgModuleType).ɵmod.bootstrap;
    if (typeof bootstrap === 'function') {
      entryComponent = bootstrap()[0];
    } else {
      entryComponent = bootstrap[0];
    }

    // loading the 1st component in the bootstrap array
    const moduleRef = moduleFactory.create(this._injector);

    const compFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(entryComponent);

    const compRef = container.createComponent(compFactory);

    compRef.changeDetectorRef.markForCheck();
    return moduleRef;
  }
}
