/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

export class NumberUtilities {
  static tryParseInt(string: string, defaultValue: number = null): number {
    let result = defaultValue;
    if (!isStringNullOrEmpty(string)) {
      result = parseInt(string, 10);
    }
    return !isNaN(result) ? result : defaultValue;
  }
}
