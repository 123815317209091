/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
  selector: 'fm-modal-content',
  templateUrl: './modal-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalContentComponent {
  @HostBinding('class.modal-content')
  hostClass = true;
}
