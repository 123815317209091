/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IVehiclePlot } from '../../core/models';
import { initialDemoProgressData } from '../data';
import { DemoProgress, IMarketingTextContent, IDemoVariationContent, EDemoCities, IDemoMapHierarchy } from '../models';

export interface IDemoState {
  plots: IVehiclePlot[];
  demoHierarchy: IDemoMapHierarchy;
  progress: DemoProgress;
  isInterstitialScreenOpen: boolean;
  interstitialScreenOffset: number;
  marketingTextContent: IMarketingTextContent;
  demoVariationContent: IDemoVariationContent;
}

export const initialDemoState: IDemoState = {
  plots: null,
  demoHierarchy: null,
  progress: initialDemoProgressData,
  isInterstitialScreenOpen: false,
  interstitialScreenOffset: 0,
  marketingTextContent: {
    currentVariation: 'a',
    titleText: '',
    buttonText: '',
    interstitialText: '',
    interstitialButtonText: '',
    url: 'https://www.verizonconnect.com/buy-online/reveal',
    showDetailsLink: true
  },
  demoVariationContent: {
    currentVariation: 'a',
    currentCity: EDemoCities.atlanta,
    welcomeTitle: 'Welcome to Reveal Starter',
    takeGuidedTourText: 'Take the guided tour or click around to find out how Reveal Starter can help you manage your fleet.',
    mobileNavCardText: 'For the full Reveal Starter experience check out our demo on your desktop.',
    mobileNavTitle: 'Reveal Starter',
    shareDemoModalContent:
      'For the full Reveal Starter experience (including Reports, Alerts, Places and Replay) check out the demo on your desktop.',
    shareDemoLinkTitle: 'Reveal Starter Demo',
    navbarNowPlanEnabled: true,
    buyNowEnabled: true,
    interstitialScreenActionEnabled: true
  }
};
