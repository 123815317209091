/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ENotificationTypes {
  harshDriving = 37,
  lateStart = 54,
  fleetStatus = 55,
  excessIdle = 56,
  longStop = 57,
  inactivity = 58,
  panic = 59,
  geoFence = 60,
  sensorActivation = 61,
  speeding = 62,
  activity = 63,
  towing = 85,
  deviceStatusChange = 90,
  ignition = 160,
  hOSViolation = 170,
  powerDisruption = 171,
  unassignedMiles = 180,
  unassignedVehicle = 193,
  diagnosticTroubleCodes = 198,
  drivingHoursAlert = 207,
  videoAlert = 224
}
