/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, tap, filter, catchError } from 'rxjs/operators';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { ESearchEntityType, ISearchLightRequestOptions, ISearchLightResult, ISearchLightResponse } from './model';
import { ISearchLightInput, ISearchLightPanel } from './components';
import { FmSearchLightService } from './services';

@Component({
  selector: 'fm-search-light',
  templateUrl: './search-light.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FmSearchLightComponent implements AfterViewInit, OnDestroy {
  @Input()
  public entityTypes: ESearchEntityType;

  @Input()
  public options: ISearchLightRequestOptions;

  @Input()
  public input: ISearchLightInput;

  @Input()
  public panel: ISearchLightPanel;

  private readonly _subscriptions: Subscription = new Subscription();

  constructor(private readonly _searchLightService: FmSearchLightService) {}

  public ngAfterViewInit(): void {
    this._init();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public getResults(): void {
    if (!this.panel.isOpen) {
      this.panel.open();
    }

    if (!this.input.isSearchValid) {
      return;
    }
    this._onSearchStarted();
    this._getResults(this.input.searchText).subscribe({
      next: this._onSearchResults.bind(this),
      error: this._onSearchError.bind(this),
      complete: this._onSearchFinished.bind(this)
    });
  }

  public close(clearValue = false): void {
    this.panel.close();
    if (clearValue) {
      this.input.writeValue('');
    }
  }

  private _init(): void {
    if (isElementNull(this.input) || isElementNull(this.panel)) {
      return;
    }

    this._subscriptions.add(
      this.input.search
        .asObservable()
        .pipe(
          tap(() => {
            if (!this.panel.isOpen) {
              this.panel.open();
            }
          }),
          filter(() => this.input.isSearchValid),
          tap(this._onSearchStarted.bind(this)),
          switchMap(searchText =>
            this._getResults(searchText).pipe(
              catchError(() => {
                this._onSearchError();
                return of(null);
              })
            )
          ),
          filter(searchResults => !isElementNull(searchResults))
        )
        .subscribe({
          next: this._onSearchResults.bind(this),
          complete: this._onSearchFinished.bind(this)
        })
    );

    this._subscriptions
      .add(
        this.input.searchTextChange.subscribe(() => {
          this.close();
          this.panel.isSearchValid = this.input.isSearchValid;
          this.panel.results = undefined;
        })
      )
      .add(
        this.panel.resultSelected.subscribe((result: ISearchLightResult) => {
          this.input.writeValue(result.text);
        })
      );

    this.input.triggerable = this.panel;
    this.panel.trigger = this.input.trigger;
  }

  private _onSearchStarted(): void {
    this.panel.hasError = false;
    this.panel.isSearchValid = this.input.isSearchValid;
    this.input.isLoading = true;
    this.panel.isLoading = true;
    this.panel.results = [];
  }

  private _onSearchResults(response: ISearchLightResponse): void {
    this.panel.results = response.Data;
    this._onSearchFinished();
  }

  private _onSearchError(): void {
    this.panel.hasError = true;
    this._onSearchFinished();
  }

  private _onSearchFinished(): void {
    this.input.isLoading = false;
    this.panel.isLoading = false;
  }

  private _getResults(searchText: string): Observable<ISearchLightResponse> {
    return this._searchLightService.getResults(searchText, this.entityTypes, this.options);
  }
}
