/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ESpeedUnits {
  Knots = 4,
  MilesPerHour = 7,
  CentimetersPerSecond = 8,
  KilometersPerHour = 10
}
