/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ChangeDetectionStrategy, Component, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { LatLng, ICoordinates } from '../../models';

@Component({
  selector: 'fm-google-maps-light',
  templateUrl: './google-maps-light.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapsLightComponent implements AfterViewInit {
  @Input()
  public set coordinates(coordinates: ICoordinates) {
    if (coordinates) {
      this.mapCenter = new LatLng(coordinates.Latitude, coordinates.Longitude);
      if (!isElementNull(this.map)) {
        this.map.setCenter(this.mapCenter);
        this.marker.setPosition(this.mapCenter);
        this.map.setZoom(this._defaultZoom);
      }
    }
  }

  public map: google.maps.Map;
  public marker: google.maps.Marker;
  public mapCenter: LatLng = new LatLng(0, 0);

  @ViewChild('mapLightHostElement', { read: ElementRef })
  private readonly _mapLightHostElement: ElementRef;

  private readonly _defaultZoom = 15;

  public ngAfterViewInit(): void {
    this.map = new google.maps.Map(this._mapLightHostElement.nativeElement, {
      center: this.mapCenter.toGoogleMaps(),
      zoom: this._defaultZoom,
      backgroundColor: '#FFFFFF',
      disableDefaultUI: true,
      zoomControl: true
    });

    this.marker = new google.maps.Marker({
      position: this.mapCenter.toGoogleMaps(),
      map: this.map
    });
  }
}
