/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IGarminStopHistoryParams, IGarminStopHistory } from '../../models';

export enum EGarminStopHistoryActions {
  GetGarminStopHistory = '[Stop History] Get Garmin Stop History',
  GetGarminStopHistorySuccess = '[Stop History] Get Garmin Stop History Success'
}

export class GetGarminStopHistory implements Action {
  public readonly type = EGarminStopHistoryActions.GetGarminStopHistory;

  constructor(public payload: IGarminStopHistoryParams) { }
}

export class GetGarminStopHistorySuccess implements Action {
  public readonly type = EGarminStopHistoryActions.GetGarminStopHistorySuccess;

  constructor(public payload: IGarminStopHistory[]) { }
}

export type GarminStopHistoryActions =
  GetGarminStopHistory |
  GetGarminStopHistorySuccess;
