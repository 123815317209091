/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IPlacesHttpService } from './places-http.service.interface';
import { IPlaceBalloonResponse, EPlaceBalloonType, IGeofencePlot, IApiResponseDto, IHotspotPlot } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';

@Injectable()
export class PlacesHttpService implements IPlacesHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.placesApiEndpoint;
  }

  public getPlaceBalloon(id: number, plotType: EPlaceBalloonType): Observable<IPlaceBalloonResponse> {
    let params = new HttpParams();
    params = params.append('request[PlotId]', id.toString());
    params = params.append('request[Type]', plotType.toString());

    return this._httpClient.get<IPlaceBalloonResponse>(`${this._rootUrl}/Place/LoadPlacesBalloon`, { params });
  }

  public getGeofencePlotDetails(id: number): Observable<IGeofencePlot> {
    const params = new HttpParams().set('request[GeofenceId]', id.toString());

    return this._httpClient
      .get<IApiResponseDto<IGeofencePlot>>(`${this._rootUrl}/Place/GeofencePlotDetails`, { params })
      .pipe(map((result: IApiResponseDto<IGeofencePlot>) => result.Data));
  }

  public getHotspotPlotDetails(id: number): Observable<IHotspotPlot> {
    const params = new HttpParams().set('request[HotspotId]', id.toString());

    return this._httpClient
      .get<IApiResponseDto<IHotspotPlot>>(`${this._rootUrl}/Place/HotspotPlotDetails`, { params })
      .pipe(map((result: IApiResponseDto<IHotspotPlot>) => result.Data));
  }
}
