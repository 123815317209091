/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { EVehicleSelectionMode } from '../../models';

export enum EVehicleListPanelActions {
  CloseScopingModal = '[Vehicle List] Close Scoping Modal',
  FocusVehicle = '[Vehicle List] Focus Vehicle',
  FocusAsset = '[Vehicle List] Focus Asset',
  OpenScopingModal = '[Vehicle List] Open Scoping Modal',
  SelectVehicle = '[Vehicle List] Select Vehicle',
  SelectAndFocusVehicle = '[Vehicle List] Select And Focus Vehicle',
  SelectAsset = '[Vehicle List] Select Asset',
  UpdateVehicleSelectionMode = '[Vehicle List] Update Selection Mode'
}

export class CloseScopingModal implements Action {
  public readonly type = EVehicleListPanelActions.CloseScopingModal;
}

export class FocusVehicle implements Action {
  public readonly type = EVehicleListPanelActions.FocusVehicle;

  constructor(public payload: string) {}
}

export class FocusAsset implements Action {
  public readonly type = EVehicleListPanelActions.FocusAsset;

  constructor(public payload: string) {}
}

export class OpenScopingModal implements Action {
  public readonly type = EVehicleListPanelActions.OpenScopingModal;
}

export class SelectVehicle implements Action {
  public readonly type = EVehicleListPanelActions.SelectVehicle;

  constructor(public payload: string) {}
}

export class SelectAndFocusVehicle implements Action {
  public readonly type = EVehicleListPanelActions.SelectAndFocusVehicle;

  constructor(public payload: string) {}
}

export class SelectAsset implements Action {
  public readonly type = EVehicleListPanelActions.SelectAsset;

  constructor(public payload: string) {}
}

export class UpdateVehicleSelectionMode implements Action {
  public readonly type = EVehicleListPanelActions.UpdateVehicleSelectionMode;

  constructor(public payload: EVehicleSelectionMode) {}
}

export type VehicleListPanelActions =
  | CloseScopingModal
  | FocusVehicle
  | FocusAsset
  | OpenScopingModal
  | SelectVehicle
  | SelectAndFocusVehicle
  | SelectAsset
  | UpdateVehicleSelectionMode;
