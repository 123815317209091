/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IUserMapSettingsState } from '../state';
import { IReplayUserSettings, IUserSettings } from '../../models';

export const enum EUserMapSettingsActions {
  GetUserMapSettings = '[UserMapSettings] Get User Map Settings',
  GetUserMapSettingsSuccess = '[UserMapSettings] Get User Map Settings success',
  GetReplayUserSettings = '[UserMapSettings] Get Replay User Settings',
  GetReplayUserSettingsSuccess = '[UserMapSettings] Get Replay User Settings Success',
  SetUserSettingsTokenValue = '[UserMapSettings] Set User Settings Token Value',
  EnableFeatureToggle = '[UserMapSettings] Enable Feature Toggle',
  DisableFeatureToggle = '[UserMapSettings] Disable Feature Toggle'
}

export class GetUserMapSettings implements Action {
  public readonly type = EUserMapSettingsActions.GetUserMapSettings;
}

export class GetUserMapSettingsSuccess implements Action {
  public readonly type = EUserMapSettingsActions.GetUserMapSettingsSuccess;

  constructor(public payload: IUserMapSettingsState) {}
}

export class GetReplayUserSettings implements Action {
  public readonly type = EUserMapSettingsActions.GetReplayUserSettings;
}

export class GetReplayUserSettingsSuccess implements Action {
  public readonly type = EUserMapSettingsActions.GetReplayUserSettingsSuccess;

  constructor(public payload: IReplayUserSettings) {}
}

export class SetUserSettingsTokenValue implements Action {
  public readonly type = EUserMapSettingsActions.SetUserSettingsTokenValue;

  constructor(public payload: IUserSettings) {}
}

export class EnableFeatureToggle implements Action {
  public readonly type = EUserMapSettingsActions.EnableFeatureToggle;

  constructor(public payload: string[]) {}
}

export class DisableFeatureToggle implements Action {
  public readonly type = EUserMapSettingsActions.DisableFeatureToggle;

  constructor(public payload: string[]) {}
}

export type UserMapSettingsActions =
  | GetUserMapSettings
  | GetUserMapSettingsSuccess
  | GetReplayUserSettings
  | GetReplayUserSettingsSuccess
  | SetUserSettingsTokenValue
  | EnableFeatureToggle
  | DisableFeatureToggle;
