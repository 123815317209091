/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { EVehicleDriverPanelActions, VehicleDriverPanelActions, GetLayoutPreferencesSuccess, ELayoutPreferencesActions } from '../actions';
import { initialVehicleDriverPanelState, IVehicleDriverPanelState } from '../state';

export function vehicleDriverPanelReducer(
  state = initialVehicleDriverPanelState,
  action: VehicleDriverPanelActions | GetLayoutPreferencesSuccess
): IVehicleDriverPanelState {
  switch (action.type) {
    case EVehicleDriverPanelActions.UpdateActiveTab: {
      return {
        ...state,
        activeTab: action.payload
      };
    }
    case EVehicleDriverPanelActions.UpdateIsCollapsed: {
      return {
        ...state,
        isCollapsed: action.payload
      };
    }
    case ELayoutPreferencesActions.GetLayoutPreferencesSuccess: {
      return {
        ...state,
        activeTab: action.payload.vehicleDriverPanel.activeTab,
        isCollapsed: action.payload.vehicleDriverPanel.isCollapsed
      };
    }
    case EVehicleDriverPanelActions.OverlaySidebarMarginsChanged: {
      return {
        ...state,
        overlayMargins: action.payload
      };
    }
    case EVehicleDriverPanelActions.OpenVehicleContextMenu: {
      return {
        ...state,
        contextMenu: action.payload
      };
    }
    case EVehicleDriverPanelActions.CloseVehicleContextMenu: {
      return {
        ...state,
        contextMenu: null
      };
    }

    default:
      return state;
  }
}
