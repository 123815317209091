/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ESmsOptInStatus {
  Unknown = 0,
  CanInvite = 1,
  CanSendSms = 2,
  DoNotContact = 3,
  MaxInvitationsSent = 4
}
