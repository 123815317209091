/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ViewChild, TemplateRef, Input, ViewContainerRef, HostListener } from '@angular/core';

import { IModalConfig } from './models/modal-config.interface';

@Component({
  selector: 'fm-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent {
  @Input()
  set contentTemplate(value: TemplateRef<ViewContainerRef>) {
    this.contentContainer.createEmbeddedView(value);
  }

  public config: IModalConfig = {
    showCloseIcon: true,
    closeIcon: 'ds-ic-close',
    disableResponsiveness: false,
    size: 'large',
    transparent: false,
    closeIconSize: 'extraSmall'
  };

  @ViewChild('content', { read: ViewContainerRef, static: true })
  contentContainer: ViewContainerRef;

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(_target: HTMLElement): void {
    if (_target && _target.classList.contains('modal__overlay')) {
      this._closeModal();
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyboardInput(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this._closeModal();
    }
  }

  public setConfig(config: IModalConfig): void {
    this.config = { ...this.config, ...config };
  }

  public onCloseClick(): void {
    this._closeModal();
  }

  public closeModal(): void {}

  private _closeModal(): void {
    this.closeModal();
  }
}
