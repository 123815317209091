/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ILatLngBounds } from './lat-lng-bounds.interface';
import { ILatLng } from './lat-lng.interface';
import { ICoordinates } from '../components';
import { LatLng } from './lat-lng.class';

export class LatLngBounds implements ILatLngBounds {
  constructor(public south: number, public west: number, public north: number, public east: number) {}

  public static fromCoordinates(coordinates: ICoordinates[]): LatLngBounds {
    const latLngs = coordinates.map((c): ILatLng => ({ lat: c.Latitude, lng: c.Longitude }));
    return LatLngBounds.fromLatLngs(latLngs);
  }
  public static fromLatLngs(latLngs: ILatLng[]): LatLngBounds {
    const b = new google.maps.LatLngBounds();
    latLngs.forEach(latLng => b.extend(new google.maps.LatLng(latLng.lat, latLng.lng)));
    return LatLngBounds.fromGoogleMaps(b);
  }
  public static fromGoogleMaps(bounds: google.maps.LatLngBounds): LatLngBounds {
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    return new LatLngBounds(sw.lat(), sw.lng(), ne.lat(), ne.lng());
  }

  public static allWorld(): LatLngBounds {
    return new LatLngBounds(-90, -180, 90, 180);
  }

  public static toLatLngs(bounds: ILatLngBounds): ILatLng[] {
    return [new LatLng(bounds.south, bounds.west), new LatLng(bounds.north, bounds.east)];
  }

  public toGoogleMaps(): google.maps.LatLngBounds {
    return new google.maps.LatLngBounds(new google.maps.LatLng(this.south, this.west), new google.maps.LatLng(this.north, this.east));
  }
}
