/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EScheduledJobStatus {
  None,
  Sent,
  Received,
  Read,
  OnMyWay,
  Started,
  Finished
}
