/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { ICustomerMetadataHttpService } from './customer-metadata-http.service.interface';
import { IApiResponseDto, IDriverDetails, IDriverEmailMessage, IVehicleDetailsResponse, ISpeedMetricResponse } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';

@Injectable()
export class CustomerMetadataHttpService implements ICustomerMetadataHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.customerMetadataApiEndpoint;
  }

  public saveMapHierarchyScoping(selectedGroups: string[]): Observable<string> {
    const body = selectedGroups.reduce((params: HttpParams, group: string) => {
      return params.append('ScopingGroups', group);
    }, new HttpParams());
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._httpClient
      .post<IApiResponseDto<string>>(`${this._rootUrl}/Hierarchy/SaveLiveMapScopingSelection`, body, { headers })
      .pipe(map((result: IApiResponseDto<string>) => result.Data));
  }

  public getDriverDetails(driverId: number): Observable<IDriverDetails> {
    const params = new HttpParams().set('request[DriverId]', driverId.toString());

    return this._httpClient
      .get<IApiResponseDto<IDriverDetails>>(`${this._rootUrl}/Driver/PopulateDriverDetails`, { params })
      .pipe(map((res: IApiResponseDto<IDriverDetails>) => res.Data));
  }

  public sendEmailToDriver(message: IDriverEmailMessage): Observable<boolean> {
    return this._httpClient
      .post<IApiResponseDto<boolean>>(`${this._rootUrl}/Driver/LiveMapDriverEmail`, message)
      .pipe(map(response => response.Data));
  }
  public getVehicleDetails(vehicleId: string): Observable<IVehicleDetailsResponse> {
    const params = new HttpParams().set('request[VehicleId]', vehicleId);

    return this._httpClient
      .get<IApiResponseDto<IVehicleDetailsResponse>>(`${this._rootUrl}/Vehicle/GetVehicleDetails`, { params })
      .pipe(map(result => result.Data));
  }

  public getSpeedMetric(groupId: string): Observable<ISpeedMetricResponse> {
    const params = new HttpParams().set('request[GroupId]', groupId);

    return this._httpClient
      .get<IApiResponseDto<ISpeedMetricResponse>>(`${this._rootUrl}/Benchmark/GetSpeedMetric`, { params })
      .pipe(map(result => result.Data));
  }
}
