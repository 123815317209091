/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */
import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { MapIconRegistryService } from '../map-icon-registry';
import { XMLUtilities } from '../../../../utils';

export interface IIconElementNameMap {
  element$: Observable<SVGElement>;
  name: string;
}

export interface IconOffsetParams {
  name: string;
  xOffset: number;
  yOffset: number;
}

@Injectable({
  providedIn: 'root'
})
export class CompoundIconRegistryService {
  private _svgElementMaps: IIconElementNameMap[];

  constructor(private readonly _mapIconRegistryService: MapIconRegistryService) {}

  public addSubIconsByName(...iconNames: string[]): CompoundIconRegistryService {
    this._svgElementMaps = [];

    iconNames.forEach(iconName => {
      const elementNameMapping: IIconElementNameMap = {
        element$: this._mapIconRegistryService.getIconByName(iconName),
        name: iconName
      };
      this._svgElementMaps.push(elementNameMapping);
    });

    return this;
  }

  public offsetIcon(...offsetParams: IconOffsetParams[]): CompoundIconRegistryService {
    this._svgElementMaps.forEach(svgElementMap => {
      offsetParams.forEach(offsetParam => {
        if (svgElementMap.name === offsetParam.name) {
          svgElementMap.element$ = svgElementMap.element$.pipe(
            map(svgElement => this._offsetSvg(svgElement, offsetParam.xOffset, offsetParam.yOffset))
          );
        }
      });
    });

    return this;
  }

  public registerIcons(): void {
    const svgs$ = this._svgElementMaps.map(svgElementMap => svgElementMap.element$);
    const svgNames = this._svgElementMaps.map(svgElementMap => svgElementMap.name);
    const combinedName = svgNames.join('-');

    zip(...svgs$)
      .pipe(take(1))
      .subscribe(svgElementArray => {
        const combinedSvgs = this._combineSvgs(svgElementArray);
        this._mapIconRegistryService.addSvgIconByElement(combinedSvgs, combinedName);
      });
  }

  private _combineSvgs(subSvgList: SVGElement[]): SVGElement {
    const svgContainer = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    subSvgList.forEach(svgItem => {
      const svgDOM = XMLUtilities.ParseFromString(this._getStringFromSvg(svgItem), 'image/svg+xml').getElementsByTagName('svg')[0];
      while (svgDOM.childNodes.length > 0) {
        svgContainer.appendChild(svgDOM.childNodes[0]);
      }
    });
    return svgContainer;
  }

  private _getStringFromSvg(svg: SVGElement): string {
    return XMLUtilities.SerializeToString(svg);
  }

  private _offsetSvg(svg: SVGElement, xOffset: number, yOffset: number): SVGElement {
    svg.firstElementChild.setAttribute('transform', `translate(${xOffset},${yOffset})`);
    return svg;
  }
}
