/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

export interface IVideoEvent {
  address?: IVideoAddress;
  analysed?: boolean;
  classification?: number;
  driver?: IVideoDriver;
  eventGenerationType?: EVideoEventGenerationType;
  expired?: boolean;
  id?: number;
  key?: string;
  thumbnailUrl?: string;
  timeUtc?: Date;
  trigger?: number;
  vehicle?: IVideoVehicle;
  numberOfVideos?: number;
}

export interface IVideoAddress {
  city?: string;
  country?: string;
  formatted?: string;
  latitude?: number;
  longitude?: number;
  state?: string;
  zipCode?: string;
}

export interface IVideoDriver {
  contactNumber?: string;
  id?: number;
  name?: string;
}

export interface IVideoVehicle {
  id?: number;
  name?: string;
  number?: string;
}

export enum EVideoEventClassification {}

export enum EVideoEventGenerationType {
  None = 0,
  VTU = 1,
  VoD = 2
}

export enum EVideoClassifications {
  HarshDriving = 1,
  Dangerous = 2,
  Collision = 3,
  LowRisk = 4,
  Requested = 247
}
