/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IGarminStopHistoryState } from '../state';
import { IGarminStopHistory } from '../../models';

export const getGarminStopHistoryState = createFeatureSelector<IGarminStopHistoryState>('garminStopHistory');

export const getGarminStopHistory = createSelector(
  getGarminStopHistoryState,
  (state: IGarminStopHistoryState): IGarminStopHistory[] => state.history
);
