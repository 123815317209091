/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { IMapIconRegistryService } from './map-icon-registry.service.interface';
import { XMLUtilities } from '../../../../utils';
import { IIconNamePathPair } from '../../models/icon-name-path-pair.interface';

@Injectable({
  providedIn: 'root'
})
export class MapIconRegistryService implements IMapIconRegistryService {
  private readonly _svgs = new Map<string, string>();

  constructor(private readonly _matIconRegistryService: MatIconRegistry, private readonly _domSanitizer: DomSanitizer) {}

  public addSvgIcon(svgIcon: IIconNamePathPair) {
    this._matIconRegistryService.addSvgIconLiteral(svgIcon.name, this._domSanitizer.bypassSecurityTrustHtml(svgIcon.svg));
  }

  public addSvgIconSet(svgSprite: string) {
    this._matIconRegistryService.addSvgIconSetLiteral(this._domSanitizer.bypassSecurityTrustHtml(svgSprite));
  }

  public addSvgIconByElement(svgElement: SVGElement, svgName: string) {
    const svgLiteral = XMLUtilities.SerializeToString(svgElement);
    const safeHtmlSvg = this._domSanitizer.bypassSecurityTrustHtml(svgLiteral);
    this._matIconRegistryService.addSvgIconLiteral(svgName, safeHtmlSvg);
  }

  public getIconByName(iconName: string): Observable<SVGElement> {
    return this._matIconRegistryService.getNamedSvgIcon(iconName);
  }

  public getSerializedIconByName(iconName: string): Observable<string> {
    if (this._svgs.has(iconName)) {
      return of(this._svgs.get(iconName));
    }

    return this.getIconByName(iconName).pipe(
      map(svg => {
        const svgXmlString = XMLUtilities.SerializeToString(svg);
        const base64 = btoa(svgXmlString);
        // cache result
        this._svgs.set(iconName, base64);

        return base64;
      }),
      catchError(err => {
        console.error(`failed to find icon: ${err}`);
        return of(null);
      })
    );
  }
}
