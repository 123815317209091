/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EPermissionId {
  ManageDrivers = 3,
  ManageVehicles = 5,
  ShowSpeedLimits = 11,
  Places = 13,
  Dispatch = 15,
  Geofences = 16,
  EnableIntegrations = 19,
  LiveMapFindNearest = 20,
  LiveMapSendStopsToInVehicleNavigationDevices = 21,
  Replay = 22,
  LiveMapWorkOrders = 26,
  LiveMapWorkOrdersAssigned = 27,
  LiveMapWorkOrdersUnassigned = 28,
  DispatchWindow = 30,
  DispatchWindowMessages = 31,
  DispatchWindowStops = 32,
  Directions = 33,
  DirectionsSendToVehicle = 34,
  Alerts = 53,
  ReportDetailed = 83,
  ReportJourneyAndStops = 86,
  LiveMapTextMessaging = 98,
  ShowVehicleSpeed = 103,
  ManagePlaces = 132,
  SuggestedGeofences = 133,
  CreatePlaceFromMap = 140,
  ManageDriverDispatch = 189,
  RoutingLiveMap = 192,
  ShowTowingNotifications = 211,
  ShowGPSSignalStrength = 213,
  AssetTracking = 214,
  ManageAssets = 218,
  VideoExperience = 224,
  DriverPlannerPage = 226,
  RoadSideAssistance = 229,
  VideoAccess = 238,
  Scheduler = 243,
  ECMFuelLevel = 267
}
