/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { INavbarMenuItem, getNavbarMenuItemsArray } from '@fleetmatics/ui.navbar';

import { IAppState } from '../../store/state';
import { getIsFullscreen } from '../../store/selectors';
import { MapAnimations } from '../../../maps/animations';
import { selectNavbarNotificationsBellProperties } from '@fleetmatics/ui.notifications/connector';

const largeNavbarHeight = 80;
const smallNavbarHeight = 56;

@Component({
  selector: 'fm-navbar-container',
  templateUrl: './navbar-container.component.html',
  styleUrls: ['./navbar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [MapAnimations.hideNavbar]
})
export class NavbarContainerComponent implements OnInit, OnDestroy {
  navbarBellProperties$ = this._store.pipe(select(selectNavbarNotificationsBellProperties));

  public isFullscreen$: Observable<boolean>;
  public menuItems$: Observable<INavbarMenuItem[]>;
  public animationState: {
    value: string;
    params: {
      navbarHeight: number;
    };
  } = { value: 'visible', params: { navbarHeight: largeNavbarHeight } };

  private _navbarHeight: number;
  private readonly _unsubscribe$ = new Subject<void>();
  private readonly _lgMediaQuery = '(min-width: 1024px) and (max-width: 1199.98px)';
  private readonly _xlMediaQuery = '(min-width: 1023.98px)';

  constructor(
    private readonly _store: Store<IAppState>,
    private readonly _breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {}

  public ngOnInit(): void {
    this.isFullscreen$ = this._store.pipe(select(getIsFullscreen));
    this.menuItems$ = this._store.pipe(select(getNavbarMenuItemsArray));

    if (isPlatformBrowser(this._platformId)) {
      combineLatest([this._breakpointObserver.observe([this._lgMediaQuery, this._xlMediaQuery]), this.menuItems$])
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(([breakpointState, menuItems]: [BreakpointState, INavbarMenuItem[]]) => {
          const tooManyItems = menuItems.length > 7;
          const isXl = breakpointState.breakpoints[this._xlMediaQuery];
          const isLg = breakpointState.breakpoints[this._lgMediaQuery];

          const isLargeNavbar = isXl || (isLg && !tooManyItems);
          this._navbarHeight = isLargeNavbar ? largeNavbarHeight : smallNavbarHeight;
        });
    }

    this.isFullscreen$.subscribe(isFullscreen => {
      const state = isFullscreen ? 'hidden' : 'visible';
      this.animationState = { value: state, params: { navbarHeight: this._navbarHeight } };
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
