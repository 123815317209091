/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */
import { Action } from '@ngrx/store';

import { EDriverWorkingState } from 'tacho';

import { IDriverStatus, EDriverStatusSortType, IVehiclePlot, IVehicleTachoInfo, IVehicleWorkOrderBalloon } from '../../models';

export enum EWorkingTimeDirectiveActions {
  GetDriversStatus = '[Working Time Directive] Get Drivers Tacho Status',
  GetDriversStatusSuccess = '[Working Time Directive] Get Drivers Tacho Status Success',
  GetDriversStatusError = '[Working Time Directive] Get Drivers Tacho Status Error',
  UpdateDriverWorkingStateFilter = '[Working Time Directive] Update Filter For Driver Working State',
  UpdateIsBreakDueSelectedFilter = '[Working Time Directive] Update Is Break Due Filter Selection State',
  UpdateDriverStatusSortType = '[Working Time Directive] Update Driver Status Sort Type',
  OpenDriverPlanner = '[Working Time Directive] Open Driver Planner',
  GetVehiclesTachoStatus = '[Working Time Directive] Get Vehicles Tacho Status',
  GetVehiclesTachoStatusSuccess = '[Working Time Directive] Get Vehicles Tacho Status Success',
  GetVehiclesTachoStatusError = '[Working Time Directive] Get Vehicles Tacho Status Error',
  StartTachoCardHighlight = '[Working Time Directive] Start Tacho Card Highlight',
  StopTachoCardHighlight = '[Working Time Directive] Stop Tacho Card Highlight',
  UpdateVehiclesDriverName = '[Vehicle] Update Vehicles Driver Name',
  UpdateTachoVehicleCoordinates = '[Working Time Directive] Update Tacho Vehicle Coordinates'
}

export class GetDriversStatus implements Action {
  public readonly type = EWorkingTimeDirectiveActions.GetDriversStatus;
}

export class GetDriversStatusSuccess implements Action {
  public readonly type = EWorkingTimeDirectiveActions.GetDriversStatusSuccess;

  constructor(public payload: IDriverStatus[]) {}
}

export class GetDriversStatusError implements Action {
  public readonly type = EWorkingTimeDirectiveActions.GetDriversStatusError;
}

export class UpdateDriverWorkingStateFilter implements Action {
  public readonly type = EWorkingTimeDirectiveActions.UpdateDriverWorkingStateFilter;

  constructor(public payload: EDriverWorkingState) {}
}

export class UpdateIsBreakDueSelectedFilter implements Action {
  public readonly type = EWorkingTimeDirectiveActions.UpdateIsBreakDueSelectedFilter;

  constructor(public payload: boolean) {}
}

export class UpdateDriverStatusSortType implements Action {
  public readonly type = EWorkingTimeDirectiveActions.UpdateDriverStatusSortType;

  constructor(public payload: EDriverStatusSortType) {}
}

export class OpenDriverPlanner implements Action {
  public readonly type = EWorkingTimeDirectiveActions.OpenDriverPlanner;
}

export class GetVehiclesTachoStatus implements Action {
  public readonly type = EWorkingTimeDirectiveActions.GetVehiclesTachoStatus;

  constructor(public payload: number[]) {}
}

export class GetVehiclesTachoStatusSuccess implements Action {
  public readonly type = EWorkingTimeDirectiveActions.GetVehiclesTachoStatusSuccess;

  constructor(public payload: IVehicleTachoInfo[]) {}
}

export class GetVehiclesTachoStatusError implements Action {
  public readonly type = EWorkingTimeDirectiveActions.GetVehiclesTachoStatusError;
}

export class StartTachoCardHighlight implements Action {
  public readonly type = EWorkingTimeDirectiveActions.StartTachoCardHighlight;

  constructor(public payload: IVehicleTachoInfo) {}
}

export class StopTachoCardHighlight implements Action {
  public readonly type = EWorkingTimeDirectiveActions.StopTachoCardHighlight;

  constructor(public payload: IVehicleTachoInfo) {}
}

export class UpdateVehiclesDriverName implements Action {
  public readonly type = EWorkingTimeDirectiveActions.UpdateVehiclesDriverName;

  constructor(public payload: IVehiclePlot[]) {}
}

export class UpdateTachoVehicleCoordinates implements Action {
  public readonly type = EWorkingTimeDirectiveActions.UpdateTachoVehicleCoordinates;

  constructor(public payload: IVehicleWorkOrderBalloon) {}
}

export type WorkingTimeDirectiveActions =
  | GetVehiclesTachoStatusSuccess
  | GetVehiclesTachoStatusError
  | GetDriversStatus
  | GetDriversStatusSuccess
  | GetDriversStatusError
  | UpdateDriverWorkingStateFilter
  | UpdateIsBreakDueSelectedFilter
  | UpdateDriverStatusSortType
  | OpenDriverPlanner
  | StartTachoCardHighlight
  | StopTachoCardHighlight
  | UpdateVehiclesDriverName
  | UpdateTachoVehicleCoordinates;
