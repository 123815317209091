/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

import { advancedOptionsReducer } from './advanced-options.reducer';
import { balloonInfoReducer } from './balloon-info.reducer';
import { customerMetadataReducer } from './customer-metadata.reducer';
import { layoutReducer } from './layout.reducer';
import { layoutPreferencesReducer } from './layout-preferences.reducer';
import { mapsReducer } from './maps.reducer';
import { mapOptionsReducer } from './map-options.reducer';
import { plotsReducer } from './plots.reducer';
import { userMapSettingsReducer } from './user-map-settings.reducer';
import { vehicleDriverPanelReducer } from './vehicle-driver-panel.reducer';
import { vehicleReducer } from './vehicle.reducer';
import { IAppState } from '../state';
import { vehicleListPanelReducer } from './vehicle-list-panel.reducer';
import { driverMessageReducer } from './driver-message.reducer';
import { garminStopHistoryReducer } from './garmin-stop-history.reducer';
import { sendToGarminReducer } from './send-to-garmin.reducer';
import { reverseGeocodeReducer } from './reverse-geocode.reducer';
import { workOrderReducer } from './work-order.reducer';
import { roadsideAssistanceReducer } from './roadside-assistance.reducer';
import { assetsReducer } from './assets.reducer';
import { harshDrivingEventsReducer } from './harsh-driving-events.reducer';
import { configReducer } from './config.reducer';
import { workingTimeDirectiveReducer } from './working-time-directive.reducer';
import { replayReducer } from '../../../replay/store';
import { modulesReducer } from './modules.reducer';
import { searchReducer } from '../../../search/store';

export const appReducers: ActionReducerMap<IAppState, any> = {
  router: routerReducer,
  customerMetadata: customerMetadataReducer,
  layout: layoutReducer,
  layoutPreferences: layoutPreferencesReducer,
  plots: plotsReducer,
  userMapSettings: userMapSettingsReducer,
  mapOptions: mapOptionsReducer,
  vehicleDriverPanel: vehicleDriverPanelReducer,
  advancedOptions: advancedOptionsReducer,
  balloonInfo: balloonInfoReducer,
  vehicle: vehicleReducer,
  maps: mapsReducer,
  vehicleListPanel: vehicleListPanelReducer,
  workingTimeDirective: workingTimeDirectiveReducer,
  driverMessage: driverMessageReducer,
  garminStopHistory: garminStopHistoryReducer,
  sendToGarmin: sendToGarminReducer,
  reverseGeocode: reverseGeocodeReducer,
  workOrder: workOrderReducer,
  roadsideAssistance: roadsideAssistanceReducer,
  harshDrivingEvent: harshDrivingEventsReducer,
  config: configReducer,
  assets: assetsReducer,
  modules: modulesReducer,
  // Replay reducer is needed here to avoid ngrx warnings related to not being able to access a lazy loaded module feature state
  replay: replayReducer,
  search: searchReducer
};
