/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ILatLng } from './lat-lng.interface';
import { EPlotIcon } from './plot-icon.enum';
import { ETrackingDeviceEventCode } from './tracking-device-event-code.enum';
import { EVehicleStatusType } from './vehicle-status-type.enum';
import { ESignalStatus } from './signal-strength.enum';
import { EPlotType } from './plot-type.enum';
import { IVehiclePlot } from './vehicle-plot.interface';

export class VehiclePlot implements IVehiclePlot {
  id: number;
  coordinates: ILatLng;
  geofenceName: string;
  lastUpdateForDisplay: string;
  lastUpdateIsToday: boolean;
  status: EVehicleStatusType;
  signalStatus: { iconClass: ESignalStatus; lastUpdate: Date };
  address: string;
  addressId: number;
  direction: number;
  driverId: number;
  driverDisplayName: string;
  duration: number;
  eventCode: ETrackingDeviceEventCode;
  group: string;
  iconClass: EPlotIcon;
  isSearchingAddress?: boolean;
  offsensors: string[];
  onsensors: string[];
  speed: number;
  speedViolation: { inrixSpeedLimitType: number; violationSpeed: number };
  ticks: number;
  time: string;
  timeUnits: string;
  vehicleName: string;
  vehicleSpeedlimit: number;
  vehicleSpeedlimitType: number;
  hasNavigationDevice: boolean;
  isFromSearchLight: boolean;
  isUpdating: boolean;
  batchTimestampTicks: number;
  type: EPlotType;

  constructor(vehiclePlot: IVehiclePlot) {
    this.id = vehiclePlot.id;
    this.coordinates = vehiclePlot.coordinates;
    this.geofenceName = vehiclePlot.geofenceName;
    this.lastUpdateForDisplay = vehiclePlot.lastUpdateForDisplay;
    this.lastUpdateIsToday = vehiclePlot.lastUpdateIsToday;
    this.status = vehiclePlot.status;
    this.address = vehiclePlot.address;
    this.addressId = vehiclePlot.addressId;
    this.direction = vehiclePlot.direction;
    this.driverId = vehiclePlot.driverId;
    this.driverDisplayName = vehiclePlot.driverDisplayName;
    this.duration = vehiclePlot.duration;
    this.eventCode = vehiclePlot.eventCode;
    this.group = vehiclePlot.group;
    this.iconClass = vehiclePlot.iconClass;
    this.signalStatus = vehiclePlot.signalStatus;
    this.offsensors = vehiclePlot.offsensors;
    this.onsensors = vehiclePlot.onsensors;
    this.speed = vehiclePlot.speed;
    this.speedViolation = vehiclePlot.speedViolation;
    this.ticks = vehiclePlot.ticks;
    this.time = vehiclePlot.time;
    this.timeUnits = vehiclePlot.timeUnits;
    this.vehicleName = vehiclePlot.vehicleName;
    this.vehicleSpeedlimit = vehiclePlot.vehicleSpeedlimit;
    this.vehicleSpeedlimitType = vehiclePlot.vehicleSpeedlimitType;
    this.hasNavigationDevice = vehiclePlot.hasNavigationDevice;
    this.isFromSearchLight = vehiclePlot.isFromSearchLight;
    this.isUpdating = vehiclePlot.isUpdating;
    this.batchTimestampTicks = vehiclePlot.batchTimestampTicks;
    this.type = vehiclePlot.type;
  }
}
