/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ENTER } from '@angular/cdk/keycodes';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  Provider,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
  ElementRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ITriggerable } from '@fleetmatics/ui.navbar';
import { isElementNull, FmDocumentRefService } from '@fleetmatics/ui.utilities';

import { ISearchLightInput } from './search-light-input.interface';
import { MIN_SEARCH_TEXT_LENGTH } from '../../search-light.constant';

export const SEARCH_LIGHT_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-forward-ref
  useExisting: forwardRef(() => FmSearchLightInputComponent),
  multi: true
};

@Component({
  selector: 'fm-search-light-input',
  templateUrl: './search-light-input.component.html',
  styleUrls: ['./search-light-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SEARCH_LIGHT_INPUT_VALUE_ACCESSOR],
  exportAs: 'fmSearchLightInput'
})
export class FmSearchLightInputComponent implements ISearchLightInput {
  @Input()
  public classes: string;
  @Input()
  public elementId: string;
  @Input()
  public set initialValue(value: string) {
    this.searchText = value.trim();
    this.isSearchValid = this.searchText.length >= this._minSearchTextLength;
  }
  @Input()
  public isActive: boolean;
  @Input()
  public set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    this._changeDetectorRef.markForCheck();
  }
  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input()
  public isSearchValid = false;
  @Input()
  public set minSearchTextLength(minSearchTextLength: number) {
    this._minSearchTextLength = minSearchTextLength;
  }
  @Input()
  public placeholder: string;
  @Input()
  public showIcon = false;
  @Input()
  public set triggerable(triggerable: ITriggerable) {
    this._triggerable = triggerable;

    if (isElementNull(triggerable)) {
      return;
    }

    this._triggerable.isOpenChange.subscribe((isOpen: boolean) => {
      this.isTriggerableOpen = isOpen;
    });
  }
  public get triggerable(): ITriggerable {
    return this._triggerable;
  }

  @Output()
  public closed = new EventEmitter<void>();
  @Output()
  public search = new EventEmitter<string>();
  @Output()
  public searchTextChange = new EventEmitter<string>();

  @ViewChild(CdkOverlayOrigin, { static: false })
  public trigger: CdkOverlayOrigin;

  @ViewChild('searchInput', { static: false })
  public searchInput: ElementRef;

  public isTriggerableOpen = false;
  public searchText = '';

  private _isLoading = false;
  private _minSearchTextLength: number = MIN_SEARCH_TEXT_LENGTH;
  private _triggerable: ITriggerable;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _documentService: FmDocumentRefService,
    private readonly _elementRef: ElementRef
  ) {}

  public onChange: (value: string) => void;
  public onTouch: () => void;

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Element): void {
    if ((!this.isTriggerableOpen && !this.isActive) || this.isLoading) {
      return;
    }

    const searchLightPanelElement = this._documentService.nativeDocument.querySelector('.fm-search-light-panel');
    const clickedInside =
      this._elementRef.nativeElement.contains(targetElement) ||
      (!isElementNull(searchLightPanelElement) && searchLightPanelElement.contains(targetElement));
    if (!clickedInside) {
      this.closed.emit();
    }
  }

  public writeValue(value: string): void {
    if (!isElementNull(value)) {
      this.searchText = value;
      this._changeDetectorRef.markForCheck();
    }
  }

  public registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouch: () => void): void {
    this.onTouch = onTouch;
  }

  public onSearchTextChanged(searchText: string): void {
    this.searchText = searchText.trim();

    if (this.isSearchValid !== this.searchText.length >= this._minSearchTextLength) {
      this.isSearchValid = this.searchText.length >= this._minSearchTextLength;
    }

    this.onChange(searchText);
    this.searchTextChange.emit(searchText);
  }

  public onKeyDown(event: KeyboardEvent): void {
    // tslint:disable-next-line:deprecation
    if (event.keyCode === ENTER) {
      this.search.emit(this.searchText);
    }
  }

  public focus(): void {
    if (!isElementNull(this.searchInput)) {
      this.searchInput.nativeElement.focus();
    }
  }
}
