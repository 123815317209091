/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { take, filter, mapTo, map, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';

import { isElementNull, FmWindowRefService, isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import {
  getMapHierarchyInfo,
  getVehiclePlots,
  getUserSettings,
  getMapModuleLoaded,
  getIsFullscreen,
  getUserPermissions
} from '../../store/selectors';
import { IAppLauncherService } from './app-launcher.service.interface';
import { IAppState } from '../../store/state';
import { IReplayRouteParams } from './replay-route-params.interface';
import { IViewReplayParameters } from '../../models';
import {
  UpdateIsFullscreen,
  UpdateVehicleAsSelected,
  TriggerViewReplay,
  GetLayoutPreferences,
  GetUserMapSettings,
  GetAccessibleVehiclesCountSuccess,
  EnableFeatureToggle,
  DisableFeatureToggle
} from '../../store/actions';
import { NumberUtilities } from '../../../../utils';
import { FEATURE_TOGGLES } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class AppLauncherService implements IAppLauncherService {
  launchReplay(routeParams: IReplayRouteParams): void {
    if (!this._validateReplayRouteParams(routeParams)) {
      this._windowRefService.nativeWindow.location.href = '/';
    }

    this._dispatchReplayActions(routeParams);
  }

  overrideFeatureToggles(featuresToEnable: string[], featuresToDisable: string[]): void {
    // create a map of available feature toggles, with lower case key
    const availableFeatureToggles = new Map(Object.values(FEATURE_TOGGLES).map(value => [value.toLowerCase(), value]));
    // ensure that featre toggle from query parameter is valid
    const togglesToEnable = featuresToEnable?.filter(t => availableFeatureToggles.has(t)).map(t => availableFeatureToggles.get(t));
    const togglesToDisable = featuresToDisable?.filter(t => availableFeatureToggles.has(t)).map(t => availableFeatureToggles.get(t));

    this._store
      .pipe(
        select(getUserPermissions),
        // wait until the user permissions are loaded - at this point we know that feature toggles from UIS are loaded
        filter(userPermissions => !isElementNull(userPermissions), take(1)),
        tap(() => {
          this._store.dispatch(new EnableFeatureToggle(togglesToEnable));
          this._store.dispatch(new DisableFeatureToggle(togglesToDisable));
        })
      )
      .subscribe();
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _windowRefService: FmWindowRefService,
    private readonly _store: Store<IAppState>
  ) {}

  private _validateReplayRouteParams(routeParams: IReplayRouteParams): boolean {
    return !isElementNull(routeParams.vehicleId) && !isElementNull(routeParams.start);
  }

  private _dispatchReplayActions(routeParams: IReplayRouteParams): void {
    if (isPlatformServer(this._platformId)) {
      this._dispatchReplayActionsSSR();
    }
    if (isPlatformBrowser(this._platformId)) {
      this._dispatchReplayActionsBrowser(routeParams);
    }
  }

  private _dispatchReplayActionsSSR() {
    this._store.dispatch(new UpdateIsFullscreen(true));
  }

  private _dispatchReplayActionsBrowser(routeParams: IReplayRouteParams): void {
    /* actions to initialize the map:
    GetAccessibleVehiclesCountSuccess is needed for scoping. since we don't have the selection tree
    in replay mode, the value doesn't matter
    */
    this._store.dispatch(new GetAccessibleVehiclesCountSuccess(0));
    this._enableFullscreenIfNotEnabled();
    this._store.dispatch(new GetLayoutPreferences());
    this._store.dispatch(new GetUserMapSettings());

    this._selectVehicle(routeParams.vehicleId);

    this._waitForReplayDataToLoad(routeParams).subscribe(() => this._triggerReplay(routeParams));
  }

  private _enableFullscreenIfNotEnabled(): void {
    this._store
      .pipe(
        select(getIsFullscreen),
        take(1),
        filter(isFullsreen => !isFullsreen)
      )
      .subscribe(() => this._store.dispatch(new UpdateIsFullscreen(true)));
  }

  private _waitForReplayDataToLoad(routeParams: IReplayRouteParams): Observable<void> {
    const isVehiclePlotDataLoaded$: Observable<void> = this._store.pipe(
      select(getVehiclePlots),
      map(plots => plots.find(p => p.id === +routeParams.vehicleId)),
      filter(p => !isElementNull(p)),
      take(1),
      mapTo(void 0)
    );

    const isMapLoadedTrue$: Observable<void> = this._store.pipe(
      select(getMapModuleLoaded),
      filter(isLoaded => isLoaded),
      take(1),
      mapTo(void 0)
    );
    const isUserSettingsLoaded$: Observable<void> = this._store.pipe(
      select(getUserSettings),
      filter(u => !isElementNull(u)),
      take(1),
      mapTo(void 0)
    );
    const streams = [isVehiclePlotDataLoaded$, isMapLoadedTrue$, isUserSettingsLoaded$];
    return forkJoin(streams).pipe(mapTo(void 0));
  }

  private _selectVehicle(vehicleId: string): void {
    const whenMapHierarchyIsLoaded$ = this._store.pipe(
      select(getMapHierarchyInfo),
      filter(h => h.mapHierarchy.length > 0),
      take(1),
      mapTo(void 0)
    );

    // we need to make sure the vehicle is selected, so we have its plot data loaded in the store
    whenMapHierarchyIsLoaded$.subscribe(() =>
      this._store.dispatch(
        new UpdateVehicleAsSelected({
          focusVehicle: false,
          selectedVehicle: `v${vehicleId}`
        })
      )
    );
  }

  private _triggerReplay(routeParams: IReplayRouteParams): void {
    const startDateUtc = moment.utc(routeParams.start);
    const preselectSegmentTimeUtc = isStringNullOrEmpty(routeParams.preselectSegmentTime)
      ? null
      : moment.utc(routeParams.preselectSegmentTime);

    const triggerReplayParameters: IViewReplayParameters = {
      id: +routeParams.vehicleId,
      dateTime: startDateUtc.toDate(),
      disableAnimation: true, // disable animation for faster loading time
      onCloseNavigateToHome: true, // define behavior for close replay button
      accountSettingOverrides: {
        minimumStopDuration: NumberUtilities.tryParseInt(routeParams.minimumStopDuration),
        minimumIdleDuration: NumberUtilities.tryParseInt(routeParams.minimumIdleDuration),
        showIdleSegments: isStringNullOrEmpty(routeParams.showIdleSegments) ? null : routeParams.showIdleSegments === 'true'
      },
      preselectSegmentTime: preselectSegmentTimeUtc?.toDate(),
      isOpenedFromExternal: true
    };

    this._store.dispatch(new TriggerViewReplay(triggerReplayParameters));
  }
}
