<label class="checkbox"
  [class.checkbox--disabled]="isDisabled"
  (click)="onChange()">
  <input class="checkbox__input"
    [disabled]="isDisabled"
    type="checkbox"
    (click)="$event.stopPropagation()">
  <div class="checkbox__checkmark"
    [class.checkbox__checkmark--checked]="isChecked"
    [class.checkbox__checkmark--indeterminate]="isIndeterminate"
    [class.checkbox__checkmark--disabled]="isDisabled">
    <div *ngIf="isChecked"
      class="checkbox--checked">
    </div>
    <div *ngIf="isIndeterminate"
      class="checkbox--indeterminate">
    </div>
  </div>
  {{ defaultLabel }}
</label>
