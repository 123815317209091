/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import {
  ILabel,
  MarkerInfo,
  IVehicleWorkOrderBalloon,
  ICustomClickEventData,
  IPlaceBalloon,
  IGarminBalloon,
  IHarshDrivingEventBalloon,
  IAssetBalloon,
  IPolylineHoverParams,
  IPolylineBalloon,
  IClusterBalloon,
  IClusterClickEvent
} from '../../models';
import { IReplayBalloon } from '../../../replay/models/replay-balloon.interface';

export const enum EBalloonInfoActions {
  CloseBalloon = '[BalloonInfo] Close Balloon',
  GetHarshDrivingEventBalloon = '[BalloonInfo] Get Harsh Driving Event Balloon',
  GetHarshDrivingEventBalloonSuccess = '[BalloonInfo] Get Harsh Driving Event Balloon Success',
  GetGarminStopBalloon = '[BalloonInfo] Get Garmin Stop Balloon',
  GetGarminStopBalloonSuccess = '[BalloonInfo] Get Garmin Stop Balloon Success',
  GetLabel = '[BalloonInfo] Get label',
  GetLabelSuccess = '[BalloonInfo] Get label success',
  ClearLabel = '[BalloonInfo] Clear Label',
  GetLabels = '[BalloonInfo] Get labels',
  GetLabelsSuccess = '[BalloonInfo] Get labels success',
  GetPlaceBalloon = '[BalloonInfo] Get Place Balloon',
  GetPlaceBalloonSuccess = '[BalloonInfo] Get Place Balloon Success',
  GetVehicleBalloon = '[BalloonInfo] Get Vehicle Balloon',
  GetVehicleBalloonSuccess = '[BalloonInfo] Get Vehicle Balloon Success',
  GetWorkOrderBalloon = '[BalloonInfo] Get Work Order Balloon',
  GetWorkOrderBalloonSuccess = '[BalloonInfo] Get Work Order Balloon Success',
  GetAssetBalloon = '[BalloonInfo] Get Asset Balloon',
  GetAssetBalloonSuccess = '[BalloonInfo] Get Asset Balloon Success',
  ShowBalloon = '[BalloonInfo] Show Balloon',
  UpdateVehicleBalloon = '[BalloonInfo] Update Vehicle Balloon',
  UpdateVehicleBalloonAddress = '[BalloonInfo] Update Vehicle Balloon Address',
  UpdateVehicleBalloonWorkingTimeDirective = '[BalloonInfo] Update Vehicle Balloon Working Time Directive',
  UpdateSpeedLimitVehicleBalloon = '[BalloonInfo] Update Speed Limit Vehicle Balloon',
  UpdateVehicleBalloonScheduler = '[BalloonInfo] Update Vehicle Balloon Scheduler',
  GetReplayBalloon = '[BalloonInfo] Get Replay Balloon',
  GetReplayBalloonSuccess = '[BalloonInfo] Get Replay Balloon Success',
  GetPolylineBalloon = '[BalloonInfo] Get Polyline Balloon',
  GetPolylineBalloonSuccess = '[BalloonInfo] Get Polyline Balloon Success',
  GetClusterBalloon = '[BalloonInfo] Get Cluster Balloon',
  GetClusterBalloonSuccess = '[BalloonInfo] Get Cluster Balloon Success'
}

export class CloseBalloon implements Action {
  public readonly type = EBalloonInfoActions.CloseBalloon;
}

export class GetHarshDrivingEventBalloon implements Action {
  public readonly type = EBalloonInfoActions.GetHarshDrivingEventBalloon;

  constructor(public payload: ICustomClickEventData<MarkerInfo>) {}
}

export class GetHarshDrivingEventBalloonSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetHarshDrivingEventBalloonSuccess;

  constructor(public payload: IHarshDrivingEventBalloon) {}
}

export class GetGarminStopBalloon implements Action {
  public readonly type = EBalloonInfoActions.GetGarminStopBalloon;

  constructor(public payload: ICustomClickEventData<MarkerInfo>) {}
}

export class GetGarminStopBalloonSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetGarminStopBalloonSuccess;

  constructor(public payload: IGarminBalloon) {}
}

export class GetLabel implements Action {
  public readonly type = EBalloonInfoActions.GetLabel;

  constructor(public payload: MarkerInfo[]) {}
}

export class ClearLabel implements Action {
  public readonly type = EBalloonInfoActions.ClearLabel;
}

export class GetLabelSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetLabelSuccess;

  constructor(public payload: ILabel) {}
}

export class GetLabels implements Action {
  public readonly type = EBalloonInfoActions.GetLabels;

  constructor(public payload: MarkerInfo[]) {}
}

export class GetLabelsSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetLabelsSuccess;

  constructor(public payload: ILabel[]) {}
}

export class GetPlaceBalloon implements Action {
  public readonly type = EBalloonInfoActions.GetPlaceBalloon;

  constructor(public payload: ICustomClickEventData<MarkerInfo>) {}
}

export class GetPlaceBalloonSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetPlaceBalloonSuccess;

  constructor(public payload: IPlaceBalloon) {}
}

export class GetVehicleBalloon implements Action {
  public readonly type = EBalloonInfoActions.GetVehicleBalloon;

  constructor(public payload: ICustomClickEventData<MarkerInfo>) {}
}

export class GetVehicleBalloonSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetVehicleBalloonSuccess;

  constructor(public payload: IVehicleWorkOrderBalloon) {}
}

export class GetWorkOrderBalloon implements Action {
  public readonly type = EBalloonInfoActions.GetWorkOrderBalloon;

  constructor(public payload: ICustomClickEventData<MarkerInfo>) {}
}

export class GetWorkOrderBalloonSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetWorkOrderBalloonSuccess;

  constructor(public payload: IVehicleWorkOrderBalloon) {}
}

export class GetAssetBalloon implements Action {
  public readonly type = EBalloonInfoActions.GetAssetBalloon;

  constructor(public payload: ICustomClickEventData<MarkerInfo>) {}
}

export class GetAssetBalloonSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetAssetBalloonSuccess;

  constructor(public payload: IAssetBalloon) {}
}

export class ShowBalloon implements Action {
  public readonly type = EBalloonInfoActions.ShowBalloon;

  constructor(public payload: ICustomClickEventData<MarkerInfo>) {}
}

export class UpdateVehicleBalloon implements Action {
  public readonly type = EBalloonInfoActions.UpdateVehicleBalloon;

  constructor(public payload: IVehicleWorkOrderBalloon) {}
}

export class UpdateVehicleBalloonAddress implements Action {
  public readonly type = EBalloonInfoActions.UpdateVehicleBalloonAddress;

  constructor(public payload: IVehicleWorkOrderBalloon) {}
}

export class UpdateVehicleBalloonWorkingTimeDirective implements Action {
  public readonly type = EBalloonInfoActions.UpdateVehicleBalloonWorkingTimeDirective;

  constructor(public payload: IVehicleWorkOrderBalloon) {}
}

export class UpdateSpeedLimitVehicleBalloon implements Action {
  public readonly type = EBalloonInfoActions.UpdateSpeedLimitVehicleBalloon;

  constructor(public payload: IVehicleWorkOrderBalloon) {}
}

export class UpdateVehicleBalloonScheduler implements Action {
  public readonly type = EBalloonInfoActions.UpdateVehicleBalloonScheduler;

  constructor(public payload: IVehicleWorkOrderBalloon) {}
}

export class GetReplayBalloon implements Action {
  public readonly type = EBalloonInfoActions.GetReplayBalloon;

  constructor(public payload: ICustomClickEventData<MarkerInfo>) {}
}

export class GetReplayBalloonSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetReplayBalloonSuccess;

  constructor(public payload: IReplayBalloon) {}
}

export class GetPolylineBalloon implements Action {
  public readonly type = EBalloonInfoActions.GetPolylineBalloon;

  constructor(public payload: IPolylineHoverParams) {}
}

export class GetPolylineBalloonSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetPolylineBalloonSuccess;

  constructor(public payload: IPolylineBalloon) {}
}

export class GetClusterBalloon implements Action {
  public readonly type = EBalloonInfoActions.GetClusterBalloon;

  constructor(public payload: IClusterClickEvent) {}
}

export class GetClusterBalloonSuccess implements Action {
  public readonly type = EBalloonInfoActions.GetClusterBalloonSuccess;

  constructor(public payload: IClusterBalloon) {}
}

export type BalloonInfoActions =
  | CloseBalloon
  | GetHarshDrivingEventBalloon
  | GetHarshDrivingEventBalloonSuccess
  | GetGarminStopBalloon
  | GetGarminStopBalloonSuccess
  | GetLabel
  | ClearLabel
  | GetLabelSuccess
  | GetLabels
  | GetLabelsSuccess
  | GetPlaceBalloon
  | GetPlaceBalloonSuccess
  | GetVehicleBalloon
  | GetVehicleBalloonSuccess
  | GetWorkOrderBalloon
  | GetWorkOrderBalloonSuccess
  | GetAssetBalloon
  | GetAssetBalloonSuccess
  | ShowBalloon
  | UpdateVehicleBalloon
  | UpdateVehicleBalloonAddress
  | UpdateVehicleBalloonWorkingTimeDirective
  | UpdateSpeedLimitVehicleBalloon
  | UpdateVehicleBalloonScheduler
  | GetReplayBalloon
  | GetReplayBalloonSuccess
  | GetPolylineBalloon
  | GetPolylineBalloonSuccess
  | GetClusterBalloon
  | GetClusterBalloonSuccess;
