/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EAssetBatteryState {
  Normal = 1,
  Low = 2,
  Empty = 3,
  Missing = 4
}
