/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { envVarToBoolean } from '@fleetmatics/ui.utilities';
import { IAppConfig, IEnvVars } from './app-config.interfaces';
import { environment } from '../../environments/environment';

export const toBoolean = (value: any) => (/(true|1)/i.test(value) ? true : /(false|0)/i.test(value) ? false : !!value);
export const toNumber = (value: string) => Number.parseInt(value, 10);

export function configMapper(source: IEnvVars): IAppConfig {
  if (environment.devSsr || !environment.production) {
    return environment.config;
  }

  return {
    endpoints: {
      customerMetadataApiEndpoint: source.BASE_CUSTOMER_METADATA,
      plotApiEndpoint: source.BASE_PLOT,
      reportsApiEndpoint: source.BASE_REPORTS,
      uiSharedServicesApiEndpoint: source.BASE_SHARED_SERVICES,
      placesApiEndpoint: source.BASE_PLACES,
      balloonApiEndpoint: source.BASE_BALLOON,
      realTimeDataApiEndpoint: source.BASE_REAL_TIME_DATA,
      notificationsApiEndpoint: source.BASE_NOTIFICATION,
      planningSchedulingApiEndpoint: source.BASE_PLANNING_SCHEDULING,
      addressResolutionApiEndpoint: source.BASE_ADDRESS_RESOLUTION,
      deviceStatusApiEndpoint: source.BASE_DEVICE_STATUS,
      categoriesApiEndpoint: source.BASE_CATEGORIES,
      alertApiEndpoint: source.BASE_ALERT,
      videoEventApiEndpoint: source.BASE_VIDEO_EVENT,
      workingTimeDirectiveApiEndpoint: source.BASE_WORKING_TIME_DIRECTIVE,
      assetsApiEndpoint: source.BASE_ASSETS_API,
      roadsideAssistanceApiEndpoint: source.BASE_ROADSIDE_ASSISTANCE,
      schedulerApiEndpoint: source.BASE_SCHEDULER,
      searchlightApiEndpoint: source.BASE_SEARCH
    },
    websocketUrl: source.BASE_WEBSOCKET,
    legacyBaseUrl: source.BASE_LEGACY,
    googleMapsClientId: source.GOOGLE_MAPS_CLIENT_ID,
    googleMapsApiVersion: source.GOOGLE_MAPS_API_VERSION,
    revealFeature: environment.config.revealFeature,
    auth: {
      shouldKeepSessionAlive: environment.config.auth.shouldKeepSessionAlive,
      keepSessionAliveEndpoint: source.AUTH_KEEP_SESSION_ALIVE_ENDPOINT,
      loginPageUrl: source.LOGIN_PAGE_URL,
      legacyLogoutEndpoint: source.LEGACY_LOGOUT_ENDPOINT,
      whiteListedUrlsRegex: source.AUTH_WHITE_LISTED_URLS_REGEX ? source.AUTH_WHITE_LISTED_URLS_REGEX.split(',') : []
    },
    navbar: {
      appBaseUrl: source.APP_BASE_URL,
      assetsBaseHref: source.ASSETS_PATH,
      assetsDomain: source.BASE_ASSETS,
      endpoints: {
        notificationsApiEndpoint: source.BASE_NOTIFICATION,
        uiSharedServicesApiEndpoint: source.BASE_SHARED_SERVICES,
        customerMetadataApiEndpoint: source.BASE_CUSTOMER_METADATA
      },
      liveMapPendoGuideId: source.BASE_PENDO_WALKTHROUGH_ID,
      helpPanelEnabled: envVarToBoolean(source.HELPPANEL_ENABLED)
    },
    usabilla: {
      enabled: envVarToBoolean(source.USABILLA_ENABLED),
      usabillaParamsApiEndpoint: source.BASE_USABILLA
    },
    disableSsrApis: toBoolean(source.DISABLE_SSR_APIS),
    isWorkingTimeDirectiveEnabled: toBoolean(source.TOGGLE_WORKING_TIME_DIRECTIVE),
    videoEventPath: source.VIDEO_EVENT_PATH,
    roadsideAssistanceMicrositeUrl: source.ROADSIDE_ASSISTANCE_MICROSITE_URL,
    driverPlannerPagePath: source.DRIVER_PLANNER_PAGE_PATH,
    isPushServiceDisabled: toBoolean(source.DISABLE_PUSH_SERVICE),
    workingTimeDirectiveInterval: +source.WORKING_TIME_DIRECTIVE_INTERVAL,
    isSchedulerEnabled: toBoolean(source.TOGGLE_SCHEDULER),
    npsSurvey: {
      enabled: toBoolean(source.NPS_ENABLED),
      npsSurveyApiEndpoint: source.NPS_SURVEY_API_ENDPOINT
    },
    pendo: {
      enabled: envVarToBoolean(source.PENDO_ENABLED)
    },
    ssrGetMapBounds: {
      enabled: envVarToBoolean(source.SSR_GET_MAP_BOUND_ENABLED),
      timeoutMs: toNumber(source.SSR_GET_MAP_BOUND_TIMEOUT)
    },
    helpPanel: {
      inContactExternalChatUrl: source.INCONTACT_EXTERNAL_CHAT_URL,
      featureToggleUrl: source.FEATURETOGGLE_URL
    },
    notificationPipeLineSettings: {
      isPipelineEnabledForMicrosite: envVarToBoolean(source.NOTIFICATIONS_PIPELINE_ENABLED),
      notificationsApiPath: source.NOTIFICATIONS_PIPELINE_API
    },
    isReplaySearchEnabled: envVarToBoolean(source.REPLAY_SEARCH_ENABLED),
    isAssetsOnPanelEnabled: envVarToBoolean(source.ASSETS_ON_PANEL_ENABLED),
    replayRealtimePlotsEnabled: envVarToBoolean(source.REPLAY_REALTIME_PLOTS_ENABLED),
    replayUpdateThrottleMs: toNumber(source.REPLAY_UPDATE_THROTTLE_MS),
    notificationsTemporaryTimeoutMs: toNumber(source.NOTIFICATION_TEMPORARY_TIMEOUT_MS),
    clusteringByDefaultMaxPlots: toNumber(source.CLUSTERING_BY_DEFAULT_MAX_PLOTS),
    // Demo added config parameters:
    fleetTrackingBasicsPendoGuideId: source.FLEET_TRACKING_BASICS_PENDO_GUIDE_ID,
    safetyPendoGuideId: source.SAFETY_PENDO_GUIDE_ID,
    onTheGoPendoGuideId: source.ON_THE_GO_PENDO_GUIDE_ID,
    interstitialPendoGuideId: source.INTERSTITIAL_PENDO_GUIDE_ID,
    mobileWebPendoGuideId: source.MOBILE_WEB_PENDO_GUIDE_ID_V3,
    mobileWebUnguidedMapPendoId: source.MOBILE_WEB_UNGUIDED_MAP_PENDO_ID,
    analyticsScripts: {
      isHotJarEnabled: toBoolean(source.IS_HOT_JAR_ENABLED),
      isGoogleTagManagerEnabled: toBoolean(source.IS_GOOGLE_TAG_MANAGER_ENABLED),
      isConvertExperimentsEnabled: toBoolean(source.IS_CONVERT_EXPERIMENTS_ENABLED)
    },
    isFsdSchedulerDemoEnabled: toBoolean(source.IS_FSD_SCHEDULER_DEMO_ENABLED),
    PendoGuideFsdId: source.FSD_PENDO_GUIDE_ID,
    starter: {
      domain: source.STARTER_DOMAIN
    },
    isVerizonNewTickBrandingEnabled: toBoolean(source.IS_VERIZON_NEW_TICK_BRANDING_ENABLED)
  };
}
