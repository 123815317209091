/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService, isElementNull } from '@fleetmatics/ui.utilities';

import { IAddressResolutionHttpService } from './address-resolution-http.service.interface';
import { IReverseGeocodeRequest, IReverseGeocodeResponse } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';

@Injectable()
export class AddressResolutionHttpService implements IAddressResolutionHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.addressResolutionApiEndpoint;
  }

  public getReverseGeocode(requests: IReverseGeocodeRequest[]): Observable<IReverseGeocodeResponse[]> {
    let parameters = new HttpParams();
    requests.forEach((request, index) => {
      parameters = parameters.append(`Requests[${index}][key]`, request.key);
      if (!isElementNull(request.data.vid)) {
        parameters = parameters.append(`Requests[${index}][data][vid]`, request.data.vid.toString());
      }
      if (!isElementNull(request.data.adrid)) {
        parameters = parameters.append(`Requests[${index}][data][adrid]`, request.data.adrid.toString());
      }
      parameters = parameters.append(`Requests[${index}][data][Latitude]`, request.data.Latitude.toString());
      parameters = parameters.append(`Requests[${index}][data][Longitude]`, request.data.Longitude.toString());
    });

    return this._httpClient.post<IReverseGeocodeResponse[]>(`${this._rootUrl}/v2/addressresolution`, parameters);
  }
}
