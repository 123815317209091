/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IGarminStopHistory } from '../../models';

export interface IGarminStopHistoryState {
  history: IGarminStopHistory[];
}

export const initialGarminStopHistoryState: IGarminStopHistoryState = {
  history: []
};
