/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import {
  INotification,
  INotificationResponse,
  INotificationDetails,
  INotificationDetailsParams,
  IDeviceStatusResponse,
  IDeviceStatusRequest,
  INotificationClosingParams
} from '../../models';

export const enum ENotificationsActions {
  InitializeNotifications = '[Notifications] Initialize Notifications',
  AddNotifications = '[Notifications] Add notification',
  AddNotificationsSuccess = '[Notifications] Add notification Success',
  UpdateNotificationSuccess = '[Notifications] Update Notifications Success',
  DeleteNotification = '[Notifications] Delete notification',
  DeleteDeviceStatusNotification = '[Notifications] Delete Device Status Notification',
  DeleteAllNotifications = '[Notifications] Delete All notifications',
  DeletePanicNotification = '[Notifications] Delete panic notification',
  CloseNonPanicNotification = '[Notifications] non panic notification closed',
  ReviewPanic = '[Notifications] Panic Reviewed',
  AcknowledgePanic = '[Notifications] Panic Acknowledged',
  GetPersistedNotifications = '[Notifications] Get persisted notifications',
  GetPersistedNotificationsSuccess = '[Notifications] Get persisted notifications success',
  GetNotificationInfo = '[Notifications] Get notification info',
  GetNotificationInfoSuccess = '[Notifications] Get notification info success',
  ReviewAsIncorrect = '[Notifications] Review Device Status As Incorrect',
  ReviewAsIncorrectSuccess = '[Notifications] Review Device Status As Incorrect Success',
  ReviewAsCorrect = '[Notifications] Review Device Status As Correct',
  ReviewAsCorrectSuccess = '[Notifications] Review Device Status As Correct Success',
  ReviewAsSeen = '[Notifications] Review Device Status As Seen',
  ReviewAsSeenSuccess = '[Notifications] Review Device Status As Seen Success',
  ReviewDeviceStatusNotificationError = '[Notifications] Review Device Status Notification Error',
  SetupDeviceStatusNotificationsCleanup = '[Notifications] Setup Device Status Notifications Cleanup',
  NotificationsExpired = '[Notifications] Notifications Expired',
  StopNonPanicNotifications = '[Notifications] Stop Non Panic Notifications',
  StartNonPanicNotifications = '[Notifications] Start Non Panic Notifications'
}

export class InitializeNotifications implements Action {
  public readonly type = ENotificationsActions.InitializeNotifications;
}

export class AddNotifications implements Action {
  public readonly type = ENotificationsActions.AddNotifications;

  constructor(public payload: INotificationResponse | IDeviceStatusResponse) {}
}

export class AddNotificationsSuccess implements Action {
  public readonly type = ENotificationsActions.AddNotificationsSuccess;

  constructor(public payload: INotification) {}
}

export class UpdateNotificationSuccess implements Action {
  public readonly type = ENotificationsActions.UpdateNotificationSuccess;

  constructor(public payload: INotification) {}
}

export class ReviewDeviceStatusNotificationError implements Action {
  public readonly type = ENotificationsActions.ReviewDeviceStatusNotificationError;
}

export class GetPersistedNotifications implements Action {
  public readonly type = ENotificationsActions.GetPersistedNotifications;
}

export class GetPersistedNotificationsSuccess implements Action {
  public readonly type = ENotificationsActions.GetPersistedNotificationsSuccess;

  constructor(public payload: INotification[]) {}
}

export class GetNotificationInfo implements Action {
  public readonly type = ENotificationsActions.GetNotificationInfo;

  constructor(public payload: INotificationDetailsParams) {}
}

export class GetNotificationInfoSuccess implements Action {
  public readonly type = ENotificationsActions.GetNotificationInfoSuccess;

  constructor(public payload: INotificationDetails) {}
}

export class DeleteNotification implements Action {
  public readonly type = ENotificationsActions.DeleteNotification;

  constructor(public payload: string) {}
}

export class DeleteDeviceStatusNotification implements Action {
  public readonly type = ENotificationsActions.DeleteDeviceStatusNotification;

  constructor(public payload: string) {}
}

export class CloseNonPanicNotification implements Action {
  public readonly type = ENotificationsActions.CloseNonPanicNotification;

  constructor(public payload: INotificationClosingParams) {}
}

export class AcknowledgePanic implements Action {
  public readonly type = ENotificationsActions.AcknowledgePanic;

  constructor(public payload: INotification) {}
}

export class ReviewAsCorrect implements Action {
  public readonly type = ENotificationsActions.ReviewAsCorrect;

  constructor(public payload: IDeviceStatusRequest) {}
}

export class ReviewAsCorrectSuccess implements Action {
  public readonly type = ENotificationsActions.ReviewAsCorrectSuccess;

  constructor(public payload: INotification) {}
}

export class ReviewAsIncorrect implements Action {
  public readonly type = ENotificationsActions.ReviewAsIncorrect;

  constructor(public payload: IDeviceStatusRequest) {}
}

export class ReviewAsIncorrectSuccess implements Action {
  public readonly type = ENotificationsActions.ReviewAsIncorrectSuccess;

  constructor(public payload: INotification) {}
}

export class ReviewAsSeen implements Action {
  public readonly type = ENotificationsActions.ReviewAsSeen;

  constructor(public payload: IDeviceStatusRequest) {}
}

export class ReviewAsSeenSuccess implements Action {
  public readonly type = ENotificationsActions.ReviewAsSeenSuccess;

  constructor(public payload: INotification) {}
}

export class ReviewPanic implements Action {
  public readonly type = ENotificationsActions.ReviewPanic;

  constructor(public payload: INotification) {}
}

export class DeleteAllNotifications implements Action {
  public readonly type = ENotificationsActions.DeleteAllNotifications;
}

export class DeletePanicNotification implements Action {
  public readonly type = ENotificationsActions.DeletePanicNotification;

  constructor(public payload: number) {}
}

export class SetupDeviceStatusNotificationsCleanup implements Action {
  public readonly type = ENotificationsActions.SetupDeviceStatusNotificationsCleanup;
}

export class NotificationsExpired implements Action {
  public readonly type = ENotificationsActions.NotificationsExpired;

  constructor(public payload: INotification[]) {}
}

export class StopNonPanicNotifications implements Action {
  public readonly type = ENotificationsActions.StopNonPanicNotifications;
}

export class StartNonPanicNotifications implements Action {
  public readonly type = ENotificationsActions.StartNonPanicNotifications;
}

export type NotificationsActions =
  | InitializeNotifications
  | AddNotificationsSuccess
  | AddNotifications
  | UpdateNotificationSuccess
  | DeleteNotification
  | DeleteDeviceStatusNotification
  | DeleteAllNotifications
  | DeletePanicNotification
  | CloseNonPanicNotification
  | AcknowledgePanic
  | ReviewPanic
  | GetPersistedNotifications
  | GetPersistedNotificationsSuccess
  | GetNotificationInfo
  | GetNotificationInfoSuccess
  | ReviewAsCorrect
  | ReviewAsCorrectSuccess
  | ReviewAsIncorrect
  | ReviewAsIncorrectSuccess
  | ReviewAsSeen
  | ReviewAsSeenSuccess
  | ReviewDeviceStatusNotificationError
  | SetupDeviceStatusNotificationsCleanup
  | NotificationsExpired
  | StopNonPanicNotifications
  | StartNonPanicNotifications;
