/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DemoDataResolverService } from '../services';
import { map } from 'rxjs/operators';

@Injectable()
export class GeofenceHttpInterceptor implements HttpInterceptor {
  constructor(private readonly _demoDataResolver: DemoDataResolverService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.endsWith('/Plot/Geofences/')) {
      return this._demoDataResolver.isReady$.pipe(
        map(() => {
          const geofencePlots = this._demoDataResolver.initialDemoGeofenceData.map(data => data.plot);
          return new HttpResponse({ body: { Data: geofencePlots }, status: 200 });
        })
      );
    }

    return next.handle(req);
  }
}
