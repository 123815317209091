<fm-search-light [entityTypes]="entityTypes" [input]="searchLightInput" [options]="options" [panel]="searchLightPanel"></fm-search-light>

<fm-search-light-input
  elementId="txt-search-light-form"
  #searchLightInput="fmSearchLightInput"
  [initialValue]="initialDisplayValue"
  [classes]="cssClasses"
  [placeholder]="placeholder"
  [showIcon]="true"
  (closed)="onClose()"
  (searchTextChange)="onSearchTextChange($event)"
></fm-search-light-input>

<fm-search-light-panel
  #searchLightPanel="fmSearchLightPanel"
  [containerWidth]="panelWidth"
  [errorMessage]="errorMessage"
  [minCharsMessage]="minCharsMessage"
  [noResultMessage]="noResultMessage"
  (resultSelected)="onModelChanged($event)"
></fm-search-light-panel>
