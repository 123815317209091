/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IApiResponseDto, ISendToGarminParams, INavigationEnabledVehiclesResponse } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';
import { IPlanningSchedulingGarminHttpService } from './planning-scheduling-garmin-http.service.interface';

@Injectable()
export class PlanningSchedulingGarminHttpService implements IPlanningSchedulingGarminHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = `${_configService.config.endpoints.planningSchedulingApiEndpoint}/Garmin`;
  }

  public getNavigationEnabledVehicles(): Observable<INavigationEnabledVehiclesResponse[]> {
    return this._httpClient
      .get<IApiResponseDto<INavigationEnabledVehiclesResponse[]>>(`${this._rootUrl}/NavigationEnabledVehiclesDropdown`)
      .pipe(map(response => response.Data));
  }

  public sendNavigationStop(parameters: ISendToGarminParams): Observable<string> {
    const params = {
      vehicleId: parameters.garminInfo.vehicleId,
      navigationStop: {
        address: parameters.addressInfo,
        description: parameters.garminInfo.description
      }
    };

    return this._httpClient.post<string>(`${this._rootUrl}/SendNavigationStop/`, params);
  }
}
