/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { IVehiclePlotTimerFormatService } from './vehicle-plot-timer-format.service.interface';

@Injectable({
  providedIn: 'root'
})
export class VehiclePlotTimerFormatService implements IVehiclePlotTimerFormatService {
  public format(duration = 0, additionalTime = 0): string {
    let formattedString = '';
    if (!isElementNull(duration) && !isElementNull(additionalTime)) {
      duration = duration + additionalTime;

      let seconds: number | string = parseInt((duration % 60).toString(), 10),
        minutes: number | string = parseInt(((duration / 60) % 60).toString(), 10),
        hours: number | string = parseInt(((duration / (60 * 60)) % 24).toString(), 10);

      hours = `${hours}`.padStart(2, '0');
      minutes = `${minutes}`.padStart(2, '0');
      seconds = `${seconds}`.padStart(2, '0');

      formattedString = `${hours}:${minutes}:${seconds}`;
    }
    return formattedString;
  }
}
