/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EFuelEfficiencyConstants {
  LitresPer100kmToKilometersPerLitre = 100,
  LitresPer100kmToMilesPerGallonUS = 235.2145833,
  LitresPer100kmToMilesPerGallonUK = 282.4809363,
  LitresPer100kmToKilometersPerGallonUS = 378.541178
}
