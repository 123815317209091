/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isElementNull } from '@fleetmatics/ui.utilities';

import {
  EWorkingTimeDirectiveActions,
  WorkingTimeDirectiveActions,
  GetLayoutPreferencesSuccess,
  ELayoutPreferencesActions
} from '../actions';
import { initialWorkingTimeDirectiveState, IWorkingTimeDirectiveState } from '../state';
import { IDriverStatus } from '../../models';

export function workingTimeDirectiveReducer(
  state = initialWorkingTimeDirectiveState,
  action: WorkingTimeDirectiveActions | GetLayoutPreferencesSuccess
): IWorkingTimeDirectiveState {
  switch (action.type) {
    case ELayoutPreferencesActions.GetLayoutPreferencesSuccess: {
      return {
        ...state,
        filter: action.payload.mapOptions.workingTimeDirective.filter,
        filterBreakDue: action.payload.mapOptions.workingTimeDirective.filterBreakDue,
        sortType: action.payload.mapOptions.workingTimeDirective.sortType
      };
    }
    case EWorkingTimeDirectiveActions.GetDriversStatusSuccess:
      return {
        ...state,
        driverStatusData: action.payload
      };
    case EWorkingTimeDirectiveActions.UpdateDriverWorkingStateFilter:
      return {
        ...state,
        filter: action.payload
      };
    case EWorkingTimeDirectiveActions.UpdateIsBreakDueSelectedFilter:
      return {
        ...state,
        filterBreakDue: action.payload
      };
    case EWorkingTimeDirectiveActions.UpdateDriverStatusSortType:
      return {
        ...state,
        sortType: action.payload
      };
    case EWorkingTimeDirectiveActions.GetVehiclesTachoStatusSuccess: {
      return {
        ...state,
        driverStatusData: state.driverStatusData.map(driverStatus => {
          if (isElementNull(driverStatus.vehicleTachoInfo.status)) {
            return driverStatus;
          }

          const updatedTachoInfo = action.payload.find(item => item.status.VehicleId === driverStatus.vehicleTachoInfo.status.VehicleId);
          if (isElementNull(updatedTachoInfo)) {
            return driverStatus;
          }

          return {
            ...driverStatus,
            vehicleTachoInfo: {
              ...driverStatus.vehicleTachoInfo,
              status: updatedTachoInfo.status
            }
          };
        })
      };
    }
    case EWorkingTimeDirectiveActions.StartTachoCardHighlight:
    case EWorkingTimeDirectiveActions.StopTachoCardHighlight: {
      const updatedDriverStatusesData = state.driverStatusData;
      for (let i = 0; i < updatedDriverStatusesData.length; i++) {
        const driverStatus = updatedDriverStatusesData[i];
        if (
          !isElementNull(driverStatus.vehicleTachoInfo.status) &&
          driverStatus.vehicleTachoInfo.status.VehicleId === action.payload.status.VehicleId
        ) {
          updatedDriverStatusesData[i] = {
            ...driverStatus,
            vehicleTachoInfo: {
              ...driverStatus.vehicleTachoInfo,
              isUpdating: action.payload.isUpdating
            }
          };
        }
      }

      return {
        ...state,
        driverStatusData: [...updatedDriverStatusesData]
      };
    }
    case EWorkingTimeDirectiveActions.UpdateTachoVehicleCoordinates: {

      const updatedDriverStatusesData: IDriverStatus[] = state.driverStatusData.map((item) => {
        if (
          !isElementNull(item.vehicleTachoInfo.status) &&
          item.vehicleTachoInfo.status.VehicleId === action.payload.id
        ) {
          return {
            ...item,
            markerInfo: {
              ...item.markerInfo,
              latLng: action.payload.latLng
            }
          };
        }
        return item;
      });

      return {
        ...state,
        driverStatusData: [...updatedDriverStatusesData]
      };
    }
    default:
      return state;
  }
}
