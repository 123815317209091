/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { ISearchLightResultParams, IVehiclePlot } from '../../models';
import { ISearchLightResult } from '../../../searchlight';

export enum ESearchLightActions {
  SelectSearchResult = '[SearchLight] Select Search Result',
  SelectGeofence = '[SearchLight] Select Geofence',
  SelectHotspot = '[SearchLight] Select Hotspot',
  SelectWorkOrder = '[SearchLight] Select Work Order',
  GetSearchLightVehiclePlot = '[SearchLight] Get SearchLight Vehicle Plot',
  GetSearchLightVehiclePlotSuccess = '[SearchLight] Get SearchLight Vehicle Plot Success',
  RemoveSearchLightResultFromMap = '[SearchLight] Remove SearchLight Result From Map',
  GetSearchLightGeofencePlot = '[SearchLight] Get SearchLight Geofence Plot',
  GetSearchLightHotspotPlot = '[SearchLight] Get SearchLight Hotspot Plot',
  GetSearchLightWorkOrderPlot = '[SearchLight] Get SearchLight Work Order Plot'
}

export class SelectSearchResult implements Action {
  public readonly type = ESearchLightActions.SelectSearchResult;

  constructor(public payload: ISearchLightResult) {}
}

export class SelectGeofence implements Action {
  public readonly type = ESearchLightActions.SelectGeofence;

  constructor(public payload: ISearchLightResultParams) {}
}

export class SelectHotspot implements Action {
  public readonly type = ESearchLightActions.SelectHotspot;

  constructor(public payload: ISearchLightResultParams) {}
}

export class SelectWorkOrder implements Action {
  public readonly type = ESearchLightActions.SelectWorkOrder;

  constructor(public payload: ISearchLightResultParams) {}
}

export class GetSearchLightVehiclePlot implements Action {
  public readonly type = ESearchLightActions.GetSearchLightVehiclePlot;

  constructor(public payload: string) {}
}

export class GetSearchLightVehiclePlotSuccess implements Action {
  public readonly type = ESearchLightActions.GetSearchLightVehiclePlotSuccess;

  constructor(public payload: IVehiclePlot[]) {}
}

export class RemoveSearchLightResultFromMap implements Action {
  public readonly type = ESearchLightActions.RemoveSearchLightResultFromMap;

  constructor(public payload: number) {}
}

export class GetSearchLightGeofencePlot implements Action {
  public readonly type = ESearchLightActions.GetSearchLightGeofencePlot;

  constructor(public payload: ISearchLightResultParams) {}
}

export class GetSearchLightHotspotPlot implements Action {
  public readonly type = ESearchLightActions.GetSearchLightHotspotPlot;

  constructor(public payload: ISearchLightResultParams) {}
}

export class GetSearchLightWorkOrderPlot implements Action {
  public readonly type = ESearchLightActions.GetSearchLightWorkOrderPlot;

  constructor(public payload: ISearchLightResultParams) {}
}

export type SearchLightActions =
  | SelectSearchResult
  | SelectGeofence
  | SelectHotspot
  | SelectWorkOrder
  | GetSearchLightVehiclePlot
  | GetSearchLightVehiclePlotSuccess
  | RemoveSearchLightResultFromMap
  | GetSearchLightGeofencePlot
  | GetSearchLightHotspotPlot
  | GetSearchLightWorkOrderPlot;
