/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NotificationsActions, ENotificationsActions } from '../actions';
import { INotificationsState, initialNotificationsState } from '../state';
import { ENotificationTypes, EDeviceStatusSnackbarTypes, INotification } from '../../models';

export function NotificationsReducer(state = initialNotificationsState, action: NotificationsActions): INotificationsState {
  switch (action.type) {
    case ENotificationsActions.AddNotificationsSuccess: {
      // In rare edge cases AddNotificationsSuccess can be called twice for the same vehicle

      let notifications: INotification[];

      const index = state.notifications.findIndex(n => n.id === action.payload.id);
      if (index === -1) {
        // it is a new notificaion
        notifications = [...state.notifications, action.payload];
      } else {
        // notification exists
        notifications = [...state.notifications];
        // take the item with the most current timestamp
        if (action.payload.lastUpdate > notifications[index].lastUpdate) {
          notifications[index] = action.payload;
        }
      }

      return {
        ...state,
        notifications: notifications
      };
    }

    case ENotificationsActions.UpdateNotificationSuccess: {
      return {
        ...state,
        notifications: state.notifications.map(notification => (notification.id === action.payload.id ? action.payload : notification))
      };
    }

    case ENotificationsActions.GetPersistedNotificationsSuccess: {
      return {
        ...state,
        notifications: [...action.payload]
      };
    }

    case ENotificationsActions.GetNotificationInfoSuccess: {
      return {
        ...state,
        notificationDetail: action.payload
      };
    }

    case ENotificationsActions.DeleteNotification: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => !(action.payload === notification.id && notification.type !== ENotificationTypes.deviceStatusChange)
        )
      };
    }

    case ENotificationsActions.DeleteDeviceStatusNotification: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => !(action.payload === notification.id && notification.type === ENotificationTypes.deviceStatusChange)
        )
      };
    }

    case ENotificationsActions.ReviewAsCorrectSuccess: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload.id || notification.troubleCode !== action.payload.troubleCode
        ),
        snackbarSuccess: EDeviceStatusSnackbarTypes.correct
      };
    }

    case ENotificationsActions.ReviewAsIncorrectSuccess: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload.id || notification.troubleCode !== action.payload.troubleCode
        ),
        snackbarSuccess: EDeviceStatusSnackbarTypes.incorrect
      };
    }

    case ENotificationsActions.ReviewAsSeenSuccess: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload.id || notification.troubleCode !== action.payload.troubleCode
        ),
        snackbarSuccess: EDeviceStatusSnackbarTypes.seen
      };
    }

    case ENotificationsActions.DeletePanicNotification: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => action.payload !== notification.vehicleId || notification.type !== ENotificationTypes.panic
        )
      };
    }

    case ENotificationsActions.DeleteAllNotifications: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification =>
            ENotificationTypes.panic === notification.type ||
            (ENotificationTypes.deviceStatusChange === notification.type && notification.exclusiveActionType === null)
        )
      };
    }

    case ENotificationsActions.ReviewDeviceStatusNotificationError: {
      return {
        ...state,
        snackbarError: true
      };
    }

    case ENotificationsActions.NotificationsExpired: {
      return {
        ...state,
        notifications: state.notifications.filter(
          currentNotification =>
            currentNotification.type !== ENotificationTypes.deviceStatusChange ||
            action.payload.every(notification => currentNotification.id !== notification.id)
        )
      };
    }

    case ENotificationsActions.StopNonPanicNotifications: {
      return {
        ...state,
        shouldShowNonPanicNotifications: false
      };
    }

    case ENotificationsActions.StartNonPanicNotifications: {
      return {
        ...state,
        shouldShowNonPanicNotifications: true
      };
    }

    default:
      return state;
  }
}
