<div class="notification"
  id="notification"
  [@onInteraction]="dataState"
  [ngClass]="{'alert': !isPanic,'panic': isPanic}"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
  (click)="notificationClicked(notification)">
  <div class="notification-header">
    <div class="notification-header__content">
      <mat-icon svgIcon="ds-ic-bell"
        class="notification-header__icon mat-icon-size-xs"
        *ngIf="!isPanic">
      </mat-icon>
      <p class="notification-header__title"
        *ngIf="!isPanic && !isDeviceStatus">{{ notification.title }}</p>
    </div>
    <mat-icon svgIcon="ds-ic-close"
      id="btn-closeNotification"
      class="notification-header__icon notification-header__icon-close mat-icon-size-xs"
      *ngIf="!isPanic"
      (click)="close($event)">
    </mat-icon>
  </div>
  <p class="notification__content"
    *ngIf="!isPanic && !isDeviceStatus; else deviceStatus">{{ notification.content }}</p>
  <span *ngIf="isPanic && notification.content"
    [outerHTML]="notification.content | boldWrap:'panic':'mode':'notification__content'"></span>
  <div *ngIf="isPanic"
    class="notification-panic-btn__container">
    <ds-button class="notification-panic-btn__container--close"
      id="btn-closePanic"
      i18n-text="@@S_CLOSE"
      text="CLOSE"
      componentStyle="tertiary"
      size="small"
      (click)="close($event)">
    </ds-button>
    <ds-button id="btn-showPanicOnMap"
      i18n-text="@@S_REVIEW"
      text="REVIEW"
      componentStyle="secondary"
      size="small"
      (click)="notificationClicked(notification)">
    </ds-button>
  </div>
</div>

<ng-template #deviceStatus>
  <p i18n="@@S_DEVICESTATUS_INSTALLATION_ERROR_NOTIFICATION"
    class="notification__content notification__content-device-status"
    *ngIf="isInstallationIssue">Vehicle: {{ notification.title }} - Our Customer Care Team is investigating a potential installation issue.</p>
  <p i18n="@@S_DEVICESTATUS_INCORRECT_STATUS_NOTIFICATION_INDEXED"
    class="notification__content notification__content-device-status"
    *ngIf="isIncorrect">{{ notification.exclusiveActorFirstName }} {{ notification.exclusiveActorLastName }} reported a problem with the tracking unit in vehicle {{ notification.title }}. Our Customer Care Team is investigating.</p>
  <p i18n="@@S_DEVICESTATUS_CORRECT_STATUS_NOTIFICATION_INDEXED"
    class="notification__content notification__content-device-status"
    *ngIf="isCorrect">{{ notification.exclusiveActorFirstName }} {{ notification.exclusiveActorLastName }} has confirmed that vehicle {{ notification.title }} started to be towed at {{ notification.messageTimeFormatted }}</p>
</ng-template>