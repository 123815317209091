/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

export class DemoConstants {
  /* Local storage keys */
  public static readonly LocalStoragePendoUserGuidKey = 'demo.pendoUserGuid';
  public static readonly LocalStorageDemoProgressKey = 'demo.progress';
  public static readonly LocalStorageMarketingPreferenceKey = 'demo.marketingPreference';
  public static readonly LocalStorageDemoVariationContentKey = 'demo.demoVariationContent';
  public static readonly LocalStorageDemoCityKey = 'demo.city';

  /* Global events */
  public static readonly OnGuideStepActivated = 'OnGuideStepActivated';
  public static readonly OnGuideStepDismissed = 'OnGuideStepDismissed';

  /* Pendo guide elements */
  public static readonly PendoGuideFleetTrackingBasicsId = 'pendo-guide-fleet-tracking-basics';
  public static readonly PendoGuideSafetyId = 'pendo-guide-safety';
  public static readonly PendoGuideOnTheGoId = 'pendo-guide-on-the-go';
  public static readonly PendoGuideInterstitialGuideId = 'pendo-guide-interstitial';
  public static readonly PendoGuideMobileWebId = 'pendo-guide-mobile-web-v3';
  public static readonly PendoGuideMobileWebUnguidedMapId = 'pendo-mobile-web-unguided-map';
  public static readonly PendoGuideFsdId = 'pendo-guide-fsd';

  /* side menu item subtitle class */
  public static readonly SideMenuItemSubtitleClass = 'fm-sidenav-menu-item-subtitle';

  /* Modal ids */
  public static readonly SplashScreenModalId = 'SplashScreen';
  public static readonly InterstitialScreenModalId = 'InterstitialScreen';
  public static readonly MobileWebSplashScreenModalId = 'MobileWebSplashScreen';
  public static readonly MobileWebFreeTrialModalId = 'MobileWebFreeTrialModal';
  public static readonly MobileWebShareDemoModalId = 'MobileWebShareDemoModal';

  // query params
  public static readonly VariationParam = 'reveal';
  public static readonly CityParam = 'city';
}
