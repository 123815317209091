/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, AfterViewInit, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ModalService } from '../../services/modal.service';
import { IAppState } from '../../../core/store/state';
import { EDemoStories, IDemoVariationContent } from '../../models';
import { UpdateStoryProgress, StartStory } from '../../store/demo.actions';
import { getDemoVariationContent } from '../../store/demo.selectors';
import { DemoConstants } from '../../constants';

@Component({
  selector: 'fm-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashScreenComponent implements OnInit, AfterViewInit {
  public EDemoStories = EDemoStories;
  public demoVariationContent$: Observable<IDemoVariationContent>;

  private _startGuidedTour = false;

  @ViewChild('splashModalContent', { static: false }) splashModalTemplate: TemplateRef<any>;

  constructor(private readonly _modalService: ModalService, private readonly _store: Store<IAppState>) {
    this._modalService.closed.pipe(take(1)).subscribe(() => {
      this._advanceSplashScreenStory();
      if (!this._startGuidedTour) {
        this._store.dispatch(new StartStory(EDemoStories.InterstitialGuide));
      }
    });
  }

  ngOnInit(): void {
    this.demoVariationContent$ = this._store.pipe(select(getDemoVariationContent));
  }

  ngAfterViewInit() {
    setTimeout(
      () =>
        this._modalService.open(DemoConstants.SplashScreenModalId, this.splashModalTemplate, {
          customClass: 'splash-screen-modal',
          disableResponsiveness: true
        }),
      0
    );
  }

  public closeModal(): void {
    this._modalService.close();
  }

  public showGuide(demoStory: EDemoStories): void {
    this._startGuidedTour = true;
    this._modalService.close();
    this._store.dispatch(new StartStory(demoStory));
  }

  private _advanceSplashScreenStory() {
    this._store.dispatch(new UpdateStoryProgress({ story: EDemoStories.SplashScreen, step: 2 }));
  }
}
