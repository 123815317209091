/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { RoadsideAssistanceActions, ERoadsideAssistanceActions } from '../actions';
import { initialRoadsideAssistanceState, IRoadsideAssistanceState } from '../state';

export function roadsideAssistanceReducer(
  state = initialRoadsideAssistanceState,
  action: RoadsideAssistanceActions
): IRoadsideAssistanceState {
  switch (action.type) {
    case ERoadsideAssistanceActions.OpenRoadsideAssistanceModalSuccess: {
      return {
        ...state,
        isModalOpen: true,
        vin: action.payload
      };
    }

    case ERoadsideAssistanceActions.CloseRoadsideAssistanceModal: {
      return {
        ...state,
        isModalOpen: false,
        vin: null
      };
    }

    case ERoadsideAssistanceActions.GetUserRoadsideAssistanceStatusSuccess: {
      return {
        ...state,
        status: action.payload
      };
    }

    case ERoadsideAssistanceActions.OpenRoadsideAssistanceMarketingModal: {
      return {
        ...state,
        isModalOpen: true
      };
    }

    default:
      return state;
  }
}
