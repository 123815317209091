/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IDemoState } from './demo.state';
import { EDemoStories } from '../models';

export const demoState = createFeatureSelector<IDemoState>('demoFeature');

export const getDemoVehiclePlots = createSelector(demoState, state => state.plots);

export const getDemoVehiclePlotById = (plotId: number) => createSelector(demoState, state => state.plots.find(plot => plot.id === plotId));

export const getDemoHierarchy = createSelector(demoState, state => state.demoHierarchy);

export const getDemoHierarchyItems = createSelector(demoState, state => state.demoHierarchy?.hierarchyItems);

export const getDemoProgress = createSelector(demoState, state => state.progress);

export const getMarketingContent = createSelector(demoState, state => state.marketingTextContent);

export const getDemoVariationContent = createSelector(demoState, state => state.demoVariationContent);

export const getDemoStoryProgress = (storyName: EDemoStories) =>
  createSelector(demoState, state => state.progress.stories.find(story => story.name === storyName));

export const getIsInterstitialScreenOpen = createSelector(demoState, state => state.isInterstitialScreenOpen);

export const getIsInterstitialScreenOffset = createSelector(demoState, state => state.interstitialScreenOffset);
