/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { FmCookieService, isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import { ILayoutPreferencesService } from './layout-preferences.service.interface';
import { ILayoutPreferencesState } from '../../store/state';

@Injectable()
export class LayoutPreferencesService implements ILayoutPreferencesService {
  private readonly _cookieName = 'LayoutPreferences';

  constructor(private readonly _cookieService: FmCookieService) {}

  public get(defaultPreferences: ILayoutPreferencesState): ILayoutPreferencesState {
    const rawPreferences = this._cookieService.getCookie(this._cookieName);
    if (!isStringNullOrEmpty(rawPreferences)) {
      const storedPreferences: ILayoutPreferencesState = JSON.parse(rawPreferences);
      return {
        ...defaultPreferences,
        ...storedPreferences,
        mapOptions: { ...defaultPreferences.mapOptions, ...storedPreferences.mapOptions }
      };
    }
    return defaultPreferences;
  }

  public save(preferences: ILayoutPreferencesState): void {
    const oneYear = 365 * 24 * 60; // minutes in a Year
    this._cookieService.setCookie(this._cookieName, JSON.stringify(preferences), oneYear);
  }
}
