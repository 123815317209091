/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */
import {
  IVehicleDriverPanelPreferences,
  EVehicleDriverPanelTabs,
  IMapOptionsPanelPreferences,
  IVehicleListPanelPreferences,
  EVehicleSelectionMode,
  EGarminStopStatusGroup,
  EVehiclesSortType,
  EMapTypeId,
  EDriverStatusSortType
} from '../../models';

export interface ILayoutPreferencesState {
  mapOptions: IMapOptionsPanelPreferences;
  vehiclesSortType: EVehiclesSortType;
  vehicleDriverPanel: IVehicleDriverPanelPreferences;
  vehicleListPanel: IVehicleListPanelPreferences;
  isLegacyLayoutPreferencesSynced: boolean;
}

export const initialLayoutPreferencesState: ILayoutPreferencesState = null;

export const defaultLayoutPreferences: ILayoutPreferencesState = {
  mapOptions: {
    isSuggestedGeofencesEnabled: false,
    isTrafficEnabled: false,
    isGeofencesEnabled: false,
    isGarminStopsEnabled: false,
    isWorkOrdersEnabled: false,
    isClusteringEnabled: false,
    isShowLabelsEnabled: false,
    isShowTachoInformationEnabled: false,
    isShowVehicleBalloonLinksEnabled: true,
    advancedOptions: {
      placeCategoryIds: null,
      garminStopStatusesIdsSelected: [
        EGarminStopStatusGroup.QUEUED,
        EGarminStopStatusGroup.RECEIVED,
        EGarminStopStatusGroup.READ,
        EGarminStopStatusGroup.EN_ROUTE,
        EGarminStopStatusGroup.COMPLETED
      ]
    },
    mapType: EMapTypeId.ROADMAP,
    workingTimeDirective: {
      filter: null,
      sortType: EDriverStatusSortType.StartTime,
      filterBreakDue: false
    }
  },
  vehiclesSortType: EVehiclesSortType.Vehicle,
  vehicleDriverPanel: {
    activeTab: EVehicleDriverPanelTabs.VehicleStatus,
    isCollapsed: false
  },
  vehicleListPanel: {
    selectionMode: EVehicleSelectionMode.Vehicles
  },
  isLegacyLayoutPreferencesSynced: false
};
