/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EPlotType {
  hotspot = 0,
  geofence = 1,
  workOrder = 2,
  garmin = 3,
  vehicle = 4,
  harshDrivingEvent = 5,
  balloon = 6,
  asset = 7,
  replay = 8,
  polyline = 9,
  poweredAsset = 10,
  cluster = 11
}
