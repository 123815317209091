/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { map, filter, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ModalService } from '../../services/modal.service';
import { IAppState } from '../../../core/store/state';
import { EDemoStories, DemoStoryProgress, IMarketingTextContent, IDemoVariationContent } from '../../models';
import {
  StartStory,
  InterstitialScreenMoveLeft,
  InterstitialScreenMoveRight,
  ShowInterstitialScreen,
  CloseInterstitialScreen
} from '../../store/demo.actions';
import { DemoConstants } from '../../constants';
import {
  getMarketingContent,
  getIsInterstitialScreenOpen,
  getDemoStoryProgress,
  getDemoVariationContent
} from '../../store/demo.selectors';

@Component({
  selector: 'fm-interstitial-screen',
  templateUrl: './interstitial-screen.component.html',
  styleUrls: ['./interstitial-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterstitialScreenComponent implements OnInit {
  public isBasicCompleted$: Observable<boolean>;
  public isFsdCompleted$: Observable<boolean>;
  public isCostCompleted$: Observable<boolean>;
  public isSafetyCompleted$: Observable<boolean>;
  public isMobileCompleted$: Observable<boolean>;
  public actionsContent$: Observable<IMarketingTextContent>;
  public demoVariationContent$: Observable<IDemoVariationContent>;
  public EDemoStories = EDemoStories;

  @ViewChild('interstitialModalContent', { static: false }) interstitialModalTemplate: TemplateRef<any>;

  private _startGuidedTour = false;

  constructor(
    private readonly _modalService: ModalService,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _store: Store<IAppState>
  ) {}

  ngOnInit() {
    this.isBasicCompleted$ = this._isGuideCompleted(EDemoStories.FleetBasics);
    this.isFsdCompleted$ = this._isGuideCompleted(EDemoStories.FieldServiceDispatch);
    this.isCostCompleted$ = this._isGuideCompleted(EDemoStories.CostAndEfficiency);
    this.isSafetyCompleted$ = this._isGuideCompleted(EDemoStories.SafetyAlerts);
    this.isMobileCompleted$ = this._isGuideCompleted(EDemoStories.OnTheGoAlerts);
    this.actionsContent$ = this._store.pipe(select(getMarketingContent));
    this.demoVariationContent$ = this._store.pipe(select(getDemoVariationContent));

    this._store
      .pipe(select(getIsInterstitialScreenOpen), withLatestFrom(this.demoVariationContent$))
      .subscribe(([isOpen, variationContent]) => {
        if (isOpen && variationContent.currentVariation !== 'b') {
          this.showInterstitialScreen();
        }
      });

    setTimeout(() => {
      const elementMenuWrapper = this._document.getElementsByClassName('fm-menu__trigger-wrapper')[1] as HTMLElement;
      const elementMenu = this._document.getElementsByClassName('account-menu-avatar')[0] as HTMLElement;

      if (elementMenuWrapper && elementMenu) {
        elementMenuWrapper.onclick = () => this._store.dispatch(new ShowInterstitialScreen());
        elementMenuWrapper.onmouseover = () => {
          elementMenu.style.setProperty('background-color', '#0088CE', 'important');
        };
        elementMenuWrapper.onmouseout = () => {
          elementMenu.style.setProperty('background-color', '#000', 'important');
        };
      }
    }, 0);

    this._modalService.closed.pipe(filter(() => this._modalService.id === DemoConstants.InterstitialScreenModalId)).subscribe(() => {
      this._store.dispatch(new CloseInterstitialScreen());
      if (!this._startGuidedTour) {
        this._store.dispatch(new StartStory(EDemoStories.InterstitialGuide));
      }
    });
  }

  public showInterstitialScreen(): void {
    this._startGuidedTour = false;
    this._store.dispatch(new ShowInterstitialScreen());
    this._modalService.open(DemoConstants.InterstitialScreenModalId, this.interstitialModalTemplate, {
      customClass: 'interstitial-screen-modal',
      disableResponsiveness: true
    });
  }

  public showGuide(demoStory: EDemoStories): void {
    this._startGuidedTour = true;
    this._modalService.close();
    this._store.dispatch(new StartStory(demoStory));
  }

  public moveLeft(): void {
    this._store.dispatch(new InterstitialScreenMoveLeft());
  }

  public moveRight(): void {
    this._store.dispatch(new InterstitialScreenMoveRight());
  }

  private _isGuideCompleted(storyName: EDemoStories): Observable<boolean> {
    return this._store.pipe(
      select(getDemoStoryProgress(storyName)),
      map(story => DemoStoryProgress.isCompleted(story))
    );
  }
}
