/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export interface IModulesState {
  loadedModules: { [key: string]: boolean };
}

export const initialModulesState: IModulesState = {
  loadedModules: {}
};
