/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IPlotsState } from '../state';
import {
  IVehiclePlot,
  IHotspotPlot,
  IGeofencePlot,
  IGarminStopPlot,
  IWorkOrderPlot,
  IIndexableObject,
  IAssetPlot,
  ILabel,
  EPlotType,
  IStatusPanelItem
} from '../../models';

export const getPlotsState = createFeatureSelector<IPlotsState>('plots');

export const getVehiclePlots = createSelector(getPlotsState, (state: IPlotsState): IVehiclePlot[] => state.vehiclePlots);

export const getHotspotPlots = createSelector(getPlotsState, (state: IPlotsState): IHotspotPlot[] => state.hotspotPlots);

export const getGeofencePlots = createSelector(getPlotsState, (state: IPlotsState): IGeofencePlot[] => state.geofencePlots);

export const getGarminStopsPlots = createSelector(getPlotsState, (state: IPlotsState): IGarminStopPlot[] => state.garminStopsPlots);

export const getWorkOrdersPlots = createSelector(getPlotsState, (state: IPlotsState): IWorkOrderPlot[] => state.workOrdersPlots);

export const getAssetPlots = createSelector(getPlotsState, (state: IPlotsState): IAssetPlot[] => state.assetsPlots);

export const getAssetPlotLabels = createSelector(getAssetPlots, (state: IAssetPlot[]): ILabel[] => {
  return state.map(
    (assetPlot: IAssetPlot): ILabel => {
      return {
        id: assetPlot.id,
        customElements: [assetPlot.name],
        isMapLabel: false,
        mapEntityType: EPlotType.asset
      };
    }
  );
});

export const getVehiclePlotsTimers = createSelector(
  getPlotsState,
  (state: IPlotsState): IIndexableObject<number> => state.vehiclePlotsTimers
);

export const getStatusPanelPlots = createSelector(
  getVehiclePlots,
  getAssetPlots,
  (vehiclePlots: IVehiclePlot[], assetPlots: IAssetPlot[]): IStatusPanelItem[] => [...vehiclePlots, ...assetPlots] as IStatusPanelItem[]
);
