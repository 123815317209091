/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { DemoConstants } from '../../constants';
import { EDemoStories, IDemoVariationContent } from '../../models';
import { ModalService } from '../../services';
import { IAppState } from '../../../core/store/state';
import { StartStory, UpdateStoryProgress } from '../../store/demo.actions';
import { getDemoVariationContent } from '../../store/demo.selectors';

@Component({
  selector: 'fm-mobile-web-splash-screen',
  templateUrl: './mobile-web-splash-screen.component.html',
  styleUrls: ['./mobile-web-splash-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileWebSplashScreenComponent implements OnInit {
  public demoVariationContent$: Observable<IDemoVariationContent>;

  private _hasStoryStarted = false;

  @ViewChild('splashScreenModalContent', { static: false }) modalTemplate: TemplateRef<any>;

  constructor(private readonly _modalService: ModalService, private readonly _store: Store<IAppState>) {}

  ngOnInit(): void {
    this.demoVariationContent$ = this._store.pipe(select(getDemoVariationContent));

    setTimeout(
      () =>
        this._modalService.open(DemoConstants.MobileWebSplashScreenModalId, this.modalTemplate, {
          customClass: 'mobile-web-splash-screen',
          size: 'small',
          disableResponsiveness: true
        }),
      0
    );

    this._modalService.closed.pipe(take(1)).subscribe(() => {
      if (!this._hasStoryStarted) {
        this._startUnguidedStory();
      }
      this._advanceMobileWebSplashScreenStory();
    });
  }

  public showMobileWebGuide(): void {
    this._store.dispatch(new StartStory(EDemoStories.MobileWeb));
    this._hasStoryStarted = true;
    this._modalService.close();
  }

  private _advanceMobileWebSplashScreenStory(): void {
    this._store.dispatch(new UpdateStoryProgress({ story: EDemoStories.MobileWebSplashScreen, step: 2 }));
  }

  private _startUnguidedStory(): void {
    this._store.dispatch(new StartStory(EDemoStories.MobileWebUnguidedMap));
  }
}
