/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import {
  EMapTypeId,
  ILatLng,
  IRunReportParams,
  IViewGarminWindowParams,
  IEditVehicleParams,
  IEditDriverParams,
  IFindNearestParams,
  IEditPlaceParams,
  IWorkOrderParams,
  IViewMetricsParams,
  IZoomToMarkerParams,
  IEditAssetParams,
  IViewReplayParameters,
  IMapHierarchyInfo,
  IOverlayMarginsChangeParams
} from '../../models';
import { EMapAnimationState } from '../state';

export enum ELayoutActions {
  ToggleFullscreen = '[Layout] Toggle Fullscreen',
  UpdateIsFullscreen = '[Layout] Update Is Fullscreen',
  TriggerTextview = '[Layout] Trigger Textview',
  TriggerGarmin = '[Layout] Trigger Garmin',
  TriggerDriverDispatch = '[Layout] Trigger Driver Dispatch',
  TriggerFitToMap = '[Layout] Trigger Fit To Map',
  UpdateIsMapFitted = '[Layout] Update Is Map Fitted',
  SwitchMapType = '[Layout] Switch Map Type',
  TriggerFindNearest = '[Layout] Trigger Find Nearest',
  TriggerCreateGeofence = '[Layout] Trigger Create Geofence',
  TriggerCreateGeofenceOnVehicle = '[Layout] Trigger Create Geofence On Vehicle',
  TriggerGetDirectionsTo = '[Layout] Trigger Get Directions To',
  TriggerGetDirectionsFrom = '[Layout] Trigger Get Directions From',
  TriggerGetDirectionsToVehicle = '[Layout] Trigger Get Directions To Vehicle',
  TriggerGetDirectionsFromVehicle = '[Layout] Trigger Get Directions From Vehicle',
  ZoomToLocation = '[Layout] Zoom To Location',
  UpdateZoomToLocation = '[Layout] Update Zoom To Location',
  UpdateZoomToMarker = '[Layout] Update Zoom To Marker',
  TryUpdateZoomToMarker = '[Layout] Try Update Zoom To Marker',
  TriggerViewReplay = '[Layout] Trigger View Replay',
  TriggerRunReport = '[Layout] Trigger Run Report',
  TriggerEditDriver = '[Layout] Trigger Edit Driver',
  TriggerEditVehicle = '[Layout] Trigger Edit Vehicle',
  TriggerEditAsset = '[Layout] Trigger Edit Asset',
  TriggerSendStop = '[Layout] Trigger Send Stop',
  TriggerViewGarminWindow = '[Layout] Trigger View Garmin Window',
  UpdateVehicleAsDeselected = '[Layout] Update Vehicle As Deselected',
  UpdateVehicleAsDeselectedSuccess = '[Layout] Update Vehicle As Deselected Success',
  TriggerEditPlace = '[Layout] Trigger Edit Place',
  TriggerViewMetrics = '[Layout] Trigger View Metrics',
  TriggerWorkOrderDetails = '[Layout] Trigger Work Order Details',
  TriggerWorkOrderStatusHistory = '[Layout] Trigger Work Order Status History',
  RemoveZoomToMarker = '[Layout] Remove Zoom To Marker',
  OpenReplayOnLiveMap = '[Layout] Open Replay On Live Map',
  CloseReplayOnLiveMap = '[Layout] Close Replay On Live Map',
  UpdateAnimationState = '[Layout] Update Animation State',
  OpenReplayDatePickerPanel = '[Layout] Open Replay Date Picker Panel',
  CloseReplayDatePickerPanel = '[Layout] Close Replay Date Picker Panel',
  UpdateIsAppAnimationsEnabled = '[Layout] Update Is Animation Enabled',
  OverlayMarginsChanged = '[Layout] Overlay Margins Changed',
  UpdateViewReplayVehicleId = '[Layout] Update View Replay Vehicle Id',
  SetReplayPageTitleFromVehicleId = '[Layout] Set Replay Page Title From Vehicle Id',
  SetPageTitle = '[Layout] Set Page Title'
}

export class TriggerFindNearest implements Action {
  public readonly type = ELayoutActions.TriggerFindNearest;

  constructor(public payload: IFindNearestParams) {}
}

export class TriggerCreateGeofence implements Action {
  public readonly type = ELayoutActions.TriggerCreateGeofence;

  constructor(public payload: ILatLng) {}
}

export class TriggerCreateGeofenceForVehicle implements Action {
  public readonly type = ELayoutActions.TriggerCreateGeofenceOnVehicle;

  constructor(public payload: number) {}
}

export class TriggerGetDirectionsTo implements Action {
  public readonly type = ELayoutActions.TriggerGetDirectionsTo;

  constructor(public payload: ILatLng) {}
}

export class TriggerGetDirectionsFrom implements Action {
  public readonly type = ELayoutActions.TriggerGetDirectionsFrom;

  constructor(public payload: ILatLng) {}
}

export class TriggerGetDirectionsToVehicle implements Action {
  public readonly type = ELayoutActions.TriggerGetDirectionsToVehicle;

  constructor(public payload: number) {}
}

export class TriggerGetDirectionsFromVehicle implements Action {
  public readonly type = ELayoutActions.TriggerGetDirectionsFromVehicle;

  constructor(public payload: number) {}
}

export class ToggleFullscreen implements Action {
  public readonly type = ELayoutActions.ToggleFullscreen;
}

export class ZoomToLocation implements Action {
  public readonly type = ELayoutActions.ZoomToLocation;
}

export class UpdateZoomToLocation implements Action {
  public readonly type = ELayoutActions.UpdateZoomToLocation;

  constructor(public payload: ILatLng) {}
}

export class TryUpdateZoomToMarker implements Action {
  public readonly type = ELayoutActions.TryUpdateZoomToMarker;

  constructor(public payload: IZoomToMarkerParams) {}
}

export class UpdateZoomToMarker implements Action {
  public readonly type = ELayoutActions.UpdateZoomToMarker;

  constructor(public payload: IZoomToMarkerParams) {}
}

export class UpdateIsFullscreen implements Action {
  public readonly type = ELayoutActions.UpdateIsFullscreen;

  constructor(public payload: boolean) {}
}

export class TriggerTextview implements Action {
  public readonly type = ELayoutActions.TriggerTextview;
}

export class TriggerGarmin implements Action {
  public readonly type = ELayoutActions.TriggerGarmin;
}

export class TriggerDriverDispatch implements Action {
  public readonly type = ELayoutActions.TriggerDriverDispatch;
}

export class TriggerFitToMap implements Action {
  public readonly type = ELayoutActions.TriggerFitToMap;
}

export class UpdateIsMapFitted implements Action {
  public readonly type = ELayoutActions.UpdateIsMapFitted;

  constructor(public payload: boolean) {}
}

export class SwitchMapType implements Action {
  public readonly type = ELayoutActions.SwitchMapType;

  constructor(public payload: EMapTypeId) {}
}

export class TriggerViewReplay implements Action {
  public readonly type = ELayoutActions.TriggerViewReplay;

  constructor(public payload: IViewReplayParameters) {}
}

export class TriggerRunReport implements Action {
  public readonly type = ELayoutActions.TriggerRunReport;

  constructor(public payload: IRunReportParams) {}
}

export class TriggerEditDriver implements Action {
  public readonly type = ELayoutActions.TriggerEditDriver;

  constructor(public payload: IEditDriverParams) {}
}

export class TriggerEditVehicle implements Action {
  public readonly type = ELayoutActions.TriggerEditVehicle;

  constructor(public payload: IEditVehicleParams) {}
}

export class TriggerEditAsset implements Action {
  public readonly type = ELayoutActions.TriggerEditAsset;

  constructor(public payload: IEditAssetParams) {}
}

export class TriggerSendStop implements Action {
  public readonly type = ELayoutActions.TriggerSendStop;

  constructor(public payload: number) {}
}

export class TriggerViewGarminWindow implements Action {
  public readonly type = ELayoutActions.TriggerViewGarminWindow;

  constructor(public payload: IViewGarminWindowParams) {}
}

export class UpdateVehicleAsDeselected implements Action {
  public readonly type = ELayoutActions.UpdateVehicleAsDeselected;

  constructor(public payload: string) {}
}

export class UpdateVehicleAsDeselectedSuccess implements Action {
  public readonly type = ELayoutActions.UpdateVehicleAsDeselectedSuccess;

  constructor(public payload: IMapHierarchyInfo) {}
}

export class TriggerEditPlace implements Action {
  public readonly type = ELayoutActions.TriggerEditPlace;

  constructor(public payload: IEditPlaceParams) {}
}

export class TriggerViewMetrics implements Action {
  public readonly type = ELayoutActions.TriggerViewMetrics;

  constructor(public payload: IViewMetricsParams) {}
}

export class TriggerWorkOrderDetails implements Action {
  public readonly type = ELayoutActions.TriggerWorkOrderDetails;

  constructor(public payload: IWorkOrderParams) {}
}

export class TriggerWorkOrderStatusHistory implements Action {
  public readonly type = ELayoutActions.TriggerWorkOrderStatusHistory;

  constructor(public payload: IWorkOrderParams) {}
}

export class RemoveZoomToMarker implements Action {
  public readonly type = ELayoutActions.RemoveZoomToMarker;
}

export class OpenReplayOnLiveMap implements Action {
  public readonly type = ELayoutActions.OpenReplayOnLiveMap;

  constructor(public payload: IViewReplayParameters) {}
}

export class CloseReplayOnLiveMap implements Action {
  public readonly type = ELayoutActions.CloseReplayOnLiveMap;
}

export class UpdateAnimationState implements Action {
  public readonly type = ELayoutActions.UpdateAnimationState;

  constructor(public payload: EMapAnimationState) {}
}

export class OpenReplayDatePickerPanel implements Action {
  public readonly type = ELayoutActions.OpenReplayDatePickerPanel;
}

export class CloseReplayDatePickerPanel implements Action {
  public readonly type = ELayoutActions.CloseReplayDatePickerPanel;
}

export class UpdateIsAppAnimationsEnabled implements Action {
  public readonly type = ELayoutActions.UpdateIsAppAnimationsEnabled;

  constructor(public payload: boolean) {}
}

export class OverlayMarginsChanged implements Action {
  public readonly type = ELayoutActions.OverlayMarginsChanged;

  constructor(public payload: IOverlayMarginsChangeParams) {}
}

export class UpdateViewReplayVehicleId implements Action {
  public readonly type = ELayoutActions.UpdateViewReplayVehicleId;

  constructor(public payload: number) {}
}

export class SetReplayPageTitleFromVehicleId implements Action {
  public readonly type = ELayoutActions.SetReplayPageTitleFromVehicleId;

  constructor(public payload: number) {}
}

export class SetPageTitle implements Action {
  public readonly type = ELayoutActions.SetPageTitle;

  constructor(public payload: string) {}
}

export type LayoutActions =
  | ToggleFullscreen
  | UpdateIsFullscreen
  | TriggerTextview
  | TriggerGarmin
  | TriggerDriverDispatch
  | TriggerFitToMap
  | UpdateIsMapFitted
  | SwitchMapType
  | ZoomToLocation
  | UpdateZoomToLocation
  | TryUpdateZoomToMarker
  | UpdateZoomToMarker
  | TriggerViewReplay
  | TriggerRunReport
  | TriggerEditDriver
  | TriggerEditVehicle
  | TriggerSendStop
  | TriggerViewGarminWindow
  | UpdateVehicleAsDeselected
  | UpdateVehicleAsDeselectedSuccess
  | TriggerEditPlace
  | TriggerWorkOrderDetails
  | TriggerWorkOrderStatusHistory
  | RemoveZoomToMarker
  | TriggerEditAsset
  | TriggerCreateGeofence
  | TriggerCreateGeofenceForVehicle
  | TriggerGetDirectionsFrom
  | TriggerGetDirectionsTo
  | TriggerGetDirectionsFromVehicle
  | TriggerGetDirectionsToVehicle
  | OpenReplayOnLiveMap
  | CloseReplayOnLiveMap
  | UpdateAnimationState
  | OpenReplayDatePickerPanel
  | CloseReplayDatePickerPanel
  | UpdateIsAppAnimationsEnabled
  | OverlayMarginsChanged
  | UpdateViewReplayVehicleId
  | SetPageTitle
  | SetReplayPageTitleFromVehicleId;
