/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { EVehicleStatusType } from '../../models';

export interface IMapOptionsState {
  isSuggestedGeofencesEnabled: boolean;
  isGeofencesEnabled: boolean;
  isGarminStopsEnabled: boolean;
  isWorkOrdersEnabled: boolean;
  isShowLabelsEnabled: boolean;
  isShowTachoInformationEnabled: boolean;
  vehicleStatusFilterType: EVehicleStatusType;
  isMapOptionsOpened: boolean;
  isShowVehicleBalloonLinksEnabled: boolean;
  shouldShowClusteringOption: boolean;
}

export const initialMapOptionsState: IMapOptionsState = {
  isSuggestedGeofencesEnabled: false,
  isGeofencesEnabled: false,
  isGarminStopsEnabled: false,
  isWorkOrdersEnabled: false,
  isShowLabelsEnabled: false,
  isShowTachoInformationEnabled: false,
  vehicleStatusFilterType: EVehicleStatusType.All,
  isMapOptionsOpened: false,
  isShowVehicleBalloonLinksEnabled: true,
  shouldShowClusteringOption: true
};
