/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

import { INotificationDetails } from '../models';

@Component({
  selector: 'fm-notification-details',
  templateUrl: './notification-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationDetailsComponent {
  @Input() public details: INotificationDetails;

  @Output() modalClosed: EventEmitter<void> = new EventEmitter();

  public onCloseClick(): void {
    this.modalClosed.emit();
  }
}
