/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { IModulesState, initialModulesState } from '../state';
import { ModulesActions, EModulesActions } from '../actions';
import { ELazyModules } from '../../models';

export function modulesReducer(state = initialModulesState, action: ModulesActions): IModulesState {
  switch (action.type) {
    case EModulesActions.MapModuleLoaded: {
      return LoadModule(state, ELazyModules.Maps);
    }
    case EModulesActions.MapOptionsModuleLoaded: {
      return LoadModule(state, ELazyModules.MapOptions);
    }
    case EModulesActions.ReplayModuleLoaded: {
      return LoadModule(state, ELazyModules.Replay);
    }
    case EModulesActions.OverlayControlsModuleLoaded: {
      return LoadModule(state, ELazyModules.OverlayControls);
    }
    case EModulesActions.VehicleDriverPanelModuleLoaded: {
      return LoadModule(state, ELazyModules.VehicleDriverPanel);
    }
    case EModulesActions.ExternalModuleLoaded: {
      return LoadModule(state, ELazyModules.External);
    }
    default:
      return state;
  }
}

function LoadModule(state: IModulesState, module: ELazyModules) {
  const loadedModules = Object.assign({}, state.loadedModules);
  loadedModules[module] = true;
  return {
    ...state,
    loadedModules: loadedModules
  };
}
