/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Injectable, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { fromEvent, Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil, filter, shareReplay, distinctUntilChanged } from 'rxjs/operators';

import { FmWindowRefService } from '@fleetmatics/ui.utilities';

import { ISize } from '../../../maps/models';
import { IWindowResizeService } from './window-resize.interface';

@Injectable()
export class WindowResizeService implements IWindowResizeService, OnDestroy {
  public windowSize$: Observable<ISize>;
  public isMobile$: Observable<boolean>;
  public isSmallMobile$: Observable<boolean>;
  public isTablet$: Observable<boolean>;
  public isMobileOrTablet$: Observable<boolean>;
  public isSmallDesktop$: Observable<boolean>;
  public isLargeDesktop$: Observable<boolean>;
  public isDesktop$: Observable<boolean>;

  /*
    base library uses these breakpoints and they are used with css mixins media "@include media-breakpoint"
    the same mixin lowers the breakpoint by 0.02 when it is max-width

    The goal is to map these breakpoints to the business use cases:
    tablets and mobile
      - mobile being between 360 and 768
      - tablet being between 768 and 1024
    desktop
      - small desktop - between 1024 and 1200
      - large desktop above 1200
  */
  private readonly _sm = 600;
  private readonly _md = 768;
  private readonly _lg = 1024;
  private readonly _xl = 1200;

  private readonly _isSmallMobile = `(max-width: ${this._below(this._sm)}px)`;
  private readonly _isMobile = `(max-width: ${this._below(this._md)}px)`;
  private readonly _isTablet = `(min-width: ${this._md}px) and (max-width: ${this._below(this._lg)}px)`;
  private readonly _isMobileOrTablet = `(max-width: ${this._below(this._lg)}px)`;
  private readonly _isDesktop = `(min-width: ${this._lg}px)`;
  private readonly _isSmallDesktop = `(min-width: ${this._lg}px) and (max-width: ${this._below(this._xl)}px)`;
  private readonly _isLargeDesktop = `(min-width: ${this._xl}px)`;
  private readonly _breakpoints = [
    this._isSmallMobile,
    this._isMobile,
    this._isTablet,
    this._isMobileOrTablet,
    this._isDesktop,
    this._isSmallDesktop,
    this._isLargeDesktop
  ];

  private readonly _windowResized$: BehaviorSubject<ISize>;
  private _breakpoints$: Observable<BreakpointState>;
  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _windowService: FmWindowRefService,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _breakpointObserver: BreakpointObserver
  ) {
    this._windowResized$ = this._initializeWindowResized();
    this.windowSize$ = this._windowResized$.asObservable().pipe(shareReplay(1));

    this._setupBreakpoints();

    this._onResizeEventsUpdateWindowSize();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private _onResizeEventsUpdateWindowSize(): void {
    if (isPlatformBrowser(this._platformId)) {
      fromEvent(this._windowService.nativeWindow, 'resize')
        .pipe(
          filter(() => isPlatformBrowser(this._platformId)),
          takeUntil(this._unsubscribe$),
          map(() => this._getWindowSize())
        )
        .subscribe(updatedSize => this._windowResized$.next(updatedSize));
    }
  }

  private _initializeWindowResized(): BehaviorSubject<ISize> {
    const size = this._getWindowSize();
    return new BehaviorSubject(size);
  }

  private _getWindowSize(): ISize {
    return isPlatformBrowser(this._platformId)
      ? {
          height: this._windowService.nativeWindow.innerHeight,
          width: this._windowService.nativeWindow.innerWidth
        }
      : {
          height: 0,
          width: 0
        };
  }

  private _setupBreakpoints(): void {
    this._breakpoints$ = this._breakpointObserver.observe(this._breakpoints);

    const getBreakpointState = (breakpoint: string) => {
      return (src: Observable<BreakpointState>): Observable<boolean> =>
        src.pipe(
          takeUntil(this._unsubscribe$),
          map(state => state.breakpoints[breakpoint]),
          shareReplay(1),
          distinctUntilChanged()
        );
    };

    this.isSmallMobile$ = this._breakpoints$.pipe(getBreakpointState(this._isSmallMobile));
    this.isMobile$ = this._breakpoints$.pipe(getBreakpointState(this._isMobile));
    this.isTablet$ = this._breakpoints$.pipe(getBreakpointState(this._isTablet));
    this.isMobileOrTablet$ = this._breakpoints$.pipe(getBreakpointState(this._isMobileOrTablet));
    this.isDesktop$ = this._breakpoints$.pipe(getBreakpointState(this._isDesktop));
    this.isSmallDesktop$ = this._breakpoints$.pipe(getBreakpointState(this._isSmallDesktop));
    this.isLargeDesktop$ = this._breakpoints$.pipe(getBreakpointState(this._isLargeDesktop));
  }

  private _below(breakpoint: number): number {
    return breakpoint - 0.02;
  }
}
