/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { EVehicleStatusType } from '../../models';

export enum EMapOptionsActions {
  UpdateIsSuggestedGeofenceEnabled = '[MapOptions] Update Is Suggested Geofence Enabled',
  UpdateIsTrafficEnabled = '[MapOptions] Update Is Traffic Enabled',
  UpdateIsGeofenceEnabled = '[MapOptions] Update Is Geofence Enabled',
  UpdateIsGarminStopsEnabled = '[MapOptions] Update Is Garmin Stops Enabled',
  UpdateIsWorkOrdersEnabled = '[MapOptions] Update Is Work Orders Enabled',
  UpdateIsClusteringEnabled = '[MapOptions] Update Is Clustering Enabled',
  UpdateIsShowLabelsEnabled = '[MapOptions] Update Is Show Labels Enabled',
  UpdateIsShowVehicleBalloonLinksEnabled = '[MapOptions] Update Is Vehicle Balloon Show Links Enabled',
  UpdateIsShowTachoInformationEnabled = '[MapOptions] Update Is Show Tacho Information Enabled',
  UpdateVehicleStatusFilterType = '[MapOptions] Update Vehicle Status Filter Type',
  OpenMapOptions = '[MapOptions] Open Map Options',
  CloseMapOptions = '[MapOptions] Close Map Options',
  UpdateShouldShowClusteringOption = '[MapOptions] Update Should Show Clustering Option'
}

export class UpdateIsSuggestedGeofenceEnabled implements Action {
  public readonly type = EMapOptionsActions.UpdateIsSuggestedGeofenceEnabled;

  constructor(public payload: boolean) {}
}

export class UpdateIsTrafficEnabled implements Action {
  public readonly type = EMapOptionsActions.UpdateIsTrafficEnabled;

  constructor(public payload: boolean) {}
}

export class UpdateIsGeofenceEnabled implements Action {
  public readonly type = EMapOptionsActions.UpdateIsGeofenceEnabled;

  constructor(public payload: boolean) {}
}

export class UpdateIsGarminStopsEnabled implements Action {
  public readonly type = EMapOptionsActions.UpdateIsGarminStopsEnabled;

  constructor(public payload: boolean) {}
}

export class UpdateIsWorkOrdersEnabled implements Action {
  public readonly type = EMapOptionsActions.UpdateIsWorkOrdersEnabled;

  constructor(public payload: boolean) {}
}

export class UpdateIsClusteringEnabled implements Action {
  public readonly type = EMapOptionsActions.UpdateIsClusteringEnabled;

  constructor(public payload: boolean) {}
}

export class UpdateIsShowLabelsEnabled implements Action {
  public readonly type = EMapOptionsActions.UpdateIsShowLabelsEnabled;

  constructor(public payload: boolean) {}
}

export class UpdateIsShowVehicleBalloonLinksEnabled implements Action {
  public readonly type = EMapOptionsActions.UpdateIsShowVehicleBalloonLinksEnabled;

  constructor(public payload: boolean) {}
}

export class UpdateVehicleStatusFilterType implements Action {
  public readonly type = EMapOptionsActions.UpdateVehicleStatusFilterType;

  constructor(public payload: EVehicleStatusType) {}
}

export class UpdateIsShowTachoInformation implements Action {
  public readonly type = EMapOptionsActions.UpdateIsShowTachoInformationEnabled;

  constructor(public payload: boolean) {}
}

export class OpenMapOptions implements Action {
  public readonly type = EMapOptionsActions.OpenMapOptions;
}

export class CloseMapOptions implements Action {
  public readonly type = EMapOptionsActions.CloseMapOptions;
}

export class UpdateShouldShowClusteringOption implements Action {
  public readonly type = EMapOptionsActions.UpdateShouldShowClusteringOption;

  constructor(public payload: boolean) {}
}

export type MapOptionsActions =
  | UpdateIsSuggestedGeofenceEnabled
  | UpdateIsTrafficEnabled
  | UpdateIsGeofenceEnabled
  | UpdateIsGarminStopsEnabled
  | UpdateIsWorkOrdersEnabled
  | UpdateIsClusteringEnabled
  | UpdateIsShowLabelsEnabled
  | UpdateIsShowVehicleBalloonLinksEnabled
  | UpdateVehicleStatusFilterType
  | UpdateIsShowTachoInformation
  | OpenMapOptions
  | CloseMapOptions
  | UpdateShouldShowClusteringOption;
