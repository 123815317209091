/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IVehiclePlot, IReverseGeocodeExecutionBatch } from '../../models';

export enum EReverseGeocodeActions {
  ExecuteReverseGeocode = '[Reverse Geocode] Execute Reverse Geocode',
  RemoveExecutionBatch = '[Reverse Geocode] Remove Execution Batch',
  ReverseGeocodeVehiclePlots = '[Reverse Geocode] Reverse Geocode Vehicle Plots',
  ReverseGeocodeVehiclePlotsSuccess = '[Reverse Geocode] Reverse Geocode Vehicle Plots Success',
  UpdateExecutionBatches = '[Reverse Geocode] Update Execution Batches'
}

export class ExecuteReverseGeocode implements Action {
  public readonly type = EReverseGeocodeActions.ExecuteReverseGeocode;

  constructor(public payload: IReverseGeocodeExecutionBatch) {}
}

export class RemoveExecutionBatch implements Action {
  public readonly type = EReverseGeocodeActions.RemoveExecutionBatch;

  constructor(public payload: number) {}
}

export class ReverseGeocodeVehiclePlots implements Action {
  public readonly type = EReverseGeocodeActions.ReverseGeocodeVehiclePlots;

  constructor(public payload: IReverseGeocodeExecutionBatch) {}
}

export class ReverseGeocodeVehiclePlotsSuccess implements Action {
  public readonly type = EReverseGeocodeActions.ReverseGeocodeVehiclePlotsSuccess;

  constructor(public payload: IVehiclePlot[]) {}
}

export class UpdateExecutionBatches implements Action {
  public readonly type = EReverseGeocodeActions.UpdateExecutionBatches;

  constructor(public payload: IReverseGeocodeExecutionBatch[]) {}
}

export type ReverseGeocodeActions =
  | ExecuteReverseGeocode
  | RemoveExecutionBatch
  | ReverseGeocodeVehiclePlots
  | ReverseGeocodeVehiclePlotsSuccess
  | UpdateExecutionBatches;
