/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { isElementNull, isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import { EDriverWorkingState, IVehicleTachoStatus, EDrivingLimitWarning } from 'tacho';

import { IWorkingTimeDirectiveState } from '../state';
import { IDriverStatus, EDriverStatusSortType } from '../../models';
import { getIsShowTachoInformationEnabled } from './map-options.selectors';
import { getWorkingTimeDirectiveFeatureAllowed } from './user-map-settings.selectors';

export const getWorkingTimeDirectiveState = createFeatureSelector<IWorkingTimeDirectiveState>('workingTimeDirective');

const hasTachoData = (tacho: IVehicleTachoStatus) => {
  return !isElementNull(tacho) && !isElementNull(tacho.DriverWorkingTime);
};

const isBreakOverDue = (tacho: IVehicleTachoStatus): boolean => {
  if (!hasTachoData(tacho)) {
    return false;
  }

  return tacho.DriverWorkingTime.DrivingWarning === EDrivingLimitWarning.Reached;
};

const isBreakDueInMinutes = (tacho: IVehicleTachoStatus): boolean => {
  if (!hasTachoData(tacho)) {
    return false;
  }
  return tacho.DriverWorkingTime.DrivingWarning === EDrivingLimitWarning.In15Min;
};

const checkBreakDueFilter = (tacho: IVehicleTachoStatus): boolean => {
  if (!hasTachoData(tacho)) {
    return false;
  }
  return isBreakDueInMinutes(tacho) || isBreakOverDue(tacho);
};

const driverWorkingStateFilter = (tacho: IVehicleTachoStatus, filter: EDriverWorkingState): boolean => {
  if (!hasTachoData(tacho)) {
    return false;
  }
  return tacho.DriverWorkingTime.TachoStatus === filter;
};

const compareFunction = (a: any, b: any): number => {
  if (a && b) {
    return a > b ? 1 : a < b ? -1 : 0;
  } else {
    if (a && !b) {
      return -1;
    } else if (!a && b) {
      return 1;
    } else {
      return 0;
    }
  }
};

export const getWorkingTimeDirectiveDisplayEnabled = createSelector(
  getWorkingTimeDirectiveFeatureAllowed,
  getIsShowTachoInformationEnabled,
  (isWorkingTimeDirectiveAllowed: boolean, isShowTachoInformationEnabled: boolean) => {
    return isWorkingTimeDirectiveAllowed && isShowTachoInformationEnabled;
  }
);

export const getDriverStatusData = createSelector(getWorkingTimeDirectiveState, (state: IWorkingTimeDirectiveState): IDriverStatus[] => {
  const selectedFilter = state.filter;
  const isDriverBreakDueFilterSelected = state.filterBreakDue || false;
  const driverSortType: EDriverStatusSortType = state.sortType;

  let driverStatusList: IDriverStatus[] = [...state.driverStatusData] || [];

  if (!isElementNull(selectedFilter) || isDriverBreakDueFilterSelected) {
    driverStatusList = state.driverStatusData.filter(
      status =>
        (isElementNull(selectedFilter) || driverWorkingStateFilter(status.vehicleTachoInfo.status, selectedFilter)) &&
        (!isDriverBreakDueFilterSelected || (isDriverBreakDueFilterSelected && checkBreakDueFilter(status.vehicleTachoInfo.status)))
    );
  }

  driverStatusList.sort((driverStatus1: IDriverStatus, driverStatus2: IDriverStatus) => {
    const workingTime1 = driverStatus1.vehicleTachoInfo.status ? driverStatus1.vehicleTachoInfo.status.DriverWorkingTime : null;
    const workingTime2 = driverStatus2.vehicleTachoInfo.status ? driverStatus2.vehicleTachoInfo.status.DriverWorkingTime : null;
    const vehicleId1 = driverStatus1.vehicleTachoInfo.status ? driverStatus1.vehicleTachoInfo.status.VehicleId : undefined;
    const vehicleId2 = driverStatus2.vehicleTachoInfo.status ? driverStatus2.vehicleTachoInfo.status.VehicleId : undefined;

    switch (driverSortType) {
      case EDriverStatusSortType.StartTime:
        let startTimeValue1;
        let startTimeValue2;

        if (!isElementNull(workingTime1)) {
          startTimeValue1 = workingTime1.StartTimeValue;
        }

        if (!isElementNull(workingTime2)) {
          startTimeValue2 = workingTime2.StartTimeValue;
        }

        return compareFunction(startTimeValue1, startTimeValue2) || compareFunction(vehicleId1, vehicleId2);
      case EDriverStatusSortType.DriverName:
        const cleanDriverName = (driverName: string) => driverName.replace(/^\s*,\s*$|^\s+$/, '');
        const driver1Name = cleanDriverName(driverStatus1.driverName);
        const driver2Name = cleanDriverName(driverStatus2.driverName);

        if (isStringNullOrEmpty(driver1Name) && !isStringNullOrEmpty(driver2Name)) {
          return 1;
        }

        if (isStringNullOrEmpty(driver2Name) && !isStringNullOrEmpty(driver1Name)) {
          return -1;
        }

        return driver1Name.localeCompare(driver2Name) || compareFunction(vehicleId1, vehicleId2);
      default:
        return 0;
    }
  });

  return driverStatusList;
});

export const getTachoEnabledVehicleIds = createSelector(getWorkingTimeDirectiveState, (state: IWorkingTimeDirectiveState): number[] =>
  state.driverStatusData
    .filter(status => !isElementNull(status.vehicleTachoInfo.status))
    .map(status => status.vehicleTachoInfo.status.VehicleId)
);

export const getWtdSettings = createSelector(
  getTachoEnabledVehicleIds,
  getWorkingTimeDirectiveDisplayEnabled,
  (tachoEnabledVehicleIds, workingTimeDirectiveDisplayEnabled): any => {
    return { enabledVehicleIds: tachoEnabledVehicleIds, isWorkingTimeDirectiveDisplayEnabled: workingTimeDirectiveDisplayEnabled };
  }
);

export const getDriverStatusFilterSelected = createSelector(
  getWorkingTimeDirectiveState,
  (state: IWorkingTimeDirectiveState): EDriverWorkingState => state.filter
);

export const getDriverStatusIsDriverBreakDueFilterSelected = createSelector(
  getWorkingTimeDirectiveState,
  (state: IWorkingTimeDirectiveState): boolean => state.filterBreakDue
);

export const getDriverStatusSortType = createSelector(
  getWorkingTimeDirectiveState,
  (state: IWorkingTimeDirectiveState): EDriverStatusSortType => state.sortType
);
