/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EDriverWorkingState {
  Rest = 0,
  Available = 1,
  Work = 2,
  Drive = 3,
  Error = 6,
  NotAvailable = 7
}
