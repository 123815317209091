/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IAssetsState } from '../state';
import { IAssetSelectionResponse, IAssetDetailResponse } from '../../models';

export const getAssetsState = createFeatureSelector<IAssetsState>('assets');

export const getAssetsSelection = createSelector(getAssetsState, (state: IAssetsState): IAssetSelectionResponse[] => state.assetSelection);

export const getSelectedAssetsIds = createSelector(getAssetsState, (state: IAssetsState): number[] =>
  state.assetSelection.filter(a => a.selected).map(a => a.id)
);

export const getAssetDetails = createSelector(getAssetsState, (state: IAssetsState): IAssetDetailResponse[] => state.assetDetails);
