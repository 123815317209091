/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAppConfig } from '../../../../../config/app-config.interfaces';
import { APP_CONFIG_TOKEN } from '../../../../../config/app-config.token';
import { IDeviceStatusHttpService } from './device-status-http.service.interface';
import { IApiResponseDto, IDeviceStatusResponse, IDeviceStatusParams, IVehicleDetailsResponse } from '../../models';

@Injectable()
export class DeviceStatusHttpService implements IDeviceStatusHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG_TOKEN) _config: IAppConfig) {
    this._rootUrl = `${_config.endpoints.deviceStatusApiEndpoint}/DeviceStatus/`;
  }

  public getDeviceStatusAlerts(): Observable<IDeviceStatusResponse[]> {
    return this._httpClient
      .get<IApiResponseDto<IDeviceStatusResponse[]>>(`${this._rootUrl}GetVehiclesDeviceStatusAlerts`)
      .pipe(map(result => result.Data));
  }

  public saveUserAction(parameters: IDeviceStatusParams): Observable<string> {
    return this._httpClient
      .post<IApiResponseDto<string>>(`${this._rootUrl}SaveVehicleAlertUserAction`, parameters)
      .pipe(map(result => (<unknown>result.Message) as string));
  }

  public getVehicleDetails(id: string): Observable<IVehicleDetailsResponse> {
    return this._httpClient
      .post<IApiResponseDto<IVehicleDetailsResponse>>(`${this._rootUrl}GetVehicleDetails`, { VehicleId: id })
      .pipe(map(result => result.Data));
  }
}
