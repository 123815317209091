/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { FlatTreeControl } from '@angular/cdk/tree';
import { Subject, Observable } from 'rxjs';

import { ECheckboxState } from '@fleetmatics/ui.base-library';

import { IHierarchyTreeNode } from './models';
import { FlatDataUtilities } from '../shared';

export class HierarchyTreeSelectionModel<T> {
  selectionChange$: Observable<IHierarchyTreeNode<T>[]>;

  private _hasSelectionChanged: boolean;
  private readonly _leafSelection = new Map<number, Set<T>>();
  private readonly _selection = new Map<T, ECheckboxState>();
  private readonly _selectionChangeSubject = new Subject<IHierarchyTreeNode<T>[]>();

  constructor(
    private readonly _treeControl: FlatTreeControl<IHierarchyTreeNode<T>>,
    private readonly _maxSelectionMap?: Map<number, number>,
    private readonly _areIdsUnique?: boolean
  ) {
    this.selectionChange$ = this._selectionChangeSubject.asObservable();
    this._initSelection();
  }

  toggle(node: IHierarchyTreeNode<T>): void {
    this._hasSelectionChanged = false;

    const shouldSelect = !this.isSelected(node);
    shouldSelect ? this.select(node) : this.deselect(node);

    // Propagate down
    const descendants = FlatDataUtilities.getDescendants(this._treeControl.dataNodes, node);
    shouldSelect ? this.select(...descendants) : this.deselect(...descendants);

    // Update all parents according to child status
    if (this._areIdsUnique) {
      this._updateNodeAncestorsStateBasedOnChildrenState(node);
    } else {
      this._updateStateOfAllExpandableNodes();
    }

    // Check maxSelectionCount and if the limit has been reached, disable unchecked nodes
    this._updateNodesDisabledStatusAccordingToMaxSelection();

    if (this._hasSelectionChanged) {
      const selectedNodes = this._getSelectedNodes();
      this._selectionChangeSubject.next(selectedNodes);
    }
  }

  updateSelection(nodes: IHierarchyTreeNode<T>[]): void {
    const nodesCount = this._treeControl.dataNodes.length;
    for (let i = 0; i < nodesCount; i++) {
      const dataNode = this._treeControl.dataNodes[i];
      const updatedNode = nodes[i];
      if (updatedNode.state === ECheckboxState.Checked) {
        this._markSelected(dataNode);
      } else if (updatedNode.state === ECheckboxState.Unchecked) {
        this.deselect(dataNode);
      }
    }
    this._updateStateOfAllExpandableNodes();
    this._updateNodesDisabledStatusAccordingToMaxSelection();
  }

  getState(node: IHierarchyTreeNode<T>): ECheckboxState {
    return this._selection.get(node.id);
  }

  isSelected(node: IHierarchyTreeNode<T>): boolean {
    const status = this._selection.get(node.id);

    return status === ECheckboxState.Checked;
  }

  isIndeterminate(node: IHierarchyTreeNode<T>): boolean {
    const status = this._selection.get(node.id);

    return status === ECheckboxState.Indeterminate;
  }

  select(...nodes: IHierarchyTreeNode<T>[]): void {
    nodes.forEach(node => {
      if (!this._isMaxSelectionReached(node.maxSelectionType) || node.isExpandable) {
        this._markSelected(node);
      }
    });
  }

  deselect(...nodes: IHierarchyTreeNode<T>[]): void {
    nodes.forEach(node => this._unmarkSelected(node));
  }

  private _initSelection(): void {
    const nodesCount = this._treeControl.dataNodes.length;
    for (let i = 0; i < nodesCount; i++) {
      const node = this._treeControl.dataNodes[i];
      if (!node.isExpandable && node.state === ECheckboxState.Checked) {
        this.select(node);
      }
    }
    this._updateStateOfAllExpandableNodes();
    this._updateNodesDisabledStatusAccordingToMaxSelection();
  }

  private _updateNodeAncestorsStateBasedOnChildrenState(node: IHierarchyTreeNode<T>): void {
    const index = this._treeControl.dataNodes.indexOf(node);
    const startingIndex = node.isExpandable ? index : index - 1;
    let currentLevel = node.isExpandable ? node.level + 1 : node.level;
    for (let i = startingIndex; i >= 0; i--) {
      const currentNode = this._treeControl.dataNodes[i];
      if (currentNode.isExpandable && currentNode.level === currentLevel - 1) {
        this._updateNodeStateBasedOnChildren(currentNode);
        currentLevel--;
      }
    }
  }

  private _updateStateOfAllExpandableNodes(): void {
    for (let i = this._treeControl.dataNodes.length - 1; i >= 0; i--) {
      const currentNode = this._treeControl.dataNodes[i];
      if (currentNode.isExpandable) {
        this._updateNodeStateBasedOnChildren(currentNode);
      }
    }
  }

  private _isMaxSelectionReached(type: number): boolean {
    const typeMaxSelection = this._maxSelectionMap.get(type);
    const typeSelection = this._leafSelection.get(type);
    return typeMaxSelection !== undefined && typeSelection !== undefined && typeSelection.size >= typeMaxSelection;
  }

  private _updateNodesDisabledStatusAccordingToMaxSelection(): void {
    if (!this._maxSelectionMap) {
      return;
    }

    const areAllNodesInLevelDisabled = new Map<number, boolean>();
    for (let i = this._treeControl.dataNodes.length - 1; i >= 0; i--) {
      const node = this._treeControl.dataNodes[i];

      if (!node.isExpandable) {
        node.isDisabled = this._isMaxSelectionReached(node.maxSelectionType) && !this._selection.has(node.id);

        if (!node.isDisabled) {
          areAllNodesInLevelDisabled.set(node.level, false);
        }
      } else {
        const isDisabled = areAllNodesInLevelDisabled.get(node.level + 1);
        node.isDisabled = isDisabled !== undefined ? isDisabled : true;
        areAllNodesInLevelDisabled.set(node.level + 1, true);

        if (!node.isDisabled) {
          areAllNodesInLevelDisabled.set(node.level, false);
        }
      }
    }
  }

  private _markSelected(node: IHierarchyTreeNode<T>): void {
    const hasSelectionChanged = this._hasSelectionChanged || this._selection.get(node.id) !== ECheckboxState.Checked;
    this._selection.set(node.id, ECheckboxState.Checked);
    if (!node.isExpandable) {
      this._hasSelectionChanged = hasSelectionChanged;

      const typeSelection = this._leafSelection.get(node.maxSelectionType);
      if (typeSelection !== undefined) {
        typeSelection.add(node.id);
      } else {
        this._leafSelection.set(
          node.maxSelectionType,
          new Set<T>([node.id])
        );
      }
    }
  }

  private _unmarkSelected(node: IHierarchyTreeNode<T>): void {
    const previousState = this._selection.get(node.id);
    this._selection.delete(node.id);
    if (!node.isExpandable) {
      this._hasSelectionChanged = this._hasSelectionChanged || (previousState !== undefined && previousState !== ECheckboxState.Unchecked);

      const typeSelection = this._leafSelection.get(node.maxSelectionType);
      if (typeSelection !== undefined) {
        typeSelection.delete(node.id);
      }
    }
  }

  private _updateNodeStateBasedOnChildren(node: IHierarchyTreeNode<T>): void {
    const children = FlatDataUtilities.getChildrenByNode(this._treeControl.dataNodes, node);
    const areAllSelected = this._areAllNodesSelected(children);

    if (areAllSelected) {
      this.select(node);
    } else {
      const arePartiallySelected = this._areSomeNodesSelected(children);

      if (arePartiallySelected) {
        this._selection.set(node.id, ECheckboxState.Indeterminate);
      } else {
        this.deselect(node);
      }
    }
  }

  private _areAllNodesSelected(nodes: IHierarchyTreeNode<T>[]): boolean {
    const allSelected = nodes.every(node => this.isSelected(node));
    return allSelected;
  }

  private _areSomeNodesSelected(nodes: IHierarchyTreeNode<T>[]): boolean {
    const result = nodes.some(node => this.isSelected(node) || this.isIndeterminate(node));
    return result && !this._areAllNodesSelected(nodes);
  }

  private _getSelectedNodes(): IHierarchyTreeNode<T>[] {
    const selectedNodes: IHierarchyTreeNode<T>[] = [];
    const nodesCount = this._treeControl.dataNodes.length;

    for (let i = 0; i < nodesCount; i++) {
      const node = this._treeControl.dataNodes[i];
      const selectionState = this._selection.get(node.id);
      if (selectionState !== undefined) {
        node.state = selectionState;
        selectedNodes.push(node);
      }
    }

    return selectedNodes;
  }
}
