/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ECheckboxState } from '@fleetmatics/ui.base-library';

import { FlatDataUtilities } from '../components/tree/shared/flat-data.utilities';
import { SortUtils } from '../utils';
import { IMapHierarchyInfo } from './map-hierarchy-info.interface';
import { IMapHierarchyItem } from './map-hierarchy-item.interface';
import { ENodeType } from './node-type.enum';
import { EVehicleSelectionMode } from './vehicle-selection-mode.enum';

export class MapHierarchyInfo implements IMapHierarchyInfo {
  static rootGroupLocalized = new Map<EVehicleSelectionMode, string>([
    [EVehicleSelectionMode.Vehicles, $localize`:@@S_VEHICLE_SELECTION_ALL_VEHICLES:Select All Vehicles`],
    [EVehicleSelectionMode.PoweredAssets, $localize`:@@S_VEHICLE_SELECTION_ALL_POWERED_ASSETS:Select All Powered Assets`],
    [EVehicleSelectionMode.Assets, $localize`:@@S_LIVE_MAP_STATUS_PANEL_SELECT_ALL_NON_POWERED_ASSETS:Select All Non-powered assets`],
    [EVehicleSelectionMode.Drivers, $localize`:@@S_VEHICLE_SELECTION_ALL_DRIVERS:Select All Drivers`],
    [EVehicleSelectionMode.Groups, $localize`:@@S_VEHICLE_SELECTION_ALL_GROUPS:Select All Groups`],
    [EVehicleSelectionMode.EntireFleet, $localize`:@@S_SELECT_ALL:Select All`]
  ]);

  static entireFleetLocalized = new Map<
    ENodeType,
    {
      id: string;
      name: string;
    }
  >([
    [
      ENodeType.Vehicle,
      {
        name: $localize`:@@S_VEHICLES:Vehicles`,
        id: 'g10000001'
      }
    ],
    [
      ENodeType.PoweredAsset,
      {
        name: $localize`:@@S_SEARCH_POWEREDASSET:Powered Assets`,
        id: 'g10000002'
      }
    ],
    [
      ENodeType.Asset,
      {
        name: $localize`:@@S_LIVE_MAP_NON_POWERED_ASSETS:Non-powered assets`,
        id: 'g10000003'
      }
    ],
    [
      ENodeType.Driver,
      {
        name: $localize`:@@S_DRIVERS:Drivers`,
        id: 'g10000004'
      }
    ]
  ]);

  isNewMapHierarchy: boolean;
  mapHierarchy: IMapHierarchyItem[];

  constructor(mapHierarchyInfo: IMapHierarchyInfo) {
    this.mapHierarchy = [...mapHierarchyInfo.mapHierarchy];
    this.isNewMapHierarchy = mapHierarchyInfo.isNewMapHierarchy;
  }

  public static areIdsUnique(vehicleSelectionMode: EVehicleSelectionMode): boolean {
    return vehicleSelectionMode !== EVehicleSelectionMode.Groups && vehicleSelectionMode !== EVehicleSelectionMode.EntireFleet;
  }

  public static getMapHierarchyByNodeType(mapHierarchy: IMapHierarchyItem[], type: ENodeType): IMapHierarchyItem[] {
    if (mapHierarchy.length === 0) {
      return [];
    }

    if (type === ENodeType.Group) {
      const removeTopLevelTrackables = (i: IMapHierarchyItem): boolean => i.level !== 1 || i.type === ENodeType.Group;
      return mapHierarchy.filter(removeTopLevelTrackables);
    }

    const root = mapHierarchy.find(i => i.level === 0);

    const hierarchy = type === ENodeType.Driver ? MapHierarchyInfo.getDriverHierarchy(mapHierarchy) : mapHierarchy;

    const ids = new Set<string>();
    const flattened = hierarchy
      .filter(i => {
        // filter nodes that match the type and remove duplicates
        if (i.type === type && !ids.has(i.id)) {
          ids.add(i.id);
          return true;
        }
        return false;
      })
      .map(item => {
        if (item.type === type) {
          return MapHierarchyInfo.flattenHierarchyItem(item);
        }
        return item;
      })
      .sort((a, b) => SortUtils.naturalSortComparer(a.name, b.name));

    return [root, ...flattened];
  }

  public static getDriverHierarchy(mapHierarchy: IMapHierarchyItem[]) {
    const map = new Map<string, IMapHierarchyItem>();
    // items with driverId can be either a vehicle or driver
    // normally, the driver will always appear in the hierarcy
    // however, if the ungrouped is not in scope, then we can have only the vehicle version
    const driversOrVehicles = mapHierarchy.filter(i => i.driverId);
    driversOrVehicles.forEach(i => {
      // add to the map the first instance, but prefer the driver
      if (!map.has(i.id) || i.type === ENodeType.Driver) {
        map.set(i.id, i);
      }
    });

    return [...map.values()].map(i => {
      if (i.type !== ENodeType.Driver) {
        // extract the name of the driver and the vehicle / powered asset
        const lastOpenParenthesis = i.name.lastIndexOf('(');
        const lastCloseParenthesis = i.name.lastIndexOf(')');
        const driverName = i.name.substring(lastOpenParenthesis + 1, lastCloseParenthesis);
        const vehicleName = i.name.substring(0, lastOpenParenthesis - 1);

        const newName = `${driverName} (${vehicleName})`;

        return {
          ...i,
          type: ENodeType.Driver,
          name: newName
        };
      }

      return i;
    });
  }

  public static flattenHierarchyItem(item: IMapHierarchyItem): IMapHierarchyItem {
    return {
      ...item,
      level: 1
    };
  }

  public static getMapHierarchyForEntireFleet(mapHierarchy: IMapHierarchyItem[]): IMapHierarchyItem[] {
    const vehicles = MapHierarchyInfo.mapToChildHierarchy(mapHierarchy, ENodeType.Vehicle);
    const nonPoweredAssets = MapHierarchyInfo.mapToChildHierarchy(mapHierarchy, ENodeType.Asset);
    const poweredAssets = MapHierarchyInfo.mapToChildHierarchy(mapHierarchy, ENodeType.PoweredAsset);
    const drivers = MapHierarchyInfo.mapToChildHierarchy(mapHierarchy, ENodeType.Driver);

    const root: IMapHierarchyItem = {
      ...mapHierarchy.find(i => i.level === 0)
    };

    return [root, ...vehicles, ...poweredAssets, ...nonPoweredAssets, ...drivers];
  }

  public static mapToChildHierarchy(hierarchy: IMapHierarchyItem[], type: ENodeType): IMapHierarchyItem[] {
    const byTypeHierarchy = MapHierarchyInfo.getMapHierarchyByNodeType(hierarchy, type);
    if (byTypeHierarchy.length === 1 && byTypeHierarchy[0].type === ENodeType.Group) {
      return [];
    }

    return MapHierarchyInfo.setAsSecondaryHierarchy(byTypeHierarchy, type);
  }

  public static setAsSecondaryHierarchy(hierarchy: IMapHierarchyItem[], type: ENodeType): IMapHierarchyItem[] {
    const { name, id } = MapHierarchyInfo.entireFleetLocalized.get(type);

    return hierarchy.map(i => {
      if (i.level === 0) {
        return {
          ...i,
          name,
          id,
          level: 1
        };
      } else {
        return {
          ...i,
          level: 2
        };
      }
    });
  }

  public updateMapHierarchySelectedNode(vehicleToSelect: string, state: ECheckboxState) {
    FlatDataUtilities.updateItemSelection(this.mapHierarchy, vehicleToSelect, state, false);
    return this;
  }

  public updateMapHierarchySelectedNodes(
    selection: Map<string, ECheckboxState>,
    vehicleSelectionMode: EVehicleSelectionMode
  ): IMapHierarchyInfo {
    /*
        "selection" gives us the items that should be intermediate or checked.
        however it's based on the current vehicle selection mode.
        so if we are in vehicles mode, those are all the vehicles that need to be updated.

        we can assume that any item not in "selection" should be unchecked.
        however, we must filter out items from different views.
    */

    // update the groups hierarchy model
    this._updateGroupsHierarchyModel(vehicleSelectionMode, selection);

    return this;
  }

  private _updateGroupsHierarchyModel(vehicleSelectionMode: EVehicleSelectionMode, selection: Map<string, ECheckboxState>): void {
    if (vehicleSelectionMode === EVehicleSelectionMode.Groups || vehicleSelectionMode === EVehicleSelectionMode.EntireFleet) {
      this.mapHierarchy.forEach(item => {
        const state = selection.get(item.id);
        item.state = state !== undefined ? state : ECheckboxState.Unchecked;
      });
    } else {
      const filtered = this._getMapHierarchyItemsToUpdate(vehicleSelectionMode, this.mapHierarchy);

      filtered.forEach(item => {
        const state = selection.get(item.id);
        const newState = state === undefined ? ECheckboxState.Unchecked : state;
        if (newState !== item.state) {
          FlatDataUtilities.updateItemSelection(this.mapHierarchy, item.id, newState, false);
        }
      });
    }
  }

  private _getMapHierarchyItemsToUpdate(vehicleSelectionMode: EVehicleSelectionMode, mapHierarchy: IMapHierarchyItem[]) {
    let nodeType: ENodeType;
    switch (vehicleSelectionMode) {
      case EVehicleSelectionMode.Vehicles:
        nodeType = ENodeType.Vehicle;
        break;
      case EVehicleSelectionMode.Assets:
        nodeType = ENodeType.Asset;
        break;
      case EVehicleSelectionMode.PoweredAssets:
        nodeType = ENodeType.PoweredAsset;
        break;
      case EVehicleSelectionMode.Drivers:
        return mapHierarchy.filter(i => i.type === ENodeType.Driver || i.type === ENodeType.Vehicle || i.type === ENodeType.PoweredAsset);
    }
    return mapHierarchy.filter(i => i.type === nodeType);
  }
}
