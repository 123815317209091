/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { ESpeedUnits } from '../../../core/models';

@Pipe({
  name: 'speedConvert'
})
export class SpeedConvertPipe implements PipeTransform {
  transform(speedKmh: number, speedUnits: ESpeedUnits): number {
    let speedUnit = speedKmh;

    switch (speedUnits) {
      case ESpeedUnits.CentimetersPerSecond:
        speedUnit = speedUnit * 27.77777778;
        break;
      case ESpeedUnits.KilometersPerHour:
        speedUnit = speedUnit;
        break;
      case ESpeedUnits.MilesPerHour:
        speedUnit = speedUnit * 0.621371;
        break;
      case ESpeedUnits.Knots:
        speedUnit = speedUnit * 0.539957;
        break;
    }

    return speedUnit;
  }
}
