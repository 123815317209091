/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EVehicleStatusType {
  All = 0,
  Towing = 1,
  Moving = 2,
  Idle = 3,
  Stopped = 4,
  LossOfSignal = 5
}
