/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EDisplayDriverAs {
  NotSet = 0,
  DriverSurnameFirstName = 1,
  DriverNumber = 2,
  DriverFirstNameSurname = 3
}
