/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { ILayoutPreferencesState } from '../state';
import { ISyncLayoutPreferencesParams } from '../../models';

export enum ELayoutPreferencesActions {
  GetLayoutPreferences = '[Layout Preferences] Get Layout Preferences',
  GetLayoutPreferencesSuccess = '[Layout Preferences] Get Layout Preferences Success',
  UpdateLayoutPreferences = '[Layout Preferences] Update Layout Preferences',
  SyncLegacyLayoutPreferences = '[Layout Preferences] Sync Legacy Layout Preferences',
  UpdateLegacyLayoutPreferences = '[Layout Preferences] Update Legacy Layout Preferences'
}

export class GetLayoutPreferences implements Action {
  public readonly type = ELayoutPreferencesActions.GetLayoutPreferences;
}

export class GetLayoutPreferencesSuccess implements Action {
  public readonly type = ELayoutPreferencesActions.GetLayoutPreferencesSuccess;

  constructor(public payload: ILayoutPreferencesState) { }
}

export class UpdateLayoutPreferences implements Action {
  public readonly type = ELayoutPreferencesActions.UpdateLayoutPreferences;

  constructor(public payload: ILayoutPreferencesState) { }
}

export class SyncLegacyLayoutPreferences implements Action {
  public readonly type = ELayoutPreferencesActions.SyncLegacyLayoutPreferences;

  constructor(public payload: ISyncLayoutPreferencesParams) { }
}

export class UpdateLegacyLayoutPreferences implements Action {
  public readonly type = ELayoutPreferencesActions.UpdateLegacyLayoutPreferences;

  constructor(public payload: ILayoutPreferencesState) { }
}

export type LayoutPreferencesActions =
  GetLayoutPreferences |
  GetLayoutPreferencesSuccess |
  UpdateLayoutPreferences |
  SyncLegacyLayoutPreferences |
  UpdateLegacyLayoutPreferences;
