/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ESpeedUnits } from '../app/core/models';

export class SpeedConversionUtilities {
  private static readonly _conversionDictionary = {
    knots: {
      knots: 1,
      milesPerHour: 1.150779448,
      centimetersPerSecond: 51.444444444,
      kilometersPerHour: 1.852
    },
    milesPerHour: {
      knots: 0.86897624191,
      milesPerHour: 1,
      centimetersPerSecond: 44.704,
      kilometersPerHour: 1.609344
    },
    centimetersPerSecond: {
      knots: 0.019438444925,
      milesPerHour: 0.022369362921,
      centimetersPerSecond: 1,
      kilometersPerHour: 0.036
    },
    kilometersPerHour: {
      knots: 0.53995680346,
      milesPerHour: 0.62137119224,
      centimetersPerSecond: 27.777777778,
      kilometersPerHour: 1
    }
  };

  static Convert(speed: number, currentSpeedUnits: ESpeedUnits, desiredSpeedUnits: ESpeedUnits): number {
    const factors = this.getCurrentSpeedMapping(currentSpeedUnits);
    const multiplicationFactor = this.getMultiplicationFactor(desiredSpeedUnits, factors);
    return speed * multiplicationFactor;
  }

  private static getCurrentSpeedMapping(currentSpeedUnits: ESpeedUnits): any {
    switch (currentSpeedUnits) {
      case ESpeedUnits.Knots: {
        return this._conversionDictionary.knots;
      }
      case ESpeedUnits.MilesPerHour: {
        return this._conversionDictionary.milesPerHour;
      }
      case ESpeedUnits.CentimetersPerSecond: {
        return this._conversionDictionary.centimetersPerSecond;
      }
      case ESpeedUnits.KilometersPerHour: {
        return this._conversionDictionary.kilometersPerHour;
      }
    }
  }

  private static getMultiplicationFactor(desiredSpeedUnits: ESpeedUnits, factors: any): number {
    switch (desiredSpeedUnits) {
      case ESpeedUnits.Knots: {
        return factors.knots;
      }
      case ESpeedUnits.MilesPerHour: {
        return factors.milesPerHour;
      }
      case ESpeedUnits.CentimetersPerSecond: {
        return factors.centimetersPerSecond;
      }
      case ESpeedUnits.KilometersPerHour: {
        return factors.kilometersPerHour;
      }
    }
  }
}
