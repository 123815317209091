/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ECheckboxState } from '@fleetmatics/ui.base-library';

import { IFlatTreeNode } from './flat-tree-node.interface';

export class FlatDataUtilities {
  static updateItemSelection<T extends IFlatTreeNode<K>, K>(items: T[], id: K, state: ECheckboxState, areIdsUnique: boolean): T[] {
    const itemCount = items.length;
    for (let i = 0; i < itemCount; i++) {
      const item = items[i];
      if (item.id === id) {
        item.state = state;
        this.updateAncestorsSelection(items, i, item.level, state);

        if (areIdsUnique) {
          break;
        }
      }
    }

    return items;
  }

  static updateAncestorsSelection<T extends IFlatTreeNode<K>, K>(items: T[], index: number, level: number, state: ECheckboxState): void {
    for (let i = index - 1; i >= 0; i--) {
      const item = items[i];
      if (item.isExpandable && item.level === level - 1) {
        const children = this.getChildrenByIndex(items, i);
        if (this.areAllItemsInState(children, state)) {
          item.state = state;
        } else if (this.areItemsPartiallySelected(children)) {
          item.state = ECheckboxState.Indeterminate;
        }
        level--;
      }
    }
  }

  static areAllItemsInState<T extends IFlatTreeNode<K>, K>(items: T[], state: ECheckboxState): boolean {
    const allSelected = items.every(child => child.state === state);
    return allSelected;
  }

  static areItemsPartiallySelected<T extends IFlatTreeNode<K>, K>(items: T[]): boolean {
    const areSomeSelected = items.some(child => child.state !== ECheckboxState.Unchecked);
    return areSomeSelected && !this.areAllItemsInState(items, ECheckboxState.Checked);
  }

  static getDescendants<T extends IFlatTreeNode<K>, K>(items: T[], node: T): T[] {
    const results: T[] = [];
    const index = items.indexOf(node);
    const nodesCount = items.length;

    for (let i = index + 1; i < nodesCount && node.level < items[i].level; i++) {
      results.push(items[i]);
    }

    return results;
  }

  static getChildrenByNode<T extends IFlatTreeNode<K>, K>(items: T[], node: T): T[] {
    const index = items.indexOf(node);
    const children = this.getChildrenByIndex(items, index);

    return children;
  }

  static getChildrenByIndex<T extends IFlatTreeNode<K>, K>(items: T[], index: number): T[] {
    const children: T[] = [];
    const itemLevel = items[index].level;
    const itemCount = items.length;

    for (let i = index + 1; i < itemCount && itemLevel < items[i].level; i++) {
      if (itemLevel === items[i].level - 1) {
        children.push(items[i]);
      }
    }

    return children;
  }
}
