/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IMapOptionsState } from '../state';
import { EVehicleStatusType } from '../../models';
import { getGarminStopsPermissionAllowed, getIsCustomBalloonsFeatureEnabled } from './user-map-settings.selectors';

export const getMapOptions = createFeatureSelector<IMapOptionsState>('mapOptions');

export const getIsSuggestedGeofencesEnabled = createSelector(
  getMapOptions,
  (state: IMapOptionsState): boolean => state.isSuggestedGeofencesEnabled
);

export const getIsGeofencesEnabled = createSelector(getMapOptions, (state: IMapOptionsState): boolean => state.isGeofencesEnabled);

export const getIsGarminStopsEnabled = createSelector(getMapOptions, (state: IMapOptionsState): boolean => state.isGarminStopsEnabled);

export const getIsGarminStopsDisplayEnabled = createSelector(
  getGarminStopsPermissionAllowed,
  getIsGarminStopsEnabled,
  (isWorkingGarminStopsFeatureAllowed: boolean, isGarminStopsEnabled: boolean) => {
    return isWorkingGarminStopsFeatureAllowed && isGarminStopsEnabled;
  }
);

export const getIsWorkOrdersEnabled = createSelector(getMapOptions, (state: IMapOptionsState): boolean => state.isWorkOrdersEnabled);

export const getIsShowLabelsEnabled = createSelector(getMapOptions, (state: IMapOptionsState): boolean => state.isShowLabelsEnabled);

export const getVehicleStatusFilterType = createSelector(
  getMapOptions,
  (state: IMapOptionsState): EVehicleStatusType => state.vehicleStatusFilterType
);

export const getIsShowTachoInformationEnabled = createSelector(
  getMapOptions,
  (state: IMapOptionsState): boolean => state.isShowTachoInformationEnabled
);

export const getIsMapOptionsOpened = createSelector(getMapOptions, (state: IMapOptionsState): boolean => state.isMapOptionsOpened);

export const getShouldShowBalloonLinksOption = createSelector(getIsCustomBalloonsFeatureEnabled, (customBalloonFeature): boolean => {
  return !customBalloonFeature;
});

export const getIsBalloonLinksEnabledFromMapOptions = createSelector(
  getMapOptions,
  (mapOptions: IMapOptionsState): boolean => mapOptions.isShowVehicleBalloonLinksEnabled
);

export const getShouldShowClusteringOption = createSelector(
  getMapOptions,
  (mapOptions: IMapOptionsState): boolean => mapOptions.shouldShowClusteringOption
);
