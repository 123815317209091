/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { GarminStopHistoryActions, EGarminStopHistoryActions } from '../actions';
import { initialGarminStopHistoryState, IGarminStopHistoryState } from '../state';

export function garminStopHistoryReducer(
  state = initialGarminStopHistoryState,
  action: GarminStopHistoryActions): IGarminStopHistoryState {
  switch (action.type) {
    case EGarminStopHistoryActions.GetGarminStopHistorySuccess: {
      return {
        ...state,
        history: action.payload
      };
    }
    default: return state;
  }
}
