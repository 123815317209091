/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { IApiResponseDto, IVehiclePlot } from '../../core/models';
import { IAppState } from '../../core/store/state';
import { getVehiclePlots } from '../../core/store/selectors';
import { ISearchLightCategory, ISearchLightResult, ESearchEntityType, ESearchEntitySubType } from '../../searchlight';

@Injectable()
export class SearchLightHttpInterceptor implements HttpInterceptor {
  constructor(private readonly _store: Store<IAppState>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url.endsWith('/SRCH/SearchLight/SearchLight')) {
      const searchText = req.params.get('request[SearchText]').toLowerCase();

      const vehiclesResults$ = this._getVehicleResults(searchText);

      const driversResults$ = this._getDriversResults(searchText);

      return forkJoin(vehiclesResults$, driversResults$).pipe(
        map(categories => categories.filter(category => category.items.length > 0)),
        map(
          searchLightCategories =>
            new HttpResponse<IApiResponseDto<ISearchLightCategory[]>>({ body: { Data: searchLightCategories, Message: null }, status: 200 })
        )
      );
    }
    return next.handle(req);
  }

  private _getDriversResults(searchText: string): Observable<ISearchLightCategory> {
    return this._store.pipe(
      select(getVehiclePlots),
      take(1),
      map(vehicles => vehicles.filter(v => v.driverDisplayName.toLowerCase().includes(searchText))),
      map((vehicles): ISearchLightResult[] =>
        vehicles.map(vehicle => this._vehiclePlotToSearchLightResult(vehicle, ESearchEntityType.Driver))
      ),
      map(
        (searchLightResults): ISearchLightCategory => ({
          entityType: 'Driver',
          localisedEntityType: 'Drivers',
          items: searchLightResults
        })
      )
    );
  }

  private _getVehicleResults(searchText: string): Observable<ISearchLightCategory> {
    return this._store.pipe(
      select(getVehiclePlots),
      take(1),
      map(vehicles => vehicles.filter(v => v.vehicleName.toLowerCase().includes(searchText))),
      map((vehicles): ISearchLightResult[] =>
        vehicles.map(vehicle => this._vehiclePlotToSearchLightResult(vehicle, ESearchEntityType.Vehicle))
      ),
      map(
        (searchLightResults): ISearchLightCategory => ({
          entityType: 'Vehicle',
          localisedEntityType: 'Vehicles',
          items: searchLightResults
        })
      )
    );
  }

  private _vehiclePlotToSearchLightResult(vehicle: IVehiclePlot, searchType: ESearchEntityType): ISearchLightResult {
    return {
      type: searchType,
      id: vehicle.id,
      text: searchType === ESearchEntityType.Driver ? vehicle.driverDisplayName : vehicle.vehicleName,
      addressComponents: vehicle.address,
      lat: vehicle.coordinates.lat,
      lng: vehicle.coordinates.lng,
      meta: null,
      subtype: ESearchEntitySubType.None
    };
  }
}
