/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgZone, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IGoogleMapsService } from './google-maps.service.interface';

@Injectable()
export class GoogleMapsService implements IGoogleMapsService {
  constructor(private readonly _zone: NgZone) {}

  public isStreetViewAvailable(latLng: google.maps.LatLng, radius = 50): Observable<boolean> {
    const response = new Subject<boolean>();
    const response$ = response.asObservable();
    const streetViewService = new google.maps.StreetViewService();
    streetViewService.getPanoramaByLocation(latLng, radius, (_, status) => {
      const isAvailable = status === google.maps.StreetViewStatus.OK;
      this._zone.run(() => {
        response.next(isAvailable);
      });
    });

    return response$;
  }
}
