/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ECheckboxState } from '@fleetmatics/ui.base-library';
import { isElementNull } from '@fleetmatics/ui.utilities';

import { ICustomerMetadataState } from '../state';
import {
  IMapHierarchyInfo,
  IMapHierarchyItem,
  ENodeType,
  EVehicleFuelType,
  EVehicleSelectionMode,
  MapHierarchyInfo,
  ETrackableClassification
} from '../../models';
import { getVehicleSelectionMode } from './vehicle-list-panel.selectors';

export const getCustomerMetadataState = createFeatureSelector<ICustomerMetadataState>('customerMetadata');

export const getMapHierarchyInfo = createSelector(
  getCustomerMetadataState,
  (state: ICustomerMetadataState): IMapHierarchyInfo => state.mapHierarchyInfo
);

export const getMapHierarchy = createSelector(getMapHierarchyInfo, (state): IMapHierarchyItem[] => state.mapHierarchy);

export const getRefreshPlots = createSelector(
  getCustomerMetadataState,
  (state: ICustomerMetadataState): boolean => !isElementNull(state.mapHierarchyOptions) && state.mapHierarchyOptions.refreshPlots
);

export const getMapHierarchyScoping = createSelector(
  getCustomerMetadataState,
  (state: ICustomerMetadataState): IMapHierarchyItem[] => state.mapHierarchyScoping
);

export const getUserSpeedingThreshold = createSelector(
  getCustomerMetadataState,
  (state: ICustomerMetadataState): number => state.userSpeedingThresholdKmh
);

export const getVehicleFuelTypesFromHierarchy = createSelector(
  getMapHierarchy,
  (mapHierarchy): Map<number, EVehicleFuelType> => {
    const vehicleFuelTypeMap = new Map<number, EVehicleFuelType>();
    mapHierarchy.forEach(mapHierarchyItem => {
      if (mapHierarchyItem.type === ENodeType.Vehicle) {
        const vehicleId = parseInt(mapHierarchyItem.id.slice(1), 10);
        vehicleFuelTypeMap.set(vehicleId, mapHierarchyItem.fuelType);
      }
    });
    return vehicleFuelTypeMap;
  }
);

export const getMapHierarchyCurrentVehicleSelectionMode = createSelector(
  getMapHierarchyInfo,
  getVehicleSelectionMode,
  (state: IMapHierarchyInfo, selectionMode: EVehicleSelectionMode): IMapHierarchyInfo =>
    getMapHierarchyByVehicleSelectionMode(state, selectionMode)
);

export const getHierarchyItemsMap = createSelector(
  getMapHierarchy,
  (mapHierarchy: IMapHierarchyItem[]): Map<ENodeType, Map<string, IMapHierarchyItem>> =>
    mapHierarchy.reduce((maps, item) => {
      const map = maps.get(item.type) ?? new Map<string, IMapHierarchyItem>();
      if (map.size === 0) {
        maps.set(item.type, map);
      }

      map.set(item.id, item);
      return maps;
    }, new Map<ENodeType, Map<string, IMapHierarchyItem>>())
);

export const getHierarchyItemsOfType = (type: ENodeType) =>
  createSelector(getHierarchyItemsMap, maps => maps.get(type) ?? new Map<string, IMapHierarchyItem>());

export const getHierarchyItemById = createSelector(
  getHierarchyItemsMap,
  (mapsByType: Map<ENodeType, Map<string, IMapHierarchyItem>>, id: string): IMapHierarchyItem => {
    return [...mapsByType.values()].find(map => map.has(id))?.get(id);
  }
);
export const getIdsOfHierarchy = createSelector(
  getMapHierarchyInfo,
  (mapHierarchy): Set<string> => new Set<string>(mapHierarchy.mapHierarchy.map(i => i.id))
);

export const getSelectedIdsOfTypes = (types: ENodeType[]) =>
  createSelector(
    getHierarchyItemsMap,
    (maps): Set<string> => {
      const checkedIds = types.reduce((ids, type) => {
        // get the items of each type
        const itemsOfType = maps.get(type);
        if (isElementNull(itemsOfType)) {
          return ids;
        }
        // take the ones that are checked and return the id
        const checkedIdsOfType = [...itemsOfType.values()].filter(i => i.state === ECheckboxState.Checked).map(item => item.id);
        return ids.concat(checkedIdsOfType);
      }, [] as string[]);

      // return as a set
      return new Set<string>(checkedIds);
    }
  );

export const getSelectedTrackableIds = getSelectedIdsOfTypes([
  ENodeType.Vehicle,
  ENodeType.Driver,
  ENodeType.PoweredAsset,
  ENodeType.Asset
]);

export const getSelectedVehicleIds = getSelectedIdsOfTypes([ENodeType.Vehicle, ENodeType.PoweredAsset]);

export const getSelectedAssetIds = getSelectedIdsOfTypes([ENodeType.Asset]);

export const getSelectedDriverIds = createSelector(
  getMapHierarchyInfo,
  (mapHierarchyInfo: IMapHierarchyInfo): Set<string> => {
    return new Set<string>(
      mapHierarchyInfo.mapHierarchy.filter(d => d.driverId && d.state === ECheckboxState.Checked).map(d => d.driverId.toString())
    );
  }
);

export const getAnyNonPoweredAssets = createSelector(getHierarchyItemsOfType(ENodeType.Asset), ids => {
  return ids.size > 0;
});

export const getAnyPoweredAssets = createSelector(getHierarchyItemsOfType(ENodeType.PoweredAsset), ids => {
  return ids.size > 0;
});

export const getTrackableClassificationFromHierarchy = createSelector(
  getMapHierarchy,
  (mapHierarchy): Map<string, ETrackableClassification> => {
    // Used as a fallback when the plot value is incorrect
    const trackableClassifications = new Map<string, ETrackableClassification>();
    mapHierarchy.forEach(mapHierarchyItem => {
      switch (mapHierarchyItem.type) {
        case ENodeType.Vehicle: {
          trackableClassifications.set(mapHierarchyItem.id, ETrackableClassification.Vehicle);
          break;
        }
        case ENodeType.PoweredAsset: {
          trackableClassifications.set(mapHierarchyItem.id, ETrackableClassification.PoweredAsset);
          break;
        }
        case ENodeType.Asset: {
          trackableClassifications.set(mapHierarchyItem.id, ETrackableClassification.NonPoweredAsset);
          break;
        }
        default:
          break;
      }
    });
    return trackableClassifications;
  }
);

export const getAccessiblePlotCount = createSelector(getHierarchyItemsMap, (maps): number => {
  const plottableNodeTypes = new Set([ENodeType.Asset, ENodeType.PoweredAsset, ENodeType.Vehicle]);
  let count = 0;
  plottableNodeTypes.forEach(type => {
    if (maps.has(type)) {
      count += maps.get(type).size;
    }
  });
  return count;
});

function getMapHierarchyByVehicleSelectionMode(state: IMapHierarchyInfo, selectionMode: EVehicleSelectionMode): IMapHierarchyInfo {
  // is this necessary? why not return state?
  const returnHierarchy = { ...state };
  if (selectionMode === EVehicleSelectionMode.EntireFleet) {
    returnHierarchy.mapHierarchy = MapHierarchyInfo.getMapHierarchyForEntireFleet(state.mapHierarchy);
  } else {
    returnHierarchy.mapHierarchy = MapHierarchyInfo.getMapHierarchyByNodeType(state.mapHierarchy, getNodeType(selectionMode));
  }

  return returnHierarchy;
}

function getNodeType(selectionMode: EVehicleSelectionMode): ENodeType {
  let nodeType: ENodeType;
  switch (selectionMode) {
    case EVehicleSelectionMode.Groups:
      nodeType = ENodeType.Group;
      break;
    case EVehicleSelectionMode.Assets:
      nodeType = ENodeType.Asset;
      break;
    case EVehicleSelectionMode.Vehicles:
      nodeType = ENodeType.Vehicle;
      break;
    case EVehicleSelectionMode.Drivers:
      nodeType = ENodeType.Driver;
      break;
    case EVehicleSelectionMode.PoweredAssets:
      nodeType = ENodeType.PoweredAsset;
      break;
  }
  return nodeType;
}
