<fm-title title="Verizon Connect | GPS Fleet Tracking System" i18n-title="@@S_SITE_PAGE_TITLE_VZ"></fm-title>

<ng-container *ngIf="isPlatformBrowser && (isVehicleDriverPanelLoaded$ | async)">
  <ng-container fmLoadModule="external"></ng-container>
  <span *ngIf="isRoadsideAssistanceAdmin$ | async" class="pendo-element" id="roadside-assistance-admin"></span>
  <span *ngIf="isRoadsideAssistanceReadOnly$ | async" class="pendo-element" id="roadside-assistance-read-only"></span>
</ng-container>

<fm-navbar-container *ngIf="!shouldDisableSsrApis()"></fm-navbar-container>

<vzc-side-drawer>
  <div
    *ngIf="isPlatformBrowser"
    class="main-content"
    [@expandMainWindow]="animationState"
    [class.main-content--sm]="!(isFullscreen$ | async) && (menuItems$ | async).length > 7"
    [class.main-content--fullscreen]="isFullscreen$ | async"
  >
    <router-outlet></router-outlet>
  </div>
</vzc-side-drawer>
