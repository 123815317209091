/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { DriverMessageActions, EDriverMessageActions } from '../actions';
import { initialDriverMessageState, IDriverMessageState } from '../state';

export function driverMessageReducer(
  state = initialDriverMessageState,
  action: DriverMessageActions): IDriverMessageState {
  switch (action.type) {
    case EDriverMessageActions.OpenDriverMessageModalSuccess: {
      return {
        ...state,
        driverDetails: action.payload,
        isEmailSent: null,
        isSmsSent: null,
        smsInvitation: null,
        isModalOpen: true,
      };
    }
    case EDriverMessageActions.GetSmsOptInStatusSuccess: {
      return {
        ...state,
        smsOptInStatus: action.payload
      };
    }

    case EDriverMessageActions.SendEmailToDriver: {
      return {
        ...state,
        isEmailSent: null
      };
    }

    case EDriverMessageActions.SendEmailToDriverSuccess: {
      return {
        ...state,
        isEmailSent: action.payload
      };
    }

    case EDriverMessageActions.CloseDriverMessageModal: {
      return {
        ...state,
        isModalOpen: false,
        isEmailSent: null,
        isSmsSent: null,
        smsInvitation: null
      };
    }

    case EDriverMessageActions.SendSmsInvitationToDriver: {
      return {
        ...state,
        smsInvitation: null
      };
    }

    case EDriverMessageActions.SendSmsInvitationToDriverSuccess: {
      return {
        ...state,
        smsInvitation: action.payload
      };
    }

    case EDriverMessageActions.SendSmsToDriver: {
      return {
        ...state,
        isSmsSent: null
      };
    }

    case EDriverMessageActions.SendSmsToDriverSuccess: {
      return {
        ...state,
        isSmsSent: action.payload
      };
    }

    default: return state;
  }
}
