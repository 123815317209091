/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { ILatLng } from './lat-lng.interface';
import { ICoordinates } from './coordinates.interface';

export class LatLng implements ILatLng {
  public static fromGoogleMaps(google: google.maps.LatLng): LatLng {
    return new LatLng(google.lat(), google.lng());
  }

  constructor(public lat: number, public lng: number) {}

  public static fromCoordinate(coordinate: ICoordinates): LatLng {
    return new LatLng(coordinate.Latitude, coordinate.Longitude);
  }

  public toGoogleMaps(): google.maps.LatLng {
    return new google.maps.LatLng(this.lat, this.lng);
  }

  public equals(latLng: LatLng) {
    return this.lat === latLng.lat && this.lng === latLng.lng;
  }
}
