/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@fleetmatics/ui.base-library';

import { TabsComponent } from './tabs.component';
import { TabDirective } from './tab/tab.directive';
import { TabContentDirective } from './tab/tab-content.directive';
import { TabHeadingDirective } from './tab/tab-heading.directive';
import { TranscludeDirective } from './tab/transclude.directive';

@NgModule({
  imports: [CommonModule, ButtonModule],
  declarations: [TabsComponent, TabContentDirective, TabDirective, TabHeadingDirective, TranscludeDirective],
  exports: [TabsComponent, TabContentDirective, TabDirective, TabHeadingDirective]
})
export class TabsModule { }
