/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ESearchType {
  Vehicle,
  Driver,
  Group,
  Asset,
  PoweredAsset
}
