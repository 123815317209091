/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  ECustomerMetadataActions,
  CustomerMetadataActions,
  ELayoutActions,
  LayoutActions,
  AssetsActions,
  EAssetsActions,
  EVehicleListPanelActions,
  VehicleListPanelActions
} from '../actions';
import { initialCustomerMetadataState, ICustomerMetadataState } from '../state';

export function customerMetadataReducer(
  state = initialCustomerMetadataState,
  action: CustomerMetadataActions | LayoutActions | AssetsActions | VehicleListPanelActions
): ICustomerMetadataState {
  switch (action.type) {
    case ECustomerMetadataActions.UpdateVehiclesSelectedSuccess:
    case ECustomerMetadataActions.UpdateVehicleAsSelectedSuccess:
    case EAssetsActions.UpdateAssetAsSelectedSuccess:
    case ELayoutActions.UpdateVehicleAsDeselectedSuccess:
    case ECustomerMetadataActions.GetMapHierarchySuccess: {
      return {
        ...state,
        mapHierarchyInfo: { ...action.payload }
      };
    }

    case ECustomerMetadataActions.GetMapHierarchy:
    case ECustomerMetadataActions.UpdateMapHierarchyOptions: {
      return {
        ...state,
        mapHierarchyOptions: { ...action.payload }
      };
    }

    case ECustomerMetadataActions.GetMapHierarchyScopingSuccess:
    case ECustomerMetadataActions.StoreMapHierarchyScoping: {
      return {
        ...state,
        mapHierarchyScoping: [...action.payload]
      };
    }

    case ECustomerMetadataActions.GetUserSpeedingThresholdSettingSuccess: {
      return {
        ...state,
        userSpeedingThresholdKmh: action.payload
      };
    }

    case EVehicleListPanelActions.UpdateVehicleSelectionMode: {
      return {
        ...state,
        mapHierarchyInfo: {
          ...state.mapHierarchyInfo,
          isNewMapHierarchy: true
        }
      };
    }

    default:
      return state;
  }
}
