/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of, timer, forkJoin } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, takeUntil, take, catchError } from 'rxjs/operators';

import { ConfigService, isArrayNullOrEmpty, isElementNull } from '@fleetmatics/ui.utilities';

import {
  ECustomerMetadataActions,
  GetVehiclePlots,
  EVehicleActions,
  GetVehiclesSortedSuccess,
  UpdateVehicleAsSelectedSuccess,
  UpdateVehicleAsDeselectedSuccess,
  ELayoutActions,
  UpdateVehiclesSortType,
  GetVehiclePlotsSuccess,
  EMapPlotsActions,
  UpdateVehicleStatusFilterType,
  EMapOptionsActions,
  GetLabelsSuccess,
  GetLabels,
  EBalloonInfoActions,
  GetAccessibleVehiclesCount,
  GetAccessibleVehiclesCountSuccess,
  StartVehiclesPolling,
  StopVehiclesPolling,
  GetGarminStopsPlots,
  StartGarminStopsPolling,
  GetAssetPlotsSuccess,
  EAssetsActions,
  UpdateVehiclesSuccess
} from '../actions';
import {
  getVehiclePlots,
  getVehiclesSortType,
  getPlotsInBounds,
  getIsShowLabelsEnabled,
  getMapVehicleMarkers,
  getMapHierarchyInfo,
  getIsGarminStopsDisplayEnabled,
  getAssetPlotLabels,
  getStatusPanelPlots,
  getVehiclesIdsSorted
} from '../selectors';
import { IAppState } from '../state';
import { EVehiclesSortType, EVehicleStatusType, ILabel, IStatusPanelItem } from '../../models';
import { VehicleSortService, BalloonService, PlotHttpService } from '../../services';
import { delayedRetry } from '../../operators';
import { IAppConfig } from '../../../config';

const GET_VEHICLE_INTERVAL = 90000;
const GET_GARMIN_STOPS_INTERVAL = 60000;

@Injectable()
export class VehicleEffects {

  public getVehiclePlotsByPolling$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<StartVehiclesPolling>(EVehicleActions.StartVehiclesPolling),
    filter(() => isPlatformBrowser(this._platformId)),
    switchMap(() =>
      this._store.pipe(
        select(getMapHierarchyInfo),
        filter(mapHierarchyInfo => mapHierarchyInfo.mapHierarchy.length > 0),
        take(1)
      )
    ),
    switchMap(() =>
      timer(0, GET_VEHICLE_INTERVAL).pipe(takeUntil(this._actions$.pipe(ofType<StopVehiclesPolling>(EVehicleActions.StopVehiclesPolling))))
    ),
    map(() => new GetVehiclePlots())
  ));

  public getGarminStopsByPolling$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<StartGarminStopsPolling>(EMapPlotsActions.StartGarminStopsPolling),
    filter(() => isPlatformBrowser(this._platformId)),
    switchMap(() =>
      timer(0, GET_GARMIN_STOPS_INTERVAL).pipe(
        takeUntil(
          this._store.pipe(
            select(getIsGarminStopsDisplayEnabled),
            filter(isGarminStopsDisplayEnabled => !isGarminStopsDisplayEnabled)
          )
        )
      )
    ),
    map(() => new GetGarminStopsPlots())
  ));

  public getMapVehiclesLabels$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetLabels>(EBalloonInfoActions.GetLabels),
    withLatestFrom(
      this._store.pipe(select(getPlotsInBounds)),
      this._store.pipe(select(getAssetPlotLabels)),
      this._store.pipe(select(getIsShowLabelsEnabled))
    ),
    filter(([, , , isLabelEnabled]) => isLabelEnabled),
    switchMap(([, plotsInBounds, assetPlotLabels]) => {
      const labels: Observable<ILabel[]> = !isArrayNullOrEmpty(plotsInBounds) ? this._balloonService.getLabels(plotsInBounds) : of([]);
      const assetPlotLabelsObsrvable = of(assetPlotLabels);

      const labelObservable: Observable<ILabel[]> = labels.pipe(
        delayedRetry(),
        catchError(() => of([]))
      );

      return forkJoin([assetPlotLabelsObsrvable, labelObservable]);
    }),
    map(([assetPlotLabelsObsrvable, labelObservable]) => [...assetPlotLabelsObsrvable, ...labelObservable]),
    filter(response => !isArrayNullOrEmpty(response)),
    map((labels: ILabel[]) => new GetLabelsSuccess(labels))
  ));

  public updateVehicleStatusFilterType$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<UpdateVehicleStatusFilterType>(EMapOptionsActions.UpdateVehicleStatusFilterType),
    withLatestFrom(this._store.pipe(select(getMapVehicleMarkers)), this._store.pipe(select(getIsShowLabelsEnabled))),
    filter(([, , isLabelEnabled]) => isLabelEnabled),
    switchMap(([action, allPlots]) => {
      const labels = this._balloonService.getLabels(
        allPlots.filter(plot => action.payload === EVehicleStatusType.All || plot.status === action.payload)
      );
      return labels.pipe(
        delayedRetry(),
        catchError(() => of(null))
      );
    }),
    filter(response => !isElementNull(response)),
    map((labels: ILabel[]) => new GetLabelsSuccess(labels))
  ));

  public updateVehiclesSelectedSuccess$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<UpdateVehicleAsSelectedSuccess | UpdateVehicleAsDeselectedSuccess>(
      ECustomerMetadataActions.UpdateVehicleAsSelectedSuccess,
      ELayoutActions.UpdateVehicleAsDeselectedSuccess
    ),
    switchMap(() => [new GetVehiclePlots(), new GetGarminStopsPlots()])
  ));

  public getAccessibleVehiclesCount$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetAccessibleVehiclesCount>(EVehicleActions.GetAccessibleVehiclesCount),
    filter(() => isPlatformBrowser(this._platformId)),
    switchMap(() =>
      this._plotHttpService.getAccessibleVehiclesCount().pipe(
        delayedRetry(),
        catchError(() => of(null))
      )
    ),
    filter(accessibleVehiclesCount => !isElementNull(accessibleVehiclesCount)),
    map((accessibleVehiclesCount: number) => new GetAccessibleVehiclesCountSuccess(accessibleVehiclesCount))
  ));

  public sortStatusPanelItems$ = createEffect(() => this._actions$.pipe(
    ofType<UpdateVehiclesSortType | GetVehiclePlotsSuccess | GetAssetPlotsSuccess | UpdateVehiclesSuccess>(
      EVehicleActions.UpdateVehiclesSortType,
      EMapPlotsActions.GetVehiclePlotsSuccess,
      EAssetsActions.GetAssetPlotsSuccess,
      EVehicleActions.UpdateVehiclesSuccess
    ),
    withLatestFrom(this._getPanelItems$(), this._store.pipe(select(getVehiclesSortType)), this._store.pipe(select(getVehiclesIdsSorted))),
    filter(
      ([action, statusPanelItems, sortType, vehiclesIdsSorted]) =>
        // hasn't been sorted before or vehicles added / removed
        statusPanelItems.length !== vehiclesIdsSorted.length ||
        // always update sorting when sorting type changes
        action.type === EVehicleActions.UpdateVehiclesSortType ||
        // if it is a vehicle / assets update, only update if the sorting is in:
        sortType === EVehiclesSortType.LastUpdate ||
        sortType === EVehiclesSortType.Status
    ),
    map(([, statusPanelItems, type]) => this._sortService.sortItems(type, statusPanelItems)),
    filter(itemsIds => !isElementNull(itemsIds)),
    map((itemsIds: number[]) => new GetVehiclesSortedSuccess(itemsIds))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _sortService: VehicleSortService,
    private readonly _balloonService: BalloonService,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _plotHttpService: PlotHttpService,
    private readonly _configService: ConfigService<IAppConfig>
  ) {}

  private _getPanelItems$(): Observable<IStatusPanelItem[]> {
    return this._configService.config.isAssetsOnPanelEnabled
      ? this._store.pipe(select(getStatusPanelPlots))
      : (this._store.pipe(select(getVehiclePlots)) as Observable<IStatusPanelItem[]>);
  }
}
