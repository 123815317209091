/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { DataSource, CollectionViewer, ListRange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Observable, BehaviorSubject, merge } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';

export class FlatTreeDataSource<T> extends DataSource<T> {
  get data() {
    return this._data.value;
  }
  set data(value: T[]) {
    this._data.next(value);
    this._treeControl.dataNodes = this.data;
  }

  private readonly _data = new BehaviorSubject<T[]>([]);
  private _range: ListRange;
  private readonly _range$: Observable<ListRange>;

  constructor(
    private readonly _treeControl: FlatTreeControl<T>,
    initialData: T[],
    range$: Observable<ListRange>,
    private readonly _defaultRange: ListRange
  ) {
    super();
    this._data = new BehaviorSubject<T[]>(initialData);
    this._treeControl.dataNodes = this.data;
    this._range$ = range$.pipe(tap(range => (this._range = range)));
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    const changes = [collectionViewer.viewChange, this._treeControl.expansionModel.changed, this._data, this._range$];

    return merge(...changes).pipe(
      filter(() => !!this._data.value),
      map(() => this._updateExpandedNodes())
    );
  }

  disconnect(collectionViewer: CollectionViewer): void {
    // no op
  }

  private _updateExpandedNodes(): T[] {
    const results: T[] = [];
    const levelExpansion: boolean[] = [];
    levelExpansion[0] = true;

    const range = !!this._range ? this._range : this._defaultRange;

    let expandedCount = 0;
    const itemsCount = this._data.value.length;
    for (let i = 0; i < itemsCount && expandedCount <= range.end; i++) {
      const node = this._data.value[i];
      let expand = true;
      for (let j = 0; j <= this._treeControl.getLevel(node); j++) {
        expand = expand && levelExpansion[j];
      }
      if (expand) {
        if (expandedCount >= range.start && expandedCount <= range.end) {
          results.push(node);
        }
        expandedCount++;
      }

      if (this._treeControl.isExpandable(node)) {
        levelExpansion[this._treeControl.getLevel(node) + 1] = this._treeControl.isExpanded(node);
      }
    }

    return results;
  }
}
