/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IBalloonService } from './balloon-http.service.interface';
import {
  EPlotType,
  MarkerInfo,
  IApiResponseDto,
  IMapBalloonResponse,
  EEntityType,
  ILoadGarminStopBalloonResponse,
  IVehiclesDriverNameResponse,
  ILabel
} from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';

@Injectable()
export class BalloonService implements IBalloonService {
  private readonly _rootUrl: string;
  private readonly _rootUrlV2: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = `${_configService.config.endpoints.balloonApiEndpoint}/Balloon`;
    this._rootUrlV2 = `${_configService.config.endpoints.balloonApiEndpoint}/v2/Balloon`;
  }

  public getLabels(plotsInBounds: MarkerInfo[]): Observable<ILabel[]> {
    let entityIds = '';
    plotsInBounds.forEach(plot => {
      switch (plot.type) {
        case EPlotType.vehicle:
        case EPlotType.poweredAsset:
          entityIds += EEntityType.vehicle + '_' + plot.id + ',';
          break;
        case EPlotType.workOrder:
          entityIds += EEntityType.workOrder + '_' + plot.id + ',';
          break;
      }
    });

    const data = {
      EntityIds: entityIds
    };

    return this._httpClient.post<ILabel[]>(`${this._rootUrlV2}/LoadLabel/`, data);
  }

  public getGarminStopBalloon(id: number, vehicleId: number): Observable<ILoadGarminStopBalloonResponse> {
    let params = new HttpParams();
    params = params.append('request[PlotId]', id.toString());
    params = params.append('request[Type]', EPlotType.garmin.toString());
    params = params.append('request[ExtraId]', vehicleId.toString());
    return this._httpClient.get<ILoadGarminStopBalloonResponse>(`${this._rootUrl}/LoadGarminStopBalloon`, { params });
  }

  public getMapBalloon(id: string): Observable<IMapBalloonResponse> {
    const body = { BalloonIds: id };
    return this._httpClient
      .post<IApiResponseDto<IMapBalloonResponse>>(`${this._rootUrl}/MapBalloon`, body)
      .pipe(map((response: IApiResponseDto<IMapBalloonResponse>) => response.Data));
  }

  public getVehiclesDriverName(vehicleIds: number[]): Observable<IVehiclesDriverNameResponse[]> {
    return this._httpClient.post<IVehiclesDriverNameResponse[]>(`${this._rootUrlV2}/GetVehiclesDriverName`, { VehicleIds: vehicleIds });
  }
}
