/*
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'fm-pendo-guides',
  templateUrl: './pendo-guides.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PendoGuidesComponent {}
