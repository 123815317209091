/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadModuleDirective } from './load-module.directive';
import { LAZY_LOADER_TOKEN } from './lazy-loader.token';
import { configureLazyLoader } from './lazy-loader.config';

@NgModule({
  declarations: [LoadModuleDirective],
  imports: [CommonModule],
  exports: [LoadModuleDirective],
  providers: [{ provide: LAZY_LOADER_TOKEN, useFactory: configureLazyLoader }]
})
export class LazyLoaderModule {}
