/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { trigger, state, style, transition, animate, query, animateChild, group } from '@angular/animations';

const BASIC_SPEED_MS = 192 * 2;

const replayMapOverlayHide = '@replayMapOverlayHide';
const expandMainWindow = '@expandMainWindow';
const hideNavbar = '@hideNavbar';
const panelState = '@panelState';

const mapOverlays = '.fab-overlay-controls, .searchlight-area-container, .fit-to-map-container,  fm-vehicle-driver-panel-large';
export const MapAnimations = {
  appAnimation: trigger('appAnimation', [
    // default to replay
    transition('default => overlayHidden', [
      group([
        query(replayMapOverlayHide, [animateChild()]),
        query(expandMainWindow, [animateChild({ delay: BASIC_SPEED_MS })]),
        query(hideNavbar, [animateChild({ delay: BASIC_SPEED_MS })], { optional: true })
      ])
    ]),
    transition('overlayHidden => replayPanelClosed, replayPanelOpen => replayPanelClosed', [
      group([query('@replayPanel', [animateChild()], { optional: true }), query(expandMainWindow, [animateChild()])])
    ]),
    transition('replayPanelClosed => default', [
      group([
        query(replayMapOverlayHide, [animateChild()]),
        query(expandMainWindow, [animateChild()]),
        query(hideNavbar, [animateChild()], { optional: true })
      ])
    ]),
    // default to fullscreen
    transition('default => fullscreen', [
      query(panelState, [animateChild()], { optional: true }),
      group([query(expandMainWindow, [animateChild()], { optional: true }), query(hideNavbar, [animateChild()])])
    ]),
    transition('fullscreen => default', [
      group([query(expandMainWindow, [animateChild()]), query(hideNavbar, [animateChild()])]),
      query(panelState, [animateChild()], { optional: true })
    ]),
    // fullscreen to replay
    transition('fullscreen => overlayHidden', [query(replayMapOverlayHide, [animateChild()])])
  ]),
  expandMainWindow: trigger('expandMainWindow', [
    // default to fullscreen / replay
    transition('default => fullscreen, default => overlayHidden', [
      style({ height: '{{mainWindowHeight}}px', top: '{{navbarHeight}}px' }),
      animate(`${BASIC_SPEED_MS}ms ease-out`, style({ height: '100%', top: '0px' }))
    ]),
    transition('replayPanelClosed => default, fullscreen => default', [
      style({ height: '100%', top: '0px' }),
      animate(`${BASIC_SPEED_MS}ms ease-out`, style({ height: '{{mainWindowHeight}}px', top: '{{navbarHeight}}px' }))
    ]),
    transition('overlayHidden => replayPanelClosed', [
      query('.fm-map-container', [
        style({ width: '100%' }),
        animate(`${BASIC_SPEED_MS}ms ease`, style({ width: '{{mainWindowWidthPanelOpen}}px' }))
      ])
    ]),
    transition('replayPanelOpen => replayPanelClosed', [
      query('.fm-map-container', [
        style({ width: '{{mainWindowWidthPanelOpen}}px' }),
        animate(`${BASIC_SPEED_MS}ms ease`, style({ width: '100%' }))
      ])
    ])
  ]),
  replayPanel: trigger('replayPanel', [
    transition(':enter', [
      style({ right: '-360px', width: '100%', height: '100%', position: 'absolute', zIndex: 1003 }),
      animate(`${BASIC_SPEED_MS}ms ease`, style({ right: '0px' }))
    ]),
    transition(':leave', [
      style({ right: '0px', width: '100%', height: '100%', position: 'absolute', zIndex: 1003 }),
      animate(`${BASIC_SPEED_MS}ms ease`, style({ right: '-360px' }))
    ])
  ]),
  replayMapOverlayHide: trigger('replayMapOverlayHide', [
    state('default', style({ display: 'block' })),
    state('replayPanelClosed', style({ display: 'none' })),
    state('replayPanelOpen', style({ display: 'none' })),
    transition('default => overlayHidden', [
      query(panelState, [animateChild()], { optional: true }),
      query(mapOverlays, [style({ top: '1rem' }), animate(`${BASIC_SPEED_MS}ms ease-in`, style({ top: '-2.5rem' }))])
    ]),
    transition('fullscreen => overlayHidden', [
      style({ display: 'block' }),
      query(mapOverlays, [style({ top: '1rem' }), animate(`${BASIC_SPEED_MS}ms ease-in`, style({ top: '-2.5rem' }))])
    ]),
    transition('replayPanelClosed => default', [
      style({ display: 'block' }),
      query(mapOverlays, [style({ top: '-2.5rem' }), animate(`${BASIC_SPEED_MS}ms  ease-out`, style({ top: '1rem' }))], {
        delay: BASIC_SPEED_MS
      }),
      query(panelState, [animateChild()], { optional: true })
    ]),
    transition('overlayHidden => fullscreen', [
      style({ display: 'block' }),
      query(mapOverlays, [style({ top: '-2.5rem' }), animate(`${BASIC_SPEED_MS}ms  ease-out`, style({ top: '1rem' }))])
    ])
  ]),
  hideNavbar: trigger('hideNavbar', [
    state('visible', style({ top: '0px' })),
    state('hidden', style({ top: '-{{navbarHeight}}px' }), { params: { navbarHeight: 80 } }),
    transition('hidden  <=> visible', [animate(`${BASIC_SPEED_MS}ms ease-out`)])
  ]),
  panelState: trigger('panelState', [
    state('expanded', style({ height: '{{panelHeight}}' }), { params: { panelHeight: '*' } }),
    state('collapsed', style({ height: '40px' })),
    transition('expanded <=> collapsed', [animate(`${BASIC_SPEED_MS}ms ease-out`)])
  ])
};
