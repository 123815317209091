<div class="notifications-wrapper"
  *ngIf="notificationsEnabled"
  [class.notifications-wrapper-with-btn]="(nonPanicNotifications$ | async)?.length > 4">
  <ds-button class="notifications-wrapper-close"
    [@onClean]="notificationWrapper"
    *ngIf="(nonPanicNotifications$ | async)?.length > 4 && (shouldShowNonPanicNotifications$ | async)"
    id="btn-closeAllNotifications"
    i18n="@@S_CLOSEALL"
    text="Close All"
    componentStyle="secondary"
    size="small"
    (click)="cleanAll()">
  </ds-button>
  <div [@onClean]="notificationWrapper"
    *ngIf="!showPanicAlertsOnly && (shouldShowNonPanicNotifications$ | async)"
    [class.notifications-wrapper__container-with-btn]="(nonPanicNotifications$ | async)?.length > 4"
    class="notifications-wrapper__container notifications-wrapper__container-default">
    <div class="notification device-status device-status-minimized"
      *ngIf="
        isDeviceStatusModalMinimized &&
        deviceStatusNotificationsEnabled &&
        deviceStatusNotificationsToReview.length > 0
      "
      (click)="openDeviceStatusModal()">
      <span class="device-status-minimized-text"
        i18n="@@S_DEVICESTATUS_MINIMIZED_NOTIFICATION">
        DEVICE STATUS ({{ deviceStatusNotificationsToReview.length }})
      </span>
      <mat-icon svgIcon="ic-expand"
        class="notification__close-button mat-icon-size-xs"
        id="btn-closeNotification"></mat-icon>
    </div>
    <div class="notification device-status"
      (click)="openDeviceStatusModal()"
      *ngIf="
        !isDeviceStatusModalMinimized &&
        !isDeviceStatusModalOpen &&
        deviceStatusNotificationsEnabled &&
        deviceStatusNotificationsToReview.length > 0
      ">
      <mat-icon svgIcon="ic-exclamation"
        class="notification-header__icon mat-icon-size-xs">
      </mat-icon>
      <p class="notification-header__title notification__content-device-status-text"
        i18n="@@S_DEVICESTATUS_CHANGE_DETECTED">
        Device Status change detected
      </p>
      <ds-button id="btn-viewDeviceNotification"
        class="notification__content-device-status-btn"
        i18n-text="@@S_DEVICESTATUS_CHANGE_VIEW"
        text="VIEW"
        componentStyle="secondary"
        size="extraSmall">
      </ds-button>
    </div>
    <fm-notification *ngFor="let notification of (nonPanicNotifications$ | async)"
      [data]="notification"
      [invokeClose]="notificationsToClose.has(notification.id)"
      (clicked)="onClick($event)"
      (removeNotification)="removeNotification($event)"
      (closeNotification)="closeNotification($event)">
    </fm-notification>
  </div>
  <div class="notifications-wrapper__container"
    *ngIf="(panicNotifications$ | async)?.length > 0">
    <fm-notification *ngFor="let notification of (panicNotifications$ | async)"
      [data]="notification"
      (clicked)="onClick($event)"
      (removeNotification)="removeNotification($event)"
      (closeNotification)="closeNotification($event)">
    </fm-notification>
  </div>
</div>

<ng-template #notificationDetails>
  <fm-notification-details [details]="notificationDetails$ | async"
    (modalClosed)="onCloseModal()"></fm-notification-details>
</ng-template>

<ng-template #deviceStatus>
  <fm-device-status [devicesStatusToReview]="deviceStatusNotificationsToReview"
    (notificationAcknowledged)="onDeviceStatusNotificationAcknowledged($event)"
    (notificationDismissed)="onDeviceStatusNotificationDismissed($event)"
    (notificationNotAcknowledged)="onDeviceStatusNotificationNotAcknowledged($event)"
    (showOnMapClicked)="showOnMap($event)"></fm-device-status>
</ng-template>
