<div class="tab-headers-left-actions">
  <div class="tab-headers-left-actions__load-previous"
    *ngIf="showLoadPreviousTabs">
    <ds-button size="small"
      class="tab-headers-left-actions__load-previous-button"
      componentStyle="tertiary"
      icon="ds-ic-arrowLeft"
      (clickBtn)="loadPreviousTabs()">
    </ds-button>
  </div>
</div>
<div class="tab-headers-container"
  #headerContainer>
  <ul class="tab-headers"
    #tabHeadersContainer
    [ngStyle]="{ 'padding-left.px': paddingOffset, 'padding-right.px': paddingOffset }">
    <li *ngFor="let tab of tabs"
      #tabHeader
      class="tab-header"
      [class.tab-header--active]="tab.active"
      [class.tab-header--fitted]="shouldFitTabs">
      <a href="javascript:void(0);"
        class="tab-header__link"
        [id]="tab.id"
        i18n-aria-label="tab.ariaLabelTranslation"
        [attr.aria-label]="tab.ariaLabelName"
        (click)="activateTab(tab)">
        <span [fmTransclude]="{ template: tab.headingRef, context: tab.tabContext }">{{ tab.heading }}</span>
      </a>
    </li>
  </ul>
</div>
<div class="tab-headers-right-actions">
  <div class="tab-headers-right-actions__load-more"
    *ngIf="showLoadMoreTabs">
    <ds-button size="small"
      class="tab-headers-right-actions__load-more-button"
      componentStyle="tertiary"
      icon="ds-ic-arrowRight"
      (clickBtn)="loadMoreTabs()">
    </ds-button>
  </div>
</div>
<hr class="tab-separator" />
<div class="tab-content">
  <span [fmTransclude]="{ template: activeTab?.contentRef, context: activeTab?.tabContext, id: activeTab.id, shouldDestroyOnDeactivation: activeTab?.shouldDestroyOnDeactivation }"></span>
</div>
