/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IVehicleState } from '../state';
import { EVehiclesSortType, MarkerInfo, ITowingVehicle } from '../../models';
import { IDeviceStatusTowedVehicle } from '../../components';

export const getVehiclesState = createFeatureSelector<IVehicleState>('vehicle');

export const getVehiclesIdsSorted = createSelector(
  getVehiclesState,
  (state: IVehicleState): number[] => state.sortedVehiclesIds
);

export const getVehiclesSortType = createSelector(
  getVehiclesState,
  (state: IVehicleState): EVehiclesSortType => state.vehiclesSortType
);

export const getPlotsInBounds = createSelector(
  getVehiclesState,
  (state: IVehicleState): MarkerInfo[] => state.plotsInBounds
);

export const getAccessibleVehiclesCount = createSelector(
  getVehiclesState,
  (state: IVehicleState): number => state.accessibleVehiclesCount
);

export const getTowingVehicles = createSelector(
  getVehiclesState,
  (state: IVehicleState): ITowingVehicle[] => state.towingVehicles
);

export const getDeviceStatusTowedVehicles = createSelector(
  getTowingVehicles,
  (towingVehicles: ITowingVehicle[]): IDeviceStatusTowedVehicle[] =>
    towingVehicles.map(towingVehicle => ({
      actionType: towingVehicle.actionType,
      troubleCode: towingVehicle.troubleCode,
      vehicleId: towingVehicle.id,
      lastMessageTime: towingVehicle.lastUpdatedTime,
      originalClass: towingVehicle.originalIconClass
    }))
);
