/*!
 * Copyright © 2021. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EReplaySegmentStatusIcon {
  Start = 'ic-replay-start',
  Moving = 'ic-moving-ne-segment',
  Stopped = 'ic-stop-segment',
  Idle = 'ic-idle-segment',
  End = 'ic-replay-end',
  Private = 'ic-private-segment',
  InProgress = 'ic-in-progress',
  MovingPoweredAsset = 'ic-moving-ne-powered-asset-segment',
  StoppedPoweredAsset = 'ic-stop-powered-asset-segment',
  IdlePoweredAsset = 'ic-idle-powered-asset-segment'
}
