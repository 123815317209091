/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

export const enum EModulesActions {
  MapModuleLoaded = '[Modules] Map Module Loaded',
  MapOptionsModuleLoaded = '[Modules] Map Options Module Loaded',
  ReplayModuleLoaded = '[Modules] Replay Modules Loaded',
  VehicleDriverPanelModuleLoaded = '[Modules] Vehicle Driver Panel Module Loaded',
  ExternalModuleLoaded = '[Modules] External Module Loaded',
  OverlayControlsModuleLoaded = '[Modules] Overlay Controls Module Loaded'
}

export class MapModuleLoaded implements Action {
  public readonly type = EModulesActions.MapModuleLoaded;
}

export class MapOptionsModuleLoaded implements Action {
  public readonly type = EModulesActions.MapOptionsModuleLoaded;
}

export class ReplayModuleLoaded implements Action {
  public readonly type = EModulesActions.ReplayModuleLoaded;
}

export class VehicleDriverPanelModuleLoaded implements Action {
  public readonly type = EModulesActions.VehicleDriverPanelModuleLoaded;
}

export class ExternalModuleLoaded implements Action {
  public readonly type = EModulesActions.ExternalModuleLoaded;
}

export class OverlayControlsModuleLoaded implements Action {
  public readonly type = EModulesActions.OverlayControlsModuleLoaded;
}

export type ModulesActions =
  | MapModuleLoaded
  | MapOptionsModuleLoaded
  | ReplayModuleLoaded
  | VehicleDriverPanelModuleLoaded
  | ExternalModuleLoaded
  | OverlayControlsModuleLoaded;
