/*
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ESearchEntityType {
  Undefined = 0,
  Vehicle = 1,
  Driver = 2,
  Place = 4,
  WorkOrder = 8,
  Address = 16,
  NavigationEnabledVehicles = 32,
  DriverWithoutVehicleFilter = 64,
  Asset = 128,
  PoweredAsset = 256
}
