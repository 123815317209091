/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgModule } from '@angular/core';

import { RadioButtonComponent, RadioGroupComponent } from './radio-button.component';

@NgModule({
  declarations: [RadioButtonComponent, RadioGroupComponent],
  exports: [RadioButtonComponent, RadioGroupComponent]
})
export class RadioButtonModule {}
