/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isElementNull } from '@fleetmatics/ui.utilities';

import { ReverseGeocodeActions, EReverseGeocodeActions } from '../actions';
import { initialReverseGeocodeState, IReverseGeocodeState } from '../state';

export function reverseGeocodeReducer(state = initialReverseGeocodeState, action: ReverseGeocodeActions): IReverseGeocodeState {
  switch (action.type) {
    case EReverseGeocodeActions.RemoveExecutionBatch:
      return {
        ...state,
        executionBatches: state.executionBatches.filter(executionBatch => executionBatch.id !== action.payload)
      };

    case EReverseGeocodeActions.ReverseGeocodeVehiclePlots:
      return {
        ...state,
        executionBatches: [...state.executionBatches, action.payload]
      };

    case EReverseGeocodeActions.UpdateExecutionBatches:
      const resultantState = { ...state };
      const updatedBatches = action.payload;
      const totalBatches = resultantState.executionBatches.length;
      const totalUpdatedBatches = updatedBatches.length;
      let updatedBatchesCount = 0;
      for (let i = 0; i < totalBatches && updatedBatchesCount < totalUpdatedBatches; i++) {
        const executionBatch = resultantState.executionBatches[i];
        const updatedBatch = updatedBatches.find(batch => batch.id === executionBatch.id);
        if (!isElementNull(updatedBatch)) {
          resultantState.executionBatches[i] = updatedBatch;
          updatedBatchesCount++;
        }
      }

      return resultantState;

    default:
      return state;
  }
}
