/*
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Provider,
  forwardRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  EventEmitter,
  Output,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Size } from '@fleetmatics/ui.base-library';

import { ISearchLightComponent } from '../../search-light-component.interface';
import { ESearchEntityType, ISearchLightRequestOptions, ISearchLightResult } from '../../model';
import { FmSearchLightComponent } from '../../search-light.component';
import { ISearchLightInput } from '../search-light-input';

export const SEARCH_LIGHT_FORM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-forward-ref
  useExisting: forwardRef(() => FmSearchLightFormComponent),
  multi: true
};

@Component({
  selector: 'fm-search-light-form',
  templateUrl: './search-light-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SEARCH_LIGHT_FORM_VALUE_ACCESSOR]
})
export class FmSearchLightFormComponent implements ISearchLightComponent, AfterViewInit {
  @Input()
  public errorMessage: string;
  @Input()
  public entityTypes: ESearchEntityType;
  @Input()
  public initialDisplayValue: string;
  @Input()
  public minCharsMessage: string;
  @Input()
  public noResultMessage: string;
  @Input()
  public options: ISearchLightRequestOptions;
  @Input()
  public placeholder: string;
  @Input()
  public resultsWidth: number;
  @Input()
  public set size(value: Size) {
    this._setInputSizeCssClass(value);
  }

  @Output()
  public searchTextChange = new EventEmitter<string>();

  @ViewChild(FmSearchLightComponent, { static: false })
  public searchLightComponent: FmSearchLightComponent;

  public cssClasses = 'ds-input';
  public model: ISearchLightResult;
  public panelWidth: number;

  public onChange: (model: ISearchLightResult) => void;
  public onTouch: () => void;

  @ViewChild('searchLightInput', { read: ElementRef, static: false })
  public _inputElement: ElementRef;
  @ViewChild('searchLightInput', { static: false })
  public _input: ISearchLightInput;

  constructor(@Inject(PLATFORM_ID) private readonly _platformId: Object) {}

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.panelWidth = this.resultsWidth || this._inputElement.nativeElement.getBoundingClientRect().width;
      this._input.registerOnChange(() => {});
    }
  }

  public writeValue(model: ISearchLightResult): void {
    if (model !== undefined && model !== null) {
      this.model = model;
    }
  }

  public registerOnChange(onChange: (model: ISearchLightResult) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouch: () => void): void {
    this.onTouch = onTouch;
  }

  public onModelChanged(model: ISearchLightResult): void {
    this.model = model;
    this.onChange(model);
  }

  public getResults(): void {
    this.searchLightComponent.getResults();
  }

  public onClose(): void {
    this.searchLightComponent.close();
  }

  public onSearchTextChange(text: string): void {
    this.searchTextChange.emit(text);
  }

  private _setInputSizeCssClass(size: Size): void {
    switch (size) {
      case 'extraSmall':
        this.cssClasses += ' ds-input-xs';
        break;
      case 'small':
        this.cssClasses += ' ds-input-sm';
        break;
      case 'medium':
        this.cssClasses += ' ds-input-md';
        break;
      case 'large':
        this.cssClasses += ' ds-input-lg';
        break;
      case 'extraLarge':
        this.cssClasses += ' ds-input-xl';
        break;
      case 'extraExtraLarge':
        this.cssClasses += ' ds-input-xxl';
        break;
      case 'special':
        this.cssClasses += ' ds-input-special';
        break;

      default:
        this.cssClasses += ' ds-input-sm';
        break;
    }
  }
}
