/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { DemoActions, EDemoActions } from './demo.actions';
import { initialDemoState, IDemoState } from './demo.state';

export function demoReducer(state = initialDemoState, action: DemoActions): IDemoState {
  switch (action.type) {
    case EDemoActions.UpdateDemoPlots:
      return {
        ...state,
        plots: action.payload
      };

    case EDemoActions.UpdateDemoHierarchy:
      return {
        ...state,
        demoHierarchy: {
          ...state.demoHierarchy,
          hierarchyItems: action.payload
        }
      };

    case EDemoActions.UpdateStoryProgressSuccess:
      return {
        ...state,
        progress: {
          ...state.progress,
          stories: state.progress.stories.map(story => (story.name === action.payload.name ? action.payload : story))
        }
      };

    case EDemoActions.UpdateDemoProgress:
      return {
        ...state,
        progress: action.payload
      };

    case EDemoActions.DismissStory:
      return {
        ...state,
        progress: {
          ...state.progress,
          stories: state.progress.stories.map(story =>
            story.name === action.payload
              ? {
                  ...story,
                  isDismissed: true
                }
              : story
          )
        }
      };

    case EDemoActions.CloseInterstitialScreen:
      return {
        ...state,
        isInterstitialScreenOpen: false
      };

    case EDemoActions.ShowInterstitialScreen:
      return {
        ...state,
        isInterstitialScreenOpen: true,
        interstitialScreenOffset: 0
      };

    case EDemoActions.InterstitialScreenMoveLeftSuccess:
      return {
        ...state,
        interstitialScreenOffset: state.interstitialScreenOffset += action.payload
      };

    case EDemoActions.InterstitialScreenMoveRightSuccess:
      return {
        ...state,
        interstitialScreenOffset: state.interstitialScreenOffset -= action.payload
      };

    case EDemoActions.SetMarketingTextContent:
      return {
        ...state,
        marketingTextContent: action.payload
      };

    case EDemoActions.SetDemoVariationContent:
      return {
        ...state,
        demoVariationContent: action.payload
      };

    default:
      return state;
  }
}
