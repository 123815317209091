/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boldWrap'
})
export class BoldWrapPipe implements PipeTransform {
  transform(text: string, highlightStart: string, highlightEnd: string, stringClass: string): string {
    const htmlText = text.replace(highlightStart, `<b>${highlightStart}`).replace(highlightEnd, `${highlightEnd}</b>`);

    return `<span class="${stringClass}">${htmlText}</span>`;
  }
}
