/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isElementNull } from '@fleetmatics/ui.utilities';

import { ISegmentHarshDrivingResponse } from './reports-api';

export interface IHarshDrivingEventCount {
  totalHighSpeedEvents: number;
  totalModerateEvents: number;
  totalSevereEvents: number;
}

export class HarshDrivingEvents {
  public readonly totalHighSpeedEvents: number;
  public readonly totalModerateEvents: number;
  public readonly totalSevereEvents: number;

  public get totalEventsCount(): number {
    return this.totalHighSpeedEvents + this.totalModerateEvents + this.totalSevereEvents;
  }

  public get totalHarshDrivingEventsCount(): number {
    return this.totalModerateEvents + this.totalSevereEvents;
  }

  public static fromHarshDrivingEventsResponse(harshDrivingEventsResponse: ISegmentHarshDrivingResponse): HarshDrivingEvents {
    return new this(
      harshDrivingEventsResponse.highSpeedEvents,
      this._getTotalModerateHarshDrivingCount(harshDrivingEventsResponse),
      this._getTotalSevereHarshDrivingCount(harshDrivingEventsResponse)
    );
  }

  public static fromMultipleHarshDrivingEvents(harshDrivingEvents: HarshDrivingEvents[]): HarshDrivingEvents {
    const accumulatedResult = harshDrivingEvents
      .filter(harshDrivingEvent => !isElementNull(harshDrivingEvent))
      .reduce(
        (accumulator: HarshDrivingEvents, currentValue: HarshDrivingEvents): HarshDrivingEvents =>
          new this(
            accumulator?.totalHighSpeedEvents + currentValue?.totalHighSpeedEvents,
            accumulator?.totalModerateEvents + currentValue?.totalModerateEvents,
            accumulator?.totalSevereEvents + currentValue?.totalSevereEvents
          ),
        new this(0, 0, 0)
      );
    return accumulatedResult;
  }

  public constructor(totalHighSpeedEvents: number, totalModerateEvents: number, totalSevereEvents: number) {
    this.totalHighSpeedEvents = totalHighSpeedEvents;
    this.totalModerateEvents = totalModerateEvents;
    this.totalSevereEvents = totalSevereEvents;
  }

  private static _getTotalModerateHarshDrivingCount(harshDrivingEventsResponse: ISegmentHarshDrivingResponse): number {
    return (
      harshDrivingEventsResponse.moderateAccelerationEvents +
      harshDrivingEventsResponse.moderateBrakingEvents +
      harshDrivingEventsResponse.moderateLeftCorneringEvents +
      harshDrivingEventsResponse.moderateRightCorneringEvents
    );
  }

  private static _getTotalSevereHarshDrivingCount(harshDrivingEventsResponse: ISegmentHarshDrivingResponse): number {
    return (
      harshDrivingEventsResponse.severeAccelerationEvents +
      harshDrivingEventsResponse.severeBrakingEvents +
      harshDrivingEventsResponse.severeLeftCorneringEvents +
      harshDrivingEventsResponse.severeRightCorneringEvents
    );
  }
}
