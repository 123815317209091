/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EMapContextMenuType {
  Vehicle,
  Map,
  Geofence,
  Hotspot,
  Garmin,
  WorkOrder,
  Asset
}
