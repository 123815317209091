/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule, CheckboxModule } from '@fleetmatics/ui.base-library';
import { TooltipModule } from 'shared';

import { ListSelectComponent } from './list-select.component';
import { ListSelectItemComponent } from './list-select-item/list-select-item.component';

@NgModule({
  imports: [CommonModule, CheckboxModule, TooltipModule, ButtonModule],
  exports: [ListSelectComponent, ListSelectItemComponent],
  declarations: [ListSelectComponent, ListSelectItemComponent]
})
export class ListSelectModule {}
