<div class="mobile-nav-card">
  <div class="mobile-nav-card__text" i18n="@@S_DEMO_REVEAL_NAVBAR">
    For the full Reveal Starter experience check out our demo on your desktop.
  </div>
  <div>
    <button id="try-on-desktop-menu-button"
      class="btn btn-primary"
      (click)="showTryOnDesktop()"
      i18n="@@S_DEMO_MOBILE_DESKTOP">Try on desktop</button>
  </div>
</div>
