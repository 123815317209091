/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum ESpeedLimitTypes {
  none = 1,
  roadSpeed = 2,
  postedSpeed = 4,
  postedAndRoad = 6
}
