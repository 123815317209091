/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { EVehiclesSortType, ITowingVehicle, MarkerInfo } from '../../models';

export interface IVehicleState {
  sortedVehiclesIds: number[];
  vehiclesSortType: EVehiclesSortType;
  towingVehicles: ITowingVehicle[];
  plotsInBounds: MarkerInfo[];
  accessibleVehiclesCount: number;
}

export const initialVehicleState: IVehicleState = {
  sortedVehiclesIds: [],
  vehiclesSortType: EVehiclesSortType.Vehicle,
  towingVehicles: [],
  plotsInBounds: [],
  accessibleVehiclesCount: null
};
