/*!
 * Copyright © 2018-2021. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of, forkJoin, timer } from 'rxjs';
import { debounceTime, map, mergeMap, switchMap, withLatestFrom, filter, take, concatMap, catchError, pairwise } from 'rxjs/operators';

import { isArrayNullOrEmpty, isElementNull, isStringNullOrEmpty } from '@fleetmatics/ui.utilities';

import {
  PlotHttpService,
  HotspotsHttpService,
  GeofencesHttpService,
  PlacesHttpService,
  GarminStopsHttpService,
  PlanningSchedulingWorkOrderHttpService,
  CategoriesHttpService
} from '../../services';
import {
  IVehiclePlot,
  IHotspotPlotsResponse,
  IVehiclePlotResponse,
  EGarminStopStatus,
  EGarminStopStatusGroup,
  EPlotIcon,
  IGeofencePlot,
  IGarminStopPlot,
  IWorkOrderPlot,
  IWorkOrderAdvancedOptions,
  IWorkOrderRequest,
  ESignalStatus,
  ISignalStatusUpdate,
  ISearchLightResultParams,
  EPlotType,
  IHotspotPlot,
  ITowingVehicle,
  MarkerInfo,
  IZoomToMarkerParams,
  LatLng,
  IVehiclesForUpdate,
  IBalloon,
  IVehicleWorkOrderBalloon,
  IIndexableObject,
  ISpeedViolationMessage,
  ISpeedForUpdate,
  IReverseGeocodeExecutionBatch,
  ILatLngBounds,
  LatLngBounds,
  ITowingVehiclePlotIcon,
  ETrackableClassification,
  VehiclePlot,
  IVehiclePlotWebsubsResponse,
  IAccountSettings
} from '../../models';
import { IAppState } from '../state';
import {
  getPlaceCategoryIds,
  getSelectedVehicleIds,
  getVehiclePlots,
  getGarminStopStatusesIdsSelected,
  getIsSuggestedGeofencesEnabled,
  getMapBounds,
  getIsGeofencesEnabled,
  getIsWorkOrdersEnabled,
  getSelectedDriverIds,
  getWorkOrderAdvancedOptions,
  getUserAccountSettings,
  getGeofencePlots,
  getUserMaxPlottableMarkers,
  getHotspotPlots,
  getWorkOrdersPlots,
  getZoomToMarkerId,
  getBalloon,
  getVehiclePlotsTimers,
  getIsWorkOrdersAllowed,
  getTowingVehicles,
  getWorkingTimeDirectiveDisplayEnabled,
  getTrackableClassificationFromHierarchy,
  getUserSettings
} from '../selectors';
import {
  EMapPlotsActions,
  GetVehiclePlots,
  GetVehiclePlotsSuccess,
  GetHotspotPlots,
  GetHotspotPlotsSuccess,
  GetGeofencePlots,
  GetGeofencePlotsSuccess,
  GetGarminStopsPlots,
  GetGarminStopsPlotsSuccess,
  UpdatePlaceCategoryIdsSilent,
  UpdateGarminStopStatusesIdsSelected,
  UpdateVehiclesSuccess,
  EVehicleActions,
  UpdateVehicles,
  EAdvancedOptionsActions,
  UpdateGarminStopStatusesIdsSelectedSilent,
  MapBoundsUpdated,
  EMapsActions,
  UpdateIsSuggestedGeofenceEnabled,
  UpdateIsGeofenceEnabled,
  EMapOptionsActions,
  UpdatePlaceCategoryIds,
  UpdateIsWorkOrdersEnabled,
  GetWorkOrdersPlots,
  GetWorkOrdersPlotsSuccess,
  UpdateVehiclesStatus,
  UpdateVehiclesStatusSuccess,
  GetWorkOrderAdvancedOptionsSuccess,
  GetWorkOrderAdvancedOptions,
  RetrieveWorkOrderPlots,
  ESearchLightActions,
  SelectVehicle,
  GetSearchLightVehiclePlot,
  RemoveFromMap,
  UpdateVehicleAsDeselected,
  RemoveSearchLightResultFromMap,
  TryUpdateZoomToMarker,
  GetSearchLightGeofencePlot,
  UpdateZoomToLocation,
  SelectSearchResult,
  SelectGeofence,
  SelectHotspot,
  GetSearchLightHotspotPlot,
  SelectWorkOrder,
  GetSearchLightWorkOrderPlot,
  UpdateTowingVehiclePlotIcons,
  ELayoutActions,
  ShowBalloon,
  UpdateVehiclePlotsIconsSuccess,
  UpdateVehicleBalloon,
  UpdateVehiclePlotsTimers,
  EmitTimeTick,
  ResetVehiclePlotTimer,
  UpdateVehicleSpeed,
  UpdateVehicleSpeedSuccess,
  UpdateSpeedLimitVehicleBalloon,
  GetSearchLightVehiclePlotSuccess,
  UpdateZoomToMarker,
  ReverseGeocodeVehiclePlots,
  GetVehiclePlotsForUser,
  SelectAsset,
  RemoveTowingVehicles,
  UpdateTowingVehicle,
  AddTowingVehicles,
  GetVehiclesTachoStatus,
  SetSelectedMarker
} from '../actions';
import { delayedRetry, bufferTimeBrowser } from '../../operators';
import { ISearchLightResult, ESearchEntityType, ESearchEntitySubType } from '../../../searchlight';
import { IUserSettings } from '../../models';
import { PlotMappingService } from '../../services/plot-mapping/plot-mapping.service';

const garminStatusMap = new Map<EGarminStopStatusGroup, EGarminStopStatus[]>([
  [EGarminStopStatusGroup.QUEUED, [EGarminStopStatus.SENDING, EGarminStopStatus.SENDING_AND_MAKING_ACTIVE, EGarminStopStatus.RE_SYNCING]],
  [
    EGarminStopStatusGroup.RECEIVED,
    [EGarminStopStatus.RECEIVED, EGarminStopStatus.MAKING_ACTIVE, EGarminStopStatus.RE_SYNCING_AND_MAKING_ACTIVE]
  ],
  [EGarminStopStatusGroup.READ, [EGarminStopStatus.PENDING]],
  [
    EGarminStopStatusGroup.EN_ROUTE,
    [EGarminStopStatus.EN_ROUTE, EGarminStopStatus.MAKING_DONE, EGarminStopStatus.RE_SYNCING_AND_MAKING_DONE]
  ],
  [EGarminStopStatusGroup.COMPLETED, [EGarminStopStatus.DONE, EGarminStopStatus.DELETED]]
]);

@Injectable()
export class PlotsEffects {
  public getVehiclePlotsForUser$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetVehiclePlotsForUser>(EMapPlotsActions.GetVehiclePlotsForUser),
      filter(() => isPlatformBrowser(this._platformId)),
      switchMap(() => this._plotsHttpService.getVehiclePlotsForUser().pipe(delayedRetry())),
      withLatestFrom(
        this._store.pipe(select(getTrackableClassificationFromHierarchy)),
        this._store.pipe(select(getUserSettings)),
        this._store.pipe(select(getUserAccountSettings))
      ),
      map(([response, classificationsFromHierachy, userSettings, accountSettings]) =>
        this._plotMappingService.mapVehiclePlotsResponseToStateModel(
          response,
          [],
          classificationsFromHierachy,
          userSettings,
          accountSettings
        )
      ),
      switchMap((vehiclePlots: IVehiclePlot[]) => {
        const actions: Action[] = [new GetVehiclePlotsSuccess(vehiclePlots)];

        const reverseGeocodeExecutionBatch = this._getReverseGeocodeExecutionBatch(vehiclePlots);
        if (reverseGeocodeExecutionBatch) {
          actions.push(new ReverseGeocodeVehiclePlots(reverseGeocodeExecutionBatch));
        }
        return actions;
      })
    )
  );

  public getVehiclePlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetVehiclePlots>(EMapPlotsActions.GetVehiclePlots),
      withLatestFrom(this._store.pipe(select(getSelectedVehicleIds)), this._store.pipe(select(getVehiclePlots))),
      switchMap(([, vehicleIdsSet, vehiclePlots]) => {
        const searchLightVehicleIds: number[] = vehiclePlots
          .filter((vehiclePlot: IVehiclePlot) => vehiclePlot.isFromSearchLight)
          .map(vehiclePlot => vehiclePlot.id);

        const vehicleIds = this._addSearcLightVehiclesToSelectedVehicles(searchLightVehicleIds, vehicleIdsSet);

        return this._plotsHttpService.getVehiclePlots(vehicleIds.join(',')).pipe(
          delayedRetry(),
          map(response => {
            return <[number[], IVehiclePlotResponse[]]>[searchLightVehicleIds, response];
          }),
          catchError(() => of(<[number[], IVehiclePlotResponse[]]>[searchLightVehicleIds, null]))
        );
      }),
      filter(([, plotsResponse]) => !isElementNull(plotsResponse)),
      withLatestFrom(
        this._store.pipe(select(getTrackableClassificationFromHierarchy)),
        this._store.pipe(select(getUserSettings)),
        this._store.pipe(select(getUserAccountSettings))
      ),
      map(([[searchLightVehicleIds, plotsResponse], classificationsFromHierachy, userSettings, accountSettings]) => {
        return this._plotMappingService.mapVehiclePlotsResponseToStateModel(
          plotsResponse,
          searchLightVehicleIds,
          classificationsFromHierachy,
          userSettings,
          accountSettings
        );
      }),
      switchMap((vehiclePlots: IVehiclePlot[]) => {
        const actions: Action[] = [new GetVehiclePlotsSuccess(vehiclePlots)];

        const reverseGeocodeExecutionBatch = this._getReverseGeocodeExecutionBatch(vehiclePlots);
        if (reverseGeocodeExecutionBatch) {
          actions.push(new ReverseGeocodeVehiclePlots(reverseGeocodeExecutionBatch));
        }
        return actions;
      })
    )
  );

  public resetVehiclePlotTimer: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<ResetVehiclePlotTimer | GetVehiclePlotsSuccess>(
        EMapPlotsActions.ResetVehiclePlotTimer,
        EMapPlotsActions.GetVehiclePlotsSuccess
      ),
      withLatestFrom(this._store.pipe(select(getVehiclePlotsTimers))),
      map(([action, vehiclePlotTimers]) => {
        const timers: IIndexableObject<number> = vehiclePlotTimers;
        action.payload.forEach(vehiclePlot => {
          timers[vehiclePlot.id] = 0;
        });
        return timers;
      }),
      map(timers => new UpdateVehiclePlotsTimers(timers))
    )
  );

  public setupTimeTick$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetVehiclePlotsSuccess>(EMapPlotsActions.GetVehiclePlotsSuccess),
      switchMap(() => timer(0, 1000).pipe(map(() => new EmitTimeTick(new Date()))))
    )
  );

  public emitTimeTick$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<EmitTimeTick>(EMapPlotsActions.EmitTimeTick),
      map(action => action.payload.getTime() / 1000),
      pairwise(),
      withLatestFrom(this._store.pipe(select(getVehiclePlotsTimers))),
      map(([[previousTime, currentTime], timers]) => {
        const result = { ...timers };
        /*
          previousTime is the last "currentTime" emmitted. Usually it'll be around 1 second behind currentTime.
          However, there are multiple scenarios where the difference will be larger.
          For example, if a tab is inactive in Chrome for a few minutes, the timer will not emit, until the tab is reactivated.
        */
        const timeToAddSeconds = currentTime - previousTime;
        Object.keys(result).forEach((key: string) => {
          result[+key] += timeToAddSeconds;
        });
        return result;
      }),
      map(timers => new UpdateVehiclePlotsTimers(timers))
    )
  );

  public GetSearchLightVehiclePlot$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSearchLightVehiclePlot>(ESearchLightActions.GetSearchLightVehiclePlot),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getSelectedVehicleIds)), this._store.pipe(select(getVehiclePlots))),
      switchMap(([vehicleId, vehicleIdsSet, vehiclePlots]) => {
        const searchLightVehicleIds: number[] = vehiclePlots
          .filter((vehiclePlot: IVehiclePlot) => vehiclePlot.isFromSearchLight)
          .map(vehiclePlot => vehiclePlot.id);
        const selectedVehicleId = +vehicleId.substring(1);

        if (!searchLightVehicleIds.includes(selectedVehicleId)) {
          searchLightVehicleIds.push(selectedVehicleId);
        }

        const vehicleIds = this._addSearcLightVehiclesToSelectedVehicles(searchLightVehicleIds, vehicleIdsSet);

        return this._plotsHttpService.getVehiclePlots(vehicleIds.join(',')).pipe(
          delayedRetry(),
          map(response => {
            return <[number[], IVehiclePlotResponse[]]>[searchLightVehicleIds, response];
          }),
          catchError(() => of(<[number[], IVehiclePlotResponse[]]>[searchLightVehicleIds, null]))
        );
      }),
      filter(([, plots]) => !isElementNull(plots)),
      withLatestFrom(
        this._store.pipe(select(getTrackableClassificationFromHierarchy)),
        this._store.pipe(select(getUserSettings)),
        this._store.pipe(select(getUserAccountSettings))
      ),
      map(([[searchLightVehicleIds, response], classificationsFromHierachy, userSettings, accountSettings]) => {
        return this._plotMappingService.mapVehiclePlotsResponseToStateModel(
          response,
          searchLightVehicleIds,
          classificationsFromHierachy,
          userSettings,
          accountSettings
        );
      }),
      map((vehiclePlots: IVehiclePlot[]) => new GetSearchLightVehiclePlotSuccess(vehiclePlots))
    )
  );

  public updateVehicleSpeed$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicleSpeed>(EVehicleActions.UpdateVehicleSpeed),
      map((action: UpdateVehicleSpeed) => action.payload),
      withLatestFrom(this._store.pipe(select(getVehiclePlots))),
      map(([vehicleToUpdate, vehiclePlots]: [ISpeedViolationMessage, IVehiclePlot[]]) => {
        const vehiclesUpdated: IVehiclePlot[] = [];
        vehiclePlots.forEach((vehicle: IVehiclePlot) => {
          if (vehicle.id === vehicleToUpdate.VehicleId) {
            vehicle = {
              ...vehicle,
              vehicleSpeedlimit: vehicleToUpdate.ViolationSpeed,
              vehicleSpeedlimitType: vehicleToUpdate.InrixSpeedLimitType,
              speedViolation: {
                inrixSpeedLimitType: vehicleToUpdate.InrixSpeedLimitType,
                violationSpeed: vehicleToUpdate.ViolationSpeed
              }
            };
          }
          vehiclesUpdated.push(vehicle);
        });
        return { vehiclesUpdated: vehiclesUpdated, vehicleToUpdate: vehicleToUpdate };
      }),
      withLatestFrom(this._store.pipe(select(getBalloon))),
      switchMap(([vehicles, balloon]: [ISpeedForUpdate, IBalloon]) => {
        const actions: Action[] = [new UpdateVehicleSpeedSuccess(vehicles.vehiclesUpdated)];

        if (
          !isElementNull(balloon) &&
          balloon.id === vehicles.vehicleToUpdate.VehicleId &&
          (balloon.type === EPlotType.vehicle || balloon.type === EPlotType.poweredAsset)
        ) {
          (balloon as IVehicleWorkOrderBalloon).status.inrixSpeedLimitType = vehicles.vehicleToUpdate.InrixSpeedLimitType;
          (balloon as IVehicleWorkOrderBalloon).status.violationSpeed = vehicles.vehicleToUpdate.ViolationSpeed;
          (balloon as IVehicleWorkOrderBalloon).status.speed = vehicles.vehiclesUpdated.find(
            vehicle => vehicle.id === vehicles.vehicleToUpdate.VehicleId
          ).speed;
          actions.push(new UpdateSpeedLimitVehicleBalloon(balloon as IVehicleWorkOrderBalloon));
        }
        return actions;
      })
    )
  );

  public updateVehicles$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehicles>(EVehicleActions.UpdateVehicles),
      bufferTimeBrowser(3000, this._platformId),
      filter(actions => actions && actions.length > 0),
      map((actions: UpdateVehicles[]) => actions.map(action => action.payload)),
      withLatestFrom(
        this._store.pipe(select(getVehiclePlots)),
        this._store.pipe(select(getTrackableClassificationFromHierarchy)),
        this._store.pipe(select(getUserSettings)),
        this._store.pipe(select(getUserAccountSettings))
      ),
      map(([vehicleUpdatesResponse, existingVehiclePlots, classificationsFromHierachy, userSettings, accountSettings]) => {
        // copy existing plots to a new reference, set the isUpdating flag to false
        // and create a map with id as the key
        const existingPlotsById = new Map<number, IVehiclePlot>(
          existingVehiclePlots.map(vehiclePlot => {
            const newPlot = new VehiclePlot(vehiclePlot);
            newPlot.isUpdating = false;

            return [vehiclePlot.id, newPlot];
          })
        );

        // convert the plot updates to IVehiclePlot and use existing plots if there's missing data
        const vehicleUpdatePlotModels = this._convertVehiclePlotUpdatesToModels(
          vehicleUpdatesResponse,
          existingPlotsById,
          classificationsFromHierachy,
          userSettings,
          accountSettings
        );

        // only update plots that are already in the store
        // this is to prevent processing updates for trackables that have been removed from the hierarchy
        // but we are still getting updates for
        const vehiclesToUpdate: IVehiclePlot[] = this._filterAndEnrichVehiclesToUpdate(vehicleUpdatePlotModels, existingPlotsById);

        // vehiclesUpdated represents all the plots in the store
        // all of the plots are updated because the isUpdating flag is set to false
        const vehiclesUpdated = Array.from(existingPlotsById.values());
        return { vehiclesUpdated, vehiclesToUpdate };
      }),
      withLatestFrom(
        this._store.pipe(select(getZoomToMarkerId)),
        this._store.pipe(select(getBalloon)),
        this._store.pipe(select(getWorkingTimeDirectiveDisplayEnabled))
      ),
      concatMap(
        ([vehicles, vehicleToZoomId, balloon, workingTimeDirectiveDisplayEnabled]: [IVehiclesForUpdate, number, IBalloon, boolean]) => {
          // update all the plots
          const actions: Action[] = [new UpdateVehiclesSuccess(vehicles.vehiclesUpdated)];

          // gather actions to dispatch for plots that we received an update for:
          // ResetVehiclePlotTimer:
          const vehiclesToResetTimer = vehicles.vehiclesToUpdate;
          actions.push(new ResetVehiclePlotTimer(vehiclesToResetTimer));

          const plotsToReverseGeocode: IVehiclePlot[] = [];
          const vehicleIdsToGetTachoStatus: number[] = [];
          vehicles.vehiclesToUpdate.forEach(vehicleToUpdate => {
            // do we need to reverse geocode this plot?
            if (vehicleToUpdate.isSearchingAddress) {
              plotsToReverseGeocode.push(vehicleToUpdate);
            }
            // are we zoomed in on this vehicle? if its location updated we want to follow the plot to the new location
            if (vehicleToUpdate.id === vehicleToZoomId) {
              actions.push(
                new TryUpdateZoomToMarker({
                  latLng: new LatLng(vehicleToUpdate.coordinates.lat, vehicleToUpdate.coordinates.lng),
                  id: vehicleToUpdate.id,
                  type: vehicleToUpdate.type === EPlotType.poweredAsset ? EPlotType.poweredAsset : EPlotType.vehicle,
                  title: vehicleToUpdate.vehicleName
                })
              );
            }
            // if balloon is open we want to update the balloon
            if (
              !isElementNull(balloon) &&
              balloon.id === vehicleToUpdate.id &&
              (balloon.type === EPlotType.vehicle || balloon.type === EPlotType.poweredAsset)
            ) {
              actions.push(new UpdateVehicleBalloon(balloon as IVehicleWorkOrderBalloon));
            } else if (workingTimeDirectiveDisplayEnabled) {
              // if tacho is enabled we want to update the tacho card
              vehicleIdsToGetTachoStatus.push(vehicleToUpdate.id);
            }
          });

          // batch reverse geocoding requests
          if (plotsToReverseGeocode.length > 0) {
            const reverseGeocodeExecutionBatch = {
              id: Date.now(),
              vehiclePlots: plotsToReverseGeocode
            };
            actions.push(new ReverseGeocodeVehiclePlots(reverseGeocodeExecutionBatch));
          }

          // update tacho status card
          actions.push(new GetVehiclesTachoStatus(vehicleIdsToGetTachoStatus));
          return actions;
        }
      )
    )
  );

  public updateVehicleIcon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateTowingVehiclePlotIcons>(EMapPlotsActions.UpdateTowingVehiclePlotIcons),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getVehiclePlots))),
      map(([vehiclesToUpdate, vehiclePlots]) => {
        const vehiclesToUpdateById = new Map<Number, ITowingVehiclePlotIcon>();
        vehiclesToUpdate.forEach(update => vehiclesToUpdateById.set(update.id, update));

        const updatedVehiclePlots: IVehiclePlot[] = vehiclePlots.map(vehiclePlot => {
          const vehicleToUpdate = vehiclesToUpdateById.get(vehiclePlot.id);
          if (!isElementNull(vehicleToUpdate)) {
            return {
              ...vehiclePlot,
              iconClass: vehicleToUpdate.iconClass
            };
          }
          return vehiclePlot;
        });

        return updatedVehiclePlots;
      }),
      map((vehicles: IVehiclePlot[]) => new UpdateVehiclePlotsIconsSuccess(vehicles))
    )
  );

  public onAddTowingVehicleUpdateVehiclePlot$ = createEffect(() =>
    this._actions$.pipe(
      ofType<AddTowingVehicles>(EVehicleActions.AddTowingVehicles),
      map(action => action.payload),
      map(towingVehicle => new UpdateTowingVehiclePlotIcons(towingVehicle))
    )
  );

  public onRemoveTowingVehicleRestoreOriginalIcon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveTowingVehicles>(EVehicleActions.RemoveTowingVehicles),
      map(action => action.payload),
      map(removedTowingVehicles =>
        removedTowingVehicles.map(removedTowingVehicle => {
          const towingVehiclePlot = this._towingVehicleToTowingVehiclePlot(removedTowingVehicle);
          towingVehiclePlot.iconClass = removedTowingVehicle.originalIconClass;
          return towingVehiclePlot;
        })
      ),
      map(removedTowingVehiclePlots => new UpdateTowingVehiclePlotIcons(removedTowingVehiclePlots))
    )
  );

  public updateTowingVehicleIconIfChanged$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateTowingVehicle>(EVehicleActions.UpdateTowingVehicle),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getVehiclePlots))),
      map(
        ([updatedTowingVehicle, vehiclePlots]) =>
          <[ITowingVehicle, IVehiclePlot]>[updatedTowingVehicle, vehiclePlots.find(plot => plot.id === updatedTowingVehicle.id)]
      ),
      filter(
        ([updatedTowingVehicle, vehiclePlot]) => !isElementNull(vehiclePlot) && vehiclePlot.iconClass !== updatedTowingVehicle.iconClass
      ),
      map(([towingVehiclePlot]) => new UpdateTowingVehiclePlotIcons([towingVehiclePlot]))
    )
  );

  public onUpdateVehiclesSuccessUpdateTowingVehicle$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehiclesSuccess | GetVehiclePlotsSuccess>(
        EVehicleActions.UpdateVehiclesSuccess,
        EMapPlotsActions.GetVehiclePlotsSuccess
      ),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getTowingVehicles))),
      switchMap(([updatedVehiclePlots, towingVehicles]) => {
        const actions: Action[] = [];
        updatedVehiclePlots.forEach(updatedVehiclePlot => {
          const towingVehicle = this._getTowingVehicle(updatedVehiclePlot, towingVehicles);
          if (!isElementNull(towingVehicle) && this._hasVehicleIconChanged(updatedVehiclePlot, towingVehicle)) {
            const updatedTowingVehicle: ITowingVehicle = {
              ...towingVehicle,
              originalIconClass: updatedVehiclePlot.iconClass
            };
            actions.push(new UpdateTowingVehicle(updatedTowingVehicle));
          }
        });
        return actions;
      })
    )
  );

  public updateVehiclesStatus$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateVehiclesStatus>(EVehicleActions.UpdateVehiclesStatus),
      map((action: UpdateVehiclesStatus) => action.payload),
      withLatestFrom(this._store.pipe(select(getVehiclePlots))),
      map(([vehiclesToUpdate, vehiclePlots]: [ISignalStatusUpdate[], IVehiclePlot[]]) => {
        const vehiclesToUpdateById = new Map<Number, ISignalStatusUpdate>();
        vehiclesToUpdate.forEach(update => vehiclesToUpdateById.set(update.alert.vehicleId, update));
        const updatedVehiclePlots = vehiclePlots.map(item => {
          const vehicleToUpdate = vehiclesToUpdateById.get(item.id);
          if (!isElementNull(vehicleToUpdate)) {
            return this._updateVehiclePlotSignalStatus(vehicleToUpdate, item, new Date());
          }
          return item;
        });
        return updatedVehiclePlots;
      }),
      map((vehicles: IVehiclePlot[]) => new UpdateVehiclesStatusSuccess(vehicles))
    )
  );

  public hotspotPlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<MapBoundsUpdated | UpdateIsSuggestedGeofenceEnabled>(
        EMapsActions.MapBoundsUpdated,
        EMapOptionsActions.UpdateIsSuggestedGeofenceEnabled
      ),
      debounceTime(1000),
      map(() => new GetHotspotPlots())
    )
  );

  public getSearchLightHotspotPlot$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSearchLightHotspotPlot>(ESearchLightActions.GetSearchLightHotspotPlot),
      map(action => action.payload),
      switchMap((params: ISearchLightResultParams) =>
        this._placesHttpService.getHotspotPlotDetails(params.id).pipe(
          delayedRetry(),
          map(response => <[ISearchLightResultParams, IHotspotPlot]>[params, response]),
          catchError(() => of(<[ISearchLightResultParams, IHotspotPlot]>[params, null]))
        )
      ),
      filter(([, hotspots]) => !isElementNull(hotspots)),
      withLatestFrom(this._store.pipe(select(getHotspotPlots)), this._store.pipe(select(getUserMaxPlottableMarkers))),
      switchMap(([[params, hotspotPlot], hotspotPlots, maxPlottableMarkers]) => {
        const maxHotspotPlots = maxPlottableMarkers.find(marker => marker.type === EPlotType.hotspot).max;
        hotspotPlot.isFromSearchLight = true;
        hotspotPlot.name = params.name;
        if (hotspotPlots.length >= maxHotspotPlots) {
          hotspotPlots = hotspotPlots.filter(plot => !plot.isFromSearchLight);
        }
        hotspotPlots = [...hotspotPlots, hotspotPlot];
        return [
          new GetHotspotPlotsSuccess(hotspotPlots),
          new TryUpdateZoomToMarker({
            latLng: params.latLng,
            id: hotspotPlot.id,
            type: EPlotType.hotspot,
            title: hotspotPlot.name
          })
        ];
      })
    )
  );

  public getHotspotPlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetHotspotPlots>(EMapPlotsActions.GetHotspotPlots),
      withLatestFrom(this._store.pipe(select(getMapBounds)), this._store.pipe(select(getIsSuggestedGeofencesEnabled))),
      filter(([, , isSuggestedGeofenceEnabled]) => isSuggestedGeofenceEnabled),
      debounceTime(1000),
      map(([, mapBounds]) => mapBounds),
      switchMap((bounds: ILatLngBounds) =>
        this._hotspotsHttpService.getHotspots(bounds).pipe(
          delayedRetry(),
          catchError(() => of(null))
        )
      ),
      filter(hotspotsResponse => !isElementNull(hotspotsResponse)),
      withLatestFrom(this._store.pipe(select(getHotspotPlots))),
      map(([hotspotPlotsResponse, currentHotspotPlots]: [IHotspotPlotsResponse, IHotspotPlot[]]) => {
        const hotspotPlots = hotspotPlotsResponse.Data;
        const searchLightHotspotPlots = currentHotspotPlots.filter(currentHotspotPlot => currentHotspotPlot.isFromSearchLight);
        searchLightHotspotPlots.forEach(searchLightHotspotPlot => {
          const existingHotspotPlot = hotspotPlots.find(hotspotPlot => hotspotPlot.id === searchLightHotspotPlot.id);
          if (existingHotspotPlot) {
            existingHotspotPlot.isFromSearchLight = true;
          } else {
            hotspotPlots.push(searchLightHotspotPlot);
          }
        });
        return new GetHotspotPlotsSuccess(hotspotPlots);
      })
    )
  );

  public geofencePlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<MapBoundsUpdated | UpdateIsGeofenceEnabled | UpdatePlaceCategoryIds>(
        EMapsActions.MapBoundsUpdated,
        EMapOptionsActions.UpdateIsGeofenceEnabled,
        EAdvancedOptionsActions.UpdatePlaceCategoryIds
      ),
      map(() => new GetGeofencePlots())
    )
  );

  public getSearchLightGeofencePlot$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSearchLightGeofencePlot>(ESearchLightActions.GetSearchLightGeofencePlot),
      map(action => action.payload),
      switchMap((params: ISearchLightResultParams) =>
        this._placesHttpService.getGeofencePlotDetails(params.id).pipe(
          delayedRetry(),
          map(response => <[ISearchLightResultParams, IGeofencePlot]>[params, response]),
          catchError(() => of(<[ISearchLightResultParams, IGeofencePlot]>[params, null]))
        )
      ),
      filter(([, geofence]) => !isElementNull(geofence)),
      withLatestFrom(this._store.pipe(select(getGeofencePlots)), this._store.pipe(select(getUserMaxPlottableMarkers))),
      switchMap(([[params, selectedGeofencePlot], geofencePlots, maxPlottableMarkers]) => {
        const maxGeofencePlots = maxPlottableMarkers.find(marker => marker.type === EPlotType.geofence).max;
        selectedGeofencePlot.isFromSearchLight = true;
        selectedGeofencePlot.name = params.name;
        if (geofencePlots.length >= maxGeofencePlots) {
          geofencePlots = geofencePlots.filter(plot => !plot.isFromSearchLight);
        }

        // if geofencePlot already exists update it
        geofencePlots = [...geofencePlots, selectedGeofencePlot];

        return [
          new GetGeofencePlotsSuccess(geofencePlots),
          new TryUpdateZoomToMarker({
            latLng: params.latLng,
            id: selectedGeofencePlot.id,
            type: EPlotType.geofence,
            title: selectedGeofencePlot.name
          })
        ];
      })
    )
  );

  public getGeofencePlots$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<GetGeofencePlots>(EMapPlotsActions.GetGeofencePlots),
        debounceTime(1000),
        withLatestFrom(this._store.pipe(select(getMapBounds)), this._store.pipe(select(getIsGeofencesEnabled))),
        filter(([, , isGeofenceEnabled]) => isGeofenceEnabled),
        map(([, mapBounds]) => mapBounds),
        withLatestFrom(this._store.pipe(select(getPlaceCategoryIds))),
        mergeMap(([mapBounds, categoryIds]) => {
          if (!categoryIds) {
            return this._categoriesHttpService.getPlaceCategories().pipe(
              delayedRetry(),
              map(
                placeCategories =>
                  <[ILatLngBounds, number[], boolean]>[mapBounds, placeCategories.map(placeCategory => placeCategory.Id), true]
              ),
              catchError(() => of(<[ILatLngBounds, number[], boolean]>[mapBounds, null, true]))
            );
          }
          return of(<[ILatLngBounds, number[], boolean]>[mapBounds, categoryIds, false]);
        }),
        filter(([, categoriesIds]) => !isElementNull(categoriesIds)),
        switchMap(([bounds, categoryIds, shouldUpdatePreferences]) => {
          return this._geofencesHttpService.getGeofences(bounds, categoryIds).pipe(
            delayedRetry(),
            map((geofencePlots: IGeofencePlot[]) => (isElementNull(geofencePlots) ? [] : geofencePlots)),
            map(geofencePlots => [geofencePlots, categoryIds, shouldUpdatePreferences]),
            catchError(() => of([null, categoryIds, shouldUpdatePreferences]))
          );
        }),
        withLatestFrom(this._store.pipe(select(getGeofencePlots))),
        switchMap(
          ([[geofencePlots, categoryIds, shouldUpdatePreferences], currentGeofencePlots]: [
            [IGeofencePlot[], number[], boolean],
            IGeofencePlot[]
          ]) => {
            const searchLightGeofencePlots = currentGeofencePlots.filter(currentGeofencePlot => currentGeofencePlot.isFromSearchLight);
            searchLightGeofencePlots.forEach(searchLightGeofencePlot => {
              const existingGeofencePlot = geofencePlots.find(geofencePlot => geofencePlot.id === searchLightGeofencePlot.id);
              if (existingGeofencePlot) {
                existingGeofencePlot.isFromSearchLight = true;
              } else {
                geofencePlots.push(searchLightGeofencePlot);
              }
            });

            const actions: Action[] = [new GetGeofencePlotsSuccess(geofencePlots)];
            if (shouldUpdatePreferences) {
              actions.push(new UpdatePlaceCategoryIdsSilent(categoryIds));
            }

            return actions;
          }
        )
      ) as Observable<GetGeofencePlotsSuccess | UpdatePlaceCategoryIdsSilent>
  );

  public garminStopsPlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateGarminStopStatusesIdsSelected>(EAdvancedOptionsActions.UpdateGarminStopStatusesIdsSelected),
      map(() => new GetGarminStopsPlots())
    )
  );

  public getGarminStopsPlots$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetGarminStopsPlots>(EMapPlotsActions.GetGarminStopsPlots),
      withLatestFrom(this._store.pipe(select(getGarminStopStatusesIdsSelected))),
      debounceTime(1000),
      mergeMap(([, statusGroupIds]) => {
        const statusIds: number[] = [];
        const allWorldBounds = LatLngBounds.allWorld();
        if (!statusGroupIds) {
          const allStatusIds: number[] = [];
          garminStatusMap.forEach((item, key) => {
            statusIds.push(...item);
            allStatusIds.push(key);
          });
          return of(<[ILatLngBounds, number[], number[], boolean]>[allWorldBounds, allStatusIds, statusIds, true]);
        }
        statusGroupIds.forEach(id => {
          statusIds.push(...garminStatusMap.get(+id));
        });

        return of(<[ILatLngBounds, number[], number[], boolean]>[allWorldBounds, statusGroupIds, statusIds, false]);
      }),
      switchMap(([bounds, statusGroupIds, statusIds, shouldUpdatePreferences]) =>
        this._garminStopsHttpService.getGarminStopsPlots(bounds, statusIds).pipe(
          delayedRetry(),
          map(garminStopsPlots => <[IGarminStopPlot[], number[], boolean]>[garminStopsPlots, statusGroupIds, shouldUpdatePreferences]),
          catchError(() => of(<[IGarminStopPlot[], number[], boolean]>[null, statusGroupIds, shouldUpdatePreferences]))
        )
      ),
      filter(([garminStops]) => !isElementNull(garminStops)),
      switchMap(([garminStopsPlots, statusGroupIds, shouldUpdatePreferences]) => {
        const actions: Action[] = [
          new GetGarminStopsPlotsSuccess(garminStopsPlots ? this._mapGarminStopsPlotsClassIcon(garminStopsPlots) : [])
        ];
        if (shouldUpdatePreferences) {
          actions.push(new UpdateGarminStopStatusesIdsSelectedSilent(statusGroupIds));
        }

        return actions;
      })
    )
  );

  public workOrdersPlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<MapBoundsUpdated | UpdateIsWorkOrdersEnabled | GetVehiclePlots>(
        EMapsActions.MapBoundsUpdated,
        EMapOptionsActions.UpdateIsWorkOrdersEnabled,
        EMapPlotsActions.GetVehiclePlots
      ),
      debounceTime(1000),
      withLatestFrom(this._store.pipe(select(getIsWorkOrdersEnabled)), this._store.pipe(select(getIsWorkOrdersAllowed))),
      filter(([, isWorkOrdersEnabled, isWorkOrdersAllowed]) => isWorkOrdersEnabled && isWorkOrdersAllowed),
      map(() => new RetrieveWorkOrderPlots())
    )
  );

  public workOrdersPlotsAdvancedOptions$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetWorkOrderAdvancedOptions>(EAdvancedOptionsActions.GetWorkOrderAdvancedOptions),
      withLatestFrom(this._store.pipe(select(getIsWorkOrdersEnabled))),
      filter(([action, isWorkOrdersEnabled]) => isWorkOrdersEnabled && action.payload),
      switchMap(() =>
        this._actions$.pipe(ofType<GetWorkOrderAdvancedOptionsSuccess>(EAdvancedOptionsActions.GetWorkOrderAdvancedOptionsSuccess), take(1))
      ),
      map(() => new GetWorkOrdersPlots())
    )
  );

  public retrieveWorkOrdersPlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<RetrieveWorkOrderPlots>(EMapPlotsActions.RetrieveWorkOrderPlots),
      withLatestFrom(this._store.pipe(select(getWorkOrderAdvancedOptions))),
      map(([, advancedOptions]: [RetrieveWorkOrderPlots, IWorkOrderAdvancedOptions]) => {
        if (isElementNull(advancedOptions)) {
          return new GetWorkOrderAdvancedOptions(true);
        }
        return new GetWorkOrdersPlots();
      })
    )
  );

  public getSearchLightWorkOrderPlot$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSearchLightWorkOrderPlot>(ESearchLightActions.GetSearchLightWorkOrderPlot),
      map(action => action.payload),
      switchMap((params: ISearchLightResultParams) => {
        return this._planningSchedulingWorkOrderHttpService.getWorkOrderPlot(params.id).pipe(
          delayedRetry(),
          map(response => <[ISearchLightResultParams, IWorkOrderPlot]>[params, response]),
          catchError(() => of(<[ISearchLightResultParams, IWorkOrderPlot]>[params, null]))
        );
      }),
      filter(([, workOrder]) => !isElementNull(workOrder)),
      withLatestFrom(this._store.pipe(select(getWorkOrdersPlots)), this._store.pipe(select(getUserMaxPlottableMarkers))),
      switchMap(([[params, workOrderPlot], workOrderPlots, maxPlottableMarkers]) => {
        const maxWorkOrderPlots = maxPlottableMarkers.find(marker => marker.type === EPlotType.workOrder).max;
        workOrderPlot.isFromSearchLight = true;
        if (workOrderPlots.length >= maxWorkOrderPlots) {
          workOrderPlots = workOrderPlots.filter(plot => !plot.isFromSearchLight);
        }
        workOrderPlots = [...workOrderPlots, workOrderPlot];
        return [
          new GetWorkOrdersPlotsSuccess(workOrderPlots),
          new TryUpdateZoomToMarker({
            latLng: params.latLng,
            id: workOrderPlot.WorkOrderId,
            type: EPlotType.workOrder,
            title: workOrderPlot.Letter
          })
        ];
      })
    )
  );

  public getWorkOrdersPlots$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetWorkOrdersPlots>(EMapPlotsActions.GetWorkOrdersPlots),
      debounceTime(1000),
      withLatestFrom(
        this._store.pipe(select(getMapBounds)),
        this._store.pipe(select(getSelectedDriverIds)),
        this._store.pipe(select(getUserAccountSettings)),
        this._store.pipe(select(getWorkOrderAdvancedOptions))
      ),
      switchMap(([, mapBounds, selectedDriverIds, accountSettings, workOrderAdvancedOptions]) => {
        if (
          isElementNull(workOrderAdvancedOptions) ||
          !(workOrderAdvancedOptions.areAssignedSelected || workOrderAdvancedOptions.areUnassignedSelected)
        ) {
          return of([]);
        }

        if (!isElementNull(accountSettings) && !isElementNull(mapBounds) && !isElementNull(workOrderAdvancedOptions)) {
          const startDate = this._getFormattedDateTime(workOrderAdvancedOptions.selectedStartDate);
          const endDate = this._getFormattedDateTime(workOrderAdvancedOptions.selectedEndDate);
          const request: IWorkOrderRequest = {
            DriverIds: [...selectedDriverIds].map(Number) || [],
            BottomLeftPoint: {
              Latitude: mapBounds.south,
              Longitude: mapBounds.west
            },
            TopRightPoint: {
              Latitude: mapBounds.north,
              Longitude: mapBounds.east
            },
            LocalStartDate: startDate,
            LocalEndDate: endDate,
            IncludeCompleted: true
          };

          return forkJoin([
            workOrderAdvancedOptions.areAssignedSelected
              ? this._plotsHttpService.getAssignedWorkOrders(request).pipe(
                  delayedRetry(),
                  catchError(() => of([]))
                )
              : of([]),
            workOrderAdvancedOptions.areUnassignedSelected
              ? this._plotsHttpService.getUnassignedWorkOrders(request).pipe(
                  delayedRetry(),
                  catchError(() => of([]))
                )
              : of([])
          ]).pipe(
            map(([assignedPlots, unassignedPlots]: [IWorkOrderPlot[], IWorkOrderPlot[]]) => {
              if (isArrayNullOrEmpty(assignedPlots) && isArrayNullOrEmpty(unassignedPlots)) {
                return [];
              }
              if (isArrayNullOrEmpty(assignedPlots)) {
                return unassignedPlots;
              }
              if (isArrayNullOrEmpty(unassignedPlots)) {
                return assignedPlots;
              }
              return assignedPlots.concat(unassignedPlots);
            })
          );
        }
        return of([]);
      }),
      withLatestFrom(this._store.pipe(select(getWorkOrdersPlots))),
      map(([workOrderPlots, currentWorkOrdersPlots]: [IWorkOrderPlot[], IWorkOrderPlot[]]) => {
        const searchLightWorkOrdersPlots = currentWorkOrdersPlots.filter(currentWorkOrdersPlot => currentWorkOrdersPlot.isFromSearchLight);
        searchLightWorkOrdersPlots.forEach(searchLightWorkOrderPlot => {
          const existingWorkOrderPlot = workOrderPlots.find(
            workOrderPlot => workOrderPlot.WorkOrderId === searchLightWorkOrderPlot.WorkOrderId
          );
          if (existingWorkOrderPlot) {
            existingWorkOrderPlot.isFromSearchLight = true;
          } else {
            workOrderPlots.push(searchLightWorkOrderPlot);
          }
        });
        return new GetWorkOrdersPlotsSuccess(workOrderPlots);
      })
    )
  );

  public removeFromMap$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveFromMap>(EMapPlotsActions.RemoveFromMap),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getVehiclePlots))),
      map(([vehicleId, vehiclePlots]) => {
        if (vehiclePlots.some(vehiclePlot => vehiclePlot.id === vehicleId && !vehiclePlot.isFromSearchLight)) {
          return new UpdateVehicleAsDeselected(`v${vehicleId}`);
        } else {
          return new RemoveSearchLightResultFromMap(vehicleId);
        }
      })
    )
  );

  public selectSearchLightResult$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectSearchResult>(ESearchLightActions.SelectSearchResult),
      map(action => action.payload),
      map((result: ISearchLightResult) => {
        const params: ISearchLightResultParams = {
          id: result.id,
          name: result.text,
          latLng: new LatLng(result.lat, result.lng)
        };
        switch (result.type) {
          case ESearchEntityType.Vehicle:
          case ESearchEntityType.PoweredAsset:
            return new SelectVehicle(`v${result.id}`);
          case ESearchEntityType.Driver:
            return new SelectVehicle(`v${result.meta.vehicleId}`);
          case ESearchEntityType.Place:
            if (result.subtype === ESearchEntitySubType.Geofence) {
              return new SelectGeofence(params);
            } else if (result.subtype === ESearchEntitySubType.Hotspot) {
              return new SelectHotspot(params);
            } else {
              return new UpdateZoomToLocation({ lat: result.lat, lng: result.lng });
            }
          case ESearchEntityType.WorkOrder:
            return new SelectWorkOrder(params);
          case ESearchEntityType.Address:
            return new UpdateZoomToLocation({ lat: result.lat, lng: result.lng });
          case ESearchEntityType.Asset:
            return new SelectAsset(`a${result.id}`);
        }
      })
    )
  );

  public selectSearchLightGeofence$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectGeofence>(ESearchLightActions.SelectGeofence),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getGeofencePlots)), this._store.pipe(select(getIsGeofencesEnabled))),
      map(([params, geofencePlots, isGeofenceEnabled]: [ISearchLightResultParams, IGeofencePlot[], boolean]) => {
        if (isGeofenceEnabled) {
          const existingGeofencePlot = geofencePlots.find(geofencePlot => geofencePlot.id === params.id);
          if (existingGeofencePlot) {
            existingGeofencePlot.isFromSearchLight = true;
            return new TryUpdateZoomToMarker({
              latLng: params.latLng,
              id: existingGeofencePlot.id,
              type: EPlotType.geofence,
              title: existingGeofencePlot.name
            });
          }
        }
        return new GetSearchLightGeofencePlot(params);
      })
    )
  );

  public selectSearchLightHotspot$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectHotspot>(ESearchLightActions.SelectHotspot),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getHotspotPlots)), this._store.pipe(select(getIsSuggestedGeofencesEnabled))),
      map(([params, hotspotPlots, isSuggestedGeofenceEnabled]: [ISearchLightResultParams, IGeofencePlot[], boolean]) => {
        if (isSuggestedGeofenceEnabled) {
          const existingHotspotPlot = hotspotPlots.find(hotspotPlot => hotspotPlot.id === params.id);
          if (existingHotspotPlot) {
            existingHotspotPlot.isFromSearchLight = true;
            return new TryUpdateZoomToMarker({
              latLng: params.latLng,
              id: existingHotspotPlot.id,
              type: EPlotType.hotspot,
              title: existingHotspotPlot.name
            });
          }
        }
        return new GetSearchLightHotspotPlot(params);
      })
    )
  );

  public selectSearchLightWorkOrder$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectWorkOrder>(ESearchLightActions.SelectWorkOrder),
      map(action => action.payload),
      withLatestFrom(this._store.pipe(select(getWorkOrdersPlots)), this._store.pipe(select(getIsWorkOrdersEnabled))),
      map(([params, workOrderPlots, isWorkOrdersEnabled]: [ISearchLightResultParams, IWorkOrderPlot[], boolean]) => {
        if (isWorkOrdersEnabled) {
          const existingWorkOrderPlot = workOrderPlots.find(workOrderPlot => workOrderPlot.WorkOrderId === params.id);
          if (existingWorkOrderPlot) {
            existingWorkOrderPlot.isFromSearchLight = true;
            return new TryUpdateZoomToMarker({
              latLng: params.latLng,
              id: existingWorkOrderPlot.WorkOrderId,
              type: EPlotType.workOrder,
              title: existingWorkOrderPlot.Letter
            });
          }
        }
        return new GetSearchLightWorkOrderPlot(params);
      })
    )
  );

  public showBalloonWhenZoomToMarker$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateZoomToMarker>(ELayoutActions.UpdateZoomToMarker),
      map(action => action.payload),
      map((params: IZoomToMarkerParams) => {
        const markerInfo: MarkerInfo = {
          id: params.id,
          latLng: params.latLng,
          type: params.type,
          options: null,
          title: params.title
        };
        return new ShowBalloon({ data: markerInfo });
      })
    )
  );

  public highlightMarker$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateZoomToMarker>(ELayoutActions.UpdateZoomToMarker),
      map(action => action.payload),
      map((params: IZoomToMarkerParams) => {
        return new SetSelectedMarker(params.id);
      })
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _plotsHttpService: PlotHttpService,
    private readonly _hotspotsHttpService: HotspotsHttpService,
    private readonly _geofencesHttpService: GeofencesHttpService,
    private readonly _garminStopsHttpService: GarminStopsHttpService,
    private readonly _placesHttpService: PlacesHttpService,
    private readonly _planningSchedulingWorkOrderHttpService: PlanningSchedulingWorkOrderHttpService,
    private readonly _categoriesHttpService: CategoriesHttpService,
    private readonly _plotMappingService: PlotMappingService,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {}

  private _updateVehiclePlotSignalStatus(
    signalStatusUpdate: ISignalStatusUpdate,
    vehiclePlot: IVehiclePlot,
    lastUpdate: Date
  ): IVehiclePlot {
    let iconClass: ESignalStatus;
    if (signalStatusUpdate.alert.troubleCode === ESignalStatus.Low) {
      iconClass = ESignalStatus.Low;
    } else if (vehiclePlot.iconClass === EPlotIcon.LostSignal) {
      iconClass = ESignalStatus.Lost;
    } else {
      iconClass = ESignalStatus.Normal;
    }
    return {
      ...vehiclePlot,
      signalStatus: {
        iconClass,
        lastUpdate: lastUpdate
      }
    };
  }

  private _towingVehicleToTowingVehiclePlot(towingVehicle: ITowingVehicle): ITowingVehiclePlotIcon {
    return {
      id: towingVehicle.id,
      iconClass: towingVehicle.iconClass
    };
  }

  private _addSearcLightVehiclesToSelectedVehicles(searchLightVehicleIds: number[], vehicleIdsSet: Set<string>): string[] {
    let vehicleIds = [...vehicleIdsSet];
    searchLightVehicleIds.forEach((searchLightVehicleId: number) => {
      const searchLightVehicle = 'v' + searchLightVehicleId;
      if (!vehicleIdsSet.has(searchLightVehicle)) {
        vehicleIds = [...vehicleIdsSet, searchLightVehicle];
      }
    });
    return vehicleIds;
  }

  private _mapGarminStopsPlotsClassIcon(plots: IGeofencePlot[]) {
    return plots.map(plot => {
      plot.iconclass = plot.iconclass.replace(/\s/g, '-') as EPlotIcon;
      return plot;
    });
  }

  private _getFormattedDateTime(date: Date): string {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      date.getDate().toString().padStart(2, '0') +
      ' ' +
      date.getHours().toString().padStart(2, '0') +
      ':' +
      date.getMinutes().toString().padStart(2, '0')
    );
  }

  private _getReverseGeocodeExecutionBatch(vehiclePlots: IVehiclePlot[]): IReverseGeocodeExecutionBatch {
    const vehiclePlotsToReverseGeocode: IVehiclePlot[] = [];
    let reverseGeocodeExecutionBatch: IReverseGeocodeExecutionBatch;

    vehiclePlots.forEach(plot => {
      if (isStringNullOrEmpty(plot.address)) {
        plot.isSearchingAddress = true;
        vehiclePlotsToReverseGeocode.push(plot);
      }
    });

    if (vehiclePlotsToReverseGeocode.length) {
      reverseGeocodeExecutionBatch = {
        id: Date.now(),
        vehiclePlots: vehiclePlotsToReverseGeocode
      };
    }

    return reverseGeocodeExecutionBatch;
  }

  private _getTowingVehicle(vehiclePlot: IVehiclePlot, towingVehiclePlots: ITowingVehicle[]): ITowingVehicle {
    return towingVehiclePlots.find(towingVehiclePlot => towingVehiclePlot.id === vehiclePlot.id);
  }

  private _hasVehicleIconChanged(updatedVehiclePlot: IVehiclePlot, towingVehicle: ITowingVehicle): boolean {
    return (
      updatedVehiclePlot.iconClass !== EPlotIcon.Towing &&
      updatedVehiclePlot.iconClass !== EPlotIcon.TowingPoweredAsset &&
      updatedVehiclePlot.iconClass !== EPlotIcon.CustomerCare &&
      updatedVehiclePlot.iconClass !== EPlotIcon.CustomerCarePoweredAsset &&
      towingVehicle.originalIconClass !== updatedVehiclePlot.iconClass
    );
  }

  private _convertVehiclePlotUpdatesToModels(
    vehicleUpdatesResponse: (IVehiclePlotResponse | IVehiclePlotWebsubsResponse)[],
    existingVehiclePlotsById: Map<number, IVehiclePlot>,
    classificationsFromHierachy: Map<string, ETrackableClassification>,
    userSettings: IUserSettings,
    accountSettings: IAccountSettings
  ) {
    vehicleUpdatesResponse = this._applyExisistingTrackableClassificationToVehicleUpdates(existingVehiclePlotsById, vehicleUpdatesResponse);
    return this._plotMappingService.convertVehiclePlotModels(
      vehicleUpdatesResponse,
      false,
      classificationsFromHierachy,
      userSettings,
      accountSettings
    );
  }

  private _applyExisistingTrackableClassificationToVehicleUpdates(
    existingVehiclePlotsById: Map<number, IVehiclePlot>,
    vehicleUpdatesResponse: (IVehiclePlotResponse | IVehiclePlotWebsubsResponse)[]
  ): (IVehiclePlotResponse | IVehiclePlotWebsubsResponse)[] {
    // If the updated vehicle plots do not have TrackableClassification
    //  1. Try to get from existing plots
    //  2. Default ETrackableClassification.Vehicle
    const vehiclesWithTrackableClassification = vehicleUpdatesResponse.map(vehicle => {
      if (isElementNull(vehicle.TrackableClassification)) {
        const trackableClassification = existingVehiclePlotsById.has(vehicle.id)
          ? this._convertPlotTypeToTrackableClassification(existingVehiclePlotsById.get(vehicle.id).type)
          : ETrackableClassification.Vehicle;
        vehicle.TrackableClassification = trackableClassification;
      }
      return vehicle;
    });
    return vehiclesWithTrackableClassification;
  }

  private _filterAndEnrichVehiclesToUpdate(
    vehicleUpdatePlotModels: IVehiclePlot[],
    existingPlotsById: Map<number, IVehiclePlot>
  ): IVehiclePlot[] {
    const vehiclesToUpdate: IVehiclePlot[] = [];

    for (const vehicleUpdate of vehicleUpdatePlotModels) {
      if (!existingPlotsById.has(vehicleUpdate.id)) {
        continue;
      }

      this._enrichVehicleUpdateWithExistingData(vehicleUpdate, existingPlotsById.get(vehicleUpdate.id));
      existingPlotsById.set(vehicleUpdate.id, vehicleUpdate);
      vehiclesToUpdate.push(vehicleUpdate);
    }
    return vehiclesToUpdate;
  }

  private _enrichVehicleUpdateWithExistingData(vehicleUpdate: IVehiclePlot, existingVehiclePlot: IVehiclePlot): void {
    vehicleUpdate.hasNavigationDevice = vehicleUpdate.hasNavigationDevice ?? existingVehiclePlot.hasNavigationDevice;
    vehicleUpdate.isSearchingAddress = isStringNullOrEmpty(vehicleUpdate.address);
    vehicleUpdate.isFromSearchLight = existingVehiclePlot.isFromSearchLight;
    vehicleUpdate.isUpdating = true;
  }

  private _convertPlotTypeToTrackableClassification(plotType: EPlotType): ETrackableClassification {
    return plotType === EPlotType.poweredAsset ? ETrackableClassification.PoweredAsset : ETrackableClassification.Vehicle;
  }
}
