/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IPlaceCategory, IWorkOrderAdvancedOptions, IWorkOrderUserSettings, IWorkOrderTypesAndStatusesResponse } from '../../models';

export enum EAdvancedOptionsActions {
  GetPlaceCategories = '[Advanced Options] Get Place Categories',
  GetPlaceCategoriesSuccess = '[Advanced Options] Get Place Categories Success',
  UpdatePlaceCategoryIds = '[Advanced Options] Update Place Category Ids',
  UpdatePlaceCategoryIdsSilent = '[Advanced Options] Update Place Category Ids Silent',
  UpdateGarminStopStatusesIdsSelected = '[Advanced Options] Update Garmin Stops Statuses Ids Selected',
  UpdateGarminStopStatusesIdsSelectedSilent = '[Advanced Options] Update Garmin Stops Statuses Ids Selected Silent',
  GetWorkOrderSettings = '[Advanced Options] Get Work Order Settings',
  GetWorkOrderSettingsSuccess = '[Advanced Options] Get Work Order Settings Success',
  UpdateWorkOrderSettingsSuccess = '[Advanced Options] Update Work Order Settings Success',
  GetWorkOrderTypesAndStatuses = '[Advanced Options] Get Work Order Types And Statuses',
  GetWorkOrderTypesAndStatusesSuccess = '[Advanced Options] Get Work Order Types And Statuses Success',
  GetWorkOrderAdvancedOptions = '[Advanced Options] Get Work Order Advanced Options',
  GetWorkOrderAdvancedOptionsSuccess = '[Advanced Options] Get Work Order Advanced Options Success',
  UpdateWorkOrderAdvancedOptions = '[Advanced Options] Update Work Order Advanced Options'
}

export class GetPlaceCategories implements Action {
  public readonly type = EAdvancedOptionsActions.GetPlaceCategories;
}

export class GetPlaceCategoriesSuccess implements Action {
  public readonly type = EAdvancedOptionsActions.GetPlaceCategoriesSuccess;

  constructor(public payload: IPlaceCategory[]) {}
}

export class UpdatePlaceCategoryIds implements Action {
  public readonly type = EAdvancedOptionsActions.UpdatePlaceCategoryIds;

  constructor(public payload: number[]) {}
}

export class UpdatePlaceCategoryIdsSilent implements Action {
  public readonly type = EAdvancedOptionsActions.UpdatePlaceCategoryIdsSilent;

  constructor(public payload: number[]) {}
}

export class UpdateGarminStopStatusesIdsSelected implements Action {
  public readonly type = EAdvancedOptionsActions.UpdateGarminStopStatusesIdsSelected;

  constructor(public payload: number[]) {}
}

export class UpdateGarminStopStatusesIdsSelectedSilent implements Action {
  public readonly type = EAdvancedOptionsActions.UpdateGarminStopStatusesIdsSelectedSilent;

  constructor(public payload: number[]) {}
}

export class GetWorkOrderSettings implements Action {
  public readonly type = EAdvancedOptionsActions.GetWorkOrderSettings;
}

export class GetWorkOrderSettingsSuccess implements Action {
  public readonly type = EAdvancedOptionsActions.GetWorkOrderSettingsSuccess;

  constructor(public payload: IWorkOrderUserSettings) {}
}

export class UpdateWorkOrderSettingsSuccess implements Action {
  public readonly type = EAdvancedOptionsActions.UpdateWorkOrderSettingsSuccess;

  constructor(public payload: IWorkOrderAdvancedOptions) {}
}

export class GetWorkOrderTypesAndStatuses implements Action {
  public readonly type = EAdvancedOptionsActions.GetWorkOrderTypesAndStatuses;
}

export class GetWorkOrderTypesAndStatusesSuccess implements Action {
  public readonly type = EAdvancedOptionsActions.GetWorkOrderTypesAndStatusesSuccess;

  constructor(public payload: IWorkOrderTypesAndStatusesResponse) {}
}

export class GetWorkOrderAdvancedOptions implements Action {
  public readonly type = EAdvancedOptionsActions.GetWorkOrderAdvancedOptions;

  constructor(public payload: boolean) {}
}

export class GetWorkOrderAdvancedOptionsSuccess implements Action {
  public readonly type = EAdvancedOptionsActions.GetWorkOrderAdvancedOptionsSuccess;

  constructor(public payload: IWorkOrderAdvancedOptions) {}
}

export class UpdateWorkOrderAdvancedOptions implements Action {
  public readonly type = EAdvancedOptionsActions.UpdateWorkOrderAdvancedOptions;

  constructor(public payload: IWorkOrderAdvancedOptions) {}
}

export type AdvancedOptionsActions =
  | GetPlaceCategories
  | GetPlaceCategoriesSuccess
  | UpdatePlaceCategoryIds
  | UpdatePlaceCategoryIdsSilent
  | UpdateGarminStopStatusesIdsSelected
  | UpdateGarminStopStatusesIdsSelectedSilent
  | GetWorkOrderAdvancedOptions
  | GetWorkOrderAdvancedOptionsSuccess
  | UpdateWorkOrderAdvancedOptions
  | GetWorkOrderSettings
  | GetWorkOrderSettingsSuccess
  | UpdateWorkOrderSettingsSuccess;
