/*
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Component, ViewChild, TemplateRef, Input, ViewContainerRef } from '@angular/core';

import { IModalConfig } from '../../models';

@Component({
  selector: 'fm-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent {
  @Input()
  set contentTemplate(value: TemplateRef<ViewContainerRef>) {
    this.contentContainer.createEmbeddedView(value);
  }

  public config: IModalConfig = {
    showCloseIcon: true,
    closeIcon: 'ds-ic-close',
    disableResponsiveness: false,
    size: 'large',
    customClass: ''
  };

  @ViewChild('content', { read: ViewContainerRef, static: true })
  contentContainer: ViewContainerRef;

  public setConfig(config: IModalConfig): void {
    this.config = { ...this.config, ...config };
  }

  public onCloseClick() {
    this._closeModal();
  }

  public closeModal(): void {}

  private _closeModal() {
    this.closeModal();
  }
}
