/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

export enum EVehiclesSortType {
  Status = 0,
  Vehicle = 1,
  Driver = 2,
  Group = 3,
  LastUpdate = 4
}
