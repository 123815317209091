/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, filter, tap, catchError } from 'rxjs/operators';

import { isElementNull } from '@fleetmatics/ui.utilities';

import {
  OpenRoadsideAssistanceModal,
  ERoadsideAssistanceActions,
  OpenRoadsideAssistanceModalSuccess,
  GetUserRoadsideAssistanceStatus,
  GetUserRoadsideAssistanceStatusSuccess,
  OpenLearnMoreWindow
} from '../actions';
import { RoadsideAssistanceHttpService, ModalWindowService } from '../../services';
import { delayedRetry } from '../../operators';

@Injectable()
export class RoadsideAssistanceEffects {

  openRoadsideAssistanceModal$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<OpenRoadsideAssistanceModal>(ERoadsideAssistanceActions.OpenRoadsideAssistanceModal),
    map((action: OpenRoadsideAssistanceModal) => action.payload),
    map(vehicleId => vehicleId.toString()),
    switchMap(vehicleId =>
      this._roadsideAssistanceHttpService.getVehicleDetails(vehicleId).pipe(
        delayedRetry(),
        catchError(() => of(null).pipe(filter(() => false))),
        map(vehicle => {
          const vin = vehicle !== null && vehicle.isActivated ? vehicle.vin : null;
          return new OpenRoadsideAssistanceModalSuccess(vin);
        })
      )
    )
  ));

  getUserRoadsideAssistanceStatus$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<GetUserRoadsideAssistanceStatus>(ERoadsideAssistanceActions.GetUserRoadsideAssistanceStatus),
    filter(() => isPlatformBrowser(this._platformId)),
    switchMap(() =>
      this._roadsideAssistanceHttpService.getUserRoadsideAssistanceStatus().pipe(
        delayedRetry(),
        catchError(() => of(null))
      )
    ),
    filter(status => !isElementNull(status)),
    map(userRoadsideAssistanceStatus => new GetUserRoadsideAssistanceStatusSuccess(userRoadsideAssistanceStatus))
  ));

  openLearnMoreWindow$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<OpenLearnMoreWindow>(ERoadsideAssistanceActions.OpenLearnMoreWindow),
    tap(() => this._modalWindowService.openNewWindow('https://www.verizonconnect.com/solutions/commercial-roadside-assistance/', '_blank'))
  ), { dispatch: false });

  constructor(
    private readonly _actions$: Actions,
    private readonly _roadsideAssistanceHttpService: RoadsideAssistanceHttpService,
    private readonly _modalWindowService: ModalWindowService,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {}
}
