/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IUserRoadsideAssistanceStatus } from '../../models';

export const enum ERoadsideAssistanceActions {
  CloseRoadsideAssistanceModal = '[RoadsideAssistance] Close Roadside Assistance Modal',
  GetUserRoadsideAssistanceStatus = '[RoadsideAssistance] Get User Roadside Assistance Status',
  GetUserRoadsideAssistanceStatusSuccess = '[RoadsideAssistance] Get User Roadside Assistance Status Success',
  OpenRoadsideAssistanceModal = '[RoadsideAssistance] Open Roadside Assistance Modal',
  OpenRoadsideAssistanceModalSuccess = '[RoadsideAssistance] Open Roadside Assistance Modal Success',
  OpenRoadsideAssistanceMarketingModal = '[RoadsideAssistance] Open Roadside Assistance Marketing Modal',
  OpenLearnMoreWindow = '[RoadsideAssistance] Open Roadside Assistance Learn More Window'
}

export class CloseRoadsideAssistanceModal implements Action {
  public readonly type = ERoadsideAssistanceActions.CloseRoadsideAssistanceModal;
}

export class GetUserRoadsideAssistanceStatus implements Action {
  public readonly type = ERoadsideAssistanceActions.GetUserRoadsideAssistanceStatus;
}

export class GetUserRoadsideAssistanceStatusSuccess implements Action {
  public readonly type = ERoadsideAssistanceActions.GetUserRoadsideAssistanceStatusSuccess;

  constructor(public payload: IUserRoadsideAssistanceStatus) {}
}

export class OpenRoadsideAssistanceModal implements Action {
  public readonly type = ERoadsideAssistanceActions.OpenRoadsideAssistanceModal;

  constructor(public payload: number) {}
}

export class OpenRoadsideAssistanceModalSuccess implements Action {
  public readonly type = ERoadsideAssistanceActions.OpenRoadsideAssistanceModalSuccess;

  constructor(public payload: string) {}
}

export class OpenRoadsideAssistanceMarketingModal implements Action {
  public readonly type = ERoadsideAssistanceActions.OpenRoadsideAssistanceMarketingModal;
}

export class OpenLearnMoreWindow implements Action {
  public readonly type = ERoadsideAssistanceActions.OpenLearnMoreWindow;
}

export type RoadsideAssistanceActions =
  | CloseRoadsideAssistanceModal
  | GetUserRoadsideAssistanceStatus
  | GetUserRoadsideAssistanceStatusSuccess
  | OpenRoadsideAssistanceModal
  | OpenRoadsideAssistanceModalSuccess
  | OpenRoadsideAssistanceMarketingModal
  | OpenLearnMoreWindow;
