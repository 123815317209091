/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { INotificationHttpService } from './notification-http.service.interface';
import { IAppConfig } from '../../../config/app-config.interfaces';
import { ISmsOptInStatus, IApiResponseDto, IDriverSmsMessage, ISmsInvitation } from '../../models';

@Injectable()
export class NotificationHttpService implements INotificationHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.notificationsApiEndpoint;
  }

  public getDriverSmsOptInStatus(driverId: number): Observable<ISmsOptInStatus> {
    const params = new HttpParams().set('request[DriverId]', driverId.toString());

    return this._httpClient
      .get<IApiResponseDto<ISmsOptInStatus>>(`${this._rootUrl}/Notification/GetDriverSmsOptInStatus`, { params })
      .pipe(map((response: IApiResponseDto<ISmsOptInStatus>) => response.Data));
  }

  public sendSmsInvitationToDriver(driverId: number): Observable<ISmsInvitation> {
    return this._httpClient
      .post<IApiResponseDto<ISmsInvitation>>(`${this._rootUrl}/Notification/SendSmsInviteToDriver`, { DriverId: driverId })
      .pipe(map((response: IApiResponseDto<ISmsInvitation>) => response.Data));
  }

  public sendSmsToDriver(message: IDriverSmsMessage): Observable<boolean> {
    return this._httpClient
      .post<IApiResponseDto<boolean>>(`${this._rootUrl}/Notification/SendSmsFromLiveMap`, message)
      .pipe(map((response: IApiResponseDto<boolean>) => response.Data));
  }
}
