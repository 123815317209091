/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@fleetmatics/ui.utilities';

import { IPlanningSchedulingHttpService } from './planning-scheduling-http.service.interface';
import {
  IGarminStopHistory,
  IGarminStopHistoryParams,
  IGetWorkOrderResponse,
  IApiResponseDto,
  IUpdateWorkOrderRequest
} from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';

@Injectable()
export class PlanningSchedulingHttpService implements IPlanningSchedulingHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.planningSchedulingApiEndpoint;
  }

  public getGarminStopHistory(garminStopHistoryParams: IGarminStopHistoryParams): Observable<IGarminStopHistory[]> {
    let params = new HttpParams();
    params = params.append('request[VehicleId]', garminStopHistoryParams.VehicleId.toString());
    params = params.append('request[StopId]', garminStopHistoryParams.StopId.toString());

    return this._httpClient
      .get<IGarminStopHistory[]>(`${this._rootUrl}/Garmin/StopHistory`, { params })
      .pipe(map((response: IGarminStopHistory[]) => response));
  }

  public getWorkOrder(workOrderId: number): Observable<IGetWorkOrderResponse> {
    let params = new HttpParams();
    params = params.append('data[workorderId]', workOrderId.toString());

    return this._httpClient
      .get<IApiResponseDto<IGetWorkOrderResponse>>(`${this._rootUrl}/WorkOrder/GetWorkOrder`, { params })
      .pipe(map(response => response.Data));
  }

  public updateWorkOrder(request: IUpdateWorkOrderRequest): Observable<IApiResponseDto<any>> {
    return this._httpClient.post<IApiResponseDto<any>>(`${this._rootUrl}/WorkOrder/UpdateWorkOrder`, request);
  }
}
