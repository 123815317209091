/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, of, zip, EMPTY } from 'rxjs';
import { map, switchMap, withLatestFrom, take, catchError, filter, mergeMap } from 'rxjs/operators';

import { isElementNull } from '@fleetmatics/ui.utilities';

import {
  EAdvancedOptionsActions,
  GetPlaceCategories,
  GetPlaceCategoriesSuccess,
  GetWorkOrderTypesAndStatuses,
  GetWorkOrderSettings,
  GetWorkOrderAdvancedOptions,
  GetWorkOrderSettingsSuccess,
  GetWorkOrderTypesAndStatusesSuccess,
  GetWorkOrderAdvancedOptionsSuccess,
  UpdateWorkOrderAdvancedOptions,
  UpdateWorkOrderSettingsSuccess,
  GetWorkOrdersPlots
} from '../actions';
import { getWorkOrderAdvancedOptions } from '../selectors';
import { IAppState } from '../state';
import {
  IPlaceCategory,
  IWorkOrderAdvancedOptions,
  EWorkOrderFilterOptions,
  IWorkOrderUserSettings,
  IWorkOrderTypesAndStatusesResponse
} from '../../models';
import { PlotHttpService, CategoriesHttpService } from '../../services';
import { delayedRetry } from '../../operators';

@Injectable()
export class AdvancedOptionsEffects {
  public getPlaceCategories$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetPlaceCategories>(EAdvancedOptionsActions.GetPlaceCategories),
      switchMap(() =>
        this._categoriesHttpService.getPlaceCategories().pipe(
          delayedRetry(),
          catchError(() => of(null))
        )
      ),
      filter(placeCategories => !isElementNull(placeCategories)),
      map((placeCategories: IPlaceCategory[]) => new GetPlaceCategoriesSuccess(placeCategories))
    )
  );

  public getWorkOrderAdvancedOptions$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetWorkOrderAdvancedOptions>(EAdvancedOptionsActions.GetWorkOrderAdvancedOptions),
      mergeMap(() => [new GetWorkOrderSettings(), new GetWorkOrderTypesAndStatuses()])
    )
  );

  public getWorkOrderSettings$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetWorkOrderSettings>(EAdvancedOptionsActions.GetWorkOrderSettings),
      switchMap(() =>
        this._plotHttpService.getWorkOrderSettings().pipe(
          delayedRetry(),
          catchError(() => of(null))
        )
      ),
      filter(settings => !isElementNull(settings)),
      map((settings: IWorkOrderUserSettings) => new GetWorkOrderSettingsSuccess(settings))
    )
  );

  public getWorkOrderTypesAndStatuses$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetWorkOrderTypesAndStatuses>(EAdvancedOptionsActions.GetWorkOrderTypesAndStatuses),
      switchMap(() =>
        this._plotHttpService.getWorkOrderTypesAndStatuses().pipe(
          delayedRetry(),
          catchError(() => of(null))
        )
      ),
      filter(typesAndStatuses => !isElementNull(typesAndStatuses)),
      map((typesAndStatuses: IWorkOrderTypesAndStatusesResponse) => new GetWorkOrderTypesAndStatusesSuccess(typesAndStatuses))
    )
  );

  public getWorkOrderAdvancedOptionsSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetWorkOrderAdvancedOptions>(EAdvancedOptionsActions.GetWorkOrderAdvancedOptions),
      mergeMap(() =>
        zip(
          this._actions$.pipe(
            ofType<GetWorkOrderSettingsSuccess>(EAdvancedOptionsActions.GetWorkOrderSettingsSuccess),
            take(1),
            map(action => action.payload)
          ),
          this._actions$.pipe(
            ofType<GetWorkOrderTypesAndStatusesSuccess>(EAdvancedOptionsActions.GetWorkOrderTypesAndStatusesSuccess),
            take(1),
            map(action => action.payload)
          )
        )
      ),
      withLatestFrom(this._store.pipe(select(getWorkOrderAdvancedOptions))),
      switchMap(
        ([[workOrderSettings, workOrderTypesAndStatuses], advancedOptions]: [
          [IWorkOrderUserSettings, IWorkOrderTypesAndStatusesResponse],
          IWorkOrderAdvancedOptions
        ]) => {
          const dateWithMinutes = (minutes: number) => {
            const date = new Date();
            date.setHours(0);
            date.setSeconds(0);
            date.setMinutes(minutes);
            return date;
          };
          const startDate = dateWithMinutes(workOrderSettings.ScheduledWorkOrderStartTotalMinutes);
          const endDate = dateWithMinutes(workOrderSettings.ScheduledWorkOrderEndTotalMinutes);

          if (!isElementNull(workOrderSettings)) {
            // tslint:disable:no-bitwise
            const areAssigned =
              (workOrderSettings.WorkOrderFilterOption & EWorkOrderFilterOptions.Assigned) === EWorkOrderFilterOptions.Assigned;
            const areUnassigned =
              (workOrderSettings.WorkOrderFilterOption & EWorkOrderFilterOptions.Unassigned) === EWorkOrderFilterOptions.Unassigned;
            // tslint:enable

            return of({
              areAssignedSelected: areAssigned,
              areUnassignedSelected: areUnassigned,
              scheduleStartMinutes: workOrderSettings.ScheduledWorkOrderStartTotalMinutes,
              scheduleEndMinutes: workOrderSettings.ScheduledWorkOrderEndTotalMinutes,
              selectedStartDate: !isElementNull(advancedOptions) ? advancedOptions.selectedStartDate : startDate,
              selectedEndDate: !isElementNull(advancedOptions) ? advancedOptions.selectedEndDate : endDate,
              statuses: workOrderTypesAndStatuses.Statuses,
              types: workOrderTypesAndStatuses.Types
            } as IWorkOrderAdvancedOptions);
          }
          return of({
            areAssignedSelected: false,
            areUnassignedSelected: false,
            scheduleStartMinutes: 0,
            scheduleEndMinutes: 0,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            statuses: workOrderTypesAndStatuses.Statuses,
            types: workOrderTypesAndStatuses.Types
          } as IWorkOrderAdvancedOptions);
        }
      ),
      map((workOrderAdvancedOptions: IWorkOrderAdvancedOptions) => new GetWorkOrderAdvancedOptionsSuccess(workOrderAdvancedOptions))
    )
  );

  public updateWorkOrderAdvancedOptions$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateWorkOrderAdvancedOptions>(EAdvancedOptionsActions.UpdateWorkOrderAdvancedOptions),
      map(action => action.payload),
      map((advancedOptions): [IWorkOrderUserSettings, IWorkOrderAdvancedOptions] => {
        let filterOption = EWorkOrderFilterOptions.None;
        // tslint:disable:no-bitwise
        if (advancedOptions.areAssignedSelected) {
          filterOption = filterOption |= EWorkOrderFilterOptions.Assigned;
        }
        if (advancedOptions.areUnassignedSelected) {
          filterOption = filterOption |= EWorkOrderFilterOptions.Unassigned;
        }
        // tslint:enable

        return [
          {
            ScheduledWorkOrderEndTotalMinutes: advancedOptions.scheduleEndMinutes,
            ScheduledWorkOrderStartTotalMinutes: advancedOptions.scheduleStartMinutes,
            SelectedWorkOrderStatusIds: advancedOptions.statuses.filter(status => status.IsSelected).map(status => status.Id),
            SelectedWorkOrderTypeIds: advancedOptions.types.filter(type => type.IsSelected).map(type => type.Id),
            WorkOrderFilterOption: filterOption
          } as IWorkOrderUserSettings,
          advancedOptions
        ];
      }),
      switchMap(([settings, advancedOptions]) =>
        this._plotHttpService.updateWorkOrderSettings(settings).pipe(
          delayedRetry(),
          switchMap(() => [new UpdateWorkOrderSettingsSuccess(advancedOptions), new GetWorkOrdersPlots()]),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _plotHttpService: PlotHttpService,
    private readonly _categoriesHttpService: CategoriesHttpService
  ) {}
}
