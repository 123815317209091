/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IVehiclePlot } from '../../core/models';
import {
  IDemoMapHierarchyItem,
  IZoomToLocationParams,
  DemoProgress,
  IAdvanceStoryProgressParams,
  DemoStoryProgress,
  EDemoStories,
  IMarketingTextContent,
  IDemoVariationContent
} from '../models';

export enum EDemoActions {
  SetupDemo = '[Demo] Setup Demo',
  StartDemoStories = '[Demo] Start Demo Stories',
  UpdateDemoPlots = '[Demo] Update Demo Plots',
  UpdateDemoHierarchy = '[Demo] Update Demo Hierarchy',
  FocusMapToPlot = '[Demo] Focus Map To Plot',
  FocusMapToLocation = '[Demo] Focus Map To Location',
  StartSimulatingPlots = '[Demo] Start Simulating Plots',
  UpdateStoryProgress = '[Demo] Update Story Progress',
  UpdateStoryProgressSuccess = '[Demo] Update Story Progress Success',
  DismissStory = '[Demo] Dismiss Story',
  UpdateDemoProgress = '[Demo] Update Demo Progress',
  CloseInterstitialScreen = '[Demo] Close Interstitial Screen',
  ShowInterstitialScreen = '[Demo] Show Interstitial Screen',
  InterstitialScreenMoveLeft = '[Demo] Interstitial Screen Move Left',
  InterstitialScreenMoveRight = '[Demo] Interstitial Screen Move Right',
  InterstitialScreenMoveLeftSuccess = '[Demo] Interstitial Screen Move Left Success',
  InterstitialScreenMoveRightSuccess = '[Demo] Interstitial Screen Move Right Success',
  StartStory = '[Demo] Start Story',
  SetMarketingTextContent = '[Demo] Set Buy Now Message Variant',
  ShowShareDemoModal = '[Demo] Show Share Demo Modal',
  ShareDemoLink = '[Demo] Share Demo Link',
  CopyDemoLink = '[Demo] Copy To Clipboard',
  SetDemoVariationContent = '[Demo] Set Demo Variation Content'
}

export class SetupDemo implements Action {
  public readonly type = EDemoActions.SetupDemo;
}

export class StartDemoStories implements Action {
  public readonly type = EDemoActions.StartDemoStories;
}

export class UpdateDemoPlots implements Action {
  public readonly type = EDemoActions.UpdateDemoPlots;

  constructor(public payload: IVehiclePlot[]) {}
}

export class UpdateDemoHierarchy implements Action {
  public readonly type = EDemoActions.UpdateDemoHierarchy;
  constructor(public payload: IDemoMapHierarchyItem[]) {}
}

export class StartSimulatingPlots implements Action {
  public readonly type = EDemoActions.StartSimulatingPlots;
}

export class FocusMapToPlot implements Action {
  public readonly type = EDemoActions.FocusMapToPlot;
  constructor(public payload: number) {}
}

export class FocusMapToLocation implements Action {
  public readonly type = EDemoActions.FocusMapToLocation;
  constructor(public payload: IZoomToLocationParams) {}
}

export class UpdateStoryProgress implements Action {
  public readonly type = EDemoActions.UpdateStoryProgress;
  constructor(public payload: IAdvanceStoryProgressParams) {}
}

export class UpdateStoryProgressSuccess implements Action {
  public readonly type = EDemoActions.UpdateStoryProgressSuccess;
  constructor(public payload: DemoStoryProgress) {}
}

export class UpdateDemoProgress implements Action {
  public readonly type = EDemoActions.UpdateDemoProgress;
  constructor(public payload: DemoProgress) {}
}

export class DismissStory implements Action {
  public readonly type = EDemoActions.DismissStory;
  constructor(public payload: EDemoStories) {}
}

export class CloseInterstitialScreen implements Action {
  public readonly type = EDemoActions.CloseInterstitialScreen;
}

export class ShowInterstitialScreen implements Action {
  public readonly type = EDemoActions.ShowInterstitialScreen;
}

export class InterstitialScreenMoveLeft implements Action {
  public readonly type = EDemoActions.InterstitialScreenMoveLeft;
}

export class InterstitialScreenMoveRight implements Action {
  public readonly type = EDemoActions.InterstitialScreenMoveRight;
}

export class InterstitialScreenMoveLeftSuccess implements Action {
  public readonly type = EDemoActions.InterstitialScreenMoveLeftSuccess;
  constructor(public payload: number) {}
}

export class InterstitialScreenMoveRightSuccess implements Action {
  public readonly type = EDemoActions.InterstitialScreenMoveRightSuccess;
  constructor(public payload: number) {}
}

export class StartStory implements Action {
  public readonly type = EDemoActions.StartStory;

  constructor(public payload: EDemoStories) {}
}

export class SetMarketingTextContentVariation implements Action {
  public readonly type = EDemoActions.SetMarketingTextContent;

  constructor(public payload: IMarketingTextContent) {}
}

export class ShowShareDemoModal implements Action {
  public readonly type = EDemoActions.ShowShareDemoModal;
}

export class ShareDemoLink implements Action {
  public readonly type = EDemoActions.ShareDemoLink;
}

export class CopyDemoLink implements Action {
  public readonly type = EDemoActions.CopyDemoLink;
}

export class SetDemoVariationContent implements Action {
  public readonly type = EDemoActions.SetDemoVariationContent;

  constructor(public payload: IDemoVariationContent) {}
}

export type DemoActions =
  | SetupDemo
  | StartDemoStories
  | UpdateDemoPlots
  | UpdateDemoHierarchy
  | StartSimulatingPlots
  | FocusMapToPlot
  | FocusMapToLocation
  | UpdateStoryProgress
  | UpdateStoryProgressSuccess
  | UpdateDemoProgress
  | CloseInterstitialScreen
  | ShowInterstitialScreen
  | InterstitialScreenMoveLeft
  | InterstitialScreenMoveRight
  | InterstitialScreenMoveLeftSuccess
  | InterstitialScreenMoveRightSuccess
  | DismissStory
  | StartStory
  | SetMarketingTextContentVariation
  | ShowShareDemoModal
  | ShareDemoLink
  | CopyDemoLink
  | SetDemoVariationContent;
