/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { isElementNull } from '@fleetmatics/ui.utilities';

import { IReplayState } from '../state';
import { IReplayJourney, IReplayPlotData, IReplayJourneySegment, EPanelDrawerState } from '../../models';
import {
  getUserSpeedingThreshold,
  getVehiclePlots,
  getReplayLayout,
  getIsReplayOpen,
  getMapZoomLevel,
  getReplayUserSettings,
  getUserSettings
} from '../../../core/store/selectors';
import { IReplayPlot, IVehiclePlot, ReplayMarkerInfo, IPolylineInfo, MarkerInfo, IPolylineHoverParams } from '../../../core/models';
import { ReplayMappingUtilities } from '../effects/replay-mapping.utilities';

export const getReplayState = createFeatureSelector<IReplayState>('replay');

export const getReplayJourney = createSelector(
  getReplayState,
  (state: IReplayState): IReplayJourney => (!isElementNull(state) ? state.journey : null)
);

export const getSelectedMultiday = createSelector(getReplayState, (state: IReplayState): number =>
  !isElementNull(state) ? state.multidaySelectedDay : null
);

export const getIsJourneyMultiday = createSelector(
  getReplayJourney,
  (journey: IReplayJourney): boolean => !isElementNull(journey) && journey.selectedDateRange.isMultiDay()
);

export const getIsReplayMultidayPanelOpen = createSelector(
  getIsJourneyMultiday,
  getSelectedMultiday,
  (isMultiday: boolean, selectedMultiday): boolean => isMultiday && isElementNull(selectedMultiday)
);

export const getReplaySegments = createSelector(
  getReplayJourney,
  getSelectedMultiday,
  (state: IReplayJourney, selectedMultiday: number): IReplayJourneySegment[] => {
    if (isElementNull(state)) {
      return null;
    }

    if (isElementNull(selectedMultiday)) {
      return state.days.reduce((segments, day) => [...segments, ...day.segments], <IReplayJourneySegment[]>[]);
    }

    return state.days[selectedMultiday - 1].segments;
  }
);

export const getAllPlotsOfJourney = createSelector(
  getReplayJourney,
  getReplaySegments,
  (state: IReplayJourney, segments: IReplayJourneySegment[]): IReplayPlotData[] => {
    if (isElementNull(state) || segments.length === 0) {
      return [];
    }

    const allPlots = segments.reduce((plots, segment) => [...plots, ...segment.plots], <IReplayPlotData[]>[]);
    return allPlots;
  }
);

export const getSelectedSegmentId = createSelector(getReplayState, (state: IReplayState): number =>
  !isElementNull(state) ? state.selectedSegmentId : null
);

export const getIsSelectedSegmentSticky = createSelector(getReplayState, (state: IReplayState): boolean =>
  !isElementNull(state) ? state.isSelectedSegmentSticky : null
);

export const getHoveredSegmentId = createSelector(getReplayState, (state: IReplayState): number =>
  !isElementNull(state) ? state.hoveredSegmentId : null
);

export const getPlotsOfSelectedSegment = createSelector(
  getReplaySegments,
  getSelectedSegmentId,
  (segments, selectedSegmentId): IReplayPlotData[] =>
    isElementNull(selectedSegmentId) || isElementNull(segments[selectedSegmentId]) ? null : segments[selectedSegmentId].plots
);

export const getPlotData = createSelector(
  getAllPlotsOfJourney,
  (plots: IReplayPlotData[], props: any): IReplayPlotData => {
    const plot = plots.find(p => p.id === props.plotId);
    return plot;
  }
);

export const getSegmentOfPlot = createSelector(
  getAllPlotsOfJourney,
  getReplaySegments,
  (plots: IReplayPlotData[], segments: IReplayJourneySegment[], props: any) => {
    const plot = plots.find(p => p.id === props.plotId);
    if (isElementNull(plot)) {
      return null;
    }
    return segments[plot.segmentIndex];
  }
);

export const getShouldAnimatePlots = createSelector(getReplayState, (state: IReplayState): boolean =>
  !isElementNull(state) ? state.shouldAnimatePlots : null
);

export const isJourneyLoading = createSelector(
  getReplayState,
  (state: IReplayState): boolean => !isElementNull(state) && state.isLoadingJourney
);

export const canClickViewAll = createSelector(getReplayState, (state: IReplayState): boolean => state.canClickViewAll);

export const canFitToMap = createSelector(getReplayState, (state: IReplayState): boolean => state.canFitToMap);

export const getNoActivityForVehicle = createSelector(
  getReplayJourney,
  getReplaySegments,
  isJourneyLoading,
  getIsReplayOpen,
  (journey, segments, isLoading, isReplayOpen): boolean => {
    if (isLoading || !isReplayOpen) {
      return false;
    }

    return isElementNull(journey) || isElementNull(journey.days) || segments.length === 0;
  }
);

export const getLastKnownVehiclePlot = createSelector(
  getVehiclePlots,
  getReplayLayout,
  (plots, layout): IVehiclePlot => {
    if (isElementNull(plots) || isElementNull(layout) || isElementNull(layout.viewReplayParams)) {
      return null;
    }
    return plots.find(p => p.id === layout.viewReplayParams.id);
  }
);

export const getReplayPlots = createSelector(
  getReplaySegments,
  getSelectedSegmentId,
  getHoveredSegmentId,
  getLastKnownVehiclePlot,
  getUserSpeedingThreshold,
  isJourneyLoading,
  getSelectedMultiday,
  getIsJourneyMultiday,
  (
    segments,
    selectedSegmentId,
    hoveredSegmentId,
    lastKnownPlot,
    userSpeedingThreshold,
    isLoading,
    selectedMultiday,
    isJourneyMultiday
  ): IReplayPlot[] => {
    if (isElementNull(segments) || segments.length === 0) {
      if (isLoading || isElementNull(lastKnownPlot)) {
        return [];
      }
      return ReplayMappingUtilities.getLastKnownReplayPlot(lastKnownPlot);
    }

    const hoveredOrSelectedId = !isElementNull(hoveredSegmentId) ? hoveredSegmentId : selectedSegmentId;
    const hoveredSegment = !isElementNull(hoveredSegmentId) ? segments.find(segment => segment.id === hoveredSegmentId) : null;
    const selectedSegment = !isElementNull(selectedSegmentId) ? segments.find(segment => segment.id === selectedSegmentId) : null;
    const replayPlots = segments.reduce((plots, segment) => {
      const plotsOfSegment = segment.plots.map(
        (plotData): IReplayPlot => {
          return ReplayMappingUtilities.getReplayPlot(plotData, segment, selectedSegment, hoveredSegment, userSpeedingThreshold);
        }
      );
      return plots.concat(plotsOfSegment);
    }, <IReplayPlot[]>[]);

    if (!isJourneyMultiday || !isElementNull(selectedMultiday)) {
      // hide "start plot" if the "actual first segment" is selected/hovered
      ReplayMappingUtilities.hideStartEndPlotWhenHoveredOrSelected(hoveredOrSelectedId, replayPlots);
    }

    return replayPlots;
  }
);

export const getHoveredPolyline = createSelector(
  getReplayState,
  (state: IReplayState): IPolylineHoverParams => (!isElementNull(state) ? state.hoveredPolyline : null)
);

export const getHoveredPolylineId = createSelector(getHoveredPolyline, (hoveredPolyline: IPolylineHoverParams): number =>
  !isElementNull(hoveredPolyline) ? hoveredPolyline.polylineInfo.id : null
);

export const getJourneyBasePolylines = createSelector(getReplayJourney, (journey: IReplayJourney): IPolylineInfo[] => {
  if (isElementNull(journey) || journey.days.length === 1) {
    return [];
  }

  const lineInfos = journey.days
    .map((day, index) => {
      return ReplayMappingUtilities.journeyDayToBasePolylineInfo(day, index, journey.days.length);
    })
    .filter(lineInfo => !isElementNull(lineInfo));

  return lineInfos;
});

export const getPolylineMarkersbyZoomLevel = createSelector(
  getJourneyBasePolylines,
  (basePolylines: IPolylineInfo[]): Map<number, Map<number, ReplayMarkerInfo[]>> => {
    const idToZoomLevelMarkersMap = new Map<number, Map<number, ReplayMarkerInfo[]>>();
    basePolylines.forEach(basePolyline => {
      const thisPolylineMap = ReplayMappingUtilities.getZoomtoMarkersMap(basePolyline);
      idToZoomLevelMarkersMap.set(basePolyline.id, thisPolylineMap);
    });

    return idToZoomLevelMarkersMap;
  }
);
export const getReplayPolylines = createSelector(
  getJourneyBasePolylines,
  getHoveredPolylineId,
  getIsReplayMultidayPanelOpen,
  getMapZoomLevel,
  getPolylineMarkersbyZoomLevel,
  (
    basePolylines: IPolylineInfo[],
    hoveredPolyline: number,
    isReplayMultidayPanelOpen: boolean,
    zoomLevel: number,
    zoomLevelToMarkersMap
  ): IPolylineInfo[] => {
    // when multiday panel is closed, markers are used
    if (!isReplayMultidayPanelOpen) {
      return [];
    }

    const modifiedPolylines: IPolylineInfo[] = basePolylines.map(basePolyline => {
      // create a new object so not to modify basePolyline
      const polyline: IPolylineInfo = {
        ...basePolyline
      };

      // null represents "nothings is hovered"
      polyline.isActive = isElementNull(hoveredPolyline) ? null : hoveredPolyline === polyline.id;

      polyline.markers = ReplayMappingUtilities.getPolylineMarkers(polyline, zoomLevel, zoomLevelToMarkersMap);

      return polyline;
    });

    return modifiedPolylines;
  }
);

export const getReplayMarkers = createSelector(
  getReplayPlots,
  getReplayPolylines,
  getIsReplayMultidayPanelOpen,
  (replayPlots: IReplayPlot[], polylines: IPolylineInfo[], isReplayMultidayPanelOpen: boolean): ReplayMarkerInfo[] => {
    // when multiday panel is open, polylines are used
    if (isReplayMultidayPanelOpen) {
      const polylineMarkers: MarkerInfo[] = [];
      polylines.forEach(polyline => polylineMarkers.push(...polyline.markers));
      return polylineMarkers;
    }

    return replayPlots.map((plot: IReplayPlot) => ReplayMappingUtilities.getReplayMarkerInfo(plot));
  }
);

export const getDrawerState = createSelector(
  getReplayState,
  (state: IReplayState): EPanelDrawerState => (!isElementNull(state) ? state.drawerState : null)
);

export const getIsReplayOptionsOpen = createSelector(getReplayState, (state: IReplayState): boolean => state.isReplayOptionsOpen);

export const getIsReplaySettingsLoaded = createSelector(
  getReplayUserSettings,
  getUserSpeedingThreshold,
  getUserSettings,
  (replayUserSettings, userSpeedingThreshold, userSettings) =>
    !isElementNull(replayUserSettings) && !isElementNull(userSpeedingThreshold) && !isElementNull(userSettings)
);
