/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '@fleetmatics/ui.utilities';
import { map } from 'rxjs/operators';

import { IGeofencesRequest, ILatLngBounds, IGeofencePlot } from '../../models';
import { IAppConfig } from '../../../config/app-config.interfaces';
import { IGeofencesService } from './geofences-http.service.interface';
import { IApiResponseDto } from '../../components';

@Injectable()
export class GeofencesHttpService implements IGeofencesService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, _configService: ConfigService<IAppConfig>) {
    this._rootUrl = _configService.config.endpoints.plotApiEndpoint;
  }

  public getGeofences(bounds: ILatLngBounds, categoryIds: number[]): Observable<IGeofencePlot[]> {
    const data: IGeofencesRequest = {
      TopBoundaryBox: {
        Latitude: bounds.north,
        Longitude: bounds.east
      },
      BottomBoundaryBox: {
        Latitude: bounds.south,
        Longitude: bounds.west
      },
      CategoryIds: categoryIds ? categoryIds.toString() : null
    };

    return this._httpClient
      .post<IApiResponseDto<IGeofencePlot[]>>(`${this._rootUrl}/Plot/Geofences/`, data)
      .pipe(map(result => result.Data));
  }
}
