/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { IAppConfig } from '../../../config/app-config.interfaces';
import { APP_CONFIG_TOKEN } from '../../../config/app-config.token';
import { IUserDataResponse } from '../../models/user-data.response';
import { IUserDateTime, IReplayUserSettings } from '../../models';
import { IUserDataHttpService } from './user-data-http.service.interface';

@Injectable()
export class UserDataHttpService implements IUserDataHttpService {
  private readonly _rootUrl: string;

  constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG_TOKEN) _config: IAppConfig) {
    this._rootUrl = _config.endpoints.uiSharedServicesApiEndpoint;
  }

  public getUserPermissions(): Observable<IUserDataResponse> {
    return this._httpClient.get<IUserDataResponse>(`${this._rootUrl}/User/GetUserMapSettings`);
  }

  public getUserDateTime(): Observable<IUserDateTime> {
    return this._httpClient.get<IUserDateTime>(`${this._rootUrl}/User/UserDateTime`);
  }

  public getConvertedDateTimeToUserLocal(date: Date): Observable<IUserDateTime> {
    const params = new HttpParams().append('dateTime', date.toISOString());
    return this._httpClient.get<IUserDateTime>(`${this._rootUrl}/User/GetConvertedDateTimeToUserLocal`, { params });
  }

  public getReplaySettings(): Observable<IReplayUserSettings> {
    return this._httpClient.get<IReplayUserSettings>(`${this._rootUrl}/v2/User/ReplaySettings`);
  }
}
