/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */
import { Action } from '@ngrx/store';

import { IScheduledJob, IScheduledJobUpdate } from '../../models';

export enum ESchedulerActions {
  RedirectToSchedulerPage = '[Scheduler] Redirect To Scheduler Page',
  UpdateScheduledJob = '[Scheduler] Update Scheduled Job',
  SelectRoutedVehicle = '[Scheduler] Select Routed Vehicle'
}

export class RedirectToSchedulerPage implements Action {
  public readonly type = ESchedulerActions.RedirectToSchedulerPage;

  constructor(public scheduledJob: IScheduledJob) {}
}

export class UpdateScheduledJob implements Action {
  public readonly type = ESchedulerActions.UpdateScheduledJob;

  constructor(public payload: IScheduledJobUpdate) {}
}

export class SelectRoutedVehicle implements Action {
  public readonly type = ESchedulerActions.SelectRoutedVehicle;

  constructor(public payload: string) {}
}

export type SchedulerActions = RedirectToSchedulerPage | UpdateScheduledJob | SelectRoutedVehicle;
