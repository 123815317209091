/*!
 * Copyright © 2018-2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IAssetPlot, IAssetSelectionResponse, IAssetDetailResponse, IMapHierarchyInfo } from '../../models';

export const enum EAssetsActions {
  GetAssetsHierarchy = '[Assets] Get Assets Hierarchy',
  GetAssetPlotsForUser = '[Assets] Get Asset Plots For User',
  GetAssetPlotsForUserSuccess = '[Assets] Get Asset Plots For User Success',
  UpdateAssetSortType = '[Assets] Updated Asset Sort Type',
  UpdateAssetSelection = '[Assets] Update Asset Selection',
  UpdateAssetSelectionSuccess = '[Assets] Update Asset Selection Success',
  UpdateAssetAsSelected = '[Assets] Update Asset as Selected',
  UpdateAssetAsSelectedSuccess = '[Assets] Update Asset as Selected Success',
  GetSelectedAssets = '[Assets] Get Selected Assets',
  GetSelectedAssetsSuccess = '[Assets] Get Selected Assets Success',
  GetAssetDetails = '[Assets] Get Asset Details',
  GetAssetDetailsSuccess = '[Assets] Get Asset Details Success',
  GetAssetPlots = '[Assets] Get Asset Plots',
  GetAssetPlotsSuccess = '[Assets] Get Asset Plots Success',
  GetSearchLightAssetPlot = '[Assets] Get SearchLight Asset Plot',
  RedirectToLocationHistory = '[Assets] Redirect To Location History'
}

export class GetAssetsHierarchy implements Action {
  public readonly type = EAssetsActions.GetAssetsHierarchy;
}

export class GetAssetPlotsForUser implements Action {
  public readonly type = EAssetsActions.GetAssetPlotsForUser;
}

export class GetAssetPlotsForUserSuccess implements Action {
  public readonly type = EAssetsActions.GetAssetPlotsForUserSuccess;
}

export class UpdateAssetSelection implements Action {
  public readonly type = EAssetsActions.UpdateAssetSelection;

  constructor(public payload: string[]) {}
}

export class UpdateAssetSelectionSuccess implements Action {
  public readonly type = EAssetsActions.UpdateAssetSelectionSuccess;

  constructor(public payload: IAssetSelectionResponse[]) {}
}

export class GetSelectedAssets implements Action {
  public readonly type = EAssetsActions.GetSelectedAssets;
}

export class GetSelectedAssetsSuccess implements Action {
  public readonly type = EAssetsActions.GetSelectedAssetsSuccess;

  constructor(public payload: IAssetSelectionResponse[]) {}
}

export class GetAssetDetails implements Action {
  public readonly type = EAssetsActions.GetAssetDetails;
}

export class GetAssetDetailsSuccess implements Action {
  public readonly type = EAssetsActions.GetAssetDetailsSuccess;

  constructor(public payload: IAssetDetailResponse[]) {}
}

export class GetAssetPlots implements Action {
  public readonly type = EAssetsActions.GetAssetPlots;
}

export class GetAssetPlotsSuccess implements Action {
  public readonly type = EAssetsActions.GetAssetPlotsSuccess;

  constructor(public payload: IAssetPlot[]) {}
}

export class UpdateAssetAsSelected implements Action {
  public readonly type = EAssetsActions.UpdateAssetAsSelected;

  constructor(public payload: string) {}
}

export class UpdateAssetAsSelectedSuccess implements Action {
  public readonly type = EAssetsActions.UpdateAssetAsSelectedSuccess;

  constructor(public payload: IMapHierarchyInfo) {}
}

export class GetSearchLightAssetPlot implements Action {
  public readonly type = EAssetsActions.GetSearchLightAssetPlot;

  constructor(public payload: string) {}
}

export class RedirectToLocationHistory implements Action {
  public readonly type = EAssetsActions.RedirectToLocationHistory;

  constructor(public payload: number) {}
}

export type AssetsActions =
  | GetAssetsHierarchy
  | GetAssetPlotsForUser
  | GetAssetPlotsForUserSuccess
  | UpdateAssetSelection
  | UpdateAssetSelectionSuccess
  | GetSelectedAssets
  | GetSelectedAssetsSuccess
  | GetAssetDetails
  | GetAssetDetailsSuccess
  | GetAssetPlots
  | GetAssetPlotsSuccess
  | UpdateAssetAsSelected
  | UpdateAssetAsSelectedSuccess
  | GetSearchLightAssetPlot
  | RedirectToLocationHistory;
