/*!
 * Copyright © 2019-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import { EDriverWorkingState } from 'tacho';
import { EDriverStatusSortType, IDriverStatus } from '../../models';

export interface IWorkingTimeDirectiveState {
  filter: EDriverWorkingState;
  filterBreakDue: boolean;
  sortType: EDriverStatusSortType;
  driverStatusData: IDriverStatus[];
}

export const initialWorkingTimeDirectiveState: IWorkingTimeDirectiveState = {
  filter: null,
  filterBreakDue: false,
  sortType: EDriverStatusSortType.StartTime,
  driverStatusData: []
};
