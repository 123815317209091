/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { FmWindowRefService } from '@fleetmatics/ui.utilities';

import { DemoDomService } from './demo-dom.service';

@Injectable()
export class PendoService {
  private readonly _isPendoReadySubject = new BehaviorSubject<boolean>(false);
  private _isPendoReady$: Observable<void>;

  constructor(
    private readonly _domService: DemoDomService,
    private readonly _windowRefService: FmWindowRefService,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {
    if (isPlatformBrowser(this._platformId)) {
      this._setupPendoAvailabilityCheck();
    }
  }

  advancePendoGuideToStep(toStep: number): void {
    const steps = toStep - 1;
    pendo.onGuideAdvanced({ steps: steps });

    const timer = setInterval(() => {
      let maxRetries = 10;
      let advanced = false;
      while (!advanced && maxRetries-- > 0) {
        // check if guide advanced to the requested step
        const guide = pendo.getActiveGuide();
        if (guide && guide.stepIndex === steps) {
          advanced = true;
        } else {
          // try again
          pendo.onGuideAdvanced({ steps: steps });
        }
      }
      clearInterval(timer);
    }, 500);
  }

  tryShowPendoGuideRelativeToElement(guideId: string, elementId: string): Observable<boolean> {
    return this._isPendoReady$.pipe(
      switchMap(() => {
        this._domService.addPendoGuideTriggerElement(elementId);
        return this.showPendoGuide(guideId);
      })
    );
  }

  showPendoGuide(guideId: string): Observable<boolean> {
    let isGuideShown = pendo.showGuideById(guideId);

    const isGuideShown$ = new BehaviorSubject<boolean>(isGuideShown ? isGuideShown : null);
    if (!isGuideShown) {
      let retries = 0;
      const intervalId = setInterval(function () {
        isGuideShown = pendo.showGuideById(guideId);
        if (isGuideShown && retries <= 50) {
          clearInterval(intervalId);
          isGuideShown$.next(true);
        } else if (++retries > 50) {
          clearInterval(intervalId);
          isGuideShown$.next(false);
        }
      }, 100);
    }

    return isGuideShown$.asObservable().pipe(
      filter(result => result !== null),
      take(1)
    );
  }

  hideGuides(): void {
    this._isPendoReady$.subscribe(() => pendo.hideGuides());
  }

  private _setupPendoAvailabilityCheck(): void {
    this._isPendoReady$ = this._isPendoReadySubject.pipe(
      filter(isReady => isReady),
      take(1),
      map(() => void 0)
    );

    const intervalId = setInterval(() => {
      if (<any>this._windowRefService && <any>this._windowRefService.nativeWindow && (<any>this._windowRefService.nativeWindow).pendo) {
        try {
          if (pendo.isReady()) {
            clearInterval(intervalId);
            this._isPendoReadySubject.next(true);
          }
        } catch (error) {}
      }
    }, 100);
  }
}
