/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IConfigState } from '../state';

export const getConfigState = createFeatureSelector<IConfigState>('config');

export const getIsWorkingTimeDirectiveEnabled = createSelector(
  getConfigState,
  (state: IConfigState): boolean => state.isWorkingTimeDirectiveEnabled
);

export const getIsSchedulerEnabled = createSelector(
  getConfigState,
  (state: IConfigState): boolean => state.isSchedulerEnabled
);
