/*!
 * Copyright © 2018-2020. Verizon Connect Ireland Limited. All rights reserved.
 */

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  AfterContentChecked,
  ElementRef,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  ViewChild
} from '@angular/core';

import { isElementNull, FmWindowRefService, isArrayNullOrEmpty } from '@fleetmatics/ui.utilities';

import { TabDirective } from './tab/tab.directive';

@Component({
  selector: 'fm-tabs',
  templateUrl: './tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements AfterContentChecked, AfterContentInit, OnDestroy {
  @Input()
  paddingOffset = 0;
  @Input()
  shouldFitTabs: boolean;

  @Output()
  public activeChanged: EventEmitter<void> = new EventEmitter();

  @ViewChild('headerContainer')
  headerContainer: ElementRef;
  @ViewChild('tabHeadersContainer')
  tabHeadersContainer: ElementRef;
  @ViewChildren('tabHeader')
  tabHeaders: QueryList<ElementRef>;

  @HostBinding('class.tabs')
  mainClass = true;

  public activeTab: TabDirective;
  public tabs: TabDirective[] = [];
  public showLoadMoreTabs = false;
  public showLoadPreviousTabs = false;

  protected isDestroyed: boolean;

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _windowsRef: FmWindowRefService
  ) {}

  ngAfterContentInit(): void {
    const activeTab = this.tabs.find((tab: TabDirective) => tab.active);
    if (!isElementNull(activeTab)) {
      this.activeTab = activeTab;
    }
  }

  ngAfterContentChecked(): void {
    const tabHeaders = this.tabHeaders ? this.tabHeaders.toArray() : [];
    if (!isArrayNullOrEmpty(tabHeaders)) {
      const headerContainer = this.headerContainer.nativeElement;
      const containerWidth = headerContainer.offsetWidth;
      const { areThereAnyTabToTheRight, areThereAnyTabToTheLeft } = this._checkIfTabsAreOnLeftOrRight(tabHeaders, containerWidth);

      if (!this.showLoadPreviousTabs && areThereAnyTabToTheRight) {
        this.showLoadPreviousTabs = true;
        this._cdr.detectChanges();
      } else if (this.showLoadPreviousTabs && !areThereAnyTabToTheRight) {
        this.showLoadPreviousTabs = false;
        this._cdr.detectChanges();
      }
      if (!this.showLoadMoreTabs && areThereAnyTabToTheLeft) {
        this.showLoadMoreTabs = true;
        this._cdr.detectChanges();
      } else if (this.showLoadMoreTabs && !areThereAnyTabToTheLeft) {
        this.showLoadMoreTabs = false;
        this._cdr.detectChanges();
      }
    }
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  public activateTab(tab: TabDirective): void {
    tab.active = true;
    this.activeTab = tab;
    this.activeChanged.next();
  }

  public addTab(tab: TabDirective): void {
    this.tabs.push(tab);
    tab.active = this.tabs.length === 1;
    this._cdr.markForCheck();
  }

  public contains(tab: TabDirective): boolean {
    return this.tabs.some(currentTab => currentTab.id === tab.id);
  }

  public ensureThereIsOneTabSelected(): void {
    if (this.tabs.length > 0 && this.tabs.every(tab => !tab.active)) {
      this.tabs[0].active = true;
    }
  }

  public removeTab(tab: TabDirective, options = { reselect: true, emit: true }): void {
    const index = this.tabs.indexOf(tab);
    if (index === -1 || this.isDestroyed) {
      return;
    }
    // Select a new tab if the tab to be removed is selected and not destroyed
    if (options.reselect && tab.active && this.tabs.length > 1) {
      const newActiveIndex = this.getClosestTabIndex(index);
      this.tabs[newActiveIndex].active = true;
    }
    if (options.emit) {
      tab.removed.emit(tab);
    }
    this.tabs.splice(index, 1);
    if (tab.elementRef.nativeElement.parentNode) {
      this._renderer.removeChild(tab.elementRef.nativeElement.parentNode, tab.elementRef.nativeElement);
    }
    this._cdr.markForCheck();
  }

  public loadMoreTabs(): void {
    const container = this.headerContainer.nativeElement;
    const tabHeaders = this.tabHeaders.toArray();
    const containerWidth = container.offsetWidth;

    let isThereAnyHiddenTabHeader = false;
    let offset = 0;
    if (!isArrayNullOrEmpty(tabHeaders)) {
      for (let i = 0, length = tabHeaders.length; i < length; i++) {
        const tabHeader = tabHeaders[i].nativeElement;
        const tabHeaderRigthEndPosition = tabHeader.offsetLeft + tabHeader.offsetWidth;
        if (tabHeaderRigthEndPosition > containerWidth) {
          isThereAnyHiddenTabHeader = true;
          offset = tabHeaderRigthEndPosition - container.offsetWidth;
          break;
        }
      }
    }
    if (isThereAnyHiddenTabHeader) {
      this._setTabHeadersContainerStyles(offset);
    }
  }

  public loadPreviousTabs(): void {
    const tabHeaders = this.tabHeaders.toArray();
    let isThereAnyHiddenTabHeader = false;
    let lastOffsetPosition = 0;
    let offset = 0;
    if (!isArrayNullOrEmpty(tabHeaders)) {
      for (let i = 0, length = tabHeaders.length; i < length; i++) {
        const tabHeader = tabHeaders[i].nativeElement;
        const tabHeaderOffsetPosition = tabHeader.offsetLeft;
        if (tabHeaderOffsetPosition < 0) {
          isThereAnyHiddenTabHeader = true;
          if (lastOffsetPosition === 0 || lastOffsetPosition < tabHeaderOffsetPosition) {
            lastOffsetPosition = tabHeaderOffsetPosition;
            offset = lastOffsetPosition;
          }
        } else {
          break;
        }
      }
    }
    if (isThereAnyHiddenTabHeader) {
      this._setTabHeadersContainerStyles(offset);
    }
  }

  protected getClosestTabIndex(index: number): number {
    if (this.tabs.length <= 1) {
      return -1;
    }

    if (!isElementNull(this.tabs[index - 1])) {
      return index - 1;
    }
    if (!isElementNull(this.tabs[index + 1])) {
      return index + 1;
    }

    return -1;
  }

  private _setTabHeadersContainerStyles(offset: number) {
    const tabHeadersContainer = this.tabHeadersContainer.nativeElement;
    const radix = 10;
    this._renderer.setStyle(
      tabHeadersContainer,
      'margin-left',
      parseInt(this._windowsRef.nativeWindow.getComputedStyle(tabHeadersContainer).marginLeft, radix) - offset + 'px'
    );
  }

  private _checkIfTabsAreOnLeftOrRight(
    tabHeaders: ElementRef[],
    containerWidth: any
  ): {
    areThereAnyTabToTheLeft: boolean;
    areThereAnyTabToTheRight: boolean;
  } {
    let areThereAnyTabToTheLeft = false;
    let areThereAnyTabToTheRight = false;
    for (let i = 0, length = tabHeaders.length; i < length; i++) {
      const tabHeader = tabHeaders[i].nativeElement;
      const tabHeaderRightEndPosition = tabHeader.offsetLeft + tabHeader.offsetWidth;
      if (tabHeader.offsetLeft < 0 && !areThereAnyTabToTheRight) {
        areThereAnyTabToTheRight = true;
      }
      if (tabHeaderRightEndPosition > containerWidth && !areThereAnyTabToTheLeft) {
        areThereAnyTabToTheLeft = true;
      }
    }
    return { areThereAnyTabToTheRight, areThereAnyTabToTheLeft };
  }
}
