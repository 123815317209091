/*!
 * Copyright © 2019. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IHarshDrivingEvent, IHarshDrivingEventParams } from '../../models';

export enum EHarshDrivingEventsActions {
  GetHarshDrivingEvent = '[Harsh Driving Events] Get Harsh Driving Event',
  GetHarshDrivingEventSuccess = '[Harsh Driving Events] Get Harsh Driving Event Success'
}

export class GetHarshDrivingEvent implements Action {
  readonly type = EHarshDrivingEventsActions.GetHarshDrivingEvent;

  constructor(public payload: IHarshDrivingEventParams) {}
}

export class GetHarshDrivingEventSuccess implements Action {
  readonly type = EHarshDrivingEventsActions.GetHarshDrivingEventSuccess;

  constructor(public payload: IHarshDrivingEvent) {}
}

export type HarshDrivingEventsActions = GetHarshDrivingEvent | GetHarshDrivingEventSuccess;
