/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Directive, TemplateRef } from '@angular/core';

import { TabDirective } from './tab.directive';

@Directive({
  selector: '[fmTabContent]'
})
export class TabContentDirective {
  public templateRef: TemplateRef<any>;

  constructor(templateRef: TemplateRef<any>, tab: TabDirective) {
    tab.contentRef = templateRef;
  }
}
