/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { withLatestFrom, mergeMap, concatMap, catchError } from 'rxjs/operators';

import {
  EReverseGeocodeActions,
  ReverseGeocodeVehiclePlots,
  ReverseGeocodeVehiclePlotsSuccess,
  ExecuteReverseGeocode,
  UpdateExecutionBatches,
  RemoveExecutionBatch
} from '../actions';
import { getVehiclePlots, getReverseGeocodeExecutionBatches } from '../selectors';
import { IAppState } from '../state';
import { IReverseGeocodeRequest, IReverseGeocodeResponse, IVehiclePlot, IReverseGeocodeExecutionBatch } from '../../models';
import { AddressResolutionHttpService } from '../../services';

@Injectable()
export class ReverseGeocodeEffects {

  public reverseGeocodeVehiclePlots$: Observable<Action> = createEffect(() => this._actions$.pipe(
    ofType<ReverseGeocodeVehiclePlots>(EReverseGeocodeActions.ReverseGeocodeVehiclePlots),
    withLatestFrom(this._store.pipe(select(getReverseGeocodeExecutionBatches))),
    concatMap(([{ payload: executionBatch }, executionBatches]) => {
      const actions: Action[] = [];
      const executionBatchesToUpdate = this._getExecutionBatchesToUpdate(executionBatches, executionBatch);
      if (executionBatchesToUpdate.length > 0) {
        actions.push(new UpdateExecutionBatches(executionBatchesToUpdate));
      }
      actions.push(new ExecuteReverseGeocode(executionBatch));
      return actions;
    })
  ));

  public executeReverseGeocode$ = createEffect(() => this._actions$.pipe(
    ofType<ExecuteReverseGeocode>(EReverseGeocodeActions.ExecuteReverseGeocode),
    mergeMap(({ payload: executionBatch }) => {
      const reverseGeocodeRequests: IReverseGeocodeRequest[] = [];
      executionBatch.vehiclePlots.forEach(plot => {
        reverseGeocodeRequests.push(this._getReverseGeocodeRequest(plot));
      });

      return this._addressResolutionHttpService.getReverseGeocode(reverseGeocodeRequests).pipe(
        catchError(() =>
          of(
            reverseGeocodeRequests.map(
              request =>
                <IReverseGeocodeResponse>{
                  fulladdress: null,
                  key: request.key
                }
            )
          )
        ),
        withLatestFrom(this._store.pipe(select(getVehiclePlots)), this._store.pipe(select(getReverseGeocodeExecutionBatches))),
        mergeMap(([reverseGeocodeResponse, vehiclePlots, executionBatches]) => {
          const upToDateCurrentExecutionBatch = executionBatches.find(batch => batch.id === executionBatch.id);
          const updatedPlots = this._applyReverseGeocodeResults(vehiclePlots, reverseGeocodeResponse, upToDateCurrentExecutionBatch);
          return [new ReverseGeocodeVehiclePlotsSuccess(updatedPlots), new RemoveExecutionBatch(executionBatch.id)];
        })
      );
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<IAppState>,
    private readonly _addressResolutionHttpService: AddressResolutionHttpService
  ) {}

  private _applyReverseGeocodeResults(
    vehiclePlots: IVehiclePlot[],
    reverseGeocodeResults: IReverseGeocodeResponse[],
    executionBatch: IReverseGeocodeExecutionBatch
  ): IVehiclePlot[] {
    const addresses = new Map<number, string>();
    reverseGeocodeResults
      .filter(result => executionBatch.vehiclePlots.some(plot => plot.id.toString() === result.key))
      .forEach(validResult => addresses.set(Number.parseInt(validResult.key, 10), validResult.fulladdress));

    return vehiclePlots.map(plot => {
      if (!addresses.has(plot.id)) {
        return plot;
      }
      return <IVehiclePlot>{
        ...plot,
        address: addresses.get(plot.id),
        isSearchingAddress: false
      };
    });
  }

  private _getExecutionBatchesToUpdate(
    executionBatches: IReverseGeocodeExecutionBatch[],
    currentExecutionBatch: IReverseGeocodeExecutionBatch
  ): IReverseGeocodeExecutionBatch[] {
    const executionBatchesToUpdate: IReverseGeocodeExecutionBatch[] = [];
    executionBatches.forEach(batch => {
      if (batch.id !== currentExecutionBatch.id) {
        const vehiclePlots = batch.vehiclePlots.filter(plot =>
          currentExecutionBatch.vehiclePlots.every(currentPlot => currentPlot.id !== plot.id)
        );
        if (vehiclePlots.length !== batch.vehiclePlots.length) {
          executionBatchesToUpdate.push({ id: batch.id, vehiclePlots });
        }
      }
    });

    return executionBatchesToUpdate;
  }

  private _getReverseGeocodeRequest(vehiclePlot: IVehiclePlot): IReverseGeocodeRequest {
    return {
      key: vehiclePlot.id.toString(),
      data: {
        adrid: vehiclePlot.addressId,
        vid: vehiclePlot.id,
        Latitude: vehiclePlot.coordinates.lat,
        Longitude: vehiclePlot.coordinates.lng
      }
    };
  }
}
