/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IWorkOrder } from '../../models';

export enum EWorkOrderActions {
  CloseEditWorkOrderModal = '[Work Order] Close Edit Work Order Modal',
  SaveWorkOrder = '[Work Order] Save Work Order',
  SaveWorkOrderError = '[Work Order] Save Work Order Error',
  SaveWorkOrderSuccess = '[Work Order] Save Work Order Success',
  TriggerEditWorkOrder = '[Work Order] Trigger Edit Work Order',
  TriggerEditWorkOrderSuccess = '[Work Order] Trigger Edit Work Order Success'
}

export class CloseEditWorkOrderModal implements Action {
  public readonly type = EWorkOrderActions.CloseEditWorkOrderModal;
}

export class SaveWorkOrder implements Action {
  public readonly type = EWorkOrderActions.SaveWorkOrder;

  constructor(public payload: IWorkOrder) {}
}

export class SaveWorkOrderError implements Action {
  public readonly type = EWorkOrderActions.SaveWorkOrderError;
}

export class SaveWorkOrderSuccess implements Action {
  public readonly type = EWorkOrderActions.SaveWorkOrderSuccess;
}

export class TriggerEditWorkOrder implements Action {
  public readonly type = EWorkOrderActions.TriggerEditWorkOrder;

  constructor(public payload: number) {}
}

export class TriggerEditWorkOrderSuccess implements Action {
  public readonly type = EWorkOrderActions.TriggerEditWorkOrderSuccess;

  constructor(public payload: IWorkOrder) {}
}

export type WorkOrderActions =
  | CloseEditWorkOrderModal
  | SaveWorkOrder
  | SaveWorkOrderError
  | SaveWorkOrderSuccess
  | TriggerEditWorkOrder
  | TriggerEditWorkOrderSuccess;
