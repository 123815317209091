/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IDriverMessageState } from '../state';
import { IDriverDetails, ISmsOptInStatus, ISmsInvitation } from '../../models';

export const getDriverMessageState = createFeatureSelector<IDriverMessageState>('driverMessage');

export const getDriverDetails = createSelector(
  getDriverMessageState,
  (state: IDriverMessageState): IDriverDetails => state.driverDetails
);

export const getSmsOptInStatus = createSelector(
  getDriverMessageState,
  (state: IDriverMessageState): ISmsOptInStatus => state.smsOptInStatus
);

export const getIsEmailSent = createSelector(
  getDriverMessageState,
  (state: IDriverMessageState): boolean => state.isEmailSent
);

export const getIsDriverMessageModalOpen = createSelector(
  getDriverMessageState,
  (state: IDriverMessageState): boolean => state.isModalOpen
);

export const getSmsInvitation = createSelector(
  getDriverMessageState,
  (state: IDriverMessageState): ISmsInvitation => state.smsInvitation
);

export const getIsSmsSent = createSelector(
  getDriverMessageState,
  (state: IDriverMessageState): boolean => state.isSmsSent
);
