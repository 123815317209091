/*!
 * Copyright © 2020. Verizon Connect Ireland Limited. All rights reserved.
 */

export const ROUTE_PARAMS = {
  SCHEDULER: {
    VEHICLE_ID: 'vehicleId'
  },
  REPLAY: {
    ROUTE: 'replay',
    VEHICLE_ID: 'id',
    START: 'start',
    PRESELECT_SEGMENT_TIME: 'preselectSegmentTime',
    MINIMUM_STOP_DURATION: 'minimumStopDuration',
    MINIMUM_IDLE_DURATION: 'minimumIdleDuration',
    SHOW_IDLE_SEGMENTS: 'showIdleSegments'
  },
  FeatureToggles: {
    Enabled: 'featureTogglesEnable',
    Disabled: 'featureTogglesDisable'
  }
};
