/*!
 * Copyright © 2018. Verizon Connect Ireland Limited. All rights reserved.
 */

import { EAdvancedOptionsActions, AdvancedOptionsActions, ELayoutPreferencesActions, GetLayoutPreferencesSuccess } from '../actions';

import { initialAdvancedOptionsState, IAdvancedOptionsState } from '../state';

export function advancedOptionsReducer(
  state = initialAdvancedOptionsState,
  action: AdvancedOptionsActions | GetLayoutPreferencesSuccess
): IAdvancedOptionsState {
  switch (action.type) {
    case ELayoutPreferencesActions.GetLayoutPreferencesSuccess: {
      return {
        ...state,
        placeCategoryIds: action.payload.mapOptions.advancedOptions.placeCategoryIds,
        garminStopStatusesIdsSelected: action.payload.mapOptions.advancedOptions.garminStopStatusesIdsSelected
      };
    }

    case EAdvancedOptionsActions.GetPlaceCategoriesSuccess: {
      return {
        ...state,
        placeCategories: action.payload
      };
    }

    case EAdvancedOptionsActions.UpdatePlaceCategoryIds: {
      return {
        ...state,
        placeCategoryIds: action.payload
      };
    }

    case EAdvancedOptionsActions.UpdatePlaceCategoryIdsSilent: {
      return {
        ...state,
        placeCategoryIds: action.payload
      };
    }

    case EAdvancedOptionsActions.UpdateGarminStopStatusesIdsSelected:
    case EAdvancedOptionsActions.UpdateGarminStopStatusesIdsSelectedSilent: {
      return {
        ...state,
        garminStopStatusesIdsSelected: action.payload
      };
    }

    case EAdvancedOptionsActions.GetWorkOrderAdvancedOptionsSuccess:
    case EAdvancedOptionsActions.UpdateWorkOrderSettingsSuccess: {
      return {
        ...state,
        workOrderAdvancedOptions: action.payload
      };
    }

    default:
      return state;
  }
}
