<div class="fm-search-light-category-menu">

  <div class="fm-search-light-category-menu__menu-outer">
    <fm-menu-panel *ngIf="menuMap"
      (menuOptionKeydown)="onMenuOptionKeydown($event)">
      <div class="fm-search-light-category-menu__menu-inner">

        <ng-container *ngFor="let category of categories">
          <fm-menu-option fmSubMenuToggle
            [autoClose]="false"
            [subMenu]="getCategorySubMenu(category)"
            [attr.a7s-id]="'live-map-search-filter-' + category.entityType.toLowerCase()"
            *ngIf="getCategorySubMenu(category)"
            (subMenuOpen)="setIsSubMenuOpen(category, true)"
            (subMenuClose)="setIsSubMenuOpen(category, false)">
            <fm-search-light-category-menu-option [isActive]="getIsSubMenuOpen(category)"
              [category]="category">
            </fm-search-light-category-menu-option>
          </fm-menu-option>
        </ng-container>

      </div>
    </fm-menu-panel>
  </div>

  <div class="fm-search-light-category-menu__results">
    <ng-container *ngFor="let category of categories">
      <fm-search-light-category-results-menu [category]="category"
        (resultSelect)="onCategoryResultSelected($event)"
        (menuChange)="onCategoryResultsMenuSet()"
        #categoryResultsMenu="fmSearchLightCategoryResultsMenu">
      </fm-search-light-category-results-menu>
    </ng-container>
  </div>
</div>
